import Homepage from "./components/Homepage.js";
import "./App.css";
import AboutUs from "./components/AboutUs.js";
import { Router, Link } from "@reach/router";
import Services from "./components/Services.js";
import Header from "./components/Header.js";
// import Home from "./homepages/Home.js";
import Footer from "./components/Footer.js";
import Register from "./pages/Register.js";
import LogIn from "./pages/LogIn";
import Coding from "./pages/Coding.js";
import Sciencefair from "./pages/Sciencefair.js";
import Motivation from "./pages/Motivation.js";
import School from "./pages/School.js";
import Teachers from "./pages/Teachers.js";
import TeacherDetails from "./components/TeacherDetails.js";
// import Observer_profile from "./pages/Observer_profile";
import Teacherprofile from "./pages/Teacher/Teacherprofile.js";
import Subject from "./pages/Teacher/Subject.js";
import Innovationvideo from "./pages/Teacher/Innovationvideo.js";
import StudentReport from "./pages/Teacher/StudentReport.js";
import TuitionTime from "./pages/Teacher/TuitionTime.js";
import SubscriberList from "./pages/Teacher/SubscriberList.js";
import MotivationalVideo from "./pages/Teacher/MotivationalVideo.js";
import TrainingVideo from "./pages/Teacher/TrainingVideo.js";
import News from "./pages/Teacher/News";
import MonthlyExam from "./pages/Teacher/MonthlyExam.js";
import Attendance from "./pages/Teacher/Attendance.js";
import VideoDetail from "./pages/Teacher/VideoDetail.js";
import Profile from "./Student/Profile.js";
import TimeBlocking from "./Student/TimeBlocking.js";
import CheckOut from "./pages/CheckOut.js";
import Payment from "./pages/Payment.js";
import CodingVideos from "./Student/CodingVideos.js";
import CodingTestExam from "./pages/CodingTestExam.js";
import InnovateVideo from "./Student/InnovateVideo.js";
import Notification from "./Student/Notification.js";
import GamingAcademy from "./Student/GamingAcademy.js";
import GamePayment from "./Student/GamePayment.js";
import SubjectUser from "./Student/SubjectUser.js";
import SubjectDetail from "./Student/SubjectDetail.js";
import SubjectDetailVideo from "./Student/SubjectDetailVideo.js";
import ProgressReport from "./Student/ProgressReport.js";
import GameProfile from "./GameAcademy/GameProfile.js";
import Classification from "./GameAcademy/Classification.js";
import GamingReport from "./GameAcademy/GamingReport.js";
import AcademyNews from "./GameAcademy/AcademyNews.js";
import GameNews from "./GameAcademy/GameNews.js";
import Tournament from "./GameAcademy/Tournament.js";
import GameSubscription from "./GameAcademy/GameSubscription.js";
import TeacherRegister from "./pages/Teacher/TeacherRegister.js";
import GameRegister from "./GameAcademy/GameRegister.js";
import Buysubscription from "./pages/Buysubscription.js";
import Observerreg from "./pages/Observerreg.js";
import Observer_profile from "./pages/Observer_profile.js";
import Student from "./components/Students.js";
// import observer_sciencefair from "./components/observer_sciencefair.js";
import ObserverSciencefair from "./components/ObserverSciencefair";
import Observer_gameacademy from "./components/Observer_gameacademy.js";
import ObserverSchool from "./components/ObserverSchool.js";
import Subscriber from "./components/Subscriber.js";
import AcademyVideos from "./components/AcademyVideos.js";
import OtherInformation from "./components/OtherInformation.js";
import Motivation_student from "./components/Motivation_student.js";
import CodingExam from "./components/CodingExam";
import MotivationalVideoDetail from "./components/MotivationalVideoDetail.js";
import InnovateVideodetails from "./components/InnovationVideodetails";
import { ProvideAuth } from "./provider/useAuth";
import ObserverTeacher from "./components/ObserverTeacher";
import StudentSubs from "./components/StudentSubs";
import StudentWallet from "./components/StudentWallet";
import PaymentReturn from "./components/PaymentReturn";
import GamePaymentReturn from "./components/GamePaymentReturn.js";
import StudentPaymentReturn from "./components/StudentPaymentReturn";

// import {useLocation} from "@reach/router"
let locati = window.location.href;
console.log("logged123", locati);
function App() {
  // const location=useLocation()
  //   let locati=window.location.href
  // console.log("logged123",locati);
  return (
    <>
      <ProvideAuth>
        <div style={{ width: "100%", height: "128px" }}>
          <Header />
        </div>
        <div className="">
          {/* <BrowserRouter>
        <Switch> */}
          <Router>
            <Homepage path="/" />
            <LogIn path="login" />
            <AboutUs path="/aboutus" />
            <Services path="/services" />
            <Register path="/signup" />
            <Coding path="/coding" />
            <Sciencefair path="/sciencefair" />
            <Motivation path="/motivation" />
            <Teacherprofile path="/teacherprofile" />
            <TeacherRegister path="/teacherregister" />
            <School path="/school" />
            <Teachers path="/teachers" />
            <TeacherDetails path="/teacher/teacherdetail" />
            <Subject path="/subject" />
            <Innovationvideo path="/innovationvideo" />
            <StudentReport path="/studentreport" />
            <TuitionTime path="/tuitiontime" />
            <SubscriberList path="/subscriberlist" />
            <MotivationalVideo path="/motivationalvideo" />
            <TrainingVideo path="/trainingvideo" />
            <News path="/news" />
            <MonthlyExam path="/monthlyexam" />
            <Attendance path="/attendance" />
            <VideoDetail path="/videodetail" />
            <Profile path="/userprofile" />
            <TimeBlocking path="/timeblocking" />
            <CheckOut path="/checkout" />
            <Payment path="/payment" />
            <CodingVideos path="/codingvideo" />
            <CodingTestExam path="/codingtestexam" />
            <InnovateVideo path="/innovation" />
            <Notification path="/notification" />
            <GamingAcademy path="/gamingacademy" />
            <GamePayment path="/gamepayment" />
            <SubjectUser path="/subjectuser" />
            <SubjectDetail path="/subjectdetail" />
            <SubjectDetailVideo path="/subjectdetailvideo" />
            <ProgressReport path="/progressreport" />
            <GameProfile path="/gameprofile" />
            <Classification path="/classification" />
            <GamingReport path="/gamingreports" />
            <AcademyNews path="/academynews" />
            <GameNews path="/Gamingnews" />
            <Tournament path="/gametournament" />
            <GameSubscription path="/subscrip" />
            <GameRegister path="/gameregister" />
            <Buysubscription path="/buysubscription" />
            <Observerreg path="/observer_register" />
            <InnovateVideodetails path="/student/innovatevideodetail" />
            <Observer_profile path="/observer_profile" />
            <Student path="/observer/student" />
            <ObserverSciencefair path="/observer/sciencefair" />
            <Observer_gameacademy path="/observer/gameacademy" />
            <ObserverSchool path="/observer/school" />
            <Subscriber path="/observer/subcribers" />
            <AcademyVideos path="/academy/videos" />

            <OtherInformation path="/gameacademy/otherinformation" />
            <Motivation_student path="/student/motivationalvideo" />
            <CodingExam path="/coding/onlinetest" />
            <MotivationalVideoDetail path="/motivationalvideo/videodetail" />
            <ObserverTeacher path="/observer/teacher" />
            <StudentSubs path="/student/subcriptions" />
            <StudentWallet path="/student/wallet" />
            <PaymentReturn path="/payment_return" />
            <GamePaymentReturn path="/game_payment_return" />
            <StudentPaymentReturn path="subscription_payment_return" />
          </Router>
          {/* <Route path="/aboutus" component={AboutUs} exact />
          <Route path="/services" component={Services} exact />
          <Route path="/signup" component={Register} exact />
          <Route path="/login" component={Signin} exact />
          <Route path="/coding" component={Coding} exact />
          <Route path="/sciencefair" component={Sciencefair} exact />
          <Route path="/motivation" component={Motivation} exact />
          <Route path="/school" component={School} exact />
          <Route path="/teachers" component={Teachers} exact />
          <Route
            path="/teacher/teacherdetail"
            component={TeacherDetails}
            exact
          /> */}
          {/* <Route path="/teacherprofile" component={Teacherprofile} exact />
          <Route path="/subject" component={Subject} exact />
          <Route path="/innovationvideo" component={Innovationvideo} exact />
          <Route path="/studentreport" component={StudentReport} exact />
          <Route path="/tuitiontime" component={TuitionTime} exact />
          <Route path="/subscriberlist" component={SubscriberList} exact />
          <Route
            path="/motivationalvideo"
            component={MotivationalVideo}
            exact
          />
          <Route path="/trainingvideo" component={TrainingVideo} exact />
          <Route path="/news" component={News} exact />
          <Route path="/monthlyexam" component={MonthlyExam} exact />
          <Route path="/attendance" component={Attendance} exact />
          <Route path="/videodetail" component={VideoDetail} exact />
          <Route path="/userprofile" component={Profile} exact />
          <Route path="/timeblocking" component={TimeBlocking} exact />
          <Route path="/checkout" component={CheckOut} exact />
          <Route path="/payment" component={Payment} exact />
          <Route path="/codingvideo" component={CodingVideos} exact />
          <Route path="/codingtestexam" component={CodingTestExam} exact />
          <Route path="/innovation" component={InnovateVideo} exact />
          <Route path="/notification" component={Notification} exact />
          <Route path="/gamingacademy" component={GamingAcademy} exact />
          <Route path="/gamepayment" component={GamePayment} exact />
          <Route path="/subjectuser" component={SubjectUser} exact />
          <Route path="/subjectdetail" component={SubjectDetail} exact />
          <Route
            path="/subjectdetailvideo"
            component={SubjectDetailVideo}
            exact
          />
          <Route path="/progressreport" component={ProgressReport} exact />
          <Route path="/gameprofile" component={GameProfile} exact />
          <Route path="/classification" component={Classification} exact />
          <Route path="/gamingreports" component={GamingReport} exact />
          <Route path="/academynews" component={AcademyNews} exact />
          <Route path="/gamingnews" component={GameNews} exact />
          <Route path="/gametournament" component={Tournament} exact />
          <Route path="/subscrip" component={GameSubscription} exact />
          <Route path="/teacherregister" component={TeacherRegister} exact />
          <Route path="/gameregister" component={GameRegister} exact />
          <Route path="/buysubscription" component={Buysubscription} exact />
          <Route path="/observer_register" component={Observerreg} />
          <Route path="/observer_profile" component={Observer_profile} />
          <Route path="/observer/student" component={Student} />
          <Route
            path="/observer/sciencefair"
            component={observer_sciencefair}
          />
          <Route
            path="/observer/gameacademy"
            component={Observer_gameacademy}
          />
          <Route path="/observer/school" component={ObserverSchool} />
          <Route path="/observer/subcribers" component={Subscriber} />
          <Route path="/academy/videos" component={AcademyVideos} />
          <Route
            path="/gameacademy/otherinformation"
            component={OtherInformation}
          />
          <Route
            path="/student/motivationalvideo"
            component={Motivation_student}
          />
          <Route path="/coding/onlinetest" component={CodingExam} />
          <Route
            path="/motivationalvideo/videodetail"
            component={MotivationalVideoDetail}
          /> */}
          {/* </Switch> */}
          {/* <br></br> */}
          <Footer />
          {/* </BrowserRouter> */}
        </div>
      </ProvideAuth>
    </>
  );
}

export default App;
