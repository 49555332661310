import React, { Component, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FloatingLabel, Form } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../../provider/useWindowSize'
import MessageIcon from '@mui/icons-material/Message';
import API from "../../components/api";

const VideoDetail = ({ classes }) => {
  const [motivationalVideos, setMotivationalVideos] = useState([])
  const [trainingVideos, setTrainingVideos] = useState([])
  const [openedVideo, setOpenedVideo] = useState("")
  const [playVideo,setPlayVideo]=useState(false)
  const [category,setCategory]=useState("")

  useEffect(() => {
    let video = JSON.parse(localStorage.getItem("selected_video"))
    let category=localStorage.getItem("category_type")
    setCategory(category)
    
    API.post("TeacherApi/videoList").then(res => {
      if (res.data.result === 1) {
        if(category === 'Motivational'){
          setMotivationalVideos(res.data.motivationalVideos)
        }
         if(category === 'Training'){
          setTrainingVideos(res.data.trainingVideos)
        }
      } else {
        if(category === 'Motivational'){
          setMotivationalVideos([])
        }
         if(category === 'Training'){
          setTrainingVideos([])
        }
      }
    })

      setOpenedVideo(video)
  }, [])

  console.log("videos", openedVideo);
  return (
    <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
        {category === 'Motivational' && <div >
          <h1>MOTIVATION VIDEOS</h1>
          <p><Link href="/" style={{ color: "white", textDecoration: "none" }}>Home</Link> | Teacher | Motivational Videos | {openedVideo?.name}</p>
        </div>}
        {category === 'Training' && <div >
          <h1>TRAINING VIDEOS</h1>
          <p><Link href="/" style={{ color: "white", textDecoration: "none" }}>Home</Link> | Teacher | Training Videos | {openedVideo?.name}</p>
        </div>}
      </div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box display="flex" flexDirection="column">
              <Box className={classes.demoPlayerBox}>
                <ReactPlayer 
                url={openedVideo.file} 
                playing="true" 
                width='100%'
                controls
                height='100%' 
                loop="true" 
                muted="false" />
              </Box>
              <Box marginY={2}>
                <Typography variant="h6">{openedVideo.name}</Typography>
              </Box>
              <Box display="flex" padding={2} style={{backgroundColor:"lightgray"}}>
                <Box style={{ width: "50%" }}>
                  <Typography style={{fontSize:"15px",fontWeight:"bold"}}>Posted On</Typography>
                  <Typography paddingY={1}>9th August,2021</Typography>
                </Box>
                <Box style={{ width: "50%" }} >
                  <Typography style={{fontSize:"15px",fontWeight:"bold"}}>Duration</Typography>
                  <Typography paddingY={1}>8 Min</Typography>
                </Box>
              </Box>
              {/* { category === "Motivational" && 
              <Box display="flex" flexDirection="column" marginY={2} style={{border:"1px solid lightgray"}}>
                <Box paddingY={1} paddingX={1} >
                  <Typography>Message</Typography>
                </Box>
                <Divider style={{marginTop:"8px",marginBottom:"8px"}}/>
                <Box display="flex">
                  <Box paddingX={2} alignSelf="center">
                    <MessageIcon />
                  </Box>
                  <Box style={{ width: "100%"}} paddingRight={2}>
                    <TextareaAutosize 
                    placeholder="Write Message"
                    minRows={4}
                    maxLength={200} 
                    maxRows={4} 
                    style={{ width: "100%",border:"none" }} />
                  </Box>
                </Box>
                <Divider style={{marginTop:"8px",marginBottom:"8px"}}/>
                <Box paddingX={1} paddingY={1}>
                  <Button variant="contained" style={{ color: "deepskyblue" }}>SEND</Button>
                </Box>
              </Box>} */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box display="flex" flexDirection="column">
              <Box style={{ width: "100%", height: "71px", backgroundColor: "deepskyblue" }} display="flex">
                <Box marginX={4} style={{ alignSelf: "center", color: "white" }}>
                  <FaLongArrowAltRight />
                </Box>
                <Box style={{ alignSelf: "center", color: "white" }}>
                  <Typography>SIMILAR VIDEOS</Typography>
                </Box>

              </Box>
              <Box>
                <Table stickyHeader aria-label="sticky table">

                  {category === "Motivational" && <TableBody>
                    {motivationalVideos.map((video,index )=> (
                      <>
                      <TableRow hover onClick={()=>{setOpenedVideo(video)}} onMouseOver={()=>{setPlayVideo(true)}} onMouseOut={()=>{setPlayVideo(false)}}>
                          <Box display="flex" >
                            <Box style={{width:"40%"}} padding={1}>
                              <ReactPlayer 
                              url={video?.file} 
                              width='100%'
                              playing={playVideo?true:false}
                              height='130px'
                              loop="true" 
                              muted="true" />
                            </Box>
                            <Box style={{width:"60%",alignSelf:"center"}} padding={2}>
                              <Typography>{video?.name}</Typography>
                              <Typography>9th August,2021</Typography>
                            </Box>
                          </Box>

                      </TableRow>
                      {index !== Number(motivationalVideos.length)-1 && <Divider style={{marginTop:"4px",marginBottom:"4px"}}/>}
                      </>
                    ))}

                  </TableBody>}
                  {category === "Training" && <TableBody>
                    {trainingVideos.map((video,index )=> (
                      <>
                      <TableRow hover onClick={()=>{setOpenedVideo(video)}} onMouseOver={()=>{setPlayVideo(true)}} onMouseOut={()=>{setPlayVideo(false)}}>
                          <Box display="flex" >
                            <Box style={{width:"40%"}} padding={1}>
                              <ReactPlayer 
                              url={video?.file} 
                              width='100%'
                              playing={playVideo?true:false}
                              height='130px'
                              loop="true" 
                              muted="true" />
                            </Box>
                            <Box style={{width:"60%",alignSelf:"center"}} padding={2}>
                              <Typography>{video?.name}</Typography>
                              <Typography>9th August,2021</Typography>
                            </Box>
                          </Box>

                      </TableRow>
                      {index !== Number(trainingVideos.length)-1 && <Divider style={{marginTop:"4px",marginBottom:"4px"}}/>}
                      </>
                    ))}

                  </TableBody>}
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <div className="container " style={{ paddingTop: "30px" }}>
        <div className="row innovation-row">
          <div className="col-md-6 offset-1" innovation-col>
            <ReactPlayer
              playing={true}
              width="100%"
              controls={true}
              url={this.state.file}
            />
            <div className="row">
              <h5>START TODAY NOT TOMORROW</h5>
            </div>
            <div
              className="container"
              style={{ backgroundColor: "lightgrey", height: "100px" }}
            >
              <div className="row">
                <div className="col-md-4">
                  <h6
                    style={{
                      fontSize: "smaller",
                      position: "relative",
                      top: "25px",
                    }}
                  >
                    Posted On
                  </h6>
                  <p
                    style={{
                      fontSize: "smaller",
                      position: "relative",
                      top: "15px",
                    }}
                  >
                    11 aug, 2021
                  </p>
                </div>
                <div className="col-md-3 ">
                  <h6
                    style={{
                      fontSize: "smaller",
                      position: "relative",
                      top: "25px",
                    }}
                  >
                    Duration
                  </h6>
                  <p
                    style={{
                      fontSize: "smaller",
                      position: "relative",
                      top: "15px",
                    }}
                  >
                    20 minutes
                  </p>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "55px" }}>
                <h6>Message</h6>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Write Message"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
              </div>
              <div className="col" style={{ paddingTop: "25px" }}>
                <button
                  style={{
                    backgroundColor: "rgb(24, 169, 218)",
                    color: "white",
                    border: "none",
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <button
                style={{
                  backgroundColor: "blue",
                  border: "none",
                  height: "40px",
                }}
              >
                <FaLongArrowAltRight />
                SIMILAR VIDEOS
              </button>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                background: "#efefef",
                margin: "20px 0",
                alignItems: "center",
                borderRadius: "6px",
                overflow: "hidden",
                width: "85%",
              }}
            >
              <div className="col-md-5">
                <img
                  style={{ height: "60px", width: "100px" }}
                  src={require("../../assets/img/videoimg.jpeg").default}
                  alt=""
                />
                <button
                  style={{
                    height: "35px",
                    width: "35px",
                    border: "none",
                    position: "absolute",
                    left: "65%",

                    transform: "translate(-20%,-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    alert("Video will be played on development phase");
                  }}
                  className="rounded-circle"
                >
                  <FaPlayCircle size="35px" />
                </button>
              </div>
              <div className="col-md-7">
                <p>Lorem Ipsum 1</p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  9th august , 2021
                </p>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                background: "#efefef",
                margin: "20px 0",
                alignItems: "center",
                borderRadius: "6px",
                overflow: "hidden",
                width: "85%",
              }}
            >
              <div className="col-md-5">
                <img
                  style={{ height: "60px", width: "100px" }}
                  src={require("../../assets/img/videoimg.jpeg").default}
                  alt=""
                />
                <button
                  style={{
                    height: "35px",
                    width: "35px",
                    border: "none",
                    position: "absolute",
                    left: "65%",

                    transform: "translate(-20%,-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    alert("Video will be played on development phase");
                  }}
                  className="rounded-circle"
                >
                  <FaPlayCircle size="35px" />
                </button>
              </div>
              <div className="col-md-7">
                <p>Lorem Ipsum 1</p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  9th august , 2021
                </p>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                background: "#efefef",
                margin: "20px 0",
                alignItems: "center",
                borderRadius: "6px",
                overflow: "hidden",
                width: "85%",
              }}
            >
              <div className="col-md-5">
                <img
                  style={{ height: "60px", width: "100px" }}
                  src={require("../../assets/img/videoimg.jpeg").default}
                  alt=""
                />
                <button
                  style={{
                    height: "35px",
                    width: "35px",
                    border: "none",
                    position: "absolute",
                    left: "65%",

                    transform: "translate(-20%,-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    alert("Video will be played on development phase");
                  }}
                  className="rounded-circle"
                >
                  <FaPlayCircle size="35px" />
                </button>
              </div>
              <div className="col-md-7">
                <p>Lorem Ipsum 1</p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  9th august , 2021
                </p>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                background: "#efefef",
                margin: "20px 0",
                alignItems: "center",
                borderRadius: "6px",
                overflow: "hidden",
                width: "85%",
              }}
            >
              <div className="col-md-5">
                <img
                  style={{ height: "60px", width: "100px" }}
                  src={require("../../assets/img/videoimg.jpeg").default}
                />
                <button
                  style={{
                    height: "35px",
                    width: "35px",
                    border: "none",
                    position: "absolute",
                    left: "65%",

                    transform: "translate(-20%,-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    alert("Video will be played on development phase");
                  }}
                  className="rounded-circle"
                >
                  <FaPlayCircle size="35px" />
                </button>
              </div>
              <div className="col-md-7">
                <p>Lorem Ipsum 1</p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  9th august , 2021
                </p>
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "flex",
                background: "#efefef",
                margin: "20px 0",
                alignItems: "center",
                borderRadius: "6px",
                overflow: "hidden",
                width: "85%",
              }}
            >
              <div className="col-md-5">
                <img
                  style={{ height: "60px", width: "100px" }}
                  src={require("../../assets/img/videoimg.jpeg").default}
                />
                <button
                  style={{
                    height: "35px",
                    width: "35px",
                    border: "none",
                    position: "absolute",
                    left: "65%",

                    transform: "translate(-20%,-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    alert("Video will be played on development phase");
                  }}
                  className="rounded-circle"
                >
                  <FaPlayCircle size="35px" />
                </button>
              </div>
              <div className="col-md-7">
                <p>Lorem Ipsum 1</p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  9th august , 2021
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "200px" }}></div> */}
    </>
  );
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  demoPlayerBox: {
    width: "100%%",
    height: "400px",
    objectFit: "cover",
    // border: "1px solid black",
    // borderRadius: "1px",
  },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(VideoDetail);
