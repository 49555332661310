import React, { Component } from "react";
import { Form, FloatingLabel, Modal } from "react-bootstrap";
import { GrAddCircle } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import API from "../components/api";
import { ClipLoader } from "react-spinners";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  DialogContentText,
  TextareaAutosize,
  MenuItem,
  Alert,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DayPicker, DateFormatter } from "react-day-picker";
import "react-day-picker/style.css";
import { addDays, addMonths, isSameMonth, format } from "date-fns";

class AcademyNews extends Component {
  state = {
    page: 1,
    AcademyNewsdata: [],
    academyeditid: "",
    openacademymodal: false,
    editacademymsg: "",
    edittitle: "",
    editdesc: "",
    editdate: "",
    success: false,
    error: false,
    loading: false,
    adddate: "",
    addtitle: "",
    adddesc: "",
    selecteddate: new Date(),
  };
  componentDidMount() {
    this.getAcademynews();
    let d = new Date();
    let d1 = d.toLocaleDateString();
    let da = d1.split("/");
    this.setState({ adddate: da[2] + "-" + da[1] + "-" + da[0] });
  }
  changeSelectedDate = (dat) => {
    let d1 = dat.toLocaleDateString();
    let da = d1.split("/");
    console.log("newdate12345", dat.toLocaleDateString());
    this.setState({ adddate: da[2] + "-" + da[1] + "-" + da[0] });
  };
  AddAcademyNews() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "add",
      title: this.state.addtitle,
      description: this.state.adddesc,
      date: this.state.adddate,
    };
    var that = this;
    API.post("AcademyApi/news", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          addacademymsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, page: 1 });
          }, 2500);
          that.getAcademynews();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  Deleteacademynews() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "delete",

      id: this.state.academyeditid,
    };
    var that = this;
    API.post("AcademyApi/news", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          editacademymsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, openacademymodal: false });
          }, 2500);
          that.getAcademynews();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getAcademynews() {
    const params = {
      action: "view",
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("AcademyApi/news", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          AcademyNewsdata: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  openAcademyeditmodal(l) {
    this.setState({
      academyeditid: l.id,
      openacademymodal: true,
      editdate: l.date,
      editdesc: l.description,
      edittitle: l.title,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> ACADEMY NEWS</h1>
          <p>
            {" "}
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home{" "}
            </Link>
            |{" "}
            <Link
              href="/gameprofile"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              Game Academy
            </Link>{" "}
            | Academy News
          </p>
        </div>
        {this.state.page === 1 && (
          <>
            <Container>
              <Grid container spacing={2} marginY={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box>
                    <Typography variant="h5">ACADEMY NEWS</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box display="flex" style={{ justifyContent: "flex-end" }}>
                    <Box>
                      <Button
                        onClick={() => {
                          this.setState({ page: 2 });
                        }}
                        style={{ border: "1px solid black", color: "black" }}
                      >
                        Add News
                        <AddCircleOutlineIcon style={{ color: "lightgreen" }} />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container>
              <Grid container marginY={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "15%",
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box display="flex" flexDirection="column">
                    {this.state.AcademyNewsdata === undefined ? (
                      <Box>No News Added</Box>
                    ) : (
                      <>
                        {this.state.AcademyNewsdata.map((news, index) => {
                          return (
                            <>
                              <Box display="flex">
                                <Box style={{ width: "90%" }}>
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {news?.date}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {news?.title}
                                  </Typography>
                                  <Typography> {news?.description}</Typography>
                                </Box>
                                <Box style={{ height: "100%", width: "10%" }}>
                                  <Box>
                                    <button
                                      onClick={() => {
                                        this.openAcademyeditmodal(news);
                                      }}
                                      className={
                                        classes.tuitionTimeStatusButton
                                      }
                                    >
                                      <DeleteIcon style={{ color: "white" }} />
                                    </button>
                                  </Box>
                                </Box>
                              </Box>
                              {index !==
                                Number(this.state.AcademyNewsdata.length) -
                                  1 && (
                                <Divider
                                  style={{
                                    marginBottom: "12px",
                                    marginTop: "12px",
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                  </Box>
                </Grid>
                <Dialog
                  open={this.state.openacademymodal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure? You want to delete this news!
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Box display="flex" flexDirection="column">
                      <Box>
                        {this.state.error && (
                          <Alert severity="error">
                            {this.state.editacademymsg}
                          </Alert>
                        )}
                        {this.state.success && (
                          <Alert severity="success">
                            {this.state.editacademymsg}
                          </Alert>
                        )}
                      </Box>
                      <Box>
                        <Button
                          onClick={() =>
                            this.setState({ openacademymodal: false })
                          }
                        >
                          Cancel
                        </Button>
                        {this.state.loading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            autoFocus
                            style={{ backgroundColor: "red", color: "white" }}
                            onClick={() => {
                              this.Deleteacademynews();
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Container>
          </>
        )}
        {this.state.page === 2 && (
          <>
            <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <button
                      onClick={() => {
                        this.setState({
                          page: 1,
                        });
                      }}
                      className={classes.tuitionTimeStatusButton}
                    >
                      <ArrowBackIcon style={{ color: "white" }} />
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    style={{
                      justifyContent: "center",
                      border: "1px solid lightgrey",
                    }}
                    display="flex"
                  >
                    <Box>
                      <DayPicker
                        onDayClick={this.changeSelectedDate}
                        selected={new Date()}
                        mode="single"
                        styles={{
                          caption: { borderBottom: "1px solid lightgray" },
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box display="flex" flexDirection="column">
                    <Box
                      style={{
                        width: "100%",
                        height: "71px",
                        backgroundColor: "deepskyblue",
                      }}
                      display="flex"
                    >
                      <Box
                        marginX={4}
                        style={{ alignSelf: "center", color: "white" }}
                      >
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center", color: "white" }}>
                        <Typography>ADD NEWS</Typography>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{ justifyContent: "space-between" }}
                      padding={2}
                    >
                      <Box paddingY={1}>
                        <Typography>Subject</Typography>
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <TextField
                          fullWidth
                          name="game"
                          type="text"
                          value={this.state.addtitle}
                          onChange={(evt) => {
                            this.setState({ addtitle: evt.target.value });
                          }}
                        />
                      </Box>
                      <Box paddingTop={2} paddingBottom={1}>
                        <Typography>Description</Typography>
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <TextareaAutosize
                          style={{ width: "100%" }}
                          placeholder="Write Description"
                          maxLength={200}
                          minRows={4}
                          maxRows={4}
                          value={this.state.adddesc}
                          onChange={(evt) => {
                            this.setState({ adddesc: evt.target.value });
                          }}
                        />
                      </Box>
                      <Box>
                        {this.state.error && (
                          <Alert severity="error">
                            {this.state.addacademymsg}
                          </Alert>
                        )}
                        {this.state.success && (
                          <Alert severity="success">
                            {this.state.addacademymsg}
                          </Alert>
                        )}
                      </Box>
                      <Box display="flex">
                        {this.state.loading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "deepskyblue" }}
                            onClick={() => {
                              this.AddAcademyNews();
                            }}
                          >
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(AcademyNews);
