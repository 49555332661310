import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import API from "../components/api";
import { ClipLoader } from "react-spinners";
import { Link } from "@mui/material";
import { navigate } from "@reach/router";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

class Payment extends Component {
  state = {
    teacher: "",
    Teacherdata: "",
    TeachersList: [],
    Profile: "",
    fees: "",
    subsmsg: "",
    success: false,
    loading: false,
    Tuitiondata: [],
    tuitionID: "",
    Timings: [],
    timingid: "",
    classiError: "",
    createorder: "",
  };
  componentDidMount() {
    let fee = localStorage.getItem("tuitionfee");
    let tutionid = localStorage.getItem("tuitionID");
    let teacherid = localStorage.getItem("teacherid");
    this.setState({ teacher: teacherid, fees: fee, tuitionID: tutionid });
    this.getTeacherdetails();
    this.getStudentdetails();
  }
  getStudentdetails() {
    var that = this;
    API.post("StudentApi/profile", { user_id: localStorage.getItem("userID") })
      .then(function (response) {
        console.log(response);
        that.setState({
          Profile: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getTeacherdetails() {
    var that = this;
    API.post("StudentApi/teacherList")
      .then(function (response) {
        console.log(response);
        that.setState({
          TeachersList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        const result = that.state.TeachersList.find(
          ({ teacher_id }) => teacher_id === that.state.teacher
        );
        console.log("folafol", result.tutions);
        that.setState({
          Teacherdata: result,
          Tuitiondata: result.tutions,
          // tuitiontimings: result.tutions.timing,
        });
        const timings = result.tutions.find(
          ({ tution_id }) => tution_id === that.state.tuitionID
        );
        console.log("respo", timings.timing);
        that.setState({
          Timings: timings.timing,
          // tuitiontimings: result.tutions.timing,
        });
        // that.state.tuition.map((l, id) => {
        //   return that.setState({ tuitiontimings: l.timing });
        // });
        // console.log("l.tining", that.state.tuitiontimings);
      });
  }
  valid() {
    if (this.state.timingid.length < 1) {
      this.setState({
        classiError: "Choose Timing To Proceed",
      });
      return false;
    } else {
      return true;
    }
  }
  // takeSubscription() {
  //   this.setState({ loading: true });
  //   setTimeout(() => {
  //     this.setState({ loading: false });
  //   }, 2500);
  //   if (this.valid()) {
  //     this.setState({ classiError: "" });
  //     const params = {
  //       user_id: localStorage.getItem("userID"),
  //       action: "add",
  //       tution_id: localStorage.getItem("tuitionID"),
  //       tution_timing_id: this.state.timingid,
  //     };
  //     var that = this;
  //     API.post("StudentApi/tutionSubscription", params)
  //       .then(function (response) {
  //         console.log(response.data.result);
  //         that.setState({
  //           subsmsg: response.data.message,
  //         });

  //         that.setState({ success: true });
  //         setTimeout(() => {
  //           that.setState({ success: false });
  //         }, 2500);
  //         if (response.data.result === 1) {
  //           setTimeout(() => {
  //             navigate("/student/subcriptions");
  //           }, 2500);
  //         }
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         console.log(error);
  //       })
  //       .finally(function () {
  //         // always executed
  //         // const result = that.state.TeachersList.find(
  //         //   ({ teacher_id }) => teacher_id === that.state.teacher
  //         // );
  //         // console.log("folafol", result.tutions);
  //         // that.setState({
  //         //   Teacherdata: result,
  //         //   tuition: result.tutions,
  //         //   // tuitiontimings: result.tutions.timing,
  //         // });
  //         // that.state.tuition.map((l, id) => {
  //         //   return that.setState({ tuitiontimings: l.timing });
  //         // });
  //         // console.log("l.tining", that.state.tuitiontimings);
  //       });
  //   } else {
  //   }
  // }
  takeSubscription() {
    if (this.valid()) {
      const params = {
        user_id: localStorage.getItem("userID"),
        orderAmount: this.state.fees,
        name: this.state.Profile.name,
        email: this.state.Profile.email,
        mobile: this.state.Profile.mobile,
      };
      var that = this;
      API.post("StudentApi/createTeacherOrder", params)
        .then(function (response) {
          console.log(response.data.payment_link);
          that.setState({
            createorder: response.data,
          });
          navigate(`${response.data.payment_link}`);
          localStorage.setItem("orderID", response.data.order_id);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    } else {
    }
  }
  // updateTeachersubs() {
  //   const params = {
  //     user_id: localStorage.getItem("userID"),
  //     action: "add",
  //     tution_id: localStorage.getItem("tuitionID"),
  //     tution_timing_id: this.state.timingid,
  //   };
  //   var that = this;
  //   API.post("StudentApi/tutionSubscription", params)
  //     .then(function (response) {
  //       console.log(response.data.result);
  //       that.setState({
  //         subsmsg: response.data.message,
  //       });

  //       that.setState({ success: true });
  //       setTimeout(() => {
  //         that.setState({ success: false });
  //       }, 2500);
  //       if (response.data.result === 1) {
  //         setTimeout(() => {
  //           navigate("/student/subcriptions");
  //         }, 2500);
  //       }
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  //     .finally(function () {});
  // }
  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> TEACHERS</h1>
          <p>
            <Link href="/" style={{ color: "white", textDecoration: "none" }}>
              Home{" "}
            </Link>
            |
            <Link
              href="/userprofile"
              style={{ color: "white", textDecoration: "none" }}
            >
              {" "}
              Student{" "}
            </Link>{" "}
            |
            <Link
              href="/teachers"
              style={{ color: "white", textDecoration: "none" }}
            >
              {" "}
              Teacher{" "}
            </Link>
            |{" "}
            <Link
              href="/teacher/teacherdetail"
              style={{ color: "white", textDecoration: "none" }}
            >
              {" "}
              Book Seat
            </Link>{" "}
            | Check Out
          </p>
        </div>
        <div className="container" style={{ paddingTop: "30px" }}>
          <div className="row">
            <div className="col-md-6 offset-1">
              <h6 style={{ fontSize: "small" }}>Teacher Details</h6>
              <div className="row">
                <h6>{this.state.Teacherdata.name}</h6>
              </div>
              <div className="row">
                <h6 style={{ fontSize: "small" }}>Qualification</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Teacherdata.qualification}
                </p>
              </div>
              <div className="row">
                <h6 style={{ fontSize: "small" }}>Experience</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Teacherdata.experience}
                </p>
              </div>
              <div className="row">
                <h6 style={{ fontSize: "small" }}>Location</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Teacherdata.address}
                </p>
                {/* <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  Chinar Park , Rajarhat
                </p> */}
              </div>
            </div>
            <div className="col-md-4">
              <h6 style={{ fontSize: "small" }}>Student Details</h6>
              <div className="row">
                <h6>{this.state.Profile.name}</h6>

                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.school}
                </p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.class}
                </p>
              </div>
              <div className="row">
                <h6 style={{ fontSize: "small" }}>Mobile Number</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.mobile}
                </p>
              </div>

              <div className="row">
                <h6 style={{ fontSize: "small" }}>Email Address</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.email}
                </p>
              </div>
              <div className="row">
                <h6 style={{ fontSize: "small" }}>Location</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.address}
                </p>
                {/* <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  Chinar Park , Rajarhat
                </p> */}
              </div>
              <div className="row" style={{ paddingTop: "10px" }}>
                <h6 style={{ fontSize: "small" }}>Choose Timing</h6>
                {<span className="error">{this.state.classiError}</span>}

                {this.state.Timings.map((l, id) => {
                  return (
                    <div className="col-md-8" style={{ paddingBottom: "3vh" }}>
                      <Form.Check
                        value={l.id}
                        onChange={(e) => {
                          this.setState({ timingid: e.target.value });

                          localStorage.setItem(
                            "tutiontimingID",
                            e.target.value
                          );
                        }}
                        type="radio"
                        style={{ opacity: "0.6", fontSize: "smaller" }}
                        label={`${l.from_time} - ${l.to_time}`}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios4"
                      />
                      {/* <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          defaultValue="female"
                          name="radio-buttons-group"
                          onChange={(e) => {
                            // this.setState({ timingid: e.target.value });
                            alert("timing", e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value={l.id}
                            control={<Radio />}
                            label={l.from_time}
                          />
                        </RadioGroup>
                      </FormControl> */}
                    </div>
                  );
                })}
              </div>
              <div className="row" style={{ paddingTop: "10px" }}>
                <h6 style={{ fontSize: "small" }}>Tuition Fee</h6>
                <div className="col-md-5">
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>Total</p>
                </div>
                <div className="col-md-5">
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    {this.state.fees}
                  </p>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "10px" }}>
                {/* <h6 style={{ fontSize: "small" }}>Select Payment Method</h6>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    style={{ opacity: "0.6" }}
                    label="Net Banking"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    style={{ opacity: "0.6" }}
                    type="radio"
                    label="Visa / Master"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  />
                  <Form.Check
                    style={{ opacity: "0.6" }}
                    type="radio"
                    label="Pay On Home"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios3"
                  />
                </Form.Group> */}
                <div className="col" style={{ paddingTop: "20px" }}>
                  <Button onClick={() => this.takeSubscription()}>
                    {this.state.loading ? (
                      <span>
                        {" "}
                        <ClipLoader color="white" size={20} />
                        Please Wait{" "}
                      </span>
                    ) : (
                      <span>Pay Now</span>
                    )}
                  </Button>
                </div>
                {this.state.success ? (
                  <div
                    style={{
                      marginTop: "3vh",
                      color: "white",
                      backgroundColor: "lightgreen",
                    }}
                  >
                    {this.state.subsmsg}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Payment;
