import React, { Component } from "react";
import { Form } from "react-bootstrap";
import API from "../components/api";
import { GiBarbedSpear } from "react-icons/gi";
import { CgDanger } from "react-icons/cg";
import ClipLoader from "react-spinners/ClipLoader";
import { navigate } from "@reach/router";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";

export default class Observerreg extends Component {
  state = {
    nameError: "",
    SchoolList: [],
    signuperror: "",
    mobileError: "",
    emailError: "",
    passwordError: "",
    RepasswordError: "",
    empError: "",
    loading: false,
    schoolError: "",
    success: false,
    error: false,
    name: "",
    emp: "",
    repass: "",
    password: "",
    res: [],
    mobile: "",

    email: "",
    Image: "",
    Image1: "",
    schools: "",
  };
  componentDidMount() {
    localStorage.setItem("userID", "");
  }
  handlenameChange(evt) {
    this.setState({ name: evt.target.value });
    console.log("name: ", this.state.name);
  }
  handlepasswordChange(evt) {
    this.setState({ password: evt.target.value });
  }

  handlemobileChange(evt) {
    const { name, value } = evt.target;
    if (value.length <= 10) {
      this.setState({ mobile: evt.target.value });
      console.log("mobile: ", this.state.mobile);
    }
  }
  handleemailChange(evt) {
    this.setState({ email: evt.target.value });
    console.log("mail: ", this.state.email);
  }

  valid2() {
    if (this.state.name.length < 5) {
      this.setState({
        nameError: "Name is a mandatory field & must be over 5 letter",
      });
      return false;
    }
    if (this.state.schools.length < 1) {
      this.setState({
        schoolError: "School is a mandatory field.",
      });
      return false;
    }
    if (this.state.mobile.length < 10) {
      this.setState({
        mobileError:
          "Mobile number is a mandatory field & must be with 10 digits.",
      });
      return false;
    }
    if (!this.state.email.includes("@")) {
      this.setState({
        emailError: "Email is a mandatory field & must include a @.",
      });
      return false;
    }

    if (this.state.emp.length < 5) {
      this.setState({
        empError:
          "Employee Id is a mandatory field & must be with minimum of 5 characters",
      });
      return false;
    }
    if (this.state.password.length < 8) {
      this.setState({ passwordError: "Password Must be atleast 8 characters" });
      return false;
    }
    if (this.state.password != this.state.repass) {
      this.setState({ RepasswordError: "Confirm password is not matching" });
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    this.getSchoolList();
  }
  getSchoolList() {
    var that = this;
    API.get("/MasterApi/school_list")
      .then(function (response) {
        console.log(response);
        that.setState({
          SchoolList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  schoolSelected = (e) => {
    this.setState({ schools: e.target.value });

    console.log("schoold", e.target.value);
    // this.setState({ stateloading: true })
  };
  Submit() {
    this.setState({
      emailError: "",
      mobileError: "",
      schoolError: "",
      nameError: "",
      empError: "",
      passwordError: "",
      RepasswordError: "",
    });
    if (this.valid2()) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1500);
      var that = this;
      let params = {
        user_type: 2,
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.email,
        password: this.state.password,
        school_id: this.state.schools,
        emp_id: this.state.emp,
        school_code: "",
      };
      const head = {
        headers: {
          "Content-Type": "application/json",
          Source: "web",
        },
      };
      API.post("SchoolApi/registration", params, head)
        .then(function (response) {
          console.log(response.data.result);
          that.setState({
            res: response.data.result,
            signuperror: response.data.message,
          });
        })

        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          if (that.state.res === 1) {
            that.setState({ success: true });
            setTimeout(() => {
              that.setState({ success: false });
            }, 3000);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            that.setState({ error: true });
            setTimeout(() => {
              that.setState({ error: false });
            }, 3000);
          }
        });
    } else {
    }
  }

  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> OBSERVER REGISTER</h1>
          <p style={{ fontSize: "0.2" }}>Home | Observer Register</p>
        </div>
        <Container style={{ marginTop: "20px", marginBottom: "20px" }}>
          <h6>*Required Field</h6>
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Category *</p>
              <FormControl variant="filled" fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="category"
                  input={<OutlinedInput />}
                  value={3}
                  disabled
                >
                  <MenuItem value={3}>School Observer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Name *</p>
              <FormControl variant="filled" style={{ width: "100%" }}>
                <TextField
                  value={this.state.name}
                  onChange={(evt) => {
                    this.handlenameChange(evt);
                  }}
                  type="name"
                  fullWidth
                />
              </FormControl>
              {<span className="error">{this.state.nameError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>School *</p>
              <FormControl variant="filled" fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="category"
                  input={<OutlinedInput />}
                  onChange={this.schoolSelected}
                  value={this.state.schools}
                >
                  {this.state.SchoolList.map((l, i) => {
                    return (
                      <MenuItem key={i} value={l.school_id}>
                        {l.name}({l.school_code})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {<span className="error">{this.state.schoolError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Mobile *</p>
              <FormControl variant="filled" style={{ width: "100%" }}>
                <TextField
                  maxLength={10}
                  value={this.state.mobile}
                  onChange={(evt) => {
                    this.handlemobileChange(evt);
                  }}
                  type="number"
                  fullWidth
                />
              </FormControl>
              {<span className="error">{this.state.mobileError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Email *</p>
              <FormControl variant="filled" style={{ width: "100%" }}>
                <TextField
                  value={this.state.email}
                  onChange={(evt) => {
                    this.handleemailChange(evt);
                  }}
                  type="email"
                  fullWidth
                />
              </FormControl>
              {<span className="error">{this.state.emailError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Emp Id *</p>
              <FormControl variant="filled" style={{ width: "100%" }}>
                <TextField
                  value={this.state.emp}
                  onChange={(evt) => {
                    this.setState({ emp: evt.target.value });
                  }}
                  type="text"
                  fullWidth
                />
              </FormControl>
              {<span className="error">{this.state.empError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Password *</p>
              <FormControl variant="filled" style={{ width: "100%" }}>
                <TextField
                  onChange={(evt) => {
                    this.handlepasswordChange(evt);
                  }}
                  value={this.state.password}
                  type="text"
                  fullWidth
                />
              </FormControl>
              {<span className="error">{this.state.passwordError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Confirm Password *</p>
              <FormControl variant="filled" style={{ width: "100%" }}>
                <TextField
                  onChange={(evt) => {
                    this.setState({ repass: evt.target.value });
                  }}
                  type="text"
                  fullWidth
                />
              </FormControl>
              {<span className="error">{this.state.RepasswordError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" flexDirection="column">
                <Box>
                  {this.state.error && (
                    <Alert severity="error">{this.state.signuperror}</Alert>
                  )}
                  {this.state.success && (
                    <Alert severity="success">Registration Successful</Alert>
                  )}
                </Box>
                <Box
                  display="flex"
                  marginTop={2}
                  justifyContent="space-between"
                >
                  {this.state.loading ? (
                    <CircularProgress />
                  ) : (
                    <Box>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "deepskyblue" }}
                        onClick={() => {
                          this.Submit();
                        }}
                      >
                        Register
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* <div className="container" style={{ paddingTop: "10vh" }}>
          <div className="col offset-2">
            {" "}
            <h6>*Required Field</h6>
          </div>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-4 offset-2">
              <p style={{ opacity: "0.6" }}>Category *</p>
              <Form.Select disabled aria-label="Default select example">
                <option>School Observer</option>
              </Form.Select>
            </div>
            <div class="col-md-4">
              <p style={{ opacity: "0.6" }}>Name *</p>
              <Form>
                <Form.Control
                  value={this.state.name}
                  onChange={(evt) => {
                    this.handlenameChange(evt);
                  }}
                  type="name"
                  placeholder=""
                />
              </Form>
              {<span className="error">{this.state.nameError}</span>}
            </div>
          </div>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-4 offset-2">
              <p style={{ opacity: "0.6" }}>School *</p>
              <Form.Select
                onChange={this.schoolSelected}
                required
                value={this.state.schools}
                aria-label="Default select example"
              >
                <option></option>
                {this.state.SchoolList.map((l, i) => {
                  return (
                    <option key={i} value={l.school_id}>
                      {l.name}({l.school_code})
                    </option>
                  );
                })}
              </Form.Select>
              {<span className="error">{this.state.schoolError}</span>}
            </div>
            <div class="col-md-4">
              <p style={{ opacity: "0.6" }}>Mobile Number *</p>
              <Form>
                <Form.Control
                  maxLength={10}
                  required
                  value={this.state.mobile}
                  onChange={(evt) => {
                    this.handlemobileChange(evt);
                  }}
                  type="number"
                  placeholder=""
                />
              </Form>
              {<span className="error">{this.state.mobileError}</span>}
            </div>
          </div>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-4 offset-2">
              <p style={{ opacity: "0.6" }}>Email Address *</p>
              <Form>
                <Form.Control
                  value={this.state.email}
                  onChange={(evt) => {
                    this.handleemailChange(evt);
                  }}
                  type="email"
                  placeholder=""
                />
              </Form>
              {<span className="error">{this.state.emailError}</span>}
            </div>
            <div className="col-md-4 ">
              <p style={{ opacity: "0.6" }}>Emp Id *</p>
              <Form>
                <Form.Control
                  value={this.state.emp}
                  onChange={(evt) => {
                    this.setState({ emp: evt.target.value });
                  }}
                  type="text"
                  placeholder=""
                />
              </Form>
              {<span className="error">{this.state.empError}</span>}
            </div>
          </div>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div class="col-md-4 offset-2">
              <p style={{ opacity: "0.6" }}>Password *</p>
              <Form>
                <Form.Control
                  onChange={(evt) => {
                    this.handlepasswordChange(evt);
                  }}
                  value={this.state.password}
                  type="text"
                  placeholder=""
                />
              </Form>
              {<span className="error">{this.state.passwordError}</span>}
            </div>
            <div class="col-md-4">
              <p style={{ opacity: "0.6" }}> Confirm Password *</p>
              <Form>
                <Form.Control
                  // onChange={(evt) => {
                  //   this.state.repass
                  // }}
                  type="text"
                  placeholder=""
                />
              </Form>
              {<span className="error">{this.state.RepasswordError}</span>}
            </div>
          </div>

          <div
            className="container"
            style={{ paddingLeft: "33vh", marginTop: "5vh" }}
          >
            <div className="row">
              {this.state.success ? (
                <div className="row">
                  <div
                    className="col-md-8 offset-2"
                    style={{
                      paddingTop: "45px",
                      position: "relative",
                      top: "4vh",
                      backgroundColor: "green",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      <GiBarbedSpear /> Registration Successful
                    </h5>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.error ? (
                <div className="row">
                  <div
                    className="col-md-8 offset-2"
                    style={{
                      paddingTop: "45px",
                      position: "relative",
                      top: "4vh",
                      backgroundColor: "red",
                      textAlign: "center",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      <CgDanger /> {this.state.signuperror}
                    </h5>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Button
              variant="primary"
              style={{ borderRadius: "0px" }}
              onClick={() => {
                this.Submit();
              }}
            >
              {this.state.loading ? (
                <span>
                  {" "}
                  <ClipLoader color="white" size={20} />
                  Please Wait{" "}
                </span>
              ) : (
                <span>Submit</span>
              )}
            </Button>
          </div>
        </div> */}
      </div>
    );
  }
}
