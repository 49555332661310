import React, { Component, useState } from "react";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from "../provider/useWindowSize";
import ReactPlayer from "react-player";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";

const CourseVideo = ({ videoList, classes }) => {
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [playVideo, setPlayVideo] = useState("");

  const openVideo = (video) => {
    setOpenVideoDialog(true);
    setPlayVideo(video);
  };
  return (
    <>
      <Typography variant="h5" style={{ paddingLeft: "22px" }}>
        COURSE VIDEO
      </Typography>
      <div
        style={{
          backgroundColor: "rgb(24, 169, 218)",
          height: "2px",
          width: "160px",
          marginLeft: "23px",
        }}
      ></div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          {videoList?.map((video) => (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* <Link href="/innovationvideo" style={{textDecoration:"none", color:"black"}}> */}
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={video?.thumbnail_file}
                    alt="green iguana"
                    onClick={() => openVideo(video)}
                  />
                  <CardContent>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" justifyContent="flex-end">
                        <Box>
                          <a target="_blank" href={video?.pdf_file}>
                            <Button variant="text">
                              <PictureAsPdfIcon />
                              <FileDownloadIcon />
                            </Button>
                          </a>
                        </Box>
                      </Box>
                      <Box onClick={() => openVideo(video)}>
                        <Typography gutterBottom variant="h6">
                          {video?.name}
                        </Typography>
                        <Typography fontWeight="bold">
                          {video?.chapter}
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                          {video?.subject}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
              {/* </Link> */}
            </Grid>
          ))}
        </Grid>

        <Dialog open={openVideoDialog} maxWidth="lg" fullWidth>
          <DialogTitle style={{ padding: "0", backgroundColor: "dimgrey" }}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              style={{ padding: "0px", color: "white" }}
              onClick={() => setOpenVideoDialog(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: "0px", backgroundColor: "dimgrey" }}>
            {/* <ReactPlayer
              url={playVideo?.file}
              playing="true"
              width='100%'
              controls
              height='100%'
              loop="false"
              muted="false" /> */}
            <video
              width="100%"
              height="600"
              controls
              src={playVideo?.file}
              type="video/mp4"
            ></video>
          </DialogContent>
        </Dialog>
      </Container>
      {/* <div style={{ height: "2px", width: "80px", backgroundColor: "rgb(24, 169, 218)" }}></div>
      <div className="container" style={{ position: "relative", top: "20px" }}>
        <div className="row">
          <div
            className="col-md-4"
            style={{ paddingRight: "10px" }}
          >
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <Link href="/innovationvideo">
                  {" "}
                  <img
                    className="card-img-top"
                    src={
                      require("../assets/img/coding.jpeg")
                        .default
                    }
                    alt="Card  cap"
                  />
                </Link>
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "2rem" }}>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  closeButton: {
    position: "absolute",
    left: "97%",
  },
  // profileheading: {
  //   position: "absolute",
  //   top: "41%",
  //   left: "16%",
  //   color: "white"
  // },
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(CourseVideo);
