import React, { Component, useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  DialogContentText,
  TextareaAutosize,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from "../../provider/useWindowSize";
import API from "../../components/api";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const News = ({ classes }) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const { sm, md, lg } = theme.breakpoints.values;
  const [newsList, setNewsList] = useState([]);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false);
  const [editNewsDialog, setEditNewsDialog] = useState(false);
  const [addNewsDialog, setAddNewsDialog] = useState(false);
  const [addNewsData, setAddNewsData] = useState("");
  const [addNewsButtonVisibility, setAddNewsButtonVisibility] = useState(false);
  const [editNewsButtonVisibility, setEditNewsButtonVisibility] =
    useState(false);

  const [deletingNews, setDeletingNews] = useState("");
  const [editNewsBackup, setEditNewsBackup] = useState("");
  const [editNews, setEditNews] = useState({
    user_id: "",
    id: "",
    action: "",
    date: "",
    description: "",
    title: "",
  });
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    getNewsList();
  }, []);
  const getNewsList = () => {
    let user = localStorage.getItem("userID");
    let payload = {
      user_id: user,
      action: "view",
    };
    API.post("TeacherApi/news", payload).then((res) => {
      if (res.data.result === 1) {
        console.log("newsList", res);
        if (res.data.message === 'No News found.') {
          setNewsList([])
        } else {
          setNewsList(res.data.data)
        }

      } else {
        setNewsList([]);
      }
    });
  };

  const deleteNewsDialog = (news) => {
    setDeleteConfirmationDialog(true);
    setDeletingNews(news);
  };

  const editNewsDialogOpen = (newss) => {
    let news = newss;
    let d = String(news.date).split(",");
    let d2 = String(d[0]).split(" ");
    let mon = Number(months.indexOf(d2[1]));
    let month = mon + 1;
    let dates = d[1] + "-" + d2[0] + "-" + month;

    console.log("d", d);
    console.log("d2", d2);

    console.log("dmon", mon);
    console.log("dates1234", dates);

    // if (editNews) {
    //   alert(1)
    //   setEditNews({
    //     ...editNews,
    //     date: dates
    //   })
    // }
    setEditNews({
      ...editNews,
      user_id: localStorage.getItem("userID"),
      action: "update",
      id: newss.id,
      date: dates,
      title: newss.title,
      description: newss.description,
    });
    // news.date = dates
    // setEditNews(news)
    // console.log("editNews", news);

    setEditNewsDialog(true);
  };

  console.log("editNews", editNews);

  const deleteNews = () => {
    console.log("sdfgsdggdgd123", deletingNews);

    let user = localStorage.getItem("userID");
    let payload = {
      user_id: user,
      action: "delete",
      id: deletingNews.id,
    };
    API.post("TeacherApi/news", payload).then((res) => {
      if (res.data.result === 1) {
        console.log("newsList", res);
        getNewsList();
        setDeletingNews("");
        setDeleteConfirmationDialog(false);
      }
    });
  };

  const handleClose = () => {
    setDeleteConfirmationDialog(false);
    setDeletingNews("");
  };

  const handleEditClose = () => {
    setEditNewsDialog(false);
    setEditNews("");
  };

  const handleAddClose = () => {
    setAddNewsDialog(false);
    setAddNewsData("");
  };

  const openAddNews = () => {
    setAddNewsData({
      user_id: localStorage.getItem("userID"),
      action: "add",
      date: "",
      description: "",
      title: "",
    });
    setAddNewsDialog(true);
  };

  const addNews = () => {
    API.post("TeacherApi/news", addNewsData).then((res) => {
      if (res.data.result === 1) {
        console.log("newsList", res);
        getNewsList();
        setAddNewsData("");
        setAddNewsDialog(false);
      }
    });
  };

  const getAddNewsData = (e) => {
    const { name, value } = e.target;
    setAddNewsData({
      ...addNewsData,
      [name]: value
    })


  }

  useEffect(() => {
    if (addNewsData?.title && addNewsData?.description && addNewsData?.date) {
      setAddNewsButtonVisibility(true)
    } else {
      setAddNewsButtonVisibility(false)
    }
  }, [addNewsData])

  useEffect(() => {
    if (editNews?.title && editNews?.description && editNews?.date) {
      setEditNewsButtonVisibility(true)
    } else {
      setEditNewsButtonVisibility(false)
    }
  }, [editNews])


  const getEditNewsData = (e) => {
    const { name, value } = e.target;
    setEditNews({
      ...editNews,
      [name]: value
    })
    console.log("editNews1234", value);
  }

  const updateNews = () => {
    setEditNews({
      ...editNews,
      user_id: localStorage.getItem("userID"),
      action: "update",
    });
    API.post("TeacherApi/news", editNews).then((res) => {
      if (res.data.result === 1) {
        console.log("newsList", res);
        getNewsList();
        setEditNews("");
        setEditNewsDialog(false);
      }
    });
  };

  return (
    <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
        <h1>NEWS</h1>
        <p>
          <Link href="/" style={{ color: "white", textDecoration: "none" }}>
            Home
          </Link>{" "}
          | Teacher | News
        </p>
      </div>
      <Container style={{ marginTop: "20px"}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box>
              <Typography variant="h5" style={{ paddingLeft: "8px" }}>
                NEWS
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              display="flex"
              style={{ justifyContent: width < sm ? "flex-start" : "flex-end" }}
            >
              <Box>
                <Button
                  onClick={openAddNews}
                  style={{ border: "1px solid black", color: "black" }}
                >
                  Add News
                  <AddCircleOutlineIcon className={classes.addTuitionButton} />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container style={{marginBottom: "20px"}}>
        <Grid container marginY={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div
              style={{
                backgroundColor: "rgb(24, 169, 218)",
                height: "2px",
                width: "15%",
              }}
            ></div>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>

        {newsList.length > 0 &&
          <Box display="flex" flexDirection="column" style={{ width: "100%" }} marginY={2}>
            {newsList.map((news, index) =>
              <>
                <Box display="flex">
                  <Box style={{ width: "90%" }}>
                    <Box>
                      <Typography style={{ fontWeight: "bold" }}>{news?.date}</Typography>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>{news?.title}</Typography>
                      <Typography> {news?.description}</Typography>
                    </Box>
                  </Box>
                  <Box style={{ width: "10%" }} display="flex" justifyContent="center">
                    <Box marginY={8}>
                      <button
                        onClick={() => editNewsDialogOpen(news)}
                        className={classes.tuitionTimeStatusButton}>
                        <EditIcon style={{ color: "white" }} />
                      </button>
                    </Box>
                    <Box marginY={8}>
                      <button
                        onClick={() => deleteNewsDialog(news)}
                        className={classes.tuitionTimeStatusButton}>
                        <DeleteIcon style={{ color: "white" }} />
                      </button>
                    </Box>

                  </Box>

                </Box>
                {index !== (Number(newsList.length) - 1) && <Divider style={{ marginBottom: "8px", marginTop: "8px" }} />}
              </>

            )}
          </Box>}
        {newsList.length === 0 &&
          <Box display="flex" justifyContent="center">
            <Box><Typography>News Not Found</Typography></Box>
          </Box>
        }

        <Dialog
          open={editNewsDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit News</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Date *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="date"
                    variant="outlined"
                    type="date"
                    value={editNews.date}
                    onChange={getEditNewsData}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Title *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="title"
                    variant="outlined"
                    type="text"
                    value={editNews.title}
                    onChange={getEditNewsData}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Description *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextareaAutosize
                    name="description"
                    variant="outlined"
                    placeholder="Write Description ( Maximum 1000 charcters )"
                    maxLength={1000}
                    minRows={6}
                    maxRows={6}
                    value={editNews.description}
                    onChange={getEditNewsData}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Cancel</Button>
            {editNewsButtonVisibility ?
              <Button autoFocus onClick={updateNews} variant="contained" style={{ backgroundColor: "deepskyblue" }}>
                Update
              </Button>
              :
              <Button variant="contained" disabled>
                Update
              </Button>}
          </DialogActions>
        </Dialog>

        <Dialog
          open={addNewsDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Add News</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Date *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="date"
                    variant="outlined"
                    type="date"
                    value={addNewsData.date}
                    onChange={getAddNewsData}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Title *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="title"
                    variant="outlined"
                    type="text"
                    value={addNewsData.title}
                    onChange={getAddNewsData}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Description *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextareaAutosize
                    name="description"
                    variant="outlined"
                    placeholder="Write Description ( Maximum 1000 charcters )"
                    maxLength={1000}
                    minRows={6}
                    maxRows={6}
                    value={addNewsData.description}
                    onChange={getAddNewsData}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddClose}>Cancel</Button>
            {addNewsButtonVisibility ? <Button autoFocus onClick={addNews} variant="contained" style={{ backgroundColor: "deepskyblue" }}>
              Add
            </Button>
              :
              <Button disabled variant="contained" >
                Add
              </Button>
            }
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteConfirmationDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure? You want to delete this news!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button autoFocus onClick={deleteNews}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      {/* <div className="container" style={{ paddingTop: "20px" }}>
        <div className="col offset-1">
          <h5>NEWS</h5>
          <div
            style={{
              height: "2px",
              width: "80px",
              textDecoration: "underline",
              backgroundColor: "rgb(24, 169, 218)",
            }}
          ></div>
        </div>
        <div className="row" style={{ paddingTop: "20px" }}>
          <div class="col-sm-9 offset-1">
            <div class="card">
              <div class="card-body">
                <p1>28-07-2021</p1>
                <h5 class="card-title">
                  Parents and Educationists divided on School
                  reopening
                </h5>
                <p1 class="card-text">
                  With supporting text below as a natural lead-in
                  to additional content.
                </p1>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <button
              className="rounded-circle"
              style={{
                backgroundColor: "lightgreen",
                height: "40px",
                width: "40px",
                border: "none",
              }}
            >
              <AiFillDelete color="white" />
            </button>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "5px" }}>
          <div class="col-sm-9 offset-1">
            <div class="card">
              <div class="card-body">
                <p1>28-07-2021</p1>
                <h5 class="card-title">
                  Parents and Educationists divided on School
                  reopening
                </h5>
                <p1 class="card-text">
                  With supporting text below as a natural lead-in
                  to additional content.
                </p1>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <button
              className="rounded-circle"
              style={{
                backgroundColor: "lightgreen",
                height: "40px",
                width: "40px",
                border: "none",
              }}
            >
              <AiFillDelete color="white" />
            </button>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
      </div> */}
    </>
  );
};
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(News);
