import React, { Component } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Form, FloatingLabel, Modal } from "react-bootstrap";
import API from "./api";
import { GrAddCircle } from "react-icons/gr";
import ClipLoader from "react-spinners/ClipLoader";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  DialogContentText,
  TextareaAutosize,
  MenuItem,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListIcon from "@mui/icons-material/List";
import SaveIcon from "@mui/icons-material/Save";

class ObserverSciencefair extends Component {
  state = {
    btninvent: true,
    editinvdate: "",
    editinvtitle: "",
    editinvclass: "",
    editinvdescription: "",
    openinvdeletemodal: false,
    editinvention: false,
    inventionaddmessage: "",
    inventionbtn: false,
    inventtiontitle: "",
    inventiondescription: "",
    inventiondate: "",
    btnparticipate: false,
    btnmenu: false,
    btnmessage: false,
    science_fair_report_button: false,
    classselected: "",
    guidemodal: false,
    addmessagemsg: "",
    addinvention: false,
    guidedesc: "",
    guidemsg: "",
    fair: 1,
    newsmessage: "",
    loading: false,
    invntnmsg: "",
    addnewsmsg: "",
    inventionID: "",
    Messages: [],
    success: false,
    error: false,
    addmessage: false,
    addnews: false,
    subject: "",
    newsdate: "",
    newssubject: "",
    description: "",
    newsdescription: "",
    News: [],
    Guideline: "",
    Inventiondata: [],
    classlist: [],
    id: "",
    delid: "",
    deleteinv: false,
    deletemsgid: "",
    deletemsgmodal: false,
    deletemsgmodalmsg: "",
    newsdltid: "",
    newsdltmodal: false,
    newsdltmsg: "",
    participateList: [],

    science_fair_add: false,
    science_fair_list: false,
    buttonAccessible: true,
    science_fair_school_id: "",
    science_fair_class_id: "",
    science_fair_invention_id: "none",
    science_fair_exam_date: "",
    science_fair_report: [],
    science_fair_action: "add",
    schoolList: [],
    inventionListForReport: [],
    classListForReport: [],
    school_id: "",
    scienceFairReportList: [],
    showSubmitButton: true,
    showButon: false,
    student_id: "",
    marks: "",
    grade: "",
  };
  openinvdeletemodal(l) {
    this.setState({ delid: l.id, deleteinv: true });
  }
  openmsgdeletemodal(l) {
    this.setState({ deletemsgid: l.id, deletemsgmodal: true });
  }
  DeleteInvention() {
    this.setState({ loading: true });

    const params = {
      id: this.state.delid,
      school_id: localStorage.getItem("school_id"),
      action: "delete",
    };
    var that = this;
    API.post("SchoolApi/inventions", params)
      .then(function (response) {
        console.log(response);
        that.setState({ loading: false });
        that.setState({
          deleteinv: false,
        });
        that.getinventionlist();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  opennewsdeletemodal(l) {
    console.log("id", l.id);
    this.setState({ newsdltid: l.id, newsdltmodal: true });
  }
  openinveditmodal(l) {
    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let startDate = l?.date;
    let splitStartDate = startDate.split(",");
    let splitStartDateSecond = splitStartDate[0].split(" ");
    let startMonth = Number(monthList.indexOf(splitStartDateSecond[1])) + 1;
    let acctualMonth = Number(startMonth) < 10 ? "0" + startMonth : startMonth;

    this.setState({
      editinvtitle: l.name,
      editinvdescription: l.description,
      editinvdate:
        splitStartDate[1] + "-" + acctualMonth + "-" + splitStartDateSecond[0],
      editinvclass: l.class_id,
    });
    this.setState({ editinvention: true, id: l.id });
    console.log(
      "fgsdgdfg1234567",
      splitStartDate[1] + "-" + acctualMonth + "-" + splitStartDateSecond[0]
    );
  }
  DeleteMessage() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    const params = {
      id: this.state.deletemsgid,
      school_id: localStorage.getItem("school_id"),
      action: "delete",
    };
    var that = this;
    API.post("SchoolApi/messages", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          deletemsgmodalmsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, deletemsgmodal: false });
          }, 2000);
          that.getmessages();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  EditInvention() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    const params = {
      id: this.state.id,
      school_id: localStorage.getItem("school_id"),
      action: "update",
      date: this.state.editinvdate,
      name: this.state.editinvtitle,
      description: this.state.editinvdescription,
      class_id: this.state.editinvclass,
    };
    var that = this;
    API.post("SchoolApi/inventions", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          editinvention: false,
        });
        that.getinventionlist();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  AddnewInvention() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "add",
      date: this.state.inventiondate,
      name: this.state.inventtiontitle,
      description: this.state.inventiondescription,
      class_id: this.state.classselected,
    };
    var that = this;
    API.post("SchoolApi/inventions", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          inventionaddmessage: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, addinvention: false });
          }, 2000);
          that.getinventionlist();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  UpdateGuideline() {
    var that = this;
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "update",
      description: this.state.guidedesc,
    };
    API.post("SchoolApi/inventionGuideline", params)
      .then(function (response) {
        console.log(response.data.description);
        that.setState({
          success: true,
          guidemsg: response.data.message,
        });
        setTimeout(() => {
          that.setState({ success: false, guidemodal: false });
        }, 2500);
        that.getinventionguideline();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  componentDidMount() {
    this.getSchoolList();
    // this.getClassListForReport();
    this.getInventionListForReport();
    this.getmessages();
    this.getnews();
    this.getinventionguideline();
    this.getinventionlist();
    this.getclassList();
    this.getparticipations();
  }

  getScienceFairReportList() {
    let payload = {
      class_id: this.state.science_fair_class_id,
      school_id: this.state.science_fair_school_id,
      invention_id:
        this.state.science_fair_invention_id !== "none"
          ? this.state.science_fair_invention_id
          : "",
      exam_date: this.state.science_fair_exam_date,
      action: "view",
    };
    API.post("SchoolApi/scienceFairReport", payload).then((resp) => {
      console.log("schoolList", resp.data);
      if (resp.data.result === 1) {
        this.setState({ scienceFairReportList: resp.data.data });
      } else {
        this.setState({ scienceFairReportList: [] });
      }
    });
  }

  getSchoolList() {
    API.post("MasterApi/school_list").then((resp) => {
      console.log("schoolList", resp.data);
      if (resp.data.result === 1) {
        this.setState({ schoolList: resp.data.data });
      } else {
        this.setState({ schoolList: [] });
      }
    });
  }
  getClassListForReport(e) {
    this.setState({
      school_id: e.target.value,
      science_fair_school_id: e.target.value,
    });
    if (
      e.target.value &&
      this.state.science_fair_class_id &&
      this.state.science_fair_invention_id &&
      this.state.science_fair_invention_id !== "none" &&
      this.state.science_fair_exam_date
    ) {
      this.setState({
        buttonAccessible: false,
      });
    }
    API.post("MasterApi/class_list", {
      school_id: e.target.value,
    }).then((resp) => {
      if (resp.data.result === 1) {
        this.setState({ classListForReport: resp.data.data });
      } else {
        this.setState({ classListForReport: [] });
      }
    });
  }
  getInventionListForReport() {
    let payload = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    API.post("SchoolApi/inventions", payload).then((resp) => {
      if (resp.data.result === 1) {
        this.setState({ inventionListForReport: resp.data.data });
      } else {
        this.setState({ inventionListForReport: [] });
      }
    });
  }
  getInventionId(e) {
    this.setState({
      science_fair_invention_id: e.target.value,
      inventionID: e.target.value,
    });

    if (e.target.value && this.state.science_fair_exam_date) {
      this.setState({
        buttonAccessible: false,
      });
    }
    this.getparticipations(e.target.value);
  }
  getReport(e, i, id) {
    const { name, value } = e.target;
    // let singleReport={}

    this.setState({ student_id: id });
    if (name === "marks") {
      this.setState({ marks: e.target.value });
    }
    if (name === "grade") {
      this.setState({ grade: e.target.value });
    }
  }
  saveCurrentReport(i) {
    let reportSingle = {
      student_id: "",
      marks: "",
      grade: "",
    };
    if (this.state.student_id && this.state.marks && this.state.grade) {
      reportSingle.student_id = this.state.student_id;
      reportSingle.marks = this.state.marks;
      reportSingle.grade = this.state.grade;
      this.state.science_fair_report[i] = reportSingle;
    }

    if (this.state.science_fair_report.length > 0) {
      this.setState({ showSubmitButton: false });
    } else {
      this.setState({ showSubmitButton: true });
    }
  }
  submitReport() {
    this.setState({ showButon: true });
    let payload = {
      school_id: this.state.science_fair_school_id,
      class_id: this.state.science_fair_class_id,
      invention_id:
        this.state.science_fair_invention_id !== "none"
          ? this.state.science_fair_invention_id
          : "",
      exam_date: this.state.science_fair_exam_date,
      report: this.state.science_fair_report,
      action: "add",
    };
    API.post("SchoolApi/scienceFairReport", payload).then((resp) => {
      if (resp.data.result === 1) {
        this.setState({
          showSubmitButton: true,
          showButon: false,
          science_fair_add: false,
        });
        this.setState({
          btninvent: true,
          inventionbtn: false,
          btnparticipate: false,
          btnmenu: false,
          btnmessage: false,
          science_fair_report_button: false,
          fair: 1,
        });
      } else {
        this.setState({ showButon: false });
      }
    });
  }

  scienceFairAdd() {
    this.setState({
      science_fair_add: true,
      science_fair_list: false,
    });
  }
  scienceFairList() {
    this.getScienceFairReportList();
    this.setState({
      science_fair_list: true,
      science_fair_add: false,
    });
  }
  getScienceFairDate(e) {
    console.log("science_fair_date123456", e.target.value);
    if (
      e.target.value &&
      this.state.science_fair_invention_id &&
      this.state.science_fair_invention_id !== "none"
    ) {
      this.setState({
        science_fair_exam_date: e.target.value,
        buttonAccessible: false,
      });
    }
  }

  getScienceFairReport() {
    let payload = {
      invention_id: this.state.inventionID,
    };
    API.post("SchoolApi/scienceFairReport", payload);
  }

  getparticipations(id) {
    var that = this;
    API.post("SchoolApi/participations", {
      invention_id: id ? id : this.state.inventionID,
    })
      .then(function (response) {
        console.log(response);
        if (response.data.result === 1) {
          if (
            response.data.message ===
            "No student participated in this Science fair"
          ) {
            that.setState({ participateList: [] });
          } else {
            that.setState({
              participateList: response.data.data,
              science_fair_class_id: response.data.data[0].class_id,
              science_fair_school_id: response.data.data[0].school_id,
            });
          }
        } else {
          that.setState({ participateList: [] });
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", {
      school_id: localStorage.getItem("school_id"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  };
  getinventionlist() {
    var that = this;
    // localStorage.getItem("school_id")
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    API.post("SchoolApi/inventions", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          Inventiondata: response.data.data,
          invntnmsg: response.data.message,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  inventionchanged(e) {
    // this.setState({ inventionID: e.target.value });
    this.state.inventionID = e.target.value;
    this.getparticipations(e.target.value);
  }
  getinventionguideline() {
    var that = this;
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    API.post("SchoolApi/inventionGuideline", params)
      .then(function (response) {
        console.log(response.data.description);
        that.setState({
          Guideline: response.data.description,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getnews() {
    var that = this;
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    API.post("SchoolApi/invention_news", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          News: response.data.data,
          newsmessage: response.data.message,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getmessages() {
    var that = this;
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    API.post("SchoolApi/messages", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          Messages: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  Addnewnews() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "add",
      date: this.state.newsdate,
      title: this.state.newssubject,
      description: this.state.newsdescription,
    };
    var that = this;
    API.post("SchoolApi/invention_news", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          addnewsmsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, addnews: false });
          }, 2000);
          that.getnews();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        that.setState({ newsdescription: "", newssubject: "", newsdate: "" });
      });
  }
  DeleteNews() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      id: this.state.newsdltid,
      school_id: localStorage.getItem("school_id"),
      action: "delete",
    };
    var that = this;
    API.post("SchoolApi/invention_news", params)
      .then(function (response) {
        console.log(response);
        that.setState({ newsdltmsg: response.data.message });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, newsdltmodal: false });
          }, 2000);
          that.getnews();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2000);
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  Addmessagedata() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "add",
      title: this.state.subject,
      description: this.state.description,
    };
    var that = this;
    API.post("SchoolApi/messages", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          addmessagemsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, addmessage: false });
          }, 2000);
          that.getmessages();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        that.setState({ subject: "", description: "" });
      });
  }
  render() {
    const { classes } = this.props;
    console.log("science_fair_date", this.state.science_fair_exam_date);
    return (
      <div>
        {" "}
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> SCIENCE FAIR</h1>
          <p>
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home{" "}
            </Link>{" "}
            |
            <Link
              href="/observer_profile"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              School Observer{" "}
            </Link>{" "}
            | science fair
          </p>
        </div>
        <Container
          style={{
            marginBottom: "12px",
            marginTop: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: true,
                      inventionbtn: false,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: false,
                      science_fair_report_button: false,
                      fair: 1,
                    });
                  }}
                  className={
                    this.state.btninvent ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>INVENTION GUIDELINE</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      inventionbtn: true,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: false,
                      science_fair_report_button: false,
                      fair: 2,
                    });
                  }}
                  className={
                    this.state.inventionbtn
                      ? classes.cliked
                      : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>INVENTION</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  className={
                    this.state.btnparticipate
                      ? classes.cliked
                      : classes.notclicked
                  }
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: true,
                      btnmenu: false,
                      btnmessage: false,
                      inventionbtn: false,
                      science_fair_report_button: false,
                      fair: 3,
                    });
                  }}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>PARTICIPATE STUDENT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  className={
                    this.state.science_fair_report_button
                      ? classes.cliked
                      : classes.notclicked
                  }
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: false,
                      inventionbtn: false,
                      science_fair_report_button: true,
                      fair: 6,
                    });
                  }}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>SCIENCE FAIR REPORT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  className={
                    this.state.btnmenu ? classes.cliked : classes.notclicked
                  }
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      btnmenu: true,
                      btnmessage: false,
                      inventionbtn: false,
                      science_fair_report_button: false,
                      fair: 4,
                    });
                  }}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>NEWS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  className={
                    this.state.btnmessage ? classes.cliked : classes.notclicked
                  }
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: true,
                      inventionbtn: false,
                      science_fair_report_button: false,
                      fair: 5,
                    });
                  }}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>MESSAGE</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {this.state.fair === 1 && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={10} lg={10}>
                        <Typography variant="h6" fontWeight="bold">
                          INVENTION GUIDELINE
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Box
                          display="flex"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <Box>
                            <button
                              onClick={() => {
                                this.setState({
                                  guidemodal: true,
                                  guidedesc: this.state.Guideline,
                                });
                              }}
                              className={classes.tuitionTimeStatusButton}
                            >
                              <EditIcon style={{ color: "white" }} />
                            </button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    {/* <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display="flex" flexDirection="column">
                          <Box>
                            <Typography fontWeight="bold">
                              Guidelines :
                            </Typography>
                          </Box>
                          <Box>
                            <Typography>{this.state.Guideline}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid> */}
                    <Card sx={{ Width: 275 }}>
                      <CardContent>
                        <Box>
                          <Typography fontWeight="bold">
                            Guidelines :
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>{this.state.Guideline}</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Container>

                  <Dialog open={this.state.guidemodal} maxWidth="sm" fullWidth>
                    <DialogTitle style={{ paddingLeft: "46px" }}>
                      <Typography variant="h6" fontWeight="bold">
                        Update Guidelines
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl
                              variant="filled"
                              style={{ width: "100%" }}
                            >
                              <TextareaAutosize
                                minRows={4}
                                maxRows={4}
                                name="guidelines"
                                value={this.state.guidedesc}
                                onChange={(evt) =>
                                  this.setState({
                                    guidedesc: evt.target.value,
                                  })
                                }
                                placeholder="Enter your guideline description"
                              />
                            </FormControl>
                            <Box>
                              {this.state.success && (
                                <Alert severity="success">
                                  {this.state.guidemsg}
                                </Alert>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                    </DialogContent>
                    <DialogActions>
                      <Box display="flex">
                        <Box>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "deepskyblue" }}
                            onClick={() => this.UpdateGuideline()}
                          >
                            Update
                          </Button>
                          <Button
                            variant="text"
                            onClick={() => this.setState({ guidemodal: false })}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </DialogActions>
                  </Dialog>
                </>
              )}

              {this.state.fair === 2 && (
                <>
                  {this.state.addinvention ? (
                    <>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                              <button
                                onClick={() => {
                                  this.setState({
                                    addinvention: false,
                                  });
                                }}
                                className={classes.tuitionTimeStatusButton}
                              >
                                <ArrowBackIcon style={{ color: "white" }} />
                              </button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" fontWeight="bold">
                              ADD INVENTION
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div
                              style={{
                                backgroundColor: "rgb(24, 169, 218)",
                                height: "2px",
                                width: "22%",
                                marginBottom: "12px",
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <p style={{ opacity: "0.6" }}>Select Class</p>
                            <FormControl variant="filled" fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="class"
                                value={this.state.classselected}
                                onChange={(e) => {
                                  this.setState({
                                    classselected: e.target.value,
                                  });
                                }}
                                input={<OutlinedInput />}
                              >
                                {this.state.classlist.map((l, i) => {
                                  return (
                                    <MenuItem key={i} value={l?.class_id}>
                                      {l?.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <p style={{ opacity: "0.6" }}>Title</p>
                            <TextField
                              value={this.state.inventtiontitle}
                              onChange={(evt) =>
                                this.setState({
                                  inventtiontitle: evt.target.value,
                                })
                              }
                              type="text"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p style={{ opacity: "0.6" }}>Description</p>
                            <FormControl
                              variant="filled"
                              style={{ width: "100%" }}
                            >
                              <TextareaAutosize
                                minRows={4}
                                maxRows={4}
                                value={this.state.inventiondescription}
                                onChange={(evt) =>
                                  this.setState({
                                    inventiondescription: evt.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <p style={{ opacity: "0.6" }}>Submission Date</p>
                            <TextField
                              value={this.state.inventiondate}
                              onChange={(evt) =>
                                this.setState({
                                  inventiondate: evt.target.value,
                                })
                              }
                              type="date"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box display="flex" flexDirection="column">
                              <Box>
                                {this.state.error && (
                                  <Alert severity="error">
                                    {this.state.inventionaddmessage}
                                  </Alert>
                                )}
                                {this.state.success && (
                                  <Alert severity="success">
                                    {this.state.inventionaddmessage}
                                  </Alert>
                                )}
                              </Box>
                              <Box>
                                {this.state.loading ? (
                                  <CircularProgress />
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{ backgroundColor: "deepskyblue" }}
                                    onClick={() => this.AddnewInvention()}
                                  >
                                    Submit
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                    </>
                  ) : (
                    <>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Typography variant="h6" fontWeight="bold">
                              INVENTION LIST
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Box
                              style={{
                                width: "100%",
                                justifyContent: "flex-end",
                              }}
                              display="flex"
                            >
                              <Box>
                                <Button
                                  style={{
                                    border: "1px solid black",
                                    color: "black",
                                  }}
                                  onClick={() => {
                                    this.setState({ addinvention: true });
                                  }}
                                >
                                  Add Invention
                                  <AddCircleOutlineIcon
                                    className={classes.addTuitionButton}
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div
                              style={{
                                backgroundColor: "rgb(24, 169, 218)",
                                height: "2px",
                                width: "22%",
                                marginBottom: "12px",
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {this.state.Inventiondata !== undefined && (
                              <Box
                                display="flex"
                                flexDirection="column"
                                style={{ width: "100%" }}
                                marginY={2}
                              >
                                {this.state.Inventiondata.map((l, index) => (
                                  <>
                                    <Box display="flex">
                                      <Box
                                        style={{ width: "85%" }}
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Box>
                                          <Typography
                                            variant="h6"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {l?.name}
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography fontWeight="bold">
                                            {l?.class}
                                          </Typography>
                                        </Box>
                                        <Box>
                                          {" "}
                                          <Typography>
                                            {" "}
                                            {l?.description}
                                          </Typography>
                                        </Box>
                                        <Box display="flex">
                                          <Box style={{ width: "25%" }}>
                                            <Typography fontWeight="bold">
                                              Submission Date
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Typography>
                                              {" "}
                                              : {l?.date}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box
                                        style={{ width: "15%" }}
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Box marginY={8}>
                                          <button
                                            onClick={() => {
                                              this.openinveditmodal(l);
                                            }}
                                            className={
                                              classes.tuitionTimeStatusButton
                                            }
                                          >
                                            <EditIcon
                                              style={{ color: "white" }}
                                            />
                                          </button>
                                        </Box>
                                        <Box marginY={8}>
                                          <button
                                            onClick={() => {
                                              this.openinvdeletemodal(l);
                                            }}
                                            className={
                                              classes.tuitionTimeStatusButton
                                            }
                                          >
                                            <DeleteIcon
                                              style={{ color: "white" }}
                                            />
                                          </button>
                                        </Box>
                                      </Box>
                                    </Box>
                                    {index !==
                                      Number(this.state.Inventiondata.length) -
                                        1 && (
                                      <Divider
                                        style={{
                                          marginBottom: "8px",
                                          marginTop: "8px",
                                        }}
                                      />
                                    )}
                                  </>
                                ))}
                              </Box>
                            )}

                            {this.state.Inventiondata === undefined && (
                              <Box>
                                <Typography>{this.state.invntnmsg}</Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Container>

                      <Dialog
                        open={this.state.editinvention}
                        maxWidth="md"
                        fullWidth
                      >
                        <DialogTitle style={{ paddingLeft: "46px" }}>
                          <Typography variant="h6" fontWeight="bold">
                            Edit Invention
                          </Typography>
                        </DialogTitle>
                        <DialogContent style={{ paddingTop: "16px" }}>
                          <Container>
                            <Grid container spacing={2}>
                              <Container>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p style={{ opacity: "0.6" }}>
                                      Select Class
                                    </p>
                                    <FormControl variant="filled" fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="class"
                                        value={this.state.editinvclass}
                                        onChange={(e) => {
                                          this.setState({
                                            editinvclass: e.target.value,
                                          });
                                        }}
                                        input={<OutlinedInput />}
                                      >
                                        {this.state.classlist.map((l, i) => {
                                          return (
                                            <MenuItem
                                              key={i}
                                              value={l?.class_id}
                                            >
                                              {l?.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p style={{ opacity: "0.6" }}>Title</p>
                                    <TextField
                                      value={this.state.editinvtitle}
                                      onChange={(evt) =>
                                        this.setState({
                                          editinvtitle: evt.target.value,
                                        })
                                      }
                                      type="text"
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <p style={{ opacity: "0.6" }}>
                                      Description
                                    </p>
                                    <FormControl
                                      variant="filled"
                                      style={{ width: "100%" }}
                                    >
                                      <TextareaAutosize
                                        minRows={4}
                                        maxRows={4}
                                        value={this.state.editinvdescription}
                                        onChange={(evt) =>
                                          this.setState({
                                            editinvdescription:
                                              evt.target.value,
                                          })
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p style={{ opacity: "0.6" }}>
                                      Submission Date
                                    </p>
                                    <TextField
                                      value={this.state.editinvdate}
                                      onChange={(evt) =>
                                        this.setState({
                                          editinvdate: evt.target.value,
                                        })
                                      }
                                      type="date"
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </Container>
                            </Grid>
                          </Container>
                        </DialogContent>
                        <DialogActions>
                          <Box display="flex">
                            <Box>
                              {this.state.loading ? (
                                <CircularProgress />
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "deepskyblue" }}
                                  onClick={() => this.EditInvention()}
                                >
                                  Update
                                </Button>
                              )}
                              <Button
                                variant="text"
                                onClick={() =>
                                  this.setState({ editinvention: false })
                                }
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Box>
                        </DialogActions>
                      </Dialog>

                      <Dialog
                        open={this.state.deleteinv}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure? You want to delete this invention!
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Box display="flex" flexDirection="column">
                            {/* <Box>
                              {this.state.error && <Alert severity="error">{this.state.newsdltmsg}</Alert>}
                              {this.state.success && <Alert severity="success">{this.state.newsdltmsg}</Alert>}
                            </Box> */}
                            <Box>
                              <Button
                                onClick={() =>
                                  this.setState({ deleteinv: false })
                                }
                              >
                                Cancel
                              </Button>
                              {this.state.loading ? (
                                <CircularProgress />
                              ) : (
                                <Button
                                  autoFocus
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  onClick={() => this.DeleteInvention()}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </>
              )}
              {this.state.fair === 3 && (
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <Typography variant="h6" fontWeight="bold">
                        PARTICIPATE STUDENT
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Box
                        style={{
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                        display="flex"
                      >
                        <Box>
                          <Form.Select
                            onChange={(e) => {
                              this.inventionchanged(e);
                            }}
                            required
                            value={this.state.participationID}
                            aria-label="Default select example"
                          >
                            <option>Select Invention</option>
                            {this.state.Inventiondata.map((l, i) => {
                              return (
                                <option key={i} value={l.id}>
                                  {l.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div
                        style={{
                          backgroundColor: "rgb(24, 169, 218)",
                          height: "2px",
                          width: "32%",
                          marginBottom: "12px",
                        }}
                      ></div>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              style={{ width: "100%" }}
                              marginY={2}
                            >
                              {this.state.participateList === undefined ? (
                                <Box>No Student Particapated </Box>
                              ) : (
                                <>
                                  {this.state.participateList.map(
                                    (news, index) => (
                                      <>
                                        <Box display="flex">
                                          <Box style={{ width: "90%" }}>
                                            <Box>
                                              <Typography
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {news?.date}
                                              </Typography>
                                              <Typography
                                                variant="h6"
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {news?.invention}
                                              </Typography>
                                              <Typography
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {news?.student}
                                              </Typography>
                                              <Typography>
                                                {" "}
                                                {news?.school}
                                              </Typography>
                                              <Typography>
                                                {" "}
                                                {news?.class}
                                              </Typography>
                                              <Typography>
                                                {" "}
                                                No of Student:{" "}
                                                {news?.student_no}
                                              </Typography>
                                              <Typography>
                                                {" "}
                                                Message: {news?.message}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                        {index !==
                                          Number(
                                            this.state.participateList.length
                                          ) -
                                            1 && (
                                          <Divider
                                            style={{
                                              marginBottom: "8px",
                                              marginTop: "8px",
                                            }}
                                          />
                                        )}
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                    </Grid>
                  </Grid>
                </Container>
              )}

              {this.state.fair === 4 && (
                <>
                  {this.state.addnews ? (
                    <>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                              <button
                                onClick={() => {
                                  this.setState({
                                    addnews: false,
                                  });
                                }}
                                className={classes.tuitionTimeStatusButton}
                              >
                                <ArrowBackIcon style={{ color: "white" }} />
                              </button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" fontWeight="bold">
                              ADD NEWS
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div
                              style={{
                                backgroundColor: "rgb(24, 169, 218)",
                                height: "2px",
                                width: "15%",
                                marginBottom: "12px",
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <p style={{ opacity: "0.8" }}>Select Date</p>
                            <TextField
                              value={this.state.newsdate}
                              onChange={(evt) =>
                                this.setState({ newsdate: evt.target.value })
                              }
                              type="date"
                              fullWidth
                              placeholder=""
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <p style={{ opacity: "0.8" }}>Title</p>
                            <TextField
                              value={this.state.newssubject}
                              onChange={(evt) =>
                                this.setState({ newssubject: evt.target.value })
                              }
                              type="text"
                              fullWidth
                              placeholder=""
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p style={{ opacity: "0.6" }}>Description</p>
                            <FormControl
                              variant="filled"
                              style={{ width: "100%" }}
                            >
                              <TextareaAutosize
                                minRows={4}
                                maxRows={4}
                                value={this.state.newsdescription}
                                onChange={(evt) =>
                                  this.setState({
                                    newsdescription: evt.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box display="flex" flexDirection="column">
                              <Box>
                                {this.state.error && (
                                  <Alert severity="error">
                                    {this.state.addnewsmsg}
                                  </Alert>
                                )}
                                {this.state.success && (
                                  <Alert severity="success">
                                    {this.state.addnewsmsg}
                                  </Alert>
                                )}
                              </Box>
                              <Box>
                                {this.state.loading ? (
                                  <CircularProgress />
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{ backgroundColor: "deepskyblue" }}
                                    onClick={() => this.Addnewnews()}
                                  >
                                    Submit
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                    </>
                  ) : (
                    <>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Typography variant="h6" fontWeight="bold">
                              NEWS
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Box
                              style={{
                                width: "100%",
                                justifyContent: "flex-end",
                              }}
                              display="flex"
                            >
                              <Box>
                                <Button
                                  style={{
                                    border: "1px solid black",
                                    color: "black",
                                  }}
                                  onClick={() => {
                                    this.setState({ addnews: true });
                                  }}
                                >
                                  Add News
                                  <AddCircleOutlineIcon
                                    className={classes.addTuitionButton}
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div
                              style={{
                                backgroundColor: "rgb(24, 169, 218)",
                                height: "2px",
                                width: "8%",
                                marginBottom: "12px",
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              style={{ width: "100%" }}
                              marginY={2}
                            >
                              {this.state.News === undefined ? (
                                <Box>No News Added</Box>
                              ) : (
                                <>
                                  {this.state.News.map((news, index) => (
                                    <>
                                      <Box display="flex">
                                        <Box style={{ width: "90%" }}>
                                          <Box>
                                            <Typography
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {news?.date}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {news?.title}
                                            </Typography>
                                            <Typography>
                                              {" "}
                                              {news?.description}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          style={{ width: "10%" }}
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <Box marginY={8}>
                                            <button
                                              onClick={() => {
                                                this.opennewsdeletemodal(news);
                                              }}
                                              className={
                                                classes.tuitionTimeStatusButton
                                              }
                                            >
                                              <DeleteIcon
                                                style={{ color: "white" }}
                                              />
                                            </button>
                                          </Box>
                                        </Box>
                                      </Box>
                                      {index !==
                                        Number(this.state.News.length) - 1 && (
                                        <Divider
                                          style={{
                                            marginBottom: "8px",
                                            marginTop: "8px",
                                          }}
                                        />
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>

                      <Dialog
                        open={this.state.newsdltmodal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure? You want to delete this news!
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Box display="flex" flexDirection="column">
                            <Box>
                              {this.state.error && (
                                <Alert severity="error">
                                  {this.state.newsdltmsg}
                                </Alert>
                              )}
                              {this.state.success && (
                                <Alert severity="success">
                                  {this.state.newsdltmsg}
                                </Alert>
                              )}
                            </Box>
                            <Box>
                              <Button
                                onClick={() =>
                                  this.setState({ newsdltmodal: false })
                                }
                              >
                                Cancel
                              </Button>
                              {this.state.loading ? (
                                <CircularProgress />
                              ) : (
                                <Button
                                  autoFocus
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    this.DeleteNews();
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </>
              )}

              {this.state.fair === 5 && (
                <>
                  {this.state.addmessage ? (
                    <>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box>
                              <button
                                onClick={() => {
                                  this.setState({
                                    addmessage: false,
                                  });
                                }}
                                className={classes.tuitionTimeStatusButton}
                              >
                                <ArrowBackIcon style={{ color: "white" }} />
                              </button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" fontWeight="bold">
                              ADD MESSAGE
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div
                              style={{
                                backgroundColor: "rgb(24, 169, 218)",
                                height: "2px",
                                width: "21%",
                                marginBottom: "12px",
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p style={{ opacity: "0.8" }}>Title</p>
                            <TextField
                              value={this.state.subject}
                              onChange={(evt) =>
                                this.setState({ subject: evt.target.value })
                              }
                              type="text"
                              fullWidth
                              placeholder=""
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p style={{ opacity: "0.6" }}>Description</p>
                            <FormControl
                              variant="filled"
                              style={{ width: "100%" }}
                            >
                              <TextareaAutosize
                                minRows={4}
                                maxRows={4}
                                value={this.state.description}
                                onChange={(evt) =>
                                  this.setState({
                                    description: evt.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box display="flex" flexDirection="column">
                              <Box>
                                {this.state.error && (
                                  <Alert severity="error">
                                    {this.state.addmessagemsg}
                                  </Alert>
                                )}
                                {this.state.success && (
                                  <Alert severity="success">
                                    {this.state.addmessagemsg}
                                  </Alert>
                                )}
                              </Box>
                              <Box>
                                {this.state.loading ? (
                                  <CircularProgress />
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{ backgroundColor: "deepskyblue" }}
                                    onClick={() => this.Addmessagedata()}
                                  >
                                    Submit
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                    </>
                  ) : (
                    <>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Typography variant="h6" fontWeight="bold">
                              MESSAGE
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Box
                              style={{
                                width: "100%",
                                justifyContent: "flex-end",
                              }}
                              display="flex"
                            >
                              <Box>
                                <Button
                                  style={{
                                    border: "1px solid black",
                                    color: "black",
                                  }}
                                  onClick={() => {
                                    this.setState({ addmessage: true });
                                  }}
                                >
                                  Add Message
                                  <AddCircleOutlineIcon
                                    className={classes.addTuitionButton}
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div
                              style={{
                                backgroundColor: "rgb(24, 169, 218)",
                                height: "2px",
                                width: "14%",
                                marginBottom: "12px",
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              style={{ width: "100%" }}
                              marginY={2}
                            >
                              {this.state.Messages === undefined ? (
                                <Box>No Message Added</Box>
                              ) : (
                                <>
                                  {this.state.Messages.map((news, index) => (
                                    <>
                                      <Box display="flex">
                                        <Box style={{ width: "90%" }}>
                                          <Box>
                                            <Typography
                                              variant="h6"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {news?.title}
                                            </Typography>
                                            <Typography>
                                              {" "}
                                              {news?.description}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          style={{ width: "10%" }}
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <Box marginY={8}>
                                            <button
                                              onClick={() => {
                                                this.openmsgdeletemodal(news);
                                              }}
                                              className={
                                                classes.tuitionTimeStatusButton
                                              }
                                            >
                                              <DeleteIcon
                                                style={{ color: "white" }}
                                              />
                                            </button>
                                          </Box>
                                        </Box>
                                      </Box>
                                      {index !==
                                        Number(this.state.Messages.length) -
                                          1 && (
                                        <Divider
                                          style={{
                                            marginBottom: "8px",
                                            marginTop: "8px",
                                          }}
                                        />
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>

                      <Dialog
                        open={this.state.deletemsgmodal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure? You want to delete this message!
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Box display="flex" flexDirection="column">
                            <Box>
                              {this.state.error && (
                                <Alert severity="error">
                                  {this.state.deletemsgmodalmsg}
                                </Alert>
                              )}
                              {this.state.success && (
                                <Alert severity="success">
                                  {this.state.deletemsgmodalmsg}
                                </Alert>
                              )}
                            </Box>
                            <Box>
                              <Button
                                onClick={() =>
                                  this.setState({ deletemsgmodal: false })
                                }
                              >
                                Cancel
                              </Button>
                              {this.state.loading ? (
                                <CircularProgress />
                              ) : (
                                <Button
                                  autoFocus
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    this.DeleteMessage();
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </>
              )}
              {this.state.fair === 6 && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                          <Typography variant="h5">
                            SCIENCE FAIR REPORT
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <FormControl variant="filled" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="class"
                            value={this.state.science_fair_invention_id}
                            onChange={(e) => this.getInventionId(e)}
                            input={<OutlinedInput />}
                          >
                            <MenuItem value="none">Select Invention</MenuItem>
                            {this.state.inventionListForReport.map((l, i) => {
                              return (
                                <MenuItem key={i} value={l?.id}>
                                  {l?.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormControl fullWidth>
                          <TextField
                            name="exam_date"
                            type="date"
                            variant="outlined"
                            onChange={(e) => this.getScienceFairDate(e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box display="flex">
                          <Box marginRight={1}>
                            <Button
                              style={{
                                border: "1px solid lightgrey",
                                color: "black",
                              }}
                              onClick={() => this.scienceFairAdd()}
                              disabled={this.state.buttonAccessible}
                            >
                              Add
                              <AddCircleOutlineIcon
                                className={classes.addTuitionButton}
                              />
                            </Button>
                          </Box>
                          {/* <Box><Button
                            style={{ border: "1px solid lightgrey", color: "black" }}
                            onClick={() => this.scienceFairList()}
                            disabled={this.state.buttonAccessible}
                          >
                            List
                            <ListIcon className={classes.addTuitionButton} />
                          </Button>
                          </Box> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Container>
                  {!this.state.science_fair_add && (
                    <Container
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        minHeight: "500px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            paddingY={8}
                            style={{
                              border: "1px solid lightgrey",
                              borderRadius: "5px",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              paddingY={1}
                            >
                              <Box alignSelf="center">
                                <Typography>
                                  Please Select "Invention" and "Date". Click
                                </Typography>
                              </Box>
                              <Box marginX={1}>
                                <Button
                                  style={{
                                    border: "1px solid lightgrey",
                                    color: "black",
                                  }}
                                  disabled
                                >
                                  Add
                                  <AddCircleOutlineIcon
                                    className={classes.addTuitionButton}
                                  />
                                </Button>
                              </Box>
                              <Box alignSelf="center">
                                <Typography> for "New Report"</Typography>
                              </Box>
                            </Box>
                            {/* <Box display="flex" justifyContent="center" paddingY={1}>
                              <Box alignSelf="center"><Typography>Please Select "Invention" and "Date". Click</Typography></Box>
                              <Box marginX={1}>
                                <Button
                                  style={{ border: "1px solid lightgrey", color: "black" }}
                                  disabled
                                >
                                  List
                                  <ListIcon className={classes.addTuitionButton} />
                                </Button>
                              </Box>
                              <Box alignSelf="center"><Typography> for "Listing"</Typography></Box>
                            </Box> */}
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  )}
                  {this.state.science_fair_add && (
                    <>
                      <Container
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          minHeight: "500px",
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ backgroundColor: "lightgray" }}
                              >
                                Student Name
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Marks
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Grade
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.participateList.map((stud, index) => (
                              <TableRow hover>
                                <TableCell>{stud?.student}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <TextField
                                    name="marks"
                                    type="number"
                                    variant="outlined"
                                    onChange={(e) =>
                                      this.getReport(e, index, stud?.student_id)
                                    }
                                  />
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <Select
                                    style={{ width: "100%" }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="grade"
                                    input={<OutlinedInput />}
                                    onChange={(e) =>
                                      this.getReport(e, index, stud?.student_id)
                                    }
                                  >
                                    <MenuItem value="A">A</MenuItem>
                                    <MenuItem value="B">B</MenuItem>
                                    <MenuItem value="C">C</MenuItem>
                                    <MenuItem value="D">D</MenuItem>
                                  </Select>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <Box display="flex" justifyContent="center">
                                    <Box>
                                      <button
                                        onClick={() =>
                                          this.saveCurrentReport(index)
                                        }
                                        className={
                                          classes.tuitionTimeStatusButton
                                        }
                                      >
                                        <SaveIcon style={{ color: "white" }} />
                                      </button>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Box marginY={2}>
                          {this.state.participateList.length > 0 ? (
                            <Box>
                              {this.state.showSubmitButton ? (
                                <Button disabled variant="contained">
                                  SUBMIT
                                </Button>
                              ) : (
                                <>
                                  {this.state.showButon ? (
                                    <CircularProgress />
                                  ) : (
                                    <Button
                                      onClick={() => this.submitReport()}
                                      style={{
                                        backgroundColor: "rgb(24, 169, 218)",
                                        color: "white",
                                        border: "none",
                                        height: "40px",
                                        width: "140px",
                                      }}
                                    >
                                      SUBMIT
                                    </Button>
                                  )}
                                </>
                              )}
                            </Box>
                          ) : (
                            <Typography>Data Not Found</Typography>
                          )}
                        </Box>
                      </Container>
                    </>
                  )}
                  {this.state.science_fair_list && (
                    <>
                      <Container
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          minHeight: "500px",
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ backgroundColor: "lightgray" }}
                              >
                                Student Name
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Marks
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Grade
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.scienceFairReportList.map(
                              (stud, index) => (
                                <TableRow hover>
                                  <TableCell>{stud?.student}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {stud?.marks}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {stud?.grade}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                        {/* <Box marginY={2}>
                                  {studentList.length > 0 ?
                                    <Box>
                                      {showSubmitButton ?
                                        <Button
                                          disabled
                                          variant="contained"
                                        >
                                          SUBMIT
                                        </Button>
                                        : <>
                                          {showButon ?
                                            <CircularProgress />
                                            :
                                            <Button
                                              onClick={submitReport}
                                              style={{
                                                backgroundColor: "rgb(24, 169, 218)", color: "white",
                                                border: "none", height: "40px", width: "140px"
                                              }}>
                                              SUBMIT
                                            </Button>
                                          }
                                        </>}
                                    </Box>
                                    :
                                    <Typography>Data Not Found</Typography>
                                    }
                                </Box> */}
                      </Container>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container science-fair">
          <div className="row " style={{ paddingTop: "15px" }}>
            <div className="col-md-3 science-row offset-1">

              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  onClick={() => {
                    this.setState({
                      btninvent: true,
                      inventionbtn: false,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: false,
                      fair: 1,
                    });
                  }}
                  className={this.state.btninvent ? "clicked" : "notclicked"}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>INVENTION GUIDELINE</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      inventionbtn: true,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: false,
                      fair: 2,
                    });
                  }}
                  className={this.state.inventionbtn ? "clicked" : "notclicked"}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>INVENTION</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  className={
                    this.state.btnparticipate ? "clicked" : "notclicked"
                  }
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: true,
                      btnmenu: false,
                      btnmessage: false,
                      inventionbtn: false,
                      fair: 3,
                    });
                  }}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>PARTICIPATE STUDENT</Typography>
                  </Box>
                </Box>
              </Box>

              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  className={this.state.btnmenu ? "clicked" : "notclicked"}
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      btnmenu: true,
                      btnmessage: false,
                      inventionbtn: false,
                      fair: 4,
                    });
                  }}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>NEWS</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  className={this.state.btnmessage ? "clicked" : "notclicked"}
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: true,
                      inventionbtn: false,
                      fair: 5,
                    });
                  }}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>MESSAGE</Typography>
                  </Box>
                </Box>
              </Box>

            </div>
            <div className="col-md-8" style={{ paddingLeft: "30px" }}>
              {this.state.fair === 1 ? (
                <div className="container">
                  <div className="row">
                    <div className="col-md-4" style={{ flex: "1" }}>
                      <h5>INVENTION GUIDELINE</h5>
                      <div
                        style={{
                          height: "3px",
                          width: "80px",
                          textDecoration: "underline",
                          backgroundColor: "lightblue",
                        }}
                      ></div>
                    </div>
                    <div className="col-md-2">
                      <Button
                        variant="none"
                        onClick={() => {
                          this.setState({
                            guidemodal: true,
                            guidedesc: this.state.Guideline,
                          });
                        }}
                      >
                        <AiFillEdit size="25px" color="lightgreen" />
                      </Button>
                    </div>
                  </div>
                  <br></br>
                  <Grid item lg={10} md={10} sm={8} xs={8}>
                    <Box display="flex" flexDirection="column">
                      <Box className={classes.personalInfoBox}>
                        <Typography variant="h6">Guidelines: </Typography>
                        <Divider />
                        <Typography
                          variant="p"
                          style={{ paddingTop: "2vh", opacity: "0.8" }}
                        >
                          {this.state.Guideline}{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 2 ? (
                <div>
                  <Modal
                    size="sm"
                    show={this.state.deleteinv}
                    onHide={() => this.setState({ deleteinv: false })}
                    animation="true"
                    autoFocus="true"
                    bottom
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Invention</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="">
                        <div className="row">
                          <div className="col">
                            <p
                              style={{
                                fontSize: "smaller",
                                opacity: "0.4",
                              }}
                            >
                              Are you sure you want to delete? This process
                              cannot be undone.
                            </p>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row" style={{ paddingTop: "20px" }}>
                            <Button
                              variant="danger"
                              onClick={() => this.DeleteInvention()}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>{" "}
                  {this.state.addinvention ? (
                    <div>
                      {" "}
                      <div>
                        <div className="col-md-1">
                          <Button
                            style={{ borderRadius: "50%" }}
                            onClick={() => {
                              this.setState({
                                addinvention: false,
                              });
                            }}
                          >
                            <HiOutlineArrowNarrowLeft size="15px" />
                          </Button>
                        </div>
                        <div className="row">
                          <div className="col-md-3" style={{ flex: "1" }}>
                            <h5>ADD INVENTION</h5>

                            <div
                              style={{
                                height: "3px",
                                width: "80px",
                                textDecoration: "underline",
                                backgroundColor: "lightblue",
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="row" style={{ paddingTop: "7vh" }}>
                          <div className="col-md-5">
                            <p style={{ opacity: "0.8" }}>Select Class</p>
                            <Box component="form" noValidate autoComplete="off">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label=""
                                value={this.state.classselected}
                                onChange={(e) => {
                                  this.setState({
                                    classselected: e.target.value,
                                  });
                                }}
                                helperText="Please select Class"
                                fullWidth
                              >
                                {this.state.classlist.map((option, id) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.class_id}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>
                          <div className="col-md-5">
                            <p style={{ opacity: "0.8" }}>Title</p>
                            <Form>
                              <TextField
                                value={this.state.inventtiontitle}
                                onChange={(evt) =>
                                  this.setState({
                                    inventtiontitle: evt.target.value,
                                  })
                                }
                                type="text"
                                placeholder=""
                                fullWidth
                              />
                            </Form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10">
                            <p style={{ opacity: "0.8" }}>Description</p>
                            <Form>
                              <Form.Control
                                value={this.state.inventiondescription}
                                style={{ height: "17vh" }}
                                onChange={(evt) =>
                                  this.setState({
                                    inventiondescription: evt.target.value,
                                  })
                                }
                                as="textarea"
                                row={3}
                                placeholder=""
                              />
                            </Form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <p style={{ opacity: "0.8" }}>Submission Date</p>
                            <Form>
                              <TextField
                                value={this.state.inventiondate}
                                onChange={(evt) =>
                                  this.setState({
                                    inventiondate: evt.target.value,
                                  })
                                }
                                type="date"
                                placeholder=""
                                fullWidth
                              />
                            </Form>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "3vh" }}>
                          <div className="col">
                            <Button onClick={() => this.AddnewInvention()}>
                              {this.state.loading ? <ClipLoader /> : "Submit"}
                            </Button>
                          </div>
                          {this.state.success ? (
                            <div
                              style={{
                                backgroundColor: "lightgreen",
                                color: "white",
                                height: "auto",
                                marginTop: "5vh",
                              }}
                            >
                              <h6 style={{ paddingLeft: "10vh" }}>
                                {this.state.inventionaddmessage}
                              </h6>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.error ? (
                            <div
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "auto",
                                marginTop: "5vh",
                              }}
                            >
                              <h6 style={{ paddingLeft: "10vh" }}>
                                {this.state.inventionaddmessage}
                              </h6>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          <h5>INVENTION LIST</h5>

                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <Button
                            variant="none"
                            style={{ border: "1px solid lightgrey" }}
                            onClick={() => {
                              this.setState({ addinvention: true });
                            }}
                          >
                            Add Invention
                            <GrAddCircle color="lightgreen" />
                          </Button>
                        </div>
                      </div>

                      <br></br>
                      <Modal
                        size="md"
                        show={this.state.editinvention}
                        onHide={() => this.setState({ editinvention: false })}
                        aria-labelledby="example-modal-sizes-title-sm"
                        animation="true"
                        autoFocus="true"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-lg">
                            Edit Your Invention
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div>
                            <div>
                              <div
                                className="row"
                                style={{ paddingTop: "7vh" }}
                              >
                                <div className="col-md-5">
                                  <p style={{ opacity: "0.8" }}>Select Class</p>
                                  <TextField
                                    id="outlined-select-currency"
                                    select
                                    label=""
                                    value={this.state.editinvclass}
                                    onChange={(e) => {
                                      this.setState({
                                        editinvclass: e.target.value,
                                      });
                                    }}
                                    helperText="Please select Class"
                                  >
                                    {this.state.classlist.map((option, id) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.class_id}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </div>
                                <div className="col-md-5">
                                  <p style={{ opacity: "0.8" }}>Title</p>
                                  <Form>
                                    <TextField
                                      value={this.state.editinvtitle}
                                      onChange={(evt) =>
                                        this.setState({
                                          editinvtitle: evt.target.value,
                                        })
                                      }
                                      type="text"
                                      fullWidth
                                      placeholder=""
                                    />
                                  </Form>
                                </div>
                              </div>
                              <div className="row">
                                <p style={{ opacity: "0.8" }}>Description</p>
                                <Form>
                                  <Form.Control
                                    value={this.state.editinvdescription}
                                    style={{ height: "17vh" }}
                                    onChange={(evt) =>
                                      this.setState({
                                        editinvdescription: evt.target.value,
                                      })
                                    }
                                    as="textarea"
                                    row={3}
                                    placeholder=""
                                  />
                                </Form>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <p style={{ opacity: "0.8" }}>
                                    Submission Date
                                  </p>
                                  <Form>
                                    <TextField
                                      value={this.state.editinvdate}
                                      onChange={(evt) =>
                                        this.setState({
                                          editinvdate: evt.target.value,
                                        })
                                      }
                                      type="date"
                                      fullWidth
                                      placeholder=""
                                    />
                                  </Form>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingTop: "2vh" }}
                              >
                                <div className="col">
                                  <Button onClick={() => this.EditInvention()}>
                                    {this.state.loading ? (
                                      <ClipLoader />
                                    ) : (
                                      "Submit"
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      {this.state.Inventiondata === undefined ? (
                        <div>{this.state.invntnmsg}</div>
                      ) : (
                        <div
                          className="container"
                          style={{ paddingTop: "10px" }}
                        >
                          {this.state.Inventiondata.map((l, id) => {
                            return (
                              <div className="row" style={{ marginTop: "1vh" }}>
                                <div class="col-sm-9">
                                  <div class="card">
                                    <div class="card-body">
                                      <Typography style={{ opacity: "0.8" }}>
                                        {l.class}
                                      </Typography>
                                      <Typography
                                        variant="h5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {l.name}
                                      </Typography>
                                      <Typography
                                        style={{ marginTop: "2vh" }}
                                        className="card-text"
                                      >
                                        {l.description}
                                      </Typography>
                                      <Box style={{ alignSelf: "center" }}>
                                        <p1
                                          style={{ fontWeight: "bold" }}
                                          class="card-text"
                                        >
                                          Submission date: {l.date}
                                        </p1>{" "}
                                      </Box>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-1">
                                  <button
                                    className="rounded-circle"
                                    style={{
                                      backgroundColor: "lightgreen",
                                      border: "1px solid lightgrey",
                                      height: "7vh",
                                      width: "7vh",
                                    }}
                                    onClick={() => {
                                      this.openinveditmodal(l);
                                    }}
                                  >
                                    <AiFillEdit color="white" size="23" />
                                  </button>
                                </div>
                                <div className="col-sm-1">
                                  {" "}
                                  <button
                                    className="rounded-circle"
                                    style={{
                                      backgroundColor: "lightgreen",
                                      border: "1px solid lightgrey",
                                      height: "7vh",
                                      width: "7vh",
                                    }}
                                    onClick={() => {
                                      this.openinvdeletemodal(l);
                                    }}
                                  >
                                    <AiFillDelete color="white" size="23" />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 3 ? (
                <div>
                  <div className="container">
                    <h5>PARTICIPATE STUDENT</h5>
                    <div
                      style={{
                        height: "3px",
                        width: "80px",
                        textDecoration: "underline",
                        backgroundColor: "lightblue",
                      }}
                    ></div>
                    
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 4 ? (
                <div>
                  {this.state.addnews ? (
                    <div>
                      <div className="col-md-1">
                        <Button
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "black",
                            border: "none",
                          }}
                          onClick={() => {
                            this.setState({
                              addnews: false,
                            });
                          }}
                        >
                          <HiOutlineArrowNarrowLeft size="15px" />
                        </Button>
                      </div>
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          <Typography variant="h6">ADD NEWS</Typography>

                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "3vh" }}>
                        <div className="col-md-5">
                          <p style={{ opacity: "0.8" }}>Select Date</p>
                          <Form>
                            <TextField
                              value={this.state.newsdate}
                              onChange={(evt) =>
                                this.setState({ newsdate: evt.target.value })
                              }
                              type="date"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="col-md-5">
                          <p style={{ opacity: "0.8" }}>Subject</p>
                          <Form>
                            <TextField
                              value={this.state.newssubject}
                              onChange={(evt) =>
                                this.setState({ newssubject: evt.target.value })
                              }
                              type="text"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row">
                        <p style={{ opacity: "0.8" }}>Description</p>
                        <Form>
                          <Form.Control
                            value={this.state.newsdescription}
                            style={{ height: "17vh" }}
                            onChange={(evt) =>
                              this.setState({
                                newsdescription: evt.target.value,
                              })
                            }
                            as="textarea"
                            row={3}
                            placeholder=""
                          />
                        </Form>
                      </div>
                      <div className="row" style={{ paddingTop: "2vh" }}>
                        <div className="col">
                          <Button onClick={() => this.Addnewnews()}>
                            {this.state.loading ? <ClipLoader /> : "Submit"}
                          </Button>
                        </div>
                        {this.state.success ? (
                          <div
                            style={{
                              backgroundColor: "lightgreen",
                              color: "white",
                              height: "auto",
                              marginTop: "5vh",
                            }}
                          >
                            <h6 style={{ paddingLeft: "10vh" }}>
                              {this.state.addnewsmsg}
                            </h6>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.error ? (
                          <div
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              height: "auto",
                              marginTop: "5vh",
                            }}
                          >
                            <h6 style={{ paddingLeft: "10vh" }}>
                              {this.state.addnewsmsg}
                            </h6>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          <h5>NEWS</h5>

                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <Button
                            variant="none"
                            style={{ border: "1px solid lightgrey" }}
                            onClick={() => {
                              this.setState({ addnews: true });
                            }}
                          >
                            Add News
                            <GrAddCircle color="lightgreen" />
                          </Button>
                        </div>
                      </div>
                      {this.state.News === undefined ? (
                        <div>{this.state.newsmessage}</div>
                      ) : (
                        <div
                          className="container"
                          style={{ paddingTop: "10px" }}
                        >
                          {this.state.News.map((l, id) => {
                            return (
                              <div className="row" style={{ marginTop: "2vh" }}>
                                <div class="col-sm-10">
                                  <div class="card">
                                    <div class="card-body">
                                      <p1
                                        style={{
                                          fontSize: "smaller",
                                          opacity: "0.8",
                                        }}
                                      >
                                        {l.date}
                                      </p1>
                                      <Typography variant="h5">
                                        {l.title}
                                      </Typography>
                                      <Typography style={{ paddingTop: "2vh" }}>
                                        {l.description}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <button
                                    className="rounded-circle"
                                    style={{
                                      backgroundColor: "lightgreen",
                                      height: "40px",
                                      width: "40px",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      this.opennewsdeletemodal(l);
                                    }}
                                  >
                                    <AiFillDelete color="white" />
                                  </button>
                                </div>
                                <Modal
                                  size="md"
                                  show={this.state.newsdltmodal}
                                  onHide={() =>
                                    this.setState({ newsdltmodal: false })
                                  }
                                  aria-labelledby="example-modal-sizes-title-sm"
                                  animation="true"
                                  autoFocus="true"
                                  centered
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title id="example-modal-sizes-title-lg">
                                      Delete News
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <p style={{ fontWeight: "bold" }}>
                                      Are you sure you want to delete?
                                    </p>
                                    <p style={{ opacity: "0.8" }}>
                                      This process can't be undone.
                                    </p>
                                    <div className="row">
                                      <Button
                                        onClick={() => {
                                          this.DeleteNews();
                                        }}
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                        }}
                                      >
                                        {this.state.loading ? (
                                          <span>
                                            {" "}
                                            <ClipLoader
                                              color="white"
                                              size={20}
                                            />
                                            Please Wait{" "}
                                          </span>
                                        ) : (
                                          <span>Delete</span>
                                        )}
                                      </Button>
                                    </div>
                                    {this.state.success ? (
                                      <div
                                        style={{
                                          backgroundColor: "lightgreen",
                                        }}
                                      >
                                        <h6 style={{ color: "white" }}>
                                          {this.state.newsdltmsg}
                                        </h6>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {this.state.error ? (
                                      <div
                                        style={{
                                          backgroundColor: "red",
                                        }}
                                      >
                                        <h6 style={{ color: "white" }}>
                                          {this.state.newsdltmsg}
                                        </h6>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Modal.Body>
                                </Modal>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 5 ? (
                <div>
                  {" "}
                  {this.state.addmessage ? (
                    <div className="container">
                      <div className="col-md-1">
                        <Button
                          style={{ borderRadius: "50%" }}
                          onClick={() => {
                            this.setState({
                              addmessage: false,
                            });
                          }}
                        >
                          <HiOutlineArrowNarrowLeft size="15px" />
                        </Button>
                      </div>
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          <h5>ADD MESSAGE</h5>

                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="row" style={{ paddingTop: "5vh" }}>
                          <p style={{ opacity: "0.8" }}>Subject</p>
                          <Form>
                            <TextField
                              value={this.state.subject}
                              onChange={(evt) =>
                                this.setState({ subject: evt.target.value })
                              }
                              type="name"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="row" style={{ paddingTop: "2vh" }}>
                          <p style={{ opacity: "0.8" }}>Description</p>
                          <Form>
                            <Form.Control
                              value={this.state.description}
                              onChange={(evt) =>
                                this.setState({ description: evt.target.value })
                              }
                              as="textarea"
                              row={3}
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="row" style={{ paddingTop: "2vh" }}>
                          <div className="col">
                            <Button onClick={() => this.Addmessagedata()}>
                              {this.state.loading ? <ClipLoader /> : "Submit"}
                            </Button>
                          </div>
                          {this.state.success ? (
                            <div
                              style={{
                                backgroundColor: "lightgreen",
                                color: "black",
                              }}
                            >
                              {this.state.addmessagemsg}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          <h5>MESSAGE</h5>

                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <Button
                            variant="none"
                            style={{ border: "1px solid lightgrey" }}
                            onClick={() => {
                              this.setState({ addmessage: true });
                            }}
                          >
                            Add Message
                            <GrAddCircle color="lightgreen" />
                          </Button>
                        </div>
                      </div>
                      <div
                        className="container"
                        style={{ paddingTop: "10px", marginLeft: "0px" }}
                      >
                        {this.state.Messages.map((l, id) => {
                          return (
                            <div className="row" style={{ paddingTop: "2vh" }}>
                              <div class="col-sm-10">
                                <div class="card">
                                  <div class="card-body">
                                    <Typography variant="h5">
                                      {l.title}
                                    </Typography>
                                    <p1 class="card-text">{l.description}</p1>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-2">
                                <button
                                  className="rounded-circle"
                                  style={{
                                    backgroundColor: "lightgreen",
                                    height: "40px",
                                    width: "40px",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    this.openmsgdeletemodal(l);
                                  }}
                                >
                                  <AiFillDelete color="white" />
                                </button>
                              </div>
                              <Modal
                                size="md"
                                show={this.state.deletemsgmodal}
                                onHide={() =>
                                  this.setState({ deletemsgmodal: false })
                                }
                                aria-labelledby="example-modal-sizes-title-sm"
                                animation="true"
                                autoFocus="true"
                                centered
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title id="example-modal-sizes-title-lg">
                                    Delete Message
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <p style={{ fontWeight: "bold" }}>
                                    Are you sure you want to delete?
                                  </p>
                                  <p style={{ opacity: "0.8" }}>
                                    This process can't be undone.
                                  </p>
                                  <div className="row">
                                    <Button
                                      onClick={() => {
                                        this.DeleteMessage();
                                      }}
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                      }}
                                    >
                                      {this.state.loading ? (
                                        <span>
                                          {" "}
                                          <ClipLoader color="white" size={20} />
                                          Please Wait{" "}
                                        </span>
                                      ) : (
                                        <span>Delete</span>
                                      )}
                                    </Button>
                                  </div>
                                  {this.state.success ? (
                                    <div
                                      style={{
                                        backgroundColor: "lightgreen",
                                      }}
                                    >
                                      <h6 style={{ color: "white" }}>
                                        {this.state.deletemsgmodalmsg}
                                      </h6>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.error ? (
                                    <div
                                      style={{
                                        backgroundColor: "red",
                                      }}
                                    >
                                      <h6 style={{ color: "white" }}>
                                        {this.state.deletemsgmodalmsg}
                                      </h6>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Modal.Body>
                              </Modal>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
const styles = (theme) => ({
  rowBox: {
    display: "flex",

    marginTop: "8px",
    marginBottom: "12px",
  },
  personalInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "1vh",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
    minHeight: "30vh",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  addTuitionButton: {
    backgroundColor: "lightgreen",
    color: "white",
    borderRadius: "30px",
    marginLeft: "2px",
  },
});
// export default ObserverSciencefair;
export default withStyles(styles)(ObserverSciencefair);
