// import React, { Component } from "react";
// import { Form, Select, Card } from "react-bootstrap";

// export default class GamingReport extends Component {
//   render() {
//     return (
//       <div>
//         {" "}
//         <div className="banner-register">
//           <img
//             className=""
//             src={require("../assets/img/banner-register.jpeg").default}
//             style={{
//               height: "300px",
//               width: "100%",
//               opacity: 0.8,
//             }}
//           />
//           <h1>STUDENT REPORT</h1>
//           <p>Home | Gaming Academy | Student Report</p>
//         </div>{" "}
//         <div className="container">
//           <div className="row">
//             <div className="col-md-6 offset-1">
//               <h5> GAMING REPORT</h5>

//               <div
//                 style={{
//                   height: "3px",
//                   width: "80px",
//                   textDecoration: "underline",
//                   backgroundColor: "lightblue",
//                 }}
//               ></div>
//             </div>
//             <div className="col-md-2">
//               <Form.Select aria-label="">
//                 <option>Class</option>
//                 <option value="1">One</option>
//                 <option value="2">Two</option>
//                 <option value="3">Three</option>
//               </Form.Select>
//             </div>
//             <div className="col-md-2">
//               {" "}
//               <Form.Select aria-label="">
//                 <option>Game</option>
//                 <option value="1">One</option>
//                 <option value="2">Two</option>
//                 <option value="3">Three</option>
//               </Form.Select>
//             </div>
//           </div>
//           <div
//             className="row"
//             style={{
//               backgroundColor: "lightgrey",
//               paddingTop: "15px",
//               position: "relative",
//               top: "20px",
//             }}
//           >
//             <div className="col-md-6 offset-1">
//               <h6>Student Lists</h6>
//             </div>
//             <div className="col-md-2">
//               <h6>Marks</h6>
//             </div>
//             <div className="col-md-2">
//               <h6>Grade</h6>
//             </div>
//           </div>
//           <div className="row" style={{ paddingTop: "15px" }}>
//             <div className="col-md-6 offset-1">
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>
//                   Sourav Chakraborty
//                 </p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>
//                   Arinjit Paul
//                 </p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>
//                   Sanjit Das
//                 </p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>
//                   Muzaffar Ahmed
//                 </p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>
//                   Swagat Bose
//                 </p1>
//               </div>
//             </div>
//             <div className="col-md-2 ">
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>20</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>20</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>20</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>AA</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>AA</p1>
//               </div>
//             </div>
//             <div className="col-md-2 ">
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>AA</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>B+</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>B</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>A+</p1>
//               </div>
//               <div className="row" style={{ paddingTop: "15px" }}>
//                 <p1 style={{ fontSize: "small", opacity: "0.8" }}>A</p1>
//               </div>
//             </div>
//           </div>
//           <div className="col offset-1" style={{ paddingTop: "25px" }}>
//             <button
//               style={{
//                 border: "none",
//                 backgroundColor: "rgb(24, 169, 218)",
//                 color: "white",
//               }}
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

import React, { Component, useState, useEffect } from "react";
// import { Form, Select, Card } from "react-bootstrap";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  MenuItem
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../provider/useWindowSize'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListIcon from '@mui/icons-material/List';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import API from '../components/api'

 const GamingReport=({classes})=>{

  const [studentReportList, setStudentReportList] = useState([])
  const [studentList, setStudentList] = useState([])
  const [studentListBackup, setStudentListBackup] = useState([])

  const [studentFilterList, setStudentFilterList] = useState([])


  const [classList, setClassList] = useState([])
  const [subjectList, setSubjectList] = useState([])

  const [studentReportAddPayload, setStudentReportAddPayload] = useState({
    user_id: "",
    class_id: "",
    game_id: "",
    exam_date: "",
    report: [],
    action: "view"
  })
  const [studentReportListingPayload, setStudentReportListingPayload] = useState({
    user_id: "",
    class_id: "none",
    game_id: "none",
    exam_date: "",
    action: "view"
  })

  const [buttonAccesible, setButtonAccesible] = useState(true)



  const [studentReportAdd, setStudentReportAdd] = useState(false)
  const [studentReportListing, setStudentReportListing] = useState(false)

  const [singleData, setSingleData] = useState({
    student_id: "",
    marks: "",
    grade: ""
  })
  const [currentIndex, setCurrentIndex] = useState(0)

  const [showButon, setShowButon] = useState(false)
  const [showAddButton, setShowAddButton] = useState(false)
  const [showListButton, setShowListButon] = useState(false)
  const [showSubmitButton, setShowSubmitButon] = useState(true)


  const [clas, setClas] = useState(0)
  const [subject, setSubject] = useState(0)
  const [searchDate, setSearchDate] = useState("")

  useEffect(() => {
    API.post("MasterApi/class_list", { school_id: "" }).then(resp => {
      if (resp.data.result === 1) {
        setClassList(resp.data.data)
      } else {
        setClassList([])
      }
    })
    API.post("MasterApi/game_list").then(resp => {
      if (resp.data.result === 1) {
        setSubjectList(resp.data.data)
      } else {
        setSubjectList([])
      }
    })

  

    setStudentReportListingPayload({
      ...studentReportListingPayload,
      user_id: localStorage.getItem("userID"),
      action: "view"
    })

    setStudentReportAddPayload({
      ...studentReportAddPayload,
      user_id: localStorage.getItem("userID"),
      action: "add"
    })
  }, [])

  useEffect(() => {

    if (studentReportListingPayload?.game_id &&
      studentReportListingPayload?.game_id !== 'none' &&
      studentReportListingPayload?.exam_date &&
      studentReportListingPayload?.class_id &&
      studentReportListingPayload?.class_id !== 'none') {
        API.post("AcademyApi/subscribers", { 
          user_id: localStorage.getItem("userID"),
          game_id:studentReportListingPayload?.game_id
         }).then(resp => {
          if (resp.data.result === 1) {
            // setStudentList(resp.data.data)
            setStudentListBackup(resp.data.data)
          } else {
            // setStudentList([])
            setStudentListBackup([])
          }
        })
      setButtonAccesible(false)
    }

  }, [studentReportListingPayload])

  const getSearchData = (e) => {
    const { name, value } = e.target;
    setStudentReportListingPayload({
      ...studentReportListingPayload,
      [name]: value
    })

    setStudentReportAddPayload({
      ...studentReportAddPayload,
      [name]: value
    })

  }



  const addStudentReport = () => {
    setShowAddButton(true)
    setShowButon(false)
    API.post("AcademyApi/studentReport", studentReportListingPayload).then(resp => {
      if (resp.data.result === 1) {
        setStudentReportList(resp.data.data)

      } else {
        setStudentReportList([])
      }
    })
    if (studentReportListingPayload?.game_id &&
      studentReportListingPayload?.game_id !== 'none' &&
      studentReportListingPayload?.class_id &&
      studentReportListingPayload?.class_id !== 'none') {
      filterStudent(studentReportListingPayload?.class_id)
    }

    setStudentReportAdd(true)
    setStudentReportListing(false)
    setShowAddButton(false)
  }



  const filterStudent = (class_id) => {
    let initialList = studentListBackup
    let byStudent = []
    let byClass = []
    let bySubject = []
    // if (studentReportList.length > 0) {
    //   alert("studentReportList greater than 0")
    //   for (let i = 0; i < studentReportList.length; i++) {
    //     for(let j=0;j<studentListBackup.length;j++){
    //       if(studentReportList[i].student_id !== studentListBackup[j].student_id){
    //         byStudent.push(studentListBackup[j])
    //       }
    //     }
    //     setStudentListBackup(byStudent)
    //     console.log("studentListBackupinner",initialList);
    //   }
    //   byClass = studentListBackup.filter(stu => stu.class_id === class_id)
    //   if (byClass.length > 0) {
    //     bySubject = byClass.filter(sub => sub.game_id === game_id)
    //   } else {
    //     setStudentList([])
    //   }
    // } else {
    // }

    byClass = studentListBackup.filter(stu => stu.class_id === class_id)
    // if (byClass.length > 0) {
    //   bySubject = byClass.filter(sub => sub.game_id === game_id)
    // } else {
    //   setStudentList([])
    // }


    if (byClass.length > 0) {
      setStudentList(byClass)

    } else {
      setStudentList([])
    }


  }

  const listStudentReport = () => {
    setShowListButon(true)
    setShowButon(false)
    API.post("AcademyApi/studentReport", studentReportListingPayload).then(resp => {
      if (resp.data.result === 1) {
        setStudentReportList(resp.data.data)
      } else {
        setStudentReportList([])
      }
    })
    setStudentReportAdd(false)
    setStudentReportListing(true)
    setShowListButon(false)
  }

  const getReport = (e, i, id) => {
    console.log("event index", e.target, i, id);
    setCurrentIndex(i)
    const { name, value } = e.target;
    // let singleData={
    //   student_id:"",
    //   marks:"",
    //   grade:""
    // }
    singleData.student_id = id
    if (name === 'grade') {
      setSingleData({
        ...singleData,
        grade: value
      })
    } else if (name === 'marks') {
      setSingleData({
        ...singleData,
        marks: value
      })
    }




  }

  const saveCurrentReport = (i) => {
    if (singleData.grade && singleData.marks && singleData.student_id) {
      studentReportAddPayload.report[i] = singleData
    }

    if (studentReportAddPayload.report.length > 0) {
      setShowSubmitButon(false)
    } else {
      setShowSubmitButon(true)
    }

    console.log("studentReportAddPayloadInner", studentReportAddPayload);
  }

  const cancelCurrentReport = (i) => {
    studentReportAddPayload.report.splice(i, 1)
    if (studentReportAddPayload.report.length > 0) {
      setShowSubmitButon(false)
    } else {
      setShowSubmitButon(true)
    }
  }
  const submitReport = () => {
    setShowButon(true)
    API.post("AcademyApi/studentReport", studentReportAddPayload).then(resp => {
      if (resp.data.result === 1) {
        listStudentReport()
        setStudentReportAddPayload({
          user_id: "",
          class_id: "",
          game_id: "",
          exam_date: "",
          report: [],
          action: "view"
        })
        setShowButon(false)
      } else {
        setStudentReportList([])
      }
    })
  }




  console.log("singleData", singleData);

  console.log("studentList", studentList);
  console.log("studentReportListingPayload", studentReportListingPayload);
  console.log("studentReportList", studentReportList);

  console.log("studentListBackup", studentListBackup);
  console.log("studentReportAddPayload", studentReportAddPayload);
    return (
      <div>
        {" "}
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1>STUDENT REPORT</h1>
          <p>Home | Gaming Academy | Student Report</p>
        </div>{" "}
        <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box>
              <Typography variant="h5" style={{ paddingLeft: "8px" }}>GAMING REPORT</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <FormControl fullWidth>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="class_id"
                input={<OutlinedInput />}
                value={studentReportListingPayload?.class_id}
                onChange={getSearchData}

              >
                <MenuItem value="none">Class</MenuItem>
                {classList.map(c => <MenuItem value={c.class_id}>{c.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="game_id"
                input={<OutlinedInput />}
                value={studentReportListingPayload?.game_id}
                onChange={getSearchData}
              >
                <MenuItem value="none">Game</MenuItem>
                {subjectList.map(sub => <MenuItem value={sub.game_id}>{sub.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <FormControl fullWidth>
              <TextField
                name="exam_date"
                type="date"
                variant="outlined"
                onChange={getSearchData}
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Box>
              {showAddButton ?
                <CircularProgress />
                :
                <Button
                  style={{ border: "1px solid lightgrey", color: "black" }}
                  onClick={addStudentReport}
                  disabled={buttonAccesible}
                >
                  Add
                  <AddCircleOutlineIcon className={classes.addTuitionButton} />
                </Button>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Box>
              {showListButton ?
                <CircularProgress />
                : <Button
                  style={{ border: "1px solid lightgrey", color: "black" }}
                  onClick={listStudentReport}
                  disabled={buttonAccesible}
                >
                  List
                  <ListIcon className={classes.addTuitionButton} />
                </Button>}
            </Box>
          </Grid>

        </Grid>
      </Container>
      <Container>
        <Grid container marginY={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div
              style={{
                backgroundColor: "rgb(24, 169, 218)",
                height: "2px",
                width: "19%"
              }}>
            </div>
          </Grid>
        </Grid>
      </Container>
      {!studentReportAdd && !studentReportListing && <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" flexDirection="column" paddingY={8} style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
              <Box display="flex" justifyContent="center" paddingY={1}>
                <Box alignSelf="center" ><Typography>Please Select "Class", "Subject" and "Date". Click</Typography></Box>
                <Box marginX={1}>
                  <Button
                    style={{ border: "1px solid lightgrey", color: "black" }}
                    disabled
                  >
                    Add
                    <AddCircleOutlineIcon className={classes.addTuitionButton} />
                  </Button>
                </Box>
                <Box alignSelf="center"><Typography> for "New Report"</Typography></Box>
              </Box>
              <Box display="flex" justifyContent="center" paddingY={1}>
                <Box alignSelf="center"><Typography>Please Select "Class", "Subject" and "Date". Click</Typography></Box>
                <Box marginX={1}>
                  <Button
                    style={{ border: "1px solid lightgrey", color: "black" }}
                    disabled
                  >
                    List
                    <ListIcon className={classes.addTuitionButton} />
                  </Button>
                </Box>
                <Box alignSelf="center"><Typography> for "Listing"</Typography></Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>}
      {studentReportAdd &&
        <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead >
              <TableRow >
                <TableCell style={{ backgroundColor: "lightgray" }}>
                  Student Name
                </TableCell>
                <TableCell style={{ backgroundColor: "lightgray", textAlign: "center" }}>
                  Marks
                </TableCell>
                <TableCell style={{ backgroundColor: "lightgray", textAlign: "center" }}>
                  Grade
                </TableCell>
                <TableCell style={{ backgroundColor: "lightgray", textAlign: "center" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList.map((stud, index) =>
                <TableRow hover >
                  <TableCell >
                    {stud?.name}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TextField
                      name="marks"
                      type="number"
                      variant="outlined"
                      onChange={(e) => getReport(e, index, stud?.student_id)}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="grade"
                      input={<OutlinedInput />}
                      onChange={(e) => getReport(e, index, stud?.student_id)}
                    >
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Box display="flex" justifyContent="center">
                      <Box>
                        <button
                          onClick={() => saveCurrentReport(index)}
                          className={classes.tuitionTimeStatusButton}>
                          <SaveIcon style={{ color: "white" }} />
                        </button>
                      </Box>
                      {/* <Box>
                        <button
                        onClick={()=>cancelCurrentReport(index)}
                          className={classes.tuitionTimeStatusButton}>
                          <CancelIcon style={{ color: "white" }} />
                        </button>
                      </Box> */}
                    </Box>
                  </TableCell>
                </TableRow>)}
            </TableBody>
          </Table>
          <Box marginY={2}>
            {studentList.length > 0 ?
              <Box>
                {showSubmitButton ?
                  <Button
                    disabled
                    variant="contained"
                  >
                    SUBMIT
                  </Button>
                  : <>
                    {showButon ?
                      <CircularProgress />
                      :
                      <Button
                        onClick={submitReport}
                        style={{
                          backgroundColor: "rgb(24, 169, 218)", color: "white",
                          border: "none", height: "40px", width: "140px"
                        }}>
                        SUBMIT
                      </Button>
                    }
                  </>}
              </Box>
              :
              <Typography>Data Not Found</Typography>
              }
          </Box>
        </Container>}
      {studentReportListing &&
        <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead >
              <TableRow >
                <TableCell style={{ backgroundColor: "lightgray" }}>
                  Student Name
                </TableCell>
                <TableCell style={{ backgroundColor: "lightgray", textAlign: "center" }}>
                  Marks
                </TableCell>
                <TableCell style={{ backgroundColor: "lightgray", textAlign: "center" }}>
                  Grade
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentReportList.map((repo, index) =>
                <TableRow hover >
                  <TableCell >
                    {repo?.student}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {repo?.marks}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {repo?.grade}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <Box marginY={2}>
            {studentReportList.length === 0 &&
              <Typography>Data Not Found</Typography>
              }
          </Box>
          </Table>
        </Container>}
      </div>
    );
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  addTuitionButton: {
    backgroundColor: "lightgreen",
    color: "white",
    borderRadius: "30px",
    marginLeft: "2px"
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px"
  },
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(GamingReport)

