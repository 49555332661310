import React, { Component, useEffect, useState } from "react";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../../provider/useWindowSize'
import API from "../../components/api";

const MotivationalVideo = ({ classes }) => {
  const [motivationalVideos, setMotivationalVideos] = useState([])

  useEffect(() => {
    API.post("TeacherApi/videoList").then(res => {
      if (res.data.result === 1) {
        setMotivationalVideos(res.data.motivationalVideos)
      } else {
        setMotivationalVideos([])
      }
    })
  }, [])

  const getVideoId = (video) => {
    localStorage.setItem("category_type", "Motivational")
    localStorage.setItem("selected_video", JSON.stringify(video))

  }
  console.log("motivationalVideos", motivationalVideos);
  return (
    <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
          <h1> MOTIVATIONAL VIDEOS</h1>
          <p><Link href="/" style={{ color: "white", textDecoration: "none" }}>Home</Link>  | Teacher | Motivational Videos</p>
        
      </div>
      <Container>
        {motivationalVideos.length > 0 && <Grid container spacing={2} marginY={2}>
          {motivationalVideos.map(video =>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card onClick={() => getVideoId(video)}>
                <Link href="/videodetail" style={{ textDecoration: "none" }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={video?.thumbnail_file}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{ color: "black" }}>
                      {video?.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles
                    </Typography>
                  </CardContent>
                </Link>
              </Card>

            </Grid>

          )}

        </Grid>
        }
        {motivationalVideos.length === 0 &&
          <Grid container spacing={2} marginY={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" style={{ justifyContent: "center", height: "200px" }}>
                <Box>
                  <Typography>Video not available</Typography>
                </Box>
              </Box>

            </Grid>
          </Grid>}
      </Container>
      {/* <div
        className="container"
        style={{ paddingLeft: "10rem", paddingTop: "2rem" }}
      >
        <div className="row">
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <Link href="/videodetail">
                <div className="embed-responsive embed-responsive-4by3">
                  <img
                    className="card-img-top"
                    src={require("../../assets/img/coding.jpeg").default}
                    alt="Card  cap"
                  />
                </div>
              </Link>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={require("../../assets/img/coding.jpeg").default}
                  alt=""
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={require("../../assets/img/coding.jpeg").default}
                  alt=""
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={require("../../assets/img/coding.jpeg").default}
                  alt=""
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "2rem" }}>
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={require("../../assets/img/coding.jpeg").default}
                  alt=""
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={require("../../assets/img/coding.jpeg").default}
                  alt=""
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={require("../../assets/img/coding.jpeg").default}
                  alt=""
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {" "}
            <div className="card" style={{ width: "13rem", height: "auto" }}>
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={require("../../assets/img/coding.jpeg").default}
                  alt=""
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">Start Today not Tomorrow</h6>
                <p className="card-text" style={{ fontSize: "smaller" }}>
                  Posted on 11th august , 2021
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(MotivationalVideo);
