import React, { Component } from "react";
import ReactPlayer from "react-player";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FloatingLabel, Form } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";
// import { Router, Link } from "@reach/router"
import { Link } from "@mui/material";
import API from "./api";

export default class InnovationVideodetails extends Component {
  state = {
    file: "",
    CodingVideos: [],
  };
  componentDidMount() {
    let video = localStorage.getItem("innovate_file");
    this.setState({ file: video });
    this.getcodingVideos();
  }
  getplayablevideoid(l) {
    let videofile = l.file;
    console.log("vfile", videofile);
    this.setState({ file: videofile });
  }
  getcodingVideos() {
    var that = this;
    let user = localStorage.getItem("userID");
    API.post("StudentApi/student_home", { user_id: user })
      .then(function (response) {
        console.log(response.data.innovativeVideos);
        that.setState({
          CodingVideos: response.data.innovativeVideos,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1>INNOVATIONAL VIDEOS</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              {" "}
              Student
            </Link>{" "}
            |
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/student/motivationalvideo"
            >
              {" "}
              Innovational Videos
            </Link>{" "}
          </p>
        </div>
        <div className="container " style={{ paddingTop: "30px" }}>
          <div className="row innovation-row">
            <div className="col-md-6 offset-1" innovation-col>
              <ReactPlayer
                playing={true}
                width="100%"
                controls={true}
                url={this.state.file}
              />
              <div className="row">
                {/* <h5>START TODAY NOT TOMORROW</h5> */}
              </div>
              <div
                className="container"
                style={{ backgroundColor: "", height: "100px" }}
              >
                {/* <div className="row">
                  <div className="col-md-4">
                    <h6
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "25px",
                      }}
                    >
                      Posted On
                    </h6>
                    <p
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "15px",
                      }}
                    >
                      11 aug, 2021
                    </p>
                  </div>
                  <div className="col-md-3 ">
                    <h6
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "25px",
                      }}
                    >
                      Duration
                    </h6>
                    <p
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "15px",
                      }}
                    >
                      20 minutes
                    </p>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "55px" }}>
                  <h6>Message</h6>
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Write Message"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                    />
                  </FloatingLabel>
                </div>
                <div className="col" style={{ paddingTop: "25px" }}>
                  <button
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Send
                  </button>
                </div> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <button
                  style={{
                    backgroundColor: "blue",
                    border: "none",
                    height: "40px",
                  }}
                >
                  <FaLongArrowAltRight />
                  SIMILAR VIDEOS
                </button>
              </div>
              {this.state.CodingVideos === undefined ? (
                <div>No Similar Videos Found</div>
              ) : (
                <div>
                  {this.state.CodingVideos.map((l, id) => {
                    return (
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          background: "#efefef",
                          margin: "20px 0",
                          alignItems: "center",
                          borderRadius: "6px",
                          overflow: "hidden",
                          width: "85%",
                        }}
                      >
                        <div className="col-md-5">
                          <img
                            style={{ height: "60px", width: "100px" }}
                            src={l.thumbnail_file}
                          />
                          <button
                            style={{
                              height: "35px",
                              width: "35px",
                              border: "none",
                              position: "absolute",
                              left: "65%",

                              transform: "translate(-20%,-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.getplayablevideoid(l);
                            }}
                            className="rounded-circle"
                          >
                            <FaPlayCircle size="35px" />
                          </button>
                        </div>
                        <div className="col-md-7">
                          <p>{l.name}</p>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            {l.coding_language}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div style={{ height: "200px" }}></div>
      </div>
    );
  }
}
