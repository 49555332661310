import React, { Component } from 'react';
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import {
    Select,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    OutlinedInput,
    TextField,
    Grid,
    Container,
    Box,
    Typography,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Link,
    CircularProgress,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    useTheme
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../provider/useWindowSize'

const MonthlyLesson = () => {
    const theme = useTheme()
    const { width } = useWindowSize()
    const { sm, md, lg } = theme.breakpoints.values
    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box>
                            <Typography variant="h5" style={{ paddingLeft: "8px" }}>MONTHLY LESSON PLAN</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box display="flex" style={{ justifyContent: width < sm ? "flex-start" : "flex-end" }}>
                            <Box><TextField
                                name='date'
                                variant="outlined"
                                type="date"
                            /></Box>
                            <Box marginX={1}>
                                <button className="rounded-circle" style={{ backgroundColor: "lightgreen", border: "none", height: "40px", width: "40px" }}>
                                    <MdModeEdit color="white" />
                                </button>
                            </Box>
                            <Box marginX={1}>
                                <button className="rounded-circle" style={{ backgroundColor: "lightgreen", border: "none", height: "40px", width: "40px" }}>
                                    <AiFillDelete color="white" />
                                </button>
                            </Box>
                        </Box>
                    </Grid>
                    <div
                        style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "160px",
                            marginLeft: "23px"
                        }}>
                    </div>
                </Grid>
            </Container>


            <Container >
                <Grid container spacing={2} marginY={2}>
                    <Grid item xs={12} >
                        <div className="row" style={{ paddingBottom: "25px", paddingTop: "30px" }}>
                            <p1>1. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                        </div>
                        <div className="row" style={{ paddingBottom: "25px" }}>
                            <p1>2.Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                        </div>
                        <div className="row" style={{ paddingBottom: "25px" }}>
                            <p1>3.Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                        </div>
                        <div className="row" style={{ paddingBottom: "25px" }}>
                            <p1>4.Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                        </div>
                        <Box display="flex" flexDirection="column">
                            <Box>
                                <p1 style={{ fontSize: "smaller", opacity: "0.8" }}>verified By</p1>
                            </Box>
                            <Box>
                                <img alt="" style={{ height: "40px", width: "120px", right: "6vh" }} src={require("../assets/img/logo.png").default} />
                            </Box>


                        </Box>
                    </Grid>
                </Grid>
            </Container>
            {/* <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h5>MONTHLY LESSON PLAN</h5>
                        <div style={{ height: "2px", backgroundColor: "rgb(24, 169, 218)", width: "80px" }}></div>
                    </div>
                    <div className="col-md-3"><input type="date" label="ddddd" placeholder="Choose a Month" /></div>
                    <div className="col-md-1">
                        <button className="rounded-circle" style={{ backgroundColor: "lightgreen", border: "none", height: "40px", width: "40px" }}>
                            <MdModeEdit color="white" /></button> </div>
                    <div className="col-md-1"><button className="rounded-circle" style={{ backgroundColor: "lightgreen", border: "none", height: "40px", width: "40px" }}>
                        <AiFillDelete color="white" /></button></div>
                    <div className="row" style={{ paddingBottom: "25px", paddingTop: "30px" }}>
                        <p1>1. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                    </div>
                    <div className="row" style={{ paddingBottom: "25px" }}>
                        <p1>2.Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                    </div>
                    <div className="row" style={{ paddingBottom: "25px" }}>
                        <p1>3.Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                    </div>
                    <div className="row" style={{ paddingBottom: "25px" }}>
                        <p1>4.Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown .</p1>
                    </div>
                    <div className="row" >
                        <div className="col-md-2">
                            <p1 style={{ fontSize: "smaller", opacity: "0.8" }}>verified By</p1>
                        </div>
                        <div className="col-md-2">
                            <img alt="" style={{ height: "40px", width: "120px", position: "relative", right: "6vh" }} src={require("../assets/img/logo.png").default} /></div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
const styles = theme => ({
    root: {
        // color: theme.palette.primary.main
    },
    // profileheading: {
    //   position: "absolute",
    //   top: "41%",
    //   left: "16%",
    //   color: "white"
    // },
    // notclicked: {
    //   backgroundolor: "lightgrey",
    //   height: "40px",
    //   border: "none",
    //   width: "100%",
    // },
    // cliked: {
    //   backgroundColor: "rgb(24, 169, 218)",
    //   height: "40px",
    //   border: "none",
    //   width: "100%",
    // }
    // demoPlayerBox: {
    //   width: "43%",
    //   height: "200px",
    //   objectFit: "cover",
    //   border: "2px solid black",
    //   borderRadius: "5px",
    // },
    // demoPlayerBoxEdit:{
    //   width: "100%",
    //   height: "55px",
    //   objectFit: "cover",
    //   border: "2px solid black",
    //   borderRadius: "5px",
    // }
    // nextButton1: {
    //   marginTop: "12px"
    // }

})
export default withStyles(styles)(MonthlyLesson);