import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaLongArrowAltRight, FaSleigh } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
// import { Router, Link } from "@reach/router"
// import { Link } from "@mui/material";
import API from "../components/api";
import { GoogleMap } from "react-google-maps";
import { Form, Button, Modal } from "react-bootstrap";
import {
  Select,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

class GameProfile extends Component {
  state = {
    profiledata: [],
    bankDetail: [],
    Gamedetail: [],
    personalmodal: false,
    Video: "",
    otherinfo: false,
    aboutacademy: "",
    winning: "",
    successstory: "",
    Image: "",
    editacademyaddress: "",
    editacademyname: "",
    editacademyname: "",
    editacademyowner: "",
    email: "",
    mobile: "",
    bankmodal: false,
    password: "",
    editbankname: "",
    editifsc: "",
    editaccnumber: "",
    loginmodal: false,
    success: false,
    error: false,
    successmsg: "",
    errormsg: "",
  };
  componentDidMount() {
    this.getProfile();
  }
  openbankModal() {
    this.setState({
      personalmodal: false,
      bankmodal: true,
      editbankname: this.state.bankDetail.bank_name,
      editifsc: this.state.bankDetail.ifsc_code,
      editaccnumber: this.state.bankDetail.account_number,
    });
  }
  Editprofile() {
    var formdata = new FormData();

    formdata.append("user_id", localStorage.getItem("userID"));
    formdata.append(
      "name",
      this.state.editacademyname || this.state.profiledata.name
    );
    formdata.append(
      "owner_name",
      this.state.editacademyowner || this.state.profiledata.owner_name
    );
    formdata.append(
      "address",
      this.state.editacademyaddress || this.state.profiledata.address
    );
    formdata.append("latitude", "");
    formdata.append("longitude", "");
    formdata.append("mobile", this.state.profiledata.mobile);
    formdata.append("email", this.state.profiledata.email);
    formdata.append("password", this.state.password);
    formdata.append("photo", this.state.Image || this.state.profiledata.photo);
    formdata.append(
      "password",
      this.state.password || this.state.profiledata.text_password
    );
    formdata.append(
      "about_academy",
      this.state.aboutacademy || this.state.profiledata.about_academy
    );
    formdata.append(
      "successful_story",
      this.state.successstory || this.state.profiledata.successful_story
    );
    formdata.append(
      "winning_tournament",
      this.state.winning || this.state.profiledata.winning_tournament
    );
    var that = this;
    API.post("AcademyApi/edit_profile", formdata)
      .then(function (response) {
        console.log(response.data.data);

        if (response.data.result === 1) {
          that.setState({
            success: true,

            successmsg: response.data.message,
          });
          setTimeout(() => {
            that.setState({ success: false, loginmodal: false });
          }, 2000);
          that.getProfile();
        } else {
          that.setState({ errormsg: response.data.message, error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2000);
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {});
  }
  getProfile() {
    const userid = localStorage.getItem("userID");
    var that = this;
    API.post("AcademyApi/profile", { user_id: userid })
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          profiledata: response.data.data,
          bankDetail: response.data.data.bankDetails,
          Gamedetail: response.data.data.game_ids,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  OpenModal() {
    this.setState({
      personalmodal: true,
      editacademyname: this.state.profiledata.name,
      email: this.state.profiledata.email,
      mobile: this.state.profiledata.mobile,
      editacademyaddress: this.state.profiledata.address,
      editacademyowner: this.state.profiledata.owner_name,
      aboutacademy: this.state.profiledata.about_academy,
      winning: this.state.profiledata.winning_tournament,
      successstory: this.state.profiledata.successful_story,
    });
  }
  bankEditinfo() {
    const params = {
      user_id: localStorage.getItem("userID"),
      bank_name: this.state.editbankname,
      ifsc_code: this.state.editifsc,
      account_number: this.state.editaccnumber,
    };
    var that = this;
    API.post("AcademyApi/edit_bank_details", params)
      .then(function (response) {
        console.log(response);
        if (response.data.result === 1) {
          that.setState({
            successmsg: response.data.message,
          });
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
    this.setState({ bankmodal: false, otherinfo: true });
  }
  handlevideoChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Video: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  handleAcademyimg(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> GAME ACADEMY PROFILE</h1>
          <p>
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home
            </Link>{" "}
            | Game Academy Profile
          </p>
        </div>
        {/* //++++____++++____++++____+++++____+_+_+++_++__+_+++++____+__+___ */}
        <Container style={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4}>
              <Box display="flex" flexDirection="column">
                <Box style={{ objectFit: "cover" }}>
                  <img
                    style={{ width: "100%" }}
                    src={this.state.profiledata.photo}
                    alt=""
                  />
                </Box>
                <Box display="flex" flexDirection="column" marginTop={2}>
                  <Box className={classes.dashboardSideButton} display="flex">
                    <Box
                      marginX={4}
                      style={{ alignSelf: "center", color: "white" }}
                    >
                      <FaLongArrowAltRight />
                    </Box>
                    <Box style={{ alignSelf: "center", color: "white" }}>
                      <Typography>DASHBOARD</Typography>
                    </Box>
                    {/* <Link href="#">
                    <button
                      className={classes.dashboardSideButton}
                    >
                      <FaLongArrowAltRight /> DASHBOARD
                    </button>
                  </Link> */}
                  </Box>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/classification"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>CLASSIFICATION</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link href="/subscrip" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>SUBSCRIPTION</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/academy/videos"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography> VIDEOS</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/gametournament"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>TOURNAMENTS</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/gamingreports"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>STUDENT REPORTS</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link href="/academynews" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>ACADEMY NEWS</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/gameacademy/otherinformation"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>OTHER INFORMATIONS</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography variant="h5">
                {this.state.profiledata.name}
              </Typography>
              <p className={classes.profileLabel}>Game Academy</p>
              <div
                style={{
                  backgroundColor: "rgb(24, 169, 218)",
                  height: "2px",
                  width: "80px",
                }}
              ></div>
              <Box className={classes.root}>
                <Box className={classes.personalInfoBox}>
                  <Box className={classes.singleBox}>
                    <Box>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Personal Information
                      </Typography>
                    </Box>
                    <Box>
                      <button
                        className={classes.profileEditButton}
                        // onClick={personalDialog}
                        onClick={() => {
                          this.OpenModal();
                        }}
                      >
                        <MdModeEdit color="white" />
                      </button>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Owner's Name
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {this.state.profiledata.owner_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  {/* <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        School Code
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {this.state.profiledata.school_code}
                      </Typography>
                    </Box>
                  </Box> */}
                  <Divider />

                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Mobile Number
                      </Typography>
                    </Box>
                    <Box>{this.state.profiledata.mobile}</Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Email Address
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{this.state.profiledata.email}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Address
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{this.state.profiledata.address}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Geo Location
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{this.state.profiledata.address}</Typography>
                    </Box>
                  </Box>
                  {/* <Divider /> */}
                </Box>
                <Box className={classes.loginInfoBox}>
                  <Box className={classes.singleBox}>
                    <Box>
                      {" "}
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Classification of Game
                      </Typography>
                    </Box>

                    <Box>
                      {/* <button
                        className={classes.profileEditButton}
                        // onClick={() => {
                        //   this.setState({ loginmodal: true });
                        // }}
                        // onClick={loginDialog}
                      >
                        <MdModeEdit color="white" />
                      </button> */}
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Game Name
                      </Typography>
                    </Box>
                    <Box>
                      {this.state.Gamedetail.map((l, id) => {
                        return (
                          <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                            <Typography> {l.gameName}</Typography>
                          </p>
                        );
                      })}
                    </Box>
                  </Box>

                  {/* <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Password
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>********</Typography>
                    </Box>
                  </Box> */}
                </Box>
                <Box className={classes.loginInfoBox}>
                  <Box className={classes.singleBox}>
                    <Box>
                      {" "}
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Bank Information
                      </Typography>
                    </Box>

                    <Box>
                      <button
                        className={classes.profileEditButton}
                        onClick={() => {
                          this.setState({
                            bankmodal: true,
                            editbankname: this.state.bankDetail.bank_name,
                            editifsc: this.state.bankDetail.ifsc_code,
                            editaccnumber: this.state.bankDetail.account_number,
                          });
                        }}
                        // onClick={loginDialog}
                      >
                        <MdModeEdit color="white" />
                      </button>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Bank Name
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{this.state.bankDetail.bank_name}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        IFSC Code
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{this.state.bankDetail.ifsc_code}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Account Number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {this.state.bankDetail.account_number}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Divider /> */}
                  {/* <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Password
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>********</Typography>
                    </Box>
                  </Box> */}
                </Box>
                <Box className={classes.loginInfoBox}>
                  <Box className={classes.singleBox}>
                    <Box>
                      {" "}
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Login Information
                      </Typography>
                    </Box>

                    <Box>
                      <button
                        className={classes.profileEditButton}
                        onClick={() => {
                          this.setState({ loginmodal: true });
                        }}
                        // onClick={loginDialog}
                      >
                        <MdModeEdit color="white" />
                      </button>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Mobile Number
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{this.state.profiledata.mobile}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.profileDivider}>
                    <Box>
                      <Typography className={classes.profileLabel}>
                        Password
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>********</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container>
          {/* <Modal
            size="xl"
            show={this.state.personalmodal}
            onHide={() => this.setState({ personalmodal: false })}
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Edit Your Personal Information
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Academy Name
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.editacademyname}
                        onChange={(evt) =>
                          this.setState({
                            editacademyname: evt.target.value,
                          })
                        }
                        type="name"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="col-md-4">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Academy's Image
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.photo}
                        onChange={(e) => this.handleAcademyimg(e)}
                        type="file"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="col-md-2" style={{ paddingBottom: "5px" }}>
                    <img
                      style={{ height: "90px", width: "80px" }}
                      src={this.state.profiledata.photo}
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Owner's Name
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.editacademyowner}
                        onChange={(evt) =>
                          this.setState({
                            editacademyowner: evt.target.value,
                          })
                        }
                        type="name"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Address
                    </p>
                    <Form>
                      <Form.Control
                        value={this.state.editacademyaddress}
                        onChange={(evt) =>
                          this.setState({
                            editacademyaddress: evt.target.value,
                          })
                        }
                        type="text"
                        placeholder=""
                      />
                    </Form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Mobile Number
                    </p>
                    <Form>
                      <Form.Control
                        value={this.state.mobile}
                        onChange={(evt) =>
                          this.setState({ mobile: evt.target.value })
                        }
                        type="number"
                        placeholder=""
                      />
                    </Form>
                  </div>
        

                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Email Id
                    </p>
                    <Form>
                      <Form.Control
                        value={this.state.email}
                        onChange={(evt) =>
                          this.setState({ email: evt.target.value })
                        }
                        type="email"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="row"></div>
                  <div className="row" style={{ paddingTop: "20px" }}>
                    <Button
                      variant="primary"
                      onClick={() => this.openbankModal()}
                    >
                      Next Section
                    </Button>
                  </div>
                </div>
                {this.state.success ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "lightgreen",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Your Personal Informations Have Been Updated Successfully.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "orangered",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Something Went Wrong.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
          </Modal> */}
          <Modal
            size="lg"
            show={this.state.personalmodal}
            onHide={() => this.setState({ personalmodal: false })}
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
          >
            <DialogTitle>Edit Your Personal Information</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText> */}
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Academy Name
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.editacademyname}
                        onChange={(evt) =>
                          this.setState({
                            editacademyname: evt.target.value,
                          })
                        }
                        type="name"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="col-md-4">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Academy's Image
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.photo}
                        onChange={(e) => this.handleAcademyimg(e)}
                        type="file"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="col-md-2" style={{ paddingBottom: "5px" }}>
                    <img
                      style={{ height: "90px", width: "80px" }}
                      src={this.state.profiledata.photo}
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Owner's Name
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.editacademyowner}
                        onChange={(evt) =>
                          this.setState({
                            editacademyowner: evt.target.value,
                          })
                        }
                        type="name"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Address
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.editacademyaddress}
                        onChange={(evt) =>
                          this.setState({
                            editacademyaddress: evt.target.value,
                          })
                        }
                        type="text"
                        placeholder=""
                      />
                    </Form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Mobile Number
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        disabled
                        value={this.state.mobile}
                        onChange={(evt) =>
                          this.setState({ mobile: evt.target.value })
                        }
                        type="number"
                        placeholder=""
                      />
                    </Form>
                  </div>

                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Email Id
                    </p>
                    <Form>
                      <TextField
                        fullWidth
                        disabled
                        value={this.state.email}
                        onChange={(evt) =>
                          this.setState({ email: evt.target.value })
                        }
                        type="email"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  <div className="row"></div>
                  <div className="row" style={{ paddingTop: "20px" }}>
                    <Button
                      variant="primary"
                      onClick={() => this.openbankModal()}
                    >
                      Next Section
                    </Button>
                  </div>
                </div>
                {this.state.success ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "lightgreen",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Your Personal Informations Have Been Updated Successfully.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "orangered",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Something Went Wrong.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Modal>
          <Modal
            size="lg"
            show={this.state.otherinfo}
            onHide={() => this.setState({ otherinfo: false })}
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Edit Your Other Informations
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    About Academy
                  </p>
                  <Form>
                    <Form.Control
                      fullWidth
                      value={this.state.aboutacademy}
                      onChange={(evt) =>
                        this.setState({ aboutacademy: evt.target.value })
                      }
                      as="textarea"
                      rows={2}
                      placeholder=""
                    />
                  </Form>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Winnging Tournament
                  </p>
                  <Form>
                    <Form.Control
                      fullWidth
                      value={this.state.winning}
                      onChange={(evt) =>
                        this.setState({ winning: evt.target.value })
                      }
                      as="textarea"
                      rows={2}
                      placeholder=""
                    />
                  </Form>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Success Story
                      </p>
                      <Form>
                        <Form.Control
                          fullWidth
                          value={this.state.successstory}
                          onChange={(evt) =>
                            this.setState({
                              successstory: evt.target.value,
                            })
                          }
                          as="textarea"
                          rows={2}
                          placeholder=""
                        />
                      </Form>
                    </div>
                  </div>

                  <div className="row" style={{ paddingTop: "20px" }}>
                    <Button
                      variant="primary"
                      onClick={() =>
                        this.setState({
                          otherinfo: false,
                          loginmodal: true,
                        })
                      }
                    >
                      Next Section
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={this.state.bankmodal}
            onHide={() => this.setState({ bankmodal: false })}
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Edit Your Bank Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Bank Name
                  </p>
                  <Form>
                    <TextField
                      fullWidth
                      value={this.state.editbankname}
                      onChange={(evt) =>
                        this.setState({ editbankname: evt.target.value })
                      }
                      type="text"
                      placeholder=""
                    />
                  </Form>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Ifsc Code
                  </p>
                  <Form>
                    <TextField
                      fullWidth
                      value={this.state.editifsc}
                      onChange={(evt) =>
                        this.setState({ editifsc: evt.target.value })
                      }
                      type="text"
                      placeholder=""
                    />
                  </Form>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Account Number
                      </p>
                      <Form>
                        <TextField
                          fullWidth
                          value={this.state.editaccnumber}
                          onChange={(evt) =>
                            this.setState({
                              editaccnumber: evt.target.value,
                            })
                          }
                          type="number"
                          placeholder=""
                        />
                      </Form>
                    </div>
                  </div>

                  <div className="row" style={{ paddingTop: "20px" }}>
                    <Button
                      variant="primary"
                      onClick={() => this.bankEditinfo()}
                    >
                      Next Section
                    </Button>
                  </div>
                </div>
                {this.state.success ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "green",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Your Personal Informations Have Been Updated Successfully.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "red",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Something Went Wrong.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={this.state.loginmodal}
            onHide={() => this.setState({ loginmodal: false })}
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Edit Your Login Information
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Mobile Number
                  </p>
                  <Form>
                    <TextField
                      fullWidth
                      disabled
                      type="text"
                      placeholder=""
                      value={this.state.profiledata.mobile}
                    />
                  </Form>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Password
                    </p>

                    <Form>
                      <TextField
                        fullWidth
                        value={this.state.profiledata.text_password}
                        onChange={(evt) =>
                          this.setState({
                            password: evt.target.value,
                          })
                        }
                        type="password"
                        placeholder=""
                      />
                    </Form>
                  </div>
                  {/* <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Re-enter Password
                    </p>

                    <Form>
                      <Form.Control
                        value={this.state.repass}
                        onChange={(evt) =>
                          this.setState({
                            repass: evt.target.value,
                          })
                        }
                        type="password"
                        placeholder=""
                      />
                    </Form>
                  </div> */}
                </div>
                <div className="container">
                  <div className="row" style={{ paddingTop: "20px" }}>
                    <Button
                      variant="primary"
                      onClick={() => this.Editprofile()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                {this.state.success ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "green",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Your Personal Informations Have Been Updated Successfully.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "red",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      Something Went Wrong.
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Modal.Body>
          </Modal>
        </Container>
        {/* //+_______________________________++++++++++++++++++++++++++_____+++ */}
        <div className="container" style={{ paddingTop: "30px" }}>
          <div className="row">
            {/* <div className="col-md-4 offset-1">
              <div className="row">
                <img
                  style={{ height: "400px" }}
                  src={this.state.profiledata.photo}
                />
              </div>
              <div className="row" style={{ paddingTop: "15px" }}>
                <Link href="/userprofile">
                  <button
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "40px",
                      border: "none",

                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> DASHBOARD
                  </button>
                </Link>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <Link href="/classification">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> CLASSIFICATION
                  </button>
                </Link>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <Link href="/subscrip">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> SUBSCRIPTION
                  </button>
                </Link>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <Link href="/academy/videos">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> VIDEOS
                  </button>
                </Link>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <Link href="/gametournament">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> TOURNAMENTS
                  </button>
                </Link>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <Link href="/gamingreports">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> STUDENT REPORT
                  </button>
                </Link>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <Link href="/academynews">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> ACADEMY NEWS
                  </button>
                </Link>
              </div>

              <div className="row" style={{ paddingTop: "5px" }}>
                <Link href="/gameacademy/otherinformation">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> OTHER INFORMATION
                  </button>
                </Link>
              </div>
            </div> */}
            <div className="col-md-6 ">
              {/* <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "30px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>Personal Information</h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                      onClick={() => {
                        this.OpenModal();
                      }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Owner's Name
                  </p>

                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.profiledata.owner_name}
                  </p>
                </div>

                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Mobile Number
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.profiledata.mobile}
                  </p>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Email Address
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.profiledata.email}
                  </p>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>Address</p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.profiledata.address}
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Geo Location<br></br>
                    </p>
                    <p style={{ fontSize: "smaller" }}>
                      {this.state.profiledata.address}
                    </p>
                  </div>
                  <div className="col-md-8"></div>
                </div>
              </div> */}
              {/* <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "40px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>
                      Classification of Game
                    </h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Game Name
                  </p>
                  {this.state.Gamedetail.map((l, id) => {
                    return (
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {l.gameName}
                      </p>
                    );
                  })}
                </div>
              </div> */}
              {/* <div></div> */}
              {/* <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "50px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>Bank Information</h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                      onClick={() => {
                        this.setState({
                          bankmodal: true,
                          editbankname: this.state.bankDetail.bank_name,
                          editifsc: this.state.bankDetail.ifsc_code,
                          editaccnumber: this.state.bankDetail.account_number,
                        });
                      }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Bank Name
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.bankDetail.bank_name}
                  </p>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    IFSC Code
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.bankDetail.ifsc_code}
                  </p>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Account Number
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.bankDetail.account_number}
                  </p>
                </div>
              </div> */}
              {/* <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "60px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>Login Information</h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                      onClick={() => {
                        this.setState({ loginmodal: true });
                      }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    User Name
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    {this.state.profiledata.mobile}
                  </p>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Password
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    *************
                  </p>
                </div>
              </div> */}
              {/* <div className="col" style={{ paddingTop: "15vh" }}>
                <button className="btn btn-primary">UPDATE</button>
              </div> */}
              <div style={{ height: "100px" }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const styles = (theme) => ({
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.4",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },

  personalInfoBox: {
    border: "1px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  profileEditButton: {
    backgroundColor: "lightgreen",
    border: "none",
    fontSize: "24px",
    borderRadius: "20px",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
});
export default withStyles(styles)(GameProfile);
