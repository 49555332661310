import React, { Component } from "react";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle, AiFillGoogleCircle } from "react-icons/ai";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { ImPhone } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
// import {
//   Link,
//   } from '@mui/material'

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container my-footer">
          <br></br>
          <img src={require("../assets/img/logo.png").default} alt="" />

          <div className="row my-footer-row">
            <div className="col-lg-4 ">
              <h6>ABOUT SER-MAPS</h6>
              <p>
                Lorem Ipsum again its nothing but just a simple text , which
                takes place intead of real data . It means its again proved that
                its dummy.
              </p>
              <p>
                <ImPhone size="18px" color="white" />
                123 125 4242
              </p>
              <p>
                <MdEmail size="18px" color="white" />
                contact@sermaps.com
              </p>
              <p>
                <FaMapMarkedAlt size="18px" color="white" /> 504A, PS AVIATOR,
                Chinar Park, Rajarhat
              </p>
            </div>
            <div className="col-lg-3 offset-1">
              <h6>Useful LInks</h6>
              <p>Popular Classes</p>
              <p>Popular Videos</p>
              <p>Latest Courses</p>
              <p>Notification</p>
            </div>
            <div className="col-lg-3 offset-1">
              <h6>Latest Videos</h6>
              <p>video</p>
              <p>video</p>
            </div>
          </div>
          <div
            className="row "
            style={{ borderTop: "2px solid white", padding: "20px" }}
          >
            <div className="col-md-9 ">
              <h6>© Copyright 2021 , sermaps.com All Rights Reserved</h6>
            </div>
            <div className="col-md-3 myfooter-ul">
              <ul style={{ display: "flex", flex: "1 1 auto" }}>
                <li>
                  <FaFacebook size="25px" color="white" />
                </li>
                <li>
                  <AiFillTwitterCircle size="25px" color="white" />
                </li>
                <li>
                  <AiFillGoogleCircle size="25px" color="white" />
                </li>
                <li>
                  <a href="linkedin.com">
                    <TiSocialLinkedinCircular size="25px" color="white" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
