import React, { Component } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import API from "./api";
import { Form, Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  MenuItem,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
  TextareaAutosize,
  DialogContentText,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from "../provider/useWindowSize";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListIcon from "@mui/icons-material/List";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallIcon from "@mui/icons-material/Call";
import RemoveIcon from "@mui/icons-material/Remove";
import ProgressReport from "../Student/ProgressReport";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

class ObserverSchool extends Component {
  state = {
    time: true,
    error: false,
    newsaddsuccess: "",
    principalError: "",
    schoolresult: false,
    Studentreport: "",
    reportmsg: "",
    item: "",
    timetable: {
      mondaystarttime: "",
      mondayendtime: "",
      tuesdayendtime: "",
      tuesdaystarttime: "",
      wednesdayendtime: "",
      wednesdaystarttime: "",
      thrusdaystarttime: "",
      thrusdayendtime: "",
      fridayendtime: "",
      fridaystarttime: "",
      saturdayendtime: "",
      saturdaystarttime: "",
      sundayendtime: "",
      sundaystarttime: "",
    },
    mondaystarttime: "",
    mondayendtime: "",
    tuesdayendtime: "",
    tuesdaystarttime: "",
    wednesdayendtime: "",
    wednesdaystarttime: "",
    thrusdaystarttime: "",
    thrusdayendtime: "",
    fridayendtime: "",
    fridaystarttime: "",
    saturdayendtime: "",
    saturdaystarttime: "",
    sundayendtime: "",
    sundaystarttime: "",
    holiday: false,
    news: false,
    result: false,
    principle: false,
    page: 1,
    timetabledata: [],
    updatetimetable: false,
    success: false,
    successmessage: "",
    loading: false,
    Image: "",
    // holiday
    holidaydata: [],
    addholiday: false,
    holidayaddsuccessmessage: "",
    holidaystart: "",
    holidayname: "",
    holidayend: "",
    holidayeditname: "",
    holidayeditstartdate: "",
    holidayeditenddate: "",
    holidayeditmodal: false,
    editholidayid: "",
    successholidayeditmessage: "",
    // news
    Newsdata: [],
    newsdltmodal: false,
    newsdltid: "",
    newsdltsuccess: "",
    addnewssection: false,
    newstitle: "",
    newsdate: "",
    newsdescription: "",
    newsaddsuccess: "",
    // principal
    principaldata: "",
    openprincipalupdatesection: false,
    principaldescription: "",
    principalmsg: "",
    classlist: [],
    classselected: "",
    StudentData: [],
    result: "",
    gradechosen: "",
    schldesc: "",
    reportbutton: true,
    stuID: "",
  };
  componentDidMount() {
    this.getTimetable();
    this.getholiday();
    this.getnews();
    this.getprincipalmessage();
    this.getclassList();
    // var a = [1,3,67,4,8,3,4,1,6,2,8,9,55,67]

    // var sorted_arr=[]

    // var i,j;
    // for(i=0;i<a.length;i++){
    //   var count=0
    //   var current=a[i]
    //   for(j=0;j<a.length;j++){
    //     if(i !== j){
    //       if((current === a[j])){
    //        count++
    //       }
    //     }

    //   }
    //   if(count === 1){
    //     sorted_arr[i]=a[i]
    //   }
    // }
    // console.log("sdfsfsfsfsfsdfsdf",a)
  }
  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", {
      school_id: localStorage.getItem("school_id"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  };
  studentgotclicked(m) {
    this.state.stuID = m.student_id;
    this.setState({ item: m, schoolresult: true });
    this.schoolreportcard(m);
  }
  schoolreportcard(m) {
    const params = {
      school_id: localStorage.getItem("school_id"),
      student_id: m.student_id,
      action: "view",
    };
    var that = this;
    API.post("SchoolApi/schoolReportCard", params)
      .then(function (response) {
        console.log("result", response.data.data);
        that.setState({ Studentreport: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  saveschoolReport() {
    this.setState({ loading: true });
    // setTimeout(() => {
    //   this.setState({ loading: false });
    // }, 1500);
    const params = {
      school_id: localStorage.getItem("school_id"),
      student_id: this.state.stuID,
      grade: this.state.gradechosen || this.state.Studentreport.grade,
      description: this.state.schldesc || this.state.Studentreport.description,
      action: "update",
    };
    var that = this;
    API.post("SchoolApi/schoolReportCard", params)
      .then(function (response) {
        console.log("result", response.data.data);
        that.setState({
          Studentreport: response.data.data,
          reportmsg: response.data.message,
        });

        if (response.data.result === 1) {
          that.setState({ reportbutton: false, success: true, loading: false });
          setTimeout(() => {
            that.setState({
              reportbutton: true,
              success: false,
              schoolresult: false,
            });
          }, 3000);
        } else {
          that.setState({ reportbutton: false, loading: false, error: true });
          setTimeout(() => {
            that.setState({ reportbutton: true, error: false });
          }, 3000);
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  valid() {
    if (this.state.principaldescription.length < 0) {
      this.setState({ principalError: "This is a mandatory field" });
      return false;
    } else {
      return true;
    }
  }
  AddprincipalMessage() {
    if (this.valid()) {
      this.setState({ loading: true });

      // const params = {
      //   school_id: localStorage.getItem("school_id"),
      //   action: "add",
      //   description: this.state.principaldescription,
      // };
      var formdata = new FormData();

      formdata.append("school_id", localStorage.getItem("school_id"));
      formdata.append("action", "add");
      formdata.append("description", this.state.principaldescription);
      formdata.append("photo", this.state.Image);
      var that = this;
      API.post("SchoolApi/principalMessage", formdata)
        .then(function (response) {
          console.log(response);
          that.setState({ principalmsg: response.data.message });
          if (response.data.result === 1) {
            that.setState({ success: true });
            setTimeout(() => {
              that.setState({ loading: false });
              that.setState({
                success: false,
                openprincipalupdatesection: false,
              });
            }, 2000);
          }
          that.getprincipalmessage();
        })

        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          if (that.state.res === 1) {
          } else {
          }
        });
    } else {
    }
  }
  getprincipalmessage() {
    // const params = {
    //   school_id: localStorage.getItem("school_id"),
    //   action: "view",
    // };
    var formdata = new FormData();

    formdata.append("school_id", localStorage.getItem("school_id"));
    formdata.append("action", "view");
    formdata.append("description", this.state.principaldescription);
    formdata.append("photo", this.state.Image);
    var that = this;
    API.post("SchoolApi/principalMessage", formdata)
      .then(function (response) {
        console.log(response);
        that.setState({ principaldata: response.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  handlePicChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  classChange = (e) => {
    this.state.classselected = e.target.value;

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    this.getStudentList(e);
  };
  getStudentList() {
    const credentials = {
      school_id: localStorage.getItem("school_id"),
      class_id: this.state.classselected,
    };
    var that = this;
    API.post("SchoolApi/studentList", credentials)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          StudentData: response.data.data,
          result: response.data.result,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  AddNews() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "add",
      title: this.state.newstitle,
      date: this.state.newsdate,
      description: this.state.newsdescription,
    };
    var that = this;
    API.post("SchoolApi/news", params)
      .then(function (response) {
        console.log(response);
        that.setState({ newsaddsuccess: response.data.message });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, addnewssection: false });
          }, 2000);
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
        that.getnews();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        that.setState({ newsdescription: "", newstitle: "", newsdate: "" });
      });
  }
  DeleteNews() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      id: this.state.newsdltid,
      school_id: localStorage.getItem("school_id"),
      action: "delete",
    };
    var that = this;
    API.post("SchoolApi/news", params)
      .then(function (response) {
        console.log(response);
        that.setState({ newsdltsuccess: response.data.message });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, newsdltmodal: false });
          }, 2000);
        } else {
        }
        that.getnews();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  opennewsdeletemodal(l) {
    console.log("id", l.id);
    this.setState({ newsdltid: l.id, newsdltmodal: true });
  }
  getnews() {
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    var that = this;
    API.post("SchoolApi/news", params)
      .then(function (response) {
        console.log(response);
        that.setState({ Newsdata: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  openholidaymodal(l) {
    console.log("id", l);
    let d = l?.date;
    let d1 = d?.split(" ");
    let d2 = d1[0]?.split("-");
    let d3 = d1[2]?.split("-");
    console.log("id1233456", d1);
    this.setState({
      editholidayid: l.id,
      holidayeditmodal: true,
      holidayeditname: l.name,
      holidayeditstartdate: d2[2] + "-" + d2[1] + "-" + d2[0],
      holidayeditenddate: d3[2] + "-" + d3[1] + "-" + d3[0],
    });
  }
  UpdateHoliday() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      id: this.state.editholidayid,
      school_id: localStorage.getItem("school_id"),
      name: this.state.holidayeditname,
      start_date: this.state.holidayeditstartdate,
      end_date: this.state.holidayeditenddate,
      action: "update",
    };
    var that = this;
    API.post("SchoolApi/holidays", params)
      .then(function (response) {
        console.log(response);
        that.setState({ successholidayeditmessage: response.data.message });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, holidayeditmodal: false });
          }, 2500);
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
        that.getholiday();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getholiday() {
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    var that = this;
    API.post("SchoolApi/holidays", params)
      .then(function (response) {
        console.log(response.data.data.time_table);
        that.setState({ holidaydata: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  updatewindow() {
    this.setState({
      page: 1,
      time: true,
      holiday: false,
      news: false,
      result: false,
      principle: false,
      updatetimetable: true,
      timetable: this.state.timetabledata,
    });
  }
  getTimetable() {
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "view",
    };
    var that = this;
    API.post("SchoolApi/time_tables", params)
      .then(function (response) {
        console.log(response.data.data.time_table);
        that.setState({
          timetabledata: response.data.data.time_table,
          mondaystarttime: response.data.data.time_table[0].start_time,
          mondayendtime: response.data.data.time_table[0].end_time,
          tuesdaystarttime: response.data.data.time_table[1].start_time,
          tuesdayendtime: response.data.data.time_table[1].end_time,
          wednesdaystarttime: response.data.data.time_table[2].start_time,
          wednesdayendtime: response.data.data.time_table[2].end_time,
          thrusdaystarttime: response.data.data.time_table[3].start_time,
          thrusdayendtime: response.data.data.time_table[3].end_time,
          fridaystarttime: response.data.data.time_table[4].start_time,
          fridayendtime: response.data.data.time_table[4].end_time,
          saturdaystarttime: response.data.data.time_table[5].start_time,
          saturdayendtime: response.data.data.time_table[5].end_time,
          sundaystarttime: response.data.data.time_table[6].start_time,
          sundayendtime: response.data.data.time_table[6].end_time,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  AddHolidayfunc() {
    this.setState({ loading: true });
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "add",
      name: this.state.holidayname,
      start_date: this.state.holidaystart,
      end_date: this.state.holidayend,
    };
    var that = this;
    API.post("SchoolApi/holidays", params)
      .then(function (response) {
        console.log(response);
        that.setState({ holidayaddsuccessmessage: response.data.message });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, addholiday: false });
            that.setState({ loading: false });
          }, 2500);
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false, loading: false });
            // that.setState({  });
          }, 2500);
        }
        that.getholiday();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  updateTimetablefunc() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      school_id: localStorage.getItem("school_id"),
      action: "update",
      time_table: [
        {
          day: "Monday",
          start_time: this.state.mondaystarttime,
          end_time: this.state.mondayendtime,
        },
        {
          day: "Tuesday",
          start_time: this.state.tuesdaystarttime,
          end_time: this.state.tuesdayendtime,
        },
        {
          day: "Wednesday",
          start_time: this.state.wednesdaystarttime,
          end_time: this.state.wednesdayendtime,
        },
        {
          day: "Thrusday",
          start_time: this.state.thrusdaystarttime,
          end_time: this.state.thrusdayendtime,
        },
        {
          day: "Friday",
          start_time: this.state.fridaystarttime,
          end_time: this.state.fridayendtime,
        },
        {
          day: "Saturday",
          start_time: this.state.saturdaystarttime,
          end_time: this.state.saturdayendtime,
        },
        {
          day: "Sunday",
          start_time: this.state.sundaystarttime,
          end_time: this.state.sundayendtime,
        },
      ],
    };
    var that = this;
    API.post("SchoolApi/time_tables", params)
      .then(function (response) {
        console.log(response);
        that.setState({ successmessage: response.data.message });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, updatetimetable: false });
          }, 2500);
        } else {
        }
        that.getTimetable();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>SCHOOL</h1>
          <p>
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home{" "}
            </Link>
            |
            <Link
              href="/observer_profile"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              School Observer{" "}
            </Link>{" "}
            | School
          </p>
        </div>
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: true,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: false,
                      updatetimetable: false,
                      addnewssection: false,
                      page: 1,
                    });
                  }}
                  className={
                    this.state.time ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>TIME TABLE</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: true,
                      news: false,
                      result: false,
                      principle: false,
                      updatetimetable: false,
                      addnewssection: false,
                      page: 2,
                    });
                  }}
                  className={
                    this.state.holiday ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>HOLIDAY LISTS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: true,
                      result: false,
                      principle: false,
                      updatetimetable: false,
                      addnewssection: false,
                      addholiday: false,
                      page: 3,
                    });
                  }}
                  className={
                    this.state.news ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>NEWS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: true,
                      principle: false,
                      updatetimetable: false,
                      addnewssection: false,
                      page: 4,
                    });
                  }}
                  className={
                    this.state.result ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>SCHOOL RESULT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: true,
                      updatetimetable: false,
                      addnewssection: false,
                      page: 5,
                    });
                  }}
                  className={
                    this.state.principle ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>PRINCIPAL MESSAGE</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              {this.state.page === 1 && !this.state.updatetimetable && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>TIME TABLE</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "deepskyblue" }}
                              onClick={() => this.updatewindow()}
                            >
                              UPDATE YOUR TIMETABLE
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ backgroundColor: "lightgray" }}
                              >
                                Day
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Start Time
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                End Time
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.timetabledata.map((rep) => (
                              <TableRow hover>
                                <TableCell>{rep?.day}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {rep?.start_time}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {rep?.end_time}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}

              {this.state.page === 2 && this.state.addholiday === false && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>HOLIDAY LIST</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "deepskyblue" }}
                              onClick={() => {
                                this.setState({ addholiday: true });
                              }}
                            >
                              ADD HOLIDAY
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ backgroundColor: "lightgray" }}
                              >
                                Holiday
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Date
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.holidaydata.map((rep, i) => {
                              return (
                                <TableRow hover>
                                  <TableCell>{rep?.name}</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {rep?.date}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <button
                                      onClick={() => {
                                        this.openholidaymodal(rep);
                                      }}
                                      className={
                                        classes.tuitionTimeStatusButton
                                      }
                                    >
                                      <EditIcon style={{ color: "white" }} />
                                    </button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Container>

                  <Dialog
                    open={this.state.holidayeditmodal}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle>Edit Holiday</DialogTitle>
                    <DialogContent>
                      <Container>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p style={{ opacity: "0.6" }}>Name of Holiday</p>
                            <FormControl
                              variant="filled"
                              style={{ width: "100%" }}
                            >
                              <TextField
                                name="name"
                                variant="outlined"
                                type="text"
                                value={this.state.holidayeditname}
                                onChange={(evt) =>
                                  this.setState({
                                    holidayeditname: evt.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <p style={{ opacity: "0.6" }}>Start Date</p>
                            <FormControl
                              variant="filled"
                              style={{ width: "100%" }}
                            >
                              <TextField
                                name="start"
                                variant="outlined"
                                type="date"
                                value={this.state.holidayeditstartdate}
                                onChange={(evt) =>
                                  this.setState({
                                    holidayeditstartdate: evt.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <p style={{ opacity: "0.6" }}>End Date</p>
                            <FormControl
                              variant="filled"
                              style={{ width: "100%" }}
                            >
                              <TextField
                                name="end"
                                variant="outlined"
                                type="date"
                                value={this.state.holidayeditenddate}
                                onChange={(evt) =>
                                  this.setState({
                                    holidayeditenddate: evt.target.value,
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Container>
                    </DialogContent>
                    <DialogActions>
                      <Box display="flex">
                        <Box>
                          {this.state.loading ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "deepskyblue" }}
                              onClick={() => {
                                this.UpdateHoliday();
                              }}
                            >
                              Update
                            </Button>
                          )}
                        </Box>
                        <Box>
                          <Button
                            variant="text"
                            onClick={() =>
                              this.setState({ holidayeditmodal: false })
                            }
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </DialogActions>
                  </Dialog>
                </>
              )}
              {this.state.addholiday && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                          <button
                            onClick={() => {
                              this.setState({ addholiday: false });
                            }}
                            className={classes.tuitionTimeStatusButton}
                          >
                            <ArrowBackIcon style={{ color: "white" }} />
                          </button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ opacity: "0.6" }}>Name of Holiday</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            value={this.state.holidayname}
                            onChange={(evt) =>
                              this.setState({
                                holidayname: evt.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Start Date</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="start"
                            variant="outlined"
                            type="date"
                            value={this.state.holidaystart}
                            onChange={(evt) =>
                              this.setState({
                                holidaystart: evt.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>End Date</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="end"
                            variant="outlined"
                            type="date"
                            value={this.state.holidayend}
                            onChange={(evt) =>
                              this.setState({
                                holidayend: evt.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <>
                          {this.state.success ? (
                            <Alert severity="success">
                              {this.state.holidayaddsuccessmessage}
                            </Alert>
                          ) : (
                            ""
                          )}
                          {this.state.error ? (
                            <Alert severity="error">
                              {this.state.holidayaddsuccessmessage}
                            </Alert>
                          ) : (
                            ""
                          )}
                          {this.state.loading ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "deepskyblue" }}
                              onClick={() => {
                                this.AddHolidayfunc();
                              }}
                            >
                              Add
                            </Button>
                          )}
                        </>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}

              {this.state.page === 3 && this.state.addnewssection === false && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>NEWS</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "deepskyblue" }}
                              onClick={() => {
                                this.setState({ addnewssection: true });
                              }}
                            >
                              Add News
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          style={{ width: "100%" }}
                          marginY={2}
                        >
                          {this.state.Newsdata === undefined ? (
                            <Box>No News Added</Box>
                          ) : (
                            <>
                              {this.state.Newsdata.map((news, index) => (
                                <>
                                  <Box display="flex">
                                    <Box style={{ width: "90%" }}>
                                      <Box>
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {news?.date}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {news?.title}
                                        </Typography>
                                        <Typography>
                                          {" "}
                                          {news?.description}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      style={{ width: "10%" }}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <Box alignSelf="center">
                                        <button
                                          onClick={() => {
                                            this.opennewsdeletemodal(news);
                                          }}
                                          className={
                                            classes.tuitionTimeStatusButton
                                          }
                                        >
                                          <DeleteIcon
                                            style={{ color: "white" }}
                                          />
                                        </button>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {index !==
                                    Number(this.state.Newsdata.length) - 1 && (
                                    <Divider
                                      style={{
                                        marginBottom: "8px",
                                        marginTop: "8px",
                                      }}
                                    />
                                  )}
                                </>
                              ))}
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>

                  <Dialog
                    open={this.state.newsdltmodal}
                    maxWidth="xs"
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      Are you sure? You want to delete this news!
                      <DialogActions>
                        <Box display="flex" flexDirection="column">
                          <Box>
                            {this.state.success && (
                              <Alert severity="success">
                                {this.state.newsdltsuccess}
                              </Alert>
                            )}
                            {this.state.error && (
                              <Alert severity="error">
                                {this.state.newsdltsuccess}
                              </Alert>
                            )}
                          </Box>
                          <Box display="flex">
                            <Box>
                              <Button
                                variant="text"
                                onClick={() =>
                                  this.setState({ newsdltmodal: false })
                                }
                              >
                                Cancel
                              </Button>
                            </Box>
                            <Box>
                              {this.state.loading ? (
                                <CircularProgress />
                              ) : (
                                <Button
                                  autoFocus
                                  variant="contained"
                                  style={{ backgroundColor: "red" }}
                                  onClick={() => {
                                    this.DeleteNews();
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </>
              )}
              {this.state.page === 3 && this.state.addnewssection && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                          <button
                            onClick={() => {
                              this.setState({ addnewssection: false });
                            }}
                            className={classes.tuitionTimeStatusButton}
                          >
                            <ArrowBackIcon style={{ color: "white" }} />
                          </button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>News Title</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="title"
                            variant="outlined"
                            type="text"
                            value={this.state.newstitle}
                            onChange={(evt) =>
                              this.setState({
                                newstitle: evt.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>News Date</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="date"
                            variant="outlined"
                            type="date"
                            value={this.state.newsdate}
                            onChange={(evt) =>
                              this.setState({
                                newsdate: evt.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ opacity: "0.6" }}>News Description</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextareaAutosize
                            name="description"
                            placeholder="Write Description"
                            value={this.state.newsdescription}
                            onChange={(evt) =>
                              this.setState({
                                newsdescription: evt.target.value,
                              })
                            }
                            minRows={4}
                            maxRows={4}
                            maxLength={500}
                            style={{ width: "100%" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display="flex" flexDirection="column">
                          <Box>
                            {this.state.success && (
                              <Alert severity="success">
                                {this.state.newsaddsuccess}
                              </Alert>
                            )}
                            {this.state.error && (
                              <Alert severity="error">
                                {this.state.newsaddsuccess}
                              </Alert>
                            )}
                          </Box>
                        </Box>
                        {this.state.loading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "deepskyblue" }}
                            onClick={() => {
                              this.AddNews();
                            }}
                          >
                            Add
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}

              {this.state.page === 4 ? (
                <div>
                  {!this.state.schoolresult ? (
                    <Container>
                      <h5>SCHOOL RESULT</h5>
                      <div
                        style={{
                          height: "3px",
                          width: "80px",
                          textDecoration: "underline",
                          backgroundColor: "lightblue",
                        }}
                      ></div>
                      <br></br>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <FormControl variant="filled" fullWidth>
                          <TextField
                            select
                            label="Choose Class"
                            name="selectGame"
                            input={<OutlinedInput />}
                            onChange={this.classChange}
                            value={this.state.classselected}
                          >
                            {/* <MenuItem>choose class</MenuItem> */}
                            {this.state.classlist.map((l, i) => {
                              return (
                                <MenuItem key={i} value={l.class_id}>
                                  {l.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={12}>
                        <Box>
                          {this.state.StudentData === undefined ? (
                            <Box>No students found</Box>
                          ) : (
                            <Box>
                              {this.state.StudentData.map((m, student_id) => {
                                return (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    style={{
                                      width: "100%",
                                      border: "1px solid lightgrey",
                                      paddingTop: "2vh",
                                      paddingBottom: "2vh",
                                      marginTop: "1vh",
                                      paddingLeft: "2vh",
                                    }}
                                    paddingY={1}
                                    onClick={() => {
                                      this.studentgotclicked(m);
                                    }}
                                  >
                                    <Box>
                                      <Typography style={{ color: "black" }}>
                                        {m.name}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <ArrowForwardIcon
                                        style={{ color: "black" }}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Container>
                  ) : (
                    ""
                  )}
                  {this.state.schoolresult ? (
                    <Box>
                      <Button
                        style={{
                          border: "1px solid black",

                          height: "5vh",
                        }}
                        onClick={() => {
                          this.setState({ schoolresult: false });
                        }}
                      >
                        {" "}
                        <HiOutlineArrowNarrowLeft size="3vh" />
                      </Button>

                      <h5>SCHOOL RESULT</h5>
                      <div
                        style={{
                          height: "3px",
                          width: "80px",
                          textDecoration: "underline",
                          backgroundColor: "lightblue",
                        }}
                      ></div>
                      <br></br>
                      <Box display="flex" flexDirection="column">
                        <Grid item xs={12} sm={6} md={8} lg={8}>
                          <Box style={{}}>
                            <Typography
                              style={{ opacity: "0.8", paddingBottom: "1vh" }}
                            >
                              Choose Grade
                            </Typography>
                            {/* <FormControl variant="filled" fullWidth> */}
                            <Form.Select
                              // style={{ height: "5px" }}
                              aria-label=""
                              onChange={(e) => {
                                this.setState({
                                  gradechosen: e.target.value,
                                });
                              }}
                              // defaultValue={this.state.Studentreport.grade}
                            >
                              <option>{this.state.Studentreport?.grade}</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                            </Form.Select>
                            {/* <TextField
                                select
                                name="selectGame"
                                onChange={(e) => {
                                  this.setState({
                                    gradechosen: e.target.value,
                                  });
                                }}
                                defaultValue={this.state.Studentreport.grade}
                              >
                                <MenuItem selected value="A">
                                  A
                                </MenuItem>
                                <MenuItem value="B">B</MenuItem>
                                <MenuItem value="C">C</MenuItem>
                                <MenuItem value="D">D</MenuItem>
                              </TextField> */}
                            {/* </FormControl> */}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={8}>
                          <Box
                            style={{
                              paddingTop: "2vh",
                              marginTop: "1vh",
                            }}
                          >
                            <Typography
                              style={{ opacity: "0.8", paddingBottom: "1vh" }}
                            >
                              Description
                            </Typography>

                            <FormControl variant="filled" fullWidth>
                              <TextField
                                type="textarea"
                                multiline
                                rows={3}
                                variant="outlined"
                                defaultValue={
                                  this.state.Studentreport?.description
                                }
                                onChange={(evt) => {
                                  this.setState({
                                    schldesc: evt.target.value,
                                  });
                                }}
                                fullWidth
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Box>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display="flex" flexDirection="column">
                          <Box>
                            {this.state.error && (
                              <Alert severity="error">
                                {this.state.reportmsg}
                              </Alert>
                            )}
                            {this.state.success && (
                              <Alert severity="success">
                                {this.state.reportmsg}
                              </Alert>
                            )}
                          </Box>
                          <Box>
                            {this.state.loading ? (
                              <CircularProgress />
                            ) : (
                              <Box style={{ marginTop: "1vh" }}>
                                {this.state.reportbutton ? (
                                  <Button
                                    variant="contained"
                                    style={{ backgroundColor: "deepskyblue" }}
                                    onClick={() => {
                                      this.saveschoolReport();
                                    }}
                                  >
                                    Submit
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      {/* <Box style={{ marginTop: "1vh", marginBottom: "1vh" }}>
                        <Button
                          variant="default"
                          onClick={() => {
                            this.saveschoolReport();
                          }}
                          style={{
                            backgroundColor: "deepskyblue",
                            color: "white",
                          }}
                        >
                          {" "}
                          Save
                        </Button>
                      </Box> */}
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* <div>
                    <p1 style={{ paddingTop: "15px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p1>
                  </div>
                  <div>
                    <p2 style={{ paddingTop: "10px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p2>
                  </div>
                  <div>
                    <p3 style={{ paddingTop: "10px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p3>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {this.state.page === 5 &&
                this.state.openprincipalupdatesection === false && (
                  <>
                    <Container>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Typography>PRINCIPLE MESSAGE</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box display="flex" justifyContent="flex-end">
                            <Box>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: "deepskyblue" }}
                                onClick={() => {
                                  this.setState({
                                    openprincipalupdatesection: true,
                                    principaldescription:
                                      this.state.principaldata.description,
                                  });
                                }}
                              >
                                Update
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <div
                            style={{
                              backgroundColor: "rgb(24, 169, 218)",
                              height: "2px",
                              width: "32%",
                              marginBottom: "12px",
                            }}
                          ></div>
                        </Grid>
                      </Grid>
                    </Container>
                    <Container>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box
                            display="flex"
                            style={{
                              border: "1px solid lightgrey",
                              borderRadius: "5px",
                            }}
                          >
                            <Box style={{ width: "200px", height: "200px" }}>
                              <img
                                src={this.state.principaldata.photo}
                                alt=""
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignSelf="center"
                              paddingX={2}
                            >
                              <Box>
                                <Typography variant="h6">
                                  {this.state.principaldata.message}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {this.state.principaldata.description}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Container>
                  </>
                )}

              {this.state.page === 5 && this.state.openprincipalupdatesection && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                          <button
                            onClick={() => {
                              this.setState({
                                openprincipalupdatesection: false,
                              });
                            }}
                            className={classes.tuitionTimeStatusButton}
                          >
                            <ArrowBackIcon style={{ color: "white" }} />
                          </button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography>UPDATE PRINCIPAL MESSAGE</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ opacity: "0.6" }}>Update Image</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="image"
                            variant="outlined"
                            type="file"
                            onChange={(e) => {
                              this.handlePicChange(e);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ opacity: "0.6" }}>Update Message</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextareaAutosize
                            name="message"
                            style={{ width: "100%" }}
                            minRows={4}
                            maxRows={4}
                            maxLength={200}
                            value={this.state.principaldescription}
                            onChange={(evt) =>
                              this.setState({
                                principaldescription: evt.target.value,
                              })
                            }
                          />
                          {
                            <span className="error">
                              {this.state.principalError}
                            </span>
                          }
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <>
                          {this.state.success && (
                            <Alert severity="success">
                              {this.state.principalmsg}
                            </Alert>
                          )}
                          {this.state.loading ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "deepskyblue" }}
                              onClick={() => {
                                this.AddprincipalMessage();
                              }}
                            >
                              Save
                            </Button>
                          )}
                        </>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}

              {/* {this.state.page === 5 ? (
                <div>
                  {this.state.openprincipalupdatesection ? (
                    <div style={{ minHeight: "80vh" }}>
                      <div className="col-md-1">
                        <Button
                          style={{ borderRadius: "50%" }}
                          onClick={() => {
                            this.setState({
                              openprincipalupdatesection: false,
                            });
                          }}
                        >
                          <HiOutlineArrowNarrowLeft size="15px" />
                        </Button>
                      </div>
                      <h5>ADD PRINCIPAL MESSAGE</h5>
                      <div
                        style={{
                          height: "3px",
                          width: "80px",
                          textDecoration: "underline",
                          backgroundColor: "lightblue",
                        }}
                      ></div>
                      <h6>Update Image</h6>
                      <Form>
                        <Form.Control
                          onChange={(e) => {
                            this.handlePicChange(e);
                          }}
                          type="file"
                          placeholder=""
                        />
                      </Form>
                      <h6>Update Message</h6>
                      <Form>
                        <Form.Control
                          value={this.state.principaldescription}
                          onChange={(evt) =>
                            this.setState({
                              principaldescription: evt.target.value,
                            })
                          }
                          as="textarea"
                          rows={4}
                          placeholder=""
                        />
                      </Form>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.AddprincipalMessage();
                        }}
                        class="btn btn-primary btn-lg"
                        style={{
                          position: "relative",
                          top: "20px",
                          height: "43px",
                          width: "132px",
                          backgroundColor: "deepskyblue"
                        }}
                      >
                        {this.state.loading ? (
                          <span>
                            {" "}
                            <ClipLoader color="white" size={20} />
                            Please Wait{" "}
                          </span>
                        ) : (
                          <span>Save</span>
                        )}
                      </Button>
                      {this.state.success ? (
                        <div
                          style={{
                            backgroundColor: "lightgreen",
                            color: "white",
                            height: "auto",
                            marginTop: "5vh",
                          }}
                        >
                          <h6 style={{ paddingLeft: "10vh" }}>
                            {this.state.principalmsg}
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          {" "}
                          <h5>PRINCIPAL MESSAGE</h5>
                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "deepskyblue" }}
                            onClick={() => {
                              this.setState({
                                openprincipalupdatesection: true,
                                principaldescription:
                                  this.state.principaldata.description,
                              });
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                      <div
                        className="container-fluid"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="row">
                          <div className="col-12 mt-3">
                            <div className="card">
                              <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                  <img src={this.state.principaldata.photo} />
                                </div>
                                <div className="card-body">
                                  <p1 class="card-text">
                                    {this.state.principaldata.description}
                                  </p1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )} */}
              {this.state.updatetimetable && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                          <button
                            onClick={() => {
                              this.setState({ updatetimetable: false });
                            }}
                            className={classes.tuitionTimeStatusButton}
                          >
                            <ArrowBackIcon style={{ color: "white" }} />
                          </button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography>Update Timetable</Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display="flex" flexDirection="column">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box style={{ width: "30%" }}>
                              <Typography style={{ fontWeight: "bold" }}>
                                Day
                              </Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                Start Time
                              </Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                End Time
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box
                              className={classes.timeTableLabel}
                              paddingY={2}
                              paddingX={2}
                            >
                              <Typography>Monday</Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.mondaystarttime}
                                onChange={(evt) =>
                                  this.setState({
                                    mondaystarttime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.mondayendtime}
                                onChange={(evt) =>
                                  this.setState({
                                    mondayendtime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box
                              className={classes.timeTableLabel}
                              paddingY={2}
                              paddingX={2}
                            >
                              <Typography>Tuesday</Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.tuesdaystarttime}
                                onChange={(evt) =>
                                  this.setState({
                                    tuesdaystarttime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.tuesdayendtime}
                                onChange={(evt) =>
                                  this.setState({
                                    tuesdayendtime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box
                              className={classes.timeTableLabel}
                              paddingY={2}
                              paddingX={2}
                            >
                              <Typography>Wednesday</Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.wednesdaystarttime}
                                onChange={(evt) =>
                                  this.setState({
                                    wednesdaystarttime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.wednesdayendtime}
                                onChange={(evt) =>
                                  this.setState({
                                    wednesdayendtime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box
                              className={classes.timeTableLabel}
                              paddingY={2}
                              paddingX={2}
                            >
                              <Typography>Thurseday</Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.thrusdaystarttime}
                                onChange={(evt) =>
                                  this.setState({
                                    thrusdaystarttime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.thrusdayendtime}
                                onChange={(evt) =>
                                  this.setState({
                                    thrusdayendtime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box
                              className={classes.timeTableLabel}
                              paddingY={2}
                              paddingX={2}
                            >
                              <Typography>Friday</Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.fridaystarttime}
                                onChange={(evt) =>
                                  this.setState({
                                    fridaystarttime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.fridayendtime}
                                onChange={(evt) =>
                                  this.setState({
                                    fridayendtime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box
                              className={classes.timeTableLabel}
                              paddingY={2}
                              paddingX={2}
                            >
                              <Typography>Saturday</Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.saturdaystarttime}
                                onChange={(evt) =>
                                  this.setState({
                                    saturdaystarttime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.saturdayendtime}
                                onChange={(evt) =>
                                  this.setState({
                                    saturdayendtime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            marginY={2}
                          >
                            <Box
                              className={classes.timeTableLabel}
                              paddingY={2}
                              paddingX={2}
                            >
                              <Typography>Sunday</Typography>
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.sundaystarttime}
                                onChange={(evt) =>
                                  this.setState({
                                    sundaystarttime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                            <Box style={{ width: "33%" }}>
                              <TextField
                                value={this.state.sundayendtime}
                                onChange={(evt) =>
                                  this.setState({
                                    sundayendtime: evt.target.value,
                                  })
                                }
                                type="time"
                                variant="outlined"
                                fullWidth
                              />
                            </Box>
                          </Box>

                          <Box marginY={2}>
                            {this.state.success ? (
                              <Alert severity="success">
                                {this.state.successmessage}
                              </Alert>
                            ) : (
                              <>
                                {this.state.loading ? (
                                  <CircularProgress />
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{ backgroundColor: "deepskyblue" }}
                                    onClick={() => {
                                      this.updateTimetablefunc();
                                    }}
                                  >
                                    Update
                                  </Button>
                                )}
                              </>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container school-cont">
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-3 cont-col offset-1">
              {/* <div className="row">
                <button
                  onClick={() => {
                    this.setState({
                      time: true,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: false,
                      page: 1,
                    });
                  }}
                  className={this.state.time ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> TIME TABLE
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: true,
                      news: false,
                      result: false,
                      principle: false,
                      page: 2,
                    });
                  }}
                  className={this.state.holiday ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> HOLIDAY LISTS
                </button>
              </div>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  onClick={() => {
                    this.setState({
                      time: true,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: false,
                      page: 1,
                    });
                  }}
                  className={this.state.time ? "clicked" : "notclickedyet"}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>TIME TABLE</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: true,
                      news: false,
                      result: false,
                      principle: false,
                      page: 2,
                    });
                  }}
                  className={this.state.holiday ? "clicked" : "notclickedyet"}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>HOLIDAY LISTS</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  className={this.state.news ? "clicked" : "notclickedyet"}
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: true,
                      result: false,
                      principle: false,

                      page: 3,
                    });
                  }}
                  className={this.state.news ? "clicked" : "notclickedyet"}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>NEWS</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: true,
                      principle: false,
                      page: 4,
                    });
                  }}
                  className={this.state.result ? "clicked" : "notclickedyet"}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>SCHOOL RESULT</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop={1} className={classes.dashboardOtherButton}>
                <Box
                  display="flex"
                  style={{ height: "100%", color: "black" }}
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: true,
                      page: 5,
                    });
                  }}
                  className={this.state.principle ? "clicked" : "notclickedyet"}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>PRINCIPAL MESSAGE</Typography>
                  </Box>
                </Box>
              </Box>
            </div>
            <div className="col-md-8">
              {this.state.page === 1 ? (
                <div className="">
                  {this.state.updatetimetable ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-1">
                          <Button
                            style={{
                              borderRadius: "50%",
                              border: "1px solid grey",
                            }}
                            onClick={() => {
                              this.setState({ updatetimetable: false });
                            }}
                          >
                            <HiOutlineArrowNarrowLeft size="15px" />
                          </Button>
                        </div>
                        <h5>Update Time & Table</h5>
                        <div
                          style={{
                            height: "3px",
                            width: "80px",
                            textDecoration: "underline",
                            backgroundColor: "lightblue",
                          }}
                        ></div>
                      </div>
                      <div className="row" style={{ paddingTop: "5vh" }}>
                        <div className="col-md-3">
                          <h6
                            style={{
                              paddingLeft: "5vh",
                            }}
                          >
                            Day
                          </h6>
                          <Form>
                            <TextField
                              value="Monday"
                              disabled
                              type="text"
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <h6
                            style={{
                              paddingLeft: "5vh",
                            }}
                          >
                            Start Time
                          </h6>
                          <Form>
                            <TextField
                              value={this.state.mondaystarttime}
                              onChange={(evt) =>
                                this.setState({
                                  mondaystarttime: evt.target.value,
                                })
                              }
                              type="time"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <h6
                            style={{
                              paddingLeft: "5vh",
                            }}
                          >
                            End Time
                          </h6>
                          <Form>
                            <TextField
                              value={this.state.mondayendtime}
                              onChange={(evt) =>
                                this.setState({
                                  mondayendtime: evt.target.value,
                                })
                              }
                              type="time"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "3vh" }}>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value="Tuesday"
                              disabled
                              type="text"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.tuesdaystarttime}
                              onChange={(evt) =>
                                this.setState({
                                  tuesdaystarttime: evt.target.value,
                                })
                              }
                              type="time"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.tuesdayendtime}
                              onChange={(evt) =>
                                this.setState({
                                  tuesdayendtime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "3vh" }}>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value="Wednesday"
                              disabled
                              type="text"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.wednesdaystarttime}
                              onChange={(evt) =>
                                this.setState({
                                  wednesdaystarttime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.wednesdayendtime}
                              onChange={(evt) =>
                                this.setState({
                                  wednesdayendtime: evt.target.value,
                                })
                              }
                              type="time"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "3vh" }}>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value="Thrusday"
                              disabled
                              type="text"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.thrusdaystarttime}
                              onChange={(evt) =>
                                this.setState({
                                  thrusdaystarttime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.thrusdayendtime}
                              onChange={(evt) =>
                                this.setState({
                                  thrusdayendtime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "3vh" }}>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value="Friday"
                              disabled
                              type="text"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.fridaystarttime}
                              onChange={(evt) =>
                                this.setState({
                                  fridaystarttime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.fridayendtime}
                              onChange={(evt) =>
                                this.setState({
                                  fridayendtime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "3vh" }}>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value="Saturday"
                              disabled
                              type="text"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.saturdaystarttime}
                              onChange={(evt) =>
                                this.setState({
                                  saturdaystarttime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.saturdayendtime}
                              onChange={(evt) =>
                                this.setState({
                                  saturdayendtime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "3vh" }}>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value="Sunday"
                              disabled
                              type="text"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.sundaystarttime}
                              onChange={(evt) =>
                                this.setState({
                                  sundaystarttime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                        <div className="col-md-3">
                          <Form>
                            <TextField
                              value={this.state.sundayendtime}
                              onChange={(evt) =>
                                this.setState({
                                  sundayendtime: evt.target.value,
                                })
                              }
                              type="time"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="col" style={{ paddingTop: "5vh" }}>
                        <Button
                          onClick={() => {
                            this.updateTimetablefunc();
                          }}
                        >
                          {this.state.loading ? (
                            <span>
                              {" "}
                              <ClipLoader color="white" size={20} />
                              Please Wait{" "}
                            </span>
                          ) : (
                            <span>Update</span>
                          )}
                        </Button>
                      </div>
                      {this.state.success ? (
                        <div
                          style={{
                            backgroundColor: "lightgreen",
                            color: "white",
                          }}
                        >
                          <h6 style={{ paddingLeft: "10vh" }}>
                            {this.state.successmessage}
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          <h5>TIME TABLE</h5>
                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="col-md-5">
                          <Button onClick={() => this.updatewindow()}>
                            UPDATE YOUR TIMETABLE
                          </Button>
                        </div>
                      </div>
                      <Container>
                        {this.state.timetabledata.map((l, i) => {
                          return (
                            <Grid
                              marginY={1}
                              item
                              lg={10}
                              md={10}
                              xs={6}
                              sm={6}
                            >
                              <Box className={classes.personalInfoBox}>
                                <Box className={classes.singleBox}>
                                  <Box style={{ alignSelf: "center" }}>
                                    <Typography
                                      variant="p"
                                      style={{ fontWeight: "" }}
                                    >
                                      {l.day}
                                    </Typography>
                                  </Box>
                                  <Box style={{ alignSelf: "left" }}>
                                    <Typography
                                      variant="p"
                                      style={{ fontWeight: "" }}
                                    >
                                      {" "}
                                      {l.start_time} - {l.end_time}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <div className="row">
                              <div className="col-md-2">
                                <div
                                  className="row"
                                  style={{ paddingTop: "15px" }}
                                >
                                  {l.day}
                                </div>
                              </div>
                              <div className="col-md-2 offset-6">
                                <div
                                  className="row"
                                  style={{ paddingTop: "15px" }}
                                >
                                  {l.start_time} - {l.end_time}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Container>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {this.state.page === 2 ? (
                <div>
                  <div className="row">
                    <div className="col-md-3" style={{ flex: "1" }}>
                      <h5>HOLIDAY LISTS</h5>
                      <div
                        style={{
                          height: "3px",
                          width: "80px",
                          textDecoration: "underline",
                          backgroundColor: "lightblue",
                        }}
                      ></div>
                    </div>
                    <div className="col-md-3">
                      <Button
                        onClick={() => {
                          this.setState({ addholiday: true });
                        }}
                      >
                        ADD HOLIDAY
                      </Button>
                    </div>
                  </div>
                  {this.state.addholiday ? (
                    <div className="container">
                      <div className="col-md-1">
                        <Button
                          style={{ borderRadius: "50%" }}
                          onClick={() => {
                            this.setState({ addholiday: false });
                          }}
                        >
                          <HiOutlineArrowNarrowLeft size="15px" />
                        </Button>
                      </div>
                      <div className="row" style={{ paddingTop: "5vh" }}>
                        <div className="col-md-10">
                          <h6>Name of the holiday</h6>
                          <Form>
                            <TextField
                              value={this.state.holidayname}
                              onChange={(evt) =>
                                this.setState({
                                  holidayname: evt.target.value,
                                })
                              }
                              type="text"
                              fullWidth
                              placeholder=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "5vh" }}>
                        <div className="col-md-5">
                          <h6>Holiday Start Date</h6>
                          <Form>
                            <TextField
                              value={this.state.holidaystart}
                              onChange={(evt) =>
                                this.setState({
                                  holidaystart: evt.target.value,
                                })
                              }
                              type="date"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                        <div className="col-md-5">
                          <h6>Holiday End Date</h6>
                          <Form>
                            <TextField
                              value={this.state.holidayend}
                              onChange={(evt) =>
                                this.setState({
                                  holidayend: evt.target.value,
                                })
                              }
                              type="date"
                              placeholder=""
                              fullWidth
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="col" style={{ paddingTop: "5vh" }}>
                        <Button
                          onClick={() => {
                            this.AddHolidayfunc();
                          }}
                        >
                          {this.state.loading ? (
                            <span>
                              {" "}
                              <ClipLoader color="white" size={20} />
                              Please Wait{" "}
                            </span>
                          ) : (
                            <span>Add</span>
                          )}
                        </Button>
                      </div>
                      {this.state.success ? (
                        <div
                          style={{
                            backgroundColor: "lightgreen",
                            color: "white",
                          }}
                        >
                          <h6 style={{ paddingLeft: "10vh" }}>
                            {this.state.holidayaddsuccessmessage}
                          </h6>
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "white",
                          }}
                        >
                          <h6 style={{ paddingLeft: "10vh" }}>
                            {this.state.holidayaddsuccessmessage}
                          </h6>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Container>
                        {this.state.holidaydata.map((l, i) => {
                          return (
                            <Grid
                              marginY={1}
                              item
                              lg={10}
                              md={10}
                              xs={6}
                              sm={6}
                            >
                              <Box className={classes.personalInfoBox}>
                                <Box className={classes.singleBox}>
                                  <Box style={{ alignSelf: "center" }}>
                                    <Typography
                                      variant="p"
                                      style={{ fontWeight: "" }}
                                    >
                                      {l.date}
                                    </Typography>
                                  </Box>
                                  <Box style={{ alignSelf: "left" }}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      {" "}
                                      {l.name}
                                    </Typography>
                                  </Box>
                                  <Box style={{ alignSelf: "left" }}>
                                    <Button
                                      style={{
                                        borderRadius: "50%",
                                        backgroundColor: "lightgreen",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        this.openholidaymodal(l);
                                      }}
                                    >
                                      <AiFillEdit color="black" size="18px" />
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <div className="row">
                              <div className="col-md-4" style={{ flex: "1" }}>
                                <div
                                  className="row"
                                  style={{ paddingTop: "15px" }}
                                >
                                  {l.date}
                                </div>
                              </div>
                              <div className="col-md-2 ">
                                <div
                                  className="row"
                                  style={{ paddingTop: "15px" }}
                                >
                                  {l.name}
                                </div>
                              </div>
                              <div
                                className="col-md-2"
                                style={{ paddingTop: "1vh" }}
                              >
                                <Button
                                  style={{
                                    borderRadius: "50%",
                                    backgroundColor: "lightgreen",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    this.openholidaymodal(l);
                                  }}
                                >
                                  <AiFillEdit color="black" size="18px" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </Container>
                      <Modal
                        size="md"
                        show={this.state.holidayeditmodal}
                        onHide={() =>
                          this.setState({ holidayeditmodal: false })
                        }
                        aria-labelledby="example-modal-sizes-title-sm"
                        animation="true"
                        autoFocus="true"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-modal-sizes-title-lg">
                            Edit Holiday Detais
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="">
                            <div className="col-md-11">
                              <p
                                style={{
                                  fontSize: "smaller",
                                  opacity: "0.4",
                                }}
                              >
                                Holiday Name
                              </p>
                              <Form>
                                <TextField
                                  value={this.state.holidayeditname}
                                  onChange={(evt) =>
                                    this.setState({
                                      holidayeditname: evt.target.value,
                                    })
                                  }
                                  type="text"
                                  fullWidth
                                />
                              </Form>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <p
                                  style={{
                                    fontSize: "smaller",
                                    opacity: "0.4",
                                  }}
                                >
                                  Holiday Start Date
                                </p>

                                <Form>
                                  <TextField
                                    value={this.state.holidayeditstartdate}
                                    onChange={(evt) =>
                                      this.setState({
                                        holidayeditstartdate: evt.target.value,
                                      })
                                    }
                                    type="date"
                                    placeholder=""
                                  />
                                </Form>
                              </div>
                              <div className="col-md-6">
                                <p
                                  style={{
                                    fontSize: "smaller",
                                    opacity: "0.4",
                                  }}
                                >
                                  Holiday End Date
                                </p>

                                <Form>
                                  <TextField
                                    value={this.state.holidayeditenddate}
                                    onChange={(evt) =>
                                      this.setState({
                                        holidayeditenddate: evt.target.value,
                                      })
                                    }
                                    type="date"
                                    placeholder=""
                                  />
                                </Form>
                              </div>
                            </div>
                            {this.state.success ? (
                              <div
                                style={{
                                  backgroundColor: "lightgreen",
                                  color: "white",
                                  height: "100px",
                                }}
                              >
                                <h6 style={{ paddingLeft: "10vh" }}>
                                  {this.state.successholidayeditmessage}
                                </h6>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="container">
                              <div
                                className="row"
                                style={{ paddingTop: "20px" }}
                              >
                                <Button
                                  onClick={() => {
                                    this.UpdateHoliday();
                                  }}
                                >
                                  {this.state.loading ? (
                                    <span>
                                      {" "}
                                      <ClipLoader color="white" size={20} />
                                      Please Wait{" "}
                                    </span>
                                  ) : (
                                    <span>Update</span>
                                  )}
                                </Button>
                              </div>
                            </div>
                            {this.state.success ? (
                              <div
                                className="row"
                                style={{
                                  paddingTop: "25px",
                                  position: "relative",
                                  top: "10px",
                                  backgroundColor: "green",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "small",
                                  }}
                                >
                                  {this.state.successholidayeditmessage}
                                </h5>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.error ? (
                              <div
                                className="row"
                                style={{
                                  paddingTop: "25px",
                                  position: "relative",
                                  top: "10px",
                                  backgroundColor: "red",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "white",
                                    fontSize: "small",
                                  }}
                                >
                                  Something Went Wrong.
                                </h5>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {this.state.page === 3 ? (
                <div>
                  {this.state.addnewssection ? (
                    <div>
                      <div className="col-md-1">
                        <Button
                          style={{ borderRadius: "50%" }}
                          onClick={() => {
                            this.setState({ addnewssection: false });
                          }}
                        >
                          <HiOutlineArrowNarrowLeft size="15px" />
                        </Button>
                      </div>{" "}
                      <h5>ADD NEWS</h5>
                      <div
                        style={{
                          height: "3px",
                          width: "80px",
                          textDecoration: "underline",
                          backgroundColor: "lightblue",
                        }}
                      ></div>
                      <div className="container" style={{ paddingTop: "4vh" }}>
                        <div className="row">
                          <div className="col-md-5">
                            <h6>News Title</h6>
                            <Form>
                              <TextField
                                value={this.state.newstitle}
                                onChange={(evt) =>
                                  this.setState({
                                    newstitle: evt.target.value,
                                  })
                                }
                                type="text"
                                fullwidth
                                placeholder=""
                              />
                            </Form>
                          </div>
                          <div className="col-md-5">
                            <h6>News Date</h6>
                            <Form>
                              <TextField
                                value={this.state.newsdate}
                                onChange={(evt) =>
                                  this.setState({
                                    newsdate: evt.target.value,
                                  })
                                }
                                type="date"
                                placeholder=""
                                fullWidth
                              />
                            </Form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10">
                            <h6> News Description</h6>
                            <Form>
                              <Form.Control
                                value={this.state.newsdescription}
                                onChange={(evt) =>
                                  this.setState({
                                    newsdescription: evt.target.value,
                                  })
                                }
                                as="textarea"
                                fullWidth
                                placeholder=""
                              />
                            </Form>
                          </div>
                        </div>
                        <Box marginTop={2}>
                          <Button
                            onClick={() => {
                              this.AddNews();
                            }}
                          >
                            {this.state.loading ? (
                              <span>
                                {" "}
                                <ClipLoader color="white" size={20} />
                                Please Wait{" "}
                              </span>
                            ) : (
                              <span>Add</span>
                            )}
                          </Button>
                        </Box>
                        {this.state.success ? (
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "lightgreen",
                            }}
                          >
                            {this.state.newsaddsuccess}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.error ? (
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "red",
                            }}
                          >
                            {this.state.newsaddsuccess}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          <h5>NEWS</h5>
                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <Button
                            onClick={() => {
                              this.setState({ addnewssection: true });
                            }}
                          >
                            Add News
                          </Button>
                        </div>
                      </div>
                      {this.state.Newsdata === undefined ? (
                        <div>No News Added </div>
                      ) : (
                        <div
                          className="container"
                          style={{ paddingTop: "10px" }}
                        >
                          {this.state.Newsdata.map((l, i) => {
                            return (
                              <div
                                className="row"
                                style={{ paddingTop: "2vh" }}
                              >
                                <div class="col-sm-10">
                                  <div class="card">
                                    <div class="card-body">
                                      <p1
                                        style={{
                                          fontSize: "smaller",
                                          opacity: "0.8",
                                        }}
                                      >
                                        {l.date}
                                      </p1>
                                      <h5 class="card-title">{l.title}</h5>
                                      <Typography class="card-text">
                                        {l.description}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <button
                                    className="rounded-circle"
                                    style={{
                                      backgroundColor: "lightgreen",
                                      height: "40px",
                                      width: "40px",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      this.opennewsdeletemodal(l);
                                    }}
                                  >
                                    <AiFillDelete color="white" />
                                  </button>
                                </div>
                                <Modal
                                  size="md"
                                  show={this.state.newsdltmodal}
                                  onHide={() =>
                                    this.setState({ newsdltmodal: false })
                                  }
                                  aria-labelledby="example-modal-sizes-title-sm"
                                  animation="true"
                                  autoFocus="true"
                                  centered
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title id="example-modal-sizes-title-lg">
                                      Delete News
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <p style={{ fontWeight: "bold" }}>
                                      Are you sure you want to delete?
                                    </p>
                                    <p style={{ opacity: "0.8" }}>
                                      This process can't be undone.
                                    </p>
                                    <div className="row">
                                      <Button
                                        onClick={() => {
                                          this.DeleteNews();
                                        }}
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                        }}
                                      >
                                        {this.state.loading ? (
                                          <span>
                                            {" "}
                                            <ClipLoader
                                              color="white"
                                              size={20}
                                            />
                                            Please Wait{" "}
                                          </span>
                                        ) : (
                                          <span>Delete</span>
                                        )}
                                      </Button>
                                    </div>
                                    {this.state.success ? (
                                      <div
                                        style={{
                                          backgroundColor: "lightgreen",
                                        }}
                                      >
                                        <h6 style={{ color: "white" }}>
                                          {this.state.newsdltsuccess}
                                        </h6>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {this.state.error ? (
                                      <div
                                        style={{
                                          backgroundColor: "red",
                                        }}
                                      >
                                        <h6 style={{ color: "white" }}>
                                          {this.state.newsdltsuccess}
                                        </h6>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Modal.Body>
                                </Modal>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {this.state.page === 4 ? (
                <div>
                  <h5>SCHOOL RESULT</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <br></br>
                  <h2>A+</h2>
                  <div>
                    <p1 style={{ paddingTop: "15px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p1>
                  </div>
                  <div>
                    <p2 style={{ paddingTop: "10px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p2>
                  </div>
                  <div>
                    <p3 style={{ paddingTop: "10px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p3>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.page === 5 ? (
                <div>
                  {this.state.openprincipalupdatesection ? (
                    <div style={{ minHeight: "80vh" }}>
                      <div className="col-md-1">
                        <Button
                          style={{ borderRadius: "50%" }}
                          onClick={() => {
                            this.setState({
                              openprincipalupdatesection: false,
                            });
                          }}
                        >
                          <HiOutlineArrowNarrowLeft size="15px" />
                        </Button>
                      </div>
                      <h5>ADD PRINCIPAL MESSAGE</h5>
                      <div
                        style={{
                          height: "3px",
                          width: "80px",
                          textDecoration: "underline",
                          backgroundColor: "lightblue",
                        }}
                      ></div>
                      <h6>Update Image</h6>
                      <Form>
                        <Form.Control
                          onChange={(e) => {
                            this.handlePicChange(e);
                          }}
                          type="file"
                          placeholder=""
                        />
                      </Form>
                      <h6>Update Message</h6>
                      <Form>
                        <Form.Control
                          value={this.state.principaldescription}
                          onChange={(evt) =>
                            this.setState({
                              principaldescription: evt.target.value,
                            })
                          }
                          as="textarea"
                          rows={4}
                          placeholder=""
                        />
                      </Form>
                      <Button
                        onClick={() => {
                          this.AddprincipalMessage();
                        }}
                        class="btn btn-primary btn-lg"
                        style={{
                          position: "relative",
                          top: "20px",
                          height: "43px",
                          width: "132px",
                        }}
                      >
                        {this.state.loading ? (
                          <span>
                            {" "}
                            <ClipLoader color="white" size={20} />
                            Please Wait{" "}
                          </span>
                        ) : (
                          <span>Save</span>
                        )}
                      </Button>
                      {this.state.success ? (
                        <div
                          style={{
                            backgroundColor: "lightgreen",
                            color: "white",
                            height: "auto",
                            marginTop: "5vh",
                          }}
                        >
                          <h6 style={{ paddingLeft: "10vh" }}>
                            {this.state.principalmsg}
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="row">
                        <div className="col-md-3" style={{ flex: "1" }}>
                          {" "}
                          <h5>PRINCIPAL MESSAGE</h5>
                          <div
                            style={{
                              height: "3px",
                              width: "80px",
                              textDecoration: "underline",
                              backgroundColor: "lightblue",
                            }}
                          ></div>
                        </div>
                        <div className="col-md-3">
                          <Button
                            onClick={() => {
                              this.setState({
                                openprincipalupdatesection: true,
                                principaldescription:
                                  this.state.principaldata.description,
                              });
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                      <div
                        className="container-fluid"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="row">
                          <div className="col-12 mt-3">
                            <div className="card">
                              <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                  <img src={this.state.principaldata.photo} />
                                </div>
                                <div className="card-body">
                                  <p1 class="card-text">
                                    {this.state.principaldata.description}
                                  </p1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

const styles = (theme) => ({
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.6",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },
  personalInfoBox: {
    border: "1px solid lightgrey",
    // borderRadius: "5%",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  profileEditButton: {
    borderRadius: "30%",
    backgroundColor: "lightgrey",
    border: "none",
    color: "white",
  },
  reportInfoButton: {
    // border: "1px solid lightgrey",
    // borderRadius: "5%",

    display: "flex",
    flexDirection: "column",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  timeTableLabel: {
    width: "30%",
    border: "1px solid lightgrey",
    height: "60px",
    marginTop: "-4px",
    borderRadius: "4px",
  },
});
// export default ObserverSchool;
export default withStyles(styles)(ObserverSchool);
