import { Box, Typography } from "@mui/material";
import React from "react";
import { Component } from "react";

class AboutUs extends Component {
  state = {};
  render() {
    return (
      <Box>
        <Typography variant="h1"> Hey there, i am about us page</Typography>
      </Box>
    );
  }
}
export default AboutUs;
