import React, { Component } from "react";
import {
  Link,
  Select,
  Dialog,
  DialogTitle,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Divider,
  Box,
  Alert,
  Typography,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import API from "./api";
import { FaCheckCircle } from "react-icons/fa";
import { GoPackage } from "react-icons/go";

export default class GamePaymentReturn extends Component {
  state = {
    open: true,
    Statusdata: "",
    customer: "",
    respo: "",
    status: false,
    Code: "",
  };
  componentDidMount() {
    this.getOrderstatus();
  }
  //   updategamesubs() {
  //     const params = {
  //       user_id: localStorage.getItem("userID"),
  //       action: "add",
  //       tution_id: localStorage.getItem("tuitionID"),
  //       tution_timing_id: localStorage.getItem("tutiontimingID"),
  //     };
  //     var that = this;
  //     API.post("StudentApi/tutionSubscription", params)
  //       .then(function (response) {
  //         console.log(response);
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         console.log(error);
  //       })
  //       .finally(function () {});
  //   }

  getOrderstatus() {
    var that = this;
    API.post("StudentApi/getAcademyOrderStatus", {
      orderId: localStorage.getItem("orderID"),
    })
      .then(function (response) {
        console.log(response.data.code);

        that.setState({
          Code: response.data.code,
          Statusdata: response.data.data,
          customer: response.data.data.customer_details,
        });
        that.state.respo = response;
        if (response.data.code === 200) {
          that.setState({ status: true });
          setTimeout(() => {
            that.setState({ status: false });
          }, 3000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        const params = {
          user_id: localStorage.getItem("userID"),
          action: "add",
          classification_id: localStorage.getItem("classification_ID"),
          game_timing_id: localStorage.getItem("gametimingID"),
          order_id: that.state.respo.data.data.order_id,
          cf_order_id: that.state.respo.data.data.cf_order_id,
          order_token: that.state.respo.data.data.order_token,
        };

        API.post("StudentApi/gameSubscription", params)
          .then(function (response) {
            console.log(response);
            // that.setState({ submsg: response.data.message });
            // that.setState({ success: true });
            // setTimeout(() => {
            //   that.setState({ success: false });
            // }, 3000);
            // if (response.data.result === 1) {
            //   setTimeout(() => {
            //     navigate("/student/subcriptions");
            //   }, 3000);
            // }
          })

          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            if (that.state.res === 1) {
            } else {
            }
          });
      });
  }
  render() {
    return (
      <div style={{ minHeight: "50vh" }}>
        {this.state.status ? (
          <Modal
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
            show={this.state.open}
            size="md"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <GoPackage color="yellow" size="14vh" />
              <Alert severity="success" style={{ fontWeight: "bold" }}>
                Order Placed !
              </Alert>
            </Box>
          </Modal>
        ) : (
          ""
        )}
        <Container>
          {/* <Modal
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
            show={this.state.open}
            size="md"
          > */}
          {/* <DialogTitle>Set backup account</DialogTitle> */}

          <Box
            item
            style={{
              border: "1px solid lightgrey",
              marginTop: "5vh",
              marginBottom: "5vh",
            }}
          >
            <Container>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
                alignItems="center"
              >
                <FaCheckCircle size="7vh" color="green" />
                <Typography variant="h6">Payment Successful</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Name:</Typography>
                </Box>
                <Box>
                  <Typography> {this.state.customer.customer_name}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Email:</Typography>
                </Box>
                <Box>
                  <Typography> {this.state.customer.customer_email}</Typography>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Mobile:</Typography>
                </Box>
                <Divider />

                <Box>
                  <Typography> {this.state.customer.customer_phone}</Typography>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    Ammount :
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ fontWeitht: "bold" }}>
                    {this.state.Statusdata.order_amount}
                  </Typography>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Your orderid:</Typography>
                </Box>
                <Box>
                  <Typography> {this.state.Statusdata.order_id}</Typography>
                </Box>
              </Box>
              {/* <Divider /> */}

              <Box
                display="flex"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
                flexDirection="column"
                alignItems="center"
              >
                <Box>
                  <Typography>
                    <a href="/student/subcriptions">
                      {" "}
                      Go To Your Subbscriptions
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
          {/* </Modal> */}
        </Container>
      </div>
    );
  }
}
