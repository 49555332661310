import React, { Component } from "react";
// import { FaCheckCircle } from "react-icons/fa";
import { GoPackage } from "react-icons/go";
import {
  Link,
  Select,
  Dialog,
  Alert,
  DialogTitle,
  Divider,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import API from "./api";
import { FaCheckCircle } from "react-icons/fa";

export default class PaymentReturn extends Component {
  state = {
    open: true,
    Statusdata: "",
    customer: "",
    token: "",
    cfid: "",
    respo: "",
  };
  componentDidMount() {
    this.getOrderstatus();
    this.updateteachersubs();
  }
  updateteachersubs() {
    // const params = {
    //   user_id: localStorage.getItem("userID"),
    //   action: "add",
    //   tution_id: localStorage.getItem("tuitionID"),
    //   tution_timing_id: localStorage.getItem("tutiontimingID"),
    //   order_id: that.state.respo.data.data.order_id,
    //   cf_order_id: that.state.respo.data.data.cf_order_id,
    //   order_token: that.state.respo.data.data.order_token,
    // };
    // var that = this;
    // API.post("StudentApi/tutionSubscription", params)
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   })
    //   .finally(function () {});
  }

  getOrderstatus() {
    var that = this;
    API.post("StudentApi/getTeacherOrderStatus", {
      orderId: localStorage.getItem("orderID"),
    })
      .then(function (response) {
        console.log(response.data.data.order_token);
        that.setState({
          Statusdata: response.data.data,
          customer: response.data.data.customer_details,
        });
        that.state.respo = response.data.data.order_id;
        that.state.token = response.data.data.order_token;
        that.state.cfid = response.data.data.cf_order_id;

        if (response.data.code === 200) {
          that.setState({ status: true });
          setTimeout(() => {
            that.setState({ status: false });
          }, 3000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        that.updatetuition();
        // always executed
        // const params = {
        //   user_id: localStorage.getItem("userID"),
        //   action: "add",
        //   tution_id: localStorage.getItem("tuitionID"),
        //   tution_timing_id: localStorage.getItem("tutiontimingID"),
        //   order_id: that.state.respo.data.data.order_id,
        //   cf_order_id: that.state.respo.data.data.cf_order_id,
        //   order_token: that.state.respo.data.data.order_token,
        // };
        // var that = this;
        // API.post("StudentApi/tutionSubscription", params)
        //   .then(function (response) {
        //     console.log(response);
        //   })
        //   .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //   })
        //   .finally(function () {});
      });
  }
  updatetuition() {
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "add",
      tution_id: localStorage.getItem("tuitionID"),
      tution_timing_id: localStorage.getItem("tutiontimingID"),
      order_id: this.state.respo,
      cf_order_id: this.state.cfid,
      order_token: this.state.token,
    };
    var that = this;
    API.post("StudentApi/tutionSubscription", params)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {});
  }
  render() {
    return (
      <div style={{ minHeight: "50vh" }}>
        {this.state.status ? (
          <Modal
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
            show={this.state.open}
            size="md"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <GoPackage color="yellow" size="14vh" />
              <Alert severity="success" style={{ fontWeight: "bold" }}>
                Order Placed !
              </Alert>
            </Box>
          </Modal>
        ) : (
          ""
        )}
        <Container>
          {/* <Modal
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
            show={this.state.open}
            size="md"
          > */}
          {/* <DialogTitle>Set backup account</DialogTitle> */}

          <Box
            item
            style={{
              border: "1px solid lightgrey",
              marginTop: "5vh",
              marginBottom: "5vh",
            }}
          >
            <Container>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
                alignItems="center"
              >
                <FaCheckCircle size="7vh" color="green" />
                <Typography variant="h6">Payment Successful</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Name:</Typography>
                </Box>
                <Box>
                  <Typography> {this.state.customer.customer_name}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Email:</Typography>
                </Box>
                <Box>
                  <Typography> {this.state.customer.customer_email}</Typography>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Mobile:</Typography>
                </Box>
                <Divider />

                <Box>
                  <Typography> {this.state.customer.customer_phone}</Typography>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    Ammount :
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ fontWeitht: "bold" }}>
                    {this.state.Statusdata.order_amount}
                  </Typography>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
              >
                <Box>
                  <Typography> Your orderid:</Typography>
                </Box>
                <Box>
                  <Typography> {this.state.Statusdata.order_id}</Typography>
                </Box>
              </Box>
              {/* <Divider /> */}

              <Box
                display="flex"
                style={{
                  paddingTop: "1vh",
                  paddingBottom: "1vh",
                  paddingLeft: "1vh",
                  paddingRight: "1vh",
                }}
                flexDirection="column"
                alignItems="center"
              >
                <Box>
                  <Typography>
                    <a href="/student/subcriptions">
                      {" "}
                      Go To Your Subbscriptions
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
          {/* </Modal> */}
        </Container>
      </div>
    );
  }
}
