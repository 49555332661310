import React, { Component } from "react";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent
} from '@mui/material'
import { withStyles } from '@mui/styles';
import { FaFilePdf } from "react-icons/fa";
import API from "../components/api";
import { FaPlayCircle } from "react-icons/fa";
import DownloadIcon from '@mui/icons-material/Download';


export default class SubjectDetail extends Component {
  state = {
    Topicdata: [],
  };
  componentDidMount() {
    this.getTopicdata();
  }
  gettopicid(l) {
    console.log("d", l.topic_id);
    localStorage.setItem("topicID", l.topic_id);
    localStorage.setItem("videofile", l.file);
  }
  getTopicdata() {
    const params = {
      subject_id: localStorage.getItem("subjectId"),
      chapter_id: localStorage.getItem("chapterID"),
    };
    var that = this;
    API.post("StudentApi/topics", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          Topicdata: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    return (
      <>
          <div className="banner-register">
            <img
              className=""
              src={require("../assets/img/banner-register.jpeg").default}
              style={{
                height: "300px",
                width: "100%",
                opacity: 0.8,
              }}
            />
            <h1> SUBJECT</h1>
            <p>
              <Link style={{ color: "white", textDecoration: "none" }} href="/">
                Home
              </Link>{" "}
              |{" "}
              <Link
                style={{ color: "white", textDecoration: "none" }}
                href="/userprofile"
              >
                Student
              </Link>{" "}
              |
              <Link
                style={{ color: "white", textDecoration: "none" }}
                href="/subjectuser"
              >
                {" "}
                Subject
              </Link>{" "}
              | Topics
            </p>
          </div>
          <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
            <Grid container spacing={2}>
              {this.state.Topicdata.map((l, id) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Card
                        onClick={() => {
                          this.gettopicid(l);
                        }}>
                        <Link href="/subjectdetailvideo" style={{ textDecoration: "none", color: "black" }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={l.thumbnail_file}
                            alt="green iguana"
                          />
                          <CardContent>
                            <Box display="flex" flexDirection="column">
                              <Box>
                                <Typography gutterBottom variant="h5" component="div">
                                  {l.name}
                                </Typography>
                              </Box>
                              <Box>
                                <a href={l.pdf_file} style={{ textDecoration: "none" }}><Button variant="text" style={{ color: "black" }}><DownloadIcon />Pdf</Button></a>
                              </Box>
                            </Box>
                          </CardContent>
                        </Link>

                      </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
          {/* <div
            className="container"
            style={{ paddingLeft: "10rem", paddingTop: "2rem" }}
          >
            <div className="row">
              {this.state.Topicdata.map((l, id) => {
                return (
                  <div className="col-md-3">
                    {" "}
                    <div
                      className="card"
                      style={{ width: "13rem", height: "auto" }}
                    >
                      <Link href="/subjectdetailvideo">
                        <div className="embed-responsive embed-responsive-4by3">
                          <Button
                            variant="none"
                            style={{ width: "100%" }}
                            onClick={() => {
                              this.gettopicid(l);
                            }}
                          >
                            {" "}
                            <img
                              className="card-img-top"
                              style={{ width: "100%", height: "40vh" }}
                              src={l.thumbnail_file}
                              alt="Card image cap"
                            />
                          </Button>
                          <button
                            style={{
                              height: "35px",
                              width: "35px",
                              border: "none",
                              position: "absolute",
                              right: "40%",
                              top: "40%",
                              transform: "translate(-20%,-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              console.log("playvideo");
                            }}
                            className="rounded-circle"
                          >
                            <FaPlayCircle size="35px" />
                          </button>
                        </div>
                      </Link>
                      <div className="card-body">
                        <h6 className="card-title">{l.name}</h6>
                        <p
                          className="card-text"
                          style={{ fontSize: "smaller" }}
                        >
                          Posted on 11th august , 2021
                        </p>
                        <button
                          className="btn btn-dark"
                          style={{
                            backgroundColor: "black",
                            width: "80px",
                            height: " 30px",
                            borderRadius: "5px",
                            position: "relative",
                            left: "30px",
                            color: "white",
                            fontSize: "smaller",
                          }}
                          onClick={() => {
                            <img src={l.pdf_file} />;
                          }}
                        >
                          <a
                            style={{ color: "white", textDecoration: "none" }}
                            href={l.pdf_file}
                            target="blank"
                          >
                            <FaFilePdf size="10px" /> pdf
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              
          </div> */}
      </>
    );
  }
}
