import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { GrAddCircle } from "react-icons/gr";
import API from "../components/api";

import { navigate } from "@reach/router";

class Buysubscription extends Component {
  state = {
    subscriptionpagedata: [],
    terms: "",
    subscription_id: "",
    subscripteddata: [],
    Profile: "",
    createorder: "",
    fees: "",
    classiError: "",
  };

  getprofile() {
    var that = this;
    API.post("StudentApi/profile", { user_id: localStorage.getItem("userID") })
      .then(function (response) {
        console.log(response);
        that.setState({
          Profile: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  radiobtn(e, l) {
    console.log("radio", l.amount);
    this.state.fees = l.amount;
    this.setState({
      subscription_id: e.currentTarget.value,
      fees: l.amount,
    });
    localStorage.setItem("SubsId", e.currentTarget.value);
  }
  valid() {
    if (this.state.subscription_id.length < 1) {
      this.setState({
        classiError: "Choose Subsriction To Proceed",
      });
      return false;
    } else {
      return true;
    }
  }
  Buysubs() {
    if (this.valid()) {
      const params = {
        user_id: localStorage.getItem("userID"),

        name: this.state.Profile.name,
        email: this.state.Profile.email,
        mobile: this.state.Profile.mobile,
        orderAmount: this.state.fees,
      };
      var that = this;
      API.post("StudentApi/createSubscriptionOrder", params)
        .then(function (response) {
          console.log(response.data.payment_link);
          that.setState({
            createorder: response.data,
          });
          navigate(`${response.data.payment_link}`);
          localStorage.setItem("orderID", response.data.order_id);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    } else {
    }
    // var that = this;
    // const params = {
    //   subscription_id: this.state.subscription_id,
    //   user_id: localStorage.getItem("userID"),
    // };
    // API.post("StudentApi/getSubscription", params)
    //   .then(function (response) {
    //     console.log("subs", response.data.data);
    //     that.setState({
    //       subscripteddata: response.data.data,
    //     });
    //     if (response.data.result === 1) {
    //       alert("success");
    //       navigate("/userprofile");
    //     } else {
    //       alert("not succeed");
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log("errors", error);
    //   })
    //   .finally(function () {});
  }
  componentDidMount() {
    this.getsubscriptiondatas();
    this.getprofile();
  }
  getsubscriptiondatas() {
    var that = this;
    API.post("StudentApi/student_subscriptions")
      .then(function (response) {
        console.log("subspage", response.data.data);
        that.setState({
          subscriptionpagedata: response.data.data,
          terms: response.data.termsContent,
        });
      })
      .catch(function (error) {
        console.log("errors", error);
      })
      .finally(function () {});
  }
  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
            }}
          />
          <h1> SUBSCRIPTION</h1>

          <p>Choose A Plan That Works Best For You</p>
        </div>
        <div className="container" style={{ paddingTop: "5vh" }}>
          <div className="row">
            <div className="col-md-5 offset-1">
              <div
                className="row"
                style={{ borderBottom: "1px solid lightgrey" }}
              >
                <h6 style={{ fontWeight: "bold" }}>Select Subscription</h6>
                {<span className="error">{this.state.classiError}</span>}
              </div>

              <div
                className="row"
                style={{ borderBottom: "1px solid lightgrey" }}
              >
                {this.state.subscriptionpagedata.map((l, id) => {
                  return (
                    <div className="row">
                      <div className="col-md-8">
                        <Form.Check
                          style={{ paddingTop: "2vh" }}
                          type="radio"
                          label={l.name}
                          id={l.id}
                          value={l.id}
                          onChange={(e) => {
                            this.radiobtn(e, l);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <p>{l.amount}</p>
                        <p
                          style={{
                            fontSize: "12px",
                            lineHeight: "15px",
                            padding: "0px",
                          }}
                        >
                          Per Month
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="row"
                style={{ paddingTop: "15px", paddingLeft: "20px" }}
              >
                <div className="col-md-11">
                  <h6>Terms & Conditions</h6>
                  <p style={{ opacity: "0.6", fontSize: "smaller" }}>
                    {this.state.terms}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              {/* <div
                className="row"
                style={{ borderBottom: "1px solid lightgrey" }}
              >
                <h6>Select Payment Method</h6>
              </div>
              <div className="row" style={{ paddingLeft: "4vh" }}>
                <Form>
                  <Form.Check
                    label="Net Banking"
                    name="group1"
                    type="radio"
                    id="pay1"
                  />
                  <Form.Check
                    label="Visa / Master"
                    name="group1"
                    type="radio"
                    id="pay2"
                  />
                  <Form.Check label="Cash On Home " type="radio" id="pay3" />
                </Form>
              </div>
              <div
                className="row"
                style={{
                  borderBottom: "1px solid lightgrey",
                  paddingTop: "7vh",
                }}
              >
                <h6>Select Payment Method</h6>
              </div> */}
              <div
                className="row"
                style={{ paddingTop: "10px", paddingLeft: "5vh" }}
              >
                {/* <Form.Group>
                  <Form.Check
                    type="radio"
                    style={{ opacity: "0.6" }}
                    label="xxxxxxxxxxxx 3432"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    style={{ opacity: "0.6" }}
                    type="radio"
                    label="xxxxxxxxxxxx 1010"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  />
                </Form.Group> */}
                <div className="row" style={{ paddingTop: "3vh" }}>
                  {/* <div className="col-md-10">
                    <button
                      style={{ border: "none" }}
                      onClick={() => {
                        alert("You will be able to add card soon");
                      }}
                    >
                      <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                        <GrAddCircle /> Add New Card
                      </p>
                    </button>
                  </div> */}
                </div>
                <div className="col" style={{ paddingTop: "20px" }}>
                  <button
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      color: "white",
                      height: "35px",
                      width: "100px",
                      border: "none",
                    }}
                    onClick={() => this.Buysubs()}
                  >
                    PAY NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Buysubscription;
