import React, { Component } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { FaRegCommentDots } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
// import { navigate } from "@reach/router";

// import { Router, Link } from "@reach/router"
import API from "../components/api";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { navigate } from "@reach/router";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

class GamingAcademy extends Component {
  state = {
    time: true,
    holiday: false,
    subsmsg: "",
    news: false,
    result: false,
    Subscriptions: [],
    page: 1,
    gameID: "",
    filteredgames: [],
    News: [],
    Tournament: [],
    AcademyLists: [],
    item: "",
    Profile: [],
    gameList: [],
    classselected: "",
    classlist: [],
    game: [],
    gameerror: "",
  };

  componentDidMount() {
    this.getSubscriptiondetails();
    this.getNewsdata();
    this.getTournaments();
    this.getAcademyList();
    this.getProfile();
    this.getgamelist();
    this.getclassList();
  }
  valid2() {
    if (this.state.gameID.length < 1) {
      this.setState({ gameerror: "Must Choose a game" });
      return false;
    } else {
      return true;
    }
  }
  subscribe() {
    this.setState({ gameerror: "" });
    if (this.valid2()) {
      navigate("/gamepayment");
    } else {
    }
  }
  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", {
      school_id: localStorage.getItem("school_id(student)"),
    })
      .then(function (response) {
        // console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  };
  getgamelist() {
    var that = this;
    API.post("MasterApi/game_list")
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          gameList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getProfile() {
    const user = {
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("StudentApi/profile", user)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Profile: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getacademyid(l) {
    const gameid = l.id;
    const result = this.state.AcademyLists.find(({ id }) => id === l.id);
    console.log("folafol", result.games);
    this.setState({ filteredgames: result.games });
    localStorage.setItem("academyID", l.id);
    this.setState({ item: l, page: 1.1, game: l.games });
  }
  getAcademyList() {
    var that = this;
    API.get("MasterApi/academyList")
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          AcademyLists: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getTournaments() {
    var that = this;
    API.get("StudentApi/tournaments")
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Tournament: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getNewsdata() {
    var that = this;
    API.get("StudentApi/academy_news")
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          News: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getSubscriptiondetails() {
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "view",
    };
    var that = this;
    API.post("StudentApi/gameSubscription", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Subscriptions: response.data.data,
          subsmsg: response.data.message,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>GAMING ACADEMY</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              {" "}
              Student{" "}
            </Link>
            | Gaming Academy
          </p>
        </div>
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: true,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: false,
                      page: 1,
                    });
                  }}
                  className={
                    this.state.time ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>ACADEMY LISTS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: true,
                      news: false,
                      result: false,
                      principle: false,
                      page: 2,
                    });
                  }}
                  className={
                    this.state.holiday ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>TOURNAMENTS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: true,
                      result: false,
                      principle: false,
                      page: 3,
                    });
                  }}
                  className={
                    this.state.news ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>NEWS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: true,
                      principle: false,
                      page: 4,
                    });
                  }}
                  className={
                    this.state.result ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>MY SUBSCRIPTION</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              {this.state.page === 1 && (
                <>
                  <Container>
                    <Typography>ACADEMY LISTS</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom: "12px",
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      {this.state.AcademyLists.map((l, id) => {
                        return (
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Card
                              onClick={() => {
                                this.getacademyid(l);
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={l.photo}
                                alt="green iguana"
                              />
                              <CardContent>
                                <Typography variant="h6">{l.name}</Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Container>
                </>
              )}
              {this.state.page === 2 && (
                <>
                  <Container>
                    <Typography>TOURNAMENTS</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom: "12px",
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      {this.state.Tournament.map((l, id) => {
                        return (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              style={{
                                width: "100%",
                                border: "1px solid lightgrey",
                                borderRadius: "5px",
                              }}
                              paddingX={2}
                              paddingY={1}
                            >
                              <Box>
                                <Typography
                                  variant="h5"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {l.league_name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{l.game_academy}</Typography>
                              </Box>
                              <Divider
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                  color: "lightgrey",
                                }}
                              />
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box display="flex" style={{ width: "50%" }}>
                                  <Box
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Game
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>: {l.game}</Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" style={{ width: "50%" }}>
                                  <Box
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Season
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>: {l.season}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box display="flex" style={{ width: "50%" }}>
                                  <Box
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Starts
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>: {l.from_date}</Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" style={{ width: "50%" }}>
                                  <Box
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Ends
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>: {l.to_date}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box display="flex" style={{ width: "50%" }}>
                                  <Box
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Prize
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>: {l.prize}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Divider
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "8px",
                                  color: "lightgrey",
                                }}
                              />
                              <Box display="flex" flexDirection="column">
                                <Box
                                  style={{
                                    width: "40%",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Rules & Regulations
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography>{l.rule_regulation}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Container>
                </>
              )}
              {this.state.page === 3 && (
                <>
                  <Container>
                    <Typography>NEWS</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom: "12px",
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      {this.state.News.map((l, index) => {
                        return (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {/* <Box style={{ width: "90%" }}> */}
                            <Box>
                              <Typography style={{ fontWeight: "bold" }}>
                                {l?.date}
                              </Typography>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                {l?.title}
                              </Typography>
                              <Typography> {l?.description}</Typography>
                            </Box>
                            {/* </Box> */}
                            {index !== Number(this.state.News.length) - 1 && (
                              <Divider
                                style={{
                                  marginTop: "12px",
                                  color: "lightgrey",
                                }}
                              />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Container>
                </>
              )}
              {this.state.page === 4 && (
                <>
                  <Container>
                    <Typography>MY SUBSCRIPTION</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom: "12px",
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      {this.state.Subscriptions === undefined ? (
                        <Box style={{ paddingTop: "2vh" }}>
                          No Subscription Taken
                        </Box>
                      ) : (
                        <>
                          {this.state.Subscriptions.map((l, index) => {
                            return (
                              <>
                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{
                                      width: "100%",
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                    }}
                                    paddingX={2}
                                    paddingY={1}
                                  >
                                    <Box>
                                      <Typography
                                        variant="h5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {l.game}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography>{l.academy}</Typography>
                                    </Box>
                                    <Divider
                                      style={{
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        color: "lightgrey",
                                      }}
                                    />
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box
                                        display="flex"
                                        style={{ width: "50%" }}
                                      >
                                        <Box
                                          style={{
                                            width: "40%",
                                            textAlign: "left",
                                            color: "black",
                                          }}
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Fees
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography>: {l.fees}</Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display="flex"
                                        style={{ width: "50%" }}
                                      >
                                        <Box
                                          style={{
                                            width: "40%",
                                            textAlign: "left",
                                            color: "black",
                                          }}
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Transaction Id
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography>
                                            : {l.transaction_id}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                  {/* <Box marginY={4}>
                                    <button
                                      className={
                                        classes.tuitionTimeStatusButton
                                      }
                                    >
                                      <DeleteIcon style={{ color: "white" }} />
                                    </button>
                                  </Box> */}
                                </Grid>
                              </>
                            );
                          })}
                        </>
                      )}
                    </Grid>
                  </Container>
                </>
              )}
              {this.state.page === 1.1 && (
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display="flex" flexDirection="column">
                          <Box>
                            <img
                              style={{ height: "39vh", width: "100%" }}
                              src={this.state.item.photo}
                              alt=""
                            />
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            paddingTop={2}
                          >
                            <Box>
                              <Typography variant="h6" fontWeight="bold">
                                {this.state.item.name}
                              </Typography>
                            </Box>
                            <Box>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: "deepskyblue" }}
                                onClick={() => {
                                  this.setState({ page: 1.2 });
                                }}
                              >
                                Choose Your Game
                              </Button>
                            </Box>
                          </Box>
                          <Box paddingBottom={2}>
                            <Typography>{this.state.item.address}</Typography>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            flexDirection="column"
                            paddingY={2}
                          >
                            <Box>
                              <Typography variant="h6" fontWeight="bold">
                                About Academy
                              </Typography>
                            </Box>
                            <Box>
                              <Typography>
                                {this.state.item.about_academy}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            flexDirection="column"
                            paddingY={2}
                          >
                            <Box>
                              <Typography variant="h6" fontWeight="bold">
                                Successfull Story
                              </Typography>
                            </Box>
                            <Box>
                              <Typography>
                                {this.state.item.successful_story}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            flexDirection="column"
                            paddingY={2}
                          >
                            <Box>
                              <Typography variant="h6" fontWeight="bold">
                                Winning Tournament
                              </Typography>
                            </Box>
                            <Box>
                              <Typography>
                                {this.state.item.winning_tournament}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          {/* <Box
                            display="flex"
                            flexDirection="column"
                            paddingY={2}
                          >
                            <Box>
                              <Typography variant="h6" fontWeight="bold">
                                Tournament
                              </Typography>
                            </Box>
                            <Box>
                              <Typography>CGA ,Mega Series</Typography>
                            </Box>
                            <Box>
                              <Typography>Football</Typography>
                            </Box>
                            <Box>
                              <Typography>Season Summer , 2021</Typography>
                            </Box>
                            <Box>
                              <Typography>Starts 7th aprill</Typography>
                            </Box>
                            <Box>
                              <Typography>Ends 11th september</Typography>
                            </Box>
                          </Box> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}

              {this.state.page === 1.2 && (
                <>
                  <Typography variant="h5" style={{ paddingLeft: "22px" }}>
                    Choose Your Game
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "160px",
                      marginLeft: "23px",
                    }}
                  ></div>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Select Game</p>
                        <FormControl variant="filled" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="selectGame"
                            input={<OutlinedInput />}
                            onChange={(e) => {
                              this.setState({ gameID: e.target.value });
                              localStorage.setItem("gameID", e.target.value);
                            }}
                            value={this.state.gameID}
                          >
                            {this.state.filteredgames.map((l, i) => {
                              return (
                                <MenuItem key={i} value={l.game_id}>
                                  {l.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {<span className="error">{this.state.gameerror}</span>}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Student Name</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            fullWidth
                            value={this.state.Profile.name}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>School Name</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            fullWidth
                            value={this.state.Profile.school}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Class</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            value={this.state.Profile.class}
                            fullWidth
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Date of Birth</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            value={this.state.Profile.dob}
                            fullWidth
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Address</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            value={this.state.Profile.address}
                            fullWidth
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Father's Name</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            value={this.state.Profile.father_name}
                            fullWidth
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Mobile</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="number"
                            value={this.state.Profile.mobile}
                            fullWidth
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ opacity: "0.6" }}>Email</p>
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <TextField
                            name="name"
                            variant="outlined"
                            type="text"
                            value={this.state.Profile.email}
                            disabled
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                          onClick={() => {
                            this.subscribe();
                          }}
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                        >
                          SUBMIT
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container school-cont">
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-3 cont-col offset-1">
              <div className="row">
                <button
                  onClick={() => {
                    this.setState({
                      time: true,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: false,
                      page: 1,
                    });
                  }}
                  className={this.state.time ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> ACADEMY LISTS
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: true,
                      news: false,
                      result: false,
                      principle: false,
                      page: 2,
                    });
                  }}
                  className={this.state.holiday ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> TOURNAMENTS
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: true,
                      result: false,
                      principle: false,
                      page: 3,
                    });
                  }}
                  className={this.state.news ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> NEWS
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: true,
                      principle: false,
                      page: 4,
                    });
                  }}
                  className={this.state.result ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> MY SUBSCRIPTION
                </button>
              </div>
            </div>
            <div className="col-md-8">
              {this.state.page === 1 ? (
                <div>
                  <h5>ACADEMY LISTS</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <div className="container">
                    <div className="row" style={{ paddingTop: "20px" }}>
                      {this.state.AcademyLists.map((l, id) => {
                        return (
                          <div
                            className="col-md-4"
                            style={{ paddingTop: "2vh" }}
                          >
                            <button
                              style={{ border: "none" }}
                              onClick={() => {
                                this.getacademyid(l);
                              }}
                            >
                              {" "}
                              <div
                                className="card"
                                style={{ width: "13rem", height: "auto" }}
                              >
                                <div className="embed-responsive embed-responsive-4by3">
                                  <img
                                    className="card-img-top"
                                    src={l.photo}
                                    alt=""
                                    style={{ height: "30vh", width: "100%" }}
                                  />
                                </div>
                                <div className="card-body">
                                  <h6 className="card-title">{l.name}</h6>
                                </div>
                              </div>
                            </button>
                          </div>
                        );
                      })}
                    </div>

                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.page === 1.1 ? (
                <div>
                  <div className="container">
                    <div className="row">
                      <img
                        style={{ height: "39vh", width: "105vh" }}
                        src={this.state.item.photo}
                      />
                    </div>
                    <div className="row" style={{ paddingTop: "5vh" }}>
                      <div className="col-md-7">
                        <h6>{this.state.item.name}</h6>
                        <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                          {this.state.item.address}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <button
                          onClick={() => {
                            this.setState({ page: 1.2 });
                          }}
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            border: "none",
                            color: "white",
                            height: "35px",
                          }}
                        >
                          Choose Your Game
                        </button>
                      </div>
                    </div>
                    <div className="row" style={{ paddingTop: "5vh" }}>
                      <div className="col-md-11">
                        <h6>About Academy</h6>
                        <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                          {this.state.item.about_academy}
                        </p>
                      </div>
                    </div>
                    <div className="row" style={{ paddingTop: "5vh" }}>
                      <div className="col-md-11">
                        <h6>Successfull Story</h6>
                        <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                          {this.state.item.successful_story}
                        </p>
                      </div>
                    </div>
                    <div className="row" style={{ paddingTop: "5vh" }}>
                      <h6>Winning Tournament</h6>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.item.winning_tournament}
                      </p>
                    </div>
                    <div className="row" style={{ paddingTop: "5vh" }}>
                      <h6>Tournament</h6>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        CGA ,Mega Series
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        Football
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        Season Summer , 2021
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        Starts 7th aprill
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        Ends 11th september
                      </p>
                    </div>

                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.page === 1.2 ? (
                <div>
                  <div className="container" style={{ paddingTop: "5vh" }}>
                    <h5>Choose Your Game</h5>
                    <div
                      style={{
                        height: "3px",
                        width: "80px",
                        textDecoration: "underline",
                        backgroundColor: "lightblue",
                      }}
                    ></div>
                    <div className="row">
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>Select Game</p>
                        <Form.Select
                          onChange={(e) => {
                            this.setState({ gameID: e.target.value });
                            localStorage.setItem("gameID", e.target.value);
                          }}
                          required
                          value={this.state.gameID}
                          aria-label="Default select example"
                        >
                          <option></option>
                          {this.state.game.map((l, i) => {
                            return (
                              <option key={i} value={l.game_id}>
                                {l.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        {<span className="error">{this.state.gameerror}</span>}
                      </div>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>Student Name</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.name}
                            type="name"
                          />
                        </Form>
                      </div>
                    </div>
                    <div className="row" style={{ paddingTop: "3vh" }}>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>School Name</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.school}
                            type="name"
                          />
                        </Form>
                      </div>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>Class</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.class}
                            type="text"
                          />
                        </Form>
                      </div>
                    </div>

                    <div className="row" style={{ paddingTop: "3vh" }}>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>DOB</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.dob}
                            type="date"
                          />
                        </Form>
                      </div>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>Address</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.address}
                            type="address"
                          />
                        </Form>
                      </div>
                    </div>

                    <div className="row" style={{ paddingTop: "3vh" }}>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>Father's Name</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.father_name}
                            type="name"
                          />
                        </Form>
                      </div>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>Mobile Number</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.mobile}
                            type="number"
                          />
                        </Form>
                      </div>
                    </div>
                    <div className="row" style={{ paddingTop: "3vh" }}>
                      <div className="col-md-5">
                        <p style={{ opacity: "0.6" }}>Email Id</p>
                        <Form>
                          <Form.Control
                            disabled
                            defaultValue={this.state.Profile.email}
                            type="email"
                          />
                        </Form>
                      </div>
                    </div>

                    <div className="row" style={{ paddingTop: "5vh" }}>
                      <div className="col">
                        <button
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            border: "none",
                            color: "white",
                          }}
                          onClick={() => {
                            this.subscribe();
                          }}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.page === 2 ? (
                <div>
                  <h5>TOURNAMENTS</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <div className="container" style={{ paddingTop: "10px" }}>
                    {this.state.Tournament.map((l, id) => {
                      return (
                        <div className="row" style={{ paddingTop: "3vh" }}>
                          <div class="col-sm-10">
                            <div class="card">
                              <div class="card-body">
                                <h4>{l.game_academy}</h4>
                                <h5
                                  class="card-title"
                                  style={{ fontSize: "small" }}
                                >
                                  {l.league_name}
                                </h5>
                                <p1 style={{ fontWeight: "bold" }}>Game : </p1>
                                <p1
                                  style={{
                                    fontSize: "smaller",
                                    opacity: "0.6",
                                  }}
                                  class="card-text"
                                >
                                  {l.game}
                                </p1>
                                <br></br>
                                <p1>Season : </p1>
                                <p1
                                  style={{
                                    fontSize: "smaller",
                                    opacity: "0.6",
                                  }}
                                  class="card-text"
                                >
                                  {l.season}
                                </p1>
                                <br></br>
                                <p1>Starts : </p1>
                                <p1
                                  style={{
                                    fontSize: "smaller",
                                    opacity: "0.6",
                                  }}
                                  class="card-text"
                                >
                                  {l.from_date}
                                </p1>
                                <br></br>
                                <p1>Ends : </p1>
                                <p1
                                  style={{
                                    fontSize: "smaller",
                                    opacity: "0.6",
                                  }}
                                  class="card-text"
                                >
                                  {l.to_date}
                                </p1>
                                <br></br>
                                <p1>Prize : </p1>
                                <p1
                                  style={{
                                    fontSize: "smaller",
                                    opacity: "0.6",
                                  }}
                                  class="card-text"
                                >
                                  {l.prize}
                                </p1>
                                <br></br>
                                <p1
                                  style={{
                                    fontSize: "smaller",
                                    fontWeight: "bold",
                                    opacity: "0.6",
                                  }}
                                  class="card-text"
                                >
                                  Rules & Regulations : {l.rule_regulation}
                                </p1>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.page === 3 ? (
                <div>
                  <h5>NEWS</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <div className="container" style={{ paddingTop: "10px" }}>
                    {this.state.News.map((l, id) => {
                      return (
                        <div className="row" style={{ paddingTop: "3vh" }}>
                          <div class="col-sm-10">
                            <div class="card">
                              <div class="card-body">
                                <p1>{l.date}</p1>
                                <h5 class="card-title">{l.title}</h5>
                                <p1 class="card-text">{l.description}</p1>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.page === 4 ? (
                <div>
                  <h5>MY SUBSCRIPTION</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  {this.state.Subscriptions === undefined ? (
                    <div>
                      <h5>{this.state.subsmsg}</h5>
                    </div>
                  ) : (
                    <div>
                      {this.state.Subscriptions.map((l, id) => {
                        return (
                          <div
                            className="container"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="row">
                              <div class="col-sm-10">
                                <div class="card">
                                  <div class="card-body">
                                    <h4
                                      class="card-title"
                                      style={{ fontSize: "smaller" }}
                                    >
                                      {l.academy}
                                    </h4>
                                    <p1
                                      style={{
                                        fontSize: "small",
                                        opacity: "0.8",
                                      }}
                                    >
                                      {l.game}
                                    </p1>
                                    <br></br>
                                    <p1
                                      class="card-text"
                                      style={{
                                        fontSize: "smaller",
                                        opacity: "0.6",
                                      }}
                                    >
                                      Fees -{l.fees}
                                    </p1>
                                    <br></br>
                                    <p1
                                      class="card-text"
                                      style={{
                                        fontSize: "smaller",
                                        opacity: "0.6",
                                      }}
                                    >
                                      Transaction Id: -{l.transaction_id}{" "}
                                    </p1>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-2">
                                <button
                                  className="rounded-circle"
                                  style={{
                                    backgroundColor: "lightgreen",
                                    height: "40px",
                                    width: "40px",
                                    border: "none",
                                  }}
                                >
                                  <AiFillDelete color="white" />
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div> */}
      </>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(GamingAcademy);
