import React, { Component } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillDelete } from "react-icons/ai";
import API from "../components/api";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { withStyles } from "@mui/styles";

class School extends Component {
  state = {
    time: true,
    holiday: false,
    news: false,
    result: false,
    principle: false,
    page: 1,
    timetabledata: [],
    holidaydata: [],
    principaldata: [],
    newsdata: [],
  };
  componentDidMount() {
    this.getTimetable();
    this.getholiday();
    this.getprincipalmessage();
    this.getnews();
  }
  getnews() {
    const params = {
      school_id: localStorage.getItem("school_id(student)"),
    };
    var that = this;
    API.post("StudentApi/news", params)
      .then(function (response) {
        console.log(response);
        that.setState({ newsdata: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getprincipalmessage() {
    const params = {
      school_id: localStorage.getItem("school_id(student)"),
    };
    var that = this;
    API.post("StudentApi/principalMessage", params)
      .then(function (response) {
        console.log(response);
        that.setState({ principaldata: response.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getholiday() {
    const params = {
      school_id: localStorage.getItem("school_id(student)"),
    };
    var that = this;
    API.post("StudentApi/holidays", params)
      .then(function (response) {
        console.log(response.data.data.time_table);
        that.setState({ holidaydata: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getTimetable() {
    const params = {
      school_id: localStorage.getItem("school_id(student)"),
    };
    var that = this;
    API.post("StudentApi/time_tables", params)
      .then(function (response) {
        console.log(response.data.data.time_table);
        that.setState({ timetabledata: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1>SCHOOL</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              Student
            </Link>{" "}
            | School
          </p>
        </div>
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: true,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: false,
                      page: 1,
                    });
                  }}
                  className={
                    this.state.time ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>TIME TABLE</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: true,
                      news: false,
                      result: false,
                      principle: false,
                      page: 2,
                    });
                  }}
                  className={
                    this.state.holiday ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>HOLIDAY LIST</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: true,
                      result: false,
                      principle: false,
                      page: 3,
                    });
                  }}
                  className={
                    this.state.news ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>NEWS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: true,
                      principle: false,
                      page: 4,
                    });
                  }}
                  className={
                    this.state.result ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>SCHOOL RESULT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: true,
                      page: 5,
                    });
                  }}
                  className={
                    this.state.principle ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>PRINCIPAL MESSAGE</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Container>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    {this.state.page === 1 && (
                      <>
                        <Typography variant="h5">TIME TABLE</Typography>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "142px",
                            marginBottom: "12px",
                          }}
                        ></div>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ backgroundColor: "lightgray" }}
                              >
                                Day
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Start Time
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                End Time
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.timetabledata.map((l) => (
                              <TableRow hover>
                                <TableCell>{l.day}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {l.start_time}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {l.end_time}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    )}
                    {this.state.page === 2 && (
                      <>
                        <Typography variant="h5">HOLIDAY LIST</Typography>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "160px",
                            marginBottom: "12px",
                          }}
                        ></div>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ backgroundColor: "lightgray" }}
                              >
                                Holiday Name
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "lightgray",
                                  textAlign: "center",
                                }}
                              >
                                Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.holidaydata.map((l) => (
                              <TableRow hover>
                                <TableCell>{l.name}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {l.date}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    )}
                    {this.state.page === 3 && (
                      <>
                        <Typography variant="h5">NEWS</Typography>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "75px",
                            marginBottom: "12px",
                          }}
                        ></div>
                        <Box
                          display="flex"
                          flexDirection="column"
                          style={{ width: "100%" }}
                          marginY={2}
                        >
                          {this.state.newsdata === undefined ? (
                            <Box>No News Found</Box>
                          ) : (
                            <>
                              {this.state.newsdata.map((news, index) => (
                                <>
                                  <Box>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      {news?.date}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {news?.title}
                                    </Typography>
                                    <Typography>
                                      {" "}
                                      {news?.description}
                                    </Typography>
                                  </Box>
                                  {index !==
                                    Number(this.state.newsdata.length) - 1 && (
                                    <Divider
                                      style={{
                                        marginBottom: "8px",
                                        marginTop: "8px",
                                      }}
                                    />
                                  )}
                                </>
                              ))}
                            </>
                          )}
                        </Box>
                      </>
                    )}
                    {this.state.page === 4 && (
                      <>
                        <Typography variant="h5">SCHOOL RESULT</Typography>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "205px",
                            marginBottom: "12px",
                          }}
                        ></div>
                        <Typography variant="h2">A+</Typography>
                        <Box display="flex" flexDirection="column">
                          <Box>
                            <Typography>
                              With supporting text below as a natural lead-in to
                              additional content. With supporting text below as
                              a natural lead-in to additional content.
                            </Typography>
                          </Box>
                          <Box>
                            <Typography>
                              With supporting text below as a natural lead-in to
                              additional content. With supporting text below as
                              a natural lead-in to additional content.
                            </Typography>
                          </Box>
                          <Box>
                            <Typography>
                              With supporting text below as a natural lead-in to
                              additional content. With supporting text below as
                              a natural lead-in to additional content.
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}

                    {this.state.page === 5 && (
                      <>
                        <Typography variant="h5">PRINCIPAL MESSAGE</Typography>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "255px",
                            marginBottom: "12px",
                          }}
                        ></div>
                        <Box
                          display="flex"
                          border={1}
                          borderColor="lightgrey"
                          style={{ borderRadius: "4px" }}
                        >
                          <Box>
                            <CardMedia
                              component="img"
                              sx={{ width: 200, height: 200 }}
                              image={this.state.principaldata.photo}
                              alt="Live from space album cover"
                            />
                          </Box>
                          <Box paddingX={2} alignSelf="center">
                            <Typography variant="h6">
                              {this.state.principaldata.message}
                            </Typography>
                            <Typography>
                              {this.state.principaldata.description}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container school-cont">
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-3 cont-col offset-1">
              <div className="row">
                <button
                  onClick={() => {
                    this.setState({
                      time: true,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: false,
                      page: 1,
                    });
                  }}
                  className={this.state.time ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> TIME TABLE
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: true,
                      news: false,
                      result: false,
                      principle: false,
                      page: 2,
                    });
                  }}
                  className={this.state.holiday ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> HOLIDAY LISTS
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: true,
                      result: false,
                      principle: false,
                      page: 3,
                    });
                  }}
                  className={this.state.news ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> NEWS
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: true,
                      principle: false,
                      page: 4,
                    });
                  }}
                  className={this.state.result ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> SCHOOL RESULT
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  onClick={() => {
                    this.setState({
                      time: false,
                      holiday: false,
                      news: false,
                      result: false,
                      principle: true,
                      page: 5,
                    });
                  }}
                  className={this.state.principle ? "clicked" : "notclickedyet"}
                  style={{ height: "50px", width: "300px", border: "none" }}
                >
                  <FaLongArrowAltRight /> PRINCIPAL MESSAGE
                </button>
              </div>
            </div>
            <div className="col-md-8">
              {this.state.page === 1 ? (
                <div>
                  <h5>TIME TABLE</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <div className="container">
                    {this.state.timetabledata.map((l, i) => {
                      return (
                        <div className="row">
                          <div className="col-md-2">
                            <div className="row" style={{ paddingTop: "15px" }}>
                              {l.day}
                            </div>
                          </div>
                          <div className="col-md-2 offset-6">
                            <div className="row" style={{ paddingTop: "15px" }}>
                              {l.start_time} - {l.end_time}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.page === 2 ? (
                <div>
                  <h5>HOLIDAY LISTS</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <div className="container">
                    {this.state.holidaydata.map((l, i) => {
                      return (
                        <div className="row">
                          <div className="col-md-4" style={{ flex: "1" }}>
                            <div className="row" style={{ paddingTop: "15px" }}>
                              {l.date}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row" style={{ paddingTop: "15px" }}>
                              {l.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.page === 3 ? (
                <div>
                  <h5>NEWS</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <div className="container" style={{ paddingTop: "10px" }}>
                    {this.state.newsdata.map((l, id) => {
                      return (
                        <div className="row">
                          <div class="col-sm-10">
                            <div class="card">
                              <div class="card-body">
                                <p1>{l.date}</p1>
                                <h5 class="card-title">{l.title}</h5>
                                <p1 class="card-text">{l.description}</p1>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.page === 4 ? (
                <div>
                  <h5>SCHOOL RESULT</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <br></br>
                  <h2>A+</h2>
                  <div>
                    <p1 style={{ paddingTop: "15px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p1>
                  </div>
                  <div>
                    <p2 style={{ paddingTop: "10px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p2>
                  </div>
                  <div>
                    <p3 style={{ paddingTop: "10px" }}>
                      {" "}
                      With supporting text below as a natural lead-in to
                      additional content. With supporting text below as a
                      natural lead-in to additional content.
                    </p3>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.page === 5 ? (
                <div>
                  {" "}
                  <h5>PRINCIPAL MESSAGE</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <div
                    className="container-fluid"
                    style={{ paddingTop: "10px" }}
                  >
                    <div className="row">
                      <div className="col-10 mt-3">
                        <div className="card">
                          <div className="card-horizontal">
                            <div className="img-square-wrapper">
                              <img src={this.state.principaldata.photo} />
                            </div>
                            <div className="card-body">
                              <p1 class="card-text">
                                {this.state.principaldata.description}
                              </p1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div> */}
      </>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white",
  },
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(School);
