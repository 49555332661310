import React, { Component, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Progress } from 'reactstrap'
import { Form, Check } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../../provider/useWindowSize'
import API from "../../components/api";
import DeleteIcon from '@mui/icons-material/Delete';

const MonthlyExam = ({ classes }) => {
  const [nowPercent, setNowPercent] = useState(0)
  const [questionSubmited, setQuestionSubmited] = useState(0)
  const [questionCount, setQuestionCount] = useState(0)
  const [percentInterval, setPercentInterval] = useState(0)
  const [isExamStart, setIsExamStart] = useState(false)




  const [unitTest, setUnitTest] = useState([])
  const [unitTestQuestionList, setUnitTestQuestionList] = useState([])
  const [unitTestSingleQuestion, setUnitTestSingleQuestion] = useState("")
  const [optionA, setOptionA] = useState("")
  const [optionB, setOptionB] = useState("")
  const [optionC, setOptionC] = useState("")
  const [optionD, setOptionD] = useState("")
  const [question, setQuestion] = useState("")
  const [questionNo, setQuestionNo] = useState("")
  const [questionId, setQuestionId] = useState("")
  const [questionIndex, setQuestionIndex] = useState(0)


  const [questionAnswer, setQuestionAnswer] = useState("")
  const [questionAnswerSheet, setQuestionAnswerSheet] = useState([])
  const [singleAnswer, setSingleAnswer] = useState("")
  const [buttonVisible, setButtonVisible] = useState(true)
  const [submitButtonVisible, setSubmitButtonVisible] = useState(true)

  const [scoreCard, setScoreCard] = useState("")
  const [questionSection, setQuestionSection] = useState(true)
  const [postExamResult, setPostExamResult] = useState(false)
  const [scoreCardSection, setScoreCardSection] = useState(false)
  const [cancelExamButton, setCancelExamButton] = useState(false)

  // const [scoreCardSection, setScoreCardSection] = useState(false)


  const [examSolution, setExamSolution] = useState([])
  const [myExam, setMyExam] = useState([])
  const [examType, setExamType] = useState("")










  const getExamType = (e) => {
    setExamType(e.target.value)
  }
  const cancelExam = () => {
    setButtonVisible(true)
    setIsExamStart(false)
    setExamType("")
    setQuestionSubmited(0)
    setQuestionCount(0)
    setNowPercent(0)
    setQuestionIndex(0)
    setQuestionAnswer("")
      setOptionA(unitTestQuestionList[0].option_A)
      setOptionB(unitTestQuestionList[0].option_B)
      setOptionC(unitTestQuestionList[0].option_C)
      setOptionD(unitTestQuestionList[0].option_D)
      setQuestion(unitTestQuestionList[0].question)
      setQuestionNo(unitTestQuestionList[0].question_no)
      setQuestionId(unitTestQuestionList[0].question_id)
      setSingleAnswer("")
      setQuestionAnswerSheet([])
  }

  const startExam = () => {
    setIsExamStart(true)
  }



  useEffect(() => {
    let payloadExamlist = {
      user_id: localStorage.getItem("userID")
    }
    API.post("TeacherApi/onlineExamList", payloadExamlist).then(res => {
      if (res.data.result === 1) {
        setUnitTest(res.data.Unit)
      }
    })
    unitQuestionList()

  }, [])

  const unitQuestionList = () => {
    let payload = {
      exam_id: 1,
      user_id: localStorage.getItem("userID"),
      unique_id: ""
    }
    let data = []
    let length = 0
    let interval = 0

    API.post("TeacherApi/allQuestionList", payload).then(res => {
      if (res.data.result === 1) {
        setUnitTestQuestionList(res.data.data)
        setUnitTestSingleQuestion(res.data.data[0])
        setOptionA(res.data.data[0].option_A)
        setOptionB(res.data.data[0].option_B)
        setOptionC(res.data.data[0].option_C)
        setOptionD(res.data.data[0].option_D)
        setQuestion(res.data.data[0].question)
        setQuestionNo(res.data.data[0].question_no)
        setQuestionId(res.data.data[0].question_id)
        data = res.data.data
        length = data.length
        interval = 100 / length
        setPercentInterval(100 / length)


        console.log("allQuestionList", res.data.data);
      }
    })
  }

  const getQuestionAnswer = (e) => {
    console.log("answer123", e.target.value);
    setQuestionAnswer(e.target.value)
    setSingleAnswer({
      question_id: questionId,
      given_answer: e.target.value
    })
  }

  // useEffect(() => {
  //   setQuestionAnswer("")
  //   setOptionA(unitTestQuestionList[questionIndex]?.option_A)
  //   setOptionB(unitTestQuestionList[questionIndex]?.option_B)
  //   setOptionC(unitTestQuestionList[questionIndex]?.option_C)
  //   setOptionD(unitTestQuestionList[questionIndex]?.option_D)
  //   setQuestion(unitTestQuestionList[questionIndex]?.question)
  //   setQuestionNo(unitTestQuestionList[questionIndex]?.question_no)
  //   setQuestionId(unitTestQuestionList[questionIndex]?.question_id)
  //   setSingleAnswer("")
  // }, [questionIndex])

  const arrangeAnswer = () => {
    let index = questionIndex + 1
    setQuestionIndex(questionIndex + 1)
    questionAnswerSheet.push(singleAnswer)
    setNowPercent(nowPercent + percentInterval)
    setQuestionCount(questionCount + 1)
    setQuestionSubmited(nowPercent + percentInterval)
    if (index < unitTestQuestionList.length) {
      setQuestionAnswer("")
      setOptionA(unitTestQuestionList[index].option_A)
      setOptionB(unitTestQuestionList[index].option_B)
      setOptionC(unitTestQuestionList[index].option_C)
      setOptionD(unitTestQuestionList[index].option_D)
      setQuestion(unitTestQuestionList[index].question)
      setQuestionNo(unitTestQuestionList[index].question_no)
      setQuestionId(unitTestQuestionList[index].question_id)
      setSingleAnswer("")
    } else {
      setButtonVisible(false)
      setSubmitButtonVisible(false)
    }
  }

  const saveExamAnswer = () => {
    let payload = {
      exam_id: 1,
      user_id: localStorage.getItem("userID"),
      data: questionAnswerSheet
    }

    API.post("TeacherApi/saveExam", payload).then(res => {
      if (res.data.result === 1) {
        console.log("SuccessResult", res.data);
        getScroreCard(res.data.unique_id)
        getExamSolution(res.data.unique_id)
        getMyExam()
        setSubmitButtonVisible(true)
        setCancelExamButton(true)

      }
    })
  }
  const getMyExam = () => {
    let payload = {
      user_id: localStorage.getItem("userID")
    }
    API.post("TeacherApi/myExam", payload).then(res => {
      if (res.data.result === 1) {
        console.log("SuccessResult", res.data);
        setMyExam(res.data.data)
      }
    })
  }
  const getScroreCard = (id) => {
    let payload = {
      exam_id: 1,
      user_id: localStorage.getItem("userID"),
      unique_id: id
    }
    API.post("TeacherApi/scorecard", payload).then(res => {
      if (res.data.result === 1) {
        console.log("SuccessResult", res.data);
        setScoreCard(res.data.data)
        setQuestionSection(false)
        setScoreCardSection(true)
      }
    })
  }

  const getExamSolution = (id) => {
    let payload = {
      exam_id: 1,
      user_id: localStorage.getItem("userID"),
      unique_id: id
    }
    API.post("TeacherApi/viewSolution", payload).then(res => {
      if (res.data.result === 1) {
        console.log("SuccessResult", res.data);
        setExamSolution(res.data.data)
      }
    })
  }


  const skipQuestion = () => {
    let index = questionIndex + 1

    setQuestionIndex(questionIndex + 1)
    console.log("questionIndex", questionIndex);

    // setSingleAnswer({
    //   question_id: questionId,
    //   given_answer: ""
    // })
    questionAnswerSheet.push({
      question_id: questionId,
      given_answer: ""
    })

    setNowPercent(nowPercent + percentInterval)
    setQuestionCount(questionCount + 1)
    setQuestionSubmited(nowPercent + percentInterval)


    if (index < unitTestQuestionList.length) {
      setQuestionAnswer("")
      setOptionA(unitTestQuestionList[index].option_A)
      setOptionB(unitTestQuestionList[index].option_B)
      setOptionC(unitTestQuestionList[index].option_C)
      setOptionD(unitTestQuestionList[index].option_D)
      setQuestion(unitTestQuestionList[index].question)
      setQuestionNo(unitTestQuestionList[index].question_no)
      setQuestionId(unitTestQuestionList[index].question_id)
      setSingleAnswer("")
    } else {
      setButtonVisible(false)
      setSubmitButtonVisible(true)
    }

  }

  const showResult = () => {

    setQuestionSection(false)
    setScoreCardSection(false)
    setPostExamResult(true)
  }


  console.log("unitTestQuestionList", unitTestQuestionList);

  console.log("questionIndex", questionIndex);
  console.log("questionAnswerSheet", questionAnswerSheet);
  console.log("questionAnswer", questionAnswer);
  console.log("optionA", optionA);
  console.log("optionB", optionB);
  console.log("optionC", optionC);
  console.log("optionD", optionD);
  console.log("questionNo", questionNo);
  console.log("questionId", questionId);
  return (
    <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
        <h1> MONTHLY EXAM</h1>
        <p><Link href="/" style={{ color: "white", textDecoration: "none" }}>Home</Link> | Teacher | Subject | Monthly Exam</p>

      </div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box display="flex" flexDirection="column">
              {isExamStart ?
                <><Box border={1} padding={2}>
                  <Progress
                    value={nowPercent ? nowPercent : 0}
                    color="success"
                    style={{
                      height: "40px",
                      top: "25px"
                    }}
                  >{nowPercent ? nowPercent : 0}%</Progress>
                </Box>
                  <Box display="flex" flexDirection="column" padding={2} marginY={2} border={1}>
                    <Box display="flex">
                      <Box style={{ width: "70%" }}>
                        <Progress
                          value={questionSubmited}
                          color="success"
                          style={{
                            height: "40px",
                            top: "25px"
                          }}

                        >{questionCount ? questionCount : 0}/{unitTestQuestionList.length}</Progress>
                      </Box>
                      <Box style={{ width: "30%" }} justifyContent="flex-end">

                        <Button variant="contained" style={{ backgroundColor: "deepskyblue", float: "right" }} onClick={cancelExam} disabled={cancelExamButton}>Cancel Exam</Button>


                      </Box>
                    </Box>
                    <Box marginY={2}>
                      {questionSection &&
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Q{questionNo}. {question}</FormLabel>

                          <RadioGroup
                            aria-label="gender"
                            name="radio-buttons-group"
                            value={questionAnswer}
                            onChange={getQuestionAnswer}
                          >
                            <FormControlLabel value="A" control={<Radio />} label={optionA} />
                            <FormControlLabel value="B" control={<Radio />} label={optionB} />
                            <FormControlLabel value="C" control={<Radio />} label={optionC} />
                            <FormControlLabel value="D" control={<Radio />} label={optionD} />
                          </RadioGroup>
                        </FormControl>}
                      {postExamResult &&
                        <Box display="flex" flexDirection="column">
                          {examSolution.map(solution =>
                            <Box>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Q{solution?.question_no}. {solution?.question}</FormLabel>

                                <RadioGroup
                                  aria-label="gender"
                                  name="radio-buttons-group"
                                  defaultValue={solution?.given_answer}
                                  onChange={getQuestionAnswer}
                                >
                                  <FormControlLabel value="A" control={<Radio />} label={solution?.option_A} />
                                  <FormControlLabel value="B" control={<Radio />} label={solution?.option_B} />
                                  <FormControlLabel value="C" control={<Radio />} label={solution?.option_C} />
                                  <FormControlLabel value="D" control={<Radio />} label={solution?.option_D} />
                                </RadioGroup>
                              </FormControl>
                              {solution?.given_answer === solution?.correct_answer ?
                                <Box style={{ width: "100%", backgroundColor: "green" }} paddingX={2} marginBottom={2}><Typography>{solution?.given_answer}</Typography></Box>
                                :
                                <Box style={{ width: "100%", backgroundColor: "red" }} paddingX={2}><Typography>{solution?.given_answer}</Typography></Box>
                              }

                            </Box>
                          )}
                        </Box>}
                      {scoreCardSection && <Box display="flex" flexDirection="column">
                        <Box display="flex" paddingY={1} paddingX={2} justifyContent="center" >
                          <Box> <Typography variant="h6">Score Card</Typography></Box>


                        </Box>
                        <Box display="flex" paddingY={1} paddingX={2} justifyContent="space-between" >
                          <Box> <Typography>Total Question</Typography></Box>
                          <Box><Typography>{scoreCard.total_no_of_question}</Typography></Box>


                        </Box>
                        <Divider />
                        <Box display="flex" justifyContent="space-between" paddingY={1} paddingX={2}>
                          <Box> <Typography>Correct</Typography></Box>
                          <Box><Typography>{scoreCard.correct_count}</Typography></Box>


                        </Box>
                        <Divider />
                        <Box display="flex" justifyContent="space-between" paddingY={1} paddingX={2}>
                          <Box> <Typography>Incorrect</Typography></Box>
                          <Box><Typography>{scoreCard.incorrect_count}</Typography></Box>


                        </Box>
                        <Divider />
                        <Box display="flex" justifyContent="space-between" paddingY={1} paddingX={2}>
                          <Box> <Typography>Total Skip</Typography></Box>
                          <Box><Typography>{scoreCard.unanswered_count}</Typography></Box>


                        </Box>
                        <Divider />
                        <Box display="flex" justifyContent="center" paddingY={1}>
                          <Box> <Button variant="contained" style={{ backgroundColor: "deepskyblue" }} onClick={showResult}>Show Result</Button></Box>


                        </Box>
                      </Box>
                      }
                    </Box>
                    {buttonVisible && <Box display="flex">
                      <Box>
                        {questionAnswer ? <Button variant="contained" style={{ backgroundColor: "deepskyblue" }} onClick={arrangeAnswer}>SUBMIT</Button> : <Button variant="contained" disabled >SUBMIT</Button>}

                      </Box>
                      <Box marginX={2}>
                        <Button variant="contained" style={{ backgroundColor: "lightgrey" }} onClick={skipQuestion}>SKIP</Button>
                      </Box>

                    </Box>}
                  </Box>
                </>
                :
                <Box display="flex" flexDirection="column" border={1} paddingY={1} paddingX={2}>
                  <Box paddingY={1}>
                    <Typography variant="h6">Please choose exam type</Typography>
                  </Box>
                  <Box paddingY={1}>
                    <FormControl component="fieldset">

                      <RadioGroup
                        aria-label="gender"
                        name="radio-buttons-group"
                        onChange={getExamType}
                        row
                      >
                        <FormControlLabel value="unit" control={<Radio />} label="Unit Test" />
                        <FormControlLabel value="phase" control={<Radio />} label="Phase Test" />
                        <FormControlLabel value="mock" control={<Radio />} label="Mock Test" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box justifyContent="center" paddingY={1}>
                    <Box>{examType ? <Button variant="contained" style={{ backgroundColor: "deepskyblue" }} onClick={startExam}>Start Exam</Button> : <Button variant="contained" disabled >Start Exam</Button>}</Box>
                  </Box>
                </Box>
              }
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box display="flex" flexDirection="column">
              <Box style={{ width: "100%", height: "71px", backgroundColor: "deepskyblue" }} display="flex">
                <Box marginX={4} style={{ alignSelf: "center", color: "white" }}>
                  <FaLongArrowAltRight />
                </Box>
                <Box style={{ alignSelf: "center", color: "white" }}>
                  <Typography>EXAM DETAILS</Typography>
                </Box>

              </Box>
              <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                <Box>
                  <Typography>Skills</Typography>

                </Box>
                <Box>
                  <Typography>{(myExam[0]?.exam_name) ? myExam[0]?.exam_name : "NA"}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                <Box>
                  <Typography>Marks</Typography>
                </Box>
                <Box>
                  <Typography>{(myExam[0]?.score) ? myExam[0]?.score : "NA"}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                <Box>
                  <Typography>Percentage</Typography>
                </Box>
                <Box>
                  <Typography>{(myExam[0]?.percentage) ? myExam[0]?.percentage : "NA"}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                <Box>
                  <Typography>Performance</Typography>
                </Box>
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>{(myExam[0]?.performance) ? myExam[0]?.performance : "NA"}</Typography>
                </Box>
              </Box>
              <Box padding={2}>
                <Box>
                  <Button variant="contained" style={{ backgroundColor: "deepskyblue" }} disabled={submitButtonVisible} onClick={saveExamAnswer}>SUBMIT EXAM</Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <div className="container" style={{ paddingTop: "30px" }}>
        <div className="row">
          <div className="col-md-6 offset-1">
            <div
              className="row"
              style={{ border: "1px solid lightgrey", height: "90px" }}
            >
              <div>
                <ProgressBar
                  now={now}
                  label={`${now}%`}
                  bar
                  variant="success"
                  style={{
                    height: "40px",
                    top: "25px",
                    position: "relative",
                  }}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                border: "1px solid lightgrey",
                position: "relative",
                top: "20px",
              }}
            >
              <div
                className="col-md-3"
                style={{ paddingBottom: "20px", paddingTop: "20px" }}
              >
                <button
                  style={{
                    height: "50px",
                    width: "140px",
                    backgroundColor: "lightgreen",
                    border: "none",
                    color: "white",
                  }}
                >
                  <h5>3/25</h5>
                </button>
              </div>

              <div
                className="col-md-6"
                style={{ paddingBottom: "20px", paddingTop: "35px" }}
              >
                <p style={{ fontSize: "smaller" }}>
                  1 Right and 1 Wrong answer
                </p>
              </div>
              <div className="row">
                <h6 style={{}}>
                  Which of the following is not a lorem ipsum product is not a
                  lorem ipsum product?
                </h6>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="OPTION 1"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    type="radio"
                    label="OPTION 2"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  />
                  <Form.Check
                    type="radio"
                    label="OPTION 3"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios3"
                  />
                  <Form.Check
                    type="radio"
                    label="OPTION 4"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios4"
                  />
                </Form.Group>
              </div>
              <div
                className="row"
                style={{ paddingTop: "25px", paddingBottom: "30px" }}
              >
                <div className="col-md-2">
                  <button
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      border: "none",
                      color: "white",
                      height: "30px",
                      width: "80px",
                    }}
                  >
                    SUBMIT
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    style={{ backgroundColor: "lightgrey", border: "none" }}
                  >
                    SKIP
                  </button>
                </div>
              </div>
            </div>
            <div style={{ height: "80px" }}></div>
          </div>
          <div className="col-md-4" style={{ paddingLeft: "25px" }}>
            <div
              className="row"
              style={{
                backgroundColor: "rgb(24, 169, 218)",
                paddingTop: "20px",
              }}
            >
              <p
                style={{
                  color: "white",
                  positon: "relative",
                  top: "15px",
                  left: "10px",
                }}
              >
                <FaLongArrowAltRight />
                EXAM DETAILS
              </p>
            </div>
            <div
              className="row"
              style={{
                borderBottom: "1px solid lightgrey",
                paddingTop: "10px",
              }}
            >
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>Skills</p>
              </div>
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>Monthly Exam</p>
              </div>
            </div>
            <div
              className="row"
              style={{
                borderBottom: "1px solid lightgrey",
                paddingTop: "10px",
              }}
            >
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>Marks</p>
              </div>
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>20</p>
              </div>
            </div>
            <div
              className="row"
              style={{
                borderBottom: "1px solid lightgrey",
                paddingTop: "10px",
              }}
            >
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>Percentage</p>
              </div>
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>75%</p>
              </div>
            </div>
            <h5 style={{ fontSize: "small" }}>You Must Study Harder !</h5>

            <div className="col" style={{ paddingTop: "20px" }}>
              {" "}
              <button
                style={{
                  backgroundColor: "rgb(24, 169, 218)",
                  border: "none",
                  color: "white",
                  height: "35px",
                }}
              >
                UPLOAD ANSWER SHEET
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  // tuitionTimeStatusButton: {
  //   backgroundColor: "lightgreen",
  //   border: "none",
  //   borderRadius: "20px",
  //   height: "40px",
  //   width: "40px",
  //   marginRight: "4px"
  // },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(MonthlyExam);
