import React, { Component } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent
} from '@mui/material'
import { withStyles } from '@mui/styles';
import API from "../components/api";

class SubjectUser extends Component {
  state = {
    subject: "",
    testexam: false,
    Subjectdata: [],
    page: 1,
    subjectid: "",
    chapterdata: [],
    chapterid: "",
    sideNavIndex: 0
  };
  componentDidMount() {
    this.getSubjects();
  }
  getChapterdetails(l) {
    console.log(l.chapter_id);
    this.setState({ chapterid: l.chapter_id });
    localStorage.setItem("chapterID", l.chapter_id);
  }
  getchapters(l) {
    const params = {
      subject_id: l.subject_id,
    };

    var that = this;
    API.post("StudentApi/chapters", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          chapterdata: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getSubjects() {
    const params = {
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("StudentApi/subjects", params)
      .then(function (response) {
        console.log(response);
        that.setState({
          Subjectdata: response.data.data,
        });
        that.getchapters(response.data.data[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  Subjectchoose(l, index) {
    this.setState({
      subjectid: l.subject_id,
      testexam: false,
      subject: l.subject_id,
      page: 1,
      sideNavIndex: index
    });
    localStorage.setItem("subjectId", l.subject_id);
    console.log("subjectid", l.subject_id);
    this.getchapters(l);
  }

  getIndex(index) {
    this.setState({ sideNavIndex: index })
  }


  render() {
    const { classes } = this.props
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>SUBJECT</h1>
          <p>
            {" "}
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              {" "}
              Student
            </Link>{" "}
            | Subject
          </p>
        </div>
        <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box display="flex" flexDirection="column">
                {this.state.Subjectdata.map((l, index) => {
                  return (
                    <Box
                      marginTop={1}
                      display="flex"
                      onClick={() => {
                        this.Subjectchoose(l, index);
                      }}
                      className={this.state.sideNavIndex === index ? classes.cliked : classes.notclicked}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>{l.name}</Typography>
                      </Box>
                    </Box>
                  );
                })}


              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              {<>
                <Typography variant="h5" style={{ paddingLeft: "22px" }}>YOUR CHAPTER</Typography>
                <div
                  style={{
                    backgroundColor: "rgb(24, 169, 218)",
                    height: "2px",
                    width: "160px",
                    marginLeft: "23px"
                  }}>
                </div>
                {this.state.chapterdata.length > 0 &&
                  <Container >
                    <Grid container spacing={2} marginY={2}>
                      {this.state.chapterdata.map((l, id) => {
                        return (
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Link href="/subjectdetail" style={{ textDecoration: "none" }}>
                              <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => {
                                  this.getChapterdetails(l);
                                }}
                              >
                                {l.name}
                              </Button>
                            </Link>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Container>}
                {this.state.chapterdata.length === 0 &&
                  <Container >
                    <Grid container spacing={2} marginY={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display="flex" justifyContent="center">
                          <Box>
                            <Typography>Data Not Found</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>}

              </>}
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container">
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-3 cont-col offset-1">
              {this.state.Subjectdata.map((l, id) => {
                return (
                  <div className="row">
                    <Button
                      variant="none"
                      onClick={() => {
                        this.Subjectchoose(l);
                      }}
                      style={{
                        height: "50px",
                        width: "300px",
                        border: "none",
                      }}
                    >
                      <FaLongArrowAltRight /> {l.name}
                    </Button>
                  </div>
                );
              })}
            </div>
            {this.state.subject === this.state.subjectid ? (
              <div className="col-md-8">
                <h5>YOUR CHAPTER</h5>
                <div
                  style={{
                    height: "3px",
                    width: "80px",
                    textDecoration: "underline",
                    backgroundColor: "lightblue",
                  }}
                ></div>

                <div className="container">
                  {this.state.chapterdata.map((l, id) => {
                    return (
                      <div className="row">
                        <div className="col-md-3">
                          <div className="row" style={{ paddingTop: "15px" }}>
                            <Link href="/subjectdetail">
                              <Button
                                variant="none"
                                style={{
                                  border: "1px solid lightgrey",
                                  textAlign: "left",
                                }}
                                onClick={() => {
                                  this.getChapterdetails(l);
                                }}
                              >
                                {l.name}
                              </Button>
                            </Link>
                          </div>{" "}
                        </div>
                        <div className="col-md-3 offset-5">
                          <div className="row" style={{ paddingTop: "15px" }}>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.math ? <div className="col-md-8"></div> : ""}
            {this.state.pol ? <div className="col-md-8"></div> : ""}
            {this.state.physics ? <div className="col-md-8"></div> : ""}
            {this.state.chemistry ? <div className="col-md-8"></div> : ""}
            {this.state.testexam ? <div className="col-md-8"></div> : ""}
          </div>
        </div> */}
      </>
    );
  }
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white"
  }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(SubjectUser)
