import React, { Component } from "react";
import { AiFillDelete } from "react-icons/ai";
// import { Router, Link } from "@reach/router"
import { Link } from "@mui/material";
import API from "../components/api";

class CheckOut extends Component {
  state = {
    fees: "",
    Profile: "",
  };
  componentDidMount() {
    const tuitionfees = localStorage.getItem("tuitionfee");
    // console.log("tid", tuition_id);
    this.setState({ fees: tuitionfees });
    this.getProfilename();
  }

  getProfilename() {
    var that = this;
    API.post("StudentApi/profile", { user_id: localStorage.getItem("userID") })
      .then(function (response) {
        console.log(response);
        that.setState({
          Profile: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> TEACHERS</h1>
          <p>
            {" "}
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home{" "}
            </Link>
            |{" "}
            <Link
              href="/userprofile"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              Student{" "}
            </Link>
            |{" "}
            <Link
              href="/teachers"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              Teacher{" "}
            </Link>{" "}
            | Book Seat
          </p>
        </div>
        <div
          className="container"
          style={{ paddingTop: "30px", paddingLeft: "20vh" }}
        >
          <div
            className="row align-items-center"
            style={{
              paddingLeft: "5vh",
              border: "1px solid lightgrey",
              marginRight: "20vh",
            }}
          >
            <div className="col-md-2 offset-1">
              <h6>Student Name</h6>
            </div>
            <div className="col-md-2">
              <h6>Class</h6>
            </div>
            <div className="col-md-2">
              <h6>Tuition Fee</h6>
            </div>
            {/* <div className="col-md-2">
              <h6>Remove</h6>
            </div> */}
            <div className="col-md-2">
              <h6>Total Ammount</h6>
            </div>
          </div>

          <div
            className="row"
            style={{ paddingTop: "15px", paddingRight: "3vh" }}
          >
            <div className="col-md-2 offset-1">
              <div className="row">
                <div className="col-md-4 ">
                  <img
                    style={{ height: "50px", width: "50px" }}
                    src={this.state.Profile.photo}
                  />
                </div>
                <div className="col-md-8">
                  <p
                    style={{
                      position: "relative",
                      top: "10px",
                      fontSize: "smaller",
                      opacity: "0.7",
                    }}
                  >
                    {this.state.Profile.name}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <p
                style={{
                  position: "relative",
                  top: "10px",
                  fontSize: "smaller",
                  opacity: "0.7",
                }}
              >
                {this.state.Profile.class}
              </p>
            </div>
            <div className="col-md-2">
              <p
                style={{
                  position: "relative",
                  top: "10px",
                  fontSize: "smaller",
                  opacity: "0.7",
                }}
              >
                {this.state.fees}
              </p>
            </div>
            {/* <div className="col-md-2">
              <button
                className="rounded-circle"
                style={{
                  height: "35px",
                  width: "35px",
                  border: "none",
                  backgroundColor: "lightgreen",
                }}
              >
                <AiFillDelete color="white" />
              </button>
            </div> */}
            <div className="col-md-2">
              <p
                style={{
                  position: "relative",
                  top: "10px",
                  fontSize: "smaller",
                  opacity: "0.7",
                }}
              >
                {this.state.fees}
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{ paddingTop: "25px", paddingRight: "3vh" }}
          >
            <div className="col-md-6 offset-1">
              <h6>Subtotal</h6>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  position: "relative",
                  top: "10px",
                  fontSize: "smaller",
                  opacity: "0.7",
                }}
              >
                {this.state.fees}
              </p>
            </div>
          </div>
          <div className="row" style={{ paddingRight: "3vh" }}>
            <div className="col-md-6 offset-1">
              <h6>Total</h6>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  position: "relative",
                  top: "10px",
                  fontSize: "smaller",
                  opacity: "0.7",
                }}
              >
                {this.state.fees}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1 offset-1">
              <Link href="/payment">
                <button
                  style={{
                    height: "30px",
                    width: "100px",
                    border: "none",
                    color: "white",
                    backgroundColor: "rgb(24, 169, 218)",
                  }}
                >
                  Check Out
                </button>
              </Link>
            </div>
            <div className="col-md-3" style={{ marginLeft: "1vh" }}>
              <Link href="/teacher/teacherdetail">
                <button
                  style={{
                    height: "30px",
                    width: "100px",
                    border: "none",
                    color: "white",
                    backgroundColor: "rgb(253, 0, 2)",
                  }}
                >
                  Cancel
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CheckOut;
