import React, { Component } from "react";
import API from "./api";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  DialogContentText,
  TextareaAutosize,
  MenuItem,
  Alert
} from '@mui/material'
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default class OtherInformation extends Component {
  state = {
    Profiledata: "",
  };
  componentDidMount() {
    this.getprofiledata();
  }
  getprofiledata() {
    let params = localStorage.getItem("userID");
    var that = this;
    API.post("AcademyApi/profile", { user_id: params })
      .then(function (response) {
        console.log(response);
        if (response.data.result === 1) {
          that.setState({
            Profiledata: response.data.data,
          });
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> OTHER INFORMATION</h1>
          <p style={{ color: "white" }}>
            <Link style={{ color: "white" }} href="/">
              Home
            </Link>{" "}
            |{" "}
            <Link style={{ color: "white" }} href="/gameprofile">
              GameAcademy
            </Link>{" "}
            |
            <Link style={{ color: "white" }} href="#">
              {" "}
              Other Information
            </Link>
          </p>
        </div>
        <Container  style={{ marginBottom: "12px", marginTop: "20px", minHeight: "500px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card style={{minHeight:"400px"}}>
                <CardContent>
                  <Box display="flex" flexDirection="column" textAlign="center">
                    <Box><Typography variant="h6">About Academy</Typography></Box>
                    <Box><Typography style={{textJustify:"auto"}}>{this.state.Profiledata.about_academy}</Typography></Box>
                  </Box>

                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
            <Card style={{minHeight:"400px"}}>
                <CardContent>
                  <Box display="flex" flexDirection="column" textAlign="center">
                    <Box><Typography variant="h6">Winning Tournaments</Typography></Box>
                    <Box><Typography style={{textJustify:"auto"}}>{this.state.Profiledata.winning_tournament}</Typography></Box>
                  </Box>

                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
            <Card style={{minHeight:"400px"}}>
                <CardContent>
                  <Box display="flex" flexDirection="column" textAlign="center">
                    <Box><Typography variant="h6">Success Story</Typography></Box>
                    <Box><Typography style={{textJustify:"auto"}}>{this.state.Profiledata.successful_story}</Typography></Box>
                  </Box>

                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container">
          <div className="row" style={{ paddingTop: "10vh" }}>
            <div
              className="col-md-3 offset-2"
              style={{
                border: "1px solid lightgrey",
                height: "70vh",
                borderRadius: "4%",
              }}
            >
              <h5>About Academy</h5>
              <div
                style={{
                  height: "3px",
                  width: "80px",
                  textDecoration: "underline",
                  backgroundColor: "rgb(145,208,79)",
                }}
              ></div>
              <p style={{ opacity: "0.8", paddingTop: "3vh" }}>
                {this.state.Profiledata.about_academy}
              </p>
            </div>
            <div
              className="col-md-3"
              style={{
                border: "1px solid lightgrey",
                height: "70vh",
                marginLeft: "3vh",
                borderRadius: "4%",
              }}
            >
              <h5>Winning Tournaments</h5>
              <div
                style={{
                  height: "3px",
                  width: "80px",
                  textDecoration: "underline",
                  backgroundColor: "rgb(145,208,79)",
                }}
              ></div>
              <p style={{ opacity: "0.8", paddingTop: "3vh" }}>
                {this.state.Profiledata.winning_tournament}
              </p>
            </div>
            <div
              className="col-md-3"
              style={{
                border: "1px solid lightgrey",
                height: "70vh",
                marginLeft: "3vh",
                borderRadius: "4%",
              }}
            >
              <h5>Success Story</h5>
              <div
                style={{
                  height: "3px",
                  width: "80px",
                  textDecoration: "underline",
                  backgroundColor: "rgb(145,208,79)",
                }}
              ></div>
              <p style={{ opacity: "0.8", paddingTop: "3vh" }}>
                {this.state.Profiledata.successful_story}
              </p>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
