import React, { Component, useEffect, useState } from 'react';
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  MenuItem,
  InputLabel
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../provider/useWindowSize'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FloatingLabel, Form } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import CloseIcon from '@mui/icons-material/Close';
import ModalVideo from 'react-modal-video'

const CodingClassVideo = ({ videoList, classes }) => {
  const theme = useTheme()
  const { width } = useWindowSize()
  const { sm, md, lg } = theme.breakpoints.values
  const [codingVideoList, setCodingVideoList] = useState([])
  const [codingVideoListBackup, setCodingVideoListBackup] = useState([])
  const [codingLanguage, setCodingLanguage] = useState("all")
  const [openVideoDialog, setOpenVideoDialog] = useState(false)
  const [playVideo, setPlayVideo] = useState("")

  const openVideo = (video) => {
    setOpenVideoDialog(true)
    setPlayVideo(video)

  }


  useEffect(() => {
    if (videoList.length > 0) {
      setCodingVideoList(videoList)
      setCodingVideoListBackup(videoList)
    }
  }, [videoList])

  const getCodingLanguage = (e) => {
    console.log("codingVideoList", codingVideoListBackup);
    const { name, value } = e.target
    if(value !== "all"){
      let videos=[]
      setCodingLanguage(value)
      // setCodingVideoList(codingVideoListBackup)
      videos= codingVideoListBackup.filter(video => video.coding_language === value)
      if(videos.length > 0){
        setCodingVideoList(videos)
      }else{
        setCodingVideoList([])
      }
    }else{
      setCodingVideoList(codingVideoListBackup)
      setCodingLanguage("all")
    }
    
    

  }
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box>
              <Typography variant="h5" style={{ paddingLeft: "8px" }}>CODING CLASS VIDEO</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box display="flex" style={{ justifyContent: width < sm ? "flex-start" : "flex-end" }}>
              <Box style={{ width: "100%" }}>
                {/* <InputLabel id="demo-simple-select">Select Coding Language</InputLabel> */}
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="codingLanguage"
                  input={<OutlinedInput />}
                  defaultValue={codingLanguage}
                  value={codingLanguage}
                  onChange={getCodingLanguage}

                >
                  <MenuItem value="all">All Programing language</MenuItem>
                  {codingVideoListBackup.map(subject =>
                  <MenuItem value={subject.coding_language}>{subject.coding_language}</MenuItem>)}

                </Select>
              </Box>
            </Box>
          </Grid>
          <div
            style={{
              backgroundColor: "rgb(24, 169, 218)",
              height: "2px",
              width: "160px",
              marginLeft: "23px"
            }}>
          </div>
        </Grid>
      </Container>
      <Container >
        <Grid container spacing={2} marginY={2}>
          {codingVideoList.length > 0 ?
          <>{codingVideoList.map(video =>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* <Link href="/innovationvideo" style={{ textDecoration: "none", color: "black" }}> */}
              <Card onClick={() => openVideo(video)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={video?.thumbnail_file}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {video?.class}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {video?.coding_language}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              {/* </Link> */}
            </Grid>
          )}
          </>
          :
          <Box style={{width:"100%",justifyContent:"center"}}>
            <Box><Typography>Data not found</Typography></Box>
          </Box>
          }
        </Grid>
        <Dialog open={openVideoDialog}
          maxWidth="lg"
          fullWidth
        >
          {/* <ModalVideo url={playVideo?.file} channel='custom' isOpen={openVideoDialog} onClose={() => setOpenVideoDialog(false)} classNames="modalVideo"/> */}
          <DialogTitle style={{ padding: "0", backgroundColor: "dimgrey" }}>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              style={{ padding: "0px", color: "white" }}
              onClick={() => setOpenVideoDialog(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: "0px", backgroundColor: "dimgrey" }}>
            {/* <Box style={{width:"100%",maxHeight:"600px",objectFit:"contain"}}>
            <ReactPlayer
              url={playVideo?.file}
              playing="true"
              width='100%'
              controls
              height='100%'
              loop="false"
              muted="false" />
            </Box> */}

            {/* <Card>
              <CardMedia
              component="video"
              height="600"
              src={playVideo?.file}
              autoPlay
              />
            </Card> */}
            <video 
            width="100%" 
            height="600" 
            controls 
            src={playVideo?.file} 
            type="video/mp4">
            </video>


          </DialogContent>
        </Dialog>


      </Container>
      {/* <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h5>CODING CLASS VIDEO</h5>
            <div style={{ height: "2px", width: "80px", backgroundColor: "rgb(24, 169, 218)" }}></div>
          </div>
          <div className="col-md-4">
            <FloatingLabel controlId="floatingSelect" label="Select Coding Class">
              <Form.Select aria-label="">
                <option></option>
                <option value="1">Java</option>
                <option value="2">Css</option>
                <option value="3">Three</option>
              </Form.Select>
            </FloatingLabel>
          </div>
        </div>
      </div>
      <div className="container" style={{ position: "relative", top: "20px" }}>
        <div className="row">
          <div
            className="col-md-4"
            style={{ paddingRight: "10px" }}
          >
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <Link href="/innovationvideo">
                  {" "}
                  <img
                    className="card-img-top"
                    src={
                      require("../assets/img/coding.jpeg")
                        .default
                    }
                    alt="Card  cap"
                  />
                </Link>
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "2rem" }}>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div
              className="card"
              style={{ width: "13rem", height: "auto" }}
            >
              <div className="embed-responsive embed-responsive-4by3">
                <img
                  className="card-img-top"
                  src={
                    require("../assets/img/coding.jpeg").default
                  }
                  alt="Card  cap"
                />
              </div>
              <div className="card-body">
                <h6 className="card-title">
                  Start Today not Tomorrow
                </h6>
                <p1
                  className="card-text"
                  style={{ fontSize: "smaller" }}
                >
                  Posted on 11th august , 2021
                </p1>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  closeButton: {
    position: "absolute",
    left: "97%",
  }
  // profileheading: {
  //   position: "absolute",
  //   top: "41%",
  //   left: "16%",
  //   color: "white"
  // },
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(CodingClassVideo);