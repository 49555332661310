import React, { Component } from "react";
import API from "./api";
// import { Button, Collapse } from "react-bootstrap";
import { MdCall } from "react-icons/md";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { GrAdd } from "react-icons/gr";
import { AiOutlineMinus } from "react-icons/ai";
import { Link } from "@mui/material";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Button
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CallIcon from '@mui/icons-material/Call';
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class Observer_gameacademy extends Component {
  state = {
    Academydata: [],
    detailpage: false,
    datas: "",
    gameopen: false,
    gamesdatas: [],
    open: true,
  };
  componentDidMount() {
    this.getAcademydetails();
  }
  getAcademydetails() {
    var that = this;
    API.post("MasterApi/academyList")
      .then(function (response) {
        console.log(response);
        that.setState({
          Academydata: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getiddetails(l) {
    this.setState({ detailpage: true });
    console.log("new id", l.id);
    this.setState({
      datas: l,
      gamesdatas: l.games,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.detailpage ? (
          <div>
            {" "}
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1>GAME ACADEMY</h1>
              {/* <Typography variant="h5"> </Typography> */}
              <p>
                <Link
                  href="/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Home
                </Link>{" "}
                |{" "}
                <Link
                  href="/observer_profile"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  School Observer
                </Link>{" "}
                |{" "}
                <Link
                  href="/observer/gameacademy"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Game Academy
                </Link>{" "}
                | Details
              </p>
            </div>
            {/* //++++++______+__+____++++_++++_+____++__++++_+_+___+++_+_+ */}


            <Container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <button
                      onClick={() => {
                        this.setState({ detailpage: false });
                      }}
                      className={classes.tuitionTimeStatusButton}>
                      <ArrowBackIcon style={{ color: "white" }} />
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box style={{ height: "400px", width: "100%" }}>
                    <img style={{ height: "400px", width: "100%", objectFit: "cover", borderRadius: "5px" }} src={this.state.datas.photo} alt="" />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Box display="flex" flexDirection="column" style={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
                    <Box display="flex" justifyContent="space-between" paddingX={2} paddingTop={1}>
                      <Box ><Typography variant="h6" style={{ fontWeight: "bold" }}> {this.state.datas.name}</Typography></Box>
                      <Box>
                        <button
                          className={classes.tuitionTimeStatusButton}>
                          <CallIcon style={{ color: "white" }} />
                        </button>
                      </Box>
                    </Box>
                    <Box paddingX={2}>
                      <Typography >{this.state.datas.address}</Typography>
                    </Box>
                    <Divider />
                    <Box>
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItemButton
                          onClick={() => {
                            this.setState({ open: !this.state.open });
                          }}>
                          <Box display="flex" justifyContent="space-between" style={{ width: "100%" }}>
                            <Box>
                              <Typography variant="h6" style={{ fontWeight: "bold" }}>Personal Information</Typography>
                            </Box>
                            <Box>
                              {this.state.open ? <RemoveIcon /> : <AddCircleOutlineIcon />}
                            </Box>
                          </Box>


                        </ListItemButton>
                        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                          <Box className={classes.personalInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Owner's Name
                                </Typography>
                              </Box>
                              <Box >
                                <Typography>{this.state.datas.owner_name}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Mobile Number
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.datas.mobile}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Email Address
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.datas.email}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Address
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.datas.address}</Typography>
                              </Box>
                            </Box>


                          </Box>
                        </Collapse>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ gameopen: !this.state.gameopen });
                          }}>
                          <Box display="flex" justifyContent="space-between" style={{ width: "100%" }}>
                            <Box>
                              <Typography variant="h6" style={{ fontWeight: "bold" }}>Classification of Game</Typography>
                            </Box>
                            <Box>
                              {this.state.gameopen ? <RemoveIcon /> : <AddCircleOutlineIcon />}
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Collapse in={this.state.gameopen} timeout="auto" unmountOnExit>
                          <Box className={classes.educationInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Registered Games
                                </Typography>
                              </Box>
                              {this.state.gamesdatas.map((l, id) => {
                                return (
                                  <Box>
                                    <Typography> {l?.name}</Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Collapse>

                      </List>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        ) : (
          <div>
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1> GAME ACADEMY</h1>
              <p>
                {" "}
                <Link
                  href="/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Home{" "}
                </Link>
                |{" "}
                <Link
                  href="/observer_profile"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  School Observer
                </Link>{" "}
                | Game Academy
              </p>
            </div>

            <Container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h5">GAME ACADEMY</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "17%"
                    }}>
                  </div>
                </Grid>
              </Grid>
            </Container>

            <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
              <Grid container spacing={2}> 
              {this.state.Academydata.map((l, id) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="outlined"
                      style={{
                        border: "1px solid lightgrey",
                        textTransform: "capitalize"
                      }}
                      onClick={() => {
                        this.getiddetails(l);
                      }}
                      fullWidth
                    >
                      <Box display="flex" justifyContent="space-between" style={{ width: "100%" }} paddingY={1}>
                        <Box><Typography style={{ color: "black" }}>{l.name}</Typography></Box>
                        <Box><ArrowForwardIcon style={{ color: "black" }} /></Box>
                      </Box>
                    </Button>
                  </Grid>
                );
              })}
              </Grid>
            </Container>

            {/* <div className="container">
              <div className="row" style={{ paddingTop: "7vh" }}>
                <div className="col offset-1">
                  <h5>GAME ACADEMY</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                </div>
              </div>
              <Container spacing={2} style={{ paddingTop: "3vh" }}>
                {this.state.Academydata.map((l, id) => {
                  return (
                    <Grid marginTop={1} display="flex" flexDirection="column">
                      <Grid item ls={12} md={12} sm={8} xs={8}>
                        <Box className={classes.root} boxShadow={5}>
                          <Box className={classes.personalInfoBox}>
                            <Box className={classes.singleBox}>
                              <Box>
                                <Typography
                                  // variant="h6"
                                  style={{ fontWeight: "" }}
                                  fontSize={18}
                                >
                                  {l.name}
                                </Typography>
                              </Box>
                              <Box>
                                <button
                                  className={classes.profileEditButton}
                                  onClick={() => {
                                    this.getiddetails(l);
                                  }}
                                >
                                  <HiOutlineArrowNarrowRight />{" "}
                                </button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
              </Container>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.6",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },
  profileEditButton: {
    borderRadius: "30%",
    backgroundColor: "lightgrey",
    border: "none",
    color: "white",
  }, profileheading: {
    position: "absolute",
    top: "25%",
    left: "18%",
    color: "white"
  },
  demoPlayerBox: {
    width: "43%",
    height: "200px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
    marginTop: "8px"
  },
  demoPlayerBoxEdit: {
    width: "100%",
    height: "55px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
  },
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px"
  },
  handWritting: {
    objectFit: "cover",
    height: "100px",
    width: "100px"
  },
  updateButton: {
    borderRadius: "0px",
    width: "18vh",
    backgroundColor: "deepskyblue"
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px"
  },
  bankInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column"
  },
  educationInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px"
  },
  personalInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column"
  },
  schoolCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px"
  },
  universityCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px"
  },
  addTuitionButton: {
    backgroundColor: "lightgreen",
    color: "white",
    borderRadius: "30px",
    marginLeft: "2px"
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px"
  },
});
export default withStyles(styles)(Observer_gameacademy);
