import React, { Component, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import {
  Link,
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import API from "../../components/api";
import style from "../../global_style.css";
import axios from "axios";
import { navigate } from "@reach/router";
// import { Router, Link } from "@reach/router"

const TeacherRegister = ({ classes }) => {
  // const [callCount, setCallCount] = useState(1)
  const form = useRef(null);
  // let history = useHistory()
  const [page, setPage] = useState(1);
  const [buttonpage2, setButtonpage2] = useState(false);
  const [buttonpage3, setButtonpage3] = useState(false);
  const [buttonpage4, setButtonpage4] = useState(false);
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [stepOneNext, setStepOneNext] = useState(false);
  const [stepTwoNext, setStepTwoNext] = useState(false);
  const [stepThreeNext, setStepThreeNext] = useState(false);
  const [stepFourNext, setStepFourNext] = useState(false);

  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [accountError, setAccountError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [userNameType, setUserNameType] = useState("email");
  const [category, setCategory] = useState(3);

  const [teacherRegister, setTeacherRegister] = useState({
    category: "",
    name: "",
    fatherName: "",
    dob: "",
    mobile: "",
    email: "",
    qualification: "",
    experience: "",
    address: "",
    photo: "",
    school: "",
    university: "",
    schoolCertificate: "",
    universityCertificate: "",
    bioData: "",
    demoClass: "",
    handWriting: "",
    bank: "",
    ifsc: "",
    account: "",
    userName: "",
    password: "",
    rePassword: "",
  });

  // const handleProfilePhoto = (e) => {
  //   e.preventDefault()
  //   console.log("pfofile_photo_payload", e)
  //   let reader = new FileReader()
  //   let file = e.target.files[0]
  //   reader.onloadend = () => {

  //     setTeacherProfilePhoto(reader.result)
  //     console.log("pfofile_photo_result", reader.result)
  //   };

  //   reader.readAsDataURL(file);
  // }

  // const handleProfileSchoolCertificate = (e) => {
  //   e.preventDefault()
  //   console.log("pfofile_school_payload", e)
  //   let reader = new FileReader()
  //   let file = e.target.files[0]
  //   reader.onloadend = () => {

  //     setTeacherProfileSchoolCertificate(reader.result)
  //     console.log("pfofile_school_result", reader.result)
  //   };

  //   reader.readAsDataURL(file);
  // }

  // const handleProfileUniversityCertificate = (e) => {
  //   e.preventDefault()
  //   console.log("pfofile_university_payload", e)
  //   let reader = new FileReader()
  //   let file = e.target.files[0]
  //   reader.onloadend = () => {

  //     setTeacherProfileUniversityCertificate(reader.result)
  //     console.log("pfofile_university_result", reader.result)
  //   };

  //   reader.readAsDataURL(file);
  // }

  // const handleProfileDemoClass = (e) => {
  //   e.preventDefault()
  //   console.log("pfofile_demo_payload", e)
  //   let reader = new FileReader()
  //   let file = e.target.files[0]
  //   reader.onloadend = () => {

  //     setTeacherProfileDemoClass(reader.result)
  //     console.log("pfofile_demo_result", reader.result)
  //   };

  //   reader.readAsDataURL(file);
  // }

  // const handleProfileHandWritting = (e) => {
  //   e.preventDefault()
  //   console.log("pfofile_demo_payload", e)
  //   let reader = new FileReader()
  //   let file = e.target.files[0]
  //   reader.onloadend = () => {

  //     setTeacherProfileHandwritting(reader.result)
  //     console.log("pfofile_demo_result", reader.result)
  //   };

  //   reader.readAsDataURL(file);
  // }

  // const handleProfileBiodata = (e) => {
  //   e.preventDefault()
  //   console.log("pfofile_biodata_payload", e)
  //   let reader = new FileReader()
  //   let file = e.target.files[0]
  //   reader.onloadend = () => {

  //     setTeacherProfileBiodata(reader.result)
  //     console.log("pfofile_biodata_result", reader.result)
  //   };

  //   reader.readAsDataURL(file);
  // }

  const handleClick = (e) => {
    e.preventDefault();
    console.log("payloadRegistration", teacherRegister);

    // API.post("TeacherApi/registration", {
    //   user_type: 3,
    //   name: teacherRegister.name,
    //   father_name: teacherRegister.fatherName,
    //   address: teacherRegister.address,
    //   latitude: "",
    //   longitude: "",
    //   dob: teacherRegister.dob,
    //   mobile: teacherRegister.mobile,
    //   email: teacherRegister.email,
    //   password: teacherRegister.rePassword,
    //   qualification: teacherRegister.qualification,
    //   experience: teacherRegister.experience,
    //   photo: teacherRegister.photo,
    //   qualification_school: teacherRegister.school,
    //   school_certificate: teacherRegister.schoolCertificate,
    //   university: teacherRegister.university,
    //   university_certificate: teacherRegister.universityCertificate,
    //   biodata: teacherRegister.bioData,
    //   demo_class: teacherRegister.demoClass,
    //   hand_writing_project: teacherRegister.handWriting,
    //   bank_name: teacherRegister.bank,
    //   ifsc_code: teacherRegister.ifsc,
    //   account_number: teacherRegister.account,
    //   fcm_token: ""
    // }).then(res => {
    //   console.log("payloadRegistrationResponse", res);
    // })
    const head = {
      headers: {
        "Content-Type": "multipart/form-data",
        Source: "web",
      },
    };

    var formdata = new FormData();

    formdata.append("user_type", 3);
    formdata.append("name", teacherRegister.name);
    formdata.append("father_name", teacherRegister.fatherName);
    formdata.append("address", teacherRegister.address);
    formdata.append("latitutde", "22.1913978");
    formdata.append("longitude", "87.9098295");
    formdata.append("dob", teacherRegister.dob);
    formdata.append("mobile", teacherRegister.mobile);
    formdata.append("email", teacherRegister.email);
    formdata.append("password", teacherRegister.password);
    formdata.append("qualification", teacherRegister.qualification);
    formdata.append("experience", teacherRegister.experience);
    formdata.append("photo", teacherRegister.photo);
    formdata.append("qualification_school", teacherRegister.school);
    formdata.append("school_certificate", teacherRegister.schoolCertificate);
    formdata.append("university", teacherRegister.university);
    formdata.append(
      "university_certificate",
      teacherRegister.universityCertificate
    );
    formdata.append("biodata", teacherRegister.bioData);
    formdata.append("demo_class", teacherRegister.demoClass);
    formdata.append("hand_writing_project", teacherRegister.handWriting);
    formdata.append("bank_name", teacherRegister.bank);
    formdata.append("ifsc_code", teacherRegister.ifsc);
    formdata.append("account_number", teacherRegister.account);
    formdata.append("fcm_token", "");
    console.log("payloadRegistration123", formdata);

    API.post("TeacherApi/registration", formdata, head).then((res) => {
      console.log("payloadRegistrationResponse", res);
      if (res.data.result === 1) {
        navigate("/login");
        setTeacherRegister({
          category: 3,
          name: "",
          fatherName: "",
          dob: "",
          mobile: "",
          email: "",
          qualification: "",
          experience: "",
          address: "",
          photo: "",
          school: "",
          university: "",
          schoolCertificate: "",
          universityCertificate: "",
          bioData: "",
          demoClass: "",
          handWriting: "",
          bank: "",
          ifsc: "",
          account: "",
          userName: "",
          password: "",
          rePassword: "",
        });
      }
    });
    // this.props.history.push("/teacherprofile");
  };

  useEffect(() => {
    if (
      teacherRegister.name &&
      teacherRegister.fatherName &&
      teacherRegister.dob &&
      teacherRegister.email &&
      teacherRegister.mobile &&
      teacherRegister.qualification &&
      teacherRegister.experience &&
      teacherRegister.address &&
      teacherRegister.photo
    ) {
      if (
        /.+@.+\..+/.test(teacherRegister.email) &&
        teacherRegister.mobile.length === 10
      ) {
        setStepOneNext(true);
        setMobileError("");
        setEmailError("");
      } else {
        if (!/.+@.+\..+/.test(teacherRegister.email)) {
          setStepOneNext(false);
          setEmailError("Email must be valid");
        } else {
          setStepOneNext(false);
          setEmailError("");
        }

        if (
          teacherRegister.mobile.length < 10 ||
          teacherRegister.mobile.length > 10
        ) {
          setStepOneNext(false);
          setMobileError("Mobile number must be 10 digit");
        } else {
          setStepOneNext(false);
          setMobileError("");
        }
      }

      // alert(true)
    } else {
      setStepOneNext(false);
      // alert(false)
    }

    if (
      stepOneNext &&
      teacherRegister.school &&
      teacherRegister.university &&
      teacherRegister.schoolCertificate &&
      teacherRegister.universityCertificate &&
      teacherRegister.bioData &&
      teacherRegister.demoClass &&
      teacherRegister.handWriting
    ) {
      setStepTwoNext(true);
    } else {
      setStepTwoNext(false);
    }

    if (
      stepOneNext &&
      stepTwoNext &&
      teacherRegister.bank &&
      teacherRegister.ifsc &&
      teacherRegister.account
    ) {
      if (teacherRegister.account.length >= 11) {
        setStepThreeNext(true);
        setAccountError("");
      } else {
        setStepThreeNext(false);
        setAccountError("Account number should be 11 digit or higher");
      }
    } else {
      setStepThreeNext(false);
    }

    if (
      stepOneNext &&
      stepTwoNext &&
      stepThreeNext &&
      teacherRegister.password &&
      teacherRegister.rePassword
    ) {
      if (
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
          teacherRegister.password
        ) &&
        teacherRegister.password === teacherRegister.rePassword
      ) {
        setStepFourNext(true);
        setPasswordMatchError("");
        setPasswordError("");
      } else {
        if (
          !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
            teacherRegister.password
          ) &&
          teacherRegister.password === teacherRegister.rePassword
        ) {
          setStepFourNext(false);
          setPasswordError(
            "Password must be at least of 8 characters containing at least one number and one special character"
          );
        }
        if (
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
            teacherRegister.password
          ) &&
          teacherRegister.password !== teacherRegister.rePassword
        ) {
          setStepFourNext(false);
          setPasswordMatchError("Password must be match");
        }
      }
    } else {
      setStepFourNext(false);
    }
  }, [teacherRegister, stepOneNext, stepTwoNext, stepThreeNext]);

  const getLocation = () => {
    axios
      .get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: teacherRegister.address,
          key: "AIzaSyA3X62AbdteMMt1Rn3aqTrSs5SdodK8VuQ",
        },
      })
      .then((resp) => {
        console.log("lat_long", resp);
      });
  };

  const getFormData = (e) => {
    let name;
    let value;
    if (e.target.name === "ifsc") {
      name = e.target.name;
      value = String(e.target.value).toUpperCase();
    } else {
      if (e.target.name === "mobile") {
        if (e.target.value.length <= 10) {
          name = e.target.name;
          value = e.target.value;
        }
      } else {
        name = e.target.name;
        value = e.target.value;
      }
    }
    setTeacherRegister({
      ...teacherRegister,
      [name]: value,
    });

    if (name === "email") {
      setUserEmail(value);
    } else if (name === "mobile") {
      setUserMobile(value);
    }
  };

  // if(page === 4 && callCount === 1){
  //   if (teacherRegister.userNameType === 'email') {
  //     alert('email')
  //     setTeacherRegister({
  //       ...teacherRegister,
  //       userName: teacherRegister.email
  //     })
  //   } else if (teacherRegister.userNameType === 'mobile') {
  //     alert('mobile')
  //     setTeacherRegister({
  //       ...teacherRegister,
  //       userName: teacherRegister.mobile
  //     })
  //   }
  //   setCallCount(2)

  // }

  const getUserNameType = (e) => {
    const { value } = e.target;
    setUserNameType(value);
    if (userNameType === "email") {
      // alert('email')
      setUserEmail(teacherRegister.email);
    } else if (userNameType === "mobile") {
      // alert('mobile')
      setUserMobile(teacherRegister.mobile);
    }
  };

  console.log("formData", teacherRegister);

  const goToStep1 = () => {
    setPage(2);
    setButtonpage2(true);
  };
  const goToStep2 = () => {
    setPage(3);
    setButtonpage3(true);
  };
  const goToStep3 = () => {
    setPage(4);
    setButtonpage4(true);
  };

  return (
    <div>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
        <h1> REGISTER</h1>
        <p>Home | Register</p>
      </div>
      {/* <div className="container registerforms">
        <div
          className="row"
          style={{ paddingTop: "30px", paddingBottom: "30px" }}
        >
          <div className="col-md-2 offset-2">
            <button className={classes.stepOne} onClick={() => setPage(1)}>
              1
            </button>
            <p> Personal</p>
          </div>
          <div className="col-md-2">
            <button
              className={
                buttonpage2
                  ? "rounded-circle buttontrue"
                  : "rounded-circle buttonfalse"
              }
              disabled={buttonpage2 ? false : true}
              onClick={goToStep1}
              style={{
                fontSize: "20px",
                height: "50px",
                border: "1px solid lightgrey",
                width: "50px",
              }}
            >
              2
            </button>
            <p> Education</p>
          </div>
          <div className="col-md-2">
            <button
              className={
                buttonpage3
                  ? "rounded-circle buttontrue"
                  : "rounded-circle buttonfalse"
              }
              disabled={buttonpage3 ? false : true}
              onClick={goToStep2}
              style={{
                fontSize: "20px",
                height: "50px",
                width: "50px",
                border: "1px solid lightgrey",
              }}
            >
              3
            </button>
            <p>Bank Details</p>
          </div>
          <div className="col-md-2">
            <button
              className={
                buttonpage4
                  ? "rounded-circle buttontrue"
                  : "rounded-circle buttonfalse"
              }
              onClick={goToStep3}
              disabled={buttonpage4 ? false : true}
              style={{
                fontSize: "20px",
                height: "50px",
                border: "1px solid lightgrey",
                width: "50px",
              }}
            >
              4
            </button>
            <p>Login Details</p>
          </div>
        </div>
      </div> */}
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box>
                <button className={classes.stepOne} onClick={() => setPage(1)}>
                  1
                </button>
              </Box>
              <Box>
                <Typography> Personal</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box>
                <button
                  className={
                    buttonpage2
                      ? "rounded-circle buttontrue"
                      : "rounded-circle buttonfalse"
                  }
                  disabled={buttonpage2 ? false : true}
                  onClick={goToStep1}
                  style={{
                    fontSize: "20px",
                    height: "50px",
                    border: "1px solid lightgrey",
                    width: "50px",
                  }}
                >
                  2
                </button>
              </Box>
              <Box>
                <Typography> Education</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box>
                <button
                  className={
                    buttonpage3
                      ? "rounded-circle buttontrue"
                      : "rounded-circle buttonfalse"
                  }
                  disabled={buttonpage3 ? false : true}
                  onClick={goToStep2}
                  style={{
                    fontSize: "20px",
                    height: "50px",
                    width: "50px",
                    border: "1px solid lightgrey",
                  }}
                >
                  3
                </button>
              </Box>
              <Box>
                <Typography> Bank Details</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box>
                <button
                  className={
                    buttonpage4
                      ? "rounded-circle buttontrue"
                      : "rounded-circle buttonfalse"
                  }
                  onClick={goToStep3}
                  disabled={buttonpage4 ? false : true}
                  style={{
                    fontSize: "20px",
                    height: "50px",
                    border: "1px solid lightgrey",
                    width: "50px",
                  }}
                >
                  4
                </button>
              </Box>
              <Box>
                <Typography> Login Details</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <form onSubmit={handleClick}>
        {page === 1 ? (
          <Container>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Category *</p>
                <FormControl variant="filled" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="category"
                    onChange={getFormData}
                    input={<OutlinedInput />}
                    value={category}
                    disabled
                  >
                    <option value={3}>Teacher</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Name *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="name"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={getFormData}
                    value={teacherRegister.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Father's Name *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="fatherName"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={getFormData}
                    value={teacherRegister.fatherName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>DOB *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="dob"
                    variant="outlined"
                    value={teacherRegister.dob}
                    onChange={getFormData}
                    type="date"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Mobile *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="mobile"
                    variant="outlined"
                    onChange={getFormData}
                    maxLength={10}
                    value={teacherRegister.mobile}
                    type="number"
                  />
                </FormControl>
                {mobileError && <span className="error">{mobileError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Email *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="email"
                    variant="outlined"
                    value={teacherRegister.email}
                    onChange={getFormData}
                    type="email"
                  />
                </FormControl>
                {emailError && <span className="error">{emailError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Qualification *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="qualification"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={getFormData}
                    value={teacherRegister.qualification}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Experience *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="experience"
                    variant="outlined"
                    value={teacherRegister.experience}
                    onChange={getFormData}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Address *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    name="address"
                    value={teacherRegister.address}
                    onChange={getFormData}
                    onBlur={getLocation}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Teacher Photo *</p>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    name="photo"
                    value={teacherRegister.photo}
                    onChange={getFormData}
                    type="file"
                    inputProps={{
                      accept: "image/png, image/jpeg",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.nextButton1}>
              <Grid item md={2} lg={4}>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "0px",
                    width: "18vh",
                    backgroundColor: "deepskyblue",
                  }}
                  disabled={stepOneNext ? false : true}
                  onClick={goToStep1}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Container>
        ) : (
          ""
        )}
        {page === 2 ? (
          <div style={{ width: "100%" }}>
            {/* <Box style={{justifyContent:"center"}}> */}
            <Container>
              <p>* Required Field</p>
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>School Name *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="school"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={getFormData}
                      value={teacherRegister.school}
                      variant="outlined"
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>University Name *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="university"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={getFormData}
                      value={teacherRegister.university}
                      variant="outlined"
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>School Certificate *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="schoolCertificate"
                      value={teacherRegister.schoolCertificate}
                      onChange={getFormData}
                      type="file"
                      inputProps={{
                        accept: "image/png, image/jpeg",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>University Certificate *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="universityCertificate"
                      value={teacherRegister.universityCertificate}
                      onChange={getFormData}
                      type="file"
                      inputProps={{
                        accept: "image/png, image/jpeg",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Bio-Data *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="bioData"
                      onChange={getFormData}
                      value={teacherRegister.bioData}
                      type="file"
                      inputProps={{
                        accept: "application/pdf,application/msword",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Demo-Class *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="demoClass"
                      onChange={getFormData}
                      value={teacherRegister.demoClass}
                      type="file"
                      inputProps={{
                        accept: "video/*",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>HandWriting Project *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="handWriting"
                      onChange={getFormData}
                      value={teacherRegister.handWriting}
                      type="file"
                      inputProps={{
                        accept: "application/pdf,application/msword",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    onClick={goToStep2}
                    disabled={stepTwoNext ? false : true}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </div>
        ) : (
          // <div className="container">
          //   <div className="col offset-2">
          //     {" "}
          //     <p>* Required Field</p>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>School Name *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='school'
          //           onChange={getFormData}
          //           value={teacherRegister.school}
          //           variant="outlined"
          //           type="text"
          //         />
          //       </FormControl>
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>University Name *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='university'
          //           onChange={getFormData}
          //           value={teacherRegister.university}
          //           variant="outlined"
          //           type="text"
          //         />
          //       </FormControl>
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>School Certificate *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='schoolCertificate'
          //           value={teacherRegister.schoolCertificate}
          //           onChange={getFormData}
          //           type="file"
          //         />
          //       </FormControl>
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>University Certificate *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='universityCertificate'
          //           value={teacherRegister.universityCertificate}
          //           onChange={getFormData}
          //           type="file"
          //         />
          //       </FormControl>
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Bio-Data *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='bioData'
          //           onChange={getFormData}
          //           value={teacherRegister.bioData}
          //           type="file"
          //         />
          //       </FormControl>
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>Demo-Class *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='demoClass'
          //           onChange={getFormData}
          //           value={teacherRegister.demoClass}
          //           type="file"
          //         />
          //       </FormControl>
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>HandWriting Project *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='handWriting'
          //           onChange={getFormData}
          //           value={teacherRegister.handWriting}
          //           type="file"
          //         />
          //       </FormControl>
          //     </div>
          //   </div>
          //   <div className="col offset-2" style={{ paddingTop: "20px", paddingBottom: "12px" }}>
          //     <Button
          //       variant="contained"
          //       style={{ borderRadius: "0px", width: "18vh", backgroundColor: "deepskyblue" }}
          //       onClick={goToStep2}
          //     >
          //       Next
          //     </Button>
          //   </div>
          // </div>
          ""
        )}
        {page === 3 ? (
          <div style={{ width: "100%" }}>
            {/* <Box style={{justifyContent:"center"}}> */}
            <Container>
              <p>* Required Field</p>
              <p style={{ lineHeight: "1px" }}>
                * Bank Details Required For Monthly Transactions Purpose
              </p>
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Bank Name *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="bank"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={getFormData}
                      value={teacherRegister.bank}
                      variant="outlined"
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>IFSC Code *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="ifsc"
                      inputProps={{
                        maxLength: 50,
                        style: { textTransform: "uppercase" },
                      }}
                      variant="outlined"
                      value={teacherRegister.ifsc}
                      onChange={getFormData}
                      type="text"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Account Number *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="account"
                      variant="outlined"
                      inputProps={{
                        maxLength: 18,
                      }}
                      value={teacherRegister.account}
                      onChange={getFormData}
                      type="number"
                    />
                  </FormControl>
                  {accountError && (
                    <span className="error">{accountError}</span>
                  )}
                </Grid>
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    onClick={goToStep3}
                    disabled={stepThreeNext ? false : true}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </div>
        ) : (
          // <div className="container">
          //   <div className="col offset-2">
          //     {" "}
          //     <p>* Required Field</p>
          //     <p style={{ lineHeight: "1px" }}>
          //       * Bank Details Required For Monthly Transactions Purpose
          //     </p>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Bank Name *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='bank'
          //           onChange={getFormData}
          //           value={teacherRegister.bank}
          //           variant="outlined"
          //           type="text"
          //         />
          //       </FormControl>
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>IFSC Code *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='ifsc'
          //           variant="outlined"
          //           value={teacherRegister.ifsc}
          //           onChange={getFormData}
          //           type="text"
          //         />
          //       </FormControl>
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Account Number *</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='account'
          //           variant="outlined"
          //           value={teacherRegister.account}
          //           onChange={getFormData}
          //           type="number"
          //         />
          //       </FormControl>
          //     </div>
          //   </div>
          //   <div className="col offset-2" style={{ paddingTop: "20px", paddingBottom: "12px" }}>
          //     <Button
          //       variant="contained"
          //       style={{ borderRadius: "0px", width: "18vh", backgroundColor: "deepskyblue" }}
          //       onClick={goToStep3}
          //     >
          //       Next
          //     </Button>
          //   </div>
          // </div>
          ""
        )}
        {page === 4 ? (
          <div style={{ width: "100%" }}>
            {/* <Box style={{justifyContent:"center"}}> */}
            <Container>
              <p>* Required Field</p>
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                <Grid item md={12} lg={12}>
                  <FormControl component="fieldset">
                    <p style={{ opacity: "0.6" }}>Username *</p>
                    <RadioGroup
                      row
                      name="userNameType"
                      onChange={getUserNameType}
                      value={userNameType}
                    >
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email Id"
                      />
                      <FormControlLabel
                        value="mobile"
                        control={<Radio />}
                        label="Mobile Number"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={12} lg={12}>
                  {userNameType === "mobile" && (
                    <FormControl variant="filled" style={{ width: "100%" }}>
                      <TextField
                        name="userName"
                        value={userMobile}
                        disabled={userMobile ? true : false}
                        variant="outlined"
                        type="number"
                      />
                    </FormControl>
                  )}
                  {userNameType === "email" && (
                    <FormControl variant="filled" style={{ width: "100%" }}>
                      <TextField
                        name="userName"
                        value={userEmail}
                        disabled={userEmail ? true : false}
                        variant="outlined"
                        type="email"
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Password *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="password"
                      onChange={getFormData}
                      value={teacherRegister.password}
                      variant="outlined"
                      type="password"
                    />
                  </FormControl>
                  {passwordError && (
                    <span className="error">{passwordError}</span>
                  )}
                </Grid>
                <Grid item md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}> Re Password *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="rePassword"
                      onChange={getFormData}
                      value={teacherRegister.rePassword}
                      variant="outlined"
                      type="password"
                    />
                  </FormControl>
                  {passwordMatchError && (
                    <span className="error">{passwordMatchError}</span>
                  )}
                </Grid>
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    type="submit"
                    disabled={stepFourNext ? false : true}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </div>
        ) : (
          // <div className="container">
          //   <div className="col offset-2">
          //     {" "}
          //     <p>* Required Field</p>
          //   </div>
          //   <div className="row">
          //     <div className="col offset-2">
          //     </div>
          //   </div>
          //   <div className="row" style={{ marginLeft: "17%" }}>
          //     <FormControl component="fieldset">
          //       <FormLabel component="legend">UserName *</FormLabel>
          //       <RadioGroup row name="userNameType" onChange={getUserNameType} value={userNameType}>
          //         <FormControlLabel value="email" control={<Radio />} label="Email Id" />
          //         <FormControlLabel value="mobile" control={<Radio />} label="Mobile Number" />
          //       </RadioGroup>
          //     </FormControl>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-8 offset-2">
          //       {userNameType === 'mobile' && <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='userName'
          //           value={userMobile}
          //           disabled={userMobile ? true : false}
          //           variant="outlined"
          //           type="number"
          //         />
          //       </FormControl>
          //       }
          //       {userNameType === 'email' && <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='userName'
          //           value={userEmail}
          //           disabled={userEmail ? true : false}
          //           variant="outlined"
          //           type="email"
          //         />
          //       </FormControl>
          //       }
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Password</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='password'
          //           onChange={getFormData}
          //           value={teacherRegister.password}
          //           variant="outlined"
          //           type="password"
          //         />
          //       </FormControl>
          //     </div>
          //     <div className="col-md-4">
          //       <p style={{ opacity: "0.6" }}> Re Password</p>
          //       <FormControl variant='filled' style={{ width: '100%' }}>
          //         <TextField
          //           name='rePassword'
          //           onChange={getFormData}
          //           value={teacherRegister.rePassword}
          //           variant="outlined"
          //           type="password"
          //         />
          //       </FormControl>
          //     </div>
          //   </div>
          //   <div className="col offset-2" style={{ paddingTop: "20px", paddingBottom: "12px" }}>
          //     <Button
          //       variant="contained"

          //       style={{ borderRadius: "0px", width: "18vh", backgroundColor: "deepskyblue" }}

          //       type="submit"
          //     >
          //       Submit
          //     </Button>
          //   </div>
          // </div>
          ""
        )}
      </form>
    </div>
  );
};
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  stepOne: {
    fontSize: "20px",
    height: "50px",
    width: "50px",
    backgroundColor: "lightgreen",
    border: "1px solid lightgrey",
    borderRadius: "50%",
  },
  nextButton1: {
    marginTop: "12px",
  },
});
export default withStyles(styles)(TeacherRegister);
