import React, { Component } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { Form, Check, Button } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";
import API from "../components/api";
import { PacmanLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";
// import { Router, Link } from "@reach/router"
import { Link } from "@mui/material";
import {
  Radio,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";

class CodingTestExam extends Component {
  state = {
    count: 0,
    Questions: [],
    total: "",
    Ques: [],
    answer: [],
    submitresp: "",
    examsubmitted: false,
    examsollution: false,
    showsol: false,
    sol: 0,
    ScoreCard: [],
    showscorecard: false,
    uniquID: "",
    examcount: 0,
    Myexam: [],
    ongoingexam: true,
    submitdisable: false,
    totalsol: "",
    totalscore: "",
    loading: false,
    examAnswer:""
  };
  componentDidMount() {
    let value = localStorage.getItem("uniqueID");
    if (value === ("" || undefined || null)) {
      this.setState({ ongoingexam: true });
    } else {
      this.setState({ ongoingexam: false });
    }
    this.getquestionList();
    this.getScoreCard();
    this.setState({ total: localStorage.getItem("totalQues") });
    this.getmyExamdetails();
  }
  setNextexam() {
    this.setState({ examcount: this.state.examcount + 1 });
    this.getmyExamdetails();
  }
  setPrevexam() {
    this.setState({ examcount: this.state.examcount - 1 });
    this.getmyExamdetails();
  }
  getmyExamdetails() {
    const params = {
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("StudentApi/myExam", params)
      .then(function (response) {
        console.log("exam", response.data.data[that.state.examcount]);
        that.setState({
          Myexam: response.data.data[that.state.examcount],
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getScoreCard() {
    const params = {
      exam_id: localStorage.getItem("examID"),
      user_id: localStorage.getItem("userID"),
      unique_id: this.state.uniquID || localStorage.getItem("uniqueID"),
    };
    var that = this;
    API.post("StudentApi/scorecard", params)
      .then(function (response) {
        that.setState({
          ScoreCard: response.data.data,
          totalscore: response.data.data.total_no_of_question,
        });
        if (response.data.result === 1) {
          that.setState({ showscorecard: true });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getSolutions() {
    const params = {
      exam_id: localStorage.getItem("examID"),
      user_id: localStorage.getItem("userID"),
      unique_id: localStorage.getItem("uniqueID"),
    };
    var that = this;
    API.post("StudentApi/viewSolution", params)
      .then(function (response) {
        that.setState({
          Solution: response.data.data,
          totalsol: response.data.data[0].total_no_of_question,
        });
        if (response.data.result === 1) {
          that.setState({ showsol: true });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  Getanswer(evt) {
    console.log("value", evt.target.value);
    this.setState({examAnswer:evt.target.value});
    console.log("qid", this.state.Ques[this.state.count].question_id);
    console.log("array", this.state.answer);
  }


  nextQuestion(){
    this.setState({
      answer: [
        ...this.state.answer,
        {
          question_id: this.state.Ques[this.state.count].question_id,
          given_answer: this.state.examAnswer,
        },
      ],
      examAnswer:""
    });
    this.setState({ count: this.state.count + 1 });
    this.getquestionList();
  }

  submitExam() {
    this.setState({
      answer: [
        ...this.state.answer,
        {
          question_id: this.state.Ques[this.state.count].question_id,
          given_answer: this.state.examAnswer,
        },
      ],
      examAnswer:""
    });
    this.setState({ submitdisable: true, loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
    const params = {
      exam_id: localStorage.getItem("examID"),
      user_id: localStorage.getItem("userID"),
      data: this.state.answer,
    };
    var that = this;
    API.post("StudentApi/saveExam", params)
      .then(function (response) {
        console.log(response.data.unique_id);
        that.setState({
          submitresp: response.data.message,
          uniquID: response.data.unique_id,
        });
        that.getScoreCard();

        if (response.data.result === 1) {
          that.setState({ examsubmitted: true, examsollution: true });
          setTimeout(() => {
            that.setState({ examsubmitted: false, ongoingexam: false });
          }, 2500);
        }
        localStorage.setItem("uniqueID", response.data.unique_id);
        // that.state.uniquID = response.data.unique_id;
        console.log("console", that.state.Ques[that.state.count]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getquestionList() {
    const params = {
      exam_id: localStorage.getItem("examID"),
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("StudentApi/allQuestionList", params)
      .then(function (response) {
        console.log(response.data.data[that.state.count]);
        that.setState({
          Questions: response.data.data[that.state.count],
          Ques: response.data.data,
        });
        console.log("console", that.state.Ques[that.state.count]);
        if (that.state.Questions === undefined) {
          that.setState({ testmsg: response.data.message });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    const now = (this.state.count + 1) * (100 / this.state.total);
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1>STUDENT</h1>
          <p>
            <Link href="/" style={{ color: "white", textDecoration: "none" }}>
              {" "}
              Home{" "}
            </Link>{" "}
            |
            <Link
              href="/subjectuser"
              style={{ color: "white", textDecoration: "none" }}
            >
              {" "}
              Student{" "}
            </Link>{" "}
            | Online Test
          </p>
        </div>
        <div className="container" style={{ paddingTop: "30px" }}>
          <div className="row">
            {this.state.ongoingexam ? (
              <div className="col-md-8 offset-2">
                <div
                  className="row"
                  style={{ border: "1px solid lightgrey", height: "90px" }}
                >
                  <div>
                    <ProgressBar
                      now={now}
                      bar
                      label={`${now}%`}
                      variant="success"
                      style={{
                        height: "40px",
                        top: "25px",
                        position: "relative",
                      }}
                    />
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    border: "1px solid lightgrey",
                    position: "relative",
                    top: "20px",
                  }}
                >
                  {/* {this.state.showsol ? (
                    <div className="col-md-6" style={{ paddingTop: "3vh" }}>
                      <h5
                        style={{
                          backgroundColor: "lightgreen",
                          color: "white",
                        }}
                      >
                        All Correct Answers Are Shown Here
                      </h5>
                    </div>
                  ) : ( */}
                  <div
                    className="col-md-3"
                    style={{ paddingBottom: "20px", paddingTop: "20px" }}
                  >
                    <button
                      style={{
                        height: "50px",
                        width: "140px",
                        backgroundColor: "lightgreen",
                        border: "none",
                        color: "white",
                      }}
                    >
                      <h5>
                        {" "}
                        {this.state.Questions.question_no}/{this.state.total}
                      </h5>
                    </button>
                  </div>
                  {/* )} */}
                  <div
                    className="col-md-6"
                    style={{ paddingBottom: "20px", paddingTop: "35px" }}
                  >
                    <p style={{ fontSize: "smaller" }}>
                      {/* 1 Right and 1 Wrong answer */}
                    </p>
                  </div>
                  <div className="row">
                    <h6 style={{}}>
                      {/* {this.state.Ques[this.state.count].question} */}
                    </h6>
                    {/* <Form.Group>
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_A}
                      name="opt"
                      id="formHorizontalRadios1"
                      value={this.state.optionC}
                      
                    />
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_B}
                      name="opt"
                      id="formHorizontalRadios2"
                    />
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_C}
                      name="opt"
                      id="formHorizontalRadios3"
                    />
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_D}
                      name="opt"
                      id="formHorizontalRadios4"
                    />
                  </Form.Group> */}
                    {/* {this.state.showsol ? (
                      <div>
                        <FormControl
                          component="fieldset"
                          style={{ paddingLeft: "4vh" }}
                        >
                          <FormLabel component="legend">
                            {this.state.Solution[this.state.sol].question}
                          </FormLabel>
                          <RadioGroup
                            // onChange={(evt) => {
                            //   this.Getanswer(evt);
                            // }}
                            // defaultValue={
                            //   this.state.Solution[this.state.sol].correct_answer
                            // }
                            aria-label="gender"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="A"
                              control={<Radio />}
                              checked={
                                "A" ===
                                this.state.Solution[this.state.sol]
                                  .correct_answer
                              }
                              label={
                                this.state.Solution[this.state.sol].option_A
                              }
                              id="A"
                            />
                            <FormControlLabel
                              value="B"
                              control={<Radio />}
                              checked={
                                "B" ===
                                this.state.Solution[this.state.sol]
                                  .correct_answer
                              }
                              label={
                                this.state.Solution[this.state.sol].option_B
                              }
                              id="B"
                            />
                            <FormControlLabel
                              value="C"
                              control={<Radio />}
                              checked={
                                "C" ===
                                this.state.Solution[this.state.sol]
                                  .correct_answer
                              }
                              label={
                                this.state.Solution[this.state.sol].option_C
                              }
                            />
                            <FormControlLabel
                              value="D"
                              control={<Radio />}
                              checked={
                                "D" ===
                                this.state.Solution[this.state.sol]
                                  .correct_answer
                              }
                              label={
                                this.state.Solution[this.state.sol].option_D
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    ) : ( */}
                    <FormControl
                      component="fieldset"
                      style={{ paddingLeft: "5vh" }}
                    >
                      <FormLabel component="legend">
                        Q: {this.state.Questions.question_no}.{" "}
                        {this.state.Questions.question}
                      </FormLabel>
                      <RadioGroup
                        onChange={(evt) => {
                          this.Getanswer(evt);
                        }}
                        aria-label="gender"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="A"
                          control={<Radio color="default" />}
                          label={`A) ${this.state.Questions.option_A}`}
                        />
                        <FormControlLabel
                          value="B"
                          control={<Radio color="default" />}
                          label={`B) ${this.state.Questions.option_B}`}
                        />
                        <FormControlLabel
                          value="C"
                          control={<Radio color="default" />}
                          label={`C) ${this.state.Questions.option_C}`}
                        />
                        <FormControlLabel
                          value="D"
                          control={<Radio color="default" />}
                          label={`D) ${this.state.Questions.option_D}`}
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* )} */}
                  </div>
                  {this.state.showsol ? (
                    <div className="row">
                      <div className="col-md-2">
                        {this.state.sol < 1 ? (
                          <Button
                            disabled
                            onClick={() => {
                              this.setState({ sol: this.state.sol - 1 });
                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({ sol: this.state.sol - 1 });
                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        )}
                      </div>
                      <div className="col-md-3">
                        {this.state.sol === this.state.total - 1 ? (
                          <Button
                            disabled
                            onClick={() => {
                              this.setState({ sol: this.state.sol + 1 });
                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({ sol: this.state.sol + 1 });

                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="row"
                      style={{ paddingTop: "25px", paddingBottom: "30px" }}
                    >
                      {/* <div className="col-md-2">
                        {this.state.count < 1 ? (
                          <Button
                            disabled
                            onClick={() => {
                              this.setState({ count: this.state.count - 1 });
                              this.getquestionList();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({ count: this.state.count - 1 });
                              this.getquestionList();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        )}
                      </div> */}
                      <div className="col-md-3">
                        {this.state.count === this.state.total - 1 ? (
                          <div>
                            {this.state.submitdisable ? (
                              <Button
                                disabled
                                onClick={() => {
                                  this.submitExam();
                                }}
                                style={{}}
                              >
                                {this.state.loading ? (
                                  <span>
                                    {" "}
                                    <ClipLoader color="white" size={20} />
                                    Just a sec...{" "}
                                  </span>
                                ) : (
                                  <span>Submit</span>
                                )}
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  this.submitExam();
                                }}
                                style={{}}
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                        ) : (
                          <Button
                            onClick={() => this.nextQuestion()}
                            style={{}}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                      {/* {this.state.count === this.state.total - 1 ? (
                        <div style={{ paddingTop: "3vh", paddingLeft: "3vh" }}>
                          <Button
                            onClick={() => {
                              this.submitExam();
                            }}
                          >
                            Submit{" "}
                          </Button>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  )}
                </div>
                <Modal
                  size="md"
                  show={this.state.examsubmitted}
                  onHide={() => this.setState({ examsubmitted: false })}
                  aria-labelledby="example-modal-sizes-title-sm"
                  animation="true"
                  autoFocus="true"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title>
                      {" "}
                      <PacmanLoader color="green" />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ height: "15vh" }}>
                    <div className="container">
                      {/*  */}
                      <p style={{ marginTop: "5vh" }}>
                        {this.state.submitresp}
                      </p>
                    </div>
                  </Modal.Body>
                </Modal>
                <div style={{ height: "80px" }}></div>
              </div>
            ) : (
              //_____________________________________________________________
              //_____________________________________________________________
              //_____________________________________________________________
              <div className="col-md-6 offset-1">
                {/* <div
                  className="row"
                  style={{ border: "1px solid lightgrey", height: "90px" }}
                >
                  <div>
                    <ProgressBar
                      now={now}
                      bar
                      label={`${now}%`}
                      variant="success"
                      style={{
                        height: "40px",
                        top: "25px",
                        position: "relative",
                      }}
                    />
                  </div>
                </div> */}

                <div
                  className="row"
                  style={{
                    border: "1px solid lightgrey",
                    position: "relative",
                    top: "20px",
                  }}
                >
                  {this.state.showsol ? (
                    <div className="col-md-6" style={{ paddingTop: "3vh" }}>
                      <h5
                        style={{
                          backgroundColor: "lightgreen",
                          color: "white",
                        }}
                      >
                        Solutions
                      </h5>
                    </div>
                  ) : (
                    <div
                      className="col-md-3"
                      style={{ paddingBottom: "20px", paddingTop: "20px" }}
                    >
                      {/* <button
                        style={{
                          height: "50px",
                          width: "140px",
                          backgroundColor: "lightgreen",
                          border: "none",
                          color: "white",
                        }}
                      >
                        <h5>
                          {" "}
                          {this.state.Questions.question_no}/{this.state.total}
                        </h5>
                      </button> */}
                    </div>
                  )}
                  <div
                    className="col-md-6"
                    style={{ paddingBottom: "20px", paddingTop: "35px" }}
                  >
                    <p style={{ fontSize: "smaller" }}>
                      {/* 1 Right and 1 Wrong answer */}
                    </p>
                  </div>
                  <div className="row">
                    <h6 style={{}}>
                      {/* {this.state.Ques[this.state.count].question} */}
                    </h6>
                    {/* <Form.Group>
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_A}
                      name="opt"
                      id="formHorizontalRadios1"
                      value={this.state.optionC}
                      
                    />
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_B}
                      name="opt"
                      id="formHorizontalRadios2"
                    />
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_C}
                      name="opt"
                      id="formHorizontalRadios3"
                    />
                    <Form.Check
                      type="radio"
                      label={this.state.Questions.option_D}
                      name="opt"
                      id="formHorizontalRadios4"
                    />
                  </Form.Group> */}
                    {this.state.showsol ? (
                      <div>
                        <FormControl
                          component="fieldset"
                          style={{ paddingLeft: "4vh" }}
                        >
                          <FormLabel component="legend">
                            Q: {this.state.Questions.question_no}.{" "}
                            {this.state.Solution[this.state.sol].question}
                          </FormLabel>
                          <RadioGroup
                            // onChange={(evt) => {
                            //   this.Getanswer(evt);
                            // }}
                            // defaultValue={
                            //   this.state.Solution[this.state.sol].correct_answer
                            // }
                            aria-label="gender"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="A"
                              control={<Radio color="default" />}
                              checked={
                                "A" ===
                                this.state.Solution[this.state.sol].given_answer
                              }
                              label={`A) ${
                                this.state.Solution[this.state.sol].option_A
                              }`}
                              id="A"
                            />
                            <FormControlLabel
                              value="B"
                              control={<Radio color="default" />}
                              checked={
                                "B" ===
                                this.state.Solution[this.state.sol].given_answer
                              }
                              label={`B) ${
                                this.state.Solution[this.state.sol].option_B
                              }`}
                              id="B"
                            />
                            <FormControlLabel
                              value="C"
                              control={<Radio color="default" />}
                              checked={
                                "C" ===
                                this.state.Solution[this.state.sol].given_answer
                              }
                              label={`C ${
                                this.state.Solution[this.state.sol].option_C
                              }`}
                            />
                            <FormControlLabel
                              value="D"
                              control={<Radio color="default" />}
                              checked={
                                "D" ===
                                this.state.Solution[this.state.sol].given_answer
                              }
                              label={`D) ${
                                this.state.Solution[this.state.sol].option_D
                              }`}
                            />
                          </RadioGroup>
                        </FormControl>
                        <h6>
                          Correct Answer: -{" "}
                          {this.state.Solution[this.state.sol].correct_answer}
                        </h6>
                      </div>
                    ) : (
                      // <FormControl
                      //   component="fieldset"
                      //   style={{ paddingLeft: "5vh" }}
                      // >
                      //   <FormLabel component="legend">
                      //     {this.state.Questions.question}
                      //   </FormLabel>
                      //   <RadioGroup
                      //     onChange={(evt) => {
                      //       this.Getanswer(evt);
                      //     }}
                      //     aria-label="gender"
                      //     name="radio-buttons-group"
                      //   >
                      //     <FormControlLabel
                      //       value="A"
                      //       control={<Radio />}
                      //       label={this.state.Questions.option_A}
                      //     />
                      //     <FormControlLabel
                      //       value="B"
                      //       control={<Radio />}
                      //       label={this.state.Questions.option_B}
                      //     />
                      //     <FormControlLabel
                      //       value="C"
                      //       control={<Radio />}
                      //       label={this.state.Questions.option_C}
                      //     />
                      //     <FormControlLabel
                      //       value="D"
                      //       control={<Radio />}
                      //       label={this.state.Questions.option_D}
                      //     />
                      //   </RadioGroup>
                      // </FormControl>

                      <div>
                        <h5
                          style={{ paddingLeft: "35vh", paddingBottom: "10vh" }}
                        >
                          Your ScoreCard
                        </h5>
                        <div
                          className="row"
                          style={{
                            borderBottom: "1px solid lightgrey",
                            paddingTop: "10px",
                          }}
                        >
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>
                              Total Number Of Questions
                            </p>
                          </div>
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>
                              {this.state.totalscore}
                            </p>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            borderBottom: "1px solid lightgrey",
                            paddingTop: "10px",
                          }}
                        >
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>
                              Correct Answers
                            </p>
                          </div>
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>
                              {this.state.ScoreCard.correct_count}
                            </p>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            borderBottom: "1px solid lightgrey",
                            paddingTop: "10px",
                          }}
                        >
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>Wrong Answers</p>
                          </div>
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>
                              {this.state.ScoreCard.incorrect_count}
                            </p>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            paddingTop: "10px",
                          }}
                        >
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>
                              Unanswered Answers
                            </p>
                          </div>
                          <div className="col-md-5 offset-1">
                            <p style={{ fontSize: "smaller" }}>
                              {this.state.ScoreCard.unanswered_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.state.showsol ? (
                    <div className="row">
                      <div className="col-md-2">
                        {this.state.sol < 1 ? (
                          <Button
                            disabled
                            onClick={() => {
                              this.setState({ sol: this.state.sol - 1 });
                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({ sol: this.state.sol - 1 });
                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        )}
                      </div>
                      <div className="col-md-3">
                        {this.state.sol === this.state.totalsol - 1 ? (
                          <Button
                            disabled
                            onClick={() => {
                              this.setState({ sol: this.state.sol + 1 });
                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({ sol: this.state.sol + 1 });

                              this.getSolutions();
                            }}
                            style={{}}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="row"
                      style={{ paddingTop: "25px", paddingBottom: "30px" }}
                    >
                      {/* <div className="col-md-2">
                        {this.state.count < 1 ? (
                          <Button
                            disabled
                            onClick={() => {
                              this.setState({ count: this.state.count - 1 });
                              this.getquestionList();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({ count: this.state.count - 1 });
                              this.getquestionList();
                            }}
                            style={{}}
                          >
                            Prev
                          </Button>
                        )}
                      </div> */}
                      {/* <div className="col-md-3">
                        {this.state.count === this.state.total - 1 ? (
                          <Button
                            disabled
                            onClick={() => {
                              this.setState({ count: this.state.count + 1 });
                              this.getquestionList();
                            }}
                            style={{}}
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({ count: this.state.count + 1 });

                              this.getquestionList();
                            }}
                            style={{}}
                          >
                            Next
                          </Button>
                        )}
                      </div> */}
                      {this.state.count === this.state.total - 1 ? (
                        <div style={{ paddingTop: "3vh", paddingLeft: "3vh" }}>
                          {/* <Button
                            onClick={() => {
                              this.submitExam();
                            }}
                          >
                            Submit{" "}
                          </Button> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <Modal
                  size="md"
                  show={this.state.examsubmitted}
                  onHide={() => this.setState({ examsubmitted: false })}
                  aria-labelledby="example-modal-sizes-title-sm"
                  animation="true"
                  autoFocus="true"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title>
                      {" "}
                      <PacmanLoader color="green" />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ height: "15vh" }}>
                    <div className="container">
                      {/*  */}
                      <p style={{ marginTop: "5vh" }}>
                        {this.state.submitresp}
                      </p>
                    </div>
                  </Modal.Body>
                </Modal>
                <div style={{ height: "80px" }}></div>
              </div>
            )}

            <div className="col-md-4" style={{ paddingLeft: "25px" }}>
              <div className="col">
                {/* <div
                  className="row"
                  style={{ height: "100px", paddingTop: "30px" }}
                >
                  <div className="col-md-5 offset-1">
                    <button
                      style={{
                        height: "35px",
                        backgroundColor: "rgb(24, 169, 218)",
                        border: "none",
                        color: "white",
                        width: "25vh",
                        fontSize: "smaller",
                      }}
                    >
                      <FaLongArrowAltLeft color="white" /> PREV LESSON
                    </button>
                  </div>
                  <div className="col-md-1 ">
                    <button
                      style={{
                        height: "35px",
                        width: "25vh",
                        backgroundColor: "rgb(24, 169, 218)",
                        border: "none",
                        color: "white",
                        fontSize: "smaller",
                      }}
                    >
                      <FaLongArrowAltRight color="white" /> NEXT LESSON
                    </button>
                  </div>
                </div> */}
              </div>
              {!this.state.ongoingexam ? (
                <div>
                  <div
                    className="row"
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      paddingTop: "20px",
                      paddingBottom: "3vh",
                    }}
                  >
                    <div className="col-md-8">
                      <p
                        style={{
                          color: "white",
                          positon: "relative",
                          top: "15px",
                          left: "10px",
                        }}
                      >
                        <FaLongArrowAltRight />
                        EXAM DETAILS
                      </p>
                    </div>
                    <div className="col-md-2">
                      <Button
                        variant="none"
                        style={{
                          borderRadius: "50%",
                          border: "2px solid lightblue",
                          color: "white",
                        }}
                        onClick={() => {
                          this.setPrevexam();
                        }}
                      >
                        <AiOutlineDoubleLeft />
                      </Button>
                    </div>
                    <div className="col-md-2">
                      <Button
                        variant="none"
                        style={{
                          borderRadius: "50%",
                          border: "2px solid lightblue",
                          color: "white",
                        }}
                        onClick={() => {
                          this.setNextexam();
                        }}
                      >
                        <AiOutlineDoubleRight />
                      </Button>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>Exam Type</p>
                    </div>
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>
                        {this.state.Myexam.exam_type}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>Topic Name</p>
                    </div>
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>
                        {this.state.Myexam.topic_name}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>Exam Name</p>
                    </div>
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>
                        {this.state.Myexam.exam_name}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>Exam Date & Time</p>
                    </div>
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>
                        {this.state.Myexam.date} & {this.state.Myexam.time}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>Exam Score</p>
                    </div>
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>
                        {this.state.Myexam.score}
                      </p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>Marks</p>
                    </div>
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>20</p>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>Percentage</p>
                    </div>
                    <div className="col-md-5 offset-1">
                      <p style={{ fontSize: "smaller" }}>
                        {this.state.Myexam.percentage}
                      </p>
                    </div>
                  </div>
                  {this.state.showscorecard
                    ? ""
                    : // <div>
                      //   <div
                      //     className="row"
                      //     style={{
                      //       borderBottom: "1px solid lightgrey",
                      //       paddingTop: "10px",
                      //     }}
                      //   >
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>
                      //         Total Number Of Questions
                      //       </p>
                      //     </div>
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>
                      //         {this.state.ScoreCard.total_no_of_question}
                      //       </p>
                      //     </div>
                      //   </div>
                      //   <div
                      //     className="row"
                      //     style={{
                      //       borderBottom: "1px solid lightgrey",
                      //       paddingTop: "10px",
                      //     }}
                      //   >
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>Correct Answers</p>
                      //     </div>
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>
                      //         {this.state.ScoreCard.correct_count}
                      //       </p>
                      //     </div>
                      //   </div>
                      //   <div
                      //     className="row"
                      //     style={{
                      //       borderBottom: "1px solid lightgrey",
                      //       paddingTop: "10px",
                      //     }}
                      //   >
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>Wrong Answers</p>
                      //     </div>
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>
                      //         {this.state.ScoreCard.incorrect_count}
                      //       </p>
                      //     </div>
                      //   </div>
                      //   <div
                      //     className="row"
                      //     style={{
                      //       borderBottom: "1px solid lightgrey",
                      //       paddingTop: "10px",
                      //     }}
                      //   >
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>
                      //         Unanswered Answers
                      //       </p>
                      //     </div>
                      //     <div className="col-md-5 offset-1">
                      //       <p style={{ fontSize: "smaller" }}>
                      //         {this.state.ScoreCard.unanswered_count}
                      //       </p>
                      //     </div>
                      //   </div>
                      // </div>
                      ""}
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="col-md-5 offset-1">
                      <h6 style={{ fontSize: "smaller" }}>Performance</h6>
                    </div>
                    <div className="col-md-5 offset-1">
                      <h5 style={{ fontSize: "small" }}>
                        {this.state.Myexam.performance}
                      </h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-5" style={{ paddingTop: "20px" }}>
                      {" "}
                      {localStorage.getItem("uniqueID") != null ? (
                        <Button
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            border: "none",
                            color: "white",
                            height: "35px",
                          }}
                          onClick={() => {
                            this.getSolutions();
                          }}
                        >
                          View Solutions
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="col-md-6" style={{ paddingTop: "20px" }}>
                      {localStorage.getItem("uniqueID") != null ? (
                        <Button
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            border: "none",
                            color: "white",
                            height: "35px",
                          }}
                          onClick={() => {
                            this.getScoreCard();
                          }}
                        >
                          Check ScoreCard
                        </Button>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CodingTestExam;
