import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { withStyles } from "@material-ui/styles";
import Homebox from "./Homebox.js";
import Hometeachers from "./Hometeachers.js";

class Homepage extends Component {
  render() {
    return (
      <div>
        <div className="banner">
          <img src={require("../assets/img/banner.jpeg").default} alt="" />
          {/* <div style={{marginTop:"20px"}}> */}
          <h1>
            WELCOME TO THE NEXT GENERATION <br></br>EDUCATION SYSTEM
          </h1>
          <p>
            Lorem ipsum is a dummy text that has no meaning, its completely
            useless
          </p>
          <button>Learn More</button>
          {/* </div> */}
        </div>

        <div className="container my-cont">
          <div className="row">
            <div className="col-md-6">
              <h3>WHY DO WE USE IT ?</h3>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters , as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>

              <p>
                Many desktop publishing packages and web page editors now use
                Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy .
                Various versions have evolved over the years, sometimes by
                accident , sometimes on purpose(injected humour and the like).
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={require("../assets/img/videoimg.jpeg").default}
                alt=""
              />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="container my-bar-cont">
          <div className="row">
            <div className="col-md-3">
              <h1>10234</h1>
              <p>Registered Students</p>
            </div>
            <div className="col-md-3">
              <h1>5036</h1>
              <p>Registered Teachers</p>
            </div>
            <div className="col-md-3">
              <h1>4078</h1>
              <p>Registered Game Academy</p>
            </div>
            <div className="col-md-3">
              <h1>7046</h1>
              <p>Registered Observers</p>
            </div>
          </div>
        </div>
        <br></br>

        <Homebox />
        <Hometeachers />
      </div>
    );
  }
}

export default Homepage;
