import React, { Component } from "react";
// import { Form, Button, Select } from "react-bootstrap";
import API from "./api";
import { MdClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { Link } from "@mui/material";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  MenuItem,
  Button,
  Select
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

class Subscriber extends Component {
  state = {
    classselected: 7,
    classlist: [],
    // subcribedornot: null,
    // subcribedornot2: null,
    subscribedata: [],
    result: "",
    subsdata: "",
  };
  componentDidMount() {
    this.getclassList();
    this.getsubscriber();
  }
  classChanged(e) {
    console.log("dsf", e.target.value);
    this.state.classselected = e.target.value;
    // this.setState({ classselected: e.target.value });
    this.getsubscriber();
  }
  getsubscriber() {
    const params = {
      school_id: localStorage.getItem("school_id"),
      class_id: this.state.classselected,
    };
    var that = this;
    API.post("SchoolApi/subscribers", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          subscribedata: response.data.data,
          result: response.data.result,
        });
        // that.state.subscribedata.map((l, id) => {
        //   that.setState({ subsdata: l.is_subscribed });
        // });
        // that.setState({ subsdata: response.data.data.is_subscribed });
        // if (that.state.subsdata === "Y") {
        //   that.setState({ subcribedornot: true, subcribedornot2: false });
        // } else {
        //   that.setState({ subcribedornot: false, subcribedornot2: true });
        // }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () { });
  }

  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", {
      school_id: localStorage.getItem("school_id"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1>SUBSCRIBERS</h1>
          <p>
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home{" "}
            </Link>{" "}
            |
            <Link
              href="/observer_profile"
              style={{ textDecoration: "none", color: "white" }}
            >
              School Observer
            </Link>{" "}
            | Subcribers
          </p>
        </div>
        <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={9} lg={9}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>SUBSCRIBERS</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl variant="filled" fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="selectGame"
                  input={<OutlinedInput />}
                  value={this.state.classselected}
                  onChange={(e) => {
                    this.classChanged(e);
                  }}
                >
                  {this.state.classlist.map((l, i) => {
                    return (
                      <MenuItem key={i} value={l.class_id}>
                        {l.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  backgroundColor: "rgb(24, 169, 218)",
                  height: "2px",
                  width: "13%",
                  marginBottom: "12px"
                }}>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Container style={{ marginBottom: "12px", marginTop: "20px", minHeight: "500px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
              
                style={{
                  textTransform: "capitalize",
                  backgroundColor:"lightgrey"
                }}
                fullWidth
              >
                <Box display="flex" style={{ width: "100%" }}>
                  <Box style={{ width: "50%", textAlign: 'left' }}><Typography variant="h6" style={{ color: "black" }}>Student List</Typography></Box>
                  <Box style={{ width: "25%" }}><Typography variant="h6" style={{ color: "black" }}>Subscribed</Typography></Box>
                  <Box style={{ width: "25%" }}><Typography variant="h6" style={{ color: "black" }}>Non-Subscribed</Typography></Box>
                </Box>
              </Button>
            </Grid>
            {this.state.subscribedata.length > 0 &&
              <>{this.state.subscribedata.map((l, i) => {
                return (<Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="outlined"
                    style={{
                      border: "1px solid lightgrey",
                      textTransform: "capitalize"
                    }}
                    fullWidth
                  >
                    <Box display="flex" style={{ width: "100%" }} paddingY={1}>
                      <Box style={{ width: "50%", textAlign: 'left' }}><Typography style={{ color: "black" }}>{l.name}</Typography></Box>
                      <Box style={{ width: "25%" }}><CheckCircleIcon style={{ color: l?.is_subscribed === "Y" ? "lightgreen" : "lightgrey", fontSize: "2rem" }} /></Box>
                      <Box style={{ width: "25%" }}><CancelIcon style={{ color: l?.is_subscribed === "Y" ? "lightgrey" : "red", fontSize: "2rem" }} /></Box>
                    </Box>
                  </Button>
                </Grid>
                );
              })}
              </>
            }
            {this.state.subscribedata.length === 0 &&
              <Grid item xs={12} sm={12} md={12} lg={12}>

                <Box display="flex" style={{ width: "100%" }} paddingY={1} justifyContent="center">
                  <Box ><Typography >Data Not Found</Typography></Box>
                </Box>
              </Grid>
            }

          </Grid>
        </Container>
        {/* <div className="container">
          <div className="row" style={{ paddingTop: "3vh" }}>
            <div
              className="col-md-3 offset-1"
              style={{ flex: "1", paddingTop: "3vh" }}
            >
              <Typography variant="h5">SUBSCRIBERS</Typography>
              <div
                style={{
                  height: "3px",
                  width: "80px",
                  textDecoration: "underline",
                  backgroundColor: "lightblue",
                }}
              ></div>
            </div>
            <div className="col-md-3">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-select-currency"
                  select
                  label=""
                  value={this.state.classselected}
                  onChange={(e) => {
                    this.classChanged(e);
                  }}
                  helperText="Please select Class"
                >
                  {this.state.classlist.map((option, id) => (
                    <MenuItem key={option.value} value={option.class_id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </div>
          </div>
          <div className="container subcribecont">
            <Box>
              <div className="row" style={{ paddingTop: "5vh" }}>
                <div className="col-md-6 offset-1">
                  <Typography variant="h6">Student Lists</Typography>
                  <div className="row"></div>
                </div>
                <div className="col-md-2">
                  <Typography variant="h6">Subscribed</Typography>
                </div>
                <div className="col-md-2">
                  <Typography variant="h6">Non - Subscribed</Typography>
                </div>
              </div>
              {this.state.result === 0 ? (
                <div style={{}}>No data </div>
              ) : (
                <div>
                  {this.state.subscribedata.map((l, id) => {
                    return (
                      <div>
                        <Box className={classes.personalInfoBox}>
                          <div
                            className="row"
                            style={{
                              marginTop: " 3vh",
                            }}
                          >
                            <div className="col-md-6 offset-1">{l.name}</div>

                            <div
                              className="col-md-2"
                              style={{
                                paddingBottom: "3vh",
                                marginLeft: "4vh",
                              }}
                            >
                              <Button
                                style={{ borderRadius: "50%" }}
                                disabled
                                className={
                                  l.is_subscribed === "Y"
                                    ? "subscribed"
                                    : "nonsubscribed"
                                }
                              >
                                <FaCheck color="white" />
                              </Button>
                            </div>
                            <div
                              className="col-md-2 "
                              style={{ marginLeft: "4vh" }}
                            >
                              <Button
                                style={{ borderRadius: "50%" }}
                                disabled
                                className={
                                  l.is_subscribed === "N"
                                    ? "subscribed1"
                                    : "nonsubscribed2"
                                }
                              >
                                <MdClose color="white" />
                              </Button>
                            </div>
                          </div>
                        </Box>
                      </div>
                    );
                  })}
                </div>
              )}
            </Box>
          </div>
        </div> */}
      </div>
    );
  }
}
const styles = (theme) => ({
  rowBox: {
    display: "flex",

    marginTop: "8px",
    marginBottom: "12px",
  },
  personalInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "1vh",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingTop: "4px",
    paddingBottom: "4px",
    display: "flex",
    flexDirection: "column",
  },
});
export default withStyles(styles)(Subscriber);
