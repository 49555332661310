import React, { Component } from "react";
import ReactPlayer from "react-player";
import { FaLongArrowAltRight } from "react-icons/fa";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../../provider/useWindowSize'
import MessageIcon from '@mui/icons-material/Message';
import API from "../../components/api";

const Innovationvideo=({classes})=>{
    return (
      <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
          <h1>MOTIVATION VIDEOS</h1>
          <p><Link href="/" style={{ color: "white", textDecoration: "none" }}>Home</Link> | Teacher | Subject | Innovative Ideas</p>
        
      </div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box display="flex" flexDirection="column">
              <Box className={classes.demoPlayerBox}>
                <ReactPlayer 
                url="https://youtu.be/T6Wufigr1p4" 
                playing="true" 
                width='100%'
                controls
                height='100%' 
                loop="true"  />
              </Box>
              <Box marginY={2}>
                <Typography variant="h6">ABCD</Typography>
              </Box>
              <Box display="flex" padding={2} style={{backgroundColor:"lightgray"}}>
                <Box style={{ width: "50%" }}>
                  <Typography style={{fontSize:"15px",fontWeight:"bold"}}>Posted On</Typography>
                  <Typography paddingY={1}>9th August,2021</Typography>
                </Box>
                <Box style={{ width: "50%" }} >
                  <Typography style={{fontSize:"15px",fontWeight:"bold"}}>Duration</Typography>
                  <Typography paddingY={1}>8 Min</Typography>
                </Box>
              </Box>
              
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box display="flex" flexDirection="column">
              <Box style={{ width: "100%", height: "71px", backgroundColor: "deepskyblue" }} display="flex">
                <Box marginX={4} style={{ alignSelf: "center", color: "white" }}>
                  <FaLongArrowAltRight />
                </Box>
                <Box style={{ alignSelf: "center", color: "white" }}>
                  <Typography>SIMILAR VIDEOS</Typography>
                </Box>

              </Box>
              {/* <Box>
                <Table stickyHeader aria-label="sticky table">

                  {category === "Motivational" && <TableBody>
                    {motivationalVideos.map((video,index )=> (
                      <>
                      <TableRow hover onClick={()=>{setOpenedVideo(video)}} onMouseOver={()=>{setPlayVideo(true)}} onMouseOut={()=>{setPlayVideo(false)}}>
                          <Box display="flex" >
                            <Box style={{width:"40%"}} padding={1}>
                              <ReactPlayer 
                              url={video?.file} 
                              width='100%'
                              playing={playVideo?true:false}
                              height='130px'
                              loop="true" 
                              muted="true" />
                            </Box>
                            <Box style={{width:"60%",alignSelf:"center"}} padding={2}>
                              <Typography>{video?.name}</Typography>
                              <Typography>9th August,2021</Typography>
                            </Box>
                          </Box>

                      </TableRow>
                      {index !== Number(motivationalVideos.length)-1 && <Divider style={{marginTop:"4px",marginBottom:"4px"}}/>}
                      </>
                    ))}

                  </TableBody>}
                  <TableBody>
                    {trainingVideos.map((video,index )=> (
                      <>
                      <TableRow hover onClick={()=>{setOpenedVideo(video)}} onMouseOver={()=>{setPlayVideo(true)}} onMouseOut={()=>{setPlayVideo(false)}}>
                          <Box display="flex" >
                            <Box style={{width:"40%"}} padding={1}>
                              <ReactPlayer 
                              url={video?.file} 
                              width='100%'
                              playing={playVideo?true:false}
                              height='130px'
                              loop="true" 
                              muted="true" />
                            </Box>
                            <Box style={{width:"60%",alignSelf:"center"}} padding={2}>
                              <Typography>{video?.name}</Typography>
                              <Typography>9th August,2021</Typography>
                            </Box>
                          </Box>

                      </TableRow>
                      {index !== Number(trainingVideos.length)-1 && <Divider style={{marginTop:"4px",marginBottom:"4px"}}/>}
                      </>
                    ))}

                  </TableBody>
                </Table>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
        {/* <div className="banner-register">
          <img
            className=""
            src={require("../../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>SUBJECT</h1>
          <p>Home | Teacher | Subject | Innovative Ideas</p>
        </div>
        <div
          className="container innovation-cont"
          style={{ paddingTop: "30px" }}
        >
          <div className="row innovation-row">
            <div className="col-md-6 offset-1" innovation-col>
              <ReactPlayer
                playing={true}
                width="500px"
                url="https://youtu.be/T6Wufigr1p4"
              />
              <div className="row">
                <h5>START TODAY NOT TOMORROW</h5>
              </div>
              <div
                className="container"
                style={{ backgroundColor: "lightgrey", height: "100px" }}
              >
                <div className="row">
                  <div className="col-md-4">
                    <h6
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "25px",
                      }}
                    >
                      Posted On
                    </h6>
                    <p
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "15px",
                      }}
                    >
                      11 aug, 2021
                    </p>
                  </div>
                  <div className="col-md-3 ">
                    <h6
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "25px",
                      }}
                    >
                      Duration
                    </h6>
                    <p
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "15px",
                      }}
                    >
                      20 minutes
                    </p>
                  </div>
                </div>
                <div style={{ height: "150px" }}></div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <button
                  style={{
                    backgroundColor: "blue",
                    border: "none",
                    height: "40px",
                  }}
                >
                  <FaLongArrowAltRight />
                  SIMILAR VIDEOS
                </button>
              </div>
              <div className="row"></div>
            </div>
          </div>
        </div> */}
      </>
    );
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  demoPlayerBox: {
    width: "100%%",
    height: "400px",
    objectFit: "cover",
    // border: "1px solid black",
    // borderRadius: "1px",
  },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(Innovationvideo);
