import React, { Component } from "react";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { FaLongArrowAltRight } from "react-icons/fa";
import API from "./api";

class StudentSubs extends Component {
  state = {
    teacher: false,
    gameacademy: false,
    page: 3,
    Subscriptions: [],
    TeachersList: [],
    subsmsg: "",
    teachermsg: "",
    sermap: true,
    profiledata: "",
  };
  componentDidMount() {
    this.getSubscriptiondetails();
    this.getTeachers();
    this.getProfile();
  }
  getProfile() {
    const userid = localStorage.getItem("userID");
    var that = this;
    API.post("StudentApi/profile", { user_id: userid })
      .then(function (response) {
        console.log(response);
        that.setState({ profiledata: response.data.data.subscription });
        that.getclassList();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getTeachers() {
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "view",
    };
    var that = this;
    API.post("StudentApi/tutionSubscription", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          TeachersList: response.data.data,
          teachermsg: response.data.message,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  getSubscriptiondetails() {
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "view",
    };
    var that = this;
    API.post("StudentApi/gameSubscription", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Subscriptions: response.data.data,
          subsmsg: response.data.message,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1>SUBSCRIPTIONS</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              Student
            </Link>{" "}
            | Subscription
          </p>
        </div>
        <Container>
          <Typography variant="h6"> SUBSCRIPTIONS</Typography>
          <div
            style={{
              backgroundColor: "rgb(24, 169, 218)",
              height: "2px",
              width: "142px",
              marginBottom: "12px",
            }}
          ></div>
        </Container>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} xs={12} sm={12}>
              <Box display="flex" flexDirection="column">
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      teacher: false,
                      gameacademy: false,
                      //   news: false,
                      sermap: true,
                      page: 3,
                    });
                  }}
                  className={
                    this.state.sermap ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>SERMAPS SUBSCRIPTION </Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      teacher: true,
                      gameacademy: false,
                      //   news: false,
                      sermap: false,
                      page: 1,
                    });
                  }}
                  className={
                    this.state.teacher ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>TEACHER </Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      teacher: false,
                      gameacademy: true,
                      sermap: false,
                      page: 2,
                    });
                  }}
                  className={
                    this.state.gameacademy ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>GAMEACADEMY </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={8} md={8} xs={12} sm={12}>
              {this.state.page === 1 && (
                <Container>
                  <Typography>TEACHER SUBSCRIPTION</Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "142px",
                      marginBottom: "12px",
                    }}
                  ></div>
                  <Grid container spacing={2}>
                    {this.state.TeachersList === undefined ? (
                      <Box style={{ paddingTop: "2vh" }}>
                        No Subscription Taken
                      </Box>
                    ) : (
                      <>
                        {this.state.TeachersList.map((l, index) => {
                          return (
                            <>
                              <Grid item xs={11} sm={11} md={11} lg={11}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  style={{
                                    width: "100%",
                                    border: "1px solid lightgrey",
                                    borderRadius: "5px",
                                  }}
                                  paddingX={2}
                                  paddingY={1}
                                >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {l.subject}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>{l.teacher}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography style={{ opacity: "0.8" }}>
                                      {l.date}
                                    </Typography>
                                  </Box>
                                  <Divider
                                    style={{
                                      marginTop: "8px",
                                      marginBottom: "8px",
                                      color: "lightgrey",
                                    }}
                                  />
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Start Time
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.timing.from_time}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          End Time
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.timing.to_time}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Divider
                                    style={{
                                      marginTop: "8px",
                                      marginBottom: "8px",
                                      color: "lightgrey",
                                    }}
                                  />
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Fees
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>: {l.fees}</Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Transaction Id
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.transaction_id}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                            </>
                          );
                        })}
                      </>
                    )}
                  </Grid>
                </Container>
              )}
              {this.state.page === 2 && (
                <Container>
                  <Typography>GAMEACADEMY SUBSCRIPTION</Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "142px",
                      marginBottom: "12px",
                    }}
                  ></div>
                  <Grid container spacing={2}>
                    {this.state.Subscriptions === undefined ? (
                      <Box style={{ paddingTop: "2vh" }}>
                        No Subscription Taken
                      </Box>
                    ) : (
                      <>
                        {this.state.Subscriptions.map((l, index) => {
                          return (
                            <>
                              <Grid item xs={11} sm={11} md={12} lg={12}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  style={{
                                    width: "100%",
                                    border: "1px solid lightgrey",
                                    borderRadius: "5px",
                                  }}
                                  paddingX={2}
                                  paddingY={1}
                                >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {l.game}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>{l.academy}</Typography>
                                  </Box>
                                  <Divider
                                    style={{
                                      marginTop: "8px",
                                      marginBottom: "8px",
                                      color: "lightgrey",
                                    }}
                                  />
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Start Time
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.timing.from_time}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          End Time
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.timing.to_time}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Divider
                                    style={{
                                      marginTop: "8px",
                                      marginBottom: "8px",
                                      color: "lightgrey",
                                    }}
                                  />
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Fees
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>: {l.fees}</Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Transaction Id
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.transaction_id}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                            </>
                          );
                        })}
                      </>
                    )}
                  </Grid>
                </Container>
              )}
              {this.state.page === 3 && (
                <Container>
                  <Typography>SERMAPS SUBSCRIPTION</Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "142px",
                      marginBottom: "12px",
                    }}
                  ></div>
                  <Grid container spacing={2}>
                    {this.state.Subscriptions === undefined ? (
                      <Box style={{ paddingTop: "2vh" }}>
                        No Subscription Taken
                      </Box>
                    ) : (
                      <>
                        <>
                          <Grid item xs={11} sm={11} md={12} lg={12}>
                            {this.state.profiledata === "" ||
                            [] ||
                            undefined ? (
                              <Box
                                display="flex"
                                flexDirection="column"
                                style={{
                                  width: "100%",
                                  border: "1px solid lightgrey",
                                  borderRadius: "5px",
                                }}
                                paddingX={2}
                                paddingY={1}
                              >
                                <Box>
                                  <Typography
                                    variant="h5"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {this.state.profiledata.subscription_name}
                                  </Typography>
                                </Box>
                                <Box>
                                  {/* <Typography>{l.academy}</Typography> */}
                                </Box>
                                <Divider
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    color: "lightgrey",
                                  }}
                                />
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box display="flex" style={{ width: "50%" }}>
                                    <Box
                                      style={{
                                        width: "40%",
                                        textAlign: "left",
                                        color: "black",
                                      }}
                                    >
                                      <Typography
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Start Date
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography>
                                        : {this.state.profiledata.start_date}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box display="flex" style={{ width: "50%" }}>
                                    <Box
                                      style={{
                                        width: "40%",
                                        textAlign: "left",
                                        color: "black",
                                      }}
                                    >
                                      <Typography
                                        style={{ fontWeight: "bold" }}
                                      >
                                        End Date
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography>
                                        :{this.state.profiledata.end_date}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Divider
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    color: "lightgrey",
                                  }}
                                />
                                {/* <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box display="flex" style={{ width: "50%" }}>
                                  <Box
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Fees
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>: {l.fees}</Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" style={{ width: "50%" }}>
                                  <Box
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Transaction Id
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography>
                                      : {l.transaction_id}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box> */}
                              </Box>
                            ) : (
                              <Box>
                                Your Subscription is over please recharge your
                                account
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                        </>
                      </>
                    )}
                  </Grid>
                </Container>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* <Grid item lg={4} md={4} xs={12} sm={12}>
          <Box display="flex">
            <Box display="flex" flexDirection="column">
              <Box
                marginTop={1}
                display="flex"
                onClick={() => {
                  this.setState({
                    teacher: true,
                    gameacademy: false,

                    page: 1,
                  });
                }}
                className={
                  this.state.teacher ? classes.cliked : classes.notclicked
                }
              >
                <Box marginX={4} style={{ alignSelf: "center" }}>
                  <FaLongArrowAltRight />
                </Box>
                <Box style={{ alignSelf: "center" }}>
                  <Typography>TEACHER </Typography>
                </Box>
              </Box>
              <Box
                marginTop={1}
                display="flex"
                onClick={() => {
                  this.setState({
                    teacher: false,
                    gameacademy: true,

                    page: 2,
                  });
                }}
                className={
                  this.state.gameacademy ? classes.cliked : classes.notclicked
                }
              >
                <Box marginX={4} style={{ alignSelf: "center" }}>
                  <FaLongArrowAltRight />
                </Box>
                <Box style={{ alignSelf: "center" }}>
                  <Typography>GAMEACADEMY </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: "1vh" }}
            >
              {this.state.page === 1 ? (
                <Box style={{ marginLeft: "2vh" }}>
                  {" "}
                  <Container>
                    <Typography>TEACHER SUBSCRIPTION</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom: "12px",
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      {this.state.TeachersList === undefined ? (
                        <Box style={{ paddingTop: "2vh" }}>
                          No Subscription Taken
                        </Box>
                      ) : (
                        <>
                          {this.state.TeachersList.map((l, index) => {
                            return (
                              <>
                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{
                                      width: "100%",
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                    }}
                                    paddingX={2}
                                    paddingY={1}
                                  >
                                    <Box>
                                      <Typography
                                        variant="h5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {l.subject}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography>{l.teacher}</Typography>
                                    </Box>
                                    <Divider
                                      style={{
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        color: "lightgrey",
                                      }}
                                    />
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box
                                        display="flex"
                                        style={{ width: "50%" }}
                                      >
                                        <Box
                                          style={{
                                            width: "40%",
                                            textAlign: "left",
                                            color: "black",
                                          }}
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Fees
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography>: {l.fees}</Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display="flex"
                                        style={{ width: "50%" }}
                                      >
                                        <Box
                                          style={{
                                            width: "40%",
                                            textAlign: "left",
                                            color: "black",
                                          }}
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Transaction Id
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography>
                                            : {l.transaction_id}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                 
                                </Grid>
                              </>
                            );
                          })}
                        </>
                      )}
                    </Grid>
                  </Container>
                </Box>
              ) : (
                ""
              )}
              {this.state.page === 2 ? (
                <Box>
                  {" "}
                  <Container>
                    <Typography>GAMEACADEMY SUBSCRIPTION</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom: "12px",
                      }}
                    ></div>
                    <Grid container spacing={2}>
                      {this.state.Subscriptions === undefined ? (
                        <Box style={{ paddingTop: "2vh" }}>
                          No Subscription Taken
                        </Box>
                      ) : (
                        <>
                          {this.state.Subscriptions.map((l, index) => {
                            return (
                              <>
                                <Grid item xs={11} sm={11} md={12} lg={12}>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    style={{
                                      width: "100%",
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                    }}
                                    paddingX={2}
                                    paddingY={1}
                                  >
                                    <Box>
                                      <Typography
                                        variant="h5"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {l.game}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography>{l.academy}</Typography>
                                    </Box>
                                    <Divider
                                      style={{
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        color: "lightgrey",
                                      }}
                                    />
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box
                                        display="flex"
                                        style={{ width: "50%" }}
                                      >
                                        <Box
                                          style={{
                                            width: "40%",
                                            textAlign: "left",
                                            color: "black",
                                          }}
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Fees
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography>: {l.fees}</Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display="flex"
                                        style={{ width: "50%" }}
                                      >
                                        <Box
                                          style={{
                                            width: "40%",
                                            textAlign: "left",
                                            color: "black",
                                          }}
                                        >
                                          <Typography
                                            style={{ fontWeight: "bold" }}
                                          >
                                            Transaction Id
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography>
                                            : {l.transaction_id}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                 
                                </Grid>
                              </>
                            );
                          })}
                        </>
                      )}
                    </Grid>
                  </Container>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Grid> */}
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white",
  },
});
export default withStyles(styles)(StudentSubs);
