import React, { Component } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Form, Modal } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { GrAddCircle } from "react-icons/gr";
// import { Router, Link } from "@reach/router"
import { AiOutlineArrowLeft } from "react-icons/ai";
import API from "../components/api";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  DialogContentText,
  TextareaAutosize,
  MenuItem,
  Alert,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

class Tournament extends Component {
  state = {
    page: 1,
    Tournamentdata: [],
    rules: "",
    fromdate: "",
    todate: "",
    opentourmodal: false,
    price: "",
    leaguename: "",
    season: "",
    success: false,
    error: false,
    addmsg: "",
    gameselected: "",
    Gamelist: [],
    editfromdate: "",
    deletemodal: false,
    edittodate: "",
    editgameselected: "",
    editprize: "",
    editleague: "",
    editrules: "",
    editseason: "",
    editid: "",
    editmsg: "",
    deleleid: "",
    tournamentmsg: "",
  };
  componentDidMount() {
    this.getTournaments();
    this.getGamelist();
  }
  opendeletemodal(l) {
    this.setState({ deleleid: l.id, deletemodal: true });
  }
  DeleteTournament() {
    const params = {
      action: "delete",
      user_id: localStorage.getItem("userID"),

      id: this.state.deleleid,
    };
    var that = this;
    API.post("AcademyApi/tournaments", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          editmsg: response.data.message,
        });

        setTimeout(() => {
          that.setState({ success: false, deletemodal: false });
        }, 2500);
        that.getTournaments();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  EditTournament() {
    const params = {
      action: "update",
      user_id: localStorage.getItem("userID"),
      game_id: this.state.editgameselected,
      id: this.state.editid,
      league_name: this.state.editleague,
      season: this.state.editseason,
      from_date: this.state.editfromdate,
      to_date: this.state.edittodate,
      prize: this.state.editprize,
      rule_regulation: this.state.editrules,
    };
    console.log("editTournamentPayload", params);
    var that = this;
    API.post("AcademyApi/tournaments", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          editmsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, opentourmodal: false });
          }, 2500);
          that.getTournaments();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  openeditmodal(l) {
    console.log("lid", l.id);
    console.log("lid1234", l);
    const monthList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let startDate = l?.from_date;
    let endDate = l?.to_date;
    let splitStartDate = startDate.split(",");
    let splitStartDateSecond = splitStartDate[0].split(" ");
    let startMonth = Number(monthList.indexOf(splitStartDateSecond[1])) + 1;

    let splitEndDate = endDate.split(",");
    let splitEndDateSecond = splitEndDate[0].split(" ");
    let endMonth = Number(monthList.indexOf(splitEndDateSecond[1])) + 1;

    this.setState({ editid: l.id });
    this.setState({
      opentourmodal: true,
      editleague: l.league_name,
      editseason: l.season,
      editprize: l.prize,
      editrules: l.rule_regulation,
      editfromdate:
        splitStartDate[1] + "-" + startMonth + "-" + splitStartDateSecond[0],
      edittodate:
        splitEndDate[1] + "-" + endMonth + "-" + splitEndDateSecond[0],
      editgameselected: l.game_id,
    });
    console.log(
      "startDate",
      splitStartDate[1] + "-" + startMonth + "-" + splitStartDateSecond[0]
    );
    console.log(
      "endDate",
      splitEndDate[1] + "-" + endMonth + "-" + splitEndDateSecond[0]
    );
  }
  getGamelist() {
    var that = this;
    const user = localStorage.getItem("userID");
    API.post("AcademyApi/profile", { user_id: user })
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Gamelist: response.data.data.game_ids,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  addTournament() {
    const params = {
      action: "add",
      user_id: localStorage.getItem("userID"),
      league_name: this.state.leaguename,
      season: this.state.season,
      from_date: this.state.fromdate,
      to_date: this.state.todate,
      prize: this.state.price,
      rule_regulation: this.state.rules,
      game_id: this.state.gameselected,
    };
    var that = this;
    API.post("AcademyApi/tournaments", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          addmsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, page: 1 });
          }, 2500);
          that.getTournaments();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2500);
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getTournaments() {
    const params = {
      action: "view",
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("AcademyApi/tournaments", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Tournamentdata: response.data.data,
          tournamentmsg: response.data.message,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1>TOURNAMENTS</h1>
          <p>
            {" "}
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home{" "}
            </Link>
            |{" "}
            <Link
              href="/gameprofile"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              Gaming Academy{" "}
            </Link>{" "}
            | Tournaments
          </p>
        </div>
        {this.state.page === 1 && (
          <>
            <Container>
              <Grid container spacing={2} marginY={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box>
                    <Typography variant="h5">TOURNAMENTS</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box display="flex" style={{ justifyContent: "flex-end" }}>
                    <Box>
                      <Button
                        onClick={() => {
                          this.setState({ page: 2 });
                        }}
                        style={{ border: "1px solid black", color: "black" }}
                      >
                        Add Tournament
                        <AddCircleOutlineIcon style={{ color: "lightgreen" }} />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container>
              <Grid container marginY={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "15%",
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                {this.state.Tournamentdata === undefined ? (
                  <Box>No Tournaments Added</Box>
                ) : (
                  <>
                    {this.state.Tournamentdata.map((l, id) => {
                      return (
                        <>
                          <Grid item xs={10} sm={10} md={10} lg={10}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              style={{
                                border: "1px solid lightgrey",
                                borderRadius: "4px",
                              }}
                              paddingX={2}
                            >
                              <Box>
                                <Typography variant="h6" fontWeight="bold">
                                  {l?.league_name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{l?.game}</Typography>
                              </Box>
                              <Box paddingBottom={2}>
                                <Typography>{l?.season}</Typography>
                              </Box>
                              <Box display="flex">
                                <Box style={{ width: "35%" }} display="flex">
                                  <Box style={{ width: "29%" }}>
                                    <Typography>Start</Typography>
                                  </Box>
                                  <Box>
                                    {" "}
                                    <Typography> : {l.from_date}</Typography>
                                  </Box>
                                </Box>
                                <Box style={{ width: "35%" }} display="flex">
                                  <Box style={{ width: "29%" }}>
                                    <Typography>End</Typography>{" "}
                                  </Box>
                                  <Box>
                                    <Typography> : {l?.to_date}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box display="flex" paddingBottom={2}>
                                <Box style={{ width: "10%" }}>
                                  <Typography>Prize</Typography>
                                </Box>
                                <Box>
                                  {" "}
                                  <Typography> : {l?.prize}</Typography>
                                </Box>
                              </Box>
                              <Divider />
                              <Box
                                display="flex"
                                flexDirection="column"
                                paddingY={2}
                              >
                                <Box>
                                  <Typography fontWeight="bold">
                                    Rules :
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography fontWeight="bold">
                                    {" "}
                                    {l?.rule_regulation}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Box
                              display="flex"
                              style={{ height: "100%", alignItems: "center" }}
                            >
                              <Box>
                                <button
                                  onClick={() => {
                                    this.openeditmodal(l);
                                  }}
                                  className={classes.tuitionTimeStatusButton}
                                >
                                  <EditIcon style={{ color: "white" }} />
                                </button>
                              </Box>
                              <Box>
                                <button
                                  onClick={() => {
                                    this.opendeletemodal(l);
                                  }}
                                  className={classes.tuitionTimeStatusButton}
                                >
                                  <DeleteIcon style={{ color: "white" }} />
                                </button>
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Container>
          </>
        )}

        {this.state.page === 2 && (
          <>
            <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <button
                      onClick={() => {
                        this.setState({
                          page: 1,
                        });
                      }}
                      className={classes.tuitionTimeStatusButton}
                    >
                      <ArrowBackIcon style={{ color: "white" }} />
                    </button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h5" style={{ paddingLeft: "8px" }}>
                    ADD TOURNAMENTS
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "15%",
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>League Name</p>
                  <TextField
                    value={this.state.leaguename}
                    onChange={(evt) => {
                      this.setState({ leaguename: evt.target.value });
                    }}
                    type="name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Seoson</p>
                  <TextField
                    value={this.state.season}
                    onChange={(evt) => {
                      this.setState({ season: evt.target.value });
                    }}
                    type="name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Start Date</p>
                  <TextField
                    value={this.state.fromdate}
                    onChange={(evt) => {
                      this.setState({ fromdate: evt.target.value });
                    }}
                    type="date"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>End Date</p>
                  <TextField
                    value={this.state.todate}
                    onChange={(evt) => {
                      this.setState({ todate: evt.target.value });
                    }}
                    type="date"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Choose Game</p>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="game"
                      onChange={(e) => {
                        this.setState({ gameselected: e.target.value });
                      }}
                      value={this.state.gameselected}
                      input={<OutlinedInput />}
                    >
                      {this.state.Gamelist.map((l, i) => {
                        return (
                          <MenuItem key={i} value={l?.gameId}>
                            {l?.gameName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Price</p>
                  <TextField
                    value={this.state.price}
                    onChange={(evt) => {
                      this.setState({ price: evt.target.value });
                    }}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p style={{ opacity: "0.6" }}>Rules & Regulations</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextareaAutosize
                      minRows={4}
                      maxRows={4}
                      value={this.state.rules}
                      onChange={(evt) => {
                        this.setState({ rules: evt.target.value });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <Box>
                      {this.state.error && (
                        <Alert severity="error">{this.state.addmsg}</Alert>
                      )}
                      {this.state.success && (
                        <Alert severity="success">{this.state.addmsg}</Alert>
                      )}
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          this.addTournament();
                        }}
                        variant="contained"
                        style={{
                          backgroundColor: "deepskyblue",
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
        <div>
          {/* {this.state.page === 1 ? (
            <div className="container" style={{ paddingTop: "30px" }}>
              <div className="row">
                <div className="col-md-8 offset-1">
                  <h5>TOURNAMENTS</h5>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                </div>
                <div className="col-md-3">
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      this.setState({ page: 2 });
                    }}
                  >
                    Add Tournaments{" "}
                    <GrAddCircle
                      style={{
                        backgroundColor: "lightgreen",
                        borderRadius: "50%",
                      }}
                      color="white"
                    />
                  </Button>
                </div>
              </div>
              {this.state.Tournamentdata === undefined ? (
                <div>{this.state.tournamentmsg}</div>
              ) : (
                <div>
                  {this.state.Tournamentdata.map((l, id) => {
                    return (
                      <div className="row" style={{ paddingTop: "25px" }}>
                        <div class="col-sm-8 offset-1">
                          <div class="card">
                            <div class="card-body">
                              <h6 class="card-title" style={{}}>
                                {l.league_name}
                              </h6>
                              <p1
                                style={{ fontSize: "smaller", opacity: "0.6" }}
                                class="card-text"
                              >
                                {l.game}
                              </p1>
                              <br></br>
                              <p1
                                style={{
                                  fontSize: "smaller",
                                  opacity: "0.8",
                                }}
                                class="card-text"
                              >
                                {l.season}
                              </p1>
                              <br></br>
                              <p1
                                style={{ fontSize: "smaller", opacity: "0.6" }}
                                class="card-text"
                              >
                                Starts , {l.from_date}
                              </p1>
                              <br></br>
                              <p1
                                style={{ fontSize: "smaller", opacity: "0.6" }}
                                class="card-text"
                              >
                                Ends, {l.to_date}
                              </p1>

                              <div className="row">
                                <div className="col-md-2">
                                  <p style={{ fontWeight: "bold" }}>
                                    Prize: {l.prize}
                                  </p>
                                </div>
                                <div className="col-md-10">
                                  <p style={{ fontWeight: "bold" }}>
                                    Rules:{l.rule_regulation}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-1">
                          <button
                            className="rounded-circle"
                            style={{
                              backgroundColor: "lightgreen",
                              height: "40px",
                              width: "40px",
                              border: "none",
                            }}
                            onClick={() => {
                              this.openeditmodal(l);
                            }}
                          >
                            <AiOutlineEdit color="white" />
                          </button>
                        </div>
                        <div className="col-sm-1">
                          <button
                            className="rounded-circle"
                            style={{
                              backgroundColor: "lightgreen",
                              height: "40px",
                              width: "40px",
                              border: "none",
                            }}
                            onClick={() => {
                              this.opendeletemodal(l);
                            }}
                          >
                            <AiFillDelete color="white" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            ""
          )} */}
          {/* {this.state.page === 2 ? (
            <div>
              <div className="container" style={{ paddingTop: "30px" }}>
                <div className="col offset-1">
                  <Button
                    variant="none"
                    onClick={() => {
                      this.setState({ page: 1 });
                    }}
                    style={{
                      borderRadius: "50%",
                      border: "1px solid grey",
                      backgroundColor: "black",
                      color: "white",
                    }}
                  >
                    <AiOutlineArrowLeft />
                  </Button>
                </div>
                <div className="col offset-1">
                  <Typography variant="h5"> ADD TOURNAMENTS</Typography>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                </div>
                <div className="row">
                  <div className="col-md-5 offset-1">
                    <p style={{ opacity: "0.6" }}>League Name</p>
                    <TextField
                      value={this.state.leaguename}
                      onChange={(evt) => {
                        this.setState({ leaguename: evt.target.value });
                      }}
                      type="name"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-5 ">
                    <p style={{ opacity: "0.6" }}>Seoson</p>
                    <Form>
                      <TextField
                        value={this.state.season}
                        onChange={(evt) => {
                          this.setState({ season: evt.target.value });
                        }}
                        type="name"
                        fullWidth
                      />
                    </Form>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "15px" }}>
                  <div className="col offset-1">
                    <h6>Date</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 offset-1">
                    <p style={{ opacity: "0.6" }}>From Date</p>
                    <Form>
                      <TextField
                        value={this.state.fromdate}
                        onChange={(evt) => {
                          this.setState({ fromdate: evt.target.value });
                        }}
                        type="date"
                        fullWidth
                      />
                    </Form>
                  </div>
                  <div className="col-md-5 ">
                    <p style={{ opacity: "0.6" }}>To Date</p>
                    <Form>
                      <TextField
                        value={this.state.todate}
                        onChange={(evt) => {
                          this.setState({ todate: evt.target.value });
                        }}
                        type="date"
                        fullWidth
                      />
                    </Form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 offset-1">
                    <p style={{ opacity: "0.6" }}>Price</p>
                    <Form>
                      <TextField
                        value={this.state.price}
                        onChange={(evt) => {
                          this.setState({ price: evt.target.value });
                        }}
                        type="text"
                        fullWidth
                      />
                    </Form>
                  </div>
                  <div className="col-md-5 ">
                    <p style={{ opacity: "0.6" }}>Rules & Regulations</p>
                    <Form>
                      <Form.Control
                        value={this.state.rules}
                        onChange={(evt) => {
                          this.setState({ rules: evt.target.value });
                        }}
                        as="textarea"
                        rows={2}
                      />
                    </Form>
                  </div>
                  <div className="row">
                    <div className="col-md-5 offset-1">
                      <p style={{ opacity: "0.6" }}>Chooose game</p>
                      <Form.Select
                        onChange={(e) => {
                          this.setState({ gameselected: e.target.value });
                        }}
                        required
                        value={this.state.gameselected}
                        aria-label="Default select example"
                      >
                        <option></option>
                        {this.state.Gamelist.map((l, i) => {
                          return (
                            <option key={i} value={l.gameId}>
                              {l.gameName}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <div className="col offset-1" style={{ paddingTop: "20px" }}>
                  <Button
                    onClick={() => {
                      this.addTournament();
                    }}
                    style={{
                      color: "white",
                      backgroundColor: "rgb(24, 169, 218)",
                      border: "none",
                    }}
                  >
                    Submit
                  </Button>
                </div>
                {this.state.success ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "green",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      {this.state.addmsg}
                    </h5>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div
                    className="row"
                    style={{
                      paddingTop: "25px",
                      position: "relative",
                      top: "10px",
                      backgroundColor: "red",
                    }}
                  >
                    <h5 style={{ color: "white", fontSize: "small" }}>
                      {this.state.addmsg}
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>
        {/* <Modal
          size="lg"
          show={this.state.opentourmodal}
          onHide={() => this.setState({ opentourmodal: false })}
          aria-labelledby="example-modal-sizes-title-sm"
          animation="true"
          autoFocus="true"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit Tournament
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                  League Name
                </p>
                <Form>
                  <Form.Control
                    value={this.state.editleague}
                    onChange={(evt) => {
                      this.setState({
                        editleague: evt.target.value,
                      });
                    }}
                    type="text"
                  />
                </Form>
              </div>
              <div className="col-md-6">
                <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                  Chooose Game
                </p>
                <Form>
                  <Form.Select
                    onChange={(e) => {
                      this.setState({
                        editgameselected: e.target.value,
                      });
                    }}
                    required
                    value={this.state.editgameselected}
                    aria-label="Default select example"
                  >
                    <option></option>
                    {this.state.Gamelist.map((l, i) => {
                      return (
                        <option key={i} value={l.game_id}>
                          {l.gameName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>Season</p>

                  <Form>
                    <Form.Control
                      value={this.state.editseason}
                      onChange={(evt) =>
                        this.setState({
                          editseason: evt.target.value,
                        })
                      }
                      type="text"
                      placeholder=""
                    />
                  </Form>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>Prize</p>

                  <Form>
                    <Form.Control
                      value={this.state.editprize}
                      onChange={(evt) =>
                        this.setState({
                          editprize: evt.target.value,
                        })
                      }
                      type="text"
                      placeholder=""
                    />
                  </Form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Start Date
                  </p>

                  <Form>
                    <Form.Control
                      value={this.state.editfromdate}
                      onChange={(evt) =>
                        this.setState({
                          editfromdate: evt.target.value,
                        })
                      }
                      type="date"
                      placeholder=""
                    />
                  </Form>
                </div>
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    End Date
                  </p>

                  <Form>
                    <Form.Control
                      value={this.state.edittodate}
                      onChange={(evt) =>
                        this.setState({
                          edittodate: evt.target.value,
                        })
                      }
                      type="date"
                      placeholder=""
                    />
                  </Form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Rules & Regulations
                  </p>

                  <Form>
                    <Form.Control
                      value={this.state.editrules}
                      onChange={(evt) =>
                        this.setState({
                          editrules: evt.target.value,
                        })
                      }
                      as="textarea"
                      row={3}
                      placeholder=""
                    />
                  </Form>
                </div>
              </div>
              <div className="container">
                <div className="row" style={{ paddingTop: "20px" }}>
                  <Button
                    variant="primary"
                    onClick={() => this.EditTournament()}
                  >
                    Save
                  </Button>
                </div>
              </div>
              {this.state.success ? (
                <div
                  className="row"
                  style={{
                    paddingTop: "25px",
                    position: "relative",
                    top: "10px",
                    backgroundColor: "green",
                  }}
                >
                  <h5 style={{ color: "white", fontSize: "small" }}>
                    {this.state.editmsg}
                  </h5>
                </div>
              ) : (
                ""
              )}
              {this.state.error ? (
                <div
                  className="row"
                  style={{
                    paddingTop: "25px",
                    position: "relative",
                    top: "10px",
                    backgroundColor: "red",
                  }}
                >
                  <h5 style={{ color: "white", fontSize: "small" }}>
                    {this.state.editmsg}
                  </h5>
                </div>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
        </Modal> */}
        <Dialog open={this.state.opentourmodal} maxWidth="md" fullWidth>
          <DialogTitle style={{ paddingLeft: "46px" }}>
            <Typography variant="h6" fontWeight="bold">
              Edit Tournament
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Leage Name</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="leagename"
                      type="text"
                      value={this.state.editleague}
                      onChange={(evt) => {
                        this.setState({
                          editleague: evt.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Choose Game</p>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="game"
                      onChange={(e) => {
                        this.setState({
                          editgameselected: e.target.value,
                        });
                      }}
                      value={this.state.editgameselected}
                      input={<OutlinedInput />}
                    >
                      {this.state.Gamelist.map((l, i) => {
                        return (
                          <MenuItem key={i} value={l?.gameId}>
                            {l?.gameName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Season</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="season"
                      type="text"
                      value={this.state.editseason}
                      onChange={(evt) =>
                        this.setState({
                          editseason: evt.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Prize</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="prize"
                      type="number"
                      value={this.state.editprize}
                      onChange={(evt) =>
                        this.setState({
                          editprize: evt.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Start Date</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="startDate"
                      type="date"
                      value={this.state.editfromdate}
                      onChange={(evt) =>
                        this.setState({
                          editfromdate: evt.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>End Date</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="endDate"
                      type="date"
                      value={this.state.edittodate}
                      onChange={(evt) =>
                        this.setState({
                          edittodate: evt.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p style={{ opacity: "0.6" }}>Rules & Regulations</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextareaAutosize
                      minRows={4}
                      maxRows={4}
                      name="ruleRegulation"
                      value={this.state.editrules}
                      onChange={(evt) =>
                        this.setState({
                          editrules: evt.target.value,
                        })
                      }
                    />
                  </FormControl>
                  <Box>
                    {this.state.error && (
                      <Alert severity="error">{this.state.editmsg}</Alert>
                    )}
                    {this.state.success && (
                      <Alert severity="success">{this.state.editmsg}</Alert>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>
            <Box display="flex">
              <Box>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "deepskyblue" }}
                  onClick={() => this.EditTournament()}
                >
                  Save
                </Button>
                <Button
                  variant="text"
                  onClick={() => this.setState({ opentourmodal: false })}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.deletemodal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure? You want to delete this news!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ deletemodal: false })}>
              Cancel
            </Button>
            <Button
              autoFocus
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => {
                this.DeleteTournament();
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Modal
          size="sm"
          show={this.state.deletemodal}
          onHide={() => this.setState({ deletemodal: false })}
          aria-labelledby="example-modal-sizes-title-sm"
          animation="true"
          autoFocus="true"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete Tournament
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ fontWeight: "bold" }}>
              Are You Sure, You Want To Delete?
            </p>
            <p>This process cant be undone</p>
            <Button
              variant="danger"
              style={{ color: "white" }}
              onClick={() => {
                this.DeleteTournament();
              }}
            >
              Delete
            </Button>
          </Modal.Body>
        </Modal> */}
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(Tournament);
