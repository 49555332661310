import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  AppBar,
  Chip,
  Icon,
  Link,
  useTheme,
  MenuItem,
  Menu,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from '../provider/useWindowSize'

const Homebox=({classes})=>{
  const theme = useTheme()
  const { width } = useWindowSize()
  const { xs, sm, md, lg } = theme.breakpoints.values
    return (
      <div className="container">
        <div className="row">
          <div
            className="col-md-6"
            style={{
              width: "50%",
              height: "80vh",
              backgroundColor: "black",
              padding: "0px",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
              }}
              src={require("../assets/img/pexels-photo-51055.jpeg").default}
            />
            <p1
              style={{
                position: "relative",
                bottom: "35vh",
                left: "10vh",

                color: "white",

                fontSize: "25px",
              }}
            >
              SER-MAPS
            </p1>
            <h5
              style={{
                position: "relative",
                bottom: "35vh",
                left: "10vh",

                color: "white",

                fontSize: "35px",
              }}
            >
              Worldclass
            </h5>
            <h5
              style={{
                position: "relative",
                bottom: "37vh",
                left: "10vh",

                color: "white",

                fontSize: "35px",
              }}
            >
              Game Academy
            </h5>
            <button
              style={{
                position: "relative",
                bottom: "37vh",
                left: "10vh",

                border: "none",
              }}
            >
              View More
            </button>
          </div>
          <div className="col-md-6">
            <div class="row ">
              <div
                className="col-md-3"
                style={{
                  width: "50%",
                  height: "40vh",
                  backgroundColor: "green",
                  padding: "0px",
                }}
              >
                {" "}
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    require("../assets/img/pexels-photo-3769981.jpeg").default
                  }
                />
              </div>
              <div
                className="col-md-3"
                style={{
                  backgroundColor: "rgb(154,211,94)",
                  width: "50%",
                  height: "40vh",
                  margin: "0px",
                }}
              >
                <div style={{ paddingLeft: "5vh" }}>
                  <p style={{ paddingTop: "10vh", lineHeight: "2px" }}>
                    SER-MAPS
                  </p>
                  <h5 style={{ fontSize: "22px" }}>
                    Advance Learning Subjects
                  </h5>
                  <button
                    style={{
                      border: "1px solid black",
                      fontSize: "smaller",
                      backgroundColor: "rgb(154,211,94)",
                    }}
                  >
                    View More
                  </button>
                </div>
              </div>
              <div className="row"></div>
              <div
                className="col"
                style={{
                  backgroundColor: "rgb(254,217,102)",
                  width: "50%",
                  height: "40vh",
                }}
              >
                <div style={{ paddingLeft: "5vh" }}>
                  <p style={{ paddingTop: "8vh" }}>SER-MAPS</p>
                  <h5 style={{ lineHeight: "3px" }}>Coding Class</h5>
                  <p1
                    style={{
                      fontSize: "smaller",
                      lineHeight: "3px",
                    }}
                  >
                    Lorem Ipsum is a dummy text , everybody knows it but still
                    writting
                  </p1>
                  <br></br>
                  {/* <Link href="/"> */}
                  <button
                    style={{
                      border: "1px solid black",
                      fontSize: "smaller",
                      backgroundColor: "rgb(254,217,102)",
                    }}
                  >
                    View More
                  </button>
                  {/* </Link> */}
                </div>
              </div>
              <div
                className="col"
                style={{
                  backgroundColor: "blue",
                  width: "50%",
                  height: "40vh",
                  padding: "0px",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    require("../assets/img/pexels-photo-5212361.jpeg").default
                  }
                />
              </div>
            </div>
          </div>

          <div
            className="col-md-3"
            style={{
              backgroundColor: "red",

              height: "40vh",
            }}
          >
            <div style={{ paddingLeft: "5vh" }}>
              <p style={{ paddingTop: "8vh" }}>SER-MAPS</p>
              <h5 style={{ lineHeight: "2px" }}>Best School</h5>
              <p1 style={{ lineHeight: "3px", fontSize: "smaller" }}>
                Lorem Ipsum is a dummy text , everybody knows it but still
                writting
              </p1>
              <br></br>
              {/* <Link href="/"> */}
              <button
                style={{
                  fontSize: "smaller",
                  backgroundColor: "red",
                  border: "1px solid black",
                }}
              >
                View More
              </button>
              {/* </Link> */}
            </div>
          </div>
          <div
            className="col-md-3"
            style={{
              backgroundColor: "violet",
              padding: "0px",
              height: "40vh",
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={require("../assets/img/pexels-photo-5554240.jpeg").default}
            />
          </div>
          <div
            className="col-6"
            style={{
              backgroundColor: "rgb(129,129,129)",

              height: "40vh",
            }}
          >
            <div
              style={{ paddingLeft: "5vh", paddingTop: "13vh", color: "white" }}
            >
              <p style={{ lineHeight: "3px" }}>SER-MAPS</p>
              <h5 style={{ lineHeight: "3px" }}>Science Fair Partition Form</h5>
              <p1
                style={{
                  fontSize: "smaller",
                  lineHeight: "3px",
                }}
              >
                Lorem Ipsum is a dummy text , everybody knows it but still
                writting
              </p1>
              <br></br>
              {/* <Link href="/"> */}
              <button
                style={{
                  border: "1px solid white",
                  backgroundColor: "rgb(129,129,129)",
                  color: "white",
                  fontSize: "smaller",
                }}
              >
                Apply Now
              </button>
              {/* </Link> */}
            </div>
          </div>
          <div className="row"></div>
          <div
            className="col-md-3"
            style={{
              backgroundColor: "black",
              height: "40vh",
              padding: "0px",
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={require("../assets/img/pexels-photo-945966.jpeg").default}
            />
          </div>
          <div
            className="col-md-3"
            style={{
              backgroundColor: "rgb(36,175,240)",

              height: "40vh",
            }}
          >
            <div style={{ paddingLeft: "5vh", paddingTop: "7vh" }}>
              <p style={{ lineHeight: "0px" }}>SER-MAPS</p>
              <h5 style={{}}>Motivational / Innovative Videos</h5>
              <p1 style={{ fontSize: "smaller" }}>
                Lorem Ipsum is a dummy text , everybody knows it but still
                writting
              </p1>
              <br></br>
              {/* <Link href="/"> */}
              <button
                style={{
                  border: "1px solid black",
                  backgroundColor: "rgb(36,175,240)",

                  fontSize: "smaller",
                }}
              >
                View More
              </button>
              {/* </Link> */}
            </div>
          </div>
          <div
            className="col-6"
            style={{
              backgroundColor: "green",
              padding: "0px",
              height: "40vh",
            }}
          >
            <img
              style={{ height: "100%", width: "100%" }}
              src={require("../assets/img/pexels-photo-3861958.jpeg").default}
            />
          </div>
        </div>
      </div>
    );
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
});
export default withStyles(styles)(Homebox);
