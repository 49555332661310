import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Router, Link } from "@reach/router"
// import { CardContent, Grid, Link } from "@mui/material";
import { FaLongArrowAltRight } from "react-icons/fa";
import TeacherDetails from "../components/TeacherDetails.js";
import API from "../components/api";
// import { Button, Container } from "react-bootstrap";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { withStyles } from '@mui/styles';
import { navigate } from "@reach/router";

class Teachers extends Component {
  state = {
    TeachersList: [],
  };
  componentDidMount() {
    this.getteacherList();
  }
  getteacherid(l) {
    console.log("teacherid", l.teacher_id);
    localStorage.setItem("teacherid", l.teacher_id);
    navigate("/teacher/teacherdetail")
  }
  getteacherList() {
    var that = this;
    API.post("StudentApi/teacherList")
      .then(function (response) {
        console.log(response);
        that.setState({
          TeachersList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> TEACHERS</h1>
          <p>
            <Link href="/" style={{ color: "white", textDecoration: "none" }}>
              Home
            </Link>{" "}
            |
            <Link
              href="/userprofile"
              style={{ color: "white", textDecoration: "none" }}
            >
              {" "}
              Student{" "}
            </Link>{" "}
            | Teachers
          </p>
        </div>
        <Container style={{minHeight:"500px",marginTop:"20px", marginBottom:"20px"}}>
          <Grid container spacing={2}>
            {this.state.TeachersList.map((l, id) => {
              return (<Grid item xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <CardContent>
                    <Box display="flex" flexDirection="column">
                      <Box marginBottom={1}><Typography variant="h6" style={{ fontWeight: "bold" }}>{l.name}</Typography></Box>
                      <Box display="flex">
                        <Box style={{ width: "30%" }}><Typography>Qualification </Typography></Box>
                        <Box ><Typography>: {l.qualification}</Typography></Box>
                      </Box>
                      <Box display="flex">
                        <Box style={{ width: "30%" }}><Typography>Experience </Typography></Box>
                        <Box><Typography>: {l.experience}</Typography></Box>
                      </Box>
                      <Box display="flex">
                        <Box style={{ width: "30%" }}><Typography>Address </Typography></Box>
                        <Box><Typography>: {l.address}</Typography></Box>
                      </Box>
                      <Box display="flex">
                        <Box>
                          <Button variant="text"
                            onClick={() => {
                              this.getteacherid(l);
                            }}><FaLongArrowAltRight /> Details</Button>
                        </Box>

                      </Box>
                    </Box>


                  </CardContent>
                </Card>
              </Grid>)
            })}
          </Grid>
        </Container>
        {/* <div className="container" style={{ paddingTop: "3vh" }}>
          <div className="row">
            {this.state.TeachersList.map((l, id) => {
              return (
                <div
                  className="col-md-5 offset-1"
                  style={{ paddingTop: "3vh" }}
                >
                  {" "}
                  <div className="card">
                    <div className="card-horizontal">
                      <div className="img-square-wrapper">
                        <Link href="/teacher/teacherdetail">
                          <Button
                            variant="none"
                            onClick={() => {
                              this.getteacherid(l);
                            }}
                          >
                            {" "}

                          </Button>
                        </Link>
                      </div>
                      <div className="card-body">
                        <h6 class="card-title">{l.name}</h6>
                        <p1
                          class="card-text"
                          style={{ fontSize: "smaller", fontWeight: " bold" }}
                        >
                          Experience:
                        </p1>{" "}
                        <p1> {l.experience}</p1>
                        <br></br>
                        <p1
                          class="card-text"
                          style={{ fontSize: "smaller", fontWeight: " bold" }}
                        >
                          Qualification:
                        </p1>{" "}
                        <p1> {l.qualification}</p1>
                        <br></br>
                        <p1
                          class="card-text"
                          style={{
                            fontSize: "smaller",
                            fontWeight: " bold",
                          }}
                        >
                          Address:
                        </p1>
                        <p1> {l.address}</p1>
                        <br></br>
                        <a href="/teacher/teacherdetail">
                          {" "}
                          <FaLongArrowAltRight />
                          Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}
        {/* <div className="col-md-5 ">
              {" "}
              <div className="card">
                <div className="card-horizontal">
                  <div className="img-square-wrapper">
                    <Link href="/teacherdetail">
                      {" "}
                      <img
                        style={{ height: "41vh", width: "38vh" }}
                        src={require("../assets/img/83890830.jpeg").default}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div
                    className="card-body"
                    style={{ position: "relative", top: "22vh" }}
                  >
                    <h6 class="card-title">DAVE JOHNSON</h6>

                    <p1
                      class="card-text"
                      style={{ fontSize: "smaller", opacity: "0.6" }}
                    >
                      Distance 2.5km
                    </p1>
                    <br></br>
                    <a href="/teacherdetail">
                      {" "}
                      <FaLongArrowAltRight />
                      Details
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
        {/* </div> */}
        {/* <div className="row" style={{ paddingTop: "2vh" }}>
            <div className="col-md-5 offset-1">
              {" "}
              <div className="card">
                <div className="card-horizontal">
                  <div
                    className="card-body"
                    style={{ position: "relative", top: "22vh" }}
                  >
                    <h6 class="card-title">SOURAV CHAKRABORTY</h6>

                    <p1
                      class="card-text"
                      style={{ fontSize: "smaller", opacity: "0.6" }}
                    >
                      Distance 2km
                    </p1>
                    <br></br>
                    <a href="/teacherdetail">
                      {" "}
                      <FaLongArrowAltRight />
                      Details
                    </a>
                  </div>
                  <div className="img-square-wrapper">
                    <Link href="/teacherdetail">
                      {" "}
                      <img
                        style={{ height: "41vh", width: "38vh" }}
                        src={require("../assets/img/83890830.jpeg").default}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 ">
              {" "}
              <div className="card">
                <div className="card-horizontal">
                  <div
                    className="card-body"
                    style={{ position: "relative", top: "22vh" }}
                  >
                    <h6 class="card-title">MA ASHRAF</h6>

                    <p1
                      class="card-text"
                      style={{ fontSize: "smaller", opacity: "0.6" }}
                    >
                      Distance 5km
                    </p1>
                    <br></br>
                    <a href="/teacherdetail">
                      {" "}
                      <FaLongArrowAltRight />
                      Details
                    </a>
                  </div>
                  <div className="img-square-wrapper">
                    <Link href="/teacherdetail">
                      {" "}
                      <img
                        style={{ height: "41vh", width: "38vh" }}
                        src={require("../assets/img/83890830.jpeg").default}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        {/* <BrowserRouter>
            <TeacherDetails />
          </BrowserRouter> */}
        {/* </div> */}
        {/* <div className="container">
          <div className="row">
            <div className="col-md-5 offset-1">
              {" "}
              <div
                className="container-fluid"
                style={{
                  paddingTop: "10px",
                }}
              >
                <div className="row">
                  <Link to="/teacherdetail">
                    <div className="col-10 mt-3">
                      <div className="card">
                        <div className="card-horizontal">
                          <div className="img-square-wrapper">
                            <img
                              style={{ height: "180px" }}
                              src={
                                require("../assets/img/83890830.jpeg").default
                              }
                            />
                          </div>
                          <div
                            style={{
                              paddingTop: "18vh",
                            }}
                          >
                            <h6>Muzaffar Ahmed</h6>
                            <p1 class="card-text">Distance 2.5km</p1>
                            <div>
                              <a href="#">Details</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="container-fluid"
                style={{
                  paddingTop: "10px",
                  position: "relative",
                  right: "20vh",
                }}
              >
                <div className="row">
                  <div className="col-10 mt-3">
                    <div className="card">
                      <div className="card-horizontal">
                        <div className="img-square-wrapper">
                          <img
                            style={{ height: "180px" }}
                            src={require("../assets/img/83890830.jpeg").default}
                          />
                        </div>
                        <div
                          style={{
                            paddingTop: "18vh",
                          }}
                        >
                          <h6>Tauqeer Hasaan</h6>
                          <p1 class="card-text">Distance= 2.5km</p1>
                          <div>
                            <a href="#">Details</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 offset-1">
              <div
                className="container-fluid"
                style={{
                  paddingTop: "10px",
                }}
              >
                <div className="row">
                  <div className="col-10 ">
                    <div className="card">
                      <div className="card-horizontal">
                        <div
                          style={{
                            paddingTop: "18vh",
                          }}
                        >
                          <h6>Tauqeer Hasaan</h6>
                          <p1 class="card-text">Distance= 2.5km</p1>
                          <div>
                            <a href="#">Details</a>
                          </div>
                        </div>
                        <div className="img-square-wrapper">
                          <img
                            style={{
                              height: "180px",
                            }}
                            src={require("../assets/img/83890830.jpeg").default}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              {" "}
              <div
                className="container-fluid"
                style={{
                  paddingTop: "10px",
                }}
              >
                <div className="row">
                  <div className="col-10 ">
                    <div className="card">
                      <div className="card-horizontal">
                        <div
                          style={{
                            paddingTop: "18vh",
                            position: "relative",
                            left: "20px",
                          }}
                        >
                          <h6>Tauqeer Hasaan</h6>
                          <p1 class="card-text">Distance= 2.5km</p1>
                          <div>
                            <a href="#">Details</a>
                          </div>
                        </div>
                        <div className="img-square-wrapper">
                          <img
                            style={{
                              height: "180px",
                              position: "relative",
                              left: "11vh",
                            }}
                            src={require("../assets/img/83890830.jpeg").default}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}
export default Teachers;
