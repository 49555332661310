import React, { Component } from "react";
import { FaLaptopHouse, FaLongArrowAltRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillDelete } from "react-icons/ai";
import { Form, FloatingLabel } from "react-bootstrap";
import API from "../components/api";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Alert,
} from "@mui/material";
import { withStyles } from "@mui/styles";

class Sciencefair extends Component {
  state = {
    btninvent: true,
    inventionbtn: false,
    btnparticipate: false,
    btnmenu: false,
    participationview: [],
    btnmessage: false,
    btnurparticipate: false,
    fair: 1,
    participationmessage: "",
    noofstudent: "",
    Guidelinedata: "",
    Newsdata: [],
    inventionname: "",
    Message: [],
    Inventiondata: [],
    blankdata: "",
    participateid: "",
    success: false,
    error: false,
    Profiledata: "",
    participationsuccess: "",
    loadingSend: false,
  };

  componentDidMount() {
    this.getguidelinelist();
    this.getnewslist();
    this.getmessages();
    this.getinventiondata();
    this.getparticipateprofile();
    this.getParticipateView();
  }
  getParticipateView() {
    var that = this;
    this.setState({ loadingSend: true });
    const params = {
      user_id: localStorage.getItem("userID"),

      action: "view",
    };

    API.post("StudentApi/participations", params)
      .then(function (response) {
        console.log(response.data.message);
        that.setState({
          participationview: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getparticipateprofile() {
    const params = {
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("StudentApi/profile", params)
      .then(function (response) {
        console.log(response.data.message);
        that.setState({
          Profiledata: response.data.data,
        });
        if (that.state.Inventiondata === undefined) {
          that.setState({ blankdata: response.data.message });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  Sendpariticipation() {
    var that = this;
    this.setState({ loadingSend: true });
    const params = {
      user_id: localStorage.getItem("userID"),
      school_id: localStorage.getItem("school_id(student)"),
      class_id: localStorage.getItem("class_id"),
      invention_id: this.state.participateid,
      student_no: this.state.noofstudent,
      message: this.state.participationmessage,
      action: "add",
    };

    API.post("StudentApi/participations", params)
      .then(function (response) {
        console.log(response.data.message);
        that.setState({
          participationsuccess: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false });
            that.setState({
              loadingSend: false,
              fair: 6,
              btninvent: false,
              btnparticipate: false,
              inventionbtn: false,
              btnmenu: false,
              btnmessage: false,
              btnurparticipate: true,
            });
          }, 2000);
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
            that.setState({ loadingSend: false });
          }, 2000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  Participate(l) {
    console.log("id:", l.invention_id);
    this.setState({
      fair: 3,
      btnparticipate: true,
      inventionbtn: false,
      participateid: l.invention_id,
      inventionname: l.name,
    });
  }
  getinventiondata() {
    var that = this;
    const params = {
      school_id: localStorage.getItem("school_id(student)"),
      class_id: localStorage.getItem("class_id"),
      // class_id: 13,
      // school_id: 1,
    };
    API.post("StudentApi/inventions", params)
      .then(function (response) {
        console.log(response.data.message);
        that.setState({
          Inventiondata: response.data.data,
        });
        if (that.state.Inventiondata === undefined) {
          that.setState({ blankdata: response.data.message });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getmessages() {
    var that = this;
    API.post("StudentApi/messages", {
      school_id: localStorage.getItem("school_id(student)"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ Message: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {});
  }
  getnewslist() {
    var that = this;
    API.post("StudentApi/news", {
      school_id: localStorage.getItem("school_id(student)"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ Newsdata: response.data.data });
        if (that.state.Newsdata === undefined) {
          that.setState({ blankdata: response.data.message });
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {});
  }
  getguidelinelist() {
    var that = this;
    API.post("StudentApi/inventionGuideline", {
      school_id: localStorage.getItem("school_id(student)"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ Guidelinedata: response.data.description });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {});
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        {" "}
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> SCIENCE FAIR</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              Student{" "}
            </Link>
            | science fair
          </p>
        </div>
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: true,
                      btnparticipate: false,
                      inventionbtn: false,
                      btnmenu: false,
                      btnmessage: false,
                      btnurparticipate: false,
                      fair: 1,
                    });
                  }}
                  className={
                    this.state.btninvent ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>INVENTION GUIDELINE</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      inventionbtn: true,
                      btnmenu: false,
                      btnmessage: false,
                      btnurparticipate: false,
                      fair: 2,
                    });
                  }}
                  className={
                    this.state.inventionbtn
                      ? classes.cliked
                      : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>INVENTION LISTS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: true,
                      inventionbtn: false,
                      btnmenu: false,
                      btnmessage: false,
                      btnurparticipate: false,
                      fair: 3,
                    });
                  }}
                  className={
                    this.state.btnparticipate
                      ? classes.cliked
                      : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>PARTICIPATE STUDENT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      inventionbtn: false,
                      btnmenu: true,
                      btnmessage: false,
                      btnurparticipate: false,
                      fair: 4,
                    });
                  }}
                  className={
                    this.state.btnmenu ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>NEWS</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      inventionbtn: false,
                      btnmenu: false,
                      btnmessage: true,
                      btnurparticipate: false,
                      fair: 5,
                    });
                  }}
                  className={
                    this.state.btnmessage ? classes.cliked : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>MESSAGE</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      btnparticipate: false,
                      inventionbtn: false,
                      btnmenu: false,
                      btnmessage: false,
                      btnurparticipate: true,
                      fair: 6,
                    });
                  }}
                  className={
                    this.state.btnurparticipate
                      ? classes.cliked
                      : classes.notclicked
                  }
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>YOUR PARTICIPATIONS</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              {this.state.fair === 1 && (
                <>
                  <Typography variant="h5" style={{ paddingLeft: "22px" }}>
                    INVENTION GUIDELINE
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "160px",
                      marginLeft: "23px",
                    }}
                  ></div>
                  <Container>
                    <Grid container spacing={2} marginY={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box display="flex" flexDirection="column">
                          <Box>
                            <Typography variant="h6">Guideline :</Typography>
                          </Box>
                          <Box>
                            <Typography>{this.state.Guidelinedata}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}

              {this.state.fair === 2 && (
                <>
                  <Typography variant="h5" style={{ paddingLeft: "22px" }}>
                    INVENTION LIST
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "160px",
                      marginLeft: "23px",
                    }}
                  ></div>
                  <Container>
                    <Grid container spacing={2} marginY={2}>
                      {this.state.Inventiondata === undefined ? (
                        <Box>No Invention Found</Box>
                      ) : (
                        <>
                          {this.state.Inventiondata.map((l, index) => (
                            <>
                              {" "}
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" flexDirection="column">
                                  <Box>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      {l?.class}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {l?.name}
                                    </Typography>
                                    <Typography> {l?.description}</Typography>
                                  </Box>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box>
                                      <Typography
                                        variant="h6"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Submission Date :{l.date}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Button
                                        variant="contained"
                                        style={{
                                          backgroundColor: "deepskyblue",
                                        }}
                                        onClick={() => {
                                          this.Participate(l);
                                        }}
                                      >
                                        Participate
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              {index !==
                                Number(this.state.Inventiondata.length) - 1 && (
                                <Divider
                                  style={{
                                    marginBottom: "8px",
                                    marginTop: "8px",
                                  }}
                                />
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </Grid>
                  </Container>
                </>
              )}

              {this.state.fair === 3 && (
                <>
                  <Typography variant="h5" style={{ paddingLeft: "22px" }}>
                    PARTICIPATE STUDENT
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "160px",
                      marginLeft: "23px",
                    }}
                  ></div>
                  <Container>
                    <Grid container spacing={2} marginY={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          style={{
                            width: "100%",
                            border: "1px solid lightgrey",
                          }}
                          padding={1}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            style={{ width: "100%" }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              paddingY={1}
                            >
                              <Box display="flex" style={{ width: "50%" }}>
                                <Box
                                  style={{
                                    width: "40%",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <Typography>School</Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    : {this.state.Profiledata.school}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box display="flex" style={{ width: "50%" }}>
                                <Box
                                  style={{
                                    width: "40%",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <Typography>Class</Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    : {this.state.Profiledata.class}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              paddingY={1}
                            >
                              <Box display="flex" style={{ width: "50%" }}>
                                <Box
                                  style={{
                                    width: "40%",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <Typography>Roll No</Typography>
                                </Box>
                                <Box>
                                  <Typography>: 001</Typography>
                                </Box>
                              </Box>
                              <Box display="flex" style={{ width: "50%" }}>
                                <Box
                                  style={{
                                    width: "40%",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <Typography>Date of Birth</Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    : {this.state.Profiledata.dob}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" paddingY={1}>
                            <Box
                              style={{
                                width: "20%",
                                textAlign: "left",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <Typography>Mobile</Typography>
                            </Box>
                            <Box>
                              <Typography>
                                : {this.state.Profiledata.mobile}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" paddingY={1}>
                            <Box
                              style={{
                                width: "20%",
                                textAlign: "left",
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <Typography>Email</Typography>
                            </Box>
                            <Box>
                              <Typography>
                                : {this.state.Profiledata.email}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            flexDirection="column"
                            paddingY={1}
                          >
                            <Box display="flex" justifyContent="center">
                              <Box>
                                <Typography variant="h6" fontWeight="bold">
                                  PARTICIPATION FORM
                                </Typography>
                              </Box>
                            </Box>
                            <Box style={{ minHeight: "30px" }} paddingY={1}>
                              {/* <Typography>
                                {this.state.inventionname}
                              </Typography> */}
                              <p style={{ opacity: "0.6" }}>Invention</p>
                              <Form.Select
                                onChange={(e) => {
                                  this.setState({
                                    participateid: e.target.value,
                                  });
                                }}
                                required
                                value={this.state.participateid}
                                aria-label="Default select example"
                              >
                                <option>Select Invention</option>
                                {this.state.Inventiondata === undefined ? (
                                  <Box>No invention found</Box>
                                ) : (
                                  <>
                                    {this.state.Inventiondata.map(
                                      (l, invention_id) => {
                                        return (
                                          <option
                                            key={invention_id}
                                            value={l.invention_id}
                                          >
                                            {l.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </Form.Select>
                            </Box>
                            <Box paddingY={1}>
                              <p style={{ opacity: "0.6" }}>
                                Number of Students Applied
                              </p>
                              <TextField
                                type="number"
                                variant="outlined"
                                value={this.state.noofstudent}
                                onChange={(evt) => {
                                  this.setState({
                                    noofstudent: evt.target.value,
                                  });
                                }}
                                fullWidth
                              />
                            </Box>
                            <Box paddingY={1}>
                              <p style={{ opacity: "0.6" }}>Message</p>
                              <TextareaAutosize
                                placeholder="Write Message"
                                value={this.state.participationmessage}
                                onChange={(evt) => {
                                  this.setState({
                                    participationmessage: evt.target.value,
                                  });
                                }}
                                minRows={4}
                                maxRows={4}
                                maxLength={100}
                                style={{ width: "100%" }}
                              />
                            </Box>
                            <Box>
                              {this.state.error && (
                                <Alert severity="error">
                                  {this.state.participationsuccess}
                                </Alert>
                              )}
                              {this.state.success && (
                                <Alert severity="success">
                                  {this.state.participationsuccess}
                                </Alert>
                              )}
                            </Box>
                            <Box>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: "deepskyblue" }}
                                onClick={() => {
                                  this.Sendpariticipation();
                                }}
                              >
                                SEND
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              )}

              {this.state.fair === 4 && (
                <>
                  <Typography variant="h5" style={{ paddingLeft: "22px" }}>
                    NEWS
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "160px",
                      marginLeft: "23px",
                    }}
                  ></div>
                  <Container>
                    <Grid container spacing={2} marginY={2}>
                      {this.state.Newsdata === undefined ? (
                        <Box>No News Found</Box>
                      ) : (
                        <Box>
                          {this.state.Newsdata.map((l, index) => (
                            <>
                              {" "}
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" flexDirection="column">
                                  <Box>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      {l?.date}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {l?.title}
                                    </Typography>
                                    <Typography> {l?.description}</Typography>
                                  </Box>
                                </Box>
                                {index !==
                                  Number(this.state.Newsdata.length) - 1 && (
                                  <Divider
                                    style={{
                                      marginBottom: "8px",
                                      marginTop: "8px",
                                    }}
                                  />
                                )}
                              </Grid>
                            </>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Container>
                </>
              )}
              {this.state.fair === 5 && (
                <>
                  <Typography variant="h5" style={{ paddingLeft: "22px" }}>
                    MESSAGE
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "160px",
                      marginLeft: "23px",
                    }}
                  ></div>
                  <Container>
                    <Grid container spacing={2} marginY={2}>
                      {this.state.Message === undefined ? (
                        <Box>No Message Found</Box>
                      ) : (
                        <>
                          {this.state.Message.map((l, index) => (
                            <>
                              {" "}
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" flexDirection="column">
                                  <Box>
                                    <Typography style={{ fontWeight: "bold" }}>
                                      {l?.date}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {l?.title}
                                    </Typography>
                                    <Typography> {l?.description}</Typography>
                                  </Box>
                                </Box>
                                {index !==
                                  Number(this.state.Message.length) - 1 && (
                                  <Divider
                                    style={{
                                      marginBottom: "8px",
                                      marginTop: "8px",
                                    }}
                                  />
                                )}
                              </Grid>
                            </>
                          ))}
                        </>
                      )}
                    </Grid>
                  </Container>
                </>
              )}
              {this.state.fair === 6 && (
                <>
                  <Typography variant="h5" style={{ paddingLeft: "22px" }}>
                    Your Participations
                  </Typography>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "160px",
                      marginLeft: "23px",
                    }}
                  ></div>
                  <Container>
                    <Grid container spacing={2} marginY={2}>
                      {this.state.participationview === undefined ? (
                        <Box>No Pariticipation Found</Box>
                      ) : (
                        <>
                          {this.state.participationview.map((l, index) => (
                            <>
                              {" "}
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  style={{
                                    border: "1px solid lightgrey",
                                    paddingTop: "2vh",
                                    paddingLeft: "2vh",
                                    paddingRight: "2vh",
                                    paddingBottom: "2vh",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {l?.invention}
                                    </Typography>

                                    <Typography
                                      style={{
                                        opacity: "0.8",
                                      }}
                                    >
                                      {" "}
                                      Message: {l?.message}
                                    </Typography>
                                    {/* {index !==
                                  Number(this.state.participationview.length) -
                                    1 && (
                                  <Divider
                                    style={{
                                      marginBottom: "8px",
                                      marginTop: "8px",
                                    }}
                                  />
                                )} */}
                                    <Box display="flex" flexDirection="column">
                                      <Typography style={{ fontWeight: "" }}>
                                        Student No: {l?.student_no}
                                      </Typography>

                                      <Typography>Date: {l?.date}</Typography>
                                    </Box>
                                  </Box>
                                </Box>

                                {/* <Typography style={{ fontWeight: "" }}>
                                  {l?.message}
                                </Typography> */}
                              </Grid>
                            </>
                          ))}
                        </>
                      )}
                    </Grid>
                  </Container>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container science-fair">
          <div className="row " style={{ paddingTop: "15px" }}>
            <div className="col-md-3 science-row offset-1">
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  className={this.state.btninvent ? "clicked" : "notclicked"}
                  style={{
                    height: "50px",
                    width: "300px",

                    border: "none",
                  }}
                  onClick={() => {
                    this.setState({
                      btninvent: true,
                      btnparticipate: false,
                      inventionbtn: false,
                      btnmenu: false,
                      btnmessage: false,
                      fair: 1,
                    });
                  }}
                >
                  <FaLongArrowAltRight />
                  INVENTION GUIDELINE
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  className={this.state.inventionbtn ? "clicked" : "notclicked"}
                  style={{
                    height: "50px",
                    width: "300px",

                    border: "none",
                  }}
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      inventionbtn: true,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: false,
                      fair: 2,
                    });
                  }}
                >
                  <FaLongArrowAltRight />
                  INVENTION LISTS
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  className={
                    this.state.btnparticipate ? "clicked" : "notclicked"
                  }
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      inventionbtn: false,
                      btnparticipate: true,
                      btnmenu: false,
                      btnmessage: false,
                      fair: 3,
                    });
                  }}
                  style={{
                    height: "50px",
                    width: "300px",

                    border: "none",
                  }}
                >
                  {" "}
                  <FaLongArrowAltRight />
                  PARTICIPATE STUDENT
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  className={this.state.btnmenu ? "clicked" : "notclicked"}
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      inventionbtn: false,
                      btnparticipate: false,
                      btnmenu: true,
                      btnmessage: false,
                      fair: 4,
                    });
                  }}
                  style={{
                    height: "50px",
                    width: "300px",

                    border: "none",
                  }}
                >
                  {" "}
                  <FaLongArrowAltRight />
                  NEWS
                </button>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <button
                  className={this.state.btnmessage ? "clicked" : "notclicked"}
                  onClick={() => {
                    this.setState({
                      btninvent: false,
                      inventionbtn: false,
                      btnparticipate: false,
                      btnmenu: false,
                      btnmessage: true,
                      fair: 5,
                    });
                  }}
                  style={{
                    height: "50px",
                    width: "300px",

                    border: "none",
                  }}
                >
                  {" "}
                  <FaLongArrowAltRight />
                  MESSAGE
                </button>
              </div>
            </div>
            <div className="col-md-8" style={{ paddingLeft: "30px" }}>
              {this.state.fair === 1 ? (
                <div>
                  <h4>INVENTION GUIDELINE</h4>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                  <br></br>
                  <h6>Guideline</h6>
                  <p>{this.state.Guidelinedata}</p>
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 2 ? (
                <div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-3" style={{ flex: "1" }}>
                        <h5>INVENTION LIST</h5>

                        <div
                          style={{
                            height: "3px",
                            width: "80px",
                            textDecoration: "underline",
                            backgroundColor: "lightblue",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="container" style={{ paddingTop: "10px" }}>
                      {this.state.Inventiondata === undefined ? (
                        <div> {this.state.blankdata}</div>
                      ) : (
                        <div>
                          {this.state.Inventiondata.map((l, id) => {
                            return (
                              <div className="row">
                                <div class="col-sm-10">
                                  <div class="card">
                                    <div class="card-body">
                                      <p1>{l.class}</p1>
                                      <h5
                                        style={{ fontWeight: "bold" }}
                                        class="card-title"
                                      >
                                        {l.name}
                                      </h5>
                                      <p1 class="card-text">{l.description}</p1>
                                      <br></br>
                                      <p1
                                        style={{ fontWeight: "bold" }}
                                        class="card-text"
                                      >
                                        Submission date: {l.date}
                                      </p1>
                                      <Button
                                        style={{
                                          border: "1px solid lightgrey",
                                        }}
                                        onClick={() => {
                                          this.Participate(l);
                                        }}
                                      >
                                        Participate
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>{" "}
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 3 ? (
                <div>
                  <div className="container">
                    <h5>PARTICIPATE STUDENT</h5>
                    <div
                      style={{
                        height: "3px",
                        width: "80px",
                        textDecoration: "underline",
                        backgroundColor: "lightblue",
                      }}
                    ></div>
                    <div className="row" style={{ paddingTop: "20px" }}>
                      <div className="col-md-6">
                        {" "}
                        <div className="row">
                          <h6 style={{}}>{this.state.Profiledata.name}</h6>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            {this.state.Profiledata.school}
                          </p>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            {this.state.Profiledata.class}
                          </p>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            Roll Number : 001
                          </p>
                        </div>
                        <div className="row">
                          <h6>Mobile Number</h6>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            {this.state.Profiledata.mobile}
                          </p>
                        </div>
                        <div className="row">
                          <h6>Email Address</h6>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            {this.state.Profiledata.email}
                          </p>
                        </div>
                        <div className="row">
                          <h6>Date of Birth</h6>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            {this.state.Profiledata.dob}
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6"
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <div className="container">
                          <h6>PARTICIPATION FORM</h6>
                          <div
                            className="row"
                            style={{ borderBottom: "1px solid lightgrey" }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "smaller",
                                opacity: "0.6",
                              }}
                            >
                              {this.state.inventionname}
                            </p>
                          </div>
                          <div
                            className="row"
                            style={{ borderBottom: "1px solid lightgrey" }}
                          >
                            <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                              Number of Students Applied
                            </p>
                            <Form>
                              <Form.Control
                                value={this.state.noofstudent}
                                onChange={(evt) => {
                                  this.setState({
                                    noofstudent: evt.target.value,
                                  });
                                }}
                                type="name"
                                placeholder=""
                              />
                            </Form>
                          </div>
                          <div className="row" style={{ paddingTop: "20px" }}>
                            <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                              Message
                            </p>
                            <Form>
                              <Form.Control
                                style={{ height: "20vh" }}
                                value={this.state.participationmessage}
                                onChange={(evt) => {
                                  this.setState({
                                    participationmessage: evt.target.value,
                                  });
                                }}
                                type="name"
                                placeholder=""
                              />
                            </Form>
                          </div>
                          <div className="row">
                            <div className="col" style={{ paddingTop: "10px" }}>
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "rgb(24, 169, 218)",
                                  color: "white",
                                }}
                                onClick={() => {
                                  this.Sendpariticipation();
                                }}
                              >
                                SEND
                              </button>
                            </div>
                            {this.state.success ? (
                              <div
                                style={{
                                  backgroundColor: "lightgreen",
                                }}
                              >
                                <h6 style={{ color: "white" }}>
                                  {this.state.participationsuccess}
                                </h6>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.error ? (
                              <div
                                style={{
                                  backgroundColor: "red",
                                }}
                              >
                                <h6 style={{ color: "white" }}>
                                  {this.state.participationsuccess}
                                </h6>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 4 ? (
                <div>
                  <div>
                    <h5>NEWS</h5>
                    <div
                      style={{
                        height: "3px",
                        width: "80px",
                        textDecoration: "underline",
                        backgroundColor: "lightblue",
                      }}
                    ></div>
                    {this.state.Newsdata === undefined ? (
                      <div>{this.state.blankdata}</div>
                    ) : (
                      <div className="container" style={{ paddingTop: "10px" }}>
                        {this.state.Newsdata.map((l, id) => {
                          return (
                            <div className="row" style={{ paddingTop: "1vh" }}>
                              <div class="col-sm-10">
                                <div class="card">
                                  <div class="card-body">
                                    <p1>{l.date}</p1>
                                    <h5 class="card-title">{l.title}</h5>
                                    <p1 class="card-text">{l.description}</p1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fair === 5 ? (
                <div>
                  {" "}
                  <div>
                    <h5>MESSAGE</h5>
                    <div
                      style={{
                        height: "3px",
                        width: "80px",
                        textDecoration: "underline",
                        backgroundColor: "lightblue",
                      }}
                    ></div>
                    <div className="container" style={{ paddingTop: "10px" }}>
                      {this.state.Message.map((l, id) => {
                        return (
                          <div className="row" style={{ paddingTop: "1vh" }}>
                            <div class="col-sm-10">
                              <div class="card">
                                <div class="card-body">
                                  <p style={{ fontWeight: "bold" }}>{l.date}</p>
                                  <h5 class="card-title">{l.title}</h5>
                                  <p1 class="card-text">{l.description}</p1>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white",
  },
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(Sciencefair);
