import React, { Component, useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent
} from '@mui/material'
import MonthlyLesson from "../../components/MonthlyLesson.js";
import CourseVideo from "../../components/CourseVideo.js"
import CodingClassVideo from "../../components/CodingClassVideo.js"
import { withStyles } from '@mui/styles';
import API from '../../components/api'

const Subject = ({ classes }) => {
  const [innovation, setInnovation] = useState(false)
  const [monthlyLesson, setMonthlyLesson] = useState(false)
  const [course, setCourse] = useState(true)
  const [codingClass, setCodingClass] = useState(false)
  const [page, setPage] = useState(1)
  const [courseVideoList, setCourseVideoList] = useState([])
  const [codingClassVideoList, setCodingClassVideoList] = useState([])


  useEffect(()=>{
  getCourseCodingList()
  },[])

  const getCourseCodingList=()=>{
    API.post("TeacherApi/subjectList").then(res=>{
      if(res.data.result === 1){
        setCourseVideoList(res.data.courseVideos)
        setCodingClassVideoList(res.data.codingVideos)
      }
    })
  }

  const getInnovationIdeas = () => {
    setInnovation(true)
    setMonthlyLesson(false)
    setCourse(false)
    setCodingClass(false)
    setPage(1)
  }

  const getMonthlyLession = () => {
    setInnovation(false)
    setMonthlyLesson(true)
    setCourse(false)
    setCodingClass(false)
    setPage(2)
  }
  const getCourse = () => {
    setInnovation(false)
    setMonthlyLesson(false)
    setCourse(true)
    setCodingClass(false)
    setPage(3)
  }
  const getCodingClass = () => {
    setInnovation(false)
    setMonthlyLesson(false)
    setCourse(false)
    setCodingClass(true)
    setPage(4)
  }


  return (
    <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
          <h1>SUBJECT</h1>
          <p><Link href="/" style={{ color: "white", textDecoration: "none" }}>Home</Link> | Teacher | Subject</p>
        
      </div>
      <Container style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box display="flex" flexDirection="column">
              <Box
                marginTop={1}
                display="flex"
                onClick={getCourse}
                className={course ? classes.cliked : classes.notclicked}
              >
                <Box marginX={4} style={{ alignSelf: "center" }}>
                  <FaLongArrowAltRight />
                </Box>
                <Box style={{ alignSelf: "center" }}>
                  <Typography>COURSE VIDEO</Typography>
                </Box>
              </Box>
              <Box
                marginTop={1}
                display="flex"
                onClick={getCodingClass}
                className={
                  codingClass ? classes.cliked : classes.notclicked
                }
              >
                <Box marginX={4} style={{ alignSelf: "center" }}>
                  <FaLongArrowAltRight />
                </Box>
                <Box style={{ alignSelf: "center" }}>
                  <Typography>CODING CLASS VIDEO</Typography>
                </Box>
              </Box>

            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            {innovation && <>
              <Typography variant="h5" style={{ paddingLeft: "22px" }}>INNOVATION IDEAS</Typography>
              <div
                style={{
                  backgroundColor: "rgb(24, 169, 218)",
                  height: "2px",
                  width: "160px",
                  marginLeft: "23px"
                }}>
              </div>
              <Container >
                <Grid container spacing={2} marginY={2}>
                  
                  <Grid item xs={12} sm={6} md={4}>
                    <Link href="/innovationvideo" style={{textDecoration:"none", color:"black"}}>
                      <Card >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              require("../../assets/img/coding.jpeg")
                                .default
                            }
                            alt="green iguana"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              Lizard
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Lizards are a widespread group of squamate reptiles, with over 6,000
                              species, ranging across all continents except Antarctica
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              </Container>

            </>}
            {monthlyLesson && <MonthlyLesson />}
            {course && <CourseVideo videoList={courseVideoList} />}
            {codingClass && <CodingClassVideo videoList={codingClassVideoList} />}
          </Grid>
        </Grid>
      </Container>
      

    </>
  );
}

const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color:"white"
  }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(Subject)
