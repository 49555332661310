/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { CSSTransition } from "react-transition-group";

import { FaMapMarkedAlt } from "react-icons/fa";
import { ImPhone } from "react-icons/im";
import { FiLogIn } from "react-icons/fi";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import { DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";
import { RiUserAddLine } from "react-icons/ri";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  AppBar,
  Chip,
  Icon,
  Link,
  useTheme,
  MenuItem,
  Menu,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MapIcon from "@mui/icons-material/Map";
import PhoneIcon from "@mui/icons-material/Phone";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { ClassNames } from "@emotion/react";
import { navigate } from "@reach/router";
import { useAuth } from "../provider/useAuth";
import useWindowSize from "../provider/useWindowSize";
import ReorderIcon from "@mui/icons-material/Reorder";
import { LinkedIn } from "@mui/icons-material";

const Header = ({ classes }) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const { xs, sm, md, lg } = theme.breakpoints.values;
  const auth = useAuth();
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [logged, setLogged] = useState(0);
  const [usertype, setUsertype] = useState("");
  const [openRegisterOption, setOpenRegisterOption] = useState(false);
  const [openUserOption, setOpenUserOption] = useState(false);
  const [openList, setOpenList] = useState(false);
  //   const isBrowser = typeof window !== 'undefined'
  //   let location;
  //   if(isBrowser){
  // location=window.location.pathname
  //   }
  // let locati=window.location.href
  // console.log("logged123456",location);

  console.log("user", usertype);
  // console.log("history", history);
  console.log("logged", logged);
  console.log("loggedIn", auth?.isLoggedIn);

  useEffect(() => {
    if (localStorage.getItem("userID")) {
      localStorage.setItem("LoggedIn", 1);
    } else {
      localStorage.setItem("LoggedIn", 0);
    }
    let log = Number(localStorage.getItem("LoggedIn"));
    let user = localStorage.getItem("user_type");
    if (Number(log) === 0) {
      setLogged(Number(log));
      auth?.setIsLoggedIn(0);
    } else if (Number(log) === 1) {
      setLogged(Number(log));
      auth?.setIsLoggedIn(1);
    }

    // alert(log)
    setUsertype(user);
  }, [auth]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const logoutAccount = () => {
    localStorage.setItem("LoggedIn", 0);
    localStorage.removeItem("user_type");
    localStorage.removeItem("userID");
    localStorage.removeItem("uniqueID");
    localStorage.removeItem("codinguniqueID");
    setLogged(0);
    setUsertype("");
    auth.setIsLoggedIn(0);
    navigate("/");
  };

  const handleClick = () => {
    setOpenList(!openList);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <AppBar
          style={{ width: "100%", backgroundColor: "white" }}
          position="fixed"
        >
          <Container
            style={{
              maxWidth: "1800px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Container
                  style={{
                    maxWidth: "1792px",
                    backgroundColor: "black",
                    // height: width < md ? "24vh" : "7vh",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        style={{
                          width: "100%",
                          height: "50px",
                          alignItems: "center",
                        }}
                        display="flex"
                      >
                        <Box>
                          <MapIcon />
                        </Box>
                        <Box>
                          <Typography>
                            504A, PS AVIATOR, Chinar Park, Rajarhat
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <List
                        sx={{
                          width: "100%",
                          height: "45px",
                          backgroundColor: "transparent",
                          display: "inline-flex",
                        }}
                        component="nav"
                      >
                        <Container>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              marginLeft: width < md ? "-52px" : "",
                              marginTop: width < md ? "-43px" : "",
                            }}
                          >
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                              <ListItemButton>
                                <ListItemIcon style={{ marginRight: "-28px" }}>
                                  <PhoneIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary="Customer Care Services" />
                              </ListItemButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              {usertype === "3" ? (
                                <Dropdown
                                  isOpen={openUserOption}
                                  toggle={() => {
                                    setOpenUserOption(!openUserOption);
                                  }}
                                  style={{ marginRight: "60px" }}
                                >
                                  <DropdownToggle
                                    caret
                                    style={{
                                      backgroundColor: "black",
                                      marginLeft: width < md ? "19px" : "",
                                      width: width < md ? "160px" : "",
                                    }}
                                  >
                                    Dashboard
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{ backgroundColor: "deepskyblue" }}
                                  >
                                    <Link
                                      href="/teacherprofile"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Dashboard</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/subject"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Subject</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/studentreport"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Student Report
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/tuitiontime"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Tuition Time</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/subscriberlist"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Subscriber List
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/motivationalvideo"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Motivational Video
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/trainingvideo"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Training Video
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/monthlyexam"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Monthly Exam</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/attendance"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Attendance</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/news"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>News</DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </Dropdown>
                              ) : usertype === "4" ? (
                                <Dropdown
                                  isOpen={openUserOption}
                                  toggle={() => {
                                    setOpenUserOption(!openUserOption);
                                  }}
                                  style={{ marginRight: "60px" }}
                                >
                                  <DropdownToggle
                                    caret
                                    style={{
                                      backgroundColor: "black",
                                      marginLeft: width < md ? "19px" : "",
                                      width: width < md ? "160px" : "",
                                    }}
                                  >
                                    Dashboard
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{ backgroundColor: "deepskyblue" }}
                                  >
                                    <Link
                                      href="/gameprofile"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Dashboard</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/classification"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Classification
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/subscrip"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Subscription</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/academy/videos"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Videos</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/gametournament"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Tournaments</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/gamingreports"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Student Reports
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/academynews"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Academy News</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/gameacademy/otherinformation"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Other Information
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </Dropdown>
                              ) : usertype === "2" ? (
                                <Dropdown
                                  isOpen={openUserOption}
                                  toggle={() => {
                                    setOpenUserOption(!openUserOption);
                                  }}
                                  style={{ marginRight: "60px" }}
                                >
                                  <DropdownToggle
                                    caret
                                    style={{
                                      backgroundColor: "black",
                                      marginLeft: width < md ? "19px" : "",
                                      width: width < md ? "160px" : "",
                                    }}
                                  >
                                    Dashboard
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{ backgroundColor: "deepskyblue" }}
                                  >
                                    <Link
                                      href="/observer_profile"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Dashboard</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/observer/student"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Student</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/observer/teacher"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Teacher</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/observer/gameacademy"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Game Academy</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/observer/school"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>School</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/observer/sciencefair"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Science Fair</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/observer/subcribers"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Subscriber</DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </Dropdown>
                              ) : usertype === "1" ? (
                                <Dropdown
                                  isOpen={openUserOption}
                                  toggle={() => {
                                    setOpenUserOption(!openUserOption);
                                  }}
                                  style={{ marginRight: "60px" }}
                                >
                                  <DropdownToggle
                                    caret
                                    style={{
                                      backgroundColor: "black",
                                      marginLeft: width < md ? "19px" : "",
                                      width: width < md ? "160px" : "",
                                    }}
                                  >
                                    Dashboard
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{ backgroundColor: "deepskyblue" }}
                                  >
                                    <Link
                                      href="/userprofile"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Dashboard</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/timeblocking"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Time Blocking</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/subjectuser"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Subject</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/school"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>School</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/teachers"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Teacher</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/coding"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Coding</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/motivation"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Motivational Video
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/innovation"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Innovate Video
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/gamingacademy"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Game Academy</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/sciencefair"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Science Fair</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/progressreport"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Progress Report
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      href="/notification"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Notification</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/student/subcriptions"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Subscriptions</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/student/wallet"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>
                                        Student Wallet
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </Dropdown>
                              ) : (
                                <Dropdown
                                  isOpen={openRegisterOption}
                                  toggle={() => {
                                    setOpenRegisterOption(!openRegisterOption);
                                  }}
                                  style={{ marginRight: "60px" }}
                                >
                                  <DropdownToggle
                                    caret
                                    style={{
                                      backgroundColor: "black",
                                      marginLeft: width < md ? "19px" : "",
                                      width: width < md ? "160px" : "",
                                    }}
                                  >
                                    Register
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{ backgroundColor: "deepskyblue" }}
                                  >
                                    <Link
                                      href="/signup"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Student</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/teacherregister"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Teacher</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/gameregister"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Game Academy</DropdownItem>
                                    </Link>
                                    <Link
                                      href="/observer_register"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <DropdownItem>Observer</DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </Dropdown>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                              {logged ? (
                                <ListItemButton
                                  style={{ paddingLeft: "40px" }}
                                  onClick={logoutAccount}
                                >
                                  <ListItemIcon
                                    style={{ marginRight: "-28px" }}
                                  >
                                    <LogoutIcon style={{ color: "white" }} />
                                  </ListItemIcon>
                                  <ListItemText primary="Logout" />
                                </ListItemButton>
                              ) : (
                                <Link href="/login" style={{ color: "white" }}>
                                  <ListItemButton style={{ marginTop: "-8px" }}>
                                    <ListItemIcon
                                      style={{ marginRight: "-28px" }}
                                    >
                                      <LoginIcon style={{ color: "white" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Login" />
                                  </ListItemButton>
                                </Link>
                              )}
                            </Grid>
                          </Grid>
                        </Container>
                      </List>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {width > lg && (
                  <Container style={{ maxWidth: "1792px", height: "78px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        <Box style={{ width: "100%", marginTop: "-16px" }}>
                          <Link href="/">
                            <img
                              src={require("../assets/img/logo.png").default}
                              className="Logo"
                              alt=""
                            />
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box style={{ width: "100%" }}>
                          <List
                            style={{
                              width: "100%",
                              backgroundColor: "transparent",
                              display: "inline-flex",
                              justifyContent: "space-between",
                              marginTop: "-24px",
                            }}
                            component="nav"
                          >
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <ListItemButton style={{ height: "78px" }}>
                                <ListItemText primary="Home" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <ListItemButton style={{ height: "78px" }}>
                                <ListItemText primary="About Us" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <ListItemButton style={{ height: "78px" }}>
                                <ListItemText primary="Services" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <ListItemButton style={{ height: "78px" }}>
                                <ListItemText primary="Teachers" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <ListItemButton style={{ height: "78px" }}>
                                <ListItemText primary="Contact Us" />
                              </ListItemButton>
                            </Link>
                          </List>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                )}
                {width < lg && (
                  <Container style={{ maxWidth: "1792px", height: "78px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box style={{ width: "100%", marginTop: "-16px" }}>
                          <Link href="/">
                            <img
                              src={require("../assets/img/logo.png").default}
                              className="Logo"
                              alt=""
                            />
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box>
                            <List component="nav">
                              <ListItemIcon onClick={handleClick}>
                                <ReorderIcon
                                  style={{
                                    color: width <= sm ? "white" : "black",
                                    fontSize: "3rem",
                                  }}
                                />
                              </ListItemIcon>
                            </List>
                          </Box>
                        </Box>

                        <Collapse in={openList} timeout="auto" unmountOnExit>
                          <List
                            component="div"
                            disablePadding
                            style={{
                              backgroundColor: "deepskyblue",
                              marginTop: "0px",
                            }}
                          >
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <ListItemButton>
                                <ListItemText primary="Home" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <ListItemButton>
                                <ListItemText primary="About Us" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <ListItemButton>
                                <ListItemText primary="Services" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <ListItemButton>
                                <ListItemText primary="Teachers" />
                              </ListItemButton>
                            </Link>
                            <Link
                              href="/"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <ListItemButton>
                                <ListItemText primary="Contact Us" />
                              </ListItemButton>
                            </Link>
                          </List>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </Grid>
            </Grid>
          </Container>
          {/* <Box display="flex" flexDirection="column">
            <Box style={{ marginBottom: "15px" }}>
              <Container
                style={{
                  maxWidth: "1792px",
                  backgroundColor: "black",
                  height: width < md ? "24vh" : "7vh",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      style={{
                        width: "100%",
                        height: "50px",
                        alignItems: "center",
                      }}
                      display="flex"
                    >
                      <Box>
                        <MapIcon />
                      </Box>
                      <Box>
                        <Typography>
                          504A, PS AVIATOR, Chinar Park, Rajarhat
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <List
                      sx={{
                        width: "100%",
                        height: "45px",
                        backgroundColor: "transparent",
                        display: "inline-flex",
                      }}
                      component="nav"
                    >
                      <Container>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            marginLeft: width < md ? "-52px" : "",
                            marginTop: width < md ? "-43px" : "",
                          }}
                        >
                          <Grid item xs={12} sm={12} md={7} lg={7}>
                            <ListItemButton>
                              <ListItemIcon style={{ marginRight: "-28px" }}>
                                <PhoneIcon style={{ color: "white" }} />
                              </ListItemIcon>
                              <ListItemText primary="Customer Care Services" />
                            </ListItemButton>
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            {usertype === "3" ? (
                              <Dropdown
                                isOpen={openUserOption}
                                toggle={() => {
                                  setOpenUserOption(!openUserOption);
                                }}
                                style={{ marginRight: "60px" }}
                              >
                                <DropdownToggle
                                  caret
                                  style={{
                                    backgroundColor: "black",
                                    marginLeft: width < md ? "19px" : "",
                                    width: width < md ? "160px" : "",
                                  }}
                                >
                                  Dashboard
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{ backgroundColor: "deepskyblue" }}
                                >
                                  <Link
                                    href="/teacherprofile"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Dashboard</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/subject"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Subject</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/studentreport"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Student Report</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/tuitiontime"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Tuition Time</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/subscriberlist"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Subscriber List</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/motivationalvideo"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>
                                      Motivational Video
                                    </DropdownItem>
                                  </Link>
                                  <Link
                                    href="/trainingvideo"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Training Video</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/monthlyexam"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Monthly Exam</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/attendance"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Attendance</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/news"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>News</DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </Dropdown>
                            ) : usertype === "4" ? (
                              <Dropdown
                                isOpen={openUserOption}
                                toggle={() => {
                                  setOpenUserOption(!openUserOption);
                                }}
                                style={{ marginRight: "60px" }}
                              >
                                <DropdownToggle
                                  caret
                                  style={{
                                    backgroundColor: "black",
                                    marginLeft: width < md ? "19px" : "",
                                    width: width < md ? "160px" : "",
                                  }}
                                >
                                  Dashboard
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{ backgroundColor: "deepskyblue" }}
                                >
                                  <Link
                                    href="/gameprofile"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Dashboard</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/classification"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Classification</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/subscrip"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Subscription</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/academy/videos"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Videos</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/gametournament"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Tournaments</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/gamingreports"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Student Reports</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/academynews"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Academy News</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/gameacademy/otherinformation"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>
                                      Other Information
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </Dropdown>
                            ) : // <Link
                            //   href="/gameprofile"
                            //   style={{
                            //     textDecoration: "none",
                            //     marginTop: "-7px",
                            //     color: "white",
                            //   }}
                            // >
                            //   <ListItemButton>
                            //     <ListItemText primary="Dashboard" />
                            //   </ListItemButton>
                            // </Link>
                            usertype === "2" ? (
                              // <Link
                              //   href="/observer_profile"
                              //   style={{
                              //     textDecoration: "none",
                              //     marginTop: "-7px",
                              //     color: "white",
                              //   }}
                              // >
                              //   <ListItemButton>
                              //     <ListItemText primary="Dashboard" />
                              //   </ListItemButton>
                              // </Link>
                              <Dropdown
                                isOpen={openUserOption}
                                toggle={() => {
                                  setOpenUserOption(!openUserOption);
                                }}
                                style={{ marginRight: "60px" }}
                              >
                                <DropdownToggle
                                  caret
                                  style={{
                                    backgroundColor: "black",
                                    marginLeft: width < md ? "19px" : "",
                                    width: width < md ? "160px" : "",
                                  }}
                                >
                                  Dashboard
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{ backgroundColor: "deepskyblue" }}
                                >
                                  <Link
                                    href="/observer_profile"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Dashboard</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/observer/student"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Student</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/observer/teacher"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Teacher</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/observer/gameacademy"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Game Academy</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/observer/school"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>School</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/observer/sciencefair"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Science Fair</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/observer/subcribers"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Subscriber</DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </Dropdown>
                            ) : usertype === "1" ? (
                              <Dropdown
                                isOpen={openUserOption}
                                toggle={() => {
                                  setOpenUserOption(!openUserOption);
                                }}
                                style={{ marginRight: "60px" }}
                              >
                                <DropdownToggle
                                  caret
                                  style={{
                                    backgroundColor: "black",
                                    marginLeft: width < md ? "19px" : "",
                                    width: width < md ? "160px" : "",
                                  }}
                                >
                                  Dashboard
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{ backgroundColor: "deepskyblue" }}
                                >
                                  <Link
                                    href="/userprofile"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Dashboard</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/subjectuser"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Subject</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/school"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>School</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/teachers"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Teacher</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/coding"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Coding</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/motivation"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>
                                      Motivational Video
                                    </DropdownItem>
                                  </Link>
                                  <Link
                                    href="/innovation"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Innovate Video</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/gamingacademy"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Game Academy</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/sciencefair"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Science Fair</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/progressreport"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Progress Report</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/notification"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Notification</DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </Dropdown>
                            ) : (
                              <Dropdown
                                isOpen={openRegisterOption}
                                toggle={() => {
                                  setOpenRegisterOption(!openRegisterOption);
                                }}
                                style={{ marginRight: "60px" }}
                              >
                                <DropdownToggle
                                  caret
                                  style={{
                                    backgroundColor: "black",
                                    marginLeft: width < md ? "19px" : "",
                                    width: width < md ? "160px" : "",
                                  }}
                                >
                                  Register
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{ backgroundColor: "deepskyblue" }}
                                >
                                  <Link
                                    href="/signup"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Student</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/teacherregister"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Teacher</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/gameregister"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Game Academy</DropdownItem>
                                  </Link>
                                  <Link
                                    href="/observer_register"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <DropdownItem>Observer</DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </Dropdown>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            {logged ? (
                              <ListItemButton
                                style={{ paddingLeft: "40px" }}
                                onClick={logoutAccount}
                              >
                                <ListItemIcon style={{ marginRight: "-28px" }}>
                                  <LogoutIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                              </ListItemButton>
                            ) : (
                              <Link href="/login" style={{ color: "white" }}>
                                <ListItemButton style={{ marginTop: "-8px" }}>
                                  <ListItemIcon
                                    style={{ marginRight: "-28px" }}
                                  >
                                    <LoginIcon style={{ color: "white" }} />
                                  </ListItemIcon>
                                  <ListItemText primary="Login" />
                                </ListItemButton>
                              </Link>
                            )}
                          </Grid>
                        </Grid>
                      </Container>
                    </List>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box style={{ paddingTop: "1px" }}>
              {width > lg && (
                <Container style={{ maxWidth: "1792px", height: "78px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Box style={{ width: "100%", marginTop: "-16px" }}>
                        <Link href="/">
                          <img
                            src={require("../assets/img/logo.png").default}
                            className="Logo"
                            alt=""
                          />
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box style={{ width: "100%" }}>
                        <List
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                            display: "inline-flex",
                            justifyContent: "space-between",
                            marginTop: "-24px",
                          }}
                          component="nav"
                        >
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItemButton style={{ height: "78px" }}>
                              <ListItemText primary="Home" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItemButton style={{ height: "78px" }}>
                              <ListItemText primary="About Us" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItemButton style={{ height: "78px" }}>
                              <ListItemText primary="Services" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItemButton style={{ height: "78px" }}>
                              <ListItemText primary="Teachers" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItemButton style={{ height: "78px" }}>
                              <ListItemText primary="Contact Us" />
                            </ListItemButton>
                          </Link>
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              )}
              {width < lg && (
                <Container style={{ maxWidth: "1792px", height: "78px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box style={{ width: "100%", marginTop: "-16px" }}>
                        <Link href="/">
                          <img
                            src={require("../assets/img/logo.png").default}
                            className="Logo"
                            alt=""
                          />
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <Box>
                          <List component="nav">
                            <ListItemIcon onClick={handleClick}>
                              <ReorderIcon
                                style={{
                                  color: width <= sm ? "white" : "black",
                                  fontSize: "3rem",
                                }}
                              />
                            </ListItemIcon>
                          </List>
                        </Box>
                      </Box>

                      <Collapse in={openList} timeout="auto" unmountOnExit>
                        <List
                          component="div"
                          disablePadding
                          style={{
                            backgroundColor: "deepskyblue",
                            marginTop: "0px",
                          }}
                        >
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <ListItemButton>
                              <ListItemText primary="Home" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <ListItemButton>
                              <ListItemText primary="About Us" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <ListItemButton>
                              <ListItemText primary="Services" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <ListItemButton>
                              <ListItemText primary="Teachers" />
                            </ListItemButton>
                          </Link>
                          <Link
                            href="/"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <ListItemButton>
                              <ListItemText primary="Contact Us" />
                            </ListItemButton>
                          </Link>
                        </List>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Container>
              )}
            </Box>
          </Box> */}
        </AppBar>
      </div>
    </>
  );
};

const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
});
export default withStyles(styles)(Header);
