import React, { Component } from "react";
// import { Router, Link } from "@reach/router"
import { Alert, Link } from "@mui/material";
import API from "./api";
import { Modal, Form } from "react-bootstrap";
import ReactPlayer from "react-player";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { GiBarbedSpear } from "react-icons/gi";
import { CgDanger } from "react-icons/cg";
import { FaPlayCircle } from "react-icons/fa";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  MenuItem,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  CardContent,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from "../provider/useWindowSize";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

class AcademyVideos extends Component {
  state = {
    Videos: [],
    videolink: "",
    add: false,
    Video: "",
    Image: "",
    success: false,
    error: false,
    vdomsg: "",
  };
  componentDidMount() {
    this.getVideosfromprofile();
  }
  uploadVideo() {
    var formdata = new FormData();

    formdata.append("user_id", localStorage.getItem("userID"));
    formdata.append("image_video_thumb", this.state.Image);
    formdata.append("image_video", this.state.Video);

    var that = this;
    API.post("AcademyApi/uploadVideo", formdata)
      .then(function (response) {
        console.log(response.data.message);
        that.setState({
          vdomsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, add: false });
          }, 2000);
          that.getVideosfromprofile();
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2000);
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  Imageupload(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  Videoupload(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Video: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  Addvideos() {
    this.setState({ add: true });
  }
  getVideosfromprofile() {
    const userid = localStorage.getItem("userID");
    var that = this;
    API.post("AcademyApi/profile", { user_id: userid })
      .then(function (response) {
        console.log(response.data.data.videos);
        that.setState({
          Videos: response.data.data.videos,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  startVideo(l) {
    console.log("ll", l.id);
    this.setState({ openvideo: true });
    this.setState({ videolink: l.file });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> VIDEOS</h1>
          <p>
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              {" "}
              Home{" "}
            </Link>{" "}
            |{" "}
            <Link
              href="/gameprofile"
              style={{ textDecoration: "none", color: "white" }}
            >
              Academy
            </Link>{" "}
            | Videos
          </p>
        </div>
        <Container
          style={{
            marginBottom: "12px",
            marginTop: "20px",
            minHeight: "500px",
          }}
        >
          {this.state.add === false && (
            <>
              <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      VIDEOS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Box>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                          onClick={() => {
                            this.Addvideos();
                          }}
                        >
                          Add More Video
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "13%",
                        marginBottom: "12px",
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </Container>
              <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  {this.state.Videos === undefined || [] ? (
                    <Box>No Videos Added</Box>
                  ) : (
                    <>
                      {this.state.Videos.map((l, id) => {
                        return (
                          <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Card
                              onClick={() => {
                                this.startVideo(l);
                              }}
                            >
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="200"
                                  image={l?.thumbnail_file}
                                  alt="green iguana"
                                />
                              </CardActionArea>
                            </Card>
                          </Grid>
                        );
                      })}
                    </>
                  )}
                </Grid>
              </Container>

              <Dialog open={this.state.openvideo} maxWidth="lg" fullWidth>
                <DialogTitle
                  style={{ padding: "0", backgroundColor: "dimgrey" }}
                >
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    style={{ padding: "0px", color: "white" }}
                    onClick={() => {
                      this.setState({ openvideo: false });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  style={{ padding: "0px", backgroundColor: "dimgrey" }}
                >
                  <video
                    width="100%"
                    height="600"
                    controls
                    src={this.state.videolink}
                    type="video/mp4"
                  ></video>
                </DialogContent>
              </Dialog>
            </>
          )}
          {this.state.add && (
            <>
              <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box>
                      <button
                        onClick={() => {
                          this.setState({
                            add: false,
                          });
                        }}
                        className={classes.tuitionTimeStatusButton}
                      >
                        <ArrowBackIcon style={{ color: "white" }} />
                      </button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      ADD VIDEO
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "13%",
                        marginBottom: "12px",
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </Container>
              <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <p style={{ opacity: "0.8", fontWeight: "bold" }}>
                      Video Thumbnail
                    </p>
                    <TextField
                      value={this.state.thumbnail}
                      onChange={(e) => this.Imageupload(e)}
                      type="file"
                      placeholder=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <p style={{ opacity: "0.8", fontWeight: "bold" }}>
                      Video Thumbnail
                    </p>
                    <TextField
                      value={this.state.videofile}
                      onChange={(e) => this.Videoupload(e)}
                      type="file"
                      placeholder=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box display="flex" flexDirection="column">
                      <Box>
                        {this.state.success && (
                          <Alert severity="success">{this.state.vdomsg}</Alert>
                        )}
                        {this.state.error && (
                          <Alert severity="error">{this.state.vdomsg}</Alert>
                        )}
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                          onClick={() => {
                            this.uploadVideo();
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </>
          )}
        </Container>
      </>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  closeButton: {
    position: "absolute",
    left: "97%",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  // profileheading: {
  //   position: "absolute",
  //   top: "41%",
  //   left: "16%",
  //   color: "white"
  // },
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(AcademyVideos);
