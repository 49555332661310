import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
// import { Router, Link } from "@reach/router"
// import { Link } from "@mui/material";
import API from "../components/api";
import { Modal, Form, Button } from "react-bootstrap";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Select,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";

class Observer_profile extends Component {
  state = {
    profiledata: [],
    modal: false,
    educationalmodal: false,
    loginmodal: false,
    name: "",
    showPassword: false,
    emp_id: "",

    photo: "",
    SchoolList: [],
    classselected: "",
    dob: "",
    userId: "",
    blood_group: "",
    mobile: "",
    email: "",
    success: false,
    Image: "",
    Image1: "",
    Image2: "",
    error: false,
    schools: "",
    class: "",
    pre_class_certificate: "",
    classlist: [],
    password: "",
    repass: "",
  };
  componentDidMount() {
    this.getuserdata();
    this.getSchoolList();
    this.state.group_photo = this.state.Image;
  }
  getSchoolList() {
    var that = this;
    API.get("/MasterApi/school_list")
      .then(function (response) {
        console.log(response);
        that.setState({
          SchoolList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  schoolSelected = (e) => {
    this.setState({ schools: e.target.value });
    console.log("schoold", e.target.value);
    // this.setState({ stateloading: true })
    setTimeout(() => {
      this.getclassList();
    }, 1000);
  };
  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", { school_id: this.state.schools })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
    // fetch(
    //   "https://thingproxy.freeboard.io/fetch/https://sermap.kazma.co.in/backend/Api/MasterApi/class_list",
    //   {
    //     method: "POST",
    //     body: JSON.stringify({ school_id: this.state.schools }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log("Success:", result);
    //     this.setState({ classlist: result.data });
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  isshowPass() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  getuserdata = async () => {
    const profiledata = this.state;
    const userid = localStorage.getItem("userID");
    var that = this;
    API.post("SchoolApi/profile", { user_id: userid })
      .then(function (response) {
        console.log(response);
        that.setState({ profiledata: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
    // fetch(
    //   "https://thingproxy.freeboard.io/fetch/https://sermap.kazma.co.in/backend/Api/StudentApi/profile",
    //   {
    //     method: "POST",
    //     body: JSON.stringify({ user_id: userid }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log("Success:", result);
    //     this.setState({ profiledata: result.data });
    //     console.log("datas", this.state.profiledata);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  handlefathername(e) {
    this.setState({ father_name: e.target.value });
    console.log("fathername", this.state.fname);
  }
  handledob(e) {
    this.setState({ dob: e.target.value });
    console.log("fathername", this.state.dob);
  }
  OpenModal() {
    this.setState({ modal: true });
    this.setState({
      name: this.state.profiledata.name,
      mobile: this.state.profiledata.mobile,
      email: this.state.profiledata.email,
      dob: this.state.profiledata.dob,
      father_name: this.state.profiledata.father_name,
      Image: this.state.profiledata.group_photo,
      blood_group: this.state.profiledata.blood_group,
      emp_id: this.state.profiledata.emp_id,
      school_id: this.state.profiledata.school_id,
    });
  }

  OpenthirdModal() {
    this.setState({ loginmodal: true });
  }
  handlenumber(e) {
    this.setState({ mobile: e.target.value });
  }
  handleemail(e) {
    this.setState({ email: e.target.value });
  }
  handleBloodgrp(e) {
    this.setState({ blood_group: e.target.value });
  }

  UpdatepersonalDetails() {
    // formdata.append("father_name", this.state.father_name);
    // formdata.append("group_photo", this.state.Image);
    // formdata.append("dob", this.state.dob);
    // formdata.append("blood_group", this.state.blood_group);
    // formdata.append("email", this.state.email);
    // formdata.append("mobile", this.state.mobile);
    // formdata.append("pre_class_certificate", this.state.Image1);
    // formdata.append("class_id", this.state.classselected);
    // formdata.append("school_id", this.state.schools);
    // formdata.append("user_id", localStorage.getItem("userID"));
    // formdata.append("password", this.state.password);
    // formdata.append("name", this.state.name);
    // formdata.append("photo", this.state.Image2);

    const informations = {
      name: this.state.name,
      emp_id: this.state.emp_id,
      // school_code: this.state.schools,
      mobile: this.state.mobile,
      email: this.state.email,
      password: this.state.password,
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("SchoolApi/edit_profile", informations)
      .then(function (response) {
        console.log(response);
        that.setState({
          res: response.data.result,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        that.getuserdata();
        if (that.state.res === 1) {
          that.setState({ success: true });
          setTimeout(() => that.setState({ success: false }), 3000);
          setTimeout(() => that.setState({ modal: false }), 3000);
          //   setTimeout(() => that.setState({ educationalmodal: false }), 3000);
          setTimeout(() => that.setState({ loginmodal: false }), 3000);
        } else {
          that.setState({
            success: false,
            error: true,
          });
          setTimeout(() => {
            that.setState({ error: false });
          }, 3000);
          setTimeout(() => that.setState({ modal: false }), 3000);
          //   setTimeout(() => that.setState({ educationalmodal: false }), 3000);
          setTimeout(() => that.setState({ loginmodal: false }), 3000);
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.profiledata ? (
          <div>
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1>SCHOOL OBSERVER PROFILE</h1>
              <p>
                <Link style={{ color: "white" }} href="/">
                  Home
                </Link>{" "}
                | School Observer Profile
              </p>
            </div>
            <Container style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item sm={4} md={4} lg={4}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" marginTop={2}>
                      <Box
                        className={classes.dashboardSideButton}
                        display="flex"
                      >
                        <Box
                          marginX={4}
                          style={{ alignSelf: "center", color: "white" }}
                        >
                          <FaLongArrowAltRight />
                        </Box>
                        <Box style={{ alignSelf: "center", color: "white" }}>
                          <Typography>DASHBOARD</Typography>
                        </Box>
                        {/* <Link href="#">
                    <button
                      className={classes.dashboardSideButton}
                    >
                      <FaLongArrowAltRight /> DASHBOARD
                    </button>
                  </Link> */}
                      </Box>
                    </Box>
                    <Box marginTop={1} className={classes.dashboardOtherButton}>
                      <Link
                        href="/observer/student"
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display="flex"
                          style={{ height: "100%", color: "black" }}
                        >
                          <Box marginX={4} style={{ alignSelf: "center" }}>
                            <FaLongArrowAltRight />
                          </Box>
                          <Box style={{ alignSelf: "center" }}>
                            <Typography>STUDENT</Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                    <Box marginTop={1} className={classes.dashboardOtherButton}>
                      <Link
                        href="/observer/teacher"
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display="flex"
                          style={{ height: "100%", color: "black" }}
                        >
                          <Box marginX={4} style={{ alignSelf: "center" }}>
                            <FaLongArrowAltRight />
                          </Box>
                          <Box style={{ alignSelf: "center" }}>
                            <Typography>TEACHER</Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                    <Box marginTop={1} className={classes.dashboardOtherButton}>
                      <Link
                        href="/observer/gameacademy"
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display="flex"
                          style={{ height: "100%", color: "black" }}
                        >
                          <Box marginX={4} style={{ alignSelf: "center" }}>
                            <FaLongArrowAltRight />
                          </Box>
                          <Box style={{ alignSelf: "center" }}>
                            <Typography> GAME ACADEMY</Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                    <Box marginTop={1} className={classes.dashboardOtherButton}>
                      <Link
                        href="/observer/school"
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display="flex"
                          style={{ height: "100%", color: "black" }}
                        >
                          <Box marginX={4} style={{ alignSelf: "center" }}>
                            <FaLongArrowAltRight />
                          </Box>
                          <Box style={{ alignSelf: "center" }}>
                            <Typography>SCHOOL</Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                    <Box marginTop={1} className={classes.dashboardOtherButton}>
                      <Link
                        href="/observer/sciencefair"
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display="flex"
                          style={{ height: "100%", color: "black" }}
                        >
                          <Box marginX={4} style={{ alignSelf: "center" }}>
                            <FaLongArrowAltRight />
                          </Box>
                          <Box style={{ alignSelf: "center" }}>
                            <Typography>SCIENCE-FAIR</Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                    <Box marginTop={1} className={classes.dashboardOtherButton}>
                      <Link
                        href="/observer/subcribers"
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display="flex"
                          style={{ height: "100%", color: "black" }}
                        >
                          <Box marginX={4} style={{ alignSelf: "center" }}>
                            <FaLongArrowAltRight />
                          </Box>
                          <Box style={{ alignSelf: "center" }}>
                            <Typography>SUBSCRIBER</Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography variant="h5">
                    {this.state.profiledata.name}
                  </Typography>
                  <p className={classes.profileLabel}>School Observer</p>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "80px",
                    }}
                  ></div>
                  <Box className={classes.root}>
                    <Box className={classes.personalInfoBox}>
                      <Box className={classes.singleBox}>
                        <Box>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Personal Information
                          </Typography>
                        </Box>
                        <Box>
                          <button
                            className={classes.profileEditButton}
                            // onClick={personalDialog}
                            onClick={() => {
                              this.OpenModal();
                            }}
                          >
                            <MdModeEdit color="white" />
                          </button>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Employee Id
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.emp_id}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            School Code
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.school_code}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />

                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Mobile Number
                          </Typography>
                        </Box>
                        <Box>{this.state.profiledata.mobile}</Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Email Address
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.email}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.loginInfoBox}>
                      <Box className={classes.singleBox}>
                        <Box>
                          {" "}
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Login Information
                          </Typography>
                        </Box>

                        <Box>
                          <button
                            className={classes.profileEditButton}
                            onClick={() => {
                              this.setState({ loginmodal: true });
                            }}
                            // onClick={loginDialog}
                          >
                            <MdModeEdit color="white" />
                          </button>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Mobile Number
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.mobile}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Password
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>********</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>

            <Modal
              size="lg"
              show={this.state.modal}
              onHide={() => this.setState({ modal: false })}
              aria-labelledby="example-modal-sizes-title-sm"
              animation="true"
              autoFocus="true"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Edit Your Personal Information
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        School Observer Name
                      </p>
                      <Form>
                        <TextField
                          fullWidth
                          value={this.state.name}
                          onChange={(evt) =>
                            this.setState({ name: evt.target.value })
                          }
                          type="name"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Email Id
                      </p>
                      <Form>
                        <TextField
                          disabled
                          fullWidth
                          value={this.state.email}
                          onChange={(evt) =>
                            this.setState({ email: evt.target.value })
                          }
                          type="email"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    {/* <div className="col-md-4">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Observer's Image
                      </p>
                      <Form>
                        <Form.Control
                          value={this.state.photo}
                          onChange={(e) => this.handlestudentimg(e)}
                          type="file"
                          placeholder=""
                        />
                      </Form>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Emp_id
                      </p>
                      <Form>
                        <TextField
                          fullWidth
                          value={this.state.emp_id}
                          onChange={(evt) =>
                            this.setState({ emp_id: evt.target.value })
                          }
                          type="name"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Mobile Number
                      </p>
                      <Form>
                        <TextField
                          disabled
                          fullWidth
                          value={this.state.mobile}
                          onChange={(evt) =>
                            this.setState({ mobile: evt.target.value })
                          }
                          type="number"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    {/* <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        School
                      </p>
                      <Form.Select
                        onChange={this.schoolSelected}
                        required
                        aria-labelledby="Select School"
                        value={this.state.schools}
                        aria-label="Default select example"
                      >
                        <option> Select School</option>
                        {this.state.SchoolList.map((l, i) => {
                          return (
                            <option key={i} value={l.school_code}>
                              {" "}
                              {l.name}({l.school_code})
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div> */}
                    {/* <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Email Id
                      </p>
                      <Form>
                        <Form.Control
                          value={this.state.email}
                          onChange={(evt) =>
                            this.setState({ email: evt.target.value })
                          }
                          type="email"
                          placeholder=""
                        />
                      </Form>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="row">
                      {/* <div className="col-md-6">
                        <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                          Mobile Number
                        </p>
                        <Form>
                          <Form.Control
                            value={this.state.mobile}
                            onChange={(evt) =>
                              this.setState({ mobile: evt.target.value })
                            }
                            type="number"
                            placeholder=""
                          />
                        </Form>
                      </div> */}
                    </div>
                    <div className="row" style={{ paddingTop: "20px" }}>
                      <Button
                        variant="primary"
                        onClick={() =>
                          this.setState({
                            modal: false,
                            loginmodal: true,
                          })
                        }
                      >
                        Next Section
                      </Button>
                    </div>
                  </div>
                  {this.state.success ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "lightgreen",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Your Personal Informations Have Been Updated
                        Successfully.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "orangered",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Something Went Wrong.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size="lg"
              show={this.state.loginmodal}
              onHide={() => this.setState({ loginmodal: false })}
              aria-labelledby="example-modal-sizes-title-sm"
              animation="true"
              autoFocus="true"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Edit Your Login Information
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      UserName
                    </p>
                    <Form>
                      <TextField
                        disabled
                        type="text"
                        value={this.state.profiledata.mobile}
                      />
                    </Form>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <p style={{ opacity: "0.6" }}>Password *</p>
                      <OutlinedInput
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.profiledata.text_password}
                        onChange={(evt) =>
                          this.setState({
                            password: evt.target.value,
                          })
                        }
                        fullWidth
                        variant="outlined"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => {
                                this.isshowPass();
                              }}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {/* {accountError && <span className="error">{accountError}</span>} */}
                    </Grid>
                  </Grid>
                  {/* <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Password
                      </p>

                      <Form>
                        <Form.Control
                          value={this.state.password}
                          onChange={(evt) =>
                            this.setState({
                              password: evt.target.value,
                            })
                          }
                          type="text"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Re-enter Password
                      </p>

                      <Form>
                        <Form.Control
                          value={this.state.repass}
                          onChange={(evt) =>
                            this.setState({
                              repass: evt.target.value,
                            })
                          }
                          type="text"
                          placeholder=""
                        />
                      </Form>
                    </div>
                  </div> */}
                  <div className="container">
                    <div className="row" style={{ paddingTop: "20px" }}>
                      <Button
                        variant="primary"
                        onClick={() => this.UpdatepersonalDetails()}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  {this.state.success ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "green",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Your Personal Informations Have Been Updated
                        Successfully.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "red",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Something Went Wrong.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <div className="container" style={{ position: "relative" }}>
            <h5 style={{ position: "relative", top: "30px" }}>
              You Must Log In First
            </h5>
            <div className="col" style={{ paddingTop: "30px" }}>
              <Link href="/login">Login</Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.4",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },

  personalInfoBox: {
    border: "1px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  profileEditButton: {
    backgroundColor: "lightgreen",
    border: "none",
    fontSize: "24px",
    borderRadius: "20px",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
});
// export default Observer_profile;
export default withStyles(styles)(Observer_profile);
