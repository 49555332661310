import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
// import { Router, Link } from "@reach/router"

import API from "../components/api";
import { Modal, Form } from "react-bootstrap";

import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";

class Profile extends Component {
  state = {
    profiledata: [],
    modal: false,
    educationalmodal: false,
    loginmodal: false,
    name: "",
    father_name: "",
    group_photo: "",
    photo: "",
    SchoolList: [],
    classselected: "",
    dob: "",
    userId: "",
    blood_group: "",
    mobile: "",
    email: "",
    success: false,
    Image: "",
    Image1: "",
    Image2: "",
    error: false,
    schools: "",
    class: "",
    pre_class_certificate: "",
    classlist: [],
    password: "",
    repass: "",
  };
  componentDidMount() {
    this.getuserdata();
    this.getSchoolList();

    this.state.group_photo = this.state.Image;
  }
  getSchoolList() {
    var that = this;
    API.get("/MasterApi/school_list")
      .then(function (response) {
        console.log(response);
        that.setState({
          SchoolList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  schoolSelected = (e) => {
    this.setState({ schools: e.target.value });
    console.log("schoold", e.target.value);
    // this.setState({ stateloading: true })
    setTimeout(() => {}, 1000);
  };
  getclassList() {
    var that = this;
    API.post("MasterApi/class_list", {
      school_id: this.state.profiledata.school_id,
    })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
    // fetch(
    //   "https://thingproxy.freeboard.io/fetch/https://sermap.kazma.co.in/backend/Api/MasterApi/class_list",
    //   {
    //     method: "POST",
    //     body: JSON.stringify({ school_id: this.state.schools }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log("Success:", result);
    //     this.setState({ classlist: result.data });
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  }
  getuserdata = async () => {
    const profiledata = this.state;

    // API.post("StudentApi/profile", form)
    //   .then(function (response) {
    //     console.log(response.data.data);
    //     that.setState({
    //       profiledata: response.data.data,
    //     });
    //   })

    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   });
    const userid = localStorage.getItem("userID");
    var that = this;
    API.post("StudentApi/profile", { user_id: userid })
      .then(function (response) {
        console.log(response);
        that.setState({ profiledata: response.data.data });
        that.getclassList();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
    // fetch(
    //   "https://thingproxy.freeboard.io/fetch/https://sermap.kazma.co.in/backend/Api/StudentApi/profile",
    //   {
    //     method: "POST",
    //     body: JSON.stringify({ user_id: userid }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log("Success:", result);
    //     this.setState({ profiledata: result.data });
    //     console.log("datas", this.state.profiledata);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  handlefathername(e) {
    this.setState({ father_name: e.target.value });
    console.log("fathername", this.state.fname);
  }
  handledob(e) {
    this.setState({ dob: e.target.value });
    console.log("fathername", this.state.dob);
  }
  OpenModal() {
    this.setState({ modal: true });
    this.setState({
      name: this.state.profiledata.name,
      mobile: this.state.profiledata.mobile,
      email: this.state.profiledata.email,
      dob: this.state.profiledata.dob,
      father_name: this.state.profiledata.father_name,
      Image: this.state.profiledata.group_photo,
      blood_group: this.state.profiledata.blood_group,
    });
  }
  OpenEducationalModal() {
    this.setState({ educationalmodal: true });
    this.setState({
      classselected: this.state.profiledata.class_id,
      schools: this.state.profiledata.school_id,
      Image1: this.state.profiledata.pre_class_certificate,
    });
  }
  OpenthirdModal() {
    this.setState({ loginmodal: true });
  }
  handlenumber(e) {
    this.setState({ mobile: e.target.value });
  }
  handleemail(e) {
    this.setState({ email: e.target.value });
  }
  handleBloodgrp(e) {
    this.setState({ blood_group: e.target.value });
  }
  handlefatherimg(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  handlestudentimg(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image2: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  handleprevcertificateimg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image1: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  UpdatepersonalDetails() {
    var formdata = new FormData();

    formdata.append("father_name", this.state.father_name);
    formdata.append("group_photo", this.state.Image);
    formdata.append("dob", this.state.dob);
    formdata.append("blood_group", this.state.blood_group);
    formdata.append("email", this.state.email);
    formdata.append("mobile", this.state.mobile);
    formdata.append("pre_class_certificate", this.state.Image1);
    formdata.append("class_id", this.state.profiledata.class_id);
    formdata.append("school_id", this.state.profiledata.school_id);
    formdata.append("user_id", localStorage.getItem("userID"));
    formdata.append("password", this.state.password);
    formdata.append("name", this.state.name);
    formdata.append("photo", this.state.Image2);

    var that = this;
    API.post("StudentApi/edit_profile", formdata)
      .then(function (response) {
        console.log(response);
        that.setState({
          res: response.data.result,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        that.getuserdata();
        if (that.state.res === 1) {
          that.setState({ success: true });
          setTimeout(() => that.setState({ success: false }), 3000);
          setTimeout(() => that.setState({ modal: false }), 3000);
          setTimeout(() => that.setState({ educationalmodal: false }), 3000);
          setTimeout(() => that.setState({ loginmodal: false }), 3000);
        } else {
          that.setState({
            success: false,
            error: true,
          });
          setTimeout(() => {
            that.setState({ error: false });
          }, 3000);
          setTimeout(() => that.setState({ modal: false }), 3000);
          setTimeout(() => that.setState({ educationalmodal: false }), 3000);
          setTimeout(() => that.setState({ loginmodal: false }), 3000);
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.profiledata ? (
          <div>
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1> USER PROFILE</h1>
              <p>
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  href="/"
                >
                  Home{" "}
                </Link>
                | User Profile
              </p>
            </div>
            <Container style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item sm={4} md={4} lg={4}>
                  <Box display="flex" flexDirection="column">
                    <Box style={{ objectFit: "cover" }}>
                      <img
                        style={{ width: "100%" }}
                        src={this.state.profiledata.photo}
                        alt=""
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" marginTop={2}>
                      <Box
                        className={classes.dashboardSideButton}
                        display="flex"
                      >
                        <Box
                          marginX={4}
                          style={{ alignSelf: "center", color: "white" }}
                        >
                          <FaLongArrowAltRight />
                        </Box>
                        <Box style={{ alignSelf: "center", color: "white" }}>
                          <Typography>DASHBOARD</Typography>
                        </Box>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/timeblocking"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>TIME BLOCKING</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/subjectuser"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>SUBJECT</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link href="/school" style={{ textDecoration: "none" }}>
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>SCHOOL</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/teachers"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>TEACHER</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link href="/coding" style={{ textDecoration: "none" }}>
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>CODING</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>

                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/student/motivationalvideo"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>MOTIVATIONAL VIDEO</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/innovation"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>INNOVATE VIDEO</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/gamingacademy"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>GAMING ACADEMY</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/sciencefair"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>SCIENCE FAIR</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/progressreport"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>PROGRESS REPORT</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/notification"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>NOTIFICATION</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/student/subcriptions"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>SUBSCRIPTIONS</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box
                        marginTop={1}
                        className={classes.dashboardOtherButton}
                      >
                        <Link
                          href="/student/wallet"
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            display="flex"
                            style={{ height: "100%", color: "black" }}
                          >
                            <Box marginX={4} style={{ alignSelf: "center" }}>
                              <FaLongArrowAltRight />
                            </Box>
                            <Box style={{ alignSelf: "center" }}>
                              <Typography>SERMAPS WALLET</Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography variant="h5">
                    {this.state.profiledata.name}
                  </Typography>
                  <p className={classes.profileLabel}>Student</p>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "80px",
                    }}
                  ></div>
                  <Box className={classes.root}>
                    <Box className={classes.personalInfoBox}>
                      <Box className={classes.singleBox}>
                        <Box>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Personal Information
                          </Typography>
                        </Box>
                        <Box>
                          <button
                            className={classes.profileEditButton}
                            onClick={() => {
                              this.OpenModal();
                            }}
                          >
                            <MdModeEdit color="white" />
                          </button>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Father's Name
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.father_name}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            DOB
                          </Typography>
                        </Box>
                        <Box>
                          <Typography> {this.state.profiledata.dob}</Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Blood Group
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.blood_group}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Mobile Number
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.mobile}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Email Address
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {this.state.profiledata.email}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.educationInfoBox}>
                      <Box className={classes.singleBox}>
                        <Box>
                          {" "}
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Educational Information
                          </Typography>
                        </Box>
                        <Box>
                          <button
                            className={classes.profileEditButton}
                            onClick={() => {
                              this.OpenEducationalModal();
                            }}
                          >
                            <MdModeEdit color="white" />
                          </button>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            School Name
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {" "}
                            {this.state.profiledata.school}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Class
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {" "}
                            {this.state.profiledata.class}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Previous Certificate
                          </Typography>
                        </Box>
                        <Box marginTop={1}>
                          <img
                            className={classes.universityCertificateStyle}
                            src={this.state.profiledata.pre_class_certificate}
                            alt=""
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box className={classes.loginInfoBox}>
                      <Box className={classes.singleBox}>
                        <Box>
                          {" "}
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            Login Information
                          </Typography>
                        </Box>
                        <Box>
                          <button
                            className={classes.profileEditButton}
                            onClick={() => {
                              this.OpenthirdModal();
                            }}
                          >
                            <MdModeEdit color="white" />
                          </button>
                        </Box>
                      </Box>
                      <Divider />
                      <Box className={classes.profileDivider}>
                        <Box>
                          <Typography className={classes.profileLabel}>
                            Password
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>********</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box marginY={2}>
                      <Button
                        className={classes.updateButton}
                        variant="contained"
                      >
                        UPDATE
                      </Button>
                    </Box>
                  </Box>

                  {/* <div className="col-md-6">
                      <h6 style={{ fontSize: "small" }}>Personal Information</h6>
                    </div>
                    <div className="col-md-2 offset-4">
                      <button
                        className="rounded-circle"
                        style={{ backgroundColor: "lightgreen", border: "none" }}
                      >
                        <MdModeEdit color="white" />
                      </button>
                    </div> */}
                </Grid>
              </Grid>
            </Container>
            {/* <div className="container" style={{ paddingTop: "30px" }}>
              <div className="row">
                <div className="col-md-4 offset-1">
                  <div className="row">
                    <img
                      style={{ height: "400px" }}
                      src={this.state.profiledata.photo}
                    />
                  </div>
                  <div className="row" style={{ paddingTop: "15px" }}>
                    <Link href="/userprofile">
                      <button
                        style={{
                          backgroundColor: "rgb(24, 169, 218)",
                          height: "40px",
                          border: "none",

                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> DASHBOARD
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/timeblocking">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> TIME BLOCKING
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/subjectuser">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> SUBJECT
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/school">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> SCHOOL
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/teachers">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> TEACHER
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/coding">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> CODING
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/student/motivationalvideo">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> MOTIVATIONAL VIDEO
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/innovation">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> INNOVATE VIDEO
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/gamingacademy">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> GAMING ACADEMY
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/sciencefair">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> SCIENCE FAIR
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/progressreport">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> PROGRESS REPORT
                      </button>
                    </Link>
                  </div>
                  <div className="row" style={{ paddingTop: "5px" }}>
                    <Link href="/notification">
                      <button
                        style={{
                          backgroundColor: "lightgrey",
                          height: "40px",
                          border: "none",
                          width: "22rem",
                        }}
                      >
                        <FaLongArrowAltRight /> NOTIFICATION
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="row">
                    {" "}
                    <h6>{this.state.profiledata.name}</h6>
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      Student
                    </p>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "80px",
                      }}
                    ></div>
                  </div>
                  <div
                    className="container"
                    style={{
                      border: "1px solid lightgrey",
                      position: "relative",
                      top: "30px",
                    }}
                  >
                    <div
                      className="row"
                      style={{ height: "50px", paddingTop: "10px" }}
                    >
                      <div className="col-md-6">
                        <h6 style={{ fontSize: "small" }}>
                          Personal Information
                        </h6>
                      </div>
                      <div className="col-md-2 offset-4">
                        <button
                          className="rounded-circle"
                          style={{
                            backgroundColor: "lightgreen",
                            border: "none",
                          }}
                          onClick={() => {
                            this.OpenModal();
                          }}
                        >
                          <MdModeEdit color="white" />
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Father's Name
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <img
                          style={{ height: "45px", width: "45px" }}
                          src={this.state.profiledata.group_photo}
                        />
                      </div>
                      <div className="col-md-2 ">
                        <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                          {this.state.profiledata.father_name}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>DOB</p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.profiledata.dob}
                      </p>
                    </div>
                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Blood Group
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.profiledata.blood_group}
                      </p>
                    </div>
                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Mobile Number
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.profiledata.mobile}
                      </p>
                    </div>
                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Email Address
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.profiledata.email}
                      </p>
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      border: "1px solid lightgrey",
                      position: "relative",
                      top: "40px",
                    }}
                  >
                    <div
                      className="row"
                      style={{ height: "50px", paddingTop: "10px" }}
                    >
                      <div className="col-md-6">
                        <h6 style={{ fontSize: "small" }}>
                          Educational Information
                        </h6>
                      </div>
                      <div className="col-md-2 offset-4">
                        <button
                          className="rounded-circle"
                          style={{
                            backgroundColor: "lightgreen",
                            border: "none",
                          }}
                          onClick={() => {
                            this.OpenEducationalModal();
                          }}
                        >
                          <MdModeEdit color="white" />
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        School Name
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.profiledata.school}
                      </p>
                    </div>

                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Class
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.profiledata.class}
                      </p>
                    </div>

                    <div className="row">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Previous Certificate
                      </p>
                      <img
                        style={{ height: "55px", width: "65px" }}
                        src={this.state.profiledata.pre_class_certificate}
                      />
                    </div>
                  </div>

                  <div
                    className="container"
                    style={{
                      border: "1px solid lightgrey",
                      position: "relative",
                      top: "60px",
                    }}
                  >
                    <div
                      className="row"
                      style={{ height: "50px", paddingTop: "10px" }}
                    >
                      <div className="col-md-6">
                        <h6 style={{ fontSize: "small" }}>Login Information</h6>
                      </div>
                      <div className="col-md-2 offset-4">
                        <button
                          className="rounded-circle"
                          style={{
                            backgroundColor: "lightgreen",
                            border: "none",
                          }}
                          onClick={() => {
                            this.OpenthirdModal();
                          }}
                        >
                          <MdModeEdit color="white" />
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      {" "}
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        User Name
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        {this.state.profiledata.mobile}
                      </p>
                    </div>
                    <div className="row">
                      {" "}
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Password
                      </p>
                      <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                        ***********
                      </p>
                    </div>
                  </div>
                  <div className="col" style={{ paddingTop: "15vh" }}>
                    <button className="btn btn-primary">UPDATE</button>
                  </div>
                  <div style={{ height: "100px" }}></div>
                </div>
              </div>
            </div> */}

            <Modal
              size="lg"
              show={this.state.modal}
              onHide={() => this.setState({ modal: false })}
              aria-labelledby="example-modal-sizes-title-sm"
              animation="true"
              autoFocus="true"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Edit Your Personal Information
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Student Name
                      </p>
                      <Form>
                        <Form.Control
                          value={this.state.name}
                          onChange={(evt) =>
                            this.setState({ name: evt.target.value })
                          }
                          type="name"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Student's Image
                      </p>
                      <Form>
                        <Form.Control
                          value={this.state.photo}
                          onChange={(e) => this.handlestudentimg(e)}
                          type="file"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    <div className="col-md-2" style={{ paddingBottom: "5px" }}>
                      <img
                        style={{ height: "80px", width: "80px" }}
                        src={this.state.profiledata.photo}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Father's Name
                      </p>
                      <Form>
                        <Form.Control
                          value={this.state.father_name}
                          onChange={(evt) => this.handlefathername(evt)}
                          type="name"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Father's Image
                      </p>
                      <Form>
                        <Form.Control
                          value={this.state.group_photo}
                          onChange={(evt) => this.handlefatherimg(evt)}
                          type="file"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    <div className="col-md-2 ">
                      <img
                        style={{ height: "80px", width: "80px" }}
                        src={this.state.profiledata.group_photo}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>Dob</p>
                      <Form>
                        <Form.Control
                          type="date"
                          value={this.state.dob}
                          onChange={(evt) => this.handledob(evt)}
                          placeholder=""
                        />
                      </Form>
                    </div>

                    <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Blood Group
                      </p>
                      <Form.Select
                        value={this.state.blood_group}
                        onChange={(evt) => this.handleBloodgrp(evt)}
                        aria-label="Default select example"
                      >
                        <option></option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="AB+">AB+</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="AB-">AB-</option>
                        <option value="B+">B+</option>
                      </Form.Select>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                          Mobile Number
                        </p>
                        <Form>
                          <Form.Control
                            value={this.state.mobile}
                            onChange={(evt) => this.handlenumber(evt)}
                            type="number"
                            placeholder=""
                          />
                        </Form>
                      </div>

                      <div className="col-md-6">
                        <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                          Email Id
                        </p>
                        <Form>
                          <Form.Control
                            value={this.state.email}
                            onChange={(evt) => this.handleemail(evt)}
                            type="email"
                            placeholder=""
                          />
                        </Form>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        paddingTop: "20px",
                      }}
                    >
                      <Button
                        variant="primary"
                        onClick={() =>
                          this.setState({
                            modal: false,
                            educationalmodal: true,
                          })
                        }
                        style={{ border: "1px solid black" }}
                      >
                        Next Section
                      </Button>
                    </div>
                  </div>
                  {this.state.success ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "lightgreen",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Your Personal Informations Have Been Updated
                        Successfully.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "orangered",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Something Went Wrong.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size="lg"
              show={this.state.educationalmodal}
              onHide={() => this.setState({ educationalmodal: false })}
              aria-labelledby="example-modal-sizes-title-sm"
              animation="true"
              autoFocus="true"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Edit Your Educational Information
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      School
                    </p>

                    <Form.Select
                      disabled
                      onChange={this.schoolSelected}
                      required
                      value={this.state.profiledata.school_id}
                      aria-label="Default select example"
                    >
                      <option></option>
                      {this.state.SchoolList.map((l, school_code) => {
                        return (
                          <option key={school_code} value={l.school_id}>
                            {l.name}({l.school_code})
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>Class</p>
                    <Form.Select
                      disabled
                      onChange={(e) => {
                        this.setState({ classselected: e.target.value });
                      }}
                      required
                      value={this.state.profiledata.class_id}
                      aria-label="Default select example"
                    >
                      <option></option>
                      {this.state.classlist.map((l, i) => {
                        return (
                          <option key={i} value={l.class_id}>
                            {l.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>

                  <div className="container">
                    <div className="row">
                      <div className="col-md-9">
                        <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                          Previous Certificate
                        </p>
                        <Form>
                          <Form.Control
                            value={this.state.pre_class_certificate}
                            onChange={(evt) =>
                              this.handleprevcertificateimg(evt)
                            }
                            type="file"
                            placeholder=""
                          />
                        </Form>
                      </div>
                      <div className="col-md-3" style={{ paddingTop: "10px" }}>
                        <img
                          src={this.state.profiledata.pre_class_certificate}
                          style={{ height: "80px", width: "80px" }}
                        />
                      </div>
                    </div>

                    <div className="row" style={{ paddingTop: "20px" }}>
                      <Button
                        variant="primary"
                        onClick={() =>
                          this.setState({
                            educationalmodal: false,
                            loginmodal: true,
                          })
                        }
                        style={{ border: "1px solid black" }}
                      >
                        Next Section
                      </Button>
                    </div>
                  </div>
                  {this.state.success ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "green",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Your Personal Informations Have Been Updated
                        Successfully.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "red",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Something Went Wrong.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size="lg"
              show={this.state.loginmodal}
              onHide={() => this.setState({ loginmodal: false })}
              aria-labelledby="example-modal-sizes-title-sm"
              animation="true"
              autoFocus="true"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Edit Your Login Information
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="">
                  <div className="col-md-6">
                    <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                      UserName
                    </p>
                    <Form>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="Currently Not Available"
                      />
                    </Form>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Password
                      </p>

                      <Form>
                        <Form.Control
                          value={this.state.profiledata.text_password}
                          onChange={(evt) =>
                            this.setState({
                              password: evt.target.value,
                            })
                          }
                          type="text"
                          placeholder=""
                        />
                      </Form>
                    </div>
                    {/* <div className="col-md-6">
                      <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                        Re-enter Password
                      </p>

                      <Form>
                        <Form.Control
                          value={this.state.repass}
                          onChange={(evt) =>
                            this.setState({
                              repass: evt.target.value,
                            })
                          }
                          type="text"
                          placeholder=""
                        />
                      </Form>
                    </div> */}
                  </div>
                  <div className="container">
                    <div className="row" style={{ paddingTop: "20px" }}>
                      <Button
                        variant="primary"
                        onClick={() => this.UpdatepersonalDetails()}
                        style={{ border: "1px solid black" }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  {this.state.success ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "green",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Your Personal Informations Have Been Updated
                        Successfully.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error ? (
                    <div
                      className="row"
                      style={{
                        paddingTop: "25px",
                        position: "relative",
                        top: "10px",
                        backgroundColor: "red",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        Something Went Wrong.
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <div className="container" style={{ position: "relative" }}>
            <h5 style={{ position: "relative", top: "30px" }}>
              You Must Log In First
            </h5>
            <div className="col" style={{ paddingTop: "30px" }}>
              <Link to="/login">Login</Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "18%",
    color: "white",
  },
  demoPlayerBox: {
    width: "43%",
    height: "200px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
    marginTop: "8px",
  },
  demoPlayerBoxEdit: {
    width: "100%",
    height: "55px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
  },
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileEditButton: {
    backgroundColor: "lightgreen",
    border: "none",
    fontSize: "24px",
    borderRadius: "20px",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  handWritting: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.4",
  },
  updateButton: {
    borderRadius: "0px",
    width: "18vh",
    backgroundColor: "deepskyblue",
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  bankInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  educationInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  personalInfoBox: {
    border: "1px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  schoolCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  universityCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(Profile);
