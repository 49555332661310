import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { GrAddCircle } from "react-icons/gr";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import API from "../components/api";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { ClipLoader } from "react-spinners";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  MenuItem,
  Button,
  Select,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CallIcon from "@mui/icons-material/Call";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

class Classification extends Component {
  state = {
    page: 1,
    gamedata: [],
    gameid: "",
    gamedetails: [],
    timings: [],
    item: "",
    from_time: "",
    to_time: "",
    from_time1: "",
    to_time1: "",
    addfees: "",
    addseat: "",
    addmsg: "",
    addfield: [{ from_time: "", to_time: "" }],
    values: [],
    success: false,
    error: false,
    addmsg: "",
    openTimeSlotDialog: false,
    slotTiming: "",
  };
  componentDidMount() {
    this.getgamedata();
  }
  getgamedata() {
    const params = { user_id: localStorage.getItem("userID") };
    var that = this;
    API.post("AcademyApi/profile", params)
      .then(function (response) {
        console.log(response.data.data.game_ids);
        that.setState({
          gamedata: response.data.data.game_ids,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  createUI() {
    return;
    this.state.addfield.map((el, i) => (
      <div className="row">
        <div className="col-md-5 offset-1">
          <p style={{ opacity: "0.6" }}>From Time</p>
          <Form>
            <TextField
              value={this.state.from_time1}
              onChange={(evt) =>
                this.setState({
                  from_time1: evt.target.value,
                })
              }
              type="time"
              fullWidth
              placeholder=""
            />
          </Form>
        </div>
        <div className="col-md-5">
          <p style={{ opacity: "0.6" }}> To Time</p>
          <Form>
            <TextField
              value={this.state.to_time1}
              onChange={(evt) =>
                this.setState({
                  to_time1: evt.target.value,
                })
              }
              type="time"
              fullWidth
              placeholder=""
            />
          </Form>
        </div>
      </div>
    ));
  }

  handleAddClick() {
    this.setState((prevState) => ({ addfield: [...prevState.addfield, ""] }));
  }
  Addcapacity() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "add",
      game_id: this.state.gameid,
      capacity: this.state.addseat,
      fees: this.state.addfees,
      timing: [
        {
          from_time: this.state.from_time,
          to_time: this.state.to_time,
        },
        {
          from_time: this.state.from_time1,
          to_time: this.state.to_time1,
        },
      ],
    };
    var that = this;
    API.post("AcademyApi/capacity", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          addmsg: response.data.message,
        });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false, page: 3 });
            that.getgamedetails();
          }, 2000);
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 2000);
        }
        // that.getgamedata();
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getgameid(l) {
    console.log("g.id", l.gameId);
    this.setState({ page: 3 });
    this.state.gameid = l.gameId;
    console.log("g.id", this.state.gameid);
    this.getgamedetails();
  }
  getgamedetails() {
    const params = {
      user_id: localStorage.getItem("userID"),
      action: "view",
      game_id: this.state.gameid,
    };
    var that = this;
    API.post("AcademyApi/capacity", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          gamedetails: response.data.data,
          timings: response.data.data.timing,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }

  openSlotTime(time) {
    console.log("editTuitionTime", time);
    this.setState({
      slotTiming: time,
      openTimeSlotDialog: true,
    });
  }

  editTuitionTime(g) {
    this.setState({ page: 2, item: g });
    console.log("editTuitionTimesdfgdsgdfgh", g.game);
  }

  closeSlotTime() {
    this.setState({ openTimeSlotDialog: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> CLASSIFICATION</h1>
          <p>
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              {" "}
              Home
            </Link>{" "}
            |{" "}
            <Link
              href="/gameprofile"
              style={{ textDecoration: "none", color: "white" }}
            >
              Game Academy
            </Link>{" "}
            | Classification
          </p>
        </div>
        {this.state.page === 1 && (
          <>
            <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    CLASSIFICATION
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "13%",
                      marginBottom: "12px",
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                {this.state.gamedata.length > 0 && (
                  <>
                    {this.state.gamedata.map((l, id) => {
                      return (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Button
                            variant="outlined"
                            style={{
                              border: "1px solid lightgrey",
                              textTransform: "capitalize",
                            }}
                            onClick={() => {
                              this.getgameid(l);
                            }}
                            fullWidth
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              style={{ width: "100%" }}
                              paddingY={1}
                            >
                              <Box>
                                <Typography style={{ color: "black" }}>
                                  {l.gameName}
                                </Typography>
                              </Box>
                              <Box>
                                <ArrowForwardIcon style={{ color: "black" }} />
                              </Box>
                            </Box>
                          </Button>
                        </Grid>
                      );
                    })}
                  </>
                )}
                {this.state.gamedata.length === 0 && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box
                      display="flex"
                      style={{ width: "100%" }}
                      paddingY={1}
                      justifyContent="center"
                    >
                      <Box>
                        <Typography>Data Not Found</Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Container>
          </>
        )}

        {this.state.page === 3 && (
          <>
            <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={6} lg={6}>
                  <Box>
                    <button
                      onClick={() => {
                        this.setState({
                          page: 1,
                        });
                      }}
                      className={classes.tuitionTimeStatusButton}
                    >
                      <ArrowBackIcon style={{ color: "white" }} />
                    </button>
                  </Box>
                </Grid>

                <Grid item xs={10} sm={10} md={6} lg={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box>
                      <Button
                        onClick={() => {
                          this.setState({ page: 2 });
                        }}
                        style={{ border: "1px solid black", color: "black" }}
                      >
                        Add Class Time
                        <AddCircleOutlineIcon
                          style={{
                            backgroundColor: "lightgreen",
                            color: "white",
                            borderRadius: "30px",
                            marginLeft: "2px",
                          }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ backgroundColor: "lightgray" }}>
                        Game
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightgray",
                          textAlign: "center",
                        }}
                      >
                        Capacity
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightgray",
                          textAlign: "center",
                        }}
                      >
                        Fees
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightgray",
                          textAlign: "center",
                        }}
                      >
                        Time
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightgray",
                          textAlign: "center",
                        }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.gamedetails.map((g) => (
                      <TableRow hover>
                        <TableCell>{g?.game}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {g?.capacity}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {g?.fees}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Link
                            onClick={() => this.openSlotTime(g)}
                            style={{ textDecoration: "none" }}
                          >
                            {g?.timing[0]?.from_time} to {g?.timing[0]?.to_time}
                          </Link>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <button
                            onClick={() => this.editTuitionTime(g)}
                            className={classes.tuitionTimeStatusButton}
                          >
                            <EditIcon style={{ color: "white" }} />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Container>

            <Dialog
              open={this.state.openTimeSlotDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>
                <Typography>
                  Slot Details of {this.state.slotTiming?.game}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ paddingTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "lightgray" }}>
                            Slot
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              textAlign: "center",
                            }}
                          >
                            Timing
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.slotTiming?.timing?.map((g, index) => (
                          <TableRow hover>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {g?.from_time} to {g?.to_time}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={() => this.closeSlotTime()}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}

        {this.state.page === 2 && (
          <>
            <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <button
                      onClick={() => {
                        this.setState({
                          page: 3,
                        });
                      }}
                      className={classes.tuitionTimeStatusButton}
                    >
                      <ArrowBackIcon style={{ color: "white" }} />
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography>SCHOOL REPORT</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "2px",
                      width: "32%",
                      marginBottom: "12px",
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Seat Capacity</p>
                  <TextField
                    name="capacity"
                    variant="outlined"
                    type="number"
                    value={this.state.addseat}
                    onChange={(evt) =>
                      this.setState({
                        addseat: evt.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Fees</p>
                  <TextField
                    name="fees"
                    variant="outlined"
                    type="number"
                    value={this.state.addfees}
                    onChange={(evt) =>
                      this.setState({
                        addfees: evt.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    justifyContent="flex-end"
                    display="flex"
                    marginTop={2}
                    style={{ width: "100%" }}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "deepskyblue" }}
                        onClick={() => {
                          this.handleAddClick();
                        }}
                        // onClick={increaseSlot}
                      >
                        Add Slot
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                {this.state.addfield.map((el, i) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>Start Time </p>
                        <TextField
                          name="from_time"
                          variant="outlined"
                          type="time"
                          value={this.state.from_time1}
                          onChange={(evt) =>
                            this.setState({
                              from_time1: evt.target.value,
                            })
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={11} sm={11} md={6} lg={6}>
                        <p style={{ opacity: "0.6" }}>End Time </p>
                        <TextField
                          name="to_time"
                          variant="outlined"
                          type="time"
                          value={this.state.to_time1}
                          onChange={(evt) =>
                            this.setState({
                              to_time1: evt.target.value,
                            })
                          }
                          fullWidth
                        />
                      </Grid>
                    </>
                  );
                })}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Start Time </p>
                  <TextField
                    name="from_time"
                    variant="outlined"
                    type="time"
                    value={this.state.from_time}
                    onChange={(evt) =>
                      this.setState({
                        from_time: evt.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={11} sm={11} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>End Time </p>
                  <TextField
                    name="to_time"
                    variant="outlined"
                    type="time"
                    value={this.state.to_time}
                    onChange={(evt) =>
                      this.setState({
                        to_time: evt.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={11} sm={11} md={12} lg={12}>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      {this.state.error && (
                        <Alert severity="error">{this.state.addmsg}</Alert>
                      )}
                      {this.state.success && (
                        <Alert severity="success">{this.state.addmsg}</Alert>
                      )}
                    </Box>
                    <Box>
                      {this.state.loading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                          onClick={() => {
                            this.Addcapacity();
                          }}
                        >
                          Submit
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>

            <Dialog
              open={this.state.openTimeSlotDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>
                <Typography>
                  Slot Details of {this.state.slotTiming?.game}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ paddingTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "lightgray" }}>
                            Slot
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              textAlign: "center",
                            }}
                          >
                            Timing
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.slotTiming?.timing?.map((g, index) => (
                          <TableRow hover>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {g?.from_time} to {g?.to_time}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={() => this.closeSlotTime()}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  }
}
const styles = (theme) => ({
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.6",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },
  profileEditButton: {
    borderRadius: "30%",
    backgroundColor: "lightgrey",
    border: "none",
    color: "white",
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "18%",
    color: "white",
  },
  demoPlayerBox: {
    width: "43%",
    height: "200px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
    marginTop: "8px",
  },
  demoPlayerBoxEdit: {
    width: "100%",
    height: "55px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
  },
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  handWritting: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  updateButton: {
    borderRadius: "0px",
    width: "18vh",
    backgroundColor: "deepskyblue",
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  bankInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  educationInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  personalInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  schoolCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  universityCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  addTuitionButton: {
    backgroundColor: "lightgreen",
    color: "white",
    borderRadius: "30px",
    marginLeft: "2px",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
});
export default withStyles(styles)(Classification);
