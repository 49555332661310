import React, { Component } from "react";
import API from "./api";
import { Button, Collapse } from "react-bootstrap";
import { MdCall } from "react-icons/md";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { withStyles } from "@mui/styles";
import { GrAdd } from "react-icons/gr";
import { AiOutlineMinus } from "react-icons/ai";
// import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CallIcon from "@mui/icons-material/Call";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

class ObserverTeacher extends Component {
  state = {
    classlist: [],
    classselected: "",
    Teacherdata: [],
    eduopen: false,
    detailpage: false,
    datas: "",
    gameopen: false,
    gamesdatas: [],
    open: true,
    teacher: "",
    Tuitionlist: [],
    subsopen: false,
  };
  componentDidMount() {
    this.getclassList();
    this.getacademylist();
  }
  getiddetails(l) {
    this.setState({ detailpage: true });
    console.log("new id", l.teacher_id);
    this.setState({
      datas: l,
      gamesdatas: l.games,
    });
    this.state.teacher = l.teacher_id;
    const result = this.state.Teacherdata.find(
      ({ teacher_id }) => teacher_id === l.teacher_id
    );
    console.log("folafol", result.tutions);
    this.setState({
      Tuitionlist: result.tutions,

      // tuitiontimings: result.tutions.timing,
    });
  }
  getacademylist() {
    // const params = {
    //   school_id: localStorage.getItem("school_id"),
    // };
    const params = {
      school_id: 3,
    };
    var that = this;
    API.post("SchoolApi/teacherList", params)
      .then(function (response) {
        console.log("this", response.data.data);
        that.setState({
          Teacherdata: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", {
      school_id: localStorage.getItem("school_id"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.detailpage ? (
          <div>
            {" "}
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1> TEACHER</h1>
              <p>
                {" "}
                <Link
                  href="/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Home{" "}
                </Link>
                |{" "}
                <Link
                  href="/observer_profile"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  School Observer
                </Link>{" "}
                | Teacher
              </p>
            </div>
            {/* //+++++________+++++++____________++++++++ */}
            {/* <Box style={{ marginLeft: "15vh", paddingTop: "3vh" }}>
              <Button
                style={{
                  borderRadius: "50%",
                  border: "1px solid grey",
                  backgroundColor: "black",
                }}
                onClick={() => {
                  this.setState({ detailpage: false });
                }}
              >
                <HiOutlineArrowNarrowLeft size="15px" />
              </Button>
            </Box> */}
            <Container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <button
                      onClick={() => {
                        this.setState({ detailpage: false });
                      }}
                      className={classes.tuitionTimeStatusButton}
                    >
                      <ArrowBackIcon style={{ color: "white" }} />
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box style={{ height: "400px", width: "100%" }}>
                    <img
                      style={{
                        height: "400px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      src={this.state.datas.photo}
                      alt=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      paddingX={2}
                      paddingTop={1}
                    >
                      <Box>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          {" "}
                          {this.state.datas.name}
                        </Typography>
                      </Box>
                      <Box>
                        <button className={classes.tuitionTimeStatusButton}>
                          <CallIcon style={{ color: "white" }} />
                        </button>
                      </Box>
                    </Box>
                    <Box paddingX={2}>
                      <Typography>{this.state.datas.qualification}</Typography>
                    </Box>
                    <Box paddingX={2} paddingBottom={1}>
                      <Typography>{this.state.datas.experience}</Typography>
                    </Box>
                    <Divider />
                    <Box>
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItemButton
                          onClick={() => {
                            this.setState({ open: !this.state.open });
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            style={{ width: "100%" }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                Personal Information
                              </Typography>
                            </Box>
                            <Box>
                              {this.state.open ? (
                                <RemoveIcon />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Collapse
                          in={this.state.open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box className={classes.personalInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Father's Name
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {this.state.datas.father_name}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  DOB
                                </Typography>
                              </Box>
                              <Box>
                                <Typography> {this.state.datas.dob}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Mobile Number
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {this.state.datas.mobile}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Email Address
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {this.state.datas.email}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Address
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {this.state.datas.address}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Collapse>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ eduopen: !this.state.eduopen });
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            style={{ width: "100%" }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                Educational Information
                              </Typography>
                            </Box>
                            <Box>
                              {this.state.eduopen ? (
                                <RemoveIcon />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Collapse
                          in={this.state.eduopen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box className={classes.educationInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  School Name
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {" "}
                                  {this.state.datas.qualification_school}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  University
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {" "}
                                  {this.state.datas.university}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  School Certificate
                                </Typography>
                              </Box>
                              <Box marginTop={1}>
                                <img
                                  className={classes.universityCertificateStyle}
                                  src={this.state.datas.school_certificate}
                                  alt=""
                                />
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  University Certificate
                                </Typography>
                              </Box>
                              <Box marginTop={1}>
                                <img
                                  className={classes.universityCertificateStyle}
                                  src={this.state.datas.university_certificate}
                                  alt=""
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Collapse>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ subsopen: !this.state.subsopen });
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            style={{ width: "100%" }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                Subscriptional Information
                              </Typography>
                            </Box>
                            <Box>
                              {this.state.subsopen ? (
                                <RemoveIcon />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Collapse
                          in={this.state.subsopen}
                          timeout="auto"
                          unmountOnExit
                        >
                          {/* <Box className={classes.educationInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Subscription Name
                                </Typography>
                              </Box>
                              <Box>
                                <Typography> {this.state.subs.subscription_name}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Ammount
                                </Typography>
                              </Box>
                              <Box >
                                <Typography>{this.state.subs.amount}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Validity
                                </Typography>
                              </Box>
                              <Box >
                                <Typography>{this.state.subs.start_date} -{" "}
                                  {this.state.subs.end_date}</Typography>
                              </Box>
                            </Box>

                          </Box> */}

                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ backgroundColor: "lightgray" }}
                                >
                                  Subject
                                </TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "lightgray",
                                    textAlign: "center",
                                  }}
                                >
                                  Total Seats
                                </TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "lightgray",
                                    textAlign: "center",
                                  }}
                                >
                                  Available Seats
                                </TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "lightgray",
                                    textAlign: "center",
                                  }}
                                >
                                  Booking Seats
                                </TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "lightgray",
                                    textAlign: "center",
                                  }}
                                >
                                  Fees
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.Tuitionlist.length > 0 && (
                              <TableBody>
                                {this.state.Tuitionlist.map((rep) => (
                                  <TableRow hover>
                                    <TableCell>{rep?.subject}</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.total_seat}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.available_seat}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.booking_seat}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.fees}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            )}
                            {this.state.Tuitionlist.length === 0 && (
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>
                            )}
                          </Table>
                        </Collapse>
                      </List>
                      <Box padding={2}>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                        >
                          Student Report
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {/* <Container style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item sm={4} md={4} lg={4}>
                  <Box display="flex" flexDirection="column">
                    <Box style={{ objectFit: "cover" }}>
                      <img
                        style={{ width: "100%" }}
                        src={this.state.datas.photo}
                        alt=""
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Box className={classes.personalInfoBox}>
                    <Box className={classes.singleBox}>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography variant="h5">
                          {this.state.datas.name}
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "80px",
                          }}
                        ></div>
                        <p className={classes.profileLabel}>
                          {this.state.datas.qualification}
                        </p>
                        <p className={classes.profileLabel}>
                          {this.state.datas.experience}
                        </p>
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Button
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "lightgreen",
                            border: "none",
                          }}
                        >
                          <MdCall />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box marginTop={1} className={classes.personalInfoBox}>
                    <Box display="flex" className={classes.singleBox}>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {" "}
                          Personsal Information
                        </Typography>
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        {this.state.open ? (
                          <Button
                            onClick={() => {
                              this.setState({ open: false });
                            }}
                            variant="none"
                          >
                            <AiOutlineMinus size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.open === false ? (
                          <Button
                            onClick={() => {
                              this.setState({ open: true });
                            }}
                            variant="none"
                          >
                            <GrAdd size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                    <Divider />
                    <Collapse in={this.state.open}>
                      <Grid display="flex" flexDirection="column" item>
                        <Box>
                          <Typography variant="p">Father's Name</Typography>
                        </Box>
                        <Box className={classes.rowBox}>
                          <Box className={classes.profileLabel}>
                            <p>{this.state.datas.father_name}</p>
                          </Box>
                        </Box>
                        <Divider />
                        <Box className={classes.singleBox}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="p">DOB</Typography>
                            <Box className={classes.profileLabel}>
                              <p>{this.state.datas.dob}</p>
                            </Box>
                          </Box>
                        </Box>
                        <Divider />
                        <Box className={classes.singleBox}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="p">Mobile Number</Typography>
                            <Box className={classes.profileLabel}>
                              <p>{this.state.datas.mobile}</p>
                            </Box>
                          </Box>
                        </Box>
                        <Divider />
                        <Box className={classes.singleBox}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="p">Email Address</Typography>
                            <Box className={classes.profileLabel}>
                              <p>{this.state.datas.email}</p>
                            </Box>
                          </Box>
                        </Box>
                        <Divider />
                        <Box className={classes.singleBox}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="p">Location</Typography>
                            <Box className={classes.profileLabel}>
                              <p>{this.state.datas.address}</p>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Collapse>
                  </Box>
                  <Box marginTop={1} className={classes.personalInfoBox}>
                    <Box display="flex" className={classes.singleBox}>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {" "}
                          Educational Information
                        </Typography>
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        {this.state.eduopen ? (
                          <Button
                            onClick={() => {
                              this.setState({ eduopen: false });
                            }}
                            variant="none"
                          >
                            <AiOutlineMinus size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.eduopen === false ? (
                          <Button
                            onClick={() => {
                              this.setState({ eduopen: true });
                            }}
                            variant="none"
                          >
                            <GrAdd size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                    <Divider />
                    <Collapse in={this.state.eduopen}>
                      <Grid display="flex" flexDirection="column" item>
                        <Box>
                          <Typography variant="p">
                            Qualification School
                          </Typography>
                        </Box>
                        <Box className={classes.rowBox}>
                          <Box className={classes.profileLabel}>
                            <p>{this.state.datas.qualification_school}</p>
                          </Box>
                        </Box>
                        <Divider />
                        <Box className={classes.singleBox}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="p">University</Typography>
                            <Box className={classes.profileLabel}>
                              <p>{this.state.datas.university}</p>
                            </Box>
                          </Box>
                        </Box>
                        <Divider />

                        <Box className={classes.singleBox}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="p">
                              {" "}
                              School Certificate
                            </Typography>
                            <Box>
                              <img
                                style={{ height: "80px", width: "80px" }}
                                src={this.state.datas.school_certificate}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Divider />
                        <Box className={classes.singleBox}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="p">
                              {" "}
                              University Certificate
                            </Typography>
                            <Box>
                              <img
                                style={{ height: "80px", width: "80px" }}
                                src={this.state.datas.university_certificate}
                              />
                            </Box>
                          </Box>
                        </Box>
                        
                      </Grid>
                    </Collapse>
                  </Box>
                  <Box marginTop={1} className={classes.personalInfoBox}>
                    <Box display="flex" className={classes.singleBox}>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {" "}
                          Subscriptional Information
                        </Typography>
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        {this.state.subsopen ? (
                          <Button
                            onClick={() => {
                              this.setState({ subsopen: false });
                            }}
                            variant="none"
                          >
                            <AiOutlineMinus size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.subsopen === false ? (
                          <Button
                            onClick={() => {
                              this.setState({ subsopen: true });
                            }}
                            variant="none"
                            style={{ paddingBottom: "2vh" }}
                          >
                            <GrAdd size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                    <Divider />
                    <Collapse in={this.state.subsopen}>
                      <div>
                        <div className="row" style={{ marginLeft: "3vh" }}>
                          <div className="col-md-2">
                            <div className="row">
                              <Typography>Subject</Typography>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="row">
                              <Typography>Total Seat</Typography>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="row">
                              <Typography>Available Seat</Typography>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <Typography>Booking Seat</Typography>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="row">
                              <Typography>Fees</Typography>
                            </div>
                          </div>
                          <div className="row">
                            <p
                              style={{
                                fontSize: "smaller",
                                opacity: "0.8",
                              }}
                            >
                            </p>
                          </div>
                        </div>
                        {this.state.Tuitionlist.map((l, id) => {
                          return (
                            <div className="row">
                              <div className="col-md-3">
                                <p style={{ fontWeight: "bold" }}>
                                  {l.subject}
                                </p>
                                <Divider />
                              </div>
                              <div className="col-md-2">
                                <p>{l.total_seat}</p>
                              </div>
                              <div className="col-md-2">
                                <p>{l.available_seat}</p>
                              </div>
                              <div className="col-md-3">
                                <p>{l.booking_seat}</p>
                              </div>
                              <div className="col-md-2">
                                <p>{l.fees}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      
                    </Collapse>
                  </Box>
                  <Box marginTop={1} className={classes.reportInfoButton}>
                    <Button>
                      <HiOutlineArrowNarrowRight />
                      STUDENT REPORT
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container> */}
            {/* //_______+++++___________+++++++_____________ */}
          </div>
        ) : (
          <div>
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1> TEACHER</h1>
              <p>
                <Link
                  href="/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Home
                </Link>{" "}
                |{" "}
                <Link
                  href="/observer_profile"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  School Observer
                </Link>{" "}
                | Teacher
              </p>
            </div>

            <Container>
              {/* <Grid item ls={10} md={10} sm={8} xs={8}> */}
              <Grid marginTop={1}>
                <Box>
                  <Typography variant="h5">TEACHERS</Typography>
                  <div
                    style={{
                      height: "3px",
                      width: "80px",
                      textDecoration: "underline",
                      backgroundColor: "lightblue",
                    }}
                  ></div>
                </Box>
              </Grid>
              <Container spacing={2} style={{ paddingTop: "3vh" }}>
                {this.state.Teacherdata === undefined ? (
                  <Box>No Teacher Found</Box>
                ) : (
                  <>
                    {this.state.Teacherdata.map((l, id) => {
                      return (
                        <Grid
                          marginTop={1}
                          display="flex"
                          flexDirection="column"
                        >
                          <Grid item ls={12} md={12} sm={8} xs={8}>
                            <Box className={classes.root} boxShadow={5}>
                              <Box className={classes.personalInfoBox}>
                                <Box className={classes.singleBox}>
                                  <Box>
                                    <Typography
                                      // variant="h6"
                                      style={{ fontWeight: "" }}
                                      fontSize={18}
                                    >
                                      {l.name}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <button
                                      className={classes.profileEditButton}
                                      onClick={() => {
                                        this.getiddetails(l);
                                      }}
                                      // onClick={personalDialog}
                                    >
                                      <HiOutlineArrowNarrowRight />{" "}
                                      {/* <MdModeEdit color="white" /> */}
                                    </button>
                                  </Box>
                                </Box>
                                {/* <Divider /> */}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        // <Grid item ls={10} md={10} sm={8} xs={8}>
                        //   <Box className={classes.singleBox}>
                        //     <Box style={{ alignSelf: "center" }}>
                        //       <Button
                        //         variant="none"
                        //         style={{
                        //           border: "2px solid lightgrey",
                        //           paddingTop: "3vh",
                        //           paddingBottom: "3vh",
                        //           textAlign: "left",
                        //         }}
                        //         onClick={() => {
                        //           this.getiddetails(l);
                        //         }}
                        //       >
                        //         {l.name}
                        //       </Button>
                        //     </Box>
                        //     <Box style={{ alignSelf: "center" }}>
                        //       <HiOutlineArrowNarrowRight />
                        //     </Box>
                        //   </Box>
                        // </Grid>
                      );
                    })}
                  </>
                )}
              </Container>
            </Container>
          </div>
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.6",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },
  profileEditButton: {
    borderRadius: "30%",
    backgroundColor: "lightgrey",
    border: "none",
    color: "white",
  },
  reportInfoButton: {
    // border: "1px solid lightgrey",
    // borderRadius: "5%",

    display: "flex",
    flexDirection: "column",
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "18%",
    color: "white",
  },
  demoPlayerBox: {
    width: "43%",
    height: "200px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
    marginTop: "8px",
  },
  demoPlayerBoxEdit: {
    width: "100%",
    height: "55px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
  },
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  handWritting: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  updateButton: {
    borderRadius: "0px",
    width: "18vh",
    backgroundColor: "deepskyblue",
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  bankInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  educationInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  personalInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  schoolCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  universityCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  addTuitionButton: {
    backgroundColor: "lightgreen",
    color: "white",
    borderRadius: "30px",
    marginLeft: "2px",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
});
export default withStyles(styles)(ObserverTeacher);
