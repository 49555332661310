import React, { Component, useEffect, useState, CSSProperties } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FloatingLabel, Form } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  TextareaAutosize
} from '@mui/material'
import { withStyles } from '@mui/styles';
import useWindowSize from '../../provider/useWindowSize'
import API from "../../components/api";
import { DayPicker, DateFormatter } from 'react-day-picker';
import 'react-day-picker/style.css';
import { addDays, addMonths, isSameMonth, format } from 'date-fns';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
const nextMonth = addMonths(new Date(), 1);



const Attendance = ({ classes }) => {
  const [pButton, setPButton] = useState(true)
  const [aButton, setAButton] = useState(false)
  const [attendanceAdd, setAttendanceAdd] = useState({
    user_id: "",
    month: "",
    year: "",
    action: "add",
    date: "",
    status: "Present",
    message: ""
  })

  const [today, setToday] = useState("")
  const [currentDay, setCurrentDay] = useState(0)
  const [currentMonth, setCurrentMonth] = useState(0)
  const [currentYear, setCurrentYear] = useState(0)
  const [month, setMonth] = useState(nextMonth)
  const [attendanceList, setAttendanceList] = useState([])
  const [absentCount, setAbsentCount] = useState(0)
  const [presentCount, setPresentCount] = useState(0)
  const [totalAbsent, setTotalAbsent] = useState(0)
  const [totalPresent, setTotalPresent] = useState(0)
  const [totalHoliday, setTotalHoliday] = useState(0)
  const [dateList, setDateList] = useState([])
  const [dateSingle, setDateSingle] = useState("")
  const [selectedDays, setSelectedDays] = useState([])
  const [presentDays, setPresentDays] = useState([])
  const [absentDays, setAbsentDays] = useState([])
  const selectedDaysStyle = { border: '2px solid currentColor' };

  const [addAttendanceButtonVisibility, setAddAttendanceButtonVisibility] = useState(false)

  // const [selectedDayofNextMonth,setSelectedDayofNextMonth]=useState(addDays(new Date(), 58))


  const [totalDaysThisMonth, setTotalDaysThisMonth] = useState(0)



  const modifiers = {
    holiday: new Date(2021, 10, 5)
  };
  // const modifiersStyles = {
  //   holiday: {
  //     color: 'white',
  //     backgroundColor: '#ffc107',
  //   },
  // };
  const style = `.my-booked-class { color: tomato }`;

  console.log("todays day", new Date().getDate());

  useEffect(() => {
    if (attendanceAdd?.date && attendanceAdd?.message && attendanceAdd?.status) {
      setAddAttendanceButtonVisibility(true)
    } else {
      setAddAttendanceButtonVisibility(false)
    }
  }, [attendanceAdd])


  useEffect(() => {

    setToday(new Date())
    let d = new Date()
    let d1 = d.toLocaleDateString()
    let dat = d1.split("/")
    setCurrentDay(Number(dat[0]))
    setCurrentMonth(Number(dat[1]))
    setCurrentYear(Number(dat[2]))
    getAttendanceList(dat[1], dat[2])
  }, [])

  // useEffect(() => {
  //   if(dateSingle){
  //     selectedDays.push(dateSingle)
  //     alert(selectedDays.length)
  //   }

  // }, [dateSingle])

  console.log("dateSingle", dateSingle);
  console.log("dateselectedDays", selectedDays);





  const getNextMonth = (e) => {
    let d1 = e.toLocaleDateString()
    let dat = d1.split("/")
    setCurrentDay(Number(dat[0]))
    setCurrentMonth(Number(dat[1]))
    setCurrentYear(Number(dat[2]))
    getAttendanceList(dat[1], dat[2])
    console.log("currentMonth", e.toLocaleDateString());
    setMonth(e)
  }

  const getAttendanceList = (mon, year) => {
    let dates = []
    let presentDates = []
    let absentDates = []

    let payload = {
      user_id: localStorage.getItem("userID"),
      month: mon,
      year: year,
      action: "view"
    }
    setTotalDaysThisMonth(new Date(year, mon, 0).getDate())
    API.post("TeacherApi/attendance", payload).then(res => {
      if (res.data.result === 1) {
        console.log("attendenceViewList", res)
        if (res.data.message === "Attendance List") {
          setAttendanceList(res.data.data)
          for (let i = 0; i < res.data.data.length; i++) {
            let d = res.data.data[i].date
            let d1 = d.split("-")
            let d2 = d1[1] + "/" + d1[2] + "/" + d1[0]
            let day = Number(d1[2])
            let toda = new Date().toLocaleDateString()
            let currentday = toda.split("/")[0]
            let currentmonth = toda.split("/")[1]
            let currentyear = toda.split("/")[2]

            let today = toda.split("/")[1] + "/" + toda.split("/")[0] + "/" + toda.split("/")[2]

            const date1 = new Date(today);
            const date2 = new Date(d2);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            console.log("responseDate", res.data.data[i].date);
            console.log("responseDatediffTime", diffDays);

            if (date2 > date1) {
              dates.push(addDays(new Date(), diffDays))
            } else if (date2 < date1) {
              dates.push(addDays(new Date(), -diffDays))
            }

            // let diff = day - currentday
            console.log("dayssdfafsgsdhhfg", day);
            console.log("dayssdfafsgsdhhfg12345", diffDays);
            console.log("totalDays1234567", totalDaysThisMonth);


            // if(d1[2]>currentyear){

            // }else if(d1[2]<currentyear){

            // }else if(d1[2]===currentyear)

            console.log("toDays1234567", today);
            console.log("toDaysresponseDate", d2);

            //             let date1 = new Date(toda);
            // let date2 = new Date(d2);






            if (res.data.data[i].status === 'Present') {
              // let d=res.data.data[i].date
              // let d1=d.split("-")
              // let day=Number(d1[2])
              // let toda=new Date().toLocaleDateString()
              // let today=toda.split("/")[0]
              // let diff=day-today
              // console.log("dayssdfafsgsdhhfg",day);
              // console.log("dayssdfafsgsdhhfg12345",diff);

              // presentDates.push(addDays(new Date(), Number(diffDays)))
              if (date2 > date1) {
                presentDates.push(addDays(new Date(), diffDays))
              } else if (date2 < date1) {
                presentDates.push(addDays(new Date(), -diffDays))
              }
            }
            if (res.data.data[i].status === 'Absent') {
              if (date2 > date1) {
                absentDates.push(addDays(new Date(), diffDays))
              } else if (date2 < date1) {
                absentDates.push(addDays(new Date(), -diffDays))
              }
              // absentDates.push(addDays(new Date(), Number(diffDays)))
            }

          }
          // selectedDays.push(dateSingle)
          setSelectedDays(dates)
          setAbsentDays(absentDates)
          setPresentDays(presentDates)
          setTotalPresent(presentDates.length)
          setTotalAbsent(absentDates.length)

          console.log("presentDates", presentDates.length);
          console.log("absentDates", absentDates.length);

        } else {
          setAttendanceList([])
          setDateList([])
          setDateSingle("")
          setTotalPresent(0)
          setTotalAbsent(0)
        }


      } else {
        setAttendanceList([])
        setDateList([])
        setDateSingle("")
      }
    })
  }
  console.log("datesdfsdf", currentDay, currentMonth, currentYear);
  // console.log("totalDays", totalDaysThisMonth);
  console.log("totalDays", selectedDays);
  console.log("totalPresenDays", presentDays);
  console.log("totalAbsentDays", absentDays);



  const pButtonStatus = (stat) => {
    setPButton(true)
    setAButton(false)
    setAttendanceAdd({
      ...attendanceAdd,
      status: stat
    })
  }
  const aButtonStatus = (stat) => {
    setPButton(false)
    setAButton(true)
    setAttendanceAdd({
      ...attendanceAdd,
      status: stat
    })
  }

  const getMessage = (e) => {
    setAttendanceAdd({
      ...attendanceAdd,
      message: e.target.value
    })
  }

  const getDate = (e) => {
    let da = e.toLocaleDateString()
    let dat = da.split("/")
    let user = localStorage.getItem("userID")
    setAttendanceAdd({
      ...attendanceAdd,
      month: dat[1],
      year: dat[2],
      date: dat[2] + "-" + dat[1] + "-" + dat[0],
      user_id: user
    })
    console.log("Date123", dat[2], dat[1], dat[0])
    console.log("Date123Date", dat[0])
    console.log("Date123Month", dat[1])
    console.log("Date123Year", dat[2])



    console.log("day", da);
  }

  const submitAttendance = () => {

    API.post("TeacherApi/attendance", attendanceAdd).then(res => {
      if (res.data.result === 1) {
        console.log("attendenceAdded", res)
        setAttendanceAdd({
          user_id: "",
          month: "",
          year: "",
          action: "add",
          date: "",
          status: "Present",
          message: ""
        })
        setPButton(true)
        setAButton(false)

        let d = new Date()
        let d1 = d.toLocaleDateString()
        let dat = d1.split("/")
        setCurrentDay(Number(dat[0]))
        setCurrentMonth(Number(dat[1]))
        setCurrentYear(Number(dat[2]))
        getAttendanceList(dat[1], dat[2])

      }
    })
  }



  const cancelAttendence = () => {
    setAttendanceAdd({
      user_id: "",
      month: "",
      year: "",
      action: "add",
      date: "",
      status: "Present",
      message: ""
    })
    setPButton(true)
    setAButton(false)
  }
  console.log("payload12", attendanceAdd);
  console.log("dateList", dateList);


  const formatDay = (day) => {
    const date = day.getDate();
    const mo = day.getMonth()
    console.log("sdfsdfsfgfsdgsdfgsdfg", day);
    console.log("pressss", presentDays);
    console.log("abseee", absentDays);
    console.log("currentMont1321343535645", currentMonth);
    for (let i = 0; i < presentDays.length; i++) {
      if ((date === presentDays[i].getDate()) && (mo === presentDays[i].getMonth())) {
        return (
          <button style={{ width: "40px", height: "40px", backgroundColor: "lightgreen" }}>{presentDays[i].getDate()}</button>
        );
      }

    }
    for (let i = 0; i < absentDays.length; i++) {
      if ((date === absentDays[i].getDate()) && (mo === absentDays[i].getMonth())) {
        return (
          <button style={{ width: "40px", height: "40px", backgroundColor: "red" }}>{absentDays[i].getDate()}</button>
        );
      }

    }

    return `${date}`;
  };





  // const modifiers = {
  //   month: new Date(2018, 8)
  // };
  // const currentMonthStyle = { border: '2px solid currentColor' };
  return (
    <>
      <head>
        <style>{style}</style>
      </head>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
        <h1> ATTENDANCE</h1>
        <p><Link href="/" style={{ color: "white", textDecoration: "none" }}>Home</Link> | Teacher | Attendance</p>

      </div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box style={{ justifyContent: "center", border: "1px solid lightgrey" }} display="flex">
              <Box >
                {/* <Calendar calendarType="Hebrew" view="month" maxDetail="month" onChange={getDate} /> */}
                {/* <Datepicker/> */}
                {/* <DayPicker onDayClick={getDate}/> */}
                <DayPicker
                  onDayClick={getDate}
                  mode="single"
                  onMonthChange={getNextMonth}
                  selected={selectedDays}
                  formatters={{ formatDay }}
                  styles={{
                    caption: { borderBottom: '1px solid lightgray' }
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" style={{ height: "100%", alignSelf: "center" }} >
                <Box display="flex" marginY={2} >
                  <Box style={{ backgroundColor: "lightgreen", height: "20px", width: "20px", borderRadius: "20px" }} marginX={2}></Box>
                  <Box><Typography>Present Days</Typography></Box>
                </Box>
                <Box display="flex" marginY={2} >
                  <Box style={{ backgroundColor: "red", height: "20px", width: "20px", borderRadius: "20px" }} marginX={2}></Box>
                  <Box><Typography>Absent Days</Typography></Box>
                </Box>
                <Box display="flex" marginY={2} >
                  <Box style={{ backgroundColor: "yellow", height: "20px", width: "20px", borderRadius: "20px" }} marginX={2}></Box>
                  <Box><Typography>Holidays</Typography></Box>
                </Box>
              </Box>

            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
              <Box display="flex" flexDirection="column">
                <Box style={{ width: "100%", height: "71px", backgroundColor: "deepskyblue" }} display="flex">
                  <Box marginX={4} style={{ alignSelf: "center", color: "white" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>ATTENDANCE DETAILS</Typography>
                  </Box>

                </Box>
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Present Days</Typography>

                  </Box>
                  <Box>
                    <Typography>{totalPresent}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Absant Days</Typography>
                  </Box>
                  <Box>
                    <Typography>{totalAbsent}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Holidays</Typography>
                  </Box>
                  <Box>
                    <Typography>{totalHoliday}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box style={{ width: "100%", height: "71px", backgroundColor: "deepskyblue" }} display="flex">

                  <Box marginX={4} style={{ alignSelf: "center", color: "white" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>TODAY'S ATTENDANCE</Typography>
                  </Box>
                </Box>
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Status</Typography>

                  </Box>
                  <Box>
                    <button onClick={() => pButtonStatus("Present")} className={pButton ? classes.pButtonClicked : classes.pButton}>P</button>
                    <button onClick={() => aButtonStatus("Absent")} className={aButton ? classes.aButtonClicked : classes.aButton}>A</button>
                  </Box>
                </Box>
                {/* <Divider />
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Seats</Typography>
                  </Box>
                  <Box>
                    <Typography>40</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Tuition Fee</Typography>
                  </Box>
                  <Box>
                    <Typography>400.00</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" style={{ justifyContent: "center" }} padding={2}>
                  <Box>
                    <Button variant="contained" style={{ backgroundColor: "deepskyblue" }}>Edit</Button>
                  </Box>
                </Box> */}
              </Box>
              <Box display="flex" flexDirection="column">
                <Box style={{ width: "100%", height: "71px", backgroundColor: "deepskyblue" }} display="flex">
                  <Box marginX={4} style={{ alignSelf: "center", color: "white" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>Message</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box style={{ width: "100%" }}>
                    <TextareaAutosize
                      onChange={getMessage}
                      style={{ width: "100%" }}
                      placeholder="Write Message"
                      value={attendanceAdd.message}
                      maxLength={500}
                      minRows={4}
                      maxRows={4}
                    />
                  </Box>
                  <Box display="flex">
                    <Box marginRight={2}>

                      {addAttendanceButtonVisibility ?
                        <Button variant="contained" style={{ backgroundColor: "deepskyblue" }} onClick={submitAttendance}>Submit</Button>
                        :
                        <Button variant="contained" disabled>Submit</Button>}
                    </Box>
                    <Box>
                      <Button variant="text" onClick={cancelAttendence}>Cancel</Button>
                    </Box>


                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <div className="container" style={{ paddingTop: "30px" }}>
          <div className="row">
            <div className="col-md-6 offset-1">
              <Calendar calendarType="Hebrew" view="month" maxDetail="month" />
            </div>
            <div className="col-md-4">
              <div
                className="row"
                style={{
                  backgroundColor: "rgb(24, 169, 218)",
                  paddingTop: "20px",
                }}
              >
                <p
                  style={{
                    color: "white",
                    positon: "relative",
                    top: "15px",
                    left: "10px",
                  }}
                >
                  <FaLongArrowAltRight />
                  ATTENDANCE DETAILS
                </p>
              </div>
              <div
                className="row"
                style={{
                  paddingTop: "20px",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div className="col-md-7 offset-1">
                  <p style={{ fontSize: "smaller" }}>Present Days</p>
                </div>
                <div className="col-md-4">
                  <p style={{ fontSize: "smaller" }}>9</p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  paddingTop: "20px",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div className="col-md-7 offset-1">
                  <p style={{ fontSize: "smaller" }}>Absent Days</p>
                </div>
                <div className="col-md-4">
                  <p style={{ fontSize: "smaller" }}>2</p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  paddingTop: "20px",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div className="col-md-7 offset-1">
                  <p style={{ fontSize: "smaller" }}>Holidays</p>
                </div>
                <div className="col-md-4">
                  <p style={{ fontSize: "smaller" }}>0</p>
                </div>

                <div
                  className="row"
                  style={{
                    backgroundColor: "rgb(24, 169, 218)",
                    paddingTop: "20px",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      positon: "relative",
                      top: "15px",
                      left: "10px",
                    }}
                  >
                    <FaLongArrowAltRight />
                    TODAY'S ATTENDANCE
                  </p>
                </div>
                <div
                  className="row"
                  style={{
                    paddingTop: "20px",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <div className="col-md-7 offset-1">
                    <p style={{ fontSize: "smaller" }}>Status</p>
                  </div>
                  <div className="col-md-1">
                    <button
                      className="rounded-circle"
                      style={{
                        height: "30px",
                        border: "none",
                        width: "30px",
                        backgroundColor: "lightgreen",
                      }}
                    >
                      P
                    </button>
                  </div>
                  <div className="col-md-2">
                    <button
                      className="rounded-circle"
                      style={{
                        height: "30px",
                        border: "none",
                        width: "30px",
                        backgroundColor: "lightgrey",
                      }}
                    >
                      A
                    </button>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    backgroundColor: "rgb(24, 169, 218)",
                    paddingTop: "20px",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      positon: "relative",
                      top: "15px",
                      left: "10px",
                    }}
                  >
                    <FaLongArrowAltRight />
                    MESSAGE
                  </p>
                </div>
                <div className="row">
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Write A Message"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                    />
                  </FloatingLabel>
                </div>
                <div className="col" style={{ paddingTop: "20px" }}>
                  <button
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      border: "none",
                      color: "white",
                      height: "35px",
                      width: "80px",
                    }}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  pButton: {
    height: "40px",
    width: "40px",
    backgroundColor: "lightgray",
    borderRadius: "20px",
    border: "none",
    marginRight: "4px"
  },
  pButtonClicked: {
    height: "40px",
    width: "40px",
    backgroundColor: "lightgreen",
    borderRadius: "20px",
    border: "none",
    marginRight: "4px"
  },
  aButton: {
    height: "40px",
    width: "40px",
    backgroundColor: "lightgray",
    borderRadius: "20px",
    border: "none"
  },
  aButtonClicked: {
    height: "40px",
    width: "40px",
    backgroundColor: "lightgreen",
    borderRadius: "20px",
    border: "none"
  },
  "& .DayPicker-Month": {
    width: "500px"
  }
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(Attendance);
