import React, { Component, useEffect, useState } from "react";
import { GrAddCircle } from "react-icons/gr";
import { GrEdit } from "react-icons/gr";
import { AiOutlineDelete } from "react-icons/ai";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  DialogContentText,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from "../../provider/useWindowSize";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import API from "../../components/api";

const TuitionTime = ({ classes }) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const { sm, md, lg } = theme.breakpoints.values;
  const [openAddTuitionTime, setOpenAddTuitionTime] = useState(false);
  const [openSlotTime, setOpenSlotTime] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");

  const [subjectList, setSubjectList] = useState([]);
  const [tuitionTimeList, setTuitionTimeList] = useState([]);
  const [slotListLength, setSlotListLength] = useState([
    {
      from_time: "",
      to_time: "",
    },
  ]);
  const [newSlot, setNewSlot] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");

  const [addTiming, setAddTiming] = useState("");

  const [slotTime, setSlotTime] = useState({
    from_time: "",
    to_time: "",
  });
  const [addIndex, setAddIndex] = useState("");
  const [editTuitionTimeDialog, setEditTuitionTimeDialog] = useState(false);
  const [deleteTuitionTimeDialog, setDeleteTuitionTimeDialog] = useState(false);
  const [editingSubjectTuitionTime, setEditingSubjectTuitionTime] = useState({
    user_id: "",
    action: "",
    capacity: "",
    subject_id: "",
    subject: "",
    fees: "",
    totalseat: "",
    timing: [],
  });
  const [editTiming, setEditTiming] = useState([]);
  const [newEditSlot, setNewEditSlot] = useState("");
  const [newDeleteEditSlot, setNewDeleteEditSlot] = useState("");
  const [deletingSubjectTuitionTime, setDeletingSubjectTuitionTime] =
    useState("");

  useEffect(() => {
    let user = localStorage.getItem("userID");
    setAddTiming({
      ...addTiming,
      user_id: user,
    });
    API.post("MasterApi/subjects").then((res) => {
      if (res.data.result === 1) {
        setSubjectList(res.data.data);
      } else {
        setSubjectList([]);
      }
    });
    getTuitionTimeList();
  }, []);

  useEffect(() => {
    if (newSlot) {
      slotListLength.push(newSlot);
      setNewSlot("");
      setDeleteIndex("");
    }

    if (deleteIndex) {
      slotListLength.splice(deleteIndex, 1);
      setNewSlot("");
      setDeleteIndex("");
    }

    if (newEditSlot) {
      editTiming.push(newEditSlot);
      setNewEditSlot("");
      setNewDeleteEditSlot("");
    }

    if (newDeleteEditSlot) {
      editTiming.splice(newDeleteEditSlot, 1);
      setNewEditSlot("");
      setNewDeleteEditSlot("");
    }
  }, [
    newSlot,
    slotListLength,
    deleteIndex,
    newEditSlot,
    editTiming,
    newDeleteEditSlot,
  ]);

  const getTuitionTimeList = () => {
    let user = localStorage.getItem("userID");

    let payload = {
      user_id: user,
      action: "view",
      subject_id: "",
    };
    API.post("TeacherApi/tutionTime", payload).then((res) => {
      if (res.data.result === 1) {
        setTuitionTimeList(res.data.data);
      } else {
        setTuitionTimeList([]);
      }
    });
  };

  const addTuitionTime = () => {
    setAddTiming({
      user_id: localStorage.getItem("userID"),
      action: "add",
      subject_id: "",
      capacity: "",
      timing: [],
      fees: "",
    });
    setOpenAddTuitionTime(true);
  };

  const handleClose = () => {
    setOpenAddTuitionTime(false);
    setSlotListLength([
      {
        from_time: "",
        to_time: "",
      },
    ]);
  };

  const increaseSlot = () => {
    setNewSlot({
      from_time: "",
      to_time: "",
    });
  };

  const increaseEditSlot = () => {
    setNewEditSlot({
      from_time: "",
      to_time: "",
    });
  };

  const decreaseSlot = (i) => {
    setDeleteIndex(i);
  };

  const decreaseEditSlot = (i) => {
    setNewDeleteEditSlot(i);
  };

  const editTuitionTime = (time) => {
    // let user = localStorage.getItem("userID")
    setEditingSubjectTuitionTime({
      ...editingSubjectTuitionTime,
      user_id: localStorage.getItem("userID"),
      action: "update",
      capacity: time.capacity,
      subject: time.subject,
      subject_id: time.subject_id,
      fees: time.fees,
      totalseat: time.total_seat,
      timing: time.timing,
    });
    // setEditingSubjectTuitionTime({
    //     ...editingSubjectTuitionTime,
    //     action: "update",
    //     user_id: user
    // })
    setEditTiming(time.timing);
    setEditTuitionTimeDialog(true);
    console.log("editTuitionTime", time);
  };

  const getEditStartTiming = (e, index) => {
    const { name, value } = e.target;
    for (let i = 0; i < editTiming.length; i++) {
      if (i === index && name === "from_time") {
        editTiming[i].from_time = value;
      } else if (i === index && name === "to_time") {
        editTiming[i].to_time = value;
      }
    }

    console.log("editEvent", e.target.name, e.target.value, index);

    console.log("editEventEditTiming", editTiming);
  };

  const handleDeleteClose = () => {
    setDeleteTuitionTimeDialog(false);
  };

  const handleEditClose = () => {
    setEditTuitionTimeDialog(false);
    setEditTiming(editingSubjectTuitionTime?.timing);
  };

  const deleteTuitionTime = (time) => {
    setDeleteTuitionTimeDialog(true);
    setDeletingSubjectTuitionTime(time);
    console.log(time);
  };

  const deleteTuitionTimeConfirm = () => {
    let payload = {
      user_id: localStorage.getItem("userID"),
      action: "delete",
      subject_id: deletingSubjectTuitionTime.subject_id,
    };
    API.post("TeacherApi/tutionTime", payload).then((res) => {
      if (res.data.result === 1) {
        setDeleteTuitionTimeDialog(false);
        getTuitionTimeList();
      }
    });
  };

  const getTimingDetail = (sub) => {
    console.log(sub);
    setSelectedSubject(sub);
    setOpenSlotTime(true);
  };
  const closeSlotTime = () => {
    setOpenSlotTime(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddTiming({
      ...addTiming,
      [name]: value,
    });

    // setAddTiming({
    //     ...addTiming,
    //     timing: slotListLength
    // })
    console.log("sdfsf1234", e.target);
  };

  useEffect(() => {
    if (slotTime.from_time && slotTime.to_time) {
      slotListLength[addIndex] = slotTime;
    }
  }, [slotTime, slotListLength, addIndex]);

  const getStartTiming = (e, i) => {
    setAddIndex(i);
    const { name, value } = e.target;
    setSlotTime({
      ...slotTime,
      [name]: value,
    });
  };

  const addNewTuitionTime = () => {
    setAddTiming({
      ...addTiming,
      timing: slotListLength,
    });
    console.log("addTimingPayload0000000", addTiming);
    setTimeout(() => {
      setAddTiming({
        ...addTiming,
        timing: slotListLength,
      });
      console.log("addTimingPayload0000012", addTiming);
      if (addTiming.timing.length > 0) {
        API.post("TeacherApi/tutionTime", addTiming).then((res) => {
          if (res.data.result === 1) {
            console.log("tutionTimeAddResponse", res);
            getTuitionTimeList();
            setOpenAddTuitionTime(false);
            let user = localStorage.getItem("userID");
            setAddTiming({
              user_id: "",
              action: "add",
              subject_id: "",
              capacity: "",
              timing: [],
              fees: "",
            });
            // setAddTiming({
            //     ...addTiming,
            //     user_id:user
            // })
          }
        });
      }
    }, 1000);
  };

  const updateTuitionTime = () => {
    let user = localStorage.getItem("userID");
    setEditingSubjectTuitionTime({
      ...editingSubjectTuitionTime,
      timing: editTiming,
      action: "update",
      user_id: user,
    });
    console.log("editingSubjectTuitionTime", editingSubjectTuitionTime);
    API.post("TeacherApi/tutionTime", editingSubjectTuitionTime).then((res) => {
      if (res.data.result === 1) {
        getTuitionTimeList();
        setEditTuitionTimeDialog(false);
        setEditingSubjectTuitionTime("");
        setEditTiming([]);
      }
    });
  };
  console.log("slotTime123", slotTime);
  console.log("addTimingPayload", addTiming);
  console.log("addTimingPayloadslotListLength", slotListLength);

  return (
    <div>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
        <h1> TUITION TIME</h1>
        <p>
          <Link href="/" style={{ color: "white", textDecoration: "none" }}>
            Home
          </Link>{" "}
          | Student | Tuition Time
        </p>
      </div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box>
              <Typography variant="h5" style={{ paddingLeft: "8px" }}>
                TUITION TIME
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              style={{
                width: "100%",
                justifyContent: width < sm ? "flex-start" : "flex-end",
              }}
              display="flex"
            >
              <Box>
                <Button
                  style={{ border: "1px solid black", color: "black" }}
                  onClick={addTuitionTime}
                >
                  Add Tuition Time
                  <AddCircleOutlineIcon className={classes.addTuitionButton} />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container marginY={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div
              style={{
                backgroundColor: "rgb(24, 169, 218)",
                height: "2px",
                width: "15%",
              }}
            ></div>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ marginBottom: "12px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "lightgray" }}>
                Subject
              </TableCell>
              <TableCell
                style={{ backgroundColor: "lightgray", textAlign: "center" }}
              >
                Fees
              </TableCell>
              <TableCell
                style={{ backgroundColor: "lightgray", textAlign: "center" }}
              >
                Time
              </TableCell>
              <TableCell
                style={{ backgroundColor: "lightgray", textAlign: "center" }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tuitionTimeList.map((tuitionTime) => (
              <TableRow hover>
                <TableCell>{tuitionTime?.subject}</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {tuitionTime?.fees}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Link
                    onClick={() => getTimingDetail(tuitionTime)}
                    style={{ textDecoration: "none" }}
                  >
                    {tuitionTime?.timing[0]?.from_time} to{" "}
                    {tuitionTime?.timing[0]?.to_time}
                  </Link>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <button
                    onClick={() => editTuitionTime(tuitionTime)}
                    className={classes.tuitionTimeStatusButton}
                  >
                    <EditIcon style={{ color: "white" }} />
                  </button>
                  {/* onClick={() => deleteTuitionTime(tuitionTime)} */}
                  {/* <button
                                    
                                    className={classes.tuitionTimeStatusButton}>
                                    <DeleteIcon style={{ color: "white" }} />
                                </button> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Box marginY={2}>
                <Box>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "deepskyblue" }}
                        className={classes.tuitionSubmit}
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Box> */}
        <Dialog
          open={openAddTuitionTime}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h6">Add Tuition Time</Typography>
          </DialogTitle>
          <DialogContent style={{ paddingTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="filled" fullWidth>
                  {/* <InputLabel id="demo-simple-select">Select Subject</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="subject_id"
                    input={<OutlinedInput />}
                    value={addTiming.subject_id}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value="">Select Subject</MenuItem> */}
                    {subjectList.map((subject) => (
                      <MenuItem value={subject.subject_id}>
                        {subject.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  name="capacity"
                  variant="outlined"
                  type="number"
                  label="Total Seats"
                  value={addTiming.capacity}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Box
                justifyContent="flex-end"
                display="flex"
                marginTop={2}
                style={{ width: "100%" }}
              >
                <Box>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "deepskyblue" }}
                    onClick={increaseSlot}
                  >
                    Add Slot
                  </Button>
                </Box>
              </Box>

              {slotListLength.map((slot, index) => (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <p style={{ opacity: "0.6" }}>Start Time *</p>
                    <TextField
                      name="from_time"
                      variant="outlined"
                      type="time"
                      onChange={(e) => getStartTiming(e, index)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={5} lg={5}>
                    <p style={{ opacity: "0.6" }}>End Time *</p>
                    <TextField
                      name="to_time"
                      variant="outlined"
                      type="time"
                      onChange={(e) => getStartTiming(e, index)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    {index !== 0 && (
                      <Box
                        display="flex"
                        style={{ width: "100%", height: "100%" }}
                        justifyContent="center"
                      >
                        <Box alignSelf="center">
                          <button
                            onClick={() => decreaseSlot(index)}
                            className={classes.tuitionTimeStatusButton}
                          >
                            <DeleteIcon style={{ color: "white" }} />
                          </button>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </>
              ))}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Fee *</p>
                <TextField
                  name="fees"
                  variant="outlined"
                  type="number"
                  value={addTiming.fees}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button autoFocus onClick={addNewTuitionTime}>
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSlotTime}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            <Typography>Slot Details of {selectedSubject?.subject}</Typography>
          </DialogTitle>
          <DialogContent style={{ paddingTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ backgroundColor: "lightgray" }}>
                        Slot
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightgray",
                          textAlign: "center",
                        }}
                      >
                        Timing
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedSubject?.timing?.map((tuitionTime, index) => (
                      <TableRow hover>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {tuitionTime.from_time} to {tuitionTime.to_time}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={closeSlotTime}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editTuitionTimeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Typography>
              Edit Tuition Time of {editingSubjectTuitionTime.subject}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ paddingTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="filled" fullWidth>
                  {/* <InputLabel id="demo-simple-select">Select Subject</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="subject_id"
                    input={<OutlinedInput />}
                    value={editingSubjectTuitionTime.subject_id}
                    onChange={handleChange}
                  >
                    {subjectList.map((subject) => (
                      <MenuItem value={subject.subject_id}>
                        {subject.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  name="capacity"
                  variant="outlined"
                  type="number"
                  label="Total Seats"
                  value={editingSubjectTuitionTime.totalseat}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Box
                justifyContent="flex-end"
                display="flex"
                marginTop={2}
                style={{ width: "100%" }}
              >
                <Box>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "deepskyblue" }}
                    onClick={increaseEditSlot}
                  >
                    Add Slot
                  </Button>
                </Box>
              </Box>

              {editTiming?.map((slot, index) => (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <p style={{ opacity: "0.6" }}>Start Time *</p>
                    <TextField
                      name="from_time"
                      variant="outlined"
                      type="time"
                      defaultValue={slot?.from_time ? slot?.from_time : ""}
                      onChange={(e) => getEditStartTiming(e, index)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={5} lg={5}>
                    <p style={{ opacity: "0.6" }}>End Time *</p>
                    <TextField
                      name="to_time"
                      variant="outlined"
                      type="time"
                      defaultValue={slot?.to_time ? slot?.to_time : ""}
                      onChange={(e) => getEditStartTiming(e, index)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    {index !== 0 && (
                      <Box
                        display="flex"
                        style={{ width: "100%", height: "100%" }}
                        justifyContent="center"
                      >
                        <Box alignSelf="center">
                          <button
                            onClick={() => decreaseEditSlot(index)}
                            className={classes.tuitionTimeStatusButton}
                          >
                            <DeleteIcon style={{ color: "white" }} />
                          </button>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </>
              ))}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Fee *</p>
                <TextField
                  name="fees"
                  variant="outlined"
                  type="number"
                  value={editingSubjectTuitionTime.fees}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Cancel</Button>
            <Button autoFocus onClick={updateTuitionTime}>
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteTuitionTimeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure? You want to delete this Tuition Time!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button autoFocus onClick={deleteTuitionTimeConfirm}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {/* <div className="container" style={{ paddingTop: "30px" }}>
            <div className="row">
                <div className="col-md-8 offset-1">
                    <h5>TUITION TIME</h5>
                    <div style={{
                        height: "2px",
                        width: "80px", backgroundColor: "rgb(24, 169, 218)"
                    }}></div>
                </div>
                <div className="col-md-3">
                    <button style={{ border: "2px solid lightgrey", height: "35px", }}>Add Tuition Time
                        <GrAddCircle color="lightgreen" size="20px" />
                    </button>
                </div>
            </div>
            <div className="container" style={{ position: "relative", top: "25px" }}>
                <div className="row" style={{ backgroundColor: "#E0E0E0", paddingTop: "10px" }}>
                    <div className="col-md-6 offset-1"><h6>Subject</h6></div>
                    <div className="col-md-2"><h6>Time</h6></div>
                    <div className="col-md-3"><h6>Status</h6></div>
                </div>

                <div className="row" style={{ paddingTop: "10px" }}>
                    <div className="col-md-6 offset-1"><p style={{ opacity: "0.8" }}>English</p></div>
                    <div className="col-md-2"><p style={{ opacity: "0.8" }}>8 Am to 10 Am</p></div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-2"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><GrEdit color="white" /></button></div>
                            <div className="col-md-1"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><AiOutlineDelete color="white" /></button></div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ paddingTop: "10px" }}>
                    <div className="col-md-6 offset-1"><p style={{ opacity: "0.8" }}>Mathematics</p></div>
                    <div className="col-md-2"><p style={{ opacity: "0.8" }}>10 Am to 12 Pm</p><p style={{ opacity: "0.8" }}>12 Pm to 1 Pm</p></div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-2"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><GrEdit color="white" /></button></div>
                            <div className="col-md-1"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><AiOutlineDelete color="white" /></button></div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ paddingTop: "10px" }}>
                    <div className="col-md-6 offset-1"><p style={{ opacity: "0.8" }}>Physics</p></div>
                    <div className="col-md-2"><p style={{ opacity: "0.8" }}>2 Pm to 4 Pm</p></div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-2"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><GrEdit color="white" /></button></div>
                            <div className="col-md-1"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><AiOutlineDelete color="white" /></button></div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ paddingTop: "10px" }}>
                    <div className="col-md-6 offset-1"><p style={{ opacity: "0.8" }}>Chemistry</p></div>
                    <div className="col-md-2"><p style={{ opacity: "0.8" }}>4 Pm to 6 Pm</p></div>        <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-2"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><GrEdit color="white" /></button></div>
                            <div className="col-md-1"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><AiOutlineDelete color="white" /></button></div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ paddingTop: "10px" }}>
                    <div className="col-md-6 offset-1"><p style={{ opacity: "0.8" }}>Biology</p></div>
                    <div className="col-md-2"><p style={{ opacity: "0.8" }}>6 Pm to 8 Pm</p></div>
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-2"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><GrEdit color="white" /></button></div>
                            <div className="col-md-1"><button className="rounded-circle" style={{
                                border: "none", height: "35px", width: "35px",
                                backgroundColor: "lightgreen"
                            }}><AiOutlineDelete color="white" /></button></div>
                        </div>
                    </div>
                </div>

                <div className="col offset-1">
                    <button style={{ backgroundColor: "rgb(24, 169, 218)", color: "white", height: "35px", border: "none" }}>
                        SUBMIT
                    </button></div>

            </div>

        </div> */}
    </div>
  );
};
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  addTuitionButton: {
    backgroundColor: "lightgreen",
    color: "white",
    borderRadius: "30px",
    marginLeft: "2px",
  },
  tuitionSubmit: {
    // backgroundColor: "rgb(24, 169, 218)",
    color: "white",
    border: "none",
    height: "40px",
    width: "140px",
  },
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(TuitionTime);
