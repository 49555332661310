/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import API from "../components/api";
import { GiBarbedSpear } from "react-icons/gi";
import PulseLoader from "react-spinners/PulseLoader";
import { CgDanger } from "react-icons/cg";
// import { Router, Link } from "@reach/router"
// import { Grid, Link, Typography } from "@mui/material";
// import YearPicker from "react-year-picker";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  MenuItem,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  CardContent,
  Card,
  Rating,
  TextareaAutosize,
  Alert,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ClipLoader from "react-spinners/ClipLoader";

class TimeBlocking extends Component {
  state = {
    loading: false,
    searchdate: "",
    success: false,
    successmessage: "",
    error: false,
    TimeBlocks: [],
    initial: true,
    fstdsc: "",
    snddsc: "",
    trddsc: "",
    frthdsc: "",

    ffthdsc: "",
    sxtdsc: "",
    svndsc: "",
    eigdsc: "",
    nntdsc: "",
    tntdsc: "",
    elvdsc: "",
    tlvdsc: "",
    thrtdsc: "",
    frtndsc: "",
    fftndsc: "",
    sxtndsc: "",
    svtndsc: "",
    eghtndsc: "",
    curr_month: "",
    curr_day: "",
    curr_year: "",
    firstbox: false,
    secondbox: false,
    thirdbox: false,
    fourthbox: false,
    fifthbox: false,
    filledbox: 0,
    searchresult: false,
    sixthbox: false,
    seventhbox: false,
    eightbox: false,
    ninthbox: false,
    tenthbox: false,
    eleventhbox: false,
    twelvthbox: false,
    thirteenbox: false,
    fourteenbox: false,
    fifteenbox: false,
    sixteenbox: false,
    seventeenbox: false,
    eighteenbox: false,
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    description6: "",
    description7: "",
    description8: "",
    description9: "",
    description10: "",
    description11: "",
    description12: "",
    description13: "",
    description14: "",
    description15: "",
    description16: "",
    description17: "",
    description18: "",
    count: 0,
    fststar: "",
    sndstar: "",
    trdstar: "",
    frtstar: "",
    fftstar: "",
    sxtstar: "",
    svnstar: "",
    egtstar: "",
    nntstar: "",
    tntstar: "",
    elvstar: "",
    tlvstar: "",
    thrtstar: "",
    frtnstar: "",
    fftnstar: "",
    sxtnstar: "",
    svtnstar: "",
    egtnstar: "",

    fststarval: 0,
    newstar: "",
    newstar2: "",
    newstar3: "",
    newstar4: "",
    newstar5: "",
    newstar6: "",
    newstar7: "",
    newstar8: "",
    newstar9: "",
    newstar10: "",
    newstar11: "",
    newstar12: "",
    newstar13: "",
    newstar14: "",
    newstar15: "",
    newstar16: "",
    newstar17: "",
    newstar18: "",
    srchstar: " ",
    srchstar1: "",
    srchstar2: "",
    srchstar3: "",
    srchstar4: "",
    srchstar5: "",
    srchstar6: "",
    srchstar7: "",
    srchstar8: "",
    srchstar9: "",
    srchstar10: "",
    srchstar11: "",
    srchstar12: "",
    srchstar13: "",
    srchstar14: "",
    srchstar15: "",
    srchstar16: "",
    srchstar17: "",

    Searcheddataone: "",
    Searcheddatatwo: "",
    Searcheddatathree: "",
    Searcheddatafour: "",
    Searcheddatafive: "",
    Searcheddatasix: "",
    Searcheddataseven: "",
    Searcheddataeight: "",
    Searcheddatanine: "",
    Searcheddataten: "",
    Searcheddataeleven: "",
    Searcheddatatwelve: "",
    Searcheddatathirteen: "",
    Searcheddatafourteen: "",
    Searcheddatafifteen: "",
    Searcheddatasixteen: "",
    Searcheddataseventeen: "",
    Searcheddataeighteen: "",
    thirdboxsrch: false,
    secondboxsrch: false,
    firstboxsrch: false,
    fourthboxsrch: false,
    fifthboxsrch: false,

    sixthboxsrch: false,
    seventhboxsrch: false,
    eightboxsrch: false,
    ninthboxsrch: false,
    tenthboxsrch: false,
    eleventhboxsrch: false,
    twelvthboxsrch: false,
    thirteenboxsrch: false,
    fourteenboxsrch: false,
    fifteenboxsrch: false,
    sixteenboxsrch: false,
    seventeenboxsrch: false,
    eighteenboxsrch: false,
    todaydate: "",
    timeBlock: [],
  };
  componentDidMount() {
    this.getTimeblock();
    let month = new Date().toLocaleString("en-US", { month: "2-digit" });
    let year = new Date().getFullYear();
    let day = new Date().toLocaleString("en-US", { day: "2-digit" });
    console.log(month);
    this.setState({ curr_month: month, curr_day: day, curr_year: year });
    var curr = new Date();
    curr.setDate(curr.getDate());
    // var date = curr.toISOString().substr(0, 10);
    let da = curr.toLocaleDateString();
    let dat = da.split("/");
    let date = dat[2] + "-" + dat[1] + "-" + dat[0];
    console.log("toLocaleDateString12345", date);
    this.setState({ todaydate: date });
  }
  getTimeblock() {
    var that = this;
    let user = localStorage.getItem("userID");
    API.post("StudentApi/student_home", { user_id: user })
      .then(function (response) {
        console.log("star", response.data.timeBlocking[0].star);

        that.setState({
          fstdsc: response.data.timeBlocking[0].description,
          fststar: response.data.timeBlocking[0].star,
          snddsc: response.data.timeBlocking[1].description,
          sndstar: response.data.timeBlocking[1].star,
          trddsc: response.data.timeBlocking[2].description,
          trdstar: response.data.timeBlocking[2].star,
          frthdsc: response.data.timeBlocking[3].description,
          frtstar: response.data.timeBlocking[3].star,
          ffthdsc: response.data.timeBlocking[4].description,
          fftstar: response.data.timeBlocking[4].star,
          sxtdsc: response.data.timeBlocking[5].description,
          sxtstar: response.data.timeBlocking[5].star,
          svndsc: response.data.timeBlocking[6].description,
          svnstar: response.data.timeBlocking[6].star,
          eigdsc: response.data.timeBlocking[7].description,
          egtstar: response.data.timeBlocking[7].star,
          nntdsc: response.data.timeBlocking[8].description,
          nntstar: response.data.timeBlocking[8].star,
          tntdsc: response.data.timeBlocking[9].description,
          tntstar: response.data.timeBlocking[9].star,
          elvdsc: response.data.timeBlocking[10].description,
          elvstar: response.data.timeBlocking[10].star,
          tlvdsc: response.data.timeBlocking[11].description,
          tlvstar: response.data.timeBlocking[11].star,
          thrtdsc: response.data.timeBlocking[12].description,
          thrtstar: response.data.timeBlocking[12].star,
          frtndsc: response.data.timeBlocking[13].description,
          frtnstar: response.data.timeBlocking[13].star,
          fftndsc: response.data.timeBlocking[14].description,
          fftnstar: response.data.timeBlocking[14].star,
          sxtndsc: response.data.timeBlocking[15].description,
          sxtnstar: response.data.timeBlocking[15].star,
          svtndsc: response.data.timeBlocking[16].description,
          svtnstar: response.data.timeBlocking[16].star,
          eghtndsc: response.data.timeBlocking[17].description,
          egtnstar: response.data.timeBlocking[17].star,
        });

        console.log("newstar", that.state.newstar);

        let val = parseInt(response.data.timeBlocking[2].star);
        that.setState({ fststarval: val });
        {
          that.state.fstdsc === ""
            ? that.setState({ firstbox: false })
            : that.setState({
              firstbox: true,
              count: that.state.count + 1,
              description1: that.state.fstdsc,
            });
        }
        {
          that.state.snddsc === ""
            ? that.setState({ secondbox: false })
            : that.setState({
              secondbox: true,
              count: that.state.count + 1,
              description2: that.state.snddsc,
            });
        }
        {
          that.state.trddsc === ""
            ? that.setState({ thirdbox: false })
            : that.setState({
              thirdbox: true,
              count: that.state.count + 1,
              description3: that.state.trddsc,
            });
        }
        {
          that.state.frthdsc === ""
            ? that.setState({ fourthbox: false })
            : that.setState({
              fourthbox: true,
              count: that.state.count + 1,
              description4: that.state.frthdsc,
            });
        }
        {
          that.state.ffthdsc === ""
            ? that.setState({ fifthbox: false })
            : that.setState({
              fifthbox: true,
              count: that.state.count + 1,
              description5: that.state.ffthdsc,
            });
        }
        {
          that.state.sxtdsc === ""
            ? that.setState({ sixthbox: false })
            : that.setState({
              sixthbox: true,
              count: that.state.count + 1,
              description6: that.state.sxtdsc,
            });
        }
        {
          that.state.svndsc === ""
            ? that.setState({ seventhbox: false })
            : that.setState({
              seventhbox: true,
              count: that.state.count + 1,
              description7: that.state.svndsc,
            });
        }
        {
          that.state.eigdsc === ""
            ? that.setState({ eightbox: false })
            : that.setState({
              eightbox: true,
              count: that.state.count + 1,
              description8: that.state.eigdsc,
            });
        }
        {
          that.state.nntdsc === ""
            ? that.setState({ ninthbox: false })
            : that.setState({
              ninthbox: true,
              count: that.state.count + 1,
              description9: that.state.nntdsc,
            });
        }
        {
          that.state.tntdsc === ""
            ? that.setState({ tenthbox: false })
            : that.setState({
              tenthbox: true,
              count: that.state.count + 1,
              description10: that.state.tntdsc,
            });
        }
        {
          that.state.elvdsc === ""
            ? that.setState({ eleventhbox: false })
            : that.setState({
              eleventhbox: true,
              count: that.state.count + 1,
              description11: that.state.elvdsc,
            });
        }
        {
          that.state.tlvdsc === ""
            ? that.setState({ twelvthbox: false })
            : that.setState({
              twelvthbox: true,
              count: that.state.count + 1,
              description12: that.state.tlvdsc,
            });
        }
        {
          that.state.thrtdsc === ""
            ? that.setState({ thirteenbox: false })
            : that.setState({
              thirteenbox: true,
              count: that.state.count + 1,
              description13: that.state.thrtdsc,
            });
        }
        {
          that.state.frtndsc === ""
            ? that.setState({ fourteenbox: false })
            : that.setState({
              fourteenbox: true,
              count: that.state.count + 1,
              description14: that.state.frtndsc,
            });
        }
        {
          that.state.fftndsc === ""
            ? that.setState({ fifteenbox: false })
            : that.setState({
              fifteenbox: true,
              count: that.state.count + 1,
              description15: that.state.fftndsc,
            });
        }
        {
          that.state.sxtndsc === ""
            ? that.setState({ sixteenbox: false })
            : that.setState({
              sixteenbox: true,
              count: that.state.count + 1,
              description16: that.state.sxtndsc,
            });
        }
        {
          that.state.svtndsc === ""
            ? that.setState({ seventeenbox: false })
            : that.setState({
              seventeenbox: true,
              count: that.state.count + 1,
              description17: that.state.svtndsc,
            });
        }
        {
          that.state.eghtndsc === ""
            ? that.setState({ eighteenbox: false })
            : that.setState({
              eighteenbox: true,
              count: that.state.count + 1,
              description18: that.state.eghtndsc,
            });
        }
        // that.state.filledbox = 0;
        // {
        //   that.state.fstdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.snddsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.trddsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.frthdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.ffthdsc || that.state.description5
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.sxtdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.svndsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.eigdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.nntdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.tntdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.elvdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.tlvdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.thrtdsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.frtndsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.fftndsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.sxtndsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.svtndsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }
        // {
        //   that.state.eghtndsc
        //     ? that.setState({ filledbox: that.state.filledbox + 1 })
        //     : "";
        //   console.log("count", that.state.filledbox);
        // }

        window.scrollTo(0, 0);
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () { });
  }
  // fstprogress() {
  //   if (this.state.description1.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // sndprogress() {
  //   if (this.state.description2.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }

  // trdprogress() {
  //   if (this.state.description3.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // frtprogress() {
  //   if (this.state.description4.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // fftprogress() {
  //   if (this.state.description5.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // sxtprogress() {
  //   if (this.state.description6.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // svnprogress() {
  //   if (this.state.description7.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // egtprogress() {
  //   if (this.state.description8.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // nntprogress() {
  //   if (this.state.description9.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // tntprogress() {
  //   if (this.state.description10.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // elvprogress() {
  //   if (this.state.description11.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // tlvprogress() {
  //   if (this.state.description12.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // thrtprogress() {
  //   if (this.state.description13.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // frtnprogress() {
  //   if (this.state.description14.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // fftnprogress() {
  //   if (this.state.description15.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // sxtnprogress() {
  //   if (this.state.description16.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // svtnprogress() {
  //   if (this.state.description17.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  // egtnprogress() {
  //   if (this.state.description18.length < 1) {
  //     this.setState({ count: this.state.count + 1 });
  //   }
  // }
  handlefirstChange(evt) {
    this.setState({ description1: evt.target.value });
    console.log("evt1", this.state.description);
  }
  handlesecondChange(evt) {
    this.setState({ description2: evt.target.value });
    console.log("evt2", this.state.description);
  }
  handlethirdChange(evt) {
    this.setState({ description3: evt.target.value });
    console.log("evt3", this.state.description);
  }
  handlefourthChange(evt) {
    this.setState({ description4: evt.target.value });
    console.log("evt4", this.state.description);
  }
  handlefifthChange(evt) {
    this.setState({ description5: evt.target.value });
    console.log("evt5", this.state.description);
  }
  handlesixthChange(evt) {
    this.setState({ description6: evt.target.value });
    console.log("evt6", this.state.description);
  }
  handleseventhChange(evt) {
    this.setState({ description7: evt.target.value });
    console.log("evt7", this.state.description);
  }
  handleeightChange(evt) {
    this.setState({ description8: evt.target.value });
    console.log("evt8", this.state.description);
  }
  handleninthChange(evt) {
    this.setState({ description9: evt.target.value });
    console.log("evt9", this.state.description);
  }
  handletenthChange(evt) {
    this.setState({ description10: evt.target.value });
    console.log("evt10", this.state.description);
  }
  handleeleventhChange(evt) {
    this.setState({ description11: evt.target.value });
    console.log("evt11", this.state.description);
  }
  handletwelvthChange(evt) {
    this.setState({ description12: evt.target.value });
  }
  handlethirteenChange(evt) {
    this.setState({ description13: evt.target.value });
  }
  handlefourteenChange(evt) {
    this.setState({ description14: evt.target.value });
  }
  handlefifteenChange(evt) {
    this.setState({ description15: evt.target.value });
  }
  handlesixteenChange(evt) {
    this.setState({ description16: evt.target.value });
  }
  handleseventeenChange(evt) {
    this.setState({ description17: evt.target.value });
  }
  handleeighteenChange(evt) {
    this.setState({ description18: evt.target.value });
  }
  savetimeblock() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1300);
    this.setState({
      count: 0,
      filledbox: 0
    });

    // eslint-disable-next-line no-unused-expressions
    (this.state.fstdsc || this.state.description1)
      ?
      this.state.filledbox=this.state.filledbox + 1
      // this.setState({ filledbox: this.state.filledbox + 1 })
      : "";
    console.log("count", this.state.filledbox);


    // eslint-disable-next-line no-unused-expressions
    (this.state.snddsc || this.state.description2)
      ?
      this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);


    (this.state.trddsc || this.state.description3)
      ?
      this.state.filledbox=this.state.filledbox + 1
      : "";
    this;
    console.log("count", this.state.filledbox);


    (this.state.frthdsc || this.state.description4)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.ffthdsc || this.state.description5)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.sxtdsc || this.state.description6)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.svndsc || this.state.description7)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.eigdsc || this.state.description8)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.nntdsc || this.state.description9)
      ? // ? this.setState({ filledbox: this.state.filledbox + 1 })
      this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.tntdsc || this.state.description10)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.elvdsc || this.state.description11)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.tlvdsc || this.state.description12)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    (this.state.thrtdsc || this.state.description13)
      ? this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    // eslint-disable-next-line no-unused-expressions
    (this.state.frtndsc || this.state.description14)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    // eslint-disable-next-line no-unused-expressions
    (this.state.fftndsc || this.state.description15)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    // eslint-disable-next-line no-unused-expressions
    (this.state.sxtndsc || this.state.description16)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    // eslint-disable-next-line no-unused-expressions
    (this.state.svtndsc || this.state.description17)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count", this.state.filledbox);

    // eslint-disable-next-line no-unused-expressions
    (this.state.eghtndsc || this.state.description18)
      ?  this.state.filledbox=this.state.filledbox + 1
      : "";
    console.log("count1234567", this.state.filledbox);

    const user = localStorage.getItem("userID");
    const value = this.state.filledbox;
    const params = {
      user_id: user,
      fillBox: value,
      timeBlocking: [
        {
          id: 1,
          hour_key: "6AM",
          description: this.state.description1,
          star: this.state.fststar,
        },
        {
          id: 2,
          hour_key: "7AM",
          description: this.state.description2,
          star: this.state.sndstar,
        },
        {
          id: 3,
          hour_key: "8AM",
          description: this.state.description3,
          star: this.state.trdstar,
        },
        {
          id: 4,
          hour_key: "9AM",
          description: this.state.description4,
          star: this.state.frtstar,
        },
        {
          id: 5,
          hour_key: "10AM",
          description: this.state.description5,
          star: this.state.fftstar,
        },
        {
          id: 6,
          hour_key: "11AM",
          description: this.state.description6,
          star: this.state.sxtstar,
        },
        {
          id: 7,
          hour_key: "12AM",
          description: this.state.description7,
          star: this.state.svnstar,
        },
        {
          id: 8,
          hour_key: "1PM",
          description: this.state.description8,
          star: this.state.egtstar,
        },
        {
          id: 9,
          hour_key: "2PM",
          description: this.state.description9,
          star: this.state.nntstar,
        },
        {
          id: 10,
          hour_key: "3PM",
          description: this.state.description10,
          star: this.state.tntstar,
        },
        {
          id: 11,
          hour_key: "4PM",
          description: this.state.description11,
          star: this.state.elvstar,
        },
        {
          id: 12,
          hour_key: "5PM",
          description: this.state.description12,
          star: this.state.tlvstar,
        },
        {
          id: 13,
          hour_key: "6PM",
          description: this.state.description13,
          star: this.state.thrtstar,
        },
        {
          id: 14,
          hour_key: "7PM",
          description: this.state.description14,
          star: this.state.frtnstar,
        },
        {
          id: 15,
          hour_key: "8PM",
          description: this.state.description15,
          star: this.state.fftnstar,
        },
        {
          id: 16,
          hour_key: "9PM",
          description: this.state.description16,
          star: this.state.sxtnstar,
        },
        {
          id: 17,
          hour_key: "10PM",
          description: this.state.description17,
          star: this.state.svtnstar,
        },
        {
          id: 18,
          hour_key: "11PM",
          description: this.state.description18,
          star: this.state.egtnstar,
        },
      ],
    };
    // that.setState({
    //   firstbox: true,
    // });
    var that = this;
    API.post("StudentApi/saveTimeBlocking", params)
      .then(function (response) {
        console.log(response);
        that.getTimeblock();
        that.setState({ successmessage: response.data.message });
        if (response.data.result === 1) {
          that.setState({ success: true });
          setTimeout(() => {
            that.setState({ success: false });
          }, 1500);
        } else {
          that.setState({ error: true });
          setTimeout(() => {
            that.setState({ error: false });
          }, 1500);
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () { });
  }
  handleDateChanged(e) {
    this.setState({
      loading: true,
      Searcheddataone: "",
      Searcheddatatwo: "",
      Searcheddatathree: "",
      Searcheddatafour: "",
      Searcheddatafive: "",
      Searcheddatasix: "",
      Searcheddataseven: "",
      Searcheddataeight: "",
      Searcheddatanine: "",
      Searcheddataten: "",
      Searcheddataeleven: "",
      Searcheddatatwelve: "",
      Searcheddatathirteen: "",
      Searcheddatafourteen: "",
      Searcheddatafifteen: "",
      Searcheddatasixteen: "",
      Searcheddataseventeen: "",
      Searcheddataeighteen: "",
      thirdboxsrch: false,
      secondboxsrch: false,
      firstboxsrch: false,
      fourthboxsrch: false,
      fifthboxsrch: false,

      sixthboxsrch: false,
      seventhboxsrch: false,
      eightboxsrch: false,
      ninthboxsrch: false,
      tenthboxsrch: false,
      eleventhboxsrch: false,
      twelvthboxsrch: false,
      thirteenboxsrch: false,
      fourteenboxsrch: false,
      fifteenboxsrch: false,
      sixteenboxsrch: false,
      seventeenboxsrch: false,
      eighteenboxsrch: false,
      srchstar: " ",
      srchstar1: "",
      srchstar2: "",
      srchstar3: "",
      srchstar4: "",
      srchstar5: "",
      srchstar6: "",
      srchstar7: "",
      srchstar8: "",
      srchstar9: "",
      srchstar10: "",
      srchstar11: "",
      srchstar12: "",
      srchstar13: "",
      srchstar14: "",
      srchstar15: "",
      srchstar16: "",
      srchstar17: "",
      // searchresult: true,
    });
    this.setState({
      searchdate: e.target.value,
      todaydate: e.target.value,
    });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
    const params = {
      user_id: localStorage.getItem("userID"),
      date: e.target.value,
    };
    var that = this;
    API.post("StudentApi/searchTimeBlocking", params)
      .then(function (response) {
        console.log(response.data.timeBlocking);
        if (response.data.timeBlocking.length < 1) {
          that.setState({ searchresult: true, thirdboxsrch: false });
        } else {
          that.setState({ timeBlock: response.data.timeBlocking });
          that.setState({
            Searcheddataone: response.data.timeBlocking[0].description,
            Searcheddatatwo: response.data.timeBlocking[1].description,
            Searcheddatathree: response.data.timeBlocking[2].description,
            Searcheddatafour: response.data.timeBlocking[3].description,
            Searcheddatafive: response.data.timeBlocking[4].description,
            Searcheddatasix: response.data.timeBlocking[5].description,
            Searcheddataseven: response.data.timeBlocking[6].description,
            Searcheddataeight: response.data.timeBlocking[7].description,
            Searcheddatanine: response.data.timeBlocking[8].description,
            Searcheddataten: response.data.timeBlocking[9].description,
            Searcheddataeleven: response.data.timeBlocking[10].description,
            Searcheddatatwelve: response.data.timeBlocking[11].description,
            Searcheddatathirteen: response.data.timeBlocking[12].description,
            Searcheddatafourteen: response.data.timeBlocking[13].description,
            Searcheddatafifteen: response.data.timeBlocking[14].description,
            Searcheddatasixteen: response.data.timeBlocking[15].description,
            Searcheddataseventeen: response.data.timeBlocking[16].description,
            Searcheddataeighteen: response.data.timeBlocking[17].description,

            srchstar: response.data.timeBlocking[0].star,

            srchstar1: response.data.timeBlocking[1].star,

            srchstar2: response.data.timeBlocking[2].star,

            srchstar3: response.data.timeBlocking[3].star,

            srchstar4: response.data.timeBlocking[4].star,

            srchstar5: response.data.timeBlocking[5].star,

            srchstar6: response.data.timeBlocking[6].star,

            srchstar7: response.data.timeBlocking[7].star,

            srchstar8: response.data.timeBlocking[8].star,

            srchstar9: response.data.timeBlocking[9].star,

            srchstar10: response.data.timeBlocking[10].star,

            srchstar11: response.data.timeBlocking[11].star,

            srchstar12: response.data.timeBlocking[12].star,

            srchstar13: response.data.timeBlocking[13].star,

            srchstar14: response.data.timeBlocking[14].star,

            srchstar15: response.data.timeBlocking[15].star,

            srchstar16: response.data.timeBlocking[16].star,

            srchstar17: response.data.timeBlocking[17].star,
          });
        }
        // if (response.data.timeBlocking.length < 1) {
        //   that.setState({ searchresult: false, thirdboxsrch: false });
        // }

        const date = e.target.value;
        const currdate =
          that.state.curr_year +
          "-" +
          that.state.curr_month +
          "-" +
          that.state.curr_day;
        console.log("compare", currdate + date);
        if (date === currdate) {
          // alert("worked");
          that.setState({ searchresult: false });
        } else {
          that.setState({ searchresult: true });
        }
        {
          that.state.Searcheddataone === ""
            ? that.setState({ firstboxsrch: false })
            : that.setState({ firstboxsrch: true });
        }
        {
          that.state.Searcheddatatwo === ""
            ? that.setState({ secondboxsrch: false })
            : that.setState({ secondboxsrch: true });
        }
        {
          that.state.Searcheddatathree === ""
            ? that.setState({ thirdboxsrch: false })
            : that.setState({ thirdboxsrch: true });
        }
        {
          that.state.Searcheddatafour === ""
            ? that.setState({ fourthboxsrch: false })
            : that.setState({ fourthboxsrch: true });
        }
        {
          that.state.Searcheddatafive === ""
            ? that.setState({ fifthboxsrch: false })
            : that.setState({ fifthboxsrch: true });
        }
        {
          that.state.Searcheddatasix === ""
            ? that.setState({ sixthboxsrch: false })
            : that.setState({ sixthboxsrch: true });
        }
        {
          that.state.Searcheddataseven === ""
            ? that.setState({ seventhboxsrch: false })
            : that.setState({ seventhboxsrch: true });
        }
        {
          that.state.Searcheddataeight === ""
            ? that.setState({ eightboxsrch: false })
            : that.setState({ eightboxsrch: true });
        }
        {
          that.state.Searcheddatanine === ""
            ? that.setState({ ninthboxsrch: false })
            : that.setState({ ninthboxsrch: true });
        }
        {
          that.state.Searcheddataten === ""
            ? that.setState({ tenthboxsrch: false })
            : that.setState({ tenthboxsrch: true });
        }
        {
          that.state.Searcheddataeleven === ""
            ? that.setState({ eleventhboxsrch: false })
            : that.setState({ eleventhboxsrch: true });
        }
        {
          that.state.Searcheddatatwelve === ""
            ? that.setState({ twelvthboxsrch: false })
            : that.setState({ twelvthboxsrch: true });
        }
        {
          that.state.Searcheddatathirteen === ""
            ? that.setState({ thirteenboxsrch: false })
            : that.setState({ thirteenboxsrch: true });
        }
        {
          that.state.Searcheddatafourteen === ""
            ? that.setState({ fourteenboxsrch: false })
            : that.setState({ fourteenboxsrch: true });
        }
        {
          that.state.Searcheddatafifteen === ""
            ? that.setState({ fifteenboxsrch: false })
            : that.setState({ fifteenboxsrch: true });
        }
        {
          that.state.Searcheddatasixteen === ""
            ? that.setState({ sixteenboxsrch: false })
            : that.setState({ sixteenboxsrch: true });
        }
        {
          that.state.Searcheddataseventeen === ""
            ? that.setState({ seventeenboxsrch: false })
            : that.setState({ seventeenboxsrch: true });
        }
        {
          that.state.Searcheddataeighteen === ""
            ? that.setState({ eighteenboxsrch: false })
            : that.setState({ eighteenboxsrch: true });
        }
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () { });
  }
  // componentDidUpdate() {
  //   this.Progressbarfunc();
  // }
  // Progressbarfunc = () => {
  //   {
  //     // this.state.description1 = !""
  //     //   ? this.setState({ count: this.state.count + 5 })
  //     //   : "";
  //     alert("cgfg");
  //   }
  // };
  ratingChanged(e) {
    console.log(e.target.value);
    this.state.fststar = e.target.value;
    this.setState({ fststar: e.target.value });

    console.log("n1", this.state.fststar);
  }
  ratingChangedtwo(e) {
    console.log(e.target.value);
    this.state.sndstar = e.target.value;
    this.setState({ sndstar: e.target.value });

    console.log("n2", this.state.sndstar);
  }
  ratingChanged3(e) {
    // console.log(newRating);
    // this.setState({ trdstar: newRating });
    this.state.trdstar = e.target.value;
    this.setState({ trdstar: e.target.value });

    console.log("n1", this.state.trdstar);
  }
  ratingChanged4(e) {
    // console.log(newRating);
    this.state.frtstar = e.target.value;
    this.setState({ frtstar: e.target.value });

    console.log("n1", this.state.frtstar);
  }
  ratingChanged5(e) {
    // console.log(newRating);
    this.state.fftstar = e.target.value;
    this.setState({ fftstar: e.target.value });
    console.log("n1", this.state.fftstar);
  }
  ratingChanged6(e) {
    // console.log(newRating);
    this.state.sxtstar = e.target.value;
    this.setState({ sxtstar: e.target.value });
    console.log("n1", this.state.sxtstar);
  }
  ratingChanged7(e) {
    // console.log(newRating);
    this.state.svnstar = e.target.value;
    this.setState({ svnstar: e.target.value });
    console.log("n1", this.state.svnstar);
  }
  ratingChanged8(e) {
    // console.log(newRating);
    this.state.egtstar = e.target.value;
    this.setState({ egtstar: e.target.value });
    console.log("n1", this.state.egtstar);
  }
  ratingChanged9(e) {
    // console.log(newRating);
    this.state.nntstar = e.target.value;
    this.setState({ nntstar: e.target.value });
    console.log("n1", this.state.nntstar);
  }
  ratingChanged10(e) {
    // console.log(newRating);
    this.state.tntstar = e.target.value;

    this.setState({ tntstar: e.target.value });
    console.log("n1", this.state.tntstar);
  }
  ratingChanged11(e) {
    // console.log(newRating);
    this.state.elvstar = e.target.value;

    this.setState({ elvstar: e.target.value });
    console.log("n1", this.state.elvstar);
  }
  ratingChanged12(e) {
    // console.log(newRating);
    this.state.tlvstar = e.target.value;

    this.setState({ tlvstar: e.target.value });
    console.log("n1", this.state.tlvstar);
  }
  ratingChanged13(e) {
    // console.log(newRating);
    this.state.thrtstar = e.target.value;
    this.setState({ thrtstar: e.target.value });

    // this.setState({ thrtstar: newRating });
    console.log("n1", this.state.thrtstar);
  }
  ratingChanged14(e) {
    // console.log(newRating);
    this.state.frtnstar = e.target.value;
    this.setState({ frtnstar: e.target.value });
    // this.setState({ frtnstar: newRating });
    console.log("n1", this.state.frtnstar);
  }
  ratingChanged15(e) {
    // console.log(newRating);
    this.state.fftnstar = e.target.value;
    this.setState({ fftnstar: e.target.value });
    // this.setState({ fftnstar: newRating });
    console.log("n1", this.state.fftnstar);
  }
  ratingChanged16(e) {
    // console.log(newRating);
    this.state.sxtnstar = e.target.value;
    this.setState({ sxtnstar: e.target.value });
    // this.setState({ sxtnstar: newRating });
    console.log("n1", this.state.sxtnstar);
  }
  ratingChanged17(e) {
    // console.log(newRating);
    this.state.svtnstar = e.target.value;
    this.setState({ svtnstar: e.target.value });
    // this.setState({ svtnstar: newRating });
    console.log("n1", this.state.svtnstar);
  }
  ratingChanged18(e) {
    // console.log(newRating);
    this.state.egtnstar = e.target.value;
    this.setState({ egtnstar: e.target.value });
    // this.setState({ egtnstar: newRating });
    console.log("n1", this.state.egtnstar);
  }
  render() {
    const val = (this.state.count + 1) * (100 / 18) - 5.555555555555555;
    var now = Math.trunc(val);

    // const ratingChanged9 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ nntstar: newRating });
    //   console.log("n1", this.state.nntstar);
    // };
    // const ratingChanged10 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ tntstar: newRating });
    //   console.log("n1", this.state.tntstar);
    // };
    // const ratingChanged11 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ elvstar: newRating });
    //   console.log("n1", this.state.elvstar);
    // };
    // const ratingChanged12 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ tlvstar: newRating });
    //   console.log("n1", this.state.tlvstar);
    // };
    // const ratingChanged13 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ thrtstar: newRating });
    //   console.log("n1", this.state.thrtstar);
    // };
    // const ratingChanged14 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ frtnstar: newRating });
    //   console.log("n1", this.state.frtnstar);
    // };
    // const ratingChanged15 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ fftnstar: newRating });
    //   console.log("n1", this.state.fftnstar);
    // };
    // const ratingChanged16 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ sxtnstar: newRating });
    //   console.log("n1", this.state.sxtnstar);
    // };
    // const ratingChanged17 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ svtnstar: newRating });
    //   console.log("n1", this.state.svtnstar);
    // };
    // const ratingChanged18 = (newRating) => {
    //   console.log(newRating);
    //   this.setState({ egtnstar: newRating });
    //   console.log("n1", this.state.egtnstar);
    // };
    const star = 0;

    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> TIME BLOCKING</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              {" "}
              Student{" "}
            </Link>
            | Time Blocking
          </p>
        </div>
        <Container style={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Typography>TIME BLOCKING SYSTEM</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <TextField
                    name="timeBlockDate"
                    type="date"
                    variant="outlined"
                    onChange={(e) => {
                      this.handleDateChanged(e);
                    }}
                    value={this.state.todaydate}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "0px" }}
            >
              <div
                style={{
                  backgroundColor: "rgb(24, 169, 218)",
                  height: "2px",
                  width: "18%",
                  marginBottom: "12px",
                }}
              ></div>
            </Grid>
          </Grid>
        </Container>
        {this.state.searchresult && (
          <>
            {this.state.timeBlock.length > 0 && (
              <Container
                style={{
                  marginTop: "12px",
                  marginBottom: "20px",
                  minHeight: "500px",
                }}
              >
                <Grid container spacing={2}>
                  {this.state.timeBlock.map((time) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card
                          style={{
                            backgroundColor: time?.description
                              ? "lightgreen"
                              : "",
                          }}
                        >
                          <CardContent>
                            <Box display="flex" flexDirection="column">
                              <Box>
                                <Typography variant="h6">
                                  {this.state.searchdate}
                                  {" | "}
                                  {time?.hour_key}
                                </Typography>
                              </Box>
                              <Box minHeight="100px">
                                <Typography>
                                  {time?.description
                                    ? time?.description
                                    : "No Scheduled"}
                                </Typography>
                              </Box>
                              <Box>
                                <Rating
                                  name="half-rating"
                                  defaultValue={time?.star}
                                  precision={1}
                                  readOnly
                                />
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
            )}
            {this.state.timeBlock.length === 0 && (
              <Container
                style={{
                  marginTop: "12px",
                  marginBottom: "20px",
                  minHeight: "500px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box display="flex" justifyContent="center">
                      <Box>
                        <Typography>Data Not Found</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            )}
          </>
        )}
        {!this.state.searchresult && (
          <>
            <Container style={{ marginTop: "12px", marginBottom: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "4px",
                    }}
                    paddingY={1}
                    paddingX={1}
                  >
                    <ProgressBar
                      variant="success"
                      style={{
                        height: "40px",
                      }}
                      bar
                      key={1}
                      label={`${now}%`}
                      max={100}
                      now={now}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container style={{ marginBottom: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.fstdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 6 AM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          defaultValue={this.state.fstdsc}
                          onFocus={() => this.setState({ firstbox: false })}
                          onBlur={() => this.setState({ firstbox: true })}
                          onChange={(evt) => {
                            this.handlefirstChange(evt);
                          }}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.firstbox && this.state.fstdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged(e);
                          }}
                          name="half-rating"
                          value={this.state.fststar}
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.snddsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 7 AM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          onFocus={() => this.setState({ secondbox: false })}
                          onBlur={() => this.setState({ secondbox: true })}
                          defaultValue={this.state.snddsc}
                          onChange={(evt) => {
                            this.handlesecondChange(evt);
                          }}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.secondbox && this.state.snddsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChangedtwo(e);
                          }}
                          name="half-rating"
                          value={this.state.sndstar}
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.trddsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 8 AM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.thirdbox && this.state.trddsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ thirdbox: false })}
                          onBlur={() => this.setState({ thirdbox: true })}
                          defaultValue={this.state.trddsc}
                          onChange={(evt) => {
                            this.handlethirdChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged3(e);
                          }}
                          value={this.state.trdstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.frthdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 9 AM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.fourthbox && this.state.frthdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ fourthbox: false })}
                          onBlur={() => this.setState({ fourthbox: true })}
                          defaultValue={this.state.frthdsc}
                          onChange={(evt) => {
                            this.handlefourthChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged4(e);
                          }}
                          value={this.state.frtstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.ffthdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 10 AM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.fifthbox && this.state.ffthdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ fifthbox: false })}
                          onBlur={() => this.setState({ fifthbox: true })}
                          defaultValue={this.state.ffthdsc}
                          onChange={(evt) => {
                            this.handlefifthChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged5(e);
                          }}
                          value={this.state.fftstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.sxtdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 11 AM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.sixthbox && this.state.sxtdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ sixthbox: false })}
                          onBlur={() => this.setState({ sixthbox: true })}
                          defaultValue={this.state.sxtdsc}
                          onChange={(evt) => {
                            this.handlesixthChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged6(e);
                          }}
                          value={this.state.sxtstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.svndsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 12 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.seventhbox && this.state.svndsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ seventhbox: false })}
                          onBlur={() => this.setState({ seventhbox: true })}
                          defaultValue={this.state.svndsc}
                          onChange={(evt) => {
                            this.handleseventhChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged7(e);
                          }}
                          value={this.state.svnstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.eigdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 1 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.eightbox && this.state.eigdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ eightbox: false })}
                          onBlur={() => this.setState({ eightbox: true })}
                          defaultValue={this.state.eigdsc}
                          onChange={(evt) => {
                            this.handleeightChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged8(e);
                          }}
                          value={this.state.egtstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.nntdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 2 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.ninthbox && this.state.nntdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ ninthbox: false })}
                          onBlur={() => this.setState({ ninthbox: true })}
                          defaultValue={this.state.nntdsc}
                          onChange={(evt) => {
                            this.handleninthChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged9(e);
                          }}
                          value={this.state.nntstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.tntdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 3 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.tenthbox && this.state.tntdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ tenthbox: false })}
                          onBlur={() => this.setState({ tenthbox: true })}
                          defaultValue={this.state.tntdsc}
                          onChange={(evt) => {
                            this.handletenthChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged10(e);
                          }}
                          value={this.state.tntstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.elvdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 4 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.eleventhbox && this.state.elvdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ eleventhbox: false })}
                          onBlur={() => this.setState({ eleventhbox: true })}
                          defaultValue={this.state.elvdsc}
                          onChange={(evt) => {
                            this.handleeleventhChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged11(e);
                          }}
                          value={this.state.elvstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.tlvdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 5 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.twelvthbox && this.state.tlvdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ twelvthbox: false })}
                          onBlur={() => this.setState({ twelvthbox: true })}
                          defaultValue={this.state.tlvdsc}
                          onChange={(evt) => {
                            this.handletwelvthChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged12(e);
                          }}
                          value={this.state.tlvstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.thrtdsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 6 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.thirteenbox && this.state.thrtdsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ thirteenbox: false })}
                          onBlur={() => this.setState({ thirteenbox: true })}
                          defaultValue={this.state.thrtdsc}
                          onChange={(evt) => {
                            this.handlethirteenChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged13(e);
                          }}
                          value={this.state.thrtstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.frtndsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 7 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.fourteenbox && this.state.frtndsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ fourteenbox: false })}
                          onBlur={() => this.setState({ fourteenbox: true })}
                          defaultValue={this.state.frtndsc}
                          onChange={(evt) => {
                            this.handlefourteenChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged14(e);
                          }}
                          value={this.state.frtnstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.fftndsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 8 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.fifteenbox && this.state.fftndsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ fifteenbox: false })}
                          onBlur={() => this.setState({ fifteenbox: true })}
                          defaultValue={this.state.fftndsc}
                          onChange={(evt) => {
                            this.handlefifteenChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged15(e);
                          }}
                          value={this.state.fftnstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.sxtndsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 9 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.sixteenbox && this.state.sxtndsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ sixteenbox: false })}
                          onBlur={() => this.setState({ sixteenbox: true })}
                          defaultValue={this.state.sxtndsc}
                          onChange={(evt) => {
                            this.handlesixteenChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged16(e);
                          }}
                          value={this.state.sxtnstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.svtndsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 10 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.seventeenbox && this.state.svtndsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ seventeenbox: false })}
                          onBlur={() => this.setState({ seventeenbox: true })}
                          defaultValue={this.state.svtndsc}
                          onChange={(evt) => {
                            this.handleseventeenChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged17(e);
                          }}
                          value={this.state.svtnstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{
                      minHeight: "200px",
                      backgroundColor: this.state.eghtndsc ? "lightgreen" : "",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 11 PM
                        </Typography>
                      </Box>
                      <Box minHeight="130px" paddingY={1}>
                        <TextareaAutosize
                          placeholder="Enter Your text here "
                          minRows={5}
                          maxRows={5}
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor:
                              this.state.eighteenbox && this.state.eghtndsc
                                ? "lightgreen"
                                : "",
                          }}
                          maxLength={100}
                          onFocus={() => this.setState({ eighteenbox: false })}
                          onBlur={() => this.setState({ eighteenbox: true })}
                          defaultValue={this.state.eghtndsc}
                          onChange={(evt) => {
                            this.handleeighteenChange(evt);
                          }}
                        />
                      </Box>
                      <Box>
                        <Rating
                          onChange={(e) => {
                            this.ratingChanged18(e);
                          }}
                          value={this.state.egtnstar}
                          name="half-rating"
                          precision={1}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{ minHeight: "200px", backgroundColor: "lightgrey" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 12 AM{" "}
                        </Typography>
                      </Box>
                      <Box minHeight="176px">
                        <Typography>Sleeping Time</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{ minHeight: "200px", backgroundColor: "lightgrey" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 1 AM{" "}
                        </Typography>
                      </Box>
                      <Box minHeight="176px">
                        <Typography>Sleeping Time</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{ minHeight: "200px", backgroundColor: "lightgrey" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 2 AM{" "}
                        </Typography>
                      </Box>
                      <Box minHeight="176px">
                        <Typography>Sleeping Time</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{ minHeight: "200px", backgroundColor: "lightgrey" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 3 AM{" "}
                        </Typography>
                      </Box>
                      <Box minHeight="176px">
                        <Typography>Sleeping Time</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{ minHeight: "200px", backgroundColor: "lightgrey" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 4 AM{" "}
                        </Typography>
                      </Box>
                      <Box minHeight="176px">
                        <Typography>Sleeping Time</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card
                    style={{ minHeight: "200px", backgroundColor: "lightgrey" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={1}
                      paddingY={1}
                    >
                      <Box>
                        <Typography>
                          {this.state.curr_day} - {this.state.curr_month} -{" "}
                          {this.state.curr_year} | 5 AM{" "}
                        </Typography>
                      </Box>
                      <Box minHeight="176px">
                        <Typography>Sleeping Time</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      {this.state.error && (
                        <Alert severity="error">Some error ocured</Alert>
                      )}
                      {this.state.success && (
                        <Alert severity="success">
                          {this.state.successmessage}
                        </Alert>
                      )}
                    </Box>
                    <Box>
                      {this.state.loading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                          onClick={() => {
                            this.savetimeblock();
                          }}
                        >
                          Submit
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
        {/* <div className="container timecont" style={{ paddingTop: "30px" }}>
          <div className="row">
            <div className="col-md-7 offset-1">
              <h6>TIME BLOCKING SYSTEM</h6>
            </div>
            <div className="col-md-2">
              <Form>
                <Form.Control
                  onChange={(e) => {
                    this.handleDateChanged(e);
                  }}
                  type="date"
                  defaultValue={this.state.todaydate}
                />
              </Form>
            </div>
            <div className="col-md-2">
              <Button variant="none" disabled>
                {" "}
                {this.state.loading ? (
                  <span>
                    {" "}
                    <ClipLoader color="black" size={20} />
                  </span>
                ) : (
                  ""
                )}
              </Button>
            </div>
          </div>

          {this.state.searchresult ? (
            <div className="container">
              <div className="row " style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  <Card
                    className={
                      this.state.Searcheddataone === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 6 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.firstboxsrch ? (
                          <div>{this.state.Searcheddataone}</div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                        )}
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  <Card
                    className={
                      this.state.Searcheddatatwo === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 7 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.secondboxsrch ? (
                          <div>{this.state.Searcheddatatwo}</div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                        )}
                      </Card.Text>
                     
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar1}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  <Card
                    className={
                      this.state.Searcheddatathree === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 8 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.thirdboxsrch ? (
                          <div>{this.state.Searcheddatathree}</div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                        )}
                       
                      </Card.Text>
                      
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar2}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  <Card
                    className={
                      this.state.Searcheddatafour === ""
                        ? "card1color"
                        : "card2color"
                    }
                  
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 9 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.fourthboxsrch ? (
                          <div>{this.state.Searcheddatafour}</div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                        )}
                        
                      </Card.Text>
                     
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar3}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddatafive === ""
                        ? "card1color"
                        : "card2color"
                    }
                   
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 10 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.fifthboxsrch ? (
                          <div
                          
                          >
                            {this.state.Searcheddatafive}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                         
                        )}
                      </Card.Text>
                      
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar4}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddatasix === ""
                        ? "card1color"
                        : "card2color"
                    }
                   
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 11 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.sixthboxsrch ? (
                          <div
                          
                          >
                            {this.state.Searcheddatasix}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                         
                        )}
                       
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar5}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddataseven === ""
                        ? "card1color"
                        : "card2color"
                    }
                   
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 12 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.seventhboxsrch ? (
                          <div
                          >
                            {this.state.Searcheddataseven}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                          
                        )}
                        
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar6}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddataeight === ""
                        ? "card1color"
                        : "card2color"
                    }
                   
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 1 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.eightboxsrch ? (
                          <div
                          >
                            {this.state.Searcheddataeight}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                        
                        )}
                      
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar7}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddatanine === ""
                        ? "card1color"
                        : "card2color"
                    }
                  
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 2 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.ninthboxsrch ? (
                          <div
                         
                          >
                            {this.state.Searcheddatanine}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                         
                        )}
                     
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar8}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddataten === ""
                        ? "card1color"
                        : "card2color"
                    }
                  
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 3 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.tenthboxsrch ? (
                          <div
                          >
                            {this.state.Searcheddataten}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                         
                        )}
                        
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar9}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddataeleven === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 4 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.eleventhboxsrch ? (
                          <div
                         
                          >
                            {this.state.Searcheddataeleven}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                        
                        )}
                       
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar10}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddatatwelve === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 5 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.twelvthboxsrch ? (
                          <div
                          >
                            {this.state.Searcheddatatwelve}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                          
                        )}
                        
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar11}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                 
                </div>
              </div>
              <div className="row " style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  <Card
                    className={
                      this.state.Searcheddatathirteen === ""
                        ? "card1color"
                        : "card2color"
                    }
                  
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 6 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.thirteenboxsrch ? (
                          <div
                          >
                            {this.state.Searcheddatathirteen}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            No Schedule Added
                          </div>
                         
                        )}
                      
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar12}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  <Card
                    className={
                      this.state.Searcheddatafourteen === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 7 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.fourteenboxsrch ? (
                          <div
                          
                          >
                            {this.state.Searcheddatafourteen}
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "black",
                            }}
                          >
                            No Schedule Added
                          </div>
                          
                        )}
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar13}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  <Card
                    className={
                      this.state.Searcheddatafifteen === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 8 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.fifteenboxsrch ? (
                          <div
                         
                          >
                            {this.state.Searcheddatafifteen}
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "black",
                            }}
                          >
                            No Schedule Added
                          </div>
                         
                        )}
                        
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar14}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  <Card
                    className={
                      this.state.Searcheddatasixteen === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 9 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.sixteenboxsrch ? (
                          <div
                          >
                            {this.state.Searcheddatasixteen}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            {" "}
                            No Schedule Added
                          </div>
                         
                        )}
                     
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar15}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddataseventeen === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 10 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.seventeenboxsrch ? (
                          <div
                         
                          >
                            {this.state.Searcheddataseventeen}
                          </div>
                        ) : (
                          <div
                            style={{
                              color: " black",
                            }}
                          >
                            No Schedule Added
                          </div>
                         
                        )}
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar16}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.Searcheddataeighteen === ""
                        ? "card1color"
                        : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 11 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                          minHeight: "20vh",
                        }}
                      >
                        {this.state.eighteenboxsrch ? (
                          <div
                          >
                            {this.state.Searcheddataeighteen}
                          </div>
                        ) : (
                          <div
                            style={{
                              color: " black",
                            }}
                          >
                            No Schedule Added
                          </div>
                          
                        )}
                       
                      </Card.Text>
                      <Rating
                        name="half-rating"
                        value={this.state.srchstar17}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 12 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>

                        
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 1 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 2 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 3 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 4 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.searchdate} | 5 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>

                       
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              
              <div className="row" style={{ paddingTop: "25px" }}>
                
                <div className="col-md-2" style={{ paddingLeft: "5vh" }}>
                
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="container" style={{ paddingTop: "3vh" }}>
                <div className="row" style={{ paddingLeft: "23vh" }}>
                  <div
                    className="col-md-10"
                    style={{
                      border: "2px solid lightgrey",
                      paddingTop: "3vh",
                      paddingBottom: "3vh",
                    }}
                  >
                    <ProgressBar
                      variant="success"
                      style={{
                        height: "40px",
                      }}
                      bar
                      key={1}
                      label={`${now}%`}
                      max={100}
                      now={now}
                    />
                  </div>
                </div>
              </div>
              <div className="row " style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  <Card
                    className={
                      this.state.fstdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 6 AM{" "}
                      </p>
                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.firstbox ? (
                          <div
                            onClick={() => {
                              this.setState({
                                firstbox: false,
                              });
                            }}
                            style={{
                              minHeight: "20vh",
                              border: "none",
                            }}
                          >
                            {this.state.fstdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              maxLength="100"
                              defaultValue={this.state.fstdsc}
                              onChange={(evt) => {
                                this.handlefirstChange(evt);
                              }}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                     
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged(e);
                        }}
                        name="half-rating"
                        value={this.state.fststar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  <Card
                    className={
                      this.state.snddsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 7 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.secondbox ? (
                          <div
                            onClick={() => {
                              this.setState({ secondbox: false });
                            }}
                            style={{
                              minHeight: "20vh",
                              border: "none",
                            }}
                          >
                            {this.state.snddsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              maxLength="100"
                              defaultValue={this.state.snddsc}
                              onChange={(evt) => {
                                this.handlesecondChange(evt);
                              }}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChangedtwo(e);
                        }}
                        name="half-rating"
                        value={this.state.sndstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  <Card
                    className={
                      this.state.trddsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 8 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.thirdbox ? (
                          <div
                            onClick={() => {
                              this.setState({ thirdbox: false });
                            }}
                            style={{
                              minHeight: "20vh",
                              border: "none",
                            }}
                          >
                            {this.state.trddsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(evt) => {
                                this.handlethirdChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.trddsc}
                              placeholder="Enter Your text here "
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged3(e);
                        }}
                        name="half-rating"
                        value={this.state.trdstar}
                        precision={1}
                      />

                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  <Card
                    className={
                      this.state.frthdsc === "" ? "card1color" : "card2color"
                    }
                  
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 9 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.fourthbox ? (
                          <div
                            onClick={() => {
                              this.setState({ fourthbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.frthdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here"
                              onChange={(evt) => {
                                this.handlefourthChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.frthdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                       
                      </Card.Text>
                     
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged4(e);
                        }}
                        name="half-rating"
                        value={this.state.frtstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    className={
                      this.state.ffthdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 10 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.fifthbox ? (
                          <div
                            onClick={() => {
                              this.setState({ fifthbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.ffthdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              onChange={(evt) => {
                                this.handlefifthChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.ffthdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                     
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged5(e);
                        }}
                        name="half-rating"
                        value={this.state.fftstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.sxtdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 11 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.sixthbox ? (
                          <div
                            onClick={() => {
                              this.setState({ sixthbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.sxtdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              onChange={(evt) => {
                                this.handlesixthChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.sxtdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged6(e);
                        }}
                        name="half-rating"
                        value={this.state.sxtstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.svndsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 12 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.seventhbox ? (
                          <div
                            onClick={() => {
                              this.setState({ seventhbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.svndsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here"
                              onChange={(evt) => {
                                this.handleseventhChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.svndsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged7(e);
                        }}
                        name="half-rating"
                        value={this.state.svnstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.eigdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 1 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.eightbox ? (
                          <div
                            onClick={() => {
                              this.setState({ eightbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.eigdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here"
                              onChange={(evt) => {
                                this.handleeightChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.eigdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged8(e);
                        }}
                        name="half-rating"
                        value={this.state.egtstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    className={
                      this.state.nntdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 2 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.ninthbox ? (
                          <div
                            onClick={() => {
                              this.setState({ ninthbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.nntdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here"
                              onChange={(evt) => {
                                this.handleninthChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.nntdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged9(e);
                        }}
                        name="half-rating"
                        value={this.state.nntstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.tntdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 3 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.tenthbox ? (
                          <div
                            onClick={() => {
                              this.setState({ tenthbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.tntdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here"
                              onChange={(evt) => {
                                this.handletenthChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.tntdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged10(e);
                        }}
                        name="half-rating"
                        value={this.state.tntstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.elvdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 4 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.eleventhbox ? (
                          <div
                            onClick={() => {
                              this.setState({ eleventhbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.elvdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here"
                              onChange={(evt) => {
                                this.handleeleventhChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.elvdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged11(e);
                        }}
                        name="half-rating"
                        value={this.state.elvstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.tlvdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 5 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.twelvthbox ? (
                          <div
                            onClick={() => {
                              this.setState({ twelvthbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.tlvdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              onChange={(evt) => {
                                this.handletwelvthChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.tlvdsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged12(e);
                        }}
                        name="half-rating"
                        value={this.state.tlvstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row " style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  <Card
                    className={
                      this.state.thrtdsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 6 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.thirteenbox ? (
                          <div
                            onClick={() => {
                              this.setState({
                                thirteenbox: false,
                              });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.thrtdsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              maxLength="100"
                              defaultValue={this.state.thrtdsc}
                              onChange={(evt) => {
                                this.handlethirteenChange(evt);
                              }}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged13(e);
                        }}
                        name="half-rating"
                        value={this.state.thrtstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  <Card
                    className={
                      this.state.frtndsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 7 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.fourteenbox ? (
                          <div
                            onClick={() => {
                              this.setState({ fourteenbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.frtndsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              maxLength="100"
                              defaultValue={this.state.frtndsc}
                              onChange={(evt) => {
                                this.handlefourteenChange(evt);
                              }}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged14(e);
                        }}
                        name="half-rating"
                        value={this.state.frtnstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  <Card
                    className={
                      this.state.fftndsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 8 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.fifteenbox ? (
                          <div
                            onClick={() => {
                              this.setState({ fifteenbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.fftndsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(evt) => {
                                this.handlefifteenChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.fftndsc}
                              placeholder="Enter Your text here "
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged15(e);
                        }}
                        name="half-rating"
                        value={this.state.fftnstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  <Card
                    className={
                      this.state.sxtndsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 9 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.sixteenbox ? (
                          <div
                            onClick={() => {
                              this.setState({ sixteenbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.sxtndsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here"
                              onChange={(evt) => {
                                this.handlesixteenChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.sxtndsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged16(e);
                        }}
                        name="half-rating"
                        value={this.state.sxtnstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    className={
                      this.state.svtndsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 10 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.seventeenbox ? (
                          <div
                            onClick={() => {
                              this.setState({ seventeenbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.svtndsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              onChange={(evt) => {
                                this.handleseventeenChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.svtndsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged17(e);
                        }}
                        name="half-rating"
                        value={this.state.svtnstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    className={
                      this.state.eghtndsc === "" ? "card1color" : "card2color"
                    }
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 11 PM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        {this.state.eighteenbox ? (
                          <div
                            onClick={() => {
                              this.setState({ eighteenbox: false });
                            }}
                            style={{ minHeight: "20vh" }}
                          >
                            {this.state.eghtndsc}
                          </div>
                        ) : (
                          <Form>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your text here "
                              onChange={(evt) => {
                                this.handleeighteenChange(evt);
                              }}
                              maxLength="100"
                              defaultValue={this.state.eghtndsc}
                              style={{
                                minHeight: "20vh",
                                border: "none",
                              }}
                            />
                          </Form>
                        )}
                      </Card.Text>
                      <Rating
                        onChange={(e) => {
                          this.ratingChanged18(e);
                        }}
                        name="half-rating"
                        value={this.state.egtnstar}
                        precision={1}
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 12 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 1 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-2 offset-1">
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 2 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "10vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 3 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "18vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 4 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "25vh" }}>
                  {" "}
                  <Card
                    style={{
                      width: "35vh",
                      backgroundColor: "darkgrey",
                      opacity: "0.9",
                      minHeight: "42vh",
                    }}
                  >
                    <Card.Body>
                      <p style={{ color: "yellow" }}>
                        {this.state.curr_day} - {this.state.curr_month} -{" "}
                        {this.state.curr_year} | 5 AM{" "}
                      </p>

                      <Card.Text
                        style={{
                          color: "white",
                          borderTop: "1px solid white",
                        }}
                      >
                        <div>Sleeping Time</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="container">
                {this.state.success ? (
                  <div className="row">
                    <div
                      className="col-md-8 offset-2"
                      style={{
                        paddingTop: "45px",
                        position: "relative",
                        top: "4vh",
                        backgroundColor: "green",
                        textAlign: "center",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        <GiBarbedSpear /> {this.state.successmessage}
                      </h5>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.error ? (
                  <div className="row">
                    <div
                      className="col-md-8 offset-2"
                      style={{
                        paddingTop: "45px",
                        position: "relative",
                        top: "4vh",
                        backgroundColor: "red",
                        textAlign: "center",
                      }}
                    >
                      <h5 style={{ color: "white", fontSize: "small" }}>
                        <CgDanger /> Some Error Ocured
                      </h5>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-md-1 offset-1">
                  <button
                    onClick={() => {
                      this.savetimeblock();
                    }}
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      color: "white",
                      height: "30px",
                      width: "110px",
                      border: "none",
                    }}
                  >
                    {this.state.loading ? (
                      <span>
                        {" "}
                        <PulseLoader color="black" size={10} />{" "}
                      </span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
                <div className="col-md-2" style={{ paddingLeft: "5vh" }}>
                 
                </div>
              </div>
            </div>
          )}
        </div> */}
      </div>
    );
  }
}
export default TimeBlocking;
