import React, { Component } from "react";
import ReactPlayer from "react-player";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
// import { Router, Link } from "@reach/router"
import API from "../components/api";
import { ClipLoader } from "react-spinners";
import { navigate } from "@reach/router";

import { PacmanLoader } from "react-spinners";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Table,
  TableBody,
  TableRow,
  DialogContentText,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DownloadIcon from "@mui/icons-material/Download";

class SubjectDetailVideo extends Component {
  state = {
    video: "",
    Examdata: [],
    CodingUniid: "",
    exammsg: "",
    confirmation: false,
    loading: false,
  };
  componentDidMount() {
    let value = localStorage.getItem("uniqueID");
    this.setState({ CodingUniid: value });
    this.setState({ video: localStorage.getItem("videofile") });
    this.getExamlist();
  }
  getexamid(l) {
    console.log("xamid", l.exam_id);
    localStorage.setItem("examID", l.exam_id);
    localStorage.setItem("totalQues", l.total_no_of_question);
    this.setState({ confirmation: true });
  }
  Startexam() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      navigate("/codingtestexam");
      // this.props.history.push("/codingtestexam");
    }, 2500);
  }
  getExamlist() {
    const params = {
      topic_id: localStorage.getItem("topicID"),
      user_id: localStorage.getItem("userID"),
    };
    var that = this;
    API.post("StudentApi/onlineExamList", params)
      .then(function (response) {
        if (response.data.result === 1) {
          that.setState({
            Examdata: response.data.Unit,
            exammsg: response.data.message,
          });
        } else {
          that.setState({
            Examdata: [],
            exammsg: "",
          });
        }
        console.log(response.data.Unit);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>SUBJECT</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home{" "}
            </Link>
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              Student
            </Link>{" "}
            |
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/subjectuser"
            >
              {" "}
              Subject{" "}
            </Link>{" "}
            | Video
          </p>
        </div>

        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container spacing={2} marginY={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box display="flex" flexDirection="column">
                <Box
                  className={classes.demoPlayerBox}
                  border={1}
                  borderColor="black"
                  borderRadius="5px"
                >
                  <ReactPlayer
                    url={this.state.video}
                    playing="true"
                    width="100%"
                    controls
                    height="100%"
                    loop="true"
                    muted="false"
                  />
                </Box>
                <Box marginY={2}>
                  <Typography variant="h6">{this.state.TopicName}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Test Exam
                    </Typography>
                  </Box>
                  {this.state.Examdata.length > 0 && (
                    <Box display="flex" flexDirection="column">
                      {this.state.Examdata.map((l, id) => {
                        return (
                          <Box>
                            <Button
                              variant="outlined"
                              style={{
                                border: "1px solid lightgrey",
                                textTransform: "capitalize",
                                width: "100%",
                                // paddingTop: "3vh",
                                // paddingBottom: "2vh",
                              }}
                              onClick={() => {
                                this.getexamid(l);
                              }}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                style={{ width: "100%" }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box display="flex" style={{ width: "50%" }}>
                                    <Box
                                      style={{
                                        width: "40%",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      <Typography>Exam</Typography>
                                    </Box>
                                    <Box>
                                      <Typography>: {l.exam_name}</Typography>
                                    </Box>
                                  </Box>
                                  <Box display="flex" style={{ width: "50%" }}>
                                    <Box
                                      style={{
                                        width: "40%",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      <Typography>Topic</Typography>
                                    </Box>
                                    <Box>
                                      <Typography>: {l.topic_name}</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box display="flex" style={{ width: "50%" }}>
                                    <Box
                                      style={{
                                        width: "40%",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      <Typography>Test Type</Typography>
                                    </Box>
                                    <Box>
                                      <Typography>: {l.test_type}</Typography>
                                    </Box>
                                  </Box>
                                  <Box display="flex" style={{ width: "50%" }}>
                                    <Box
                                      style={{
                                        width: "40%",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      <Typography>Total Question</Typography>
                                    </Box>
                                    <Box>
                                      <Typography>
                                        : {l.total_no_of_question}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Button>
                          </Box>
                        );
                      })}
                      <Box>
                        {this.state.CodingUniid ? (
                          <Box
                            display="flex"
                            style={{
                              justifyContent: "space-between",
                              border: "1px solid lightgrey",
                              paddingTop: "2vh",
                              paddingBottom: "2vh",
                              paddingLeft: "2vh",
                              paddingRight: "2vh",
                              marginTop: "1vh",
                            }}
                          >
                            {" "}
                            <Box style={{ color: "green" }}>Exam Finished</Box>
                            <Box
                              style={{ border: "1px solid lightgrey" }}
                              onClick={() => {
                                navigate("/codingtestexam");
                              }}
                            >
                              View Sollution
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  )}
                  {this.state.Examdata.length === 0 && (
                    <Box display="flex" justifyContent="center">
                      <Box>
                        <Typography>Data Not Found</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* { category === "Motivational" && 
              <Box display="flex" flexDirection="column" marginY={2} style={{border:"1px solid lightgray"}}>
                <Box paddingY={1} paddingX={1} >
                  <Typography>Message</Typography>
                </Box>
                <Divider style={{marginTop:"8px",marginBottom:"8px"}}/>
                <Box display="flex">
                  <Box paddingX={2} alignSelf="center">
                    <MessageIcon />
                  </Box>
                  <Box style={{ width: "100%"}} paddingRight={2}>
                    <TextareaAutosize 
                    placeholder="Write Message"
                    minRows={4}
                    maxLength={200} 
                    maxRows={4} 
                    style={{ width: "100%",border:"none" }} />
                  </Box>
                </Box>
                <Divider style={{marginTop:"8px",marginBottom:"8px"}}/>
                <Box paddingX={1} paddingY={1}>
                  <Button variant="contained" style={{ color: "deepskyblue" }}>SEND</Button>
                </Box>
              </Box>} */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box display="flex" flexDirection="column">
                <Box
                  style={{
                    width: "100%",
                    height: "71px",
                    backgroundColor: "deepskyblue",
                  }}
                  display="flex"
                >
                  <Box
                    marginX={4}
                    style={{ alignSelf: "center", color: "white" }}
                  >
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>SIMILAR VIDEOS</Typography>
                  </Box>
                </Box>
                {/* <Box>
                  <Table stickyHeader aria-label="sticky table">

                    <TableBody>
                      {this.state.CodingVideos.map((video, index) => (
                        <>
                          <TableRow hover onClick={() => {
                            this.getplayablevideoid(video);
                          }} >
                            <Box display="flex" >
                              <Box style={{ width: "40%" }} padding={1}>
                                <img alt="" src={video?.thumbnail_file} style={{ width: "100%", height: "130px" }} />
                              </Box>
                              <Box style={{ width: "60%", alignSelf: "center" }} padding={2}>
                                <Typography>{video?.name}</Typography>
                              </Box>
                            </Box>

                          </TableRow>
                          {index !== Number(this.state.CodingVideos.length) - 1 && <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />}
                        </>
                      ))}

                    </TableBody>

                  </Table>
                </Box> */}
              </Box>
            </Grid>
          </Grid>
          <Dialog open={this.state.confirmation} maxWidth="sm" fullWidth>
            <DialogContent>
              <Typography variant="h6">
                Are You Sure You Want To Start Exam ?
              </Typography>
              <DialogContentText>
                Get yourself prepared before starting exam.
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Box display="flex" width="35%" justifyContent="space-between">
                <Box>
                  {this.state.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "deepskyblue" }}
                      onClick={() => {
                        this.Startexam();
                      }}
                    >
                      Confirm
                    </Button>
                  )}
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      this.setState({ confirmation: false });
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </Dialog>

          {/* <Modal
            size="md"
            show={this.state.confirmation}
            onHide={() => this.setState({ confirmation: false })}
            aria-labelledby="example-modal-sizes-title-sm"
            animation="true"
            autoFocus="true"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                <h6>Are You Sure You Want To Start Exam ?</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <p style={{}}>
                  Get yourself prepared before starting exam.
                </p>
                <div className="row">
                  <div className="col-md-3">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "deepskyblue" }}
                      onClick={() => {
                        this.Startexam();
                      }}
                    >
                      {this.state.loading ? (
                        <span>
                          {" "}
                          <ClipLoader color="white" size={20} />
                          Just a sec...{" "}
                        </span>
                      ) : (
                        <span>Confirm</span>
                      )}
                    </Button>
                  </div>

                  <div className="col-md-3">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "red" }}
                      onClick={() => {
                        this.setState({ confirmation: false });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal> */}
        </Container>

        {/* <div className="container " style={{ paddingTop: "30px" }}>
          <div className="row innovation-row">
            <div className="col-md-6 offset-1" innovation-col>
              <ReactPlayer
                playing={true}
                width="100%"
                controls={true}
                url={this.state.video}
              />
              <div className="row">
              </div>
              <div className="row" style={{ paddingTop: "20px" }}>
                <div className="col-md-8">
                  <p style={{ fontWeight: "bold" }}>TEST EXAM :-</p>

                  <div className="col-md-8">
                    {this.state.Examdata === undefined ? (
                      <div>{this.state.exammsg}</div>
                    ) : (
                      <div>
                        {this.state.Examdata.map((l, id) => {
                          return (
                            <div className="row" style={{ paddingTop: "2vh" }}>
                              <Button
                                variant="Info"
                                style={{
                                  border: "1px solid lightgrey",
                                  paddingTop: "3vh",
                                  paddingBottom: "2vh",
                                }}
                                onClick={() => {
                                  this.getexamid(l);
                                }}
                              >
                                <h5>Exam:-{l.exam_name}</h5>
                                <h6>Topic:-{l.topic_name}</h6>
                                <p>Test type: - {l.test_type}</p>
                                <p
                                  style={{
                                    fontSize: "small",
                                    lineHeight: "0px",
                                  }}
                                >
                                  Total Question: - {l.total_no_of_question}
                                </p>
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="col-md-4">
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <button
                  style={{
                    backgroundColor: "blue",
                    border: "none",
                    height: "40px",
                  }}
                >
                  <FaLongArrowAltRight />
                  SIMILAR VIDEOS
                </button>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  background: "#efefef",
                  margin: "20px 0",
                  alignItems: "center",
                  borderRadius: "6px",
                  overflow: "hidden",
                  width: "85%",
                }}
              >
                <div className="col-md-5">
                  <img
                    style={{ height: "60px", width: "100px" }}
                    src={require("../assets/img/videoimg.jpeg").default}
                  />
                  <button
                    style={{
                      height: "35px",
                      width: "35px",
                      border: "none",
                      position: "absolute",
                      left: "65%",

                      transform: "translate(-20%,-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      alert("Video will be played on development phase");
                    }}
                    className="rounded-circle"
                  >
                    <FaPlayCircle size="35px" />
                  </button>
                </div>
                <div className="col-md-7">
                  <p>Lorem Ipsum 1</p>
                  <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                    9th august , 2021
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "200px" }}></div> */}
      </>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  demoPlayerBox: {
    width: "100%%",
    height: "400px",
    objectFit: "cover",
    // border: "1px solid black",
    // borderRadius: "1px",
  },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(SubjectDetailVideo);
