import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, } from "react-bootstrap";
import API from "../components/api";
import { GiBarbedSpear } from "react-icons/gi";
import ClipLoader from "react-spinners/ClipLoader";
import { CgDanger } from "react-icons/cg";
import { navigate } from "@reach/router";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
} from '@mui/material'
import { withStyles } from '@mui/styles';

class Register extends Component {
  state = {
    page: 1,
    buttonpage2: false,
    buttonpage3: false,
    succes: false,
    error: false,

    res: "",
    name: "",
    father_name: "",
    dob: "",
    schools: "",
    blood_group: "",
    mobile: "",
    email: "",
    Image: "",
    Image1: "",
    Image3: "",
    loginnumber: "",
    password: "",
    repassword: "",

    classselected: "",
    SchoolList: [],
    classlist: [],

    nameError: "",
    fnameError: "",
    dobError: "",
    bloodError: "",
    mobileError: "",
    emailError: "",
    photoError: "",
    guardianError: "",
    schoolError: "",
    classError: "",
    certificateError: "",
    passwordError: "",
    repasswordError: "",

    loading: false,
    signuperror: "",
  };

  valid() {
    if (this.state.name.length < 5) {
      this.setState({
        nameError: "Name is a mandatory field & must be over 5 letter",
      });
      return false;
    }
    if (this.state.father_name.length < 5) {
      this.setState({
        fnameError: "Father Name is a mandatory field & must be over 5 letter",
      });
      return false;
    }
    if (this.state.dob.length < 1) {
      this.setState({
        dobError: "Date Of Birth is a mandatory field.",
      });
      return false;
    }
    if (this.state.blood_group.length < 1) {
      this.setState({
        bloodError: "Blood Group is a mandatory field.",
      });
      return false;
    }
    if (!this.state.email.includes("@")) {
      this.setState({
        emailError: "Email is a mandatory field & must include a @.",
      });
      return false;
    }
    if (this.state.Image === "") {
      this.setState({
        photoError: "This is a mandatory Field.",
      });
      return false;
    }
    if (this.state.Image1 === "") {
      this.setState({
        guardianError: "This is a mandatory Field.",
      });
      return false;
    }
    if (this.state.mobile.length < 10) {
      this.setState({
        mobileError:
          "Mobile number is a mandatory field & must be with 10 digits.",
      });
      return false;
    } else {
      return true;
    }
  }

  handleFirstButton() {
    this.setState({
      nameError: "",
      fnameError: "",
      dobError: "",
      bloodError: "",
      mobileError: "",
      emailError: "",
      photoError: "",
      guardianError: "",
    });
    if (this.valid()) {
    this.setState({ page: 2, buttonpage2: true });
    } else {
    }
  }
  valid1() {
    if (this.state.schools.length < 1) {
      this.setState({
        schoolError: "School is a mandatory field.",
      });
      return false;
    }
    if (this.state.classselected.length < 1) {
      this.setState({
        classError: "Class is a mandatory field.",
      });
      return false;
    }
    if (this.state.Image3 === "") {
      this.setState({
        certificateError: "Previous Certificate is a mandatory field.",
      });
      return false;
    } else {
      return true;
    }
  }
  handleSecondButton() {
    this.setState({ schoolError: "", classError: "", certificateError: "" });
    if (this.valid1()) {
    this.setState({ page: 3, buttonpage3: true });
    } else {
    }
  }
  handlenameChange(evt) {
    const{name, value}=evt.target
    // if(value.length < 5){
      this.setState({ name: evt.target.value });
      console.log("nameStudent: ", evt.target.value);
    // }
    
  }
  handlepasswordChange(evt) {
    console.log("passwordStudent",evt.target.value);
    this.setState({ password: evt.target.value });
  }
  handlerepasswordChange(evt) {
    this.setState({ repassword: evt.target.value });
  }
  handlefnameChange(evt) {
    this.setState({ father_name: evt.target.value });
    console.log("fname: ", this.state.father_name);
  }
  handledobChange(evt) {
    this.setState({ dob: evt.target.value });
    console.log("dob: ", this.state.dob);
  }
  handlebloodgroupChange(evt) {
    this.setState({ blood_group: evt.target.value });
    console.log("blood: ", this.state.blood_group);
  }
  handleloginnumberChange(evt) {
    this.setState({ loginnumber: evt.target.value });
  }
  handlemobileChange(evt) {
    const{name, value}=evt.target
    if(value.length <= 10){
      this.setState({ mobile: evt.target.value });
      console.log("mobile: ", this.state.mobile);
    }
    
  }
  handleemailChange(evt) {
    this.setState({ email: evt.target.value });
    console.log("mail: ", this.state.email);
  }
  handlestudentPicChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  handleGuardianstudentPicChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image1: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  handleCertificateChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image3: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  componentDidMount() {
    this.getSchoolList();
  }
  getSchoolList() {
    var that = this;
    API.get("/MasterApi/school_list")
      .then(function (response) {
        console.log(response);
        that.setState({
          SchoolList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  schoolSelected = (e) => {
    this.setState({ schools: e.target.value });
    console.log("schoold", e.target.value);
    // this.setState({ stateloading: true })
    setTimeout(() => {
      this.getclassList();
    }, 1000);
  };
  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", { school_id: this.state.schools })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  };
  // fetch(
  //   "https://thingproxy.freeboard.io/fetch/https://sermap.kazma.co.in/backend/Api/MasterApi/class_list",
  //   {
  //     method: "POST",
  //     body: JSON.stringify({ school_id: this.state.schools }),
  //   }
  // )
  //   .then((response) => response.json())
  //   .then((result) => {
  //     console.log("Success:", result);
  //     this.setState({ classlist: result.data });
  //   })
  //   .catch((error) => {
  //     console.error("Error:", error);
  //   });

  valid2() {
    if (this.state.password.length < 8) {
      this.setState({ passwordError: "Password Must be atleast 8 characters" });
      return false;
    }
    if (this.state.password != this.state.repassword) {
      this.setState({ repasswordError: "Password Doesnot Matches" });
      return false;
    } else {
      return true;
    }
  }
  Signup() {
    this.setState({ passwordError: "", repasswordError: "" });
    if (this.valid2()) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
      var formdata = new FormData();

      formdata.append("user_type", 1);
      formdata.append("name", this.state.name);
      formdata.append("father_name", this.state.father_name);
      formdata.append("dob", this.state.dob);
      formdata.append("school_id", this.state.schools);
      formdata.append("class_id", this.state.classselected);
      formdata.append("blood_group", this.state.blood_group);
      formdata.append("mobile", this.state.mobile);
      formdata.append("email", this.state.email);
      formdata.append("password", this.state.password);
      formdata.append("photo", this.state.Image);
      formdata.append("group_photo", this.state.Image1);
      formdata.append("pre_class_certificate", this.state.Image3);
      formdata.append("latitude", "");
      formdata.append("longitude", "");
      formdata.append("address", "");
      formdata.append("fcm_token", "");

      const head = {
        headers: {
          "Content-Type": "application/json",
          Source: "web",
        },
      };
      console.log("dsfgsdfg313412423", formdata);

      var that = this;
      API.post("StudentApi/registration", formdata, head)
        .then(function (response) {
          console.log(response);
          that.setState({
            res: response.data.result,
            signuperror: response.data.message,
          });
        })

        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          if (that.state.res === 1) {
            that.setState({ success: true });
            setTimeout(() => {
              that.setState({ success: false });
            }, 3000);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            that.setState({ error: true });
            setTimeout(() => {
              that.setState({ error: false });
            }, 3000);
          }
        });
    }
  }
  render() {
    const { classes } = this.props
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> REGISTER</h1>
          <p>Home | Register</p>
        </div>
        <Container>
          <Grid container spacing={2} marginY={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  <button
                    class="rounded-circle"
                    onClick={() =>
                      this.setState({
                        page: 1,
                      })
                    }
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      width: "50px",
                      backgroundColor: "lightgreen",
                      border: "1px solid lightgrey",
                    }}
                  >
                    1
                  </button>
                </Box>
                <Box>
                  <Typography> Personal</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  <button
                    className={
                      this.state.buttonpage2
                        ? "rounded-circle buttontrue"
                        : "rounded-circle buttonfalse"
                    }
                    onClick={() =>
                      this.setState({
                        page: 2,
                        buttonpage2: true,
                      })
                    }
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      border: "1px solid lightgrey",
                      width: "50px",
                    }}
                  >
                    2
                  </button>
                </Box>
                <Box>
                  <Typography> Education</Typography>
                </Box>
              </Box>

            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  <button
                    className={
                      this.state.buttonpage3
                        ? "rounded-circle buttontrue"
                        : "rounded-circle buttonfalse"
                    }
                    onClick={() =>
                      this.setState({
                        page: 3,
                        buttonpage3: true,
                      })
                    }
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      border: "1px solid lightgrey",
                      width: "50px",
                    }}
                  >
                    3
                  </button>
                </Box>
                <Box>
                  <Typography> Login Details</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container registerforms">
          <div
            className="row"
            style={{ paddingTop: "30px", paddingBottom: "30px" }}
          >
            <div className="col-md-3 offset-3">
              <button
                class="rounded-circle"
                onClick={() =>
                  this.setState({
                    page: 1,
                  })
                }
                style={{
                  fontSize: "20px",
                  height: "50px",
                  width: "50px",
                  backgroundColor: "lightgreen",
                  border: "1px solid lightgrey",
                }}
              >
                1
              </button>
              <p> Personal</p>
            </div>
            <div className="col-md-3">
              <button
                className={
                  this.state.buttonpage2
                    ? "rounded-circle buttontrue"
                    : "rounded-circle buttonfalse"
                }
                onClick={() =>
                  this.setState({
                    page: 2,
                    buttonpage2: true,
                  })
                }
                style={{
                  fontSize: "20px",
                  height: "50px",
                  border: "1px solid lightgrey",
                  width: "50px",
                }}
              >
                2
              </button>
              <p> Education</p>
            </div>
            <div className="col-md-3">
              <button
                className={
                  this.state.buttonpage3
                    ? "rounded-circle buttontrue"
                    : "rounded-circle buttonfalse"
                }
                onClick={() =>
                  this.setState({
                    page: 3,
                    buttonpage3: true,
                  })
                }
                style={{
                  fontSize: "20px",
                  height: "50px",
                  border: "1px solid lightgrey",
                  width: "50px",
                }}
              >
                3
              </button>
              <p>Login Details</p>
            </div>
          </div>
        </div> */}
        {this.state.page === 1 ? (
          <Container >
            <h6>*Required Field</h6>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Category *</p>
                <FormControl variant="filled" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="category"
                    input={<OutlinedInput />}
                    value={3}
                    disabled
                  >
                    <MenuItem value={3} >Student</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Name *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    maxLength="20"
                    required
                    value={this.state.name}
                    onChange={(evt) => {
                      this.handlenameChange(evt);
                    }}
                    type="name"
                    placeholder=""
                    fullWidth
                  />
                </FormControl>
                {<span className="error">{this.state.nameError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Father's Name *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.father_name}
                    onChange={(evt) => {
                      this.handlefnameChange(evt);
                    }}
                    type="name"
                    placeholder=""
                    fullWidth
                  />
                </FormControl>
                {<span className="error">{this.state.fnameError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>DOB *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.dob}
                    onChange={(evt) => {
                      this.handledobChange(evt);
                    }}
                    type="date"
                    placeholder=""
                  />
                </FormControl>
                {<span className="error">{this.state.dobError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Blood Group *</p>
                <FormControl variant="filled" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="category"
                    input={<OutlinedInput />}
                    value={this.state.blood_group}
                    onChange={(evt) => {
                      this.handlebloodgroupChange(evt);
                    }}
                  >
                    <MenuItem value="A+" >A+</MenuItem>
                    <MenuItem value="B+" >B+</MenuItem>
                    <MenuItem value="O+" >O+</MenuItem>
                    <MenuItem value="AB+" >AB+</MenuItem>
                    <MenuItem value="A-" >A-</MenuItem>
                    <MenuItem value="B-" >B-</MenuItem>
                    <MenuItem value="O-" >O-</MenuItem>
                    <MenuItem value="AB-" >AB-</MenuItem>


                  </Select>
                </FormControl>
                {<span className="error">{this.state.bloodError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Mobile *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    maxLength={10}
                    required
                    value={this.state.mobile}
                    onChange={(evt) => {
                      this.handlemobileChange(evt);
                    }}
                    type="number"
                    placeholder=""
                  />
                </FormControl>
                {<span className="error">{this.state.mobileError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Email *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.email}
                    onChange={(evt) => {
                      this.handleemailChange(evt);
                    }}
                    type="email"
                    placeholder=""
                  />
                </FormControl>
                {<span className="error">{this.state.emailError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Student Photo *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    onChange={(evt) => {
                      this.handlestudentPicChange(evt);
                    }}
                    type="file"
                    inputProps={{
                      accept:"image/png, image/jpeg"
                    }}
                    placeholder=""
                  />
                </FormControl>
                {<span className="error">{this.state.photoError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Guardian Photo *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    onChange={(evt) => {
                      this.handleGuardianstudentPicChange(evt);
                    }}
                    type="file"
                    inputProps={{
                      accept:"image/png, image/jpeg"
                    }}
                    placeholder=""
                  />
                </FormControl>
                {<span className="error">{this.state.guardianError}</span>}

              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    onClick={() => {
                      this.handleFirstButton();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          // <div>
          //   <div className="container">
          //     <div className="col offset-2">
          //       {" "}
          //       <h6>*Required Field</h6>
          //     </div>
          //     <div className="row" style={{ paddingTop: "20px" }}>
          //       <div className="col-md-4 offset-2">
          //         <p style={{ opacity: "0.6" }}>Category *</p>
          //         <Form.Select disabled aria-label="Default select example">
          //           <option>Student</option>
          //         </Form.Select>
          //       </div>
          //       <div class="col-md-4">
          //         <p style={{ opacity: "0.6" }}>Name *</p>
          //         <Form>
          //           <Form.Control
          //             maxLength="20"
          //             required
          //             value={this.state.name}
          //             onChange={(evt) => {
          //               this.handlenameChange(evt);
          //             }}
          //             type="name"
          //             placeholder=""
          //           />
          //         </Form>
          //         {<span className="error">{this.state.nameError}</span>}
          //       </div>
          //     </div>
          //     <div className="row" style={{ paddingTop: "20px" }}>
          //       <div className="col-md-4 offset-2">
          //         <p style={{ opacity: "0.6" }}>Father's Name *</p>
          //         <Form>
          //           <Form.Control
          //             value={this.state.father_name}
          //             onChange={(evt) => {
          //               this.handlefnameChange(evt);
          //             }}
          //             type="name"
          //             placeholder=""
          //           />
          //         </Form>
          //         {<span className="error">{this.state.fnameError}</span>}
          //       </div>
          //       <div class="col-md-4">
          //         <p style={{ opacity: "0.6" }}>DOB *</p>
          //         <Form>
          //           <Form.Control
          //             value={this.state.dob}
          //             onChange={(evt) => {
          //               this.handledobChange(evt);
          //             }}
          //             type="date"
          //             placeholder=""
          //           />
          //         </Form>
          //         {<span className="error">{this.state.dobError}</span>}
          //       </div>
          //     </div>
          //     <div className="row" style={{ paddingTop: "20px" }}>
          //       <div className="col-md-4 offset-2">
          //         <p style={{ opacity: "0.6" }}>Blood Group *</p>
          //         <Form.Select
          //           value={this.state.blood_group}
          //           onChange={(evt) => {
          //             this.handlebloodgroupChange(evt);
          //           }}
          //           aria-label="Default select example"
          //         >
          //           <option></option>
          //           <option value="O+">O+</option>
          //           <option value="O-">O-</option>
          //           <option value="A+">A+</option>
          //           <option value="AB+">AB+</option>
          //           <option value="A-">A-</option>
          //           <option value="AB-">AB-</option>
          //           <option value="B+">B+</option>
          //         </Form.Select>
          //         {<span className="error">{this.state.bloodError}</span>}
          //       </div>
          //       <div class="col-md-4">
          //         <p style={{ opacity: "0.6" }}>Mobile Number *</p>
          //         <Form>
          //           <Form.Control
          //             maxLength={10}
          //             required
          //             value={this.state.mobile}
          //             onChange={(evt) => {
          //               this.handlemobileChange(evt);
          //             }}
          //             type="number"
          //             placeholder=""
          //           />
          //         </Form>
          //         {<span className="error">{this.state.mobileError}</span>}
          //       </div>
          //     </div>
          //     <div className="row" style={{ paddingTop: "20px" }}>
          //       <div className="col-md-4 offset-2">
          //         <p style={{ opacity: "0.6" }}>Email Address *</p>
          //         <Form>
          //           <Form.Control
          //             value={this.state.email}
          //             onChange={(evt) => {
          //               this.handleemailChange(evt);
          //             }}
          //             type="email"
          //             placeholder=""
          //           />
          //         </Form>
          //         {<span className="error">{this.state.emailError}</span>}
          //       </div>
          //       <div class="col-md-4">
          //         <p style={{ opacity: "0.6" }}>Student Photo *</p>
          //         <Form>
          //           <Form.Control
          //             onChange={(evt) => {
          //               this.handlestudentPicChange(evt);
          //             }}
          //             type="file"
          //             placeholder=""
          //           />
          //         </Form>
          //         {<span className="error">{this.state.photoError}</span>}
          //       </div>
          //     </div>
          //     <div className="row" style={{ paddingTop: "20px" }}>
          //       <div class="col-md-4 offset-2">
          //         <p style={{ opacity: "0.6" }}>Guardian's Photo *</p>
          //         <Form>
          //           <Form.Control
          //             onChange={(evt) => {
          //               this.handleGuardianstudentPicChange(evt);
          //             }}
          //             type="file"
          //             placeholder=""
          //           />
          //         </Form>
          //         {<span className="error">{this.state.guardianError}</span>}
          //       </div>
          //     </div>
          //     <div className="col offset-2" style={{ paddingTop: "20px" }}>
          //       <Button
          //         variant="primary"
          //         style={{ borderRadius: "0px", width: "18vh" }}
          //         onClick={() => {
          //           this.handleFirstButton();
          //         }}
          //       >
          //         Next
          //       </Button>
          //     </div>
          //   </div>
          //   )
          // </div>
        ) : (
          ""
        )}
        {this.state.page === 2 ? (
          <Container >
            <h6>*Required Field</h6>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>School Name*</p>
                <FormControl variant="filled" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={this.schoolSelected}
                    input={<OutlinedInput />}
                    value={this.state.schools}
                    aria-label="Default select example"
                  >
                    {this.state.SchoolList.map((l, i) => {
                      return (
                        <MenuItem key={i} value={l.school_id}>
                          {l.name}({l.school_code})
                        </MenuItem>
                      );
                    })}


                  </Select>
                  {<span className="error">{this.state.schoolError}</span>}
                </FormControl>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Class *</p>
                <FormControl variant="filled" fullWidth>
                  <Select
                    onChange={(e) => {
                      this.setState({ classselected: e.target.value });
                    }}
                    input={<OutlinedInput />}
                    value={this.state.classselected}
                    aria-label="Default select example"
                  >
                    {this.state.classlist.map((l, i) => {
                      return (
                        <MenuItem key={i} value={l.class_id}>
                          {l.name}
                        </MenuItem>
                      );
                    })}


                  </Select>
                  {<span className="error">{this.state.classError}</span>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Previous Certificate *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    onChange={(evt) => {
                      this.handleCertificateChange(evt);
                    }}
                    type="file"
                    inputProps={{
                      accept:"image/png, image/jpeg"
                    }}
                  />
                </FormControl>
                {<span className="error">{this.state.certificateError}</span>}
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    onClick={() => this.handleSecondButton()}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          // <div className="container page-2">
          //   <form>
          //     <h6 className="col offset-3">*Required Field</h6>
          //     <div class="row">
          //       <div class="col-3 offset-3">
          //         <label
          //           for="Schoolname"
          //           style={{ opacity: "0.4", paddingTop: "10px" }}
          //         >
          //           School Name*
          //         </label>
          //         <Form.Select
          //           onChange={this.schoolSelected}
          //           required
          //           value={this.state.schools}
          //           aria-label="Default select example"
          //         >
          //           <option></option>
          //           {this.state.SchoolList.map((l, i) => {
          //             return (
          //               <option key={i} value={l.school_id}>
          //                 {l.name}({l.school_code})
          //               </option>
          //             );
          //           })}
          //         </Form.Select>
          //         {<span className="error">{this.state.schoolError}</span>}
          //       </div>
          //       <div class="col-3">
          //         <label
          //           for="class"
          //           style={{ opacity: "0.4", paddingTop: "10px" }}
          //         >
          //           Class*
          //         </label>
          //         <Form.Select
          //           onChange={(e) => {
          //             this.setState({ classselected: e.target.value });
          //           }}
          //           required
          //           value={this.state.classselected}
          //           aria-label="Default select example"
          //         >
          //           <option></option>
          //           {this.state.classlist.map((l, i) => {
          //             return (
          //               <option key={i} value={l.class_id}>
          //                 {l.name}
          //               </option>
          //             );
          //           })}
          //         </Form.Select>
          //         {<span className="error">{this.state.classError}</span>}
          //       </div>
          //     </div>
          //     <div className="col-4 offset-3">
          //       <h6
          //         style={{ top: "15px", position: "relative", opacity: "0.4" }}
          //       >
          //         Previous Certificate*
          //       </h6>
          //       <input
          //         onChange={(evt) => {
          //           this.handleCertificateChange(evt);
          //         }}
          //         type="file"
          //         style={{
          //           opacity: "0.4",
          //           top: "15px",
          //           position: "relative",
          //           paddingTop: "10px",
          //         }}
          //         id="repass"
          //         class="form-control"
          //       />
          //       {<span className="error">{this.state.certificateError}</span>}
          //     </div>
          //   </form>
          //   <button
          //     className="page-2-button col offset-3"
          //     onClick={() => this.handleSecondButton()}

          //   >
          //     NEXT
          //   </button>
          // </div>
        ) : (
          ""
        )}
        <div>
          {this.state.page === 3 ? (
            <Container >
              <h6>*Required Field</h6>
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p style={{ opacity: "0.6" }}>Mobile</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      disabled
                      value={this.state.mobile}
                      onChange={(evt) => {
                        this.handleloginnumberChange(evt);
                      }}
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Password *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      value={this.state.password}
                      onChange={(evt) => {
                        this.handlepasswordChange(evt);
                      }}
                      type="password"
                    />
                  </FormControl>
                  {<span className="error">{this.state.passwordError}</span>}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <p style={{ opacity: "0.6" }}>Re Password *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      value={this.state.repassword}
                      onChange={(evt) => {
                        this.handlerepasswordChange(evt);
                      }}
                      type="password"
                    />
                  </FormControl>
                  {<span className="error">{this.state.repasswordError}</span>}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Box display="flex" flexDirection="column">
                    <Box>
                      {this.state.error && <Alert severity="error">{this.state.signuperror}</Alert>}
                      {this.state.success && <Alert severity="success">Registration Successful</Alert>}
                    </Box>
                    <Box display="flex" marginTop={2} justifyContent="space-between">
                      {this.state.loading ?
                        <CircularProgress />
                        :
                        <Box>
                          <Button variant="contained" style={{ backgroundColor: "deepskyblue" }}
                            onClick={() => {
                              this.Signup();
                            }}
                          >Register</Button>
                        </Box>
                      }

                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            // <div className="container">
            //   <div className="col offset-2">
            //     {" "}
            //     <p>*Required Field</p>
            //   </div>
            //   <div className="row">
            //     <div className="col-md-4 offset-2">
            //       <p style={{ opacity: "0.6" }}>Mobile Number</p>
            //       <Form>
            //         <Form.Control
            //           disabled
            //           value={this.state.mobile}
            //           onChange={(evt) => {
            //             this.handleloginnumberChange(evt);
            //           }}
            //           type="name"
            //           placeholder=""
            //         />
            //       </Form>
            //     </div>
            //   </div>
            //   <div className="row" style={{ paddingTop: "20px" }}>
            //     <div className="col-md-4 offset-2">
            //       <p style={{ opacity: "0.6" }}>Password*</p>
            //       <Form>
            //         <Form.Control
            //           value={this.state.password}
            //           onChange={(evt) => {
            //             this.handlepasswordChange(evt);
            //           }}
            //           type="password"
            //           placeholder=""
            //         />
            //       </Form>
            //       {<span className="error">{this.state.passwordError}</span>}
            //     </div>
            //     <div className="col-md-4">
            //       <p style={{ opacity: "0.6" }}> Re Password*</p>
            //       <Form>
            //         <Form.Control
            //           value={this.state.repassword}
            //           onChange={(evt) => {
            //             this.handlerepasswordChange(evt);
            //           }}
            //           type="password"
            //           placeholder=""
            //         />
            //       </Form>
            //       {<span className="error">{this.state.repasswordError}</span>}
            //     </div>
            //   </div>
            //   <div className="container">
            //     {this.state.success ? (
            //       <div className="row">
            //         <div
            //           className="col-md-8 offset-2"
            //           style={{
            //             paddingTop: "45px",
            //             position: "relative",
            //             top: "4vh",
            //             backgroundColor: "green",
            //             textAlign: "center",
            //           }}
            //         >
            //           <h5 style={{ color: "white", fontSize: "small" }}>
            //             <GiBarbedSpear /> Registration Successful
            //           </h5>
            //         </div>
            //       </div>
            //     ) : (
            //       ""
            //     )}
            //     {this.state.error ? (
            //       <div className="row">
            //         <div
            //           className="col-md-8 offset-2"
            //           style={{
            //             paddingTop: "45px",
            //             position: "relative",
            //             top: "4vh",
            //             backgroundColor: "red",
            //             textAlign: "center",
            //           }}
            //         >
            //           <h5 style={{ color: "white", fontSize: "small" }}>
            //             <CgDanger /> {this.state.signuperror}
            //           </h5>
            //         </div>
            //       </div>
            //     ) : (
            //       ""
            //     )}
            //   </div>
            //   <div className="col-6 offset-2" style={{ paddingTop: "20px" }}>
            //     <Button
            //       variant="primary"
            //       style={{ borderRadius: "0px" }}
            //       onClick={() => {
            //         this.Signup();
            //       }}
            //     >
            //       {this.state.loading ? (
            //         <span>
            //           {" "}
            //           <ClipLoader color="white" size={20} />
            //           Please Wait{" "}
            //         </span>
            //       ) : (
            //         <span>Submit</span>
            //       )}
            //     </Button>
            //   </div>
            // </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  stepOne: {
    fontSize: "20px",
    height: "50px",
    width: "50px",
    backgroundColor: "lightgreen",
    border: "1px solid lightgrey",
    borderRadius: "50%",
  },
  nextButton1: {
    marginTop: "12px",
    marginLeft: "16px"
  },
});
export default withStyles(styles)(Register);
