import React from "react";
import { Component } from "react";

class Services extends Component {
  state = {};
  render() {
    return (
      <div>
        <h2>Hey welcome to services</h2>
      </div>
    );
  }
}
export default Services;
