import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "@mui/material";
import API from "../components/api";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  CircularProgress,
  MenuItem,
  Select,
  Button,
} from "@mui/material";

export default class GameSubscription extends Component {
  state = {
    page: 1,
    gamelist: [],
    gamechosen: "none",
    SubscriberLists: [],
    subsmsg: "",
    item: "",
    showdetails: false,
  };
  componentDidMount() {
    this.getgamelists();
    // this.getsubslist();
  }
  gameChanged(e) {
    this.setState({ gamechosen: e.target.value });
    this.setState({ page: 2 });
    const params = {
      user_id: localStorage.getItem("userID"),
      game_id: e.target.value,
    };
    var that = this;
    API.post("AcademyApi/subscribers", params)
      .then(function (response) {
        console.log(response.data.message);
        that.setState({
          SubscriberLists: response.data.data,
          subsmsg: response.data.message,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
    console.log("subList123455", e.target.value);
  }

  getStudentdetails(l) {
    this.setState({ item: l, showdetails: true });
  }
  getgamelists() {
    const userid = localStorage.getItem("userID");
    var that = this;
    API.post("AcademyApi/profile", { user_id: userid })
      .then(function (response) {
        console.log(response.data.data.game_ids);
        that.setState({
          gamelist: response.data.data.game_ids,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        const result = that.state.gamelist.find(({ game_id }) => game_id === 2);
        console.log("folafol", result);
      });
  }
  render() {
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> SUBSCRIPTION</h1>
          <p>
            {" "}
            <Link href="/" style={{ textDecoration: "none", color: "white" }}>
              Home{" "}
            </Link>{" "}
            |{" "}
            <Link
              href="/gameprofile"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              Game Academy
            </Link>{" "}
            | Subscription
          </p>
        </div>
        <Container
          style={{
            marginBottom: "12px",
            marginTop: "20px",
            minHeight: "500px",
          }}
        >
          {this.state.page === 1 && (
            <>
              <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={9} lg={9}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      SUBSCRIPTION
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControl variant="filled" fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="selectGame"
                        input={<OutlinedInput />}
                        onChange={(e) => {
                          this.gameChanged(e);
                        }}
                        value={this.state.gamechosen}
                      >
                        <MenuItem value="none">Select Game</MenuItem>
                        {this.state.gamelist.map((l, i) => {
                          return (
                            <MenuItem key={i} value={l.gameId}>
                              {l.gameName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "13%",
                        marginBottom: "12px",
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </Container>
            </>
          )}

          {this.state.page === 2 && (
            <>
              <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={9} lg={9}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      STUDENTS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControl variant="filled" fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="selectGame"
                        input={<OutlinedInput />}
                        onChange={(e) => {
                          this.gameChanged(e);
                        }}
                        value={this.state.gamechosen}
                      >
                        <MenuItem value="none">Select Game</MenuItem>
                        {this.state.gamelist.map((l, i) => {
                          return (
                            <MenuItem key={i} value={l.gameId}>
                              {l.gameName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "13%",
                        marginBottom: "12px",
                      }}
                    ></div>
                  </Grid>
                </Grid>
              </Container>
              <Container
                style={{
                  marginBottom: "12px",
                  marginTop: "20px",
                  minHeight: "500px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {this.state.SubscriberLists === undefined ? (
                      <Box>No Subscriber till now.</Box>
                    ) : (
                      <>
                        {this.state.SubscriberLists.map((l, id) => {
                          return (
                            <Button
                              style={{
                                textTransform: "capitalize",
                                border: "1px solid lightgrey",
                                marginBottom: "8px",
                              }}
                              onClick={() => {
                                this.getStudentdetails(l);
                              }}
                              fullWidth
                            >
                              <Box display="flex" style={{ width: "100%" }}>
                                <Box style={{ textAlign: "left" }}>
                                  <Typography
                                    variant="h6"
                                    style={{ color: "black" }}
                                  >
                                    {l.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Button>
                          );
                        })}
                      </>
                    )}
                  </Grid>
                  {this.state.showdetails && (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box display="flex" flexDirection="column">
                        <Box
                          style={{
                            width: "100%",
                            height: "71px",
                            backgroundColor: "deepskyblue",
                          }}
                          display="flex"
                        >
                          <Box
                            marginX={4}
                            style={{ alignSelf: "center", color: "white" }}
                          >
                            <FaLongArrowAltRight />
                          </Box>
                          <Box style={{ alignSelf: "center", color: "white" }}>
                            <Typography>STUDENT DETAIL</Typography>
                          </Box>
                        </Box>
                        <Box style={{ border: "1px solid lightgrey" }}>
                          <Box paddingY={2} paddingX={2}>
                            <Box>
                              <Typography fontWeight="bold">Test</Typography>
                              <Typography>{this.state.item.class}</Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box paddingY={2} paddingX={2}>
                            <Box>
                              <Typography fontWeight="bold">DOB</Typography>
                              <Typography>{this.state.item.dob}</Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box paddingY={2} paddingX={2}>
                            <Box>
                              <Typography fontWeight="bold">
                                Father's Name
                              </Typography>
                              <Typography>
                                {this.state.item.father_name}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box paddingY={2} paddingX={2}>
                            <Box>
                              <Typography fontWeight="bold">Mobile</Typography>
                              <Typography>{this.state.item.mobile}</Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box paddingY={2} paddingX={2}>
                            <Box>
                              <Typography fontWeight="bold">
                                Location
                              </Typography>
                              <Typography>{this.state.item.address}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Container>
            </>
          )}
        </Container>
      </>
    );
  }
}
