import React, { Component } from "react";
import { AiFillWallet } from "react-icons/ai";
import { ClipLoader } from "react-spinners";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
} from "@mui/material";
import API from "./api";

export default class StudentWallet extends Component {
  state = {
    profileData: [],
    checkbalance: true,
    showbalance: false,
    loading: false,
  };
  componentDidMount() {
    this.getuserdata();
  }
  getuserdata() {
    const userid = localStorage.getItem("userID");
    var that = this;
    API.post("StudentApi/profile", { user_id: userid })
      .then(function (response) {
        console.log(response);
        that.setState({ profileData: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> WALLET</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home{" "}
            </Link>
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              User Profile{" "}
            </Link>
            | Wallet
          </p>
        </div>
        <Container>
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
            sm={12}
            style={{ minHeight: "50vh" }}
          >
            <Typography style={{ paddingTop: "2vh" }} variant="h6">
              {" "}
              STUDENT WALLET
            </Typography>
            <div
              style={{
                backgroundColor: "rgb(24, 169, 218)",
                height: "2px",
                width: "80px",
              }}
            ></div>
            <Grid
              display="flex"
              item
              md={12}
              lg={12}
              xs={12}
              sm={12}
              style={{ paddingLeft: "4vh", paddingTop: "3vh" }}
            >
              <Container
                style={{ border: "1px solid lightgrey", marginTop: "2vh" }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    paddingTop: "1vh",
                    paddingLeft: "1vh",
                    paddingBottom: "1vh",
                    paddingRight: "1vh",
                  }}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      Name:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{this.state.profileData.name}</Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    paddingTop: "1vh",
                    paddingLeft: "1vh",
                    paddingBottom: "1vh",
                    paddingRight: "1vh",
                  }}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      School:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{this.state.profileData.school}</Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    paddingTop: "1vh",
                    paddingLeft: "1vh",
                    paddingBottom: "1vh",
                    paddingRight: "1vh",
                  }}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      Class:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{this.state.profileData.class}</Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    paddingTop: "1vh",
                    paddingLeft: "1vh",
                    paddingBottom: "1vh",
                    paddingRight: "1vh",
                  }}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      Mobile:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{this.state.profileData.mobile}</Typography>
                  </Box>
                </Box>
              </Container>

              <Container
                style={{
                  border: "1px solid lightgrey",
                  marginTop: "2vh",
                  marginLeft: "5vh",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-around"
                  style={{ paddingTop: "9vh" }}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      <AiFillWallet color="deepskyblue" size="5vh" /> Wallet
                      Balance:
                    </Typography>
                  </Box>
                  <Box>
                    {this.state.checkbalance ? (
                      <Button
                        onClick={() => {
                          this.setState({ checkbalance: false, loading: true });
                          setTimeout(() => {
                            this.setState({
                              loading: false,
                              showbalance: true,
                            });
                          }, 2500);
                        }}
                      >
                        Check Balance
                      </Button>
                    ) : (
                      ""
                    )}
                    {this.state.loading ? <ClipLoader /> : ""}
                    {this.state.showbalance ? (
                      <Typography>
                        {this.state.profileData.wallet_balance}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
