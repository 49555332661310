import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { GrAddCircle } from "react-icons/gr";
import API from "../components/api";
import { ClipLoader } from "react-spinners";
import { navigate } from "@reach/router";

export default class GamePayment extends Component {
  state = {
    Academy: [],
    gameacademy: "",
    Gamesdata: [],
    game_id: "",
    selectedgame: "",
    gameclassification: [],
    classificationid: "",
    submsg: "",
    loading: false,
    success: false,
    classiError: "",
    Profile: "",
    choosetime: false,
    Timings: [],
    timingid: "",
    fees: "",
    respo: "",
  };
  componentDidMount() {
    const gameaca = localStorage.getItem("academyID");
    const gameid = localStorage.getItem("gameID");

    this.setState({ gameacademy: gameaca, game_id: gameid });
    this.getGameList();
    this.getProfile();
  }
  getProfile() {
    var that = this;
    API.post("StudentApi/profile", { user_id: localStorage.getItem("userID") })
      .then(function (response) {
        console.log(response);
        that.setState({
          Profile: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  valid() {
    if (this.state.classificationid.length < 1) {
      this.setState({
        classiError: "Choose Subscription Package first",
      });
      return false;
    } else {
      return true;
    }
  }
  takegameSubscription() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
    if (this.valid()) {
      this.setState({ classiError: "" });

      const parameter = {
        user_id: localStorage.getItem("userID"),
        orderAmount: this.state.fees,
        name: this.state.Profile.name,
        email: this.state.Profile.email,
        mobile: this.state.Profile.mobile,
      };
      var that = this;
      API.post("StudentApi/createAcademyOrder", parameter)
        .then(function (response) {
          console.log(response.data.payment_link);
          that.setState({
            createorder: response.data,
          });
          that.state.respo = response;
          navigate(`${response.data.payment_link}`);
          localStorage.setItem("orderID", response.data.order_id);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // const params = {
          //   user_id: localStorage.getItem("userID"),
          //   action: "add",
          //   classification_id: that.state.classificationid,
          //   game_timing_id: that.state.timingid,
          //   order_id: that.state.respo.data.order_id,
          //   cf_order_id: that.state.respo.data.cf_order_id,
          //   order_token: that.state.respo.data.order_token,
          // };
          // API.post("StudentApi/gameSubscription", params)
          //   .then(function (response) {
          //     console.log(response);
          //     // that.setState({ submsg: response.data.message });
          //     // that.setState({ success: true });
          //     // setTimeout(() => {
          //     //   that.setState({ success: false });
          //     // }, 3000);
          //     // if (response.data.result === 1) {
          //     //   setTimeout(() => {
          //     //     navigate("/student/subcriptions");
          //     //   }, 3000);
          //     // }
          //   })
          //   .catch(function (error) {
          //     // handle error
          //     console.log(error);
          //   })
          //   .finally(function () {
          //     if (that.state.res === 1) {
          //     } else {
          //     }
          //   });
        });
      //________________________________________________________
    }
  }
  gettiming(e, l) {
    localStorage.setItem("classification_ID", e.target.value);
    this.setState({ classificationid: e.target.value, fees: l.fees });
    const timings = this.state.gameclassification.find(
      ({ classification_id }) => classification_id === e.target.value
    );
    console.log("respo", timings.timing);

    this.setState({ Timings: timings.timing, choosetime: true });
  }
  getGameList() {
    var that = this;
    API.post("MasterApi/academyList")
      .then(function (response) {
        console.log(response);
        that.setState({
          Academy: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        const result = that.state.Academy.find(
          ({ id }) => id === that.state.gameacademy
        );
        console.log("folafol", result.games);
        that.setState({
          Gamesdata: result.games,
          // tuition: result.tutions,
          // tuitiontimings: result.tutions.timing,
        });
        const result1 = that.state.Gamesdata.find(
          ({ game_id }) => game_id === that.state.game_id
        );
        console.log("classi", result1.classification);
        that.setState({
          selectedgame: result1,
          gameclassification: result1.classification,
        });

        // that.state.tuition.map((l, id) => {
        //   return that.setState({ tuitiontimings: l.timing });
        // });
        // console.log("l.tining", that.state.tuitiontimings);
      });
  }
  render() {
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> GAMING ACADEMY</h1>
          <p>Home | Student | Gaming Academy | Payment</p>
        </div>
        <div className="container" style={{ paddingTop: "30px" }}>
          <div className="row">
            <div className="col-md-6 offset-1">
              <h6 style={{ fontSize: "small" }}>Student Details</h6>
              <div className="row">
                <h6>{this.state.Profile.name}</h6>

                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.school}
                </p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.class}
                </p>
                {/* <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  Roll Number: 21
                </p> */}
              </div>

              <div className="row">
                <h6 style={{ fontSize: "small" }}>Selected Game</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.selectedgame.name}
                </p>
              </div>

              <div className="row">
                <h6 style={{ fontSize: "small" }}>DOB</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.dob}
                </p>
              </div>

              <div className="row">
                <h6 style={{ fontSize: "small" }}>Mobile Number</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.mobile}
                </p>
              </div>

              <div className="row">
                <h6 style={{ fontSize: "small" }}>Email Address</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.email}
                </p>
              </div>
              <div className="row">
                <h6 style={{ fontSize: "small" }}>Location</h6>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  {this.state.Profile.address}
                </p>
                {/* <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  Chinar Park , Rajarhat
                </p> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="row" style={{ paddingTop: "10px" }}>
                <h6 style={{ fontSize: "small" }}>Select Subscription</h6>
                {<span className="error">{this.state.classiError}</span>}
                {/* <div className="col-md-8">
                  <Form.Check
                    type="radio"
                    style={{ opacity: "0.6", fontSize: "smaller" }}
                    label="1 Month"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios4"
                  />
                </div>
                <div className="col-md-4">
                  <p style={{ fontSize: "small", opacity: "0.8" }}>299.00</p>
                  <p
                    style={{
                      fontSize: "smaller",
                      position: "relative",
                      bottom: "3vh",
                      opacity: "0.6",
                    }}
                  >
                    Per Month
                  </p>
                </div>*/}
              </div>
              <div className="row" style={{}}>
                {this.state.gameclassification.map((l, id) => {
                  return (
                    <div className="col-md-8" style={{ paddingBottom: "3vh" }}>
                      <Form.Check
                        value={l.classification_id}
                        onChange={(e) => {
                          this.gettiming(e, l);
                          // this.setState({ classificationid: e.target.value });
                        }}
                        type="radio"
                        style={{ opacity: "0.6", fontSize: "smaller" }}
                        label={l.fees}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios4"
                      />
                    </div>
                  );
                })}

                {/* <div className="col-md-4">
                  <p style={{ fontSize: "small", opacity: "0.8" }}>699.00</p>
                  <p
                    style={{
                      fontSize: "smaller",
                      position: "relative",
                      bottom: "3vh",
                      opacity: "0.6",
                    }}
                  >
                    Per Year
                  </p>
                </div> */}
                {/* <p style={{ fontSize: "small" }}>Terms & Conditions</p>
                <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                  Terms and condition Terms and condition Terms and condition
                  Terms and condition 
                </p>*/}
              </div>
              {this.state.choosetime ? (
                <div>
                  <h6 style={{ fontSize: "small" }}>Select Timing</h6>

                  {this.state.Timings.map((l, id) => {
                    return (
                      <div
                        className="col-md-8"
                        style={{ paddingBottom: "3vh" }}
                      >
                        <Form.Check
                          value={l.id}
                          onChange={(e) => {
                            this.setState({ timingid: e.target.value });
                            localStorage.setItem(
                              "gametimingID",
                              e.target.value
                            );
                          }}
                          type="radio"
                          style={{ opacity: "0.6", fontSize: "smaller" }}
                          label={`${l.from_time} - ${l.to_time}`}
                          name="formHorizontalRadios"
                          id="formHorizontalRadios4"
                        />
                        {/* <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          defaultValue="female"
                          name="radio-buttons-group"
                          onChange={(e) => {
                            // this.setState({ timingid: e.target.value });
                            alert("timing", e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value={l.id}
                            control={<Radio />}
                            label={l.from_time}
                          />
                        </RadioGroup>
                      </FormControl> */}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              {/* <div className="row" style={{ paddingTop: "10px" }}>
                <h6 style={{ fontSize: "small" }}>Select Payment Method</h6>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    style={{ opacity: "0.6" }}
                    label="Net Banking"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    style={{ opacity: "0.6" }}
                    type="radio"
                    label="Visa / Master"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  />
                  <Form.Check
                    style={{ opacity: "0.6" }}
                    type="radio"
                    label="Pay On Home"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios3"
                  />
                </Form.Group>
              </div> */}
              <div className="row" style={{ paddingTop: "10px" }}>
                {/* <h6 style={{ fontSize: "small" }}>Select Payment Method</h6>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    style={{ opacity: "0.6" }}
                    label="xxxxxxxxxxxx 3432"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                  />
                  <Form.Check
                    style={{ opacity: "0.6" }}
                    type="radio"
                    label="xxxxxxxxxxxx 1010"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  />
                </Form.Group> */}
                {/* <div className="row" style={{ paddingTop: "3vh" }}>
                  <div className="col-md-10">
                    <button
                      style={{ border: "none" }}
                      onClick={() => {
                        alert("You will be able to add card soon");
                      }}
                    >
                      <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                        <GrAddCircle /> Add New Card
                      </p>
                    </button>
                  </div>
                </div> */}
                <div className="col" style={{ paddingTop: "20px" }}>
                  <Button
                    style={{}}
                    onClick={() => this.takegameSubscription()}
                  >
                    {this.state.loading ? (
                      <span>
                        {" "}
                        <ClipLoader color="white" size={20} />
                        Please Wait{" "}
                      </span>
                    ) : (
                      <span>Pay Now</span>
                    )}
                  </Button>
                </div>
              </div>
              {this.state.success ? (
                <div
                  style={{
                    marginTop: "3vh",
                    color: "white",
                    backgroundColor: "lightgreen",
                  }}
                >
                  {this.state.submsg}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
