import React, { Component } from "react";
import { AiFillDelete } from "react-icons/ai";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  DialogContentText,
  TextareaAutosize,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from "../provider/useWindowSize";
import API from "../components/api";
import DeleteIcon from "@mui/icons-material/Delete";

class Notification extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>NOTIFICATION</h1>
          <p>Home | Student | Notification</p>
        </div>
        {/* <Container>
          <Grid container spacing={2} marginY={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                <Typography variant="h5" style={{ paddingLeft: "8px" }}>NEWS</Typography>
              </Box>
            </Grid>

          </Grid>
        </Container>
        <Container>
          <Grid container marginY={1}>
            <Grid item xs={12} sm={12} md={12}>
              <div
                style={{
                  backgroundColor: "rgb(24, 169, 218)",
                  height: "2px",
                  width: "15%"
                }}>
              </div>
            </Grid>
          </Grid>
        </Container> */}
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container marginY={1}>
            {/* {newsList.map((news, index) => */}
            <>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Typography variant="h5" style={{ paddingLeft: "8px" }}>
                  NOTIFICATIONS
                </Typography>
                <div
                  style={{
                    backgroundColor: "rgb(24, 169, 218)",
                    height: "2px",
                    width: "15%",
                  }}
                ></div>
                {/* <Box>
                  <Typography style={{ fontWeight: "bold" }}>{news?.date}</Typography>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>{news?.title}</Typography>
                  <Typography> {news?.description}</Typography>
                </Box> */}
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={3}>
                <Box marginY={3}>
                  No Notification Found
                  {/* <button
                      className={classes.tuitionTimeStatusButton}>
                      <DeleteIcon style={{ color: "white" }} />
                    </button> */}
                </Box>
              </Grid>
              {/* {index !== (Number(newsList.length) - 1) && <Divider style={{ marginBottom: "8px", marginTop: "8px" }} />} */}
            </>

            {/* )} */}
          </Grid>
        </Container>
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(Notification);
