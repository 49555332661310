import React, { Component } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillDelete } from "react-icons/ai";

import { Form, ProgressBar } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  MenuItem,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  CardContent,
  Card,
  Rating,
  TextareaAutosize,
  Tab,
  Tabs
} from '@mui/material'
import { withStyles } from '@mui/styles';
import API from '../components/api'
import EditIcon from '@mui/icons-material/Edit';

// const monthList=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]


class ProgressReport extends Component {
  state = {
    totalDayOfThisMonth: "",
    totalMonth: 12,
    monthList: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    totalYear: 0,
    startYear: 2001,
    monthlyReportBy: "none",
    yearlyReportBy: "none",
    weeklyReportBy: "none",
    year: "",
    month1: "",
    year2: "",
    value: 0,
    gameValue: 0,
    scienceValue: 0,
    timeBlockValue: 0,

    weekly: [],
    monthly: [],
    yearly: [],
    totalData: [],
    totalDataBackup: [],
    reportBy: "all",
    gameWeekly: [],
    gameMonthly: [],
    gameYearly: [],
    gameTotalData: [],
    gameTotalDataBackup: [],
    gameReportBy: "all",
    gameWeeklyReportBy: "none",
    gameMonthlyReportBy: "none",
    gameYearlyReportBy: "none",
    scienceWeekly: [],
    scienceMonthly: [],
    scienceYearly: [],
    scienceTotalData: [],
    scienceTotalDataBackup: [],
    scienceReportBy: "all",
    scienceWeeklyReportBy: "none",
    scienceMonthlyReportBy: "none",
    scienceYearlyReportBy: "none",
    timeBlockSearchDate: "",
    timeBlock: [],
    timeBlockMonthlyReportBy: "none",
    timeBlockYearlyReportBy: "none",
    timeBlockWeeklyReportBy: "none",
    timeBlockWeekly: "",
    timeBlockMonthly: "",
    timeBlockYearly: "",
    schoolResult: {
      grade: "",
      description: ""
    },
    schoolReportVisible: false,
    openEditSchoolResult: false,
    schoolResultDescription: "",
    schoolResultGrade: "",
    student_id: "",
    school_id: "",
    action: "",
    onLoadPage: true,
    tuition: true,
    gaming: false,
    science: false,
    timeblock: false,
    report: false,
    message: false,
    page: 1,
  };
  componentDidMount() {

    this.getReporyOnLoad("byDefault")

    this.getSchoolReport()


  }

  getReporyOnLoad() {

    let today = new Date()
    let year1 = today.getFullYear()
    let mon = today.getMonth()
    let actualMonth = Number(mon) + 1
    let mon1 = year1 + " " + actualMonth
    let dat = today.getDate()


    console.log("getFullYear", year1, mon, dat);
    let total = Number(year1) - 2000
    console.log("getTotalFullYear", total);
    this.setState({
      totalYear: total,
      year: year1,
      month1: actualMonth,
      year2: year1,
      monthlyReportBy: actualMonth,
      gameMonthlyReportBy: actualMonth,
      scienceMonthlyReportBy: actualMonth,
      timeBlockMonthlyReportBy: actualMonth,
      yearlyReportBy: year1,
      gameYearlyReportBy: year1,
      scienceYearlyReportBy: year1,
      timeBlockYearlyReportBy: year1
    })

    let payload = {
      student_id: "",
      month: mon1,
      year: year1
    }





    console.log("prop12345", this.props);

    if (this.props.from === 'Observer') {
      payload.student_id = this.props.student
    } else {
      payload.student_id = localStorage.getItem("userID")
    }
    // if (this.props.from === 'Observer') {
    API.post("MasterApi/tutionProgressReportCard", payload).then(resp => {
      console.log("tutionProgressReportCard", resp);
      if (resp.data.result === 1) {

        this.setState({
          weekly: resp.data.weekly,
          monthly: resp.data.monthly,
          yearly: resp.data.yearly
        })

        // this.setState({
        //   totalData: resp.data.weekly,
        //   totalDataBackup: resp.data.weekly
        // })

      }
      console.log("tutionProgressReportCard1234", this.state.totalData);

    })

    API.post("MasterApi/gameAcademyReportCard", payload).then(resp => {
      console.log("tutionProgressReportCard", resp);
      if (resp.data.result === 1) {
        // this.setState({
        //   gameTotalData: resp.data.weekly,
        //   gameTotalDataBackup: resp.data.weekly
        // })

        this.setState({
          gameWeekly: resp.data.weekly,
          gameMonthly: resp.data.monthly,
          gameYearly: resp.data.yearly
        })

      }
      console.log("gameAcademyReportCard1234", this.state.gameTotalData);

    })

    API.post("MasterApi/scienceFairReportCard", payload).then(resp => {
      console.log("tutionProgressReportCard", resp);
      if (resp.data.result === 1) {
        // this.setState({
        //   scienceTotalData: resp.data.weekly,
        //   scienceTotalDataBackup: resp.data.weekly
        // })
        this.setState({
          scienceWeekly: resp.data.weekly,
          scienceMonthly: resp.data.monthly,
          scienceYearly: resp.data.yearly
        })

      }
      console.log("scienceFairReportCard1234", this.state.scienceTotalData);
    })

    API.post("MasterApi/timeBlockingReportCard", payload).then(resp => {
      console.log("tutionProgressReportCard", resp);
      if (resp.data.result === 1) {
        this.setState({
          onLoadPage: false,
          timeBlockWeekly: resp.data.weekly,
          timeBlockMonthly: resp.data.monthly,
          timeBlockYearly: resp.data.yearly
        })

      }
      console.log("timeBlockingReportCard1234", this.state.timeBlock);

    })
  }
  getTuitionReportOnChange(from, value) {
    let payload;

    if (from === 'onMonthChange') {
      payload = {
        student_id: "",
        month: this.state.year + " " + value,
        year: this.state.year
      }
    } else if (from === 'onYearChange') {
      payload = {
        student_id: "",
        month: value + " " + this.state.month1,
        year: value
      }
    }

    if (this.props.from === 'Observer') {
      payload.student_id = this.props.student
    } else {
      payload.student_id = localStorage.getItem("userID")
    }

    API.post("MasterApi/tutionProgressReportCard", payload).then(resp => {
      console.log("tutionProgressReportCard", resp);
      if (resp.data.result === 1) {
        // if (from === 'onMonthChange') {
        //   this.setState({
        //     totalData: resp.data.monthly,
        //     totalDataBackup: resp.data.monthly
        //   })
        // } else if (from === 'onYearChange') {
        //   this.setState({
        //     totalData: resp.data.yearly,
        //     totalDataBackup: resp.data.yearly
        //   })
        // }

        // this.setState({
        //   totalDataBackup: resp.data.weekly
        // })

        this.setState({
          weekly: resp.data.weekly,
          monthly: resp.data.monthly,
          yearly: resp.data.yearly
        })


      } else {
        this.setState({
          weekly: [],
          monthly: [],
          yearly: []
        })
      }
      console.log("tutionProgressReportCard1234", this.state.totalData);

    })
  }

  getGameReportOnChange(from, value) {
    let payload;

    if (from === 'onMonthChange') {
      payload = {
        student_id: "",
        month: this.state.year + " " + value,
        year: this.state.year
      }
    } else if (from === 'onYearChange') {
      payload = {
        student_id: "",
        month: value + " " + this.state.month1,
        year: value
      }
    }

    if (this.props.from === 'Observer') {
      payload.student_id = this.props.student
    } else {
      payload.student_id = localStorage.getItem("userID")
    }

    API.post("MasterApi/gameAcademyReportCard", payload).then(resp => {

      if (resp.data.result === 1) {
        console.log("gameAcademyReportCard", resp.data);
        // if (from === 'onMonthChange') {
        //   this.setState({
        //     gameTotalData: resp.data.monthly,
        //     gameTotalDataBackup: resp.data.monthly
        //   })
        // } else if (from === 'onYearChange') {
        //   this.setState({
        //     gameTotalData: resp.data.yearly,
        //     gameTotalDataBackup: resp.data.yearly
        //   })
        // }
        // this.setState({
        //   gameTotalDataBackup: resp.data.weekly
        // })
        this.setState({
          gameWeekly: resp.data.weekly,
          gameMonthly: resp.data.monthly,
          gameYearly: resp.data.yearly
        })

      } else {
        this.setState({
          gameWeekly: [],
          gameMonthly: [],
          gameYearly: []
        })
      }
      console.log("tutionProgressReportCard1234", this.state.totalData);

    })
  }

  getScienceReportOnChange(from, value) {
    let payload;

    if (from === 'onMonthChange') {
      payload = {
        student_id: "",
        month: this.state.year + " " + value,
        year: this.state.year
      }
    } else if (from === 'onYearChange') {
      payload = {
        student_id: "",
        month: value + " " + this.state.month1,
        year: value
      }
    }

    if (this.props.from === 'Observer') {
      payload.student_id = this.props.student
    } else {
      payload.student_id = localStorage.getItem("userID")
    }

    API.post("MasterApi/scienceFairReportCard", payload).then(resp => {
      console.log("gameAcademyReportCard", resp);
      if (resp.data.result === 1) {
        this.setState({
          scienceWeekly: resp.data.weekly,
          scienceMonthly: resp.data.monthly,
          scienceYearly: resp.data.yearly
        })

      } else {
        this.setState({
          scienceWeekly: [],
          scienceMonthly: [],
          scienceYearly: []
        })
      }
      console.log("tutionProgressReportCard1234", this.state.totalData);

    })
  }



  getSchoolReport() {
    let schoolFromObserver = localStorage.getItem("school_id")
    let payload = {
      school_id: 1,
      student_id: 1,
      action: "view"
    }
    if (this.props.from === 'Observer') {
      payload.student_id = this.props.student
      payload.school_id = localStorage.getItem("school_id")
    } else {
      payload.student_id = localStorage.getItem("userID")
      payload.school_id = localStorage.getItem("school_id(student)")
    }

    API.post("SchoolApi/schoolReportCard", payload).then(resp => {
      console.log("tutionProgressReportCard", resp);
      if (resp.data.result === 1) {
        if (resp.data.message === 'No Report card found.') {
          this.setState({ schoolReportVisible: false })
        } else {
          this.setState({ schoolReportVisible: true })
          this.setState({
            schoolResult: resp.data.data
          })
        }


      }
      console.log("SchoolApi/schoolReportCard", this.state.schoolResult);

    })

  }
  getCategory(e) {

    const { name, value } = e.target
    console.log("getCategory", e.target);

    if (name === 'weekly') {
      this.setState({ weeklyReportBy: value })
      this.setState({ totalData: this.state.totalDataBackup })

    } else if (name === 'monthly') {

      this.setState({ monthlyReportBy: value })
      this.getTuitionReportOnChange("onMonthChange", value)

    } else if (name === 'yearly') {

      this.setState({ yearlyReportBy: value })
      this.getTuitionReportOnChange("onYearChange", value)

    } else {
      this.setState({ totalData: this.state.totalDataBackup })
    }
  }

  getGameCategory(e) {
    const { name, value } = e.target
    console.log("getGameCategory", e);
    if (name === 'weekly') {
      this.setState({ gameWeeklyReportBy: value })
      this.setState({ gameTotalData: this.state.gameTotalDataBackup })

    } else if (name === 'monthly') {

      this.setState({ gameMonthlyReportBy: value })
      this.getGameReportOnChange("onMonthChange", value)

    } else if (name === 'yearly') {

      this.setState({ gameYearlyReportBy: value })
      this.getGameReportOnChange("onYearChange", value)

    } else {
      this.setState({ gameTotalData: this.state.gameTotalDataBackup })
    }
  }

  getScienceCategory(e) {
    const { name, value } = e.target
    console.log("getScienceCategory", e);
    if (name === 'weekly') {
      this.setState({ scienceWeeklyReportBy: value })

      this.setState({ scienceTotalData: this.state.scienceTotalDataBackup })

    } else if (name === 'monthly') {

      this.setState({ scienceMonthlyReportBy: value })
      this.getScienceReportOnChange("onMonthChange", value)

    } else if (name === 'yearly') {

      this.setState({ scienceYearlyReportBy: value })
      this.getScienceReportOnChange("onYearChange", value)

    } else {
      this.setState({ scienceTotalData: this.state.scienceTotalDataBackup })
    }
  }

  getTimeBlockCategory(e) {
    const { name, value } = e.target
    console.log("getScienceCategory", e);
    if (name === 'weekly') {
      this.setState({ timeBlockWeeklyReportBy: value })

      this.setState({ scienceTotalData: this.state.scienceTotalDataBackup })

    } else if (name === 'monthly') {

      this.setState({ timeBlockMonthlyReportBy: value })
      this.getTimeBlockReportOnChange("onMonthChange", value)

    } else if (name === 'yearly') {

      this.setState({ timeBlockYearlyReportBy: value })
      this.getTimeBlockReportOnChange("onYearChange", value)

    } else {
      // this.setState({ scienceTotalData: this.state.scienceTotalDataBackup })
    }
  }

  getTimeBlockReportOnChange(from, value) {
    let payload;

    if (from === 'onMonthChange') {
      payload = {
        student_id: "",
        month: this.state.year + " " + value,
        year: this.state.year
      }
    } else if (from === 'onYearChange') {
      payload = {
        student_id: "",
        month: value + " " + this.state.month1,
        year: value
      }
    }

    if (this.props.from === 'Observer') {
      payload.student_id = this.props.student
    } else {
      payload.student_id = localStorage.getItem("userID")
    }

    API.post("MasterApi/timeBlockingReportCard", payload).then(resp => {
      console.log("timeBlockingReportCard1234", resp.data);
      if (resp.data.result === 1) {
        this.setState({
          onLoadPage: false,
          timeBlockWeekly: resp.data.weekly,
          timeBlockMonthly: resp.data.monthly,
          timeBlockYearly: resp.data.yearly
        })

      } else {
        this.setState({
          timeBlockWeekly: [],
          timeBlockMonthly: [],
          timeBlockYearly: []
        })
      }

    })
    // }
  }

  getSchoolResultEdit() {
    this.setState({
      openEditSchoolResult: true,
      school_id: 1,
      student_id: 1,
      action: "update",
      schoolResultGrade: this.state.schoolResult.grade,
      schoolResultDescription: this.state.schoolResult.description
    })
  }

  getReport(e) {
    const { name, value } = e.target
    if (name === 'grade') {
      this.setState({ schoolResultGrade: value })
    } else if (name === 'description') {
      this.setState({ schoolResultDescription: value })
    }
  }

  updateReport() {
    let payload = {
      school_id: this.state.school_id,
      student_id: this.state.student_id,
      action: this.state.action,
      grade: this.state.schoolResultGrade,
      description: this.state.schoolResultDescription
    }
    API.post("SchoolApi/schoolReportCard", payload).then(resp => {
      console.log("tutionProgressReportCard", resp);
      if (resp.data.result === 1) {
        this.getSchoolReport()
        this.setState({
          openEditSchoolResult: false,
          school_id: "",
          student_id: "",
          action: "",
          schoolResultGrade: "",
          schoolResultDescription: ""
        })


      }
      console.log("SchoolApi/schoolReportCard", this.state.schoolResult);

    })
  }



  handleChange(event, newValue) {
    console.log("newValue12345", event.target.tabIndex);
    this.setState({ value: event.target.tabIndex })
  };

  handleChangeForGame(event, newValue) {
    console.log("newValue123456789", event.target.tabIndex);
    this.setState({ gameValue: event.target.tabIndex })
  };
  handleChangeForScience(event, newValue) {
    console.log("newValue12345", event.target.tabIndex);
    this.setState({ scienceValue: event.target.tabIndex })
  };
  handleChangeForTimeBlock(event, newValue) {
    console.log("newValue12345", event.target.tabIndex);
    this.setState({ timeBlockValue: event.target.tabIndex })
  };

  render() {
    const { classes } = this.props

    const TabPanel = (props) => {
      const { children, value, index, ...other } = props;
      console.log("propsTab", props)
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={1}>
              {children}
            </Box>
          )}
        </div>
      );
    }
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>PROGRESS REPORT</h1>
          <p>Home | Student | Progress Report</p>
        </div>
        <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  onClick={() => {
                    this.setState({
                      tuition: true,
                      gaming: false,
                      science: false,
                      timeblock: false,
                      report: false,
                      message: false,
                      page: 1,
                    });
                  }}
                  className={this.state.tuition ? classes.cliked : classes.notclicked}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>TUITION PROGRESS REPORT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      tuition: false,
                      gaming: true,
                      science: false,
                      timeblock: false,
                      report: false,
                      message: false,
                      page: 2,
                    });
                  }}
                  className={this.state.gaming ? classes.cliked : classes.notclicked}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>GAMING ACADEMY REPORT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      tuition: false,
                      gaming: false,
                      science: true,
                      timeblock: false,
                      report: false,
                      message: false,
                      page: 3,
                    });
                  }}
                  className={this.state.science ? classes.cliked : classes.notclicked}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>SCIENCE FAIR REPORT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      tuition: false,
                      gaming: false,
                      science: false,
                      timeblock: true,
                      report: false,
                      message: false,
                      page: 4,
                    });
                  }}
                  className={this.state.timeblock ? classes.cliked : classes.notclicked}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>TIME BLOCKING REPORT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      tuition: false,
                      gaming: false,
                      science: false,
                      timeblock: false,
                      report: true,
                      message: false,
                      page: 5,
                    });
                  }}
                  className={this.state.report ? classes.cliked : classes.notclicked}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>SCHOOL REPORT</Typography>
                  </Box>
                </Box>
                <Box
                  marginTop={1}
                  display="flex"
                  onClick={() => {
                    this.setState({
                      tuition: false,
                      gaming: false,
                      science: false,
                      timeblock: false,
                      report: false,
                      message: true,
                      page: 6,
                    });
                  }}
                  className={this.state.message ? classes.cliked : classes.notclicked}
                >
                  <Box marginX={4} style={{ alignSelf: "center" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center" }}>
                    <Typography>MESSAGE</Typography>
                  </Box>
                </Box>

              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              {this.state.page === 1 &&
                <>


                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>TUITION PROGRESS REPORT</Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="weekly"
                          value={this.state.weeklyReportBy}
                          onChange={(e) => this.getCategory(e)}
                          input={<OutlinedInput />}

                        ><MenuItem value="none">Select Weekly</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                        </Select>
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="monthly"
                          value={this.state.monthlyReportBy}
                          onChange={(e) => this.getCategory(e)}
                          input={<OutlinedInput />}

                        >
                          <MenuItem value="none">Select Month</MenuItem>
                          {this.state.monthList.map((mont, i) => {
                            return <MenuItem value={i + 1} key={i}>{mont}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="yearly"
                          value={this.state.yearlyReportBy}
                          onChange={(e) => this.getCategory(e)}

                          input={<OutlinedInput />}

                        > <MenuItem value="none">Select Year</MenuItem>
                          {Array.from(Array(this.state.totalYear), (e, i) => {
                            return <MenuItem value={this.state.startYear + i} key={i}>{this.state.startYear + i}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px"
                          }}>
                        </div>
                      </Grid>
                    </Grid>


                    <Grid container spacing={2}>

                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          <Tabs value={this.state.value} onChange={(e) => this.handleChange(e)} style={{ backgroundColor: "lightgrey" }} TabIndicatorProps={{
                            style: {
                              display: "none"
                            },
                          }}>
                            <Tab label="Weekly" tabIndex={0} style={{ backgroundColor: this.state.value === 0 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Monthly" tabIndex={1} style={{ backgroundColor: this.state.value === 1 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Yearly" tabIndex={2} style={{ backgroundColor: this.state.value === 2 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                          </Tabs>
                        </Box>
                        <TabPanel value={this.state.value} index={0} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Subject
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.weekly.length > 0 &&
                              <TableBody>
                                {this.state.weekly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.subject}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.weekly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Subject
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.monthly.length > 0 &&
                              <TableBody>
                                {this.state.monthly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.subject}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.monthly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={2} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Subject
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.yearly.length > 0 &&
                              <TableBody>
                                {this.state.yearly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.subject}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.yearly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              }

              {this.state.page === 2 &&
                <>

                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>GAMING ACADEMY REPORT</Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="weekly"
                          value={this.state.gameWeeklyReportBy}
                          onChange={(e) => this.getGameCategory(e)}
                          input={<OutlinedInput />}

                        ><MenuItem value="none">Select Weekly</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                        </Select>
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="monthly"
                          value={this.state.gameMonthlyReportBy}
                          onChange={(e) => this.getGameCategory(e)}
                          input={<OutlinedInput />}

                        >
                          <MenuItem value="none">Select Month</MenuItem>
                          {this.state.monthList.map((mont, i) => {
                            return <MenuItem value={i + 1} key={i}>{mont}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="yearly"
                          value={this.state.gameYearlyReportBy}
                          onChange={(e) => this.getGameCategory(e)}
                          input={<OutlinedInput />}

                        > <MenuItem value="none">Select Year</MenuItem>
                          {Array.from(Array(this.state.totalYear), (e, i) => {
                            return <MenuItem value={this.state.startYear + i} key={i}>{this.state.startYear + i}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px"
                          }}>
                        </div>
                      </Grid>
                    </Grid>


                    <Grid container spacing={2}>

                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          <Tabs value={this.state.gameValue} onChange={(e) => this.handleChangeForGame(e)} style={{ backgroundColor: "lightgrey" }} TabIndicatorProps={{
                            style: {
                              display: "none"
                            },
                          }}>
                            <Tab label="Weekly" tabIndex={0} style={{ backgroundColor: this.state.gameValue === 0 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Monthly" tabIndex={1} style={{ backgroundColor: this.state.gameValue === 1 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Yearly" tabIndex={2} style={{ backgroundColor: this.state.gameValue === 2 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                          </Tabs>
                        </Box>
                        <TabPanel value={this.state.gameValue} index={0} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Game
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.gameWeekly.length > 0 &&
                              <TableBody>
                                {this.state.gameWeekly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.game}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.gameWeekly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                        <TabPanel value={this.state.gameValue} index={1} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Game
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.gameMonthly.length > 0 &&
                              <TableBody>
                                {this.state.gameMonthly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.game}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.gameMonthly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                        <TabPanel value={this.state.gameValue} index={2} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Game
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.gameYearly.length > 0 &&
                              <TableBody>
                                {this.state.gameYearly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.game}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.gameYearly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              }

              {this.state.page === 3 &&
                <>

                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>SCIENCE FAIR REPORT</Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="weekly"
                          value={this.state.scienceWeeklyReportBy}
                          onChange={(e) => this.getScienceCategory(e)}
                          input={<OutlinedInput />}

                        ><MenuItem value="none">Select Weekly</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                        </Select>
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="monthly"
                          value={this.state.scienceMonthlyReportBy}
                          onChange={(e) => this.getScienceCategory(e)}
                          input={<OutlinedInput />}

                        >
                          <MenuItem value="none">Select Month</MenuItem>
                          {this.state.monthList.map((mont, i) => {
                            return <MenuItem value={i + 1} key={i}>{mont}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="yearly"
                          value={this.state.scienceYearlyReportBy}
                          onChange={(e) => this.getScienceCategory(e)}
                          input={<OutlinedInput />}

                        > <MenuItem value="none">Select Year</MenuItem>
                          {Array.from(Array(this.state.totalYear), (e, i) => {
                            return <MenuItem value={this.state.startYear + i} key={i}>{this.state.startYear + i}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px"
                          }}>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          <Tabs value={this.state.scienceValue} onChange={(e) => this.handleChangeForScience(e)} style={{ backgroundColor: "lightgrey" }} TabIndicatorProps={{
                            style: {
                              display: "none"
                            },
                          }}>
                            <Tab label="Weekly" tabIndex={0} style={{ backgroundColor: this.state.scienceValue === 0 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Monthly" tabIndex={1} style={{ backgroundColor: this.state.scienceValue === 1 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Yearly" tabIndex={2} style={{ backgroundColor: this.state.scienceValue === 2 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                          </Tabs>
                        </Box>
                        <TabPanel value={this.state.scienceValue} index={0} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Invention
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.scienceWeekly.length > 0 &&
                              <TableBody>
                                {this.state.scienceWeekly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.invention}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.scienceWeekly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                        <TabPanel value={this.state.scienceValue} index={1} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Invention
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.scienceMonthly.length > 0 &&
                              <TableBody>
                                {this.state.scienceMonthly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.invention}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.scienceMonthly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                        <TabPanel value={this.state.scienceValue} index={2} style={{ height: "500px", overflow: "auto" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead >
                              <TableRow >
                                <TableCell style={{ fontWeight: "bold" }}>
                                  Invention
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Marks
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                                  Exam Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {this.state.scienceYearly.length > 0 &&
                              <TableBody>
                                {this.state.scienceYearly.map(rep =>
                                  <TableRow hover >
                                    <TableCell >
                                      {rep?.invention}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.marks}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.grade}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      {rep?.exam_date}
                                    </TableCell>

                                  </TableRow>
                                )}
                              </TableBody>}
                            {this.state.scienceYearly.length === 0 &&
                              <TableBody>
                                <Typography>Data Not Found</Typography>
                              </TableBody>}
                          </Table>
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              }

              {this.state.page === 4 &&
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>TIME BLOCKING REPORT</Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          name="date"
                          type="date"
                          variant="outlined"
                          value={this.state.timeBlockSearchDate}
                          onChange={(e) => this.getDateForTimeBlock(e)}
                          fullWidth
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="monthly"
                          value={this.state.timeBlockMonthlyReportBy}
                          onChange={(e) => this.getTimeBlockCategory(e)}
                          input={<OutlinedInput />}

                        >
                          <MenuItem value="none">Select Month</MenuItem>
                          {this.state.monthList.map((mont, i) => {
                            return <MenuItem value={i + 1} key={i}>{mont}</MenuItem>
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="yearly"
                          value={this.state.timeBlockYearlyReportBy}
                          onChange={(e) => this.getTimeBlockCategory(e)}
                          input={<OutlinedInput />}

                        > <MenuItem value="none">Select Year</MenuItem>
                          {Array.from(Array(this.state.totalYear), (e, i) => {
                            return <MenuItem value={this.state.startYear + i} key={i}>{this.state.startYear + i}</MenuItem>
                          })}
                        </Select>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px"
                          }}>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          <Tabs value={this.state.timeBlockValue} onChange={(e) => this.handleChangeForTimeBlock(e)} style={{ backgroundColor: "lightgrey" }} TabIndicatorProps={{
                            style: {
                              display: "none"
                            },
                          }}>
                            <Tab label="Weekly" tabIndex={0} style={{ backgroundColor: this.state.timeBlockValue === 0 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Monthly" tabIndex={1} style={{ backgroundColor: this.state.timeBlockValue === 1 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                            <Tab label="Yearly" tabIndex={2} style={{ backgroundColor: this.state.timeBlockValue === 2 ? "darkgrey" : "", color: "black", textTransform: "capitalize", fontSize: "15px" }} />
                          </Tabs>
                        </Box>
                        <TabPanel value={this.state.timeBlockValue} index={0} style={{ height: "500px", overflow: "auto" }}>
                          <ProgressBar
                            variant="success"
                            style={{
                              height: "40px",
                            }}
                            bar
                            key={1}
                            label={`${this.state.timeBlockWeekly}%`}
                            max={100}
                            now={this.state.timeBlockWeekly}
                          />
                        </TabPanel>
                        <TabPanel value={this.state.timeBlockValue} index={1} style={{ height: "500px", overflow: "auto" }}>
                          <ProgressBar
                            variant="success"
                            style={{
                              height: "40px",
                            }}
                            bar
                            key={1}
                            label={`${this.state.timeBlockMonthly}%`}
                            max={100}
                            now={this.state.timeBlockMonthly}
                          />
                        </TabPanel>
                        <TabPanel value={this.state.timeBlockValue} index={2} style={{ height: "500px", overflow: "auto" }}>
                          <ProgressBar
                            variant="success"
                            style={{
                              height: "40px",
                            }}
                            bar
                            key={1}
                            label={`${this.state.timeBlockYearly}%`}
                            max={100}
                            now={this.state.timeBlockYearly}
                          />
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Container>
                </>
              }

              {this.state.page === 5 &&
                <>
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography>SCHOOL REPORT</Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          name="date"
                          type="date"
                          variant="outlined"
                          onChange={(e) => this.getDateForTimeBlock(e)}
                          fullWidth
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "rgb(24, 169, 218)",
                            height: "2px",
                            width: "32%",
                            marginBottom: "12px"
                          }}>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                  {this.state.schoolReportVisible ?
                    <Container>
                      <Grid container spacing={2}>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          <Box display="flex" flexDirection="column">
                            <Box><Typography variant="h6" style={{ fontWeight: "bold" }}>{this.state.schoolResult?.grade}</Typography></Box>
                            <Box>
                              <Typography >{this.state.schoolResult?.description}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                        {this.props.from === 'Observer' &&
                          <Grid item xs={1} sm={1} md={1} lg={1}>
                            <Box>
                              <button
                                onClick={() => this.getSchoolResultEdit()}
                                className={classes.tuitionTimeStatusButton}>
                                <EditIcon style={{ color: "white" }} />
                              </button>
                            </Box>
                          </Grid>}
                      </Grid>
                    </Container>
                    :
                    <Container>
                      <Grid container spacing={2}>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          <Typography>Report Not Found</Typography>
                        </Grid>
                      </Grid>
                    </Container>
                  }
                </>
              }

            </Grid>
          </Grid>
          <Dialog
            open={this.state.openEditSchoolResult}
            onClose={() => this.setState({ openEditSchoolResult: false })}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Edit Report</DialogTitle>
            <DialogContent style={{ paddingTop: "12px", paddingBottom: "12px" }}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="grade"
                      value={this.state.schoolResultGrade}
                      input={<OutlinedInput />}
                      onChange={(e) => this.getReport(e)}

                    >

                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextareaAutosize
                      style={{ width: "100%" }}
                      name="description"
                      minRows={4}
                      maxRows={4}
                      maxLength={100}
                      value={this.state.schoolResultDescription}
                      onChange={(e) => this.getReport(e)}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" style={{ backgroundColor: "deepskyblue" }} onClick={() => this.updateReport()}>Update</Button>
              <Button variant="text" onClick={() => this.setState({ openEditSchoolResult: false })}>Cancel</Button>

            </DialogActions>
          </Dialog>

        </Container>

      </>
    );
  }
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white"
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px"
  },
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(ProgressReport)
