import React, { Component } from "react";
import ReactPlayer from "react-player";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FloatingLabel, Form } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";
// import { Router, Link } from "@reach/router"
import API from "./api";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { withStyles } from "@mui/styles";

class MotivationalVideoDetail extends Component {
  state = {
    file: "",
    videoName: "",
    CodingVideos: [],
  };
  componentDidMount() {
    let video = JSON.parse(localStorage.getItem("motivatevdo"));
    this.setState({
      file: video?.file,
      videoName: video?.name,
    });
    this.getcodingVideos();
  }
  getplayablevideoid(l) {
    let videofile = l.file;
    console.log("vfile", videofile);
    this.setState({ file: videofile });
  }
  getcodingVideos() {
    var that = this;
    let user = localStorage.getItem("userID");
    API.post("StudentApi/student_home", { user_id: user })
      .then(function (response) {
        console.log(response.data.innovativeVideos);
        that.setState({
          CodingVideos: response.data.motivationalVideos,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }

  setOpenedVideo(video) {
    this.setState({
      file: video?.file,
      videoName: video?.name,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>MOTIVATION VIDEOS</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              {" "}
              Student
            </Link>{" "}
            |
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/student/motivationalvideo"
            >
              {" "}
              Motivational Videos
            </Link>{" "}
            | videoname
          </p>
        </div>

        <Container>
          <Grid container spacing={2} marginY={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box display="flex" flexDirection="column">
                <Box
                  className={classes.demoPlayerBox}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {this.state.file ? (
                    <ReactPlayer
                      url={this.state.file}
                      playing="true"
                      width="100%"
                      controls
                      height="100%"
                      loop="true"
                      muted="false"
                    />
                  ) : (
                    <Box>
                      <Typography>Video Not Found</Typography>
                    </Box>
                  )}
                </Box>
                <Box marginY={2}>
                  <Typography variant="h6">{this.state.videoName}</Typography>
                </Box>
                {/* <Box display="flex" padding={2} style={{ backgroundColor: "lightgray" }}>
                  <Box style={{ width: "50%" }}>
                    <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>Posted On</Typography>
                    <Typography paddingY={1}>9th August,2021</Typography>
                  </Box>
                  <Box style={{ width: "50%" }} >
                    <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>Duration</Typography>
                    <Typography paddingY={1}>8 Min</Typography>
                  </Box>
                </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box display="flex" flexDirection="column">
                <Box
                  style={{
                    width: "100%",
                    height: "71px",
                    backgroundColor: "deepskyblue",
                  }}
                  display="flex"
                >
                  <Box
                    marginX={4}
                    style={{ alignSelf: "center", color: "white" }}
                  >
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>SIMILAR VIDEOS</Typography>
                  </Box>
                </Box>
                <Box>
                  <Table stickyHeader aria-label="sticky table">
                    {this.state.CodingVideos.length > 0 && (
                      <TableBody>
                        {this.state.CodingVideos.map((video, index) => (
                          <>
                            <TableRow
                              hover
                              onClick={() => this.setOpenedVideo(video)}
                            >
                              <Box display="flex">
                                <Box style={{ width: "40%" }} padding={1}>
                                  <ReactPlayer
                                    url={video?.file}
                                    width="100%"
                                    playing={false}
                                    height="130px"
                                    loop="true"
                                    muted="true"
                                  />
                                </Box>
                                <Box
                                  style={{ width: "60%", alignSelf: "center" }}
                                  padding={2}
                                >
                                  <Typography>{video?.name}</Typography>
                                  <Typography>9th August,2021</Typography>
                                </Box>
                              </Box>
                            </TableRow>
                            {index !==
                              Number(this.state.CodingVideos.length) - 1 && (
                              <Divider
                                style={{
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                }}
                              />
                            )}
                          </>
                        ))}
                      </TableBody>
                    )}
                    {this.state.CodingVideos.length === 0 && (
                      <TableBody>
                        <Box display="flex" justifyContent="center">
                          <Box padding={1}>
                            <Typography>Video Not Found</Typography>
                          </Box>
                        </Box>
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  demoPlayerBox: {
    width: "100%%",
    height: "400px",
    objectFit: "cover",
    // border: "1px solid black",
    // borderRadius: "1px",
  },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(MotivationalVideoDetail);
