import react, { Component, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
// import { Router, Link } from "@reach/router"
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DownloadIcon from "@mui/icons-material/Download";
import ReactPlayer from "react-player";
import API from "../../components/api";
import PDFIcon from "../../assets/img/pdf-icon-sermap.png";

const Teacherprofile = ({ classes }) => {
  const [profile, setProfile] = useState("");
  const [editProfile, setEditProfile] = useState("");

  const [profileSave, setProfileSave] = useState(true);
  const [educationSave, setEducationSave] = useState(true);
  const [bankSave, setBankSave] = useState(true);
  const [loginSave, setLoginSave] = useState(true);

  const [personalDialogOpen, setPersonalDialogOpen] = useState(false);
  const [educationDialogOpen, setEducationDialogOpen] = useState(false);
  const [bankDialogOpen, setBankDialogOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [accountError, setAccountError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [teacherProfile, setTeacherProfile] = useState({
    user_id: "",
    name: "",
    fatherName: "",
    dob: "",
    mobile: "",
    email: "",
    qualification: "",
    experience: "",
    address: "",
    photo: "",
    school: "",
    university: "",
    schoolCertificate: "",
    universityCertificate: "",
    bioData: "",
    demoClass: "",
    handWriting: "",
    bank: "",
    ifsc: "",
    account: "",
    userName: "",
    password: "",
  });
  const [teacherProfilePhoto, setTeacherProfilePhoto] = useState("");
  const [teacherProfileSchoolCertificate, setTeacherProfileSchoolCertificate] =
    useState("");
  const [
    teacherProfileUniversityCertificate,
    setTeacherProfileUniversityCertificate,
  ] = useState("");
  const [teacherProfileBiodata, setTeacherProfileBiodata] = useState("");
  const [teacherProfileDemoClass, setTeacherProfileDemoClass] = useState("");
  const [teacherProfileHandwritting, setTeacherProfileHandwritting] =
    useState("");

  const [teacherProfilePhoto1, setTeacherProfilePhoto1] = useState("");
  const [
    teacherProfileSchoolCertificate1,
    setTeacherProfileSchoolCertificate1,
  ] = useState("");
  const [
    teacherProfileUniversityCertificate1,
    setTeacherProfileUniversityCertificate1,
  ] = useState("");
  const [teacherProfileBiodata1, setTeacherProfileBiodata1] = useState("");
  const [teacherProfileDemoClass1, setTeacherProfileDemoClass1] = useState("");
  const [teacherProfileHandwritting1, setTeacherProfileHandwritting1] =
    useState("");

  // const[profile,setProfile]=useState("")
  // let payload = {
  //   "user_id": 1
  // }
  const headers = {
    "Content-Type": "application/json",
    Source: "web",
  };
  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (
      teacherProfile.name &&
      teacherProfile.fatherName &&
      teacherProfile.dob &&
      teacherProfile.email &&
      teacherProfile.mobile &&
      teacherProfile.qualification &&
      teacherProfile.experience &&
      teacherProfile.address
    ) {
      if (
        /.+@.+\..+/.test(teacherProfile.email) &&
        teacherProfile.mobile.length === 10
      ) {
        setProfileSave(true);
        setMobileError("");
        setEmailError("");
      } else {
        if (!/.+@.+\..+/.test(teacherProfile.email)) {
          setProfileSave(false);
          setEmailError("Email must be valid");
        } else {
          setProfileSave(false);
          setEmailError("");
        }

        if (
          teacherProfile.mobile.length < 10 ||
          teacherProfile.mobile.length > 10
        ) {
          setProfileSave(false);
          setMobileError("Mobile number must be 10 digit");
        } else {
          setProfileSave(false);
          setMobileError("");
        }
      }
    } else {
      setProfileSave(false);
    }

    if (teacherProfile.school && teacherProfile.university) {
      setEducationSave(true);
    } else {
      setEducationSave(false);
    }

    if (teacherProfile.bank && teacherProfile.ifsc && teacherProfile.account) {
      if (teacherProfile.account.length >= 11) {
        setBankSave(true);
        setAccountError("");
      } else {
        setBankSave(false);
        setAccountError("Account number should be 11 digit or higher");
      }
    } else {
      setBankSave(false);
    }

    if (teacherProfile.password) {
      if (
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
          teacherProfile.password
        )
      ) {
        setLoginSave(true);
        setPasswordError("");
      } else if (
        !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
          teacherProfile.password
        )
      ) {
        setLoginSave(false);
        setPasswordError(
          "Password must be at least of 8 characters containing at least one number and one special character"
        );
      }
    } else {
      setLoginSave(false);
    }
  }, [teacherProfile]);

  const getProfile = () => {
    let user = localStorage.getItem("userID");
    let payload = {
      user_id: user,
    };
    API.post("TeacherApi/profile", payload, headers).then((res) => {
      if (res.data.result === 1) {
        setProfile(res.data.data);
        setTeacherProfile({
          user_id: res.data.data.user_id,
          name: res.data.data.name,
          fatherName: res.data.data.father_name,
          dob: res.data.data.dob,
          mobile: res.data.data.mobile,
          email: res.data.data.email,
          qualification: res.data.data.qualification,
          experience: res.data.data.experience,
          address: res.data.data.address,
          photo: "",
          school: res.data.data.qualification_school,
          university: res.data.data.university,
          schoolCertificate: "",
          universityCertificate: "",
          bioData: "",
          demoClass: "",
          handWriting: "",
          bank: res.data.data.bank_name,
          ifsc: res.data.data.ifsc_code,
          account: res.data.data.account_number,
          userName: "",
          password: res.data.data.text_password,
        });
        // setTeacherProfilePhoto(res.data.data.photo)
        // setTeacherProfileHandwritting(res.data.data.hand_writing_project)
        // setTeacherProfileDemoClass(res.data.data.demo_class)
        // setTeacherProfileSchoolCertificate(res.data.data.school_certificate)
        // setTeacherProfileUniversityCertificate(res.data.data.university_certificate)
        // setTeacherProfileBiodata(res.data.data.biodata)

        setTeacherProfilePhoto1(res.data.data.photo);
        setTeacherProfileHandwritting1(res.data.data.hand_writing_project);
        setTeacherProfileDemoClass1(res.data.data.demo_class);
        setTeacherProfileSchoolCertificate1(res.data.data.school_certificate);
        setTeacherProfileUniversityCertificate1(
          res.data.data.university_certificate
        );
        setTeacherProfileBiodata1(res.data.data.biodata);
      }
    });
  };

  const personalDialog = () => {
    setPersonalDialogOpen(true);
  };

  const closePersonalDialog = () => {
    setPersonalDialogOpen(false);
    setTeacherProfile({
      ...teacherProfile,
      name: profile.name,
      fatherName: profile.father_name,
      dob: profile.dob,
      mobile: profile.mobile,
      email: profile.email,
      qualification: profile.qualification,
      experience: profile.experience,
      address: profile.address,
    });
  };

  const educationDialog = () => {
    setEducationDialogOpen(true);
  };
  const closeEducationDialog = () => {
    setEducationDialogOpen(false);
    setTeacherProfile({
      ...teacherProfile,
      school: profile.qualification_school,
      university: profile.university,
    });
  };

  const bankDialog = () => {
    setBankDialogOpen(true);
  };
  const closeBankDialog = () => {
    setBankDialogOpen(false);
    setTeacherProfile({
      ...teacherProfile,
      bank: profile.bank_name,
      ifsc: profile.ifsc_code,
      account: profile.account_number,
    });
  };

  const loginDialog = () => {
    setLoginDialogOpen(true);
  };
  const closeLoginDialog = () => {
    setLoginDialogOpen(false);
    setTeacherProfile({
      ...teacherProfile,
      password: profile.text_password,
    });
  };

  const isShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    // const { name, value } = e.target;
    let name;
    let value;
    if (e.target.name === "ifsc") {
      name = e.target.name;
      value = String(e.target.value).toUpperCase();
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setTeacherProfile({
      ...teacherProfile,
      [name]: value,
    });
  };

  const cancelNewSchoolCertificate = () => {
    setTeacherProfileSchoolCertificate("");
  };
  const cancelNewUniversityCertificate = () => {
    setTeacherProfileUniversityCertificate("");
  };
  const cancelNewProfilePhoto = () => {
    setTeacherProfilePhoto("");
  };
  const cancelNewDemoClass = () => {
    setTeacherProfileDemoClass("");
  };
  const cancelNewBiodata = () => {
    setTeacherProfileBiodata("");
  };
  const cancelNewHandwritting = () => {
    setTeacherProfileHandwritting("");
  };

  const handleProfilePhoto = (e) => {
    e.preventDefault();
    console.log("pfofile_photo_payload", e);
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setTeacherProfilePhoto(reader.result);
      console.log("pfofile_photo_result", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleProfileSchoolCertificate = (e) => {
    e.preventDefault();
    console.log("pfofile_school_payload", e);
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setTeacherProfileSchoolCertificate(reader.result);
      console.log("pfofile_school_result", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleProfileUniversityCertificate = (e) => {
    e.preventDefault();
    console.log("pfofile_university_payload", e);
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setTeacherProfileUniversityCertificate(reader.result);
      console.log("pfofile_university_result", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleProfileDemoClass = (e) => {
    e.preventDefault();
    console.log("pfofile_demo_payload", e);
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setTeacherProfileDemoClass(reader.result);
      console.log("pfofile_demo_result", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleProfileHandWritting = (e) => {
    e.preventDefault();
    console.log("pfofile_demo_payload", e);
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setTeacherProfileHandwritting(reader.result);
      console.log("pfofile_demo_result", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleProfileBiodata = (e) => {
    e.preventDefault();
    console.log("pfofile_biodata_payload", e);
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setTeacherProfileBiodata(reader.result);
      console.log("pfofile_biodata_result", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const updateProfile = () => {
    setUpdateLoading(true);
    const head = {
      headers: {
        "Content-Type": "multipart/form-data",
        Source: "web",
      },
    };

    var formdata = new FormData();
    formdata.append("user_id", teacherProfile.user_id);
    formdata.append("name", teacherProfile.name);
    formdata.append("father_name", teacherProfile.fatherName);
    formdata.append("address", teacherProfile.address);
    formdata.append("latitutde", "22.1913978");
    formdata.append("longitude", "87.9098295");
    formdata.append("dob", teacherProfile.dob);
    formdata.append("mobile", teacherProfile.mobile);
    formdata.append("email", teacherProfile.email);
    formdata.append("password", teacherProfile.password);
    formdata.append("qualification", teacherProfile.qualification);
    formdata.append("experience", teacherProfile.experience);
    formdata.append("photo", teacherProfilePhoto ? teacherProfilePhoto : "");
    formdata.append("qualification_school", teacherProfile.school);
    formdata.append(
      "school_certificate",
      teacherProfileSchoolCertificate ? teacherProfileSchoolCertificate : ""
    );
    formdata.append("university", teacherProfile.university);
    formdata.append(
      "university_certificate",
      teacherProfileUniversityCertificate
        ? teacherProfileUniversityCertificate
        : ""
    );
    formdata.append(
      "biodata",
      teacherProfileBiodata ? teacherProfileBiodata : ""
    );
    formdata.append(
      "demo_class",
      teacherProfileDemoClass ? teacherProfileDemoClass : ""
    );
    formdata.append(
      "hand_writing_project",
      teacherProfileHandwritting ? teacherProfileHandwritting : ""
    );
    formdata.append("bank_name", teacherProfile.bank);
    formdata.append("ifsc_code", teacherProfile.ifsc);
    formdata.append("account_number", teacherProfile.account);
    formdata.append("fcm_token", "");
    console.log("payloadRegistration123", formdata);

    API.post("TeacherApi/edit_profile", formdata, head).then((res) => {
      if (res.data.result === 1) {
        setTeacherProfilePhoto("");
        setTeacherProfileHandwritting("");
        setTeacherProfileDemoClass("");
        setTeacherProfileSchoolCertificate("");
        setTeacherProfileUniversityCertificate("");
        setTeacherProfileBiodata("");
        getProfile();
        setUpdateLoading(false);
      }
    });
  };

  console.log("editProfile", teacherProfile);

  return (
    <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />

        <h1> TEACHER PROFILE</h1>
        <p>
          <Link href="/" style={{ color: "white", textDecoration: "none" }}>
            Home
          </Link>{" "}
          | Teacher Profile
        </p>
      </div>
      <Container style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item sm={4} md={4} lg={4}>
            <Box display="flex" flexDirection="column">
              <Box style={{ objectFit: "cover" }}>
                <img style={{ width: "100%" }} src={profile.photo} alt="" />
              </Box>
              <Box display="flex" flexDirection="column" marginTop={2}>
                <Box className={classes.dashboardSideButton} display="flex">
                  <Box
                    marginX={4}
                    style={{ alignSelf: "center", color: "white" }}
                  >
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>DASHBOARD</Typography>
                  </Box>
                  {/* <Link href="#">
                    <button
                      className={classes.dashboardSideButton}
                    >
                      <FaLongArrowAltRight /> DASHBOARD
                    </button>
                  </Link> */}
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link href="/subject" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>SUBJECT</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/studentreport"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>STUDENT REPORT</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/studentreport">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> STUDENT REPORT
                    </button>
                  </Link> */}
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link href="/tuitiontime" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>TUITION TIME</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/tuitiontime">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> TUITION TIME
                    </button>
                  </Link> */}
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/subscriberlist"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>SUBSCRIBER LIST</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/subscriberlist">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> SUBSCRIBER LIST
                    </button>
                  </Link> */}
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/motivationalvideo"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>MOTIVATIONAL VIDEOS</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/subscriberlist">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> SUBSCRIBER LIST
                    </button>
                  </Link> */}
                </Box>

                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link
                    href="/trainingvideo"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>TRAINING VIDEO</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/trainingvideo">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> TRAINING VIDEO
                    </button>
                  </Link> */}
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link href="/monthlyexam" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>MONTHLY EXAM</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/monthlyexam">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> MONTHLY EXAM
                    </button>
                  </Link> */}
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link href="/attendance" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>ATTENDANCE</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/attendance">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> ATTENDANCE
                    </button>
                  </Link> */}
                </Box>
                <Box marginTop={1} className={classes.dashboardOtherButton}>
                  <Link href="/news" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      style={{ height: "100%", color: "black" }}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>NEWS</Typography>
                      </Box>
                    </Box>
                  </Link>
                  {/* <Link href="/news">
                    <button
                      className={classes.dashboardOtherButton}
                    >
                      <FaLongArrowAltRight /> NEWS
                    </button>
                  </Link> */}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography variant="h5">{profile.name}</Typography>
            <p className={classes.profileLabel}>Teacher</p>
            <div
              style={{
                backgroundColor: "rgb(24, 169, 218)",
                height: "2px",
                width: "80px",
              }}
            ></div>
            <Box className={classes.root}>
              <Box className={classes.personalInfoBox}>
                <Box className={classes.singleBox}>
                  <Box>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Personal Information
                    </Typography>
                  </Box>
                  <Box>
                    <button
                      className={classes.profileEditButton}
                      onClick={personalDialog}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Father's Name
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.father_name}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      DOB
                    </Typography>
                  </Box>
                  <Box>
                    <Typography> {profile.dob}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Mobile Number
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.mobile}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Email Address
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.email}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Qualification
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.qualification}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Experience
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.experience}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Address
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.address}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.educationInfoBox}>
                <Box className={classes.singleBox}>
                  <Box>
                    {" "}
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Education Information
                    </Typography>
                  </Box>
                  <Box>
                    <button
                      className={classes.profileEditButton}
                      onClick={educationDialog}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      School Name
                    </Typography>
                  </Box>
                  <Box>
                    <Typography> {profile.qualification_school}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      School Certificate
                    </Typography>
                  </Box>
                  <Box marginTop={1}>
                    <img
                      className={classes.schoolCertificateStyle}
                      src={profile.school_certificate}
                      alt=""
                    />
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      University Name
                    </Typography>
                  </Box>
                  <Box>
                    <Typography> {profile.university}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      University Certificate
                    </Typography>
                  </Box>
                  <Box marginTop={1}>
                    <img
                      className={classes.universityCertificateStyle}
                      src={profile.university_certificate}
                      alt=""
                    />
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Bio-Data
                    </Typography>
                  </Box>
                  <Box marginTop={1}>
                    <img
                      style={{ width: "45px", height: "45px" }}
                      src={PDFIcon}
                      alt=""
                    />
                    {profile.biodata && (
                      <a
                        href={profile.biodata}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadIcon />
                      </a>
                    )}
                    {/* <Typography> {profile.biodata}</Typography> */}
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Demo Class
                    </Typography>
                  </Box>
                  <Box className={classes.demoPlayerBox}>
                    <ReactPlayer
                      url={profile.demo_class}
                      playing="true"
                      width="100%"
                      controls
                      height="100%"
                      loop="true"
                      muted="true"
                    />
                    {/* <Typography> 01-09-2021</Typography> */}
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      HandWritting Project
                    </Typography>
                  </Box>
                  <Box marginTop={1}>
                    <img
                      className={classes.handWritting}
                      src={profile.hand_writing_project}
                      alt=""
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.bankInfoBox}>
                <Box className={classes.singleBox}>
                  <Box>
                    {" "}
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Bank Information
                    </Typography>
                  </Box>
                  <Box>
                    <button
                      className={classes.profileEditButton}
                      onClick={bankDialog}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Bank Name
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.bank_name}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      IFSC Code
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.ifsc_code}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Account Number
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{profile.account_number}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.loginInfoBox}>
                <Box className={classes.singleBox}>
                  <Box>
                    {" "}
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Login Information
                    </Typography>
                  </Box>
                  <Box>
                    <button
                      className={classes.profileEditButton}
                      onClick={loginDialog}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </Box>
                </Box>
                {/* <Divider style={{ height: "2px", marginBottom: "20px", marginTop: "20px" }} />
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography style={{ fontSize: "smaller", opacity: "0.4" }}>
                      User Name
                    </Typography>
                  </Box>
                  <Box>
                    <Typography> 01-09-2021</Typography>
                  </Box>
                </Box> */}
                <Divider />
                <Box className={classes.profileDivider}>
                  <Box>
                    <Typography className={classes.profileLabel}>
                      Password
                    </Typography>
                  </Box>
                  <Box>
                    {/* <Typography>{profile.text_password}</Typography> */}
                    <Typography>********</Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginY={2}>
                {updateLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    className={classes.updateButton}
                    variant="contained"
                    onClick={updateProfile}
                  >
                    UPDATE
                  </Button>
                )}
              </Box>
            </Box>

            {/* <div className="col-md-6">
              <h6 style={{ fontSize: "small" }}>Personal Information</h6>
            </div>
            <div className="col-md-2 offset-4">
              <button
                className="rounded-circle"
                style={{ backgroundColor: "lightgreen", border: "none" }}
              >
                <MdModeEdit color="white" />
              </button>
            </div> */}
          </Grid>
        </Grid>
      </Container>

      <Dialog open={personalDialogOpen} maxWidth="md" fullWidth>
        <DialogTitle>Personal Information</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Name *</p>
              <TextField
                autoFocus
                name="name"
                value={teacherProfile.name}
                onChange={handleChange}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Father's Name *</p>
              <TextField
                name="fatherName"
                type="text"
                value={teacherProfile.fatherName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Date of Birth *</p>
              <TextField
                name="dob"
                value={teacherProfile.dob}
                onChange={handleChange}
                type="date"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Mobile *</p>
              <TextField
                name="mobile"
                value={teacherProfile.mobile}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                type="number"
              />
              {mobileError && <span className="error">{mobileError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Email *</p>
              <TextField
                name="email"
                fullWidth
                value={teacherProfile.email}
                onChange={handleChange}
                variant="outlined"
                type="text"
              />
              {emailError && <span className="error">{emailError}</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Qualification *</p>
              <TextField
                name="qualification"
                fullWidth
                value={teacherProfile.qualification}
                onChange={handleChange}
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Experience *</p>
              <TextField
                name="experience"
                value={teacherProfile.experience}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Address *</p>
              <TextField
                name="address"
                value={teacherProfile.address}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={5}>
              {/* <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={4} lg={4}> */}
              <p style={{ opacity: "0.6" }}>Teacher's Photo</p>
              <TextField
                name="photo"
                fullWidth
                variant="outlined"
                onChange={handleProfilePhoto}
                accept="image/jpeg"
                type="file"
              />
              {/* </Grid> */}

              {/* </Grid> */}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Box marginTop={5}>
                {teacherProfilePhoto && (
                  <IconButton
                    aria-label="close"
                    className={classes.cancelProilePic}
                    onClick={cancelNewProfilePhoto}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <img
                  style={{ width: "45px", height: "45px" }}
                  src={
                    teacherProfilePhoto
                      ? teacherProfilePhoto
                      : teacherProfilePhoto1
                  }
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box style={{ justifyContent: "flex-start", width: "100%" }}>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "deepskyblue",
                marginLeft: "16px",
                marginRight: "12px",
              }}
              variant="contained"
              onClick={() => {
                setPersonalDialogOpen(false);
              }}
              disabled={profileSave ? false : true}
            >
              Save
            </Button>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "lightgray",
              }}
              variant="contained"
              onClick={closePersonalDialog}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={educationDialogOpen} maxWidth="md" fullWidth>
        <DialogTitle>Education Information</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>School Name *</p>
              <TextField
                autoFocus
                name="school"
                value={teacherProfile.school}
                onChange={handleChange}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>University Name *</p>
              <TextField
                name="university"
                type="text"
                value={teacherProfile.university}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={5}>
              <p style={{ opacity: "0.6" }}>School Certificate</p>
              <TextField
                name="schoolCertificate"
                onChange={handleProfileSchoolCertificate}
                type="file"
                fullWidth
                accept="image/jpeg"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Box marginTop={5}>
                {teacherProfileSchoolCertificate && (
                  <IconButton
                    aria-label="close"
                    style={{
                      position: "absolute",
                      marginLeft: "19px",
                      marginTop: "-28px",
                    }}
                    onClick={cancelNewSchoolCertificate}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <img
                  style={{ width: "45px", height: "45px" }}
                  src={
                    teacherProfileSchoolCertificate
                      ? teacherProfileSchoolCertificate
                      : teacherProfileSchoolCertificate1
                  }
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={5}>
              <p style={{ opacity: "0.6" }}>University Certificate</p>
              <TextField
                name="universityCertificate"
                fullWidth
                variant="outlined"
                onChange={handleProfileUniversityCertificate}
                accept="image/jpeg"
                type="file"
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Box marginTop={5}>
                {teacherProfileUniversityCertificate && (
                  <IconButton
                    aria-label="close"
                    style={{
                      position: "absolute",
                      marginLeft: "19px",
                      marginTop: "-28px",
                    }}
                    onClick={cancelNewUniversityCertificate}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <img
                  style={{ width: "45px", height: "45px" }}
                  src={
                    teacherProfileUniversityCertificate
                      ? teacherProfileUniversityCertificate
                      : teacherProfileUniversityCertificate1
                  }
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={5}>
              <p style={{ opacity: "0.6" }}>Bio-data</p>
              <TextField
                name="biodata"
                fullWidth
                variant="outlined"
                onChange={handleProfileBiodata}
                type="file"
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Box marginTop={5}>
                {teacherProfileBiodata && (
                  <IconButton
                    aria-label="close"
                    style={{
                      position: "absolute",
                      marginLeft: "19px",
                      marginTop: "-28px",
                    }}
                    onClick={cancelNewBiodata}
                  >
                    <CloseIcon />
                  </IconButton>
                )}

                <img
                  style={{ width: "45px", height: "45px" }}
                  src={PDFIcon}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={5}>
              <p style={{ opacity: "0.6" }}>Demo class</p>
              <TextField
                name="democlass"
                fullWidth
                variant="outlined"
                onChange={handleProfileDemoClass}
                type="file"
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Box marginTop={5} className={classes.demoPlayerBoxEdit}>
                {teacherProfileDemoClass && (
                  <IconButton
                    aria-label="close"
                    style={{
                      position: "absolute",
                      marginLeft: "19px",
                      marginTop: "-28px",
                    }}
                    onClick={cancelNewDemoClass}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                {/* <img style={{ width: "45px", height: "45px" }} src={teacherProfileDemoClass ? teacherProfileDemoClass : teacherProfileDemoClass1} alt="" /> */}
                <ReactPlayer
                  url={
                    teacherProfileDemoClass
                      ? teacherProfileDemoClass
                      : teacherProfileDemoClass1
                  }
                  playing="true"
                  width="100%"
                  controls
                  height="100%"
                  loop="true"
                  muted="true"
                />
              </Box>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={5}>
              <p style={{ opacity: "0.6" }}>Handwritting Project</p>
              <TextField
                name="handwriting"
                fullWidth
                variant="outlined"
                onChange={handleProfileHandWritting}
                type="file"
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Box marginTop={5}>
                {teacherProfileHandwritting && (
                  <IconButton
                    aria-label="close"
                    style={{
                      position: "absolute",
                      marginLeft: "19px",
                      marginTop: "-28px",
                    }}
                    onClick={cancelNewHandwritting}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <img
                  style={{ width: "45px", height: "45px" }}
                  src={
                    teacherProfileHandwritting
                      ? teacherProfileHandwritting
                      : teacherProfileHandwritting1
                  }
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box style={{ justifyContent: "flex-start", width: "100%" }}>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "deepskyblue",
                marginLeft: "16px",
                marginRight: "12px",
              }}
              variant="contained"
              onClick={() => {
                setEducationDialogOpen(false);
              }}
              disabled={educationSave ? false : true}
            >
              Save
            </Button>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "lightgray",
              }}
              variant="contained"
              onClick={closeEducationDialog}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={bankDialogOpen} maxWidth="md" fullWidth>
        <DialogTitle>Bank Information</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Bank Name *</p>
              <TextField
                autoFocus
                name="bank"
                value={teacherProfile.bank}
                onChange={handleChange}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>IFSC Code *</p>
              <TextField
                name="ifsc"
                type="text"
                value={teacherProfile.ifsc}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p style={{ opacity: "0.6" }}>Account Number *</p>
              <TextField
                name="account"
                type="number"
                value={teacherProfile.account}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
              {accountError && <span className="error">{accountError}</span>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box style={{ justifyContent: "flex-start", width: "100%" }}>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "deepskyblue",
                marginLeft: "16px",
                marginRight: "12px",
              }}
              variant="contained"
              onClick={() => {
                setBankDialogOpen(false);
              }}
              disabled={bankSave ? false : true}
            >
              Save
            </Button>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "lightgray",
              }}
              variant="contained"
              onClick={closeBankDialog}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={loginDialogOpen} maxWidth="md" fullWidth>
        <DialogTitle>Login Information</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <p style={{ opacity: "0.6" }}>Password *</p>
              <OutlinedInput
                name="password"
                type={showPassword ? "text" : "password"}
                value={teacherProfile.password}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={isShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {accountError && <span className="error">{accountError}</span>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box style={{ justifyContent: "flex-start", width: "100%" }}>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "deepskyblue",
                marginLeft: "16px",
                marginRight: "12px",
              }}
              variant="contained"
              onClick={() => {
                setLoginDialogOpen(false);
              }}
              disabled={loginSave ? false : true}
            >
              Save
            </Button>
            <Button
              style={{
                borderRadius: "0px",
                width: "18vh",
                backgroundColor: "lightgray",
              }}
              variant="contained"
              onClick={closeLoginDialog}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {/* <div className="container" style={{ paddingTop: "30px" }}>
          <div className="row">
            <div className="col-md-4 offset-1">
              <div className="row">
                <img
                  style={{ height: "400px" }}
                  src={require("../../assets/img/83890830.jpeg").default}
                  alt=""
                />
              </div>
              <div className="row" style={{ paddingTop: "15px" }}>
                <NavLink to="">
                  <button
                    style={{
                      backgroundColor: "rgb(24, 169, 218)",
                      height: "40px",
                      border: "none",

                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> DASHBOARD
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/subject">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> SUBJECT
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/studentreport">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> STUDENT REPORT
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/tuitiontime">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> TUITION TIME
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/subscriberlist">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> SUBSCRIBER LISTS
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/motivationalvideo">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> MOTIVATIONAL VIDEOS
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/trainingvideo">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> TRAINING VIDEO
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/monthlyexam">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> MONTHLY EXAM
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/attendance">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> ATTENDANCE
                  </button>
                </NavLink>
              </div>
              <div className="row" style={{ paddingTop: "5px" }}>
                <NavLink to="/news">
                  <button
                    style={{
                      backgroundColor: "lightgrey",
                      height: "40px",
                      border: "none",
                      width: "22rem",
                    }}
                  >
                    <FaLongArrowAltRight /> NEWS
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="row">
                {" "}
                <h6>John Doe</h6>
                <p style={{ fontSize: "smaller", opacity: "0.4" }}>Teacher</p>
                <div
                  style={{
                    backgroundColor: "rgb(24, 169, 218)",
                    height: "2px",
                    width: "80px",
                  }}
                ></div>
              </div>
              <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "30px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>Personal Information</h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Father's Name
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-1">
                    <img
                      style={{ height: "45px", width: "45px" }}
                      src={
                        require("../../assets/img/hero_landscape_91e1b039-11ef-4d97-a01e-639b6ee4990a.jpeg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="col-md-2 ">
                    <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                      David Doe
                    </p>
                  </div>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>DOB</p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    01-09-2021
                  </p>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Mobile Number
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    987654321
                  </p>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Email Address
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    johndoe@gmail.com
                  </p>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>Address</p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    504 A , ps aviator , rajarhat
                  </p>
                </div>
              </div>
              <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "40px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>
                      Educational Information
                    </h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    School Name
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    R.B.P High School
                  </p>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    School Certificate
                  </p>
                  <img
                    style={{ height: "45px", width: "55px" }}
                    src={
                      require("../../assets/img/hero_landscape_91e1b039-11ef-4d97-a01e-639b6ee4990a.jpeg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    University Name
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    R.B.P High School
                  </p>
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    University Certificate
                  </p>
                  <img
                    style={{ height: "45px", width: "55px" }}
                    src={
                      require("../../assets/img/hero_landscape_91e1b039-11ef-4d97-a01e-639b6ee4990a.jpeg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Bio Data
                  </p>
                  <img
                    style={{ height: "45px", width: "55px" }}
                    src={
                      require("../../assets/img/hero_landscape_91e1b039-11ef-4d97-a01e-639b6ee4990a.jpeg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Demo Class
                  </p>
                  <img
                    style={{ height: "45px", width: "55px" }}
                    src={
                      require("../../assets/img/hero_landscape_91e1b039-11ef-4d97-a01e-639b6ee4990a.jpeg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="row">
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Handwritting Project
                  </p>
                  <img
                    style={{ height: "45px", width: "55px" }}
                    src={
                      require("../../assets/img/hero_landscape_91e1b039-11ef-4d97-a01e-639b6ee4990a.jpeg")
                        .default
                    }
                    alt=""
                  />
                </div>
              </div>
              <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "50px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>Bank Information</h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Bank Name
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    Axis Bank
                  </p>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    IFSC Code
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    Axis123456
                  </p>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Account Number
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    987654321
                  </p>
                </div>
              </div>
              <div
                className="container"
                style={{
                  border: "1px solid lightgrey",
                  position: "relative",
                  top: "60px",
                }}
              >
                <div
                  className="row"
                  style={{ height: "50px", paddingTop: "10px" }}
                >
                  <div className="col-md-6">
                    <h6 style={{ fontSize: "small" }}>Login Information</h6>
                  </div>
                  <div className="col-md-2 offset-4">
                    <button
                      className="rounded-circle"
                      style={{ backgroundColor: "lightgreen", border: "none" }}
                    >
                      <MdModeEdit color="white" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    User Name
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    johndoe420
                  </p>
                </div>
                <div className="row">
                  {" "}
                  <p style={{ fontSize: "smaller", opacity: "0.4" }}>
                    Password
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                    Axis123456
                  </p>
                </div>
              </div>
              <div className="col" style={{ paddingTop: "15vh" }}>
                <button className="btn btn-primary">UPDATE</button>
              </div>
              <div style={{ height: "100px" }}></div>
            </div>
          </div>
        </div> */}
    </>
  );
};

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "18%",
    color: "white",
  },
  demoPlayerBox: {
    width: "43%",
    height: "200px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
    marginTop: "8px",
  },
  demoPlayerBoxEdit: {
    width: "100%",
    height: "55px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
  },
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileEditButton: {
    backgroundColor: "lightgreen",
    border: "none",
    fontSize: "24px",
    borderRadius: "20px",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  handWritting: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.4",
  },
  updateButton: {
    borderRadius: "0px",
    width: "18vh",
    backgroundColor: "deepskyblue",
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  bankInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  educationInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  personalInfoBox: {
    border: "1px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  schoolCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  universityCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(Teacherprofile);
