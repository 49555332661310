import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle, AiFillGoogleCircle } from "react-icons/ai";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { MdEmail } from "react-icons/md";

class Hometeachers extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <div style={{ paddingTop: "40px" }}>
        <div className="teachers">
          <h2>OUR TEACHERS</h2>
          <h6>
            Lorem ipsum is a dummy text which doesn't have any meaning but i am
            still writing this
          </h6>
          <br></br>
          <br></br>
        </div>
        <div>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            showDots={true}
            infinite={true}
            autoPlaySpeed={2000}
            centerMode={false}
            arrows={false}
          >
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mt-3">
                    <div className="card">
                      <div className="card-horizontal">
                        <div className="img-square-wrapper">
                          <img
                            src={require("../assets/img/83890830.jpeg").default}
                          />
                        </div>
                        <div className="card-body">
                          <h5 class="card-title">Muzaffar Ahmed</h5>
                          <h6>Mathematics</h6>
                          <p class="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <div className="teacherul">
                            <ul
                              style={{
                                display: "flex",
                                flex: "1 1 auto",
                              }}
                            >
                              <li style={{ paddingRight: "5px" }}>
                                <FaFacebook size="25px" />
                              </li>
                              <li style={{ paddingRight: "5px" }}>
                                <AiFillTwitterCircle size="25px" />
                              </li>
                              <li style={{ paddingRight: "5px" }}>
                                <AiFillGoogleCircle size="25px" />
                              </li>
                              <li style={{ paddingRight: "5px" }}>
                                <a href="linkedin.com">
                                  <TiSocialLinkedinCircular
                                    size="25px"
                                    color="black"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {" "}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mt-3">
                    <div className="card">
                      <div className="card-horizontal">
                        <div className="img-square-wrapper">
                          <img
                            src={require("../assets/img/83890830.jpeg").default}
                          />
                        </div>
                        <div className="card-body">
                          <h5 class="card-title">Tauqeer Hasaan</h5>
                          <h6>Backend</h6>
                          <p class="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p>
                          <div className="teacherul">
                            <ul style={{ display: "flex", flex: "1 1 auto" }}>
                              <li style={{ paddingRight: "5px" }}>
                                <FaFacebook size="25px" />
                              </li>
                              <li style={{ paddingRight: "5px" }}>
                                <AiFillTwitterCircle size="25px" />
                              </li>
                              <li style={{ paddingRight: "5px" }}>
                                <AiFillGoogleCircle size="25px" />
                              </li>
                              <li style={{ paddingRight: "5px" }}>
                                <a href="linkedin.com">
                                  <TiSocialLinkedinCircular
                                    size="25px"
                                    color="black"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
        <br></br> <br></br>
        <br></br>
      </div>
    );
  }
}
export default Hometeachers;
