import React, { Component } from "react";
import { Form } from "react-bootstrap";
import API from "./api";
import ClipLoader from "react-spinners/ClipLoader";
import { GrAdd } from "react-icons/gr";
import { AiOutlineMinus } from "react-icons/ai";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { MdCall } from "react-icons/md";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  MenuItem,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import useWindowSize from "../provider/useWindowSize";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListIcon from "@mui/icons-material/List";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CallIcon from "@mui/icons-material/Call";
import RemoveIcon from "@mui/icons-material/Remove";
import ProgressReport from "../Student/ProgressReport";

class Student extends Component {
  state = {
    schools: "",
    classselected: 7,
    SchoolList: [],
    eduopen: false,
    subsopen: false,
    open: false,
    classlist: [],
    loading: false,

    StudentData: [],
    result: "",
    class: "",
    dob: "",
    mail: "",
    fname: "",
    fphoto: "",
    mobile: "",
    name: "",
    pic: "",
    certificate: "",
    school: "",
    subs: "",
    bloodgroup: "",
    lists: true,
    details: false,
    progressReport: false,
    address: "",
    student_id: "",
  };
  componentDidMount() {
    this.getclassList();
    // this.getStudentList();
    this.getStudentList();
  }

  Studentdetailpage(l) {
    console.log("id", l.student_id);
    this.setState({
      details: true,
      lists: false,
      bloodgroup: l.blood_group,
      class: l.class,
      dob: l.dob,
      mail: l.email,
      fname: l.father_name,
      fphoto: l.group_photo,
      mobile: l.mobile,
      name: l.name,
      pic: l.photo,
      certificate: l.pre_class_certificate,
      school: l.school,
      subs: l.subscription,
      address: l.address,
      student_id: l.student_id,
    });
    console.log("subsdata", l);
  }

  getclassList = () => {
    var that = this;
    API.post("MasterApi/class_list", {
      school_id: localStorage.getItem("school_id"),
    })
      .then(function (response) {
        console.log(response);
        that.setState({ classlist: response.data.data });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  };
  classChange = (e) => {
    this.state.classselected = e.target.value;

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    this.getStudentList(e);
  };
  getStudentList() {
    const credentials = {
      school_id: localStorage.getItem("school_id"),
      class_id: this.state.classselected,
    };
    var that = this;
    API.post("SchoolApi/studentList", credentials)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          StudentData: response.data.data,
          result: response.data.result,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  getProgressReport() {
    this.setState({
      progressReport: true,
      details: false,
      lists: false,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div style={{ minHeight: "130vh" }}>
        {this.state.lists && (
          <div>
            {" "}
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1> STUDENT</h1>
              <p
              // style={{
              //   fontSize: "smaller",
              //   position: "relative",
              //   bottom: "10vh",
              //   left: "55vh",
              //   color: "white",
              // }}
              //           style={{ position: "absolute",
              //   bottom:" 27%",
              //   left: "28%",
              //   color: "white"}}
              >
                <Link
                  href="/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Home
                </Link>{" "}
                |
                <Link
                  href="/observer_profile"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  School Observer{" "}
                </Link>
                | Student
              </p>
            </div>
            <Container style={{ marginBottom: "12px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={9} lg={9}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Students
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="selectGame"
                      input={<OutlinedInput />}
                      onChange={this.classChange}
                      value={this.state.classselected}
                    >
                      {this.state.classlist.map((l, i) => {
                        return (
                          <MenuItem key={i} value={l.class_id}>
                            {l.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
            <Container
              style={{
                marginBottom: "12px",
                marginTop: "20px",
                minHeight: "500px",
              }}
            >
              <Grid container spacing={2}>
                {this.state.StudentData === undefined ? (
                  <Box>No Students Available</Box>
                ) : (
                  <>
                    {this.state.StudentData.map((l, i) => {
                      return (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Button
                            variant="outlined"
                            style={{
                              border: "1px solid lightgrey",
                              textTransform: "capitalize",
                            }}
                            onClick={() => {
                              this.Studentdetailpage(l);
                            }}
                            fullWidth
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              style={{ width: "100%" }}
                              paddingY={1}
                            >
                              <Box>
                                <Typography style={{ color: "black" }}>
                                  {l.name}
                                </Typography>
                              </Box>
                              <Box>
                                <ArrowForwardIcon style={{ color: "black" }} />
                              </Box>
                            </Box>
                          </Button>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Container>
            {/* <div className="container">
              <div className="row" style={{ paddingTop: "5vh" }}>
                <div className="col-md-6 offset-1" style={{ flex: "1" }}>
                  <h5>Student</h5>
                </div>

                <div className="col-md-2">
                  <Form.Select

                    onChange={this.classChange}
                    required
                    value={this.state.classselected}
                    aria-label="Default select example"
                  >
                    <option>Select Class</option>
                    {this.state.classlist.map((l, i) => {
                      return (
                        <option key={i} value={l.class_id}>
                          {l.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              </div>
              {this.state.classselected === "" ? (
                <div className="col offset-1">Please Select Class</div>
              ) : (
                <div className="col offset-1">
                  {this.state.loading ? (
                    <div
                      className="row"
                      style={{ paddingTop: "4vh", justifyContent: "center" }}
                    >
                      {" "}
                      <ClipLoader color="black" size={20} />
                      Loading Data{" "}
                    </div>
                  ) : (
                    <>
                      {this.state.result === 0 ? (
                        <div>No Data</div>
                      ) : (
                        <div className="col-md-10 ">
                          {this.state.StudentData.map((l, i) => {
                            return (
                              <div
                                className="row"
                                style={{ paddingTop: "2vh" }}
                              >
                                <Button
                                  onClick={() => {
                                    this.Studentdetailpage(l);
                                  }}
                                  variant="Info"
                                  style={{
                                    border: "2px solid lightgrey",
                                    textAlign: "right",
                                  }}
                                >
                                  <h6 style={{ textAlign: "left" }}>
                                    {l.name}
                                  </h6>{" "}
                                  <HiOutlineArrowNarrowRight />
                                </Button>
                              </div>
                            );
                          })}{" "}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div> */}
          </div>
        )}
        {this.state.details && (
          <>
            <div className="banner-register">
              <img
                className=""
                src={require("../assets/img/banner-register.jpeg").default}
                style={{
                  height: "300px",
                  width: "100%",
                  opacity: 0.8,
                }}
              />
              <h1>STUDENT</h1>
              <p>Home | School Observer | Student | Details</p>
            </div>
            <Container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <button
                      onClick={() => {
                        this.setState({ lists: true, details: false });
                      }}
                      className={classes.tuitionTimeStatusButton}
                    >
                      <ArrowBackIcon style={{ color: "white" }} />
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box style={{ height: "400px", width: "100%" }}>
                    <img
                      style={{
                        height: "400px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      src={this.state.pic}
                      alt=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      paddingX={2}
                      paddingTop={1}
                    >
                      <Box>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          {this.state.name}
                        </Typography>
                      </Box>
                      <Box>
                        <button className={classes.tuitionTimeStatusButton}>
                          <CallIcon style={{ color: "white" }} />
                        </button>
                      </Box>
                    </Box>
                    <Box paddingX={2}>
                      <Typography>{this.state.school}</Typography>
                    </Box>
                    <Box paddingX={2} paddingBottom={1}>
                      <Typography>{this.state.class}</Typography>
                    </Box>
                    <Divider />
                    <Box>
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItemButton
                          onClick={() => {
                            this.setState({ open: !this.state.open });
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            style={{ width: "100%" }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                Personal Information
                              </Typography>
                            </Box>
                            <Box>
                              {this.state.open ? (
                                <RemoveIcon />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Collapse
                          in={this.state.open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box className={classes.personalInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Father's Name
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.fname}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  DOB
                                </Typography>
                              </Box>
                              <Box>
                                <Typography> {this.state.dob}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Blood Group
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.bloodgroup}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Mobile Number
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.mobile}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Email Address
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.mail}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Address
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>{this.state.address}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Collapse>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ eduopen: !this.state.eduopen });
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            style={{ width: "100%" }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                Educational Information
                              </Typography>
                            </Box>
                            <Box>
                              {this.state.eduopen ? (
                                <RemoveIcon />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Collapse
                          in={this.state.eduopen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box className={classes.educationInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  School Name
                                </Typography>
                              </Box>
                              <Box>
                                <Typography> {this.state.school}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Class
                                </Typography>
                              </Box>
                              <Box>
                                <Typography> {this.state.class}</Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Previous Certificate
                                </Typography>
                              </Box>
                              <Box marginTop={1}>
                                <img
                                  className={classes.universityCertificateStyle}
                                  src={this.state.certificate}
                                  alt=""
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Collapse>
                        <ListItemButton
                          onClick={() => {
                            this.setState({ subsopen: !this.state.subsopen });
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            style={{ width: "100%" }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                              >
                                Subscriptional Information
                              </Typography>
                            </Box>
                            <Box>
                              {this.state.subsopen ? (
                                <RemoveIcon />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Collapse
                          in={this.state.subsopen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box className={classes.educationInfoBox}>
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Subscription Name
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {" "}
                                  {this.state.subs.subscription_name}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Ammount
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {this.state.subs.amount}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                            <Box className={classes.profileDivider}>
                              <Box>
                                <Typography className={classes.profileLabel}>
                                  Validity
                                </Typography>
                              </Box>
                              <Box>
                                <Typography>
                                  {this.state.subs.start_date} -{" "}
                                  {this.state.subs.end_date}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Collapse>
                      </List>
                      <Box padding={2}>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                          onClick={() => this.getProgressReport()}
                        >
                          Student Report
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {/* <div className="col-md-1 offset-2">
              <Button
                style={{
                  backgroundColor: "black",
                  borderRadius: "50%",
                  border: "1px solid grey",
                }}
                onClick={() => {
                  this.setState({ lists: true });
                }}
              >
                <HiOutlineArrowNarrowLeft size="3vh" />
              </Button>
            </div> */}
            {/* <div className="container">
              <div className="row" style={{ paddingTop: "5vh" }}>
                <div className="col-md-3 offset-2">
                  <div className="row">
                    <img style={{ height: "400px" }} src={this.state.pic} />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-8">
                      <h5>{this.state.name}</h5>
                      <p style={{ opacity: "0.8" }}>{this.state.school}</p>
                      <p style={{ opacity: "0.8" }}>{this.state.class}</p>
                    </div>
                    <div className="col-md-1 ">
                      <Button
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "lightgreen",
                        }}
                      >
                        <MdCall />
                      </Button>
                    </div>
                  </div>
                  <div
                    className="col-md-9"
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "3%",
                    }}
                  >
                    <div
                      className="row"
                      style={{ paddingTop: "3vh", paddingLeft: "4vh" }}
                    >
                      <div className="col-md-10">
                        <p style={{ fontWeight: "bold" }}>
                          Personsal Information
                        </p>
                      </div>
                      <div className="col-md-1 ">
                        {this.state.open ? (
                          <Button
                            onClick={() => {
                              this.setState({ open: false });
                            }}
                            variant="none"
                          >
                            <AiOutlineMinus size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.open === false ? (
                          <Button
                            onClick={() => {
                              this.setState({ open: true });
                            }}
                            variant="none"
                          >
                            <GrAdd size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row" style={{ paddingLeft: "2vh" }}>
                      <Collapse in={this.state.open}>
                        <div>
                          <div className="row">
                            <h6>Father's Name</h6>
                          </div>
                          <div className="row">
                            <div className="col-md-2">
                              <img
                                style={{ height: "50px", width: "50px" }}
                                src={this.state.fphoto}
                              />
                            </div>
                            <div className="col-md-2 ">
                              <p
                                style={{ fontSize: "smaller", opacity: "0.8" }}
                              >
                                {this.state.fname}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2 ">
                              <h6>DOB</h6>
                              <p
                                style={{ fontSize: "smaller", opacity: "0.8" }}
                              >
                                {this.state.dob}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <h6>Blood Group</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.bloodgroup}
                            </p>
                          </div>
                          <div className="row">
                            <h6>Mobile Number</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.mobile}
                            </p>
                          </div>
                          <div className="row">
                            <h6>Email Address</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.mail}
                            </p>
                          </div>
                          <div className="row">
                            <h6>Location</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.address}
                            </p>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  <div
                    className="col-md-9"
                    style={{
                      border: "1px solid lightgrey",
                      marginTop: "3vh",
                      borderRadius: "3%",
                    }}
                  >
                    <div
                      className="row"
                      style={{ paddingTop: "3vh", paddingLeft: "4vh" }}
                    >
                      <div className="col-md-10">
                        <p style={{ fontWeight: "bold" }}>
                          Educational Information
                        </p>
                      </div>
                      <div className="col-md-1 ">
                        {this.state.eduopen ? (
                          <Button
                            onClick={() => {
                              this.setState({ eduopen: false });
                            }}
                            variant="none"
                          >
                            <AiOutlineMinus size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.eduopen === false ? (
                          <Button
                            onClick={() => {
                              this.setState({ eduopen: true });
                            }}
                            variant="none"
                          >
                            <GrAdd size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row" style={{ paddingLeft: "3vh" }}>
                      <Collapse in={this.state.eduopen}>
                        <div>
                          <div className="row">
                            <h6>School Name</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.school}
                            </p>
                          </div>

                          <div className="row">
                            <h6>Class Name</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.class}
                            </p>
                          </div>
                          <div className="row">
                            <h6>Previous Class Certificate</h6>
                            <img
                              style={{ height: "50px", width: "50px" }}
                              src={this.state.certificate}
                            />
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  <div
                    className="col-md-9"
                    style={{
                      border: "1px solid lightgrey",
                      marginTop: "3vh",
                      borderRadius: "3%",
                    }}
                  >
                    <div
                      className="row"
                      style={{ paddingTop: "3vh", paddingLeft: "4vh" }}
                    >
                      <div className="col-md-10" style={{ paddingLeft: "3vh" }}>
                        <p style={{ fontWeight: "bold" }}>
                          Subscriptional Information
                        </p>
                      </div>
                      <div className="col-md-1 ">
                        {this.state.subsopen ? (
                          <Button
                            onClick={() => {
                              this.setState({ subsopen: false });
                            }}
                            variant="none"
                          >
                            <AiOutlineMinus size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.subsopen === false ? (
                          <Button
                            onClick={() => {
                              this.setState({ subsopen: true });
                            }}
                            variant="none"
                            style={{ paddingBottom: "2vh" }}
                          >
                            <GrAdd size="20px" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row" style={{ paddingLeft: "3vh" }}>
                      <Collapse in={this.state.subsopen}>
                        <div>
                          <div className="row">
                            <h6>Subscription Name</h6>

                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.subs.subscription_name}
                            </p>
                          </div>

                          <div className="row">
                            <h6>Ammount</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.subs.amount}
                            </p>
                          </div>
                          <div className="row">
                            <h6>Validity</h6>
                            <p style={{ fontSize: "smaller", opacity: "0.8" }}>
                              {this.state.subs.start_date} -{" "}
                              {this.state.subs.end_date}
                            </p>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
        {this.state.progressReport && (
          <ProgressReport student={this.state.student_id} from="Observer" />
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "18%",
    color: "white",
  },
  demoPlayerBox: {
    width: "43%",
    height: "200px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
    marginTop: "8px",
  },
  demoPlayerBoxEdit: {
    width: "100%",
    height: "55px",
    objectFit: "cover",
    border: "2px solid black",
    borderRadius: "5px",
  },
  dashboardSideButton: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
  },
  dashboardOtherButton: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  profileEditButton: {
    backgroundColor: "lightgreen",
    border: "none",
    fontSize: "24px",
    borderRadius: "20px",
  },
  profileDivider: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginBottom: "12px",
  },
  singleBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    marginBottom: "12px",
  },
  handWritting: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  profileLabel: {
    fontSize: "smaller",
    opacity: "0.4",
  },
  updateButton: {
    borderRadius: "0px",
    width: "18vh",
    backgroundColor: "deepskyblue",
  },
  loginInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  bankInfoBox: {
    border: "1px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  educationInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    marginTop: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  personalInfoBox: {
    borderBottom: "2px solid lightgrey",
    borderTop: "2px solid lightgrey",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    flexDirection: "column",
  },
  schoolCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  universityCertificateStyle: {
    objectFit: "cover",
    height: "100px",
    width: "100px",
  },
  addTuitionButton: {
    backgroundColor: "lightgreen",
    color: "white",
    borderRadius: "30px",
    marginLeft: "2px",
  },
  tuitionTimeStatusButton: {
    backgroundColor: "lightgreen",
    border: "none",
    borderRadius: "20px",
    height: "40px",
    width: "40px",
    marginRight: "4px",
  },
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(Student);
