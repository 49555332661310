import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import API from "../components/api";
// import { Router, Link } from "@reach/router"
// import { Grid, Link } from "@mui/material";
// import { Button, Container } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { withStyles } from '@mui/styles';
import { navigate } from "@reach/router";
import DownloadIcon from '@mui/icons-material/Download';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

class Coding extends Component {
  state = {
    button: 0,
    index: 0,
    languageName:"",
    jsbutton: false,

    CodingLanguages: [],
    CodingTopicdata: [],
    CodingVideos: [],
  };
  componentDidMount() {
    this.getcodingVideos();
    this.getCodingLanguages();
  }
  getCodinglanguageid(l, i) {
    this.setState({ button: i });
    this.setState({ index: i });
    this.setState({languageName: l.name})
    this.getTopicdetails(l.coding_language_id);
  }
  gettopicid(l) {
    localStorage.setItem("codingTopicid", l.topic_id);
    localStorage.setItem("codingVideo", l.file);
  }
  getTopicdetails(id) {
    const params = {
      coding_language_id: id,
    };
    var that = this;
    

    API.post("StudentApi/codingLanguageTopics", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          CodingTopicdata: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getCodingLanguages() {
    const params = {
      class_id: localStorage.getItem("class_id"),
    };
    var that = this;

    API.post("StudentApi/codingLanguage", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          CodingLanguages: response.data.data,
        });
        that.setState({
          languageName: response.data.data[0].name
        });
        
        that.getTopicdetails(response.data.data[0].coding_language_id)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getcodingVideos() {
    var that = this;
    let user = localStorage.getItem("userID");
    API.post("StudentApi/student_home", { user_id: user })
      .then(function (response) {
        console.log(response.data.innovativeVideos);
        that.setState({
          CodingVideos: response.data.codingVideos,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  render() {
    const { classes } = this.props
    return (
      <>
        {" "}
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> CODING</h1>
          <p>
            {" "}
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              {" "}
              Student
            </Link>{" "}
            | Coding
          </p>
        </div>
        <Container style={{ marginTop: "20px", marginBottom:"20px", minHeight:"500px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box display="flex" flexDirection="column">
                {this.state.CodingLanguages.map((l, index) => {
                  return (
                    <Box
                      marginTop={1}
                      display="flex"
                      onClick={() => {
                        this.getCodinglanguageid(l, index);
                      }}
                      className={this.state.button === index ? classes.cliked : classes.notclicked}
                    >
                      <Box marginX={4} style={{ alignSelf: "center" }}>
                        <FaLongArrowAltRight />
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Typography>{l.name}</Typography>
                      </Box>
                    </Box>
                  );
                })}

              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
           
              {this.state.button === this.state.index &&
                <Container>
                   <Typography variant="h5" >{this.state.languageName}</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom:"12px"
                      }}>
                    </div>
                  <Grid container>
                    {this.state.CodingTopicdata.length > 0 ?<>
                    {this.state.CodingTopicdata.map((l, i) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                         
                            <Card 
                            onClick={() => {
                                  this.gettopicid(l);
                                }}>
                              <CardActionArea>
                              <Link href="/codingvideo/" style={{textDecoration:"none",color:"black"}}>
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={l.thumbnail_file}
                                  alt="green iguana"
                                />
                                <CardContent>
                                  <Box display="flex" flexDirection="column">
                                  <Box>
                                  <Typography gutterBottom variant="h5" component="div">
                                  {l.name}
                                  </Typography>
                                  </Box>
                                  <Box>
                                    <a href={l.pdf_file} style={{textDecoration:"none"}}><Button variant="text" style={{color:"black"}}><DownloadIcon/>Pdf</Button></a>
                                  </Box>
                                  </Box>
                                </CardContent>
                                </Link>
                              </CardActionArea>
                            </Card>
                          
                        </Grid>
                      );
                    })}
                    </>
                    :
                    <Typography>Data not found</Typography>
                    }
                  </Grid>

                </Container>
              }
            </Grid>
          </Grid>
        </Container>
        
      </>
    );
  }
}
const styles = theme => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white"
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white"
  }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }

})
export default withStyles(styles)(Coding);
