import react, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Router, Link } from "@reach/router"
import API from "../components/api";
// import { Button, Collapse } from "react-bootstrap";
import { GrAdd } from "react-icons/gr";
import { AiOutlineMinus } from "react-icons/ai";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { navigate } from "@reach/router";

class TeacherDetails extends Component {
  state = {
    TeachersList: [],
    teacher: "",
    Teacherdata: "",
    open: false,
    tuition: [],
    tuitiontimings: [],
  };
  componentDidMount() {
    let teacherid = localStorage.getItem("teacherid");
    this.setState({ teacher: teacherid });
    this.getteacherList();
  }
  getTuitionid(l) {
    localStorage.setItem("tuitionfee", l.fees);
    localStorage.setItem("tuitionID", l.tution_id);
    console.log("tuitionid", l.fees);
  }
  getteacherList() {
    var that = this;
    API.post("StudentApi/teacherList")
      .then(function (response) {
        console.log(response);
        that.setState({
          TeachersList: response.data.data,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        const result = that.state.TeachersList.find(
          ({ teacher_id }) => teacher_id === that.state.teacher
        );
        console.log("folafol", result.tutions);
        that.setState({
          Teacherdata: result,
          tuition: result.tutions,
          // tuitiontimings: result.tutions.timing,
        });
        // that.state.tuition.map((l, id) => {
        //   return that.setState({ tuitiontimings: l.timing });
        // });
        // console.log("l.tining", that.state.tuitiontimings);
      });
  }
  render() {
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> TEACHERS</h1>
          <p>
            {" "}
            <Link href="/" style={{ color: "white", textDecoration: "none" }}>
              Home
            </Link>{" "}
            |{" "}
            <Link
              href="/userprofile"
              style={{ color: "white", textDecoration: "none" }}
            >
              Student{" "}
            </Link>
            |{" "}
            <Link
              href="/teachers"
              style={{ color: "white", textDecoration: "none" }}
            >
              {" "}
              Teachers
            </Link>{" "}
            | {this.state.Teacherdata.name}
          </p>
        </div>
        <Container
          style={{
            minHeight: "500px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                border={1}
                borderColor="lightgrey"
                borderRadius="5px"
              >
                <Box>
                  <img
                    style={{
                      height: "350px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    src={require("../assets/img/83890830.jpeg").default}
                    alt=""
                  />
                </Box>
                <Box marginY={2} paddingX={1}>
                  <Box>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {this.state.Teacherdata.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {this.state.Teacherdata.qualification}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box display="flex" flexDirection="column" marginY={1} paddingX={1}>
                  <Box><Typography style={{ fontWeight: "bold" }}>Educational Qualification</Typography></Box>
                  <Box><Typography >{this.state.Teacherdata.qualification}</Typography></Box>
                </Box> */}
                <Divider />
                <Box
                  display="flex"
                  flexDirection="column"
                  marginY={1}
                  paddingX={1}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      Experience
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {this.state.Teacherdata.experience} yrs
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  flexDirection="column"
                  marginY={1}
                  paddingX={1}
                >
                  <Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      Location
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{this.state.Teacherdata.address}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Container>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5">Tuition Seat Details</Typography>
                    <div
                      style={{
                        backgroundColor: "rgb(24, 169, 218)",
                        height: "2px",
                        width: "142px",
                        marginBottom: "12px",
                      }}
                    ></div>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "lightgray" }}>
                            Subject
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              textAlign: "center",
                            }}
                          >
                            Total Seats
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              textAlign: "center",
                            }}
                          >
                            Available Seats
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              textAlign: "center",
                            }}
                          >
                            Booking Seats
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              textAlign: "center",
                            }}
                          >
                            Fees
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "lightgray",
                              textAlign: "center",
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.tuition.map((l) => (
                          <TableRow hover>
                            <TableCell>{l.subject}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {l.total_seat}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {l.available_seat}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {l.booking_seat}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {l.fees}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <Link href="/checkout">
                                <Button
                                  onClick={() => {
                                    this.getTuitionid(l);
                                  }}
                                  variant="text"
                                >
                                  BOOK SEAT
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container" style={{ paddingTop: "10px" }}>
          <div className="row">
            <div className="col-md-3 offset-1">
              <img
                style={{ height: "250px", width: "200px" }}
                src={require("../assets/img/83890830.jpeg").default}
                alt=""
              />
            </div>
            <div
              className="col-md-6"
              style={{ paddingTop: "5vh", position: "relative", right: "10vh" }}
            >
              <div className="row">
                {" "}
                <h6>{this.state.Teacherdata.name}</h6>
                <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                </p>
              </div>
              <div className="row">
                <h6>Educational Qualification</h6>
                <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                  {this.state.Teacherdata.qualification}
                </p>
              </div>
              <div className="row">
                <h6>Experience</h6>
                <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                  {this.state.Teacherdata.experience} yrs
                </p>
              </div>
              <div className="row">
                <h6>Location</h6>
                <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                  {this.state.Teacherdata.address}
                </p>
              </div>
              <div className="row" style={{ paddingTop: "30px" }}>
                <div className="col-md-5">
                  <h6>Tuition Seat Details</h6>
                </div>
              </div>
              <div
                className="row"
                style={{
                  borderBottom: "2px solid lightgrey",
                  borderTop: "2px solid lightgrey",
                }}
              >
                <div className="col-md-2">
                  <p style={{ fontWeight: "bold", opacity: "0.8" }}>Subject</p>
                </div>
                <div className="col-md-2">
                  <p style={{ fontWeight: "bold", opacity: "0.8" }}>
                    Total Seats
                  </p>
                </div>
                <div className="col-md-2">
                  <p style={{ fontWeight: "bold", opacity: "0.8" }}>
                    Available Seats
                  </p>
                </div>
                <div className="col-md-2">
                  <p style={{ fontWeight: "bold", opacity: "0.8" }}>
                    Booking Seats
                  </p>
                </div>
                <div className="col-md-2">
                  <p style={{ fontWeight: "bold", opacity: "0.8" }}>Fees</p>
                </div>
                <div className="col-md-2">
                  <p style={{ fontWeight: "bold", opacity: "0.8" }}></p>
                </div>
              </div>
              {this.state.tuition.map((l, id) => {
                return (
                  <div
                    className="row"
                    style={{
                      borderBottom: "2px solid lightgrey",
                      borderTop: "2px solid lightgrey",
                    }}
                  >
                    <div className="col-md-2">
                      <p style={{ fontWeight: "", opacity: "1" }}>
                        {l.subject}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p style={{ fontWeight: "", opacity: "0.8" }}>
                        {l.total_seat}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p style={{ fontWeight: "", opacity: "0.8" }}>
                        {l.available_seat}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p style={{ fontWeight: "", opacity: "0.8" }}>
                        {l.booking_seat}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p style={{ fontWeight: "", opacity: "0.8" }}>{l.fees}</p>
                    </div>
                    <div className="col-md-2">
                      <Link href="/checkout">
                        <Button
                          onClick={() => {
                            this.getTuitionid(l);
                          }}
                        >
                          BOOK SEAT
                        </Button>
                      </Link>
                    </div>
                  </div>
                );
              })} */}
        {/* {this.state.tuition.map((l, id) => {
                return (
                  <div>
                    <div className="row">
                      <div className="col-md-10" style={{ paddingLeft: "6vh" }}>
                        <p style={{ fontWeight: "bold" }}>{l.subject}</p>
                      </div>
                      <div className="col-md-1 ">
                        {this.state.open ? (
                          <Button
                            onClick={() => {
                              this.setState({ open: false });
                            }}
                            variant="none"
                          >
                            <AiOutlineMinus />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.open === false ? (
                          <Button
                            onClick={() => {
                              this.setState({ open: true });
                            }}
                            variant="none"
                          >
                            <GrAdd />
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row" style={{ paddingLeft: "2vh" }}>
                      <Collapse in={this.state.open}>
                        <div className="row">
                          <div className="col-md-8">
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              Total Seats
                            </p>
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              Booking Seats
                            </p>
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              Available Seats
                            </p>
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              Tuition Fee
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              {l.total_seat}
                            </p>
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              {l.booking_seat}
                            </p>
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              {l.available_seat}
                            </p>
                            <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                              {l.fees}
                            </p>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                );
              })} */}
        {/* //___________________________ */}
        {/* <div className="row">
                <div className="col-md-8">
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                    Total Seats
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                    Booking Seats
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                    Available Seats
                  </p>
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>
                    Tuition Fee
                  </p>
                </div>
                <div className="col-md-4">
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>20</p>
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>16</p>
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>4</p>
                  <p style={{ fontSize: "smaller", opacity: "0.7" }}>1200.00</p>
                </div>
              </div> */}
        {/* <div className="col"> */}
        {/* <Link href="/checkout">
                  <button className="btn btn-primary">BOOK SEAT</button>
                </Link> */}
        {/* </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}
export default TeacherDetails;
