import React, { Component, useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import API from "../../components/api";

const SubscriberList = ({ classes }) => {
  const [subscriberList, setSubscriberList] = useState([]);
  const [subscriberListBackup, setSubscriberListBackup] = useState([]);
  const [classList, setClassList] = useState([]);

  const [totalSeats, setTotalSeats] = useState("");
  const [bookSeats, setBookSeats] = useState("");
  const [classId, setClassId] = useState("all");

  useEffect(() => {
    API.post("TeacherApi/subscribers", {
      user_id: localStorage.getItem("userID"),
    }).then((res) => {
      if (res.data.result === 1) {
        setSubscriberList(res.data.data);
        setSubscriberListBackup(res.data.data);
        setTotalSeats(res.data.totalSeat);
        setBookSeats(res.data.bookedSeat);
      } else {
        setSubscriberList([]);
        setSubscriberListBackup([]);
      }
    });

    API.post("MasterApi/class_list", { school_id: "" }).then((res) => {
      if (res.data.result === 1) {
        setClassList(res.data.data);
      } else {
        setClassList([]);
      }
    });
  }, []);

  const getStudentByClass = (e) => {
    const { name, value } = e.target;
    console.log("valuesfgsdhgfh", value);
    if (value !== "all") {
      setClassId(value);
      let subscribers = [];
      // setSubscriberList(subscriberListBackup)
      subscribers = subscriberListBackup.filter(
        (subscriber) => subscriber.class_id === value
      );
      console.log("subscribers", subscribers);
      if (subscribers.length > 0) {
        setSubscriberList(subscribers);
      } else {
        setSubscriberList([]);
      }
    } else {
      setClassId("all");
      setSubscriberList(subscriberListBackup);
    }
  };
  return (
    <>
      <div className="banner-register">
        <img
          className=""
          src={require("../../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
            opacity: 0.8,
          }}
          alt=""
        />
        <h1> SUBSCRIBER LISTS</h1>
        <p>
          <Link href="/" style={{ color: "white", textDecoration: "none" }}>
            Home
          </Link>{" "}
          | Student | Subscriber Lists
        </p>
      </div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box>
              <Typography variant="h5" style={{ paddingLeft: "8px" }}>
                SUBSCRIBER LISTS
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container marginY={1}>
          <Grid item xs={12} sm={12} md={12}>
            <div
              style={{
                backgroundColor: "rgb(24, 169, 218)",
                height: "2px",
                width: "15%",
              }}
            ></div>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* className={classes.subscriberListHeading} */}
                  <TableCell
                    style={{
                      backgroundColor: "lightgray",
                      padding: "0px",
                      paddingBottom: "4px",
                    }}
                  >
                    <Container style={{ marginTop: "11px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                          <Typography variant="h6">Students List</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          {/* className={classes.dropdownClass} */}
                          <FormControl
                            fullWidth
                            style={{
                              backgroundColor: "white",
                              paddingTop: "5px",
                            }}
                          >
                            {/* <InputLabel id="demo-simple-select">Class</InputLabel> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="class"
                              defaultValue={classId}
                              value={classId}
                              onChange={getStudentByClass}
                              input={<OutlinedInput />}
                            >
                              <MenuItem value="all">All Class</MenuItem>
                              {classList.map((singleClass) => (
                                <MenuItem value={singleClass.class_id}>
                                  {singleClass.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={3} lg={3}>
                          <FormControl fullWidth style={{
                            backgroundColor: "white",
                            paddingTop: "5px"
                          }}>
                            <InputLabel id="demo-simple-select">Time Slot</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="timeslot"
                              input={<OutlinedInput />}
                            >
                              <option value={1}>JAVA</option>
                              <option value={2}>CSS</option>
                              <option value={3}>Three</option>
                            </Select>
                          </FormControl>
                        </Grid> */}
                      </Grid>
                    </Container>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriberList.length > 0 ? (
                  <>
                    {subscriberList.map((subscriber) => (
                      <TableRow hover>
                        <TableCell>
                          {subscriber?.student} ( {subscriber?.subject} ,{" "}
                          {subscriber?.class})
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow hover>
                    <TableCell>Data not found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* <Box marginY={2}>
              <Box>
                <Button
                  variant="contained"
                  className={classes.subscriberSubmit}
                  style={{ backgroundColor: "deepskyblue" }}
                >
                  SUBMIT
                </Button>
              </Box>
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: "100%" }}
            >
              <Box display="flex" flexDirection="column">
                <Box
                  style={{
                    width: "100%",
                    height: "71px",
                    backgroundColor: "deepskyblue",
                  }}
                  display="flex"
                >
                  <Box
                    marginX={4}
                    style={{ alignSelf: "center", color: "white" }}
                  >
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>YOUR SUBSCRIBER</Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  style={{ justifyContent: "space-between" }}
                  padding={2}
                >
                  <Box>
                    <Typography>Total Seats</Typography>
                  </Box>
                  <Box>
                    <Typography>{totalSeats}</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  style={{ justifyContent: "space-between" }}
                  padding={2}
                >
                  <Box>
                    <Typography>Booking Seats</Typography>
                  </Box>
                  <Box>
                    <Typography>{bookSeats}</Typography>
                  </Box>
                </Box>
              </Box>
              {/* <Box display="flex" flexDirection="column">
                <Box style={{ width: "100%", height: "71px", backgroundColor: "deepskyblue" }} display="flex">

                  <Box marginX={4} style={{ alignSelf: "center", color: "white" }}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>TUITION FEES DETAILS</Typography>
                  </Box>
                </Box>
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Plan Name</Typography>

                  </Box>
                  <Box>
                    <Typography>ABCD</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Seats</Typography>
                  </Box>
                  <Box>
                    <Typography>40</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" style={{ justifyContent: "space-between" }} padding={2}>
                  <Box>
                    <Typography>Tuition Fee</Typography>
                  </Box>
                  <Box>
                    <Typography>400.00</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" style={{ justifyContent: "center" }} padding={2}>
                  <Box>
                    <Button variant="contained" style={{ backgroundColor: "deepskyblue" }}>Edit</Button>
                  </Box>
                </Box>
              </Box> */}
              <Box display="flex" flexDirection="column">
                <Box
                  style={{
                    width: "100%",
                    height: "71px",
                    backgroundColor: "deepskyblue",
                  }}
                  display="flex"
                >
                  <Box
                    marginX={4}
                    style={{ alignSelf: "center", color: "white" }}
                  >
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>CUSTOMER CARE</Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  style={{ justifyContent: "space-between" }}
                  padding={2}
                >
                  <Box>
                    <Typography>Contact</Typography>
                  </Box>
                  <Box display="inline-flex">
                    <Typography>+91 9876543234</Typography>
                    <PhoneInTalkIcon />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* <div className="container" style={{ paddingTop: "30px" }}>
        <div className="row">
          <div className="col offset-1">
            <h5>SUBSCRIBER LISTS</h5>
            <div style={{
              backgroundColor: "rgb(24, 169, 218)", height: "2px",
              width: "80px"
            }}></div></div></div>


        <div className="row" style={{ position: "relative", top: "20px" }}>
          <div className="col-md-6 offset-1">
            <div className="row" style={{ backgroundColor: "#E0E0E0" }}>
              <div className="col-md-7 offset-1"><h6>Student Lists</h6></div>

              <div className="col-md-2">
                <FloatingLabel controlId="floatingSelect" label="Class">
                  <Form.Select style={{ height: "5px" }} aria-label="">
                    <option></option>
                    <option value="1">Java</option>
                    <option value="2">Css</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className="col-md-2"><FloatingLabel controlId="floatingSelect" label="TimeSlot">
                <Form.Select style={{ height: "5px" }} aria-label="">
                  <option></option>
                  <option value="1">Java</option>
                  <option value="2">Css</option>
                  <option value="3">Three</option>
                </Form.Select>
              </FloatingLabel></div>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey", borderOpacity: "0.2" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="row" style={{ borderBottom: "1px solid lightgrey" }}>
              <p>Sourav Chakraborty</p>
            </div>
            <div className="col " style={{ position: "relative", top: "15px" }}>
              <button style={{ backgroundColor: "rgb(24, 169, 218)", height: "35px", color: "white", border: "none" }}> SUBMIT</button>
            </div>
          </div>



          <div className="col-md-4" style={{ position: "relative", left: "10px" }}>

            <div className="row" style={{ backgroundColor: "rgb(24, 169, 218)", paddingTop: "20px" }}>
              <p style={{ color: "white" }}><FaLongArrowAltRight /> YOUR SUBSCRIBER</p>
            </div>
            <div className="row" style={{ paddingTop: "20px", borderBottom: "1px solid lightgrey" }}>
              <div className="col-md-7 offset-1"><p style={{ fontSize: "smaller" }}>Total Seats</p></div>
              <div className="col-md-2"><p style={{ fontSize: "smaller" }}>40</p></div>
            </div>
            <div className="row" style={{ paddingTop: "20px", borderBottom: "1px solid lightgrey" }}>
              <div className="col-md-7 offset-1"><p style={{ fontSize: "smaller" }}>Booking Seats</p></div>
              <div className="col-md-2"><p style={{ fontSize: "smaller" }}>28</p></div>
            </div>

            <div className="row" style={{ backgroundColor: "rgb(24, 169, 218)", paddingTop: "20px" }}>
              <p style={{ color: "white", positon: "relative", top: "15px", left: "10px" }}><FaLongArrowAltRight />
                TUITION FEES DETAILS</p>
            </div>
            <div className="row" style={{ paddingTop: "20px", borderBottom: "1px solid lightgrey" }}>
              <div className="col-md-7 offset-1"><p style={{ fontSize: "smaller" }}>Plan Name</p></div>
              <div className="col-md-4"><p style={{ fontSize: "smaller" }}>1 MONTH</p></div>
            </div>
            <div className="row" style={{ paddingTop: "20px", borderBottom: "1px solid lightgrey" }}>
              <div className="col-md-7 offset-1"><p style={{ fontSize: "smaller" }}>Seats</p></div>
              <div className="col-md-2"><p style={{ fontSize: "smaller" }}>40</p></div>
            </div>
            <div className="row" style={{ paddingTop: "20px", borderBottom: "1px solid lightgrey" }}>
              <div className="col-md-7 offset-1"><p style={{ fontSize: "smaller" }}>Tuition Fee</p></div>
              <div className="col-md-2"><p style={{ fontSize: "smaller" }}>699.00</p></div>
            </div>
            <div className="row" style={{ paddingTop: "20px", borderBottom: "1px solid lightgrey" }}>
              <div className="col">
                <button style={{ backgroundColor: "rgb(24, 169, 218)", border: "none", color: "white" }}>edit</button>
              </div>
            </div>

            <div className="row" style={{ backgroundColor: "rgb(24, 169, 218)", paddingTop: "20px" }}>
              <p style={{ color: "white" }}><FaLongArrowAltRight /> CUSTOMER CARE</p>
            </div>

            <div className="row">
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>Contact</p>
              </div>
              <div className="col-md-5 offset-1">
                <p style={{ fontSize: "smaller" }}>+91 9876543234 <FiPhoneCall color="green" /></p>
              </div>
            </div>

          </div>
          <div style={{ height: "100px" }}></div>
        </div>

      </div> */}
    </>
  );
};
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  subscriberListHeading: {
    backgroundColor: "lightgray",
    padding: "0px",
    paddingBottom: "4px",
  },
  dropdownClass: {
    backgroundColor: "white",
    paddingTop: "5px",
  },
  subscriberSubmit: {
    color: "white",
    border: "none",
    height: "40px",
    width: "140px",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(SubscriberList);
