import React, { Component } from "react";
import ReactPlayer from "react-player";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
// import { Router, Link } from "@reach/router"
import API from "../components/api";
import { ClipLoader } from "react-spinners";

import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { navigate } from "@reach/router";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

class CodingVideos extends Component {
  state = {
    video: "",
    TopicName: "",
    Examdata: [],
    CodingUniid: "",
    exammsg: "",
    confirmationmodal: false,
    loading: false,
    CodingVideos: [],
  };
  componentDidMount() {
    let value = localStorage.getItem("codinguniqueID");
    this.setState({ CodingUniid: value });
    this.setState({ video: localStorage.getItem("codingVideo") });
    this.getCodingExamList();
    this.getcodingVideos();
  }
  getplayablevideoid(l) {
    let videofile = l.file;
    console.log("vfile", videofile);
    this.setState({ video: videofile });
    this.setState({ TopicName: l.name });
  }
  getcodingVideos() {
    var that = this;
    let user = localStorage.getItem("userID");
    API.post("StudentApi/student_home", { user_id: user })
      .then(function (response) {
        console.log(response.data.innovativeVideos);
        that.setState({
          CodingVideos: response.data.codingVideos,
        });
        that.setState({ TopicName: response.data.codingVideos[0].name });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  Startexam() {
    // eslint-disable-next-line no-unused-expressions
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
      navigate("/coding/onlinetest");
      // this.props.history.push("/coding/onlinetest");
    }, 2500);
  }
  getCodingExamList() {
    const params = {
      user_id: localStorage.getItem("userID"),
      topic_id: localStorage.getItem("codingTopicid"),
    };
    var that = this;
    API.post("StudentApi/onlineCodingExamList", params)
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Examdata: response.data.Unit,
          exammsg: response.data.message,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  getexamid(l) {
    localStorage.setItem("codingexamid", l.exam_id);
    this.setState({ confirmationmodal: true });
    localStorage.setItem("codingtotalques", l.total_no_of_question);
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1>CODING</h1>
          <p>
            {" "}
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              {" "}
              Student
            </Link>{" "}
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/coding"
            >
              {" "}
              Coding
            </Link>{" "}
            | Language
          </p>
        </div>
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            minHeight: "500px",
          }}
        >
          <Grid container spacing={2} marginY={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box display="flex" flexDirection="column">
                <Box
                  className={classes.demoPlayerBox}
                  border={1}
                  borderColor="black"
                  borderRadius="5px"
                >
                  <ReactPlayer
                    url={this.state.video}
                    playing="true"
                    width="100%"
                    controls
                    height="100%"
                    loop="true"
                    muted="false"
                  />
                </Box>
                <Box marginY={2}>
                  <Typography variant="h6">{this.state.TopicName}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Test Exam
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    {this.state.Examdata === undefined ? (
                      <Box>No Data Found</Box>
                    ) : (
                      <>
                        {this.state.Examdata.map((l, id) => {
                          return (
                            <Box>
                              <Button
                                variant="outlined"
                                style={{
                                  border: "1px solid lightgrey",
                                  textTransform: "capitalize",
                                  width: "100%",
                                  // paddingTop: "3vh",
                                  // paddingBottom: "2vh",
                                }}
                                onClick={() => {
                                  this.getexamid(l);
                                }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  style={{ width: "100%" }}
                                >
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        <Typography>Exam</Typography>
                                      </Box>
                                      <Box>
                                        <Typography>: {l.exam_name}</Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        <Typography>Topic</Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.topic_name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        <Typography>Test Type</Typography>
                                      </Box>
                                      <Box>
                                        <Typography>: {l.test_type}</Typography>
                                      </Box>
                                    </Box>
                                    <Box
                                      display="flex"
                                      style={{ width: "50%" }}
                                    >
                                      <Box
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        <Typography>Total Question</Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          : {l.total_no_of_question}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Button>
                            </Box>
                          );
                        })}
                      </>
                    )}
                    <Box>
                      {this.state.CodingUniid ? (
                        <Box
                          display="flex"
                          style={{
                            justifyContent: "space-between",
                            border: "1px solid lightgrey",
                            paddingTop: "2vh",
                            paddingBottom: "2vh",
                            paddingLeft: "2vh",
                            paddingRight: "2vh",
                            marginTop: "1vh",
                          }}
                        >
                          {" "}
                          <Box style={{ color: "green" }}>Exam Finished</Box>
                          <Box
                            style={{ border: "1px solid lightgrey" }}
                            onClick={() => {
                              navigate("/coding/onlinetest");
                            }}
                          >
                            View Sollution
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>
                {/* { category === "Motivational" && 
              <Box display="flex" flexDirection="column" marginY={2} style={{border:"1px solid lightgray"}}>
                <Box paddingY={1} paddingX={1} >
                  <Typography>Message</Typography>
                </Box>
                <Divider style={{marginTop:"8px",marginBottom:"8px"}}/>
                <Box display="flex">
                  <Box paddingX={2} alignSelf="center">
                    <MessageIcon />
                  </Box>
                  <Box style={{ width: "100%"}} paddingRight={2}>
                    <TextareaAutosize 
                    placeholder="Write Message"
                    minRows={4}
                    maxLength={200} 
                    maxRows={4} 
                    style={{ width: "100%",border:"none" }} />
                  </Box>
                </Box>
                <Divider style={{marginTop:"8px",marginBottom:"8px"}}/>
                <Box paddingX={1} paddingY={1}>
                  <Button variant="contained" style={{ color: "deepskyblue" }}>SEND</Button>
                </Box>
              </Box>} */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box display="flex" flexDirection="column">
                <Box
                  style={{
                    width: "100%",
                    height: "71px",
                    backgroundColor: "deepskyblue",
                  }}
                  display="flex"
                >
                  <Box
                    marginX={4}
                    style={{ alignSelf: "center", color: "white" }}
                  >
                    <FaLongArrowAltRight />
                  </Box>
                  <Box style={{ alignSelf: "center", color: "white" }}>
                    <Typography>SIMILAR VIDEOS</Typography>
                  </Box>
                </Box>
                <Box>
                  <Table stickyHeader aria-label="sticky table">
                    <TableBody>
                      {this.state.CodingVideos.map((video, index) => (
                        <>
                          <TableRow
                            hover
                            onClick={() => {
                              this.getplayablevideoid(video);
                            }}
                          >
                            <Box display="flex">
                              <Box style={{ width: "40%" }} padding={1}>
                                <img
                                  alt=""
                                  src={video?.thumbnail_file}
                                  style={{ width: "100%", height: "130px" }}
                                />
                              </Box>
                              <Box
                                style={{ width: "60%", alignSelf: "center" }}
                                padding={2}
                              >
                                <Typography>{video?.name}</Typography>
                              </Box>
                            </Box>
                          </TableRow>
                          {index !==
                            Number(this.state.CodingVideos.length) - 1 && (
                            <Divider
                              style={{ marginTop: "4px", marginBottom: "4px" }}
                            />
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container " style={{ paddingTop: "30px" }}>
          <div className="row innovation-row">
            <div className="col-md-6 offset-1" innovation-col>
            <ReactPlayer
                playing={true}
                width="100%"
                controls={true}
                url={this.state.video}
              />
              <div className="row">
              </div>
            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col-md-4">
                <p style={{ fontWeight: "bold" }}>TEST EXAM</p>
              </div>
              <div className="row">
                <div className="col-md-8">
                  {this.state.Examdata === undefined ? (
                    <div>{this.state.exammsg}</div>
                  ) : (
                    <div>
                      {this.state.Examdata.map((l, id) => {
                        return (
                          <div className="row" style={{ paddingTop: "2vh" }}>
                            <Button
                              variant="outlined"
                              style={{
                                border: "1px solid lightgrey",
                                paddingTop: "3vh",
                                paddingBottom: "2vh",
                              }}
                              onClick={() => {
                                this.getexamid(l);
                              }}
                            >
                              <h5>Exam:-{l.exam_name}</h5>
                              <h6>Topic:-{l.topic_name}</h6>
                              <p>Test type: - {l.test_type}</p>
                              <p
                                style={{
                                  fontSize: "small",
                                  lineHeight: "0px",
                                }}
                              >
                                Total Question: - {l.total_no_of_question}
                              </p>
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div> */}
        <Modal
          size="md"
          show={this.state.confirmationmodal}
          onHide={() => this.setState({ confirmationmodal: false })}
          aria-labelledby="example-modal-sizes-title-sm"
          animation="true"
          autoFocus="true"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <h6>Are You Sure You Want To Start Exam ?</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <p style={{ marginTop: "5vh" }}>
                Get yourself prepared before starting exam.
              </p>
              <div className="row">
                <div className="col-md-3">
                  {this.state.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "deepskyblue" }}
                      onClick={() => {
                        this.Startexam();
                      }}
                    >
                      Confirm
                    </Button>
                  )}
                </div>

                <div className="col-md-3">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      this.setState({ confirmationmodal: false });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* </div> */}
        {/* </div> */}
        {/* <div
                className="container"
                
              > */}
        {/* <div className="row" style={{}}>
                  <div className="col-md-4">
                    <h6
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "25px",
                      }}
                    >
                      Teacher
                    </h6>
                    <p
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "15px",
                      }}
                    >
                      Chris Thompson
                    </p>
                  </div>
                  <div className="col-md-3 ">
                    <h6
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "25px",
                      }}
                    >
                      Chapter
                    </h6>
                    <p
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "15px",
                      }}
                    >
                      Introduction
                    </p>
                  </div>
                  <div className="col-md-3 ">
                    <h6
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "25px",
                      }}
                    >
                      Duration
                    </h6>
                    <p
                      style={{
                        fontSize: "smaller",
                        position: "relative",
                        top: "15px",
                      }}
                    >
                      20 minutes
                    </p>
                  </div>
                </div> */}

        {/* </div>
            </div> */}
        {/* <div className="col-md-4">
              <div className="row">
                <button
                  style={{
                    backgroundColor: "blue",
                    border: "none",
                    height: "40px",
                  }}
                >
                  <FaLongArrowAltRight />
                  SIMILAR VIDEOS
                </button>
              </div>

              {this.state.CodingVideos === undefined ? (
                <div>No Similar Videos Found</div>
              ) : (
                <div>
                  {this.state.CodingVideos.map((l, id) => {
                    return (
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          background: "#efefef",
                          margin: "20px 0",
                          alignItems: "center",
                          borderRadius: "6px",
                          overflow: "hidden",
                          width: "85%",
                        }}
                      >
                        <div className="col-md-5">
                          <img
                            style={{ height: "60px", width: "100px" }}
                            src={l.thumbnail_file}
                          />
                          <button
                            style={{
                              height: "35px",
                              width: "35px",
                              border: "none",
                              position: "absolute",
                              left: "65%",

                              transform: "translate(-20%,-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.getplayablevideoid(l);
                            }}
                            className="rounded-circle"
                          >
                            <FaPlayCircle size="35px" />
                          </button>
                        </div>
                        <div className="col-md-7">
                          <p>{l.name}</p>
                          <p style={{ fontSize: "smaller", opacity: "0.6" }}>
                            {l.coding_language}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div> */}
        {/* </div>
        </div>
        <div style={{ height: "200px" }}></div> */}
      </>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  // tuitionTimeStatusButton: {
  //     backgroundColor: "lightgreen",
  //     border: "none",
  //     borderRadius: "20px",
  //     height: "40px",
  //     width: "40px",
  //     marginRight: "4px"
  // },
  // addTuitionButton: {
  //     backgroundColor: "lightgreen",
  //     color: "white",
  //     borderRadius: "30px",
  //     marginLeft: "2px"
  // }
  // notclicked: {
  //   backgroundolor: "lightgrey",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // },
  // cliked: {
  //   backgroundColor: "rgb(24, 169, 218)",
  //   height: "40px",
  //   border: "none",
  //   width: "100%",
  // }
  demoPlayerBox: {
    width: "100%%",
    height: "400px",
    objectFit: "cover",
    // border: "1px solid black",
    // borderRadius: "1px",
  },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(CodingVideos);
