import React, { Component } from "react";
import { Form, Select, Button, FloatingLabel } from "react-bootstrap";
import { GrAddCircle } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaLongArrowAltRight } from "react-icons/fa";

export default class GameNews extends Component {
  render() {
    return (
      <div>
        {" "}
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
          />
          <h1> GAMING NEWS</h1>
          <p>Home | Game Academy | Game News</p>
        </div>{" "}
        <div className="container" style={{ paddingTop: "10px" }}>
          <div className="row">
            <div className="col-md-8 offset-1">
              <h5>GAMING NEWS</h5>
              <div
                style={{
                  height: "3px",
                  width: "80px",
                  textDecoration: "underline",
                  backgroundColor: "lightblue",
                }}
              ></div>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div class="col-sm-8 offset-1">
              <div class="card">
                <div class="card-body">
                  <p1>28-07-2021</p1>
                  <h5 class="card-title">
                    Parents and Educationists divided on School reopening
                  </h5>
                  <p1 class="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p1>
                </div>
              </div>
            </div>

            <div className="col-sm-1">
              <button
                className="rounded-circle"
                style={{
                  backgroundColor: "lightgreen",
                  height: "40px",
                  width: "40px",
                  border: "none",
                }}
              >
                <AiFillDelete color="white" />
              </button>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "5px" }}>
            <div class="col-sm-8 offset-1">
              <div class="card">
                <div class="card-body">
                  <p1>28-07-2021</p1>
                  <h5 class="card-title">
                    Parents and Educationists divided on School reopening
                  </h5>
                  <p1 class="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p1>
                </div>
              </div>
            </div>

            <div className="col-sm-1">
              <button
                className="rounded-circle"
                style={{
                  backgroundColor: "lightgreen",
                  height: "40px",
                  width: "40px",
                  border: "none",
                }}
              >
                <AiFillDelete color="white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
