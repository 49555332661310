import React, { Component } from "react";
import API from "../components/api";
// import { FloatingLabel, Form, Button, Modal } from "react-bootstrap";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { withStyles } from '@mui/styles';
import { navigate } from "@reach/router";
import DownloadIcon from '@mui/icons-material/Download';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

class Motivation extends Component {
  state = {
    MotivationalVideo: [],
  };
  componentDidMount() {
    this.getMotivationalVideos();
  }
  MotivateFile(l) {
    localStorage.setItem("motivatevdo1", l.file);
  }
  getMotivationalVideos() {
    var that = this;
    let user = localStorage.getItem("userID");
    API.post("StudentApi/student_home", { user_id: 1 })
      .then(function (response) {
        console.log(response.data.innovativeVideos);
        that.setState({
          MotivationalVideo: response.data.motivationalVideos,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  render() {
    return (
      <>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> MOTIVATIONAL VIDEOS</h1>
          <p>
            <Link style={{ color: "white", textDecoration: "none" }} href="/">
              Home
            </Link>{" "}
            |{" "}
            <Link
              style={{ color: "white", textDecoration: "none" }}
              href="/userprofile"
            >
              Student
            </Link>{" "}
            | Motivational Videos
          </p>
        </div>
        <Container style={{ marginTop: "20px", marginBottom: "20px", minHeight: "500px" }}>
          <Grid container spacing={2}>
            {this.state.MotivationalVideo.map((l, i) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3}>

                  <Card onClick={() => {
                    this.MotivateFile(l);
                  }}>
                    <Link href="/motivationalvideo/videodetail" style={{textDecoration:"none"}}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={l.thumbnail_file}
                          alt="green iguana"
                        />
                        <CardContent style={{color:"black"}}>
                          <Typography gutterBottom variant="h5" component="div" >
                            {l.name}
                          </Typography>
                          <Typography >
                          Posted on 11th august , 2021
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Link>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
        {/* <div
          className="container"
          style={{ paddingLeft: "10rem", paddingTop: "2rem" }}
        >
          <div className="row">
            {this.state.MotivationalVideo.map((l, i) => {
              return (
                <div className="col-md-3">
                  {" "}
                  <div
                    className="card"
                    style={{ width: "13rem", height: "auto" }}
                  >
                    <div className="embed-responsive embed-responsive-4by3">
                      <Link href={"/videodetail"}>
                        <Button
                          variant="none"
                          onClick={() => {
                            this.MotivateFile(l);
                          }}
                          style={{ width: "100%" }}
                        >
                          <img
                            className="card-img-top"
                            src={l.thumbnail_file}
                            alt="Card cap"
                          />
                        </Button>
                      </Link>
                    </div>
                    <div className="card-body">
                      <h6 className="card-title">{l.name}</h6>
                      <p className="card-text" style={{ fontSize: "smaller" }}>
                        Posted on 11th august , 2021
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <br></br> */}
      </>
    );
  }
}
export default Motivation;
