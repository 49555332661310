import React, { Component } from "react";
import { Form } from "react-bootstrap";
import API from "../components/api";
import { ClipLoader } from "react-spinners";
import { GiBarbedSpear } from "react-icons/gi";
import { CgDanger } from "react-icons/cg";
import { navigate } from "@reach/router";

import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";

class GameRegister extends Component {
  state = {
    page: 1,
    buttonpage2: false,
    buttonpage3: false,
    buttonpage4: false,
    loading: false,
    Video: "",
    Image: "",
    email: "",
    address: "",
    mobile: "",
    ownername: "",
    academyname: "",
    latitude: "",
    longitude: "",
    success: false,
    accnumber: "",
    bankname: "",
    ifsc: "",
    id1: "",
    signuperror: "",
    pass: "",
    repass: "",
    Gamelist: [],
    workDays: "",
    nameError: "",
    ownerError: "",
    AddressError: "",
    mobileError: "",
    emailError: "",
    photoError: "",
    GameError: "",
    banknameError: "",
    accnumberError: "",
    ifscError: "",
    passError: "",
    repassError: "",
  };
  componentDidMount() {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);

      const lati = position.coords.latitude;
      localStorage.setItem("lat", lati);
      localStorage.setItem("long", position.coords.longitude);
      console.log("lat", lati);
    });

    this.setState({
      latitude: localStorage.getItem("lat"),
      longitude: localStorage.getItem("long"),
    });
    console.log("LAT", localStorage.getItem("lat"));
    this.getgamelist();
  }
  valid() {
    if (this.state.academyname.length < 5) {
      this.setState({
        nameError:
          "Academy Name is a mandatory field & must be minimum 5 letter",
      });
      return false;
    }
    if (this.state.ownername.length < 5) {
      this.setState({
        ownerError:
          "Owner Name is a mandatory field & must be minimum 5 letter",
      });
      return false;
    }
    if (this.state.mobile.length < 10) {
      this.setState({
        mobileError:
          "Mobile number is a mandatory field & must be with 10 digits.",
      });
      return false;
    }
    if (!this.state.email.includes("@")) {
      this.setState({
        emailError: "Email must be valid",
      });
      return false;
    }
    if (this.state.address.length < 5) {
      this.setState({
        AddressError:
          "Address is a mandatory field & must be minimum 5 letter.",
      });
      return false;
    }

    if (this.state.Image === "") {
      this.setState({
        photoError: "This is a mandatory Field.",
      });
      return false;
    } else {
      return true;
    }
  }
  firstnextbtn() {
    this.setState({
      nameError: "",
      ownerError: "",
      AddressError: "",
      mobileError: "",
      emailError: "",
      photoError: "",
    });
    if (this.valid()) {
      this.setState({ page: 2, buttonpage2: true });
    } else {
    }
  }
  valid2() {
    if (this.state.id1 === "") {
      this.setState({
        GameError: "This is a mandatory field & must select atleast one game.",
      });
      return false;
    } else {
      return true;
    }
  }

  Gamecheck(evt) {
    this.setState({ id1: evt.target.value });

    let newArray = [...this.state.workDays, evt.target.value];
    if (this.state.workDays.includes(evt.target.value)) {
      newArray = newArray.filter((day) => day !== evt.target.value);
    }
    this.setState({
      workDays: newArray,
    });

    console.log("CHECK", newArray);
  }
  secondnextbtn() {
    this.setState({ GameError: "" });
    if (this.valid2()) {
      this.setState({ page: 3, buttonpage3: true });
    } else {
    }
  }
  valid3() {
    if (this.state.bankname.length < 2) {
      this.setState({
        banknameError: "Bank Name is a mandatory field ",
      });
      return false;
    }
    if (this.state.ifsc === "") {
      this.setState({
        ifscError: "Ifsc Code is mandatory field",
      });
      return false;
    }
    if (this.state.accnumber.length < 8) {
      this.setState({
        accnumberError:
          "Account Number is a mandatory field & must be over 8 digits",
      });
      return false;
    } else {
      return true;
    }
  }
  thirdnextbtn() {
    this.setState({
      banknameError: "",
      ifscError: "",
      accnumberError: "",
    });
    if (this.valid3()) {
      this.setState({ page: 4, buttonpage4: true });
    } else {
    }
  }
  valid4() {
    if (this.state.pass.length < 8) {
      this.setState({
        passError:
          "Password is a mandatory field & must be with minimum 8 keywords",
      });
      return false;
    }
    if (this.state.repass != this.state.pass) {
      this.setState({
        repassError: "Confirm Password Doesnot Matches",
      });
      return false;
    } else {
      return true;
    }
  }
  getgamelist() {
    var that = this;
    API.post("MasterApi/game_list")
      .then(function (response) {
        console.log(response.data.data);
        that.setState({
          Gamelist: response.data.data,
        });
      })

      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        if (that.state.res === 1) {
        } else {
        }
      });
  }
  handlePicChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Image: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }
  handlevideoChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    reader.onloadend = () => {
      this.setState({
        Video: file,
        // imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  getGameMobile(evt) {
    const { name, value } = evt.target;
    if (value.length <= 10) {
      this.setState({ mobile: evt.target.value });
    }
  }
  getaccountnumber(evt) {
    const { name, value } = evt.target;
    if (value.length <= 18) {
      this.setState({ accnumber: evt.target.value });
    }
  }
  Register() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    this.setState({
      passError: "",
      repassError: "",
    });
    if (this.valid4()) {
      var formdata = new FormData();

      formdata.append("user_type", 4);
      formdata.append("name", this.state.academyname);
      formdata.append("owner_name", this.state.ownername);
      formdata.append("address", this.state.address);
      formdata.append("latitude", this.state.latitude);
      formdata.append("longitude", this.state.longitude);
      formdata.append("email", this.state.email);
      formdata.append("mobile", this.state.mobile);

      formdata.append("password", this.state.pass);
      formdata.append("photo", this.state.Image);
      // formdata.append("video", this.state.Video);
      formdata.append("bank_name", this.state.bankname);
      formdata.append("ifsc_code", this.state.ifsc);
      formdata.append("account_number", this.state.accnumber);
      formdata.append("game_ids", this.state.workDays);
      formdata.append("fcm_token", "");

      const head = {
        headers: {
          "Content-Type": "application/json",
          Source: "web",
        },
      };

      var that = this;
      API.post("AcademyApi/registration", formdata, head)
        .then(function (response) {
          console.log(response);
          that.setState({
            res: response.data.result,
            signuperror: response.data.message,
          });
        })

        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          if (that.state.res === 1) {
            that.setState({ success: true });
            setTimeout(() => {
              that.setState({ success: false });
            }, 3000);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            that.setState({ error: true });
            setTimeout(() => {
              that.setState({ error: false });
            }, 3000);
          }
        });
    } else {
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="banner-register">
          <img
            className=""
            src={require("../assets/img/banner-register.jpeg").default}
            style={{
              height: "300px",
              width: "100%",
              opacity: 0.8,
            }}
            alt=""
          />
          <h1> REGISTER</h1>
          <p>Home | Register</p>
        </div>
        <Container>
          <Grid container spacing={2} marginY={2}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  <button
                    class="rounded-circle"
                    onClick={() =>
                      this.setState({
                        page: 1,
                      })
                    }
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      width: "50px",
                      backgroundColor: "lightgreen",
                      border: "1px solid lightgrey",
                    }}
                  >
                    1
                  </button>
                </Box>
                <Box>
                  <Typography> Personal</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  <button
                    className={
                      this.state.buttonpage2
                        ? classes.buttontrue
                        : classes.buttonfalse
                    }
                    onClick={() =>
                      this.setState({
                        page: 2,
                        buttonpage2: true,
                      })
                    }
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      width: "50px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    2
                  </button>
                </Box>
                <Box>
                  <Typography> Classification</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  <button
                    className={
                      this.state.buttonpage3
                        ? classes.buttontrue
                        : classes.buttonfalse
                    }
                    onClick={() =>
                      this.setState({
                        page: 3,
                        buttonpage3: true,
                      })
                    }
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      width: "50px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    3
                  </button>
                </Box>
                <Box>
                  <Typography> Bank Details</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  <button
                    className={
                      this.state.buttonpage4
                        ? classes.buttontrue
                        : classes.buttonfalse
                    }
                    onClick={() =>
                      this.setState({
                        page: 4,
                        buttonpage4: true,
                      })
                    }
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      width: "50px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    4
                  </button>
                </Box>
                <Box>
                  <Typography> Login Details</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <div className="container registerforms">
          <div
            className="row"
            style={{ paddingTop: "30px", paddingBottom: "30px" }}
          >
            <div className="col-md-2 offset-2">
              <button
                class="rounded-circle"
                onClick={() =>
                  this.setState({
                    page: 1,
                  })
                }
                style={{
                  fontSize: "20px",
                  height: "50px",
                  width: "50px",
                  backgroundColor: "lightgreen",
                  border: "1px solid lightgrey",
                }}
              >
                1
              </button>
              <p> Personal</p>
            </div>
            <div className="col-md-2">
              <button
                className={
                  this.state.buttonpage2
                    ? "rounded-circle buttontrue"
                    : "rounded-circle buttonfalse"
                }
                onClick={() =>
                  this.setState({
                    page: 2,
                    buttonpage2: true,
                  })
                }
                style={{
                  fontSize: "20px",
                  height: "50px",
                  width: "50px",
                  border: "1px solid lightgrey",
                }}
              >
                2
              </button>
              <p> Classification</p>
            </div>
            <div className="col-md-2">
              <button
                className={
                  this.state.buttonpage3
                    ? "rounded-circle buttontrue"
                    : "rounded-circle buttonfalse"
                }
                onClick={() =>
                  this.setState({
                    page: 3,
                    buttonpage3: true,
                  })
                }
                style={{
                  fontSize: "20px",
                  height: "50px",
                  width: "50px",
                  border: "1px solid lightgrey",
                }}
              >
                3
              </button>
              <p>Bank Details</p>
            </div>
            <div className="col-md-2">
              <button
                className={
                  this.state.buttonpage4
                    ? "rounded-circle buttontrue"
                    : "rounded-circle buttonfalse"
                }
                onClick={() =>
                  this.setState({
                    page: 4,
                    buttonpage4: true,
                  })
                }
                style={{
                  fontSize: "20px",
                  height: "50px",
                  width: "50px",
                  border: "1px solid lightgrey",
                }}
              >
                4
              </button>
              <p>Login Details</p>
            </div>
          </div>
        </div> */}
        {this.state.page === 1 ? (
          <Container>
            <h6>*Required Field</h6>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Category *</p>
                <FormControl variant="filled" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="category"
                    input={<OutlinedInput />}
                    value={3}
                    disabled
                  >
                    <MenuItem value={3}>Gaming Academy</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Academy Name *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.academyname}
                    onChange={(evt) => {
                      this.setState({ academyname: evt.target.value });
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    type="text"
                    fullWidth
                  />
                </FormControl>
                <span className="error">{this.state.nameError}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Owner Name *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.ownername}
                    onChange={(evt) => {
                      this.setState({ ownername: evt.target.value });
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    type="name"
                    fullWidth
                  />
                </FormControl>
                <span className="error">{this.state.ownerError}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Mobile *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.mobile}
                    onChange={(evt) => this.getGameMobile(evt)}
                    type="number"
                    fullWidth
                  />
                </FormControl>
                <span className="error">{this.state.mobileError}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Email *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.email}
                    onChange={(evt) => {
                      this.setState({ email: evt.target.value });
                    }}
                    type="email"
                    fullWidth
                  />
                </FormControl>
                <span className="error">{this.state.emailError}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Address *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.address}
                    onChange={(evt) => {
                      this.setState({ address: evt.target.value });
                    }}
                    type="text"
                    fullWidth
                    inputProps={{
                      maxLength: 80,
                    }}
                  />
                </FormControl>
                <span className="error">{this.state.AddressError}</span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Photo *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    onChange={(evt) => {
                      this.handlePicChange(evt);
                    }}
                    type="file"
                    inputProps={{
                      accept: "image/png, image/jpeg",
                    }}
                    fullWidth
                  />
                </FormControl>
                <span className="error">{this.state.photoError}</span>
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    onClick={() => {
                      this.firstnextbtn();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        ) : (
          // <div className="container">
          //   <div className="col offset-2">
          //     {" "}
          //     <p>*Required Field</p>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Category *</p>
          //       <Form.Select aria-label="Default select example" disabled>
          //         <option>Game Academy</option>
          //       </Form.Select>
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>Academy Name *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.academyname}
          //           onChange={(evt) => {
          //             this.setState({ academyname: evt.target.value });
          //           }}
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.nameError}</span>}
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Owner Name *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.ownername}
          //           onChange={(evt) => {
          //             this.setState({ ownername: evt.target.value });
          //           }}
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.ownerError}</span>}
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>Mobile Number *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.mobile}
          //           onChange={(evt) => {
          //             this.setState({ mobile: evt.target.value });
          //           }}
          //           type="number"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.mobileError}</span>}
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>EmailAddress *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.email}
          //           onChange={(evt) => {
          //             this.setState({ email: evt.target.value });
          //           }}
          //           type="email"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.emailError}</span>}
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>Address *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.address}
          //           onChange={(evt) => {
          //             this.setState({ address: evt.target.value });
          //           }}
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.AddressError}</span>}
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div class="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}> Photo *</p>
          //       <Form>
          //         <Form.Control
          //           onChange={(evt) => {
          //             this.handlePicChange(evt);
          //           }}
          //           type="file"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.photoError}</span>}
          //     </div>
          //   </div>
          //   <div className="row"></div>
          //   <div className="col offset-2" style={{ paddingTop: "20px" }}>
          //     <Button
          //       variant="primary"
          //       style={{ borderRadius: "0px", width: "18vh" }}
          //       onClick={() => {
          //         this.firstnextbtn();
          //       }}
          //     >
          //       Next
          //     </Button>
          //   </div>
          // </div>
          ""
        )}
        {this.state.page === 2 ? (
          <Container>
            <h6>*Required Field</h6>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Classification of game *</p>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="radio-buttons-group"
                    onChange={(evt) => {
                      this.Gamecheck(evt);
                    }}
                    row
                  >
                    {this.state.Gamelist.map((l, id) => {
                      return (
                        <FormControlLabel
                          value={l.game_id}
                          control={<Radio />}
                          label={l.name}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {<span className="error">{this.state.GameError}</span>}
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    onClick={() => {
                      this.secondnextbtn();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        ) : (
          // <div className="container">
          //   <div className="col offset-2">
          //     {" "}
          //     <p>*Required Field</p>
          //     <div className="row" style={{ paddingLeft: "20px" }}>
          //       <p style={{ opacity: "0.6" }}>Classification of game *</p>
          //       {this.state.Gamelist.map((l, id) => {
          //         return (
          //           <Form.Check
          //             label={l.name}
          //             name="group1"
          //             type="checkbox"
          //             onChange={(evt) => {
          //               this.Gamecheck(evt);
          //             }}
          //             key={l.game_id}
          //             value={l.game_id}
          //             style={{ opacity: "0.8", paddingTop: "10px" }}
          //           />
          //         );
          //       })}
          //       {<span className="error">{this.state.GameError}</span>}
          //     </div>
          //   </div>
          //   <div className="col offset-2" style={{ paddingTop: "20px" }}>
          //     <Button
          //       variant="primary"
          //       style={{ borderRadius: "0px", width: "18vh" }}
          //       onClick={() => {
          //         this.secondnextbtn();
          //       }}
          //     >
          //       Next
          //     </Button>
          //   </div>
          // </div>
          ""
        )}
        {this.state.page === 3 ? (
          <Container>
            <h6>*Required Field</h6>
            <p style={{ lineHeight: "1px" }}>
              *Bank Details Required For Monthly Transactions Purpose
            </p>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Bank Name *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.bankname}
                    onChange={(evt) => {
                      this.setState({ bankname: evt.target.value });
                    }}
                    inputProps={{
                      maxLength: 30,
                    }}
                    type="name"
                    fullWidth
                  />
                </FormControl>
                {<span className="error">{this.state.banknameError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>IFSC Code *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.ifsc}
                    onChange={(evt) => {
                      this.setState({ ifsc: evt.target.value });
                    }}
                    inputProps={{
                      maxLength: 11,
                      style: { textTransform: "uppercase" },
                    }}
                    type="name"
                    fullWidth
                  />
                </FormControl>
                {<span className="error">{this.state.ifscError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>Account Number *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.accnumber}
                    onChange={(evt) => {
                      this.getaccountnumber(evt);
                    }}
                    inputProps={{
                      maxLength: 18,
                    }}
                    type="number"
                    fullWidth
                  />
                </FormControl>
                {/* <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.accnumber}
                    onChange={(evt) => {
                      this.setState({ accnumber: evt.target.value });
                    }}
                    inputProps={{
                      maxLength: "18",
                    }}
                    type="number"
                    fullWidth
                  />
                </FormControl> */}
                {<span className="error">{this.state.accnumberError}</span>}
              </Grid>
              <Grid container className={classes.nextButton1}>
                <Grid item md={2} lg={4}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0px",
                      width: "18vh",
                      backgroundColor: "deepskyblue",
                    }}
                    onClick={() => {
                      this.thirdnextbtn();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        ) : (
          // <div className="container">
          //   <div className="col offset-2">
          //     {" "}
          //     <p>*Required Field</p>
          //     <p style={{ lineHeight: "1px" }}>
          //       *Bank Details Required For Monthly Transactions Purpose
          //     </p>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Bank Name *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.bankname}
          //           onChange={(evt) => {
          //             this.setState({ bankname: evt.target.value });
          //           }}
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.banknameError}</span>}
          //     </div>
          //     <div class="col-md-4">
          //       <p style={{ opacity: "0.6" }}>IFSC Code *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.ifsc}
          //           onChange={(evt) => {
          //             this.setState({ ifsc: evt.target.value });
          //           }}
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.ifscError}</span>}
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Account Number *</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.accnumber}
          //           onChange={(evt) => {
          //             this.setState({ accnumber: evt.target.value });
          //           }}
          //           type="number"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.accnumberError}</span>}
          //     </div>
          //   </div>
          //   <div className="col offset-2" style={{ paddingTop: "20px" }}>
          //     <Button
          //       variant="primary"
          //       style={{ borderRadius: "0px", width: "18vh" }}
          //       onClick={() => {
          //         this.thirdnextbtn();
          //       }}
          //     >
          //       Next
          //     </Button>
          //   </div>
          // </div>
          ""
        )}
        {this.state.page === 4 ? (
          <Container>
            <h6>*Required Field</h6>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p style={{ opacity: "0.6" }}>UserName *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.mobile}
                    disabled
                    type="name"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Password *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.pass}
                    onChange={(evt) => {
                      this.setState({ pass: evt.target.value });
                    }}
                    type="name"
                    fullWidth
                  />
                </FormControl>
                {<span className="error">{this.state.passError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p style={{ opacity: "0.6" }}>Re Password *</p>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <TextField
                    value={this.state.repass}
                    onChange={(evt) => {
                      this.setState({ repass: evt.target.value });
                    }}
                    type="name"
                    fullWidth
                  />
                </FormControl>
                {<span className="error">{this.state.repassError}</span>}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display="flex" flexDirection="column">
                  <Box>
                    {this.state.error && (
                      <Alert severity="error">{this.state.signuperror}</Alert>
                    )}
                    {this.state.success && (
                      <Alert severity="success">{this.state.signuperror}</Alert>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    marginTop={2}
                    justifyContent="space-between"
                  >
                    {this.state.loading ? (
                      <CircularProgress />
                    ) : (
                      <Box>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "deepskyblue" }}
                          onClick={() => {
                            this.Register();
                          }}
                        >
                          Register
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        ) : (
          // <div className="container">
          //   <div className="col offset-2">
          //     {" "}
          //     <p>*Required Field</p>
          //   </div>
          //   <div className="row">
          //     <div className="col offset-2">
          //       <p style={{ opacity: "0.6" }}>UserName *</p>
          //     </div>
          //   </div>
          //   <div className="row"></div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-8 offset-2">
          //       <Form>
          //         <Form.Control
          //           value={this.state.mobile}
          //           disabled
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //     </div>
          //   </div>
          //   <div className="row" style={{ paddingTop: "20px" }}>
          //     <div className="col-md-4 offset-2">
          //       <p style={{ opacity: "0.6" }}>Password</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.pass}
          //           onChange={(evt) => {
          //             this.setState({ pass: evt.target.value });
          //           }}
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.passError}</span>}
          //     </div>
          //     <div className="col-md-4">
          //       <p style={{ opacity: "0.6" }}> Re Password</p>
          //       <Form>
          //         <Form.Control
          //           value={this.state.repass}
          //           onChange={(evt) => {
          //             this.setState({ repass: evt.target.value });
          //           }}
          //           type="name"
          //           placeholder=""
          //         />
          //       </Form>
          //       {<span className="error">{this.state.repassError}</span>}
          //     </div>
          //     {this.state.success ? (
          //       <div className="row">
          //         <div
          //           className="col-md-8 offset-2"
          //           style={{
          //             paddingTop: "45px",
          //             position: "relative",
          //             top: "4vh",
          //             backgroundColor: "green",
          //             textAlign: "center",
          //           }}
          //         >
          //           <h5 style={{ color: "white", fontSize: "small" }}>
          //             <GiBarbedSpear />
          //             {this.state.signuperror}
          //           </h5>
          //         </div>
          //       </div>
          //     ) : (
          //       ""
          //     )}
          //     {this.state.error ? (
          //       <div className="row">
          //         <div
          //           className="col-md-8 offset-2"
          //           style={{
          //             paddingTop: "45px",
          //             position: "relative",
          //             top: "4vh",
          //             backgroundColor: "red",
          //             textAlign: "center",
          //           }}
          //         >
          //           <h5 style={{ color: "white", fontSize: "small" }}>
          //             <CgDanger /> {this.state.signuperror}
          //           </h5>
          //         </div>
          //       </div>
          //     ) : (
          //       ""
          //     )}
          //   </div>
          //   <div className="col offset-2" style={{ paddingTop: "20px" }}>
          //     <Button
          //       variant="primary"
          //       style={{ borderRadius: "0px", width: "18vh" }}
          //       onClick={() => {
          //         this.Register();
          //       }}
          //     >
          //       {this.state.loading ? (
          //         <span>
          //           {" "}
          //           <ClipLoader color="white" size={20} />
          //           Please Wait{" "}
          //         </span>
          //       ) : (
          //         <span>Submit</span>
          //       )}
          //     </Button>
          //   </div>
          // </div>
          ""
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    // color: theme.palette.primary.main
  },
  stepOne: {
    fontSize: "20px",
    height: "50px",
    width: "50px",
    backgroundColor: "lightgreen",
    border: "1px solid lightgrey",
    borderRadius: "50%",
  },
  nextButton1: {
    marginTop: "12px",
    marginLeft: "16px",
  },
  buttontrue: {
    backgroundColor: "lightgreen",
    borderRadius: "50%",
  },
  buttonfalse: {
    backgroundColor: "lightgrey",
    borderRadius: "50%",
  },
});
export default withStyles(styles)(GameRegister);
