import React, { Component, useEffect, useState } from "react";
import { GiBarbedSpear } from "react-icons/gi";
import { Form } from "react-bootstrap";
import { CgDanger } from "react-icons/cg";
import API from "../components/api";
import ClipLoader from "react-spinners/ClipLoader";
import { navigate } from "@reach/router";
// import { Router, Link } from "@reach/router"
import { useAuth } from "../provider/useAuth";
import {
  Select,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  OutlinedInput,
  TextField,
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  MenuItem,
  Alert,
  Stack,
  useTheme,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useWindowSize from "../provider/useWindowSize";
import { withStyles } from "@mui/styles";

const LogIn = ({ classes }) => {
  // let history = useHistory()
  const theme = useTheme();
  const { width } = useWindowSize();
  const { xs, sm, md, lg } = theme.breakpoints.values;
  const auth = useAuth();
  const [subscription, setSubscription] = useState("");
  const [userName, setUserName] = useState("");

  const [password, setPassword] = useState("");

  const [user_type, setUser_type] = useState("none");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signInButtonVisibility, setSignInButtonVisibility] = useState(false);

  const [accountError, setAccountError] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginStatusErrorMessage, setLoginStatusErrorMessage] = useState("");
  const [loginStatusSuccessMessage, setLoginStatusSuccessMessage] =
    useState("");
  const [loadingSignInButton, setLoadingSignInButton] = useState(false);

  // state = {
  //   subscription: "",
  //   username: "",

  //   password: "",
  //   user_type: "",
  //   success: false,
  //   error: false,
  //   message: "",
  //   loading: false,
  // };

  useEffect(() => {
    if (user_type && user_type !== "none" && userName && password) {
      // if (password && (password.length < 8 || password.length > 16)) {
      //   setAccountError("Password must be 8-16 characters")
      //   setSignInButtonVisibility(false)
      // } else if (password && (password.length >= 8 && password.length <= 16) && (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(password))) {
      //   setAccountError("Password must be valid")
      //   setSignInButtonVisibility(false)
      // } else {
      //   setAccountError("")
      //   setSignInButtonVisibility(true)
      // }
      setSignInButtonVisibility(true);
    } else {
      setSignInButtonVisibility(false);
    }
  }, [password, userName, user_type]);

  const handleusernameChange = (evt) => {
    setUserName(evt.target.value);
  };
  const handlepasswordChange = (evt) => {
    setPassword(evt.target.value);
    console.log("evt", evt.target.value);
  };
  const handleusertype = (evt) => {
    setUser_type(evt.target.value);
    console.log("type", evt.target.value);
  };

  const isShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const Login = () => {
    setLoadingSignInButton(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);

    var that = this;
    const credentials = {
      mobile: userName,
      password: password,
      user_type: user_type,
    };
    const headers = {
      "Content-Type": "application/json",
      Source: "web",
    };

    if (user_type === "3") {
      API.post("TeacherApi/login", credentials, {
        headers: headers,
      }).then((response) => {
        if (response.data.result === 1) {
          setLoginStatusSuccessMessage(response.data.data.name);
          setLoginStatus(true);
          localStorage.setItem("userID", response.data.data.user_id);
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("LoggedIn", 1);

          setTimeout(() => {
            setLoginStatus(false);
            setLoginStatusSuccessMessage("");
            setLoadingSignInButton(false);
            auth.setIsLoggedIn(1);
            navigate("/teacherprofile");
          }, 2000);
        } else {
          setLoginStatus(true);
          setLoginStatusErrorMessage(response.data.message);
          setTimeout(() => {
            setLoadingSignInButton(false);
            setLoginStatus(false);
            setLoginStatusErrorMessage("");
          }, 2000);
        }
        console.log("loginSuccess", response);
      });
    }
    if (user_type === "1") {
      API.post("StudentApi/login", credentials, {
        headers: headers,
      }).then((response) => {
        if (response.data.result === 1) {
          setLoginStatusSuccessMessage(response.data.data.name);
          setLoginStatus(true);

          localStorage.setItem("userID", response.data.data.user_id);
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("LoggedIn", 1);
          localStorage.setItem("class_id", response.data.data.class_id);
          localStorage.setItem(
            "school_id(student)",
            response.data.data.school_id
          );
          setTimeout(() => {
            setLoginStatus(false);
            setLoginStatusSuccessMessage("");
            setLoadingSignInButton(false);
            auth.setIsLoggedIn(1);
            {
              response.data.data.subscription_taken === "Y"
                ? navigate("/userprofile")
                : navigate("/buysubscription");
            }
          }, 2000);
        } else {
          setLoginStatus(true);
          setLoginStatusErrorMessage(response.data.message);
          setTimeout(() => {
            setLoginStatus(false);
            setLoginStatusErrorMessage("");
            setLoadingSignInButton(false);
          }, 2000);
        }
        console.log("loginSuccess", response);
      });
    }
    if (user_type === "2") {
      API.post("SchoolApi/login", credentials, {
        headers: headers,
      }).then((response) => {
        if (response.data.result === 1) {
          setLoginStatusSuccessMessage(response.data.data.name);
          setLoginStatus(true);
          localStorage.setItem("userID", response.data.data.user_id);
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("LoggedIn", 1);
          localStorage.setItem("school_id", response.data.data.school_id);
          setTimeout(() => {
            setLoginStatus(false);
            setLoginStatusSuccessMessage("");
            setLoadingSignInButton(false);
            auth.setIsLoggedIn(1);
            navigate("/observer_profile");
          }, 2000);
        } else {
          setLoginStatus(true);
          setLoginStatusErrorMessage(response.data.message);
          setTimeout(() => {
            setLoginStatus(false);
            setLoginStatusErrorMessage("");
            setLoadingSignInButton(false);
          }, 2000);
        }
        console.log("loginSuccess", response);
      });
    }
    if (user_type === "4") {
      API.post("AcademyApi/login", credentials, {
        headers: headers,
      }).then((response) => {
        if (response.data.result === 1) {
          setLoginStatusSuccessMessage(response.data.data.name);
          setLoginStatus(true);
          localStorage.setItem("userID", response.data.data.user_id);
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("LoggedIn", 1);
          localStorage.setItem(
            "school_id(student)",
            response.data.data.school_id
          );

          localStorage.setItem("class_id", response.data.data.class_id);
          setTimeout(() => {
            setLoginStatus(false);
            setLoginStatusSuccessMessage("");
            setLoadingSignInButton(false);
            auth.setIsLoggedIn(1);
            navigate("/gameprofile");
          }, 2000);
        } else {
          setLoginStatus(true);
          setLoginStatusErrorMessage(response.data.message);
          setTimeout(() => {
            setLoginStatus(false);
            setLoginStatusErrorMessage("");
            setLoadingSignInButton(false);
          }, 2000);
        }
        console.log("loginSuccess", response);
      });
    }

    // {
    //   this.state.user_type == 1
    //     ? API.post("StudentApi/login", credentials, {
    //         headers: headers,
    //       })
    //         .then(function (response) {
    //           console.log(response);
    //           that.setState({ message: response.data.message });
    //           if (response.data.result === 1) {
    //             that.setState({
    //               subscription: response.data.data.subscription_taken,
    //             });
    //             that.setState({ success: true });
    //             setTimeout(() => {
    //               that.setState({ success: false });
    //             }, 2000);
    //             localStorage.setItem("userID", response.data.data.user_id);
    //             localStorage.setItem("user_type", response.data.data.user_type);
    //             localStorage.setItem("LoggedIn", true);
    //             localStorage.setItem(
    //               "school_id(student)",
    //               response.data.data.school_id
    //             );

    //             localStorage.setItem("class_id", response.data.data.class_id);
    //             {
    //               that.state.subscription === "Y"
    //                 ? setTimeout(() => {
    //                     that.props.history.push("/userprofile");
    //                   }, 2000)
    //                 : that.props.history.push("/buysubscription");
    //             }
    //           } else {
    //             that.setState({ error: true });
    //             setTimeout(() => {
    //               that.setState({ error: false });
    //             }, 2000);
    //           }
    //         })

    //         .catch(function (error) {
    //           console.log(error);
    //         })
    //         .finally(function () {})
    //     : "";
    // }
    // {
    //   this.state.user_type == 4
    //     ? API.post("AcademyApi/login", credentials, {
    //         headers: headers,
    //       })
    //         .then(function (response) {
    //           console.log(response);
    //           that.setState({ message: response.data.message });
    //           if (response.data.result === 1) {
    //             that.setState({
    //               subscription: response.data.data.subscription_taken,
    //             });
    //             that.setState({ success: true });
    //             setTimeout(() => {
    //               that.setState({ success: false });
    //             }, 2000);
    //             localStorage.setItem("userID", response.data.data.user_id);
    //             localStorage.setItem("user_type", response.data.data.user_type);
    //             localStorage.setItem("LoggedIn", true);
    //             localStorage.setItem(
    //               "school_id(student)",
    //               response.data.data.school_id
    //             );

    //             localStorage.setItem("class_id", response.data.data.class_id);
    //             setTimeout(() => {
    //               that.props.history.push("/gameprofile");
    //             }, 2500);
    //           } else {
    //             that.setState({ error: true });
    //             setTimeout(() => {
    //               that.setState({ error: false });
    //             }, 2000);
    //           }
    //         })

    //         .catch(function (error) {
    //           console.log(error);
    //         })
    //         .finally(function () {})
    //     : "";
    // }
    // {
    //   this.state.user_type == 2
    //     ? API.post("SchoolApi/login", credentials, {
    //         headers: headers,
    //       })
    //         .then(function (response) {
    //           console.log(response);
    //           that.setState({ message: response.data.message });
    //           if (response.data.result === 1) {
    //             that.setState({ success: true });
    //             setTimeout(() => {
    //               that.setState({ success: false });
    //             }, 2000);
    //             localStorage.setItem("user2ndID", response.data.data.user_id);
    //             localStorage.setItem("user_type", response.data.data.user_type);
    //             localStorage.setItem("LoggedIn", true);
    //             localStorage.setItem("school_id", response.data.data.school_id);
    //             that.props.history.push("/observer_profile");
    //           } else {
    //             that.setState({ error: true });
    //             setTimeout(() => {
    //               that.setState({ error: false });
    //             }, 2000);
    //           }
    //         })

    //         .catch(function (error) {
    //           console.log(error);
    //         })
    //         .finally(function () {})
    //     : "";
    // }
    // fetch(
    //   "https://thingproxy.freeboard.io/fetch/https://sermap.kazma.co.in/backend/Api/StudentApi/login",
    //   {
    //     method: "POST",
    //     body: JSON.stringify({
    //       mobile: this.state.username,
    //       password: this.state.password,
    //       user_type: this.state.user_type,
    //     }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log("Success:", result);
    //     this.setState({
    //       message: result.message,
    //     });

    //     if (result.result === 1) {
    //       this.setState({ subscription: result.data.subscription_taken });
    //       this.setState({ success: true });
    //       setTimeout(() => {
    //         this.setState({ success: false });
    //       }, 2000);
    //       localStorage.setItem("userID", result.data.user_id);
    //       localStorage.setItem("LoggedIn", true);
    //       {
    //         this.state.subscription === "Y"
    //           ? setTimeout(() => {
    //               this.props.history.push("/userprofile");
    //             }, 2000)
    //           : this.props.history.push("/buysubscription");
    //       }
    //     } else {
    //       this.setState({ error: true });
    //       setTimeout(() => {
    //         this.setState({ error: false });
    //       }, 2000);
    //     }
    //   })

    //   .catch((error) => {});
    // {this.state.}
  };

  return (
    <>
      <div
        className="banner-register"
        style={{ marginTop: width < md ? "156px" : "" }}
      >
        <img
          src={require("../assets/img/banner-register.jpeg").default}
          style={{
            height: "300px",
            width: "100%",
          }}
          alt=""
        />
        <h1> SIGN IN</h1>

        <p>You can sign in to our system from here</p>
      </div>
      <Container>
        <Grid container spacing={2} marginY={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="sign-in">
              <img
                src={require("../assets/img/pexels-photo-3769981.jpeg").default}
                alt=""
                style={{ width: "100%" }}
              />
              <h4>WELCOME TO THE NEXT GENERATION EDUCATION SYSTEM</h4>
              <p>
                Lorem Ipsum is simply a dummy which we write instead of the real
                text , it will be changed later
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Container>
              <Grid container paddingTop={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="subject_id"
                      value={user_type}
                      onChange={handleusertype}
                      input={<OutlinedInput />}
                    >
                      <MenuItem value="none">Who are you? *</MenuItem>
                      <MenuItem value="1">Student</MenuItem>
                      <MenuItem value="2">Observer</MenuItem>
                      <MenuItem value="4">Game Academy</MenuItem>
                      <MenuItem value="3">Teacher</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} marginY={1}>
                  <p style={{ opacity: "0.6" }}>Username / Mobile / Email *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="username"
                      variant="outlined"
                      value={userName}
                      onChange={handleusernameChange}
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} marginY={1}>
                  {/* <p style={{ opacity: "0.6" }}>Password *</p>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <TextField
                      name="password"
                      variant="outlined"
                      type="password"
                    />
                  </FormControl> */}

                  <p style={{ opacity: "0.6" }}>Password *</p>
                  <OutlinedInput
                    name="password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={handlepasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={isShowPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {accountError && (
                    <span className="error">{accountError}</span>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box display="flex" flexDirection="column">
                    {loginStatus ? (
                      <Box>
                        {loginStatusErrorMessage && (
                          <Alert severity="error">
                            {loginStatusErrorMessage}
                          </Alert>
                        )}
                        {loginStatusSuccessMessage && (
                          <Alert severity="success">
                            Successfully logged in {loginStatusSuccessMessage}
                          </Alert>
                        )}
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        marginTop={2}
                        justifyContent="space-between"
                      >
                        {loadingSignInButton ? (
                          <CircularProgress />
                        ) : (
                          <Box>
                            {signInButtonVisibility ? (
                              <Button
                                variant="contained"
                                style={{ backgroundColor: "deepskyblue" }}
                                onClick={Login}
                              >
                                Sign In
                              </Button>
                            ) : (
                              <Button variant="contained" disabled>
                                Sign In
                              </Button>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                    <Box marginTop={1}>
                      <Typography>Forgot Password?</Typography>
                    </Box>
                    <Box marginTop={1}>
                      <Typography>
                        Are you new on Sermap?{" "}
                        <Link href="/signup" style={{ textDecoration: "none" }}>
                          Register Now
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
      {/* <div className="container sign-in">
        <div className="row">
          <div className="col-md-5 offset-1">
            <img
              src={require("../assets/img/pexels-photo-3769981.jpeg").default}
              alt=""
            />
            <h4>WELCOME TO THE NEXT GENERATION EDUCATION SYSTEM</h4>
            <p>
              Lorem Ipsum is simply a dummy which we write instead of the real
              text , it will be changed later
            </p>
          </div>
          <div className="col-md-5 offset-1">
            <div className="col-md-5">
              <Form>
                <Form.Select
                  required
                  value={user_type}
                  onChange={handleusertype}
                >
                  <option style={{ fontSize: "larger", fontWeight: "bold" }}>
                    Who are you ?
                  </option>
                  <option value="1">Student</option>
                  <option value="2">Observer</option>
                  <option value="4">GameAcademy</option>
                  <option value="3">Teacher</option>
                </Form.Select>
              </Form>
            </div>
            <div className="form-group">
              <h6
                style={{
                  paddingBottom: "5px",
                  opacity: "0.4",
                  paddingTop: "60px",
                }}
              >
                Username / Mobile / Email
              </h6>
              <input
                type="username"
                value={userName}
                onChange={handleusernameChange}
                style={{ opacity: "0.4", height: "50px", width: "350px" }}
              />
              <h6
                style={{
                  paddingBottom: "5px",
                  opacity: "0.4",
                  paddingTop: "19px",
                }}
              >
                Password
              </h6>
              <input
                value={password}
                onChange={handlepasswordChange}
                type="password"
                style={{ opacity: "0.4", height: "50px", width: "350px" }}
              />

              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <Button
                      onClick={Login}
                      class="btn btn-primary btn-lg"
                      style={{
                        position: "relative",
                        top: "20px",
                        height: "43px",
                        width: "132px",
                      }}
                    >
                      {loading ? (
                        <span>
                          {" "}
                          <ClipLoader color="white" size={20} />
                          Please Wait{" "}
                        </span>
                      ) : (
                        <span>Sign In</span>
                      )}
                    </Button>
                  </div>
                  <div className="col-md-10">
                    {success ? (
                      <div className="row">
                        <div
                          className="col-md-10"
                          style={{
                            paddingTop: "20px",
                            position: "relative",
                            top: "4vh",
                            backgroundColor: "green",
                            textAlign: "center",
                          }}
                        >
                          <h5 style={{ color: "white", fontSize: "small" }}>
                            <GiBarbedSpear /> You are logged in successfully
                          </h5>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {error ? (
                      <div className="row">
                        <div
                          className="col-md-10 "
                          style={{
                            paddingTop: "20px",
                            position: "relative",
                            top: "4vh",
                            backgroundColor: "red",
                            textAlign: "center",
                          }}
                        >
                          <h5 style={{ color: "white", fontSize: "small" }}>
                            <CgDanger />
                            Invalid Login Credentials
                          </h5>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <br></br>
                <div className="row" style={{ paddingTop: "10px" }}>
                  <div className="col-md-5">
                    <h6>Create an account ?</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <h6>Forgot Password</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
const styles = (theme) => ({
  root: {},
  profileheading: {
    position: "absolute",
    top: "25%",
    left: "20%",
    color: "white",
  },
  notclicked: {
    backgroundColor: "lightgrey",
    height: "40px",
    border: "none",
    width: "100%",
  },
  cliked: {
    backgroundColor: "rgb(24, 169, 218)",
    height: "40px",
    border: "none",
    width: "100%",
    color: "white",
  },
  // demoPlayerBox: {
  //   width: "43%",
  //   height: "200px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // },
  // demoPlayerBoxEdit:{
  //   width: "100%",
  //   height: "55px",
  //   objectFit: "cover",
  //   border: "2px solid black",
  //   borderRadius: "5px",
  // }
  // nextButton1: {
  //   marginTop: "12px"
  // }
});
export default withStyles(styles)(LogIn);
