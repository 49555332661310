import React, { useState, useEffect, useContext, createContext } from 'react'
const authContext = createContext()
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>
    {children}

  </authContext.Provider>
}

export const useAuth = () => useContext(authContext)

function useProvideAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState(0)
  return {
    isLoggedIn,
    setIsLoggedIn
  }
}